<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p style="font-size: 1.2rem; font-weight: bold">{{ title }}</p>
      </header>
      <section class="modal-card-body">
        <p>{{ message }}</p>
        <br v-if="message != ''" />
        <o-checkbox v-model="canAction">OK</o-checkbox>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$emit('cancel')">キャンセル</button>
        <button class="button is-primary" type="button" :disabled="!canAction" @click="action()">
          実行
        </button>
      </footer>
    </div>
  </form>
</template>
<script lang="ts">
import { defineComponent } from "vue";
// Facebook連携解除&データ削除確認用モーダル
export default defineComponent({
  props: {
    propTitle: { type: String, default: "" },
    propMessage: { type: String, default: "" },
    propType: { type: String, default: "" },
    propCanAction: { type: Boolean, default: false },
  },
  emits: ["cancel", "fbDeauthorize", "fbDeletion"],
  data(): { canAction: boolean; title: string; message: string } {
    return {
      canAction: this.propCanAction,
      title: this.propTitle,
      message: this.propMessage,
    };
  },
  methods: {
    action(): void {
      if (this.propType == "deauthorize") {
        this.$emit("fbDeauthorize");
      } else if (this.propType == "deletion") {
        this.$emit("fbDeletion");
      }
    },
  },
});
</script>
