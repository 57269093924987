import { Component, Vue, toNative } from "vue-facing-decorator";
import { useSnackbar } from "@/storepinia/snackbar";
import { requiredAuth } from "@/helpers/request";
import type { EntitiesCompaniesResponse, EntitiesCompany } from "@/types/ls-api";
import wordDictionary from "@/word-dictionary";
import ModalForm from "./modal-form.vue";
import { useSs } from "@/storepinia/ss";
import { getter, action } from "@/storepinia/idxdb";
import { useDialog } from "@/storepinia/dialog";
import { Conditions } from "@/components/shared/store-selector-type";
import { getOperationLogParams } from "@/routes/operation-log";

type Company = EntitiesCompaniesResponse["companies"][0] & {
    ops?: {
        gmbPostEdit: boolean;
    };
};

@Component({
    components: { ModalForm },
})
class Companies extends Vue {
    isShowAllCompanies = getter().isShowAllCompanies;
    setCompany = action().setCompany;
    setAreas = action().setAreas;
    setAreaStores = action().setAreaStores;
    setStores = action().setStores;
    setRoles = action().setRoles;
    setIsShowAllCompanies = action().setIsShowAllCompanies;
    addSnackbarMessages = useSnackbar().addSnackbarMessages;

    dict: any = wordDictionary.companies;
    loading = false;
    companies: Company[] = [];
    scoreMasters = [];
    isModalActive: boolean = false;
    modalProps: any = {};
    searchWord = "";
    cachedCompanies: Company[] = [];

    async pageLoad(thenSearch = false): Promise<void> {
        this.loading = true;

        const resCompanies = await requiredAuth<EntitiesCompaniesResponse>(
            "get",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies`
        );

        if (resCompanies == null || resCompanies.data == null) {
            this.companies = [];
        } else {
            this.companies = resCompanies.data.companies.map((s) => {
                const ops: Company["ops"] = {
                    gmbPostEdit: false,
                };
                if (!s.options) {
                    s.options = [];
                }
                s.options.forEach((o) => {
                    ops[o] = true;
                });
                (s as Company).ops = ops;
                return s as Company;
            });
            // poiGroupIdが0だとレスポンスから省かれる仕様のため、ここで追加する
            this.companies.map(
                (company) =>
                    (company.poiGroupID = company.poiGroupID == null ? 0 : company.poiGroupID)
            );

            // 未登録の企業表示有効の場合フィルタリングする
            if (this.isAll === false) {
                this.companies = this.companies.filter((item) => item.isActive === true);
            }

            // 企業ID順にソートする
            this.companies.sort((a: any, b: any): number => {
                if (a.poiGroupID > b.poiGroupID) {
                    return 1;
                } else {
                    return -1;
                }
            });

            // 企業データをキャッシュする
            this.cachedCompanies = Object.assign([], this.companies);
            // 未登録の企業も表示するのチェックからpageLoadした場合
            if (thenSearch) {
                this.searchCompanies();
            }
        }

        this.loading = false;
    }

    async mounted(): Promise<void> {
        await this.pageLoad();
    }

    async saveCompany(company: Company): Promise<void> {
        this.loading = true;
        // オプションを配列にして、送信用のオブジェクトを作成する
        company.options = Object.keys(company.ops).filter((op) => company.ops[op]);
        const postdata: Company = Object.assign({}, company);
        delete postdata.ops;

        await requiredAuth<EntitiesCompany>(
            "put",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${company.poiGroupID}`,
            null,
            postdata
        );

        this.loading = false;
    }

    get total(): number {
        return Object.keys(this.companies).length;
    }

    get perPage(): 15 {
        return 15;
    }

    get currentPage(): 1 {
        return 1;
    }

    get isAll(): boolean {
        return this.isShowAllCompanies;
    }

    set isAll(show: boolean) {
        this.setIsShowAllCompanies(show);
        this.pageLoad(show);
    }

    newOwnerConfirm(company: EntitiesCompany): void {
        useDialog().confirm({
            title: "オーナー追加",
            message:
                "オーナー追加画面に遷移します。よろしいですか？オーナーを追加すると通知メールが自動で出され、利用可能な状態になります。",
            hasIcon: true,
            type: "warning",
            onConfirm: () => this.newOwner(company.poiGroupID),
        });
    }

    newOwner(poiGroupId: number): any {
        this.$router.push({
            name: "OwnersNew",
            params: { poiGroupId: `${poiGroupId}` },
        });
    }

    async changeCompany(poiGroupId: number): Promise<void> {
        // 店舗情報画面で選択していた店舗等の条件を消去する
        const ss = useSs();
        ss.selectedCondition = Conditions.Null;
        ss.selectedGroup = 0;
        ss.selectedStore = 0;
        // 企業情報等の各種情報を取得しないと画面を表示できないため待機する
        this.loading = true;
        await Promise.all([
            this.setCompany(poiGroupId),
            this.setAreas(poiGroupId),
            this.setAreaStores(poiGroupId),
            this.setStores(poiGroupId),
            this.setRoles(poiGroupId),
        ]);
        this.$router.push({
            name: "AdminStores",
            params: { poiGroupId: `${poiGroupId}` },
        });
    }

    async execAggregation(poiGroupId: number): Promise<void> {
        try {
            this.loading = true;
            const response = await requiredAuth<any>(
                "post",
                `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/execCalcScore`
            );
            if (response == null || response.data == null) {
                // 権限不足
            } else {
                this.addSnackbarMessages({
                    text: "集計処理を開始しました",
                    color: "success",
                });
                this.pageLoad();
            }
        } catch (e) {
            console.log(e);
            throw Error("集計処理の実行に失敗しました");
        } finally {
            this.isModalActive = false;
            this.loading = false;
        }
    }

    deleteConfirm(company: EntitiesCompany): void {
        this.modalProps = {
            propOrgId: company.poiGroupID,
            propOrgName: company.name,
            propCanDelete: false,
        };
        this.isModalActive = true;
    }

    async deleteCompany(poiGroupId: number): Promise<void> {
        try {
            this.loading = true;
            const response = await requiredAuth<EntitiesCompany>(
                "delete",
                `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}`,
                getOperationLogParams(this.$route, "delete", "companies")
            );
            if (response == null || response.data == null) {
                // 権限不足
            } else {
                this.addSnackbarMessages({
                    text: "企業を削除しました",
                    color: "success",
                });
                this.pageLoad();
            }
        } catch (e) {
            console.log(e);
            throw Error("企業の削除に失敗しました。");
        } finally {
            this.isModalActive = false;
            this.loading = false;
        }
    }

    searchCompanies(): void {
        // 検索キーワードで絞り込み
        let companies: Company[] = this.cachedCompanies;
        const searchWord = this.searchWord?.trim().toLowerCase();
        if (searchWord !== "") {
            companies = companies.filter((company) => {
                // 企業名でマッチするか
                if (company.name.toLowerCase().includes(searchWord)) {
                    return true;
                }

                // 対象検索ワードでマッチするか
                if (company.keywords?.some((word) => word.toLowerCase().includes(searchWord))) {
                    return true;
                }

                // 企業IDでマッチするか
                if (searchWord === company.poiGroupID.toString()) {
                    return true;
                }

                return false;
            });
        }

        // 未登録の企業表示有効の場合フィルタリングする
        if (!this.isAll) {
            companies = companies.filter((item) => item.isActive);
        }

        // 企業ID順にソートする
        companies.sort((a, b) => (a.poiGroupID > b.poiGroupID ? 1 : -1));
        this.companies = companies;
    }
}
export default toNative(Companies);
