// 下記を参考に作成

import Axios from "axios";

// https://www.hands-lab.com/tech/entry/5172.html
export const checkIfApplicationUpdated = async (): Promise<boolean> => {
    const host = location.host;

    // ローカル実行時はindex.html取得不可
    if (host.indexOf("localhost") == 0) {
        return false;
    }
    const path = location.pathname;

    // その他含めて共通
    const url = `https://${host}${path}`;
    return await Axios.get(url)
        .then((res) => {
            const html = res?.data ?? "";
            const hash = createHash(html);
            return isHashChanged(hash);
        })
        .catch((e) => {
            console.warn("[checkIfApplicationUpdated] Error", e);
            if (!e.response) {
                // ネットワークエラーは無視する
                return false;
            }
            // throw e;
        });
};

function createHash(str) {
    const len = str.length;
    let hash = 0;
    if (len === 0) return hash;
    let i;
    for (i = 0; i < len; i++) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
        hash |= 0;
    }
    return hash;
}

let previousHash = 0;
function isHashChanged(hash: number): boolean {
    if (previousHash === 0) {
        previousHash = hash;
        return false;
    }
    return previousHash !== hash;
}
