import type { AxiosError } from "axios";
import { useSnackbar } from "@/storepinia/snackbar";
import type { HttpMethod } from "@/helpers";
import { requiredAuth } from "@/helpers";
import { Component, Vue, toNative } from "vue-facing-decorator";
import type { ListGrid, ColumnDefine } from "cheetah-grid";

import type {
    EntitiesCompany,
    EntitiesStore,
    EntitiesKeywordRanking,
    EntitiesStorePostResponse,
    EntitiesStoresResponse,
    ControllersExecStoresinfoOutput,
} from "@/types/ls-api";
import wordDictionary from "@/word-dictionary";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { read, arrayBufferToCsv } from "@/helpers/xlsxtools";
import { ZoneId, DateTimeFormatter, ZonedDateTime } from "@js-joda/core";
import { parallels } from "@/helpers/parallel-promise";
import { action, getter, useIndexedDb } from "@/storepinia/idxdb";
import { currentTheme } from "@/components/shared/theme";
import type { CustomSortState } from "@/components/shared/cheetah-grid-shared";

const columns = [
    "gmbLocationID",
    "店舗ID",
    "店舗コード",
    "店舗名",
    "検索キーワード",
    "順位取得失敗キーワード数",
    "クチコミ分析",
    "投稿",
    "Facebook/Instagram連携",
    "Yahoo!プレイス連携",
    "店舗ページ構造化",
    "登録済/未登録",
];

type DefaultBgColorArgs = {
    row: number;
    col: number;
    grid: ListGrid<StoreView>;
};

// EntitiesStoreを保持して、表示用に変換する処理
export class StoreView {
    constructor(company: EntitiesCompany, store: EntitiesStore) {
        this.company = company;
        this.store = JSON.parse(JSON.stringify(store)); // storeのコピーを作成する
        this.update_origin_values();
    }

    private update_origin_values() {
        this.old_name = this.store.name;
        if (this.store.options === undefined) {
            this.store.options = [];
        }
        this.old_keyword = this.store.keywords?.join(",");
        this.old_review = this.store.options?.includes("review");
        this.old_post = this.store.options?.includes("post");
        this.old_citation = this.store.options?.includes("citation");
        this.old_yplace = this.store.options?.includes("yahooplace");
        this.old_structuredPage = this.store.options?.includes("structuredPage");
        this.old_enabled = this.store.enabled;
        this.failedCount = this.getFailedCount(this.company, this.store);
        this.failedInfo = this.getFailedInfo(
            this.company,
            this.old_keyword,
            this.store.keywordsRankings
        );
    }

    // DynamoDBへの適用が完了したあとで呼び出すことで、変更差分をなくす
    commit(): void {
        this.update_origin_values();
    }

    // 未登録の場合は全体を、登録済みの場合は変更差分だけを作ってput用のデータを返す
    get_post_data(): any {
        if (this.store.unregistered) {
            return this.store;
        }
        let ret = {};
        ret = { ...ret, poiID: this.store.poiID };
        if (this.change_keyword) {
            ret = { ...ret, keywords: this.store.keywords };
        }
        if (this.change_enabled) {
            ret = { ...ret, enabled: this.store.enabled };
        }
        if (
            this.change_review ||
            this.change_citation ||
            this.change_yplace ||
            this.change_post ||
            this.change_structuredPage
        ) {
            const options: string[] = [];
            if (this.review) {
                options.push("review");
            }
            if (this.citation) {
                options.push("citation");
            }
            if (this.yplace) {
                options.push("yahooplace");
            }
            if (this.post) {
                options.push("post");
            }
            if (this.structuredPage) {
                options.push("structuredPage");
            }
            ret = { ...ret, options: options };
        }
        return ret;
    }

    private getFailedCount(company: EntitiesCompany, store: EntitiesStore): number {
        if ((store.keywordsRankings ?? []).length === 0) {
            return 0;
        }
        let failedCount = 0;
        for (const companyKeyword of company.keywords ?? []) {
            for (const storeKeyword of store.keywords ?? []) {
                if (
                    this.getKeywordRanking(
                        companyKeyword,
                        storeKeyword,
                        store.keywordsRankings
                    ).startsWith("【エラー】")
                ) {
                    failedCount++;
                }
            }
        }
        return failedCount;
    }

    private getKeywordRanking(
        companyKeyword: string,
        storeKeyword: string,
        rankings: EntitiesKeywordRanking[]
    ): string {
        for (const r of rankings ?? []) {
            if (r.companyKeyword === companyKeyword && r.storeKeyword === storeKeyword) {
                switch (r.ranking) {
                    case -1:
                        return "圏外";
                    case 0:
                        return "【エラー】" + r.errorReason;
                    default:
                        return r.ranking + "位";
                }
            }
        }
        return "未取得";
    }

    private getFailedInfo(
        company: EntitiesCompany,
        storeKeyword: string,
        keywordsRankings: EntitiesKeywordRanking[]
    ): string[] {
        const items: string[] = [];
        if ((keywordsRankings ?? []).length === 0) {
            items.push(`「${storeKeyword}」では検索キーワード別スコアランキングを未取得`);
            return items;
        }

        const companyKeywords = company.keywords ?? [];
        for (const companyKeyword of companyKeywords) {
            items.push(
                `${companyKeyword} × ${storeKeyword}: ${this.getKeywordRanking(
                    companyKeyword,
                    storeKeyword,
                    keywordsRankings
                )}\n`
            );
        }
        items.push(
            `最終取得日時: ${this.getKeywordRankingCollectedAt(storeKeyword, keywordsRankings)}`
        );
        return items;
    }

    private utcToJst(utc: string): string {
        if (utc == null || utc === "") {
            return "";
        } else {
            return ZonedDateTime.parse(utc)
                .withZoneSameInstant(ZoneId.of("Asia/Tokyo"))
                .format(DateTimeFormatter.ofPattern("yyyy/MM/dd HH:mm:ss"));
        }
    }

    private getKeywordRankingCollectedAt(
        storeKeyword: string,
        rankings: EntitiesKeywordRanking[]
    ): string {
        for (const r of rankings ?? []) {
            if (r.storeKeyword === storeKeyword && r.ranking > 0) {
                // "2020-07-08T08:47:44" (UTC)
                return this.utcToJst(r.collectedAt);
            }
        }
        return "未取得";
    }

    private company: EntitiesCompany;
    private store: EntitiesStore;

    get storeData(): EntitiesStore {
        return this.store;
    }

    get poiID(): number {
        return this.store.poiID;
    }

    get gmbStoreCode(): string {
        return this.store.gmbStoreCode;
    }

    private old_keyword: string;
    get keyword(): string {
        return this.store.keywords?.join(",");
    }
    set keyword(value: string) {
        this.store.keywords = value.split(",");
    }
    get change_keyword(): boolean {
        const keyword = this.store.keywords?.join(",");
        const old_keyword = this.old_keyword === undefined ? "" : this.old_keyword;
        return (keyword === undefined ? "" : keyword) !== old_keyword;
    }

    failedCount: number;
    get failed_count(): number {
        return this.failedCount;
    }
    private failedInfo: string[];
    get failed_info(): string[] {
        return this.failedInfo;
    }

    private old_name: string;
    get name(): string {
        return this.store.name;
    }
    set name(value: string) {
        this.store.name = value;
    }
    get change_name(): boolean {
        return this.old_name !== this.store.name;
    }

    private convert(value: boolean | undefined): boolean {
        if (value === undefined) {
            return false;
        }
        return value;
    }

    private old_review: boolean;
    get review(): boolean {
        return this.convert(this.store.options?.includes("review"));
    }
    set review(value: boolean) {
        if (value) {
            if (!this.store.options?.includes("review")) {
                this.store.options?.push("review");
            }
        } else {
            this.store.options = this.store.options?.filter((x) => x !== "review");
        }
    }
    get change_review(): boolean {
        return this.old_review !== this.store.options?.includes("review");
    }

    private old_post: boolean;
    get post(): boolean {
        return this.convert(this.store.options?.includes("post"));
    }
    set post(value: boolean) {
        if (value) {
            if (!this.store.options?.includes("post")) {
                this.store.options?.push("post");
            }
        } else {
            this.store.options = this.store.options?.filter((x) => x !== "post");
        }
    }
    get change_post(): boolean {
        return this.old_post !== this.store.options?.includes("post");
    }

    private old_citation: boolean;
    get citation(): boolean {
        return this.convert(this.store.options?.includes("citation"));
    }
    set citation(value: boolean) {
        if (value) {
            if (!this.store.options?.includes("citation")) {
                this.store.options?.push("citation");
            }
        } else {
            this.store.options = this.store.options?.filter((x) => x !== "citation");
        }
    }
    get change_citation(): boolean {
        return this.old_citation !== this.store.options?.includes("citation");
    }

    private old_yplace: boolean;
    get yplace(): boolean {
        return this.convert(this.store.options?.includes("yahooplace"));
    }
    set yplace(value: boolean) {
        if (value) {
            if (!this.store.options?.includes("yahooplace")) {
                this.store.options?.push("yahooplace");
            }
        } else {
            this.store.options = this.store.options?.filter((x) => x !== "yahooplace");
        }
    }
    get change_yplace(): boolean {
        return this.old_yplace !== this.store.options?.includes("yahooplace");
    }

    private old_structuredPage: boolean;
    get structuredPage(): boolean {
        return this.convert(this.store.options?.includes("structuredPage"));
    }
    set structuredPage(value: boolean) {
        if (value) {
            if (!this.store.options?.includes("structuredPage")) {
                this.store.options?.push("structuredPage");
            }
        } else {
            this.store.options = this.store.options?.filter((x) => x !== "structuredPage");
        }
    }
    get change_structuredPage(): boolean {
        return this.old_structuredPage !== this.store.options?.includes("structuredPage");
    }

    private old_enabled: boolean;
    get enabled(): boolean {
        return this.convert(this.store.enabled);
    }
    set enabled(value: boolean) {
        this.store.enabled = value;
    }
    get change_enabled(): boolean {
        return this.convert(this.old_enabled) != this.convert(this.store.enabled);
    }

    // なにか変更が発生している場合にtrueを返す
    get is_change(): boolean {
        return (
            this.change_name ||
            this.change_keyword ||
            this.change_review ||
            this.change_post ||
            this.change_citation ||
            this.change_yplace ||
            this.change_structuredPage ||
            this.change_enabled
        );
    }
}

@Component({})
class Stores extends Vue {
    initialized: boolean = false;
    loading: boolean = false;
    dict = wordDictionary.adminStores;
    currentPage: number = 1;
    lastPage: number = 1;
    perPage: number = 50;
    poiGroupId: string;
    orgId: number = 0;
    poiName: string;
    planTagCode: string = "";
    optionTagCode: string = "";
    checkedRows = [];
    errMessage: string;
    tour: any;
    onTutorial: boolean = false;
    isModalActive: boolean = false;
    xlsxFile: File = null;
    dialog = {
        show: false,
        percentage: 0,
        title: "",
        message: "",
        errorCause: "",
        errorCode: "",
        cancelButton: "",
        acceptButton: "",
        acceptAction: "",
    };
    searchWord = "";

    //
    storesInfoShow: boolean = false;
    storesInfoTitle: string = "";
    storesInfoDetails: string[] = [];

    isShowAllStores = getter().isShowAllStores;
    company = getter().company;
    stores = getter().stores;
    setIsShowAllStores = action().setIsShowAllStores;
    setStores = action().setStores;
    setAreaStores = action().setAreaStores;
    addSnackbarMessages = useSnackbar().addSnackbarMessages;

    gridFontSize = 12.8;
    gridFont = `${this.gridFontSize}px sans-serif`;
    // cheetah-gridのスクロールバーだけ表示したいのでbodyのを消す
    mounted(): void {
        document.body.style.overflow = "hidden";
    }
    // ページ離脱時bodyのスクロールバーを元に戻しとく
    unmounted(): void {
        document.body.style.overflow = "initial";
    }

    // cheetah-gridのカスタムテーマ設定（変更したセルの背景を水色に変えている）
    customTheme = {
        checkbox: {
            borderColor: currentTheme().vuetifyTheme.colors.primary,
        },
        defaultBgColor({ col, row, grid }: DefaultBgColorArgs): string {
            if (col < grid.frozenColCount || row < grid.frozenRowCount) {
                return "#f0f0f0";
            }
            const hdr = grid.header[col] as ColumnDefine<StoreView>;
            const rec = grid.dataSource.source[row - 1];

            switch (hdr.field) {
                case "keyword": {
                    // 検索キーワードの比較
                    return rec.change_keyword ? "#aaffff" : "#ffffff";
                }
                case "failedCount": {
                    // 順位取得失敗キーワード数
                    return rec.failed_count > 0 ? "#ff7f50" : "#ffffff";
                }
            }
            return rec["change_" + hdr.field] ? "#aaffff" : "#ffffff";
        },
    };

    // cheetah-gridでキーワード入力のバリデーションを実行
    keywordValidator(value: string): string {
        if (value.indexOf(",") >= 0) {
            return "検索キーワードに , は設定できません";
        }
        return "";
    }

    // すべての店舗情報を保持
    rawStoreRecords: StoreView[] = [];
    // 検索キーワード等で絞り込まれた店舗情報を保持
    storeRecords: StoreView[] = [];
    // 順位取得失敗キーワードをクリックされたイベント
    failedClick(rec: StoreView): void {
        this.storesInfoShow = true;
        this.storesInfoTitle = `順位取得失敗(poiID=${rec.poiID} ${rec.name})`;
        this.storesInfoDetails = rec.failed_info;
    }
    // 順位取得失敗が存在するかを返す
    keywordProblem(rec: StoreView): boolean {
        return rec.failed_count === 0;
    }

    // 直前に行ったソート順と対象列の保存
    private customSortState: CustomSortState = {
        col: 0,
        order: null,
    };
    // cheetah-gridヘッダ部をクリックされたイベント
    sortColumn(order: number, col: number, grid: ListGrid<StoreView>): void {
        if (col !== this.customSortState.col) {
            // 直前のと違う列をソートしようとした場合はorderを初期化する
            this.customSortState.order = "desc";
            this.customSortState.col = col;
        } else {
            switch (this.customSortState.order) {
                case null:
                    this.customSortState.order = "desc";
                    break;
                case "desc":
                    this.customSortState.order = "asc";
                    break;
                case "asc":
                    this.customSortState.order = null;
            }
        }
        // 実際にデータをソートする
        this.sortRecord(col, this.customSortState.order);
        // ↑↓マーク制御
        grid.sortState.order = this.customSortState.order;
    }
    // クリックされたカラムでソート処理を実行
    private sortRecord(col: number, order: string | null = "asc"): void {
        let orderVal: number = 1;
        // カラムの並び順
        const keys: string[] = [
            "poiID",
            "gmbStoreCode",
            "name",
            "keyword",
            "failedCount",
            "review",
            "post",
            "citation",
            "yplace",
            "structuredPage",
            "enabled",
        ];
        let key = keys[col];
        if (order === null) {
            // 昇りでも降りでもない場合は初期の並び順であるpoiIDの昇り順でのソートになる
            key = keys[0];
        } else if (order === "asc") {
            orderVal = -1;
        }
        this.rawStoreRecords = this.rawStoreRecords.sort((a, b) => {
            const aDash: string | number | boolean = a[key];
            const bDash: string | number | boolean = b[key];
            if (aDash > bDash) {
                return 1 * orderVal;
            } else {
                return -1 * orderVal;
            }
        });
        this.searchStores();
    }

    async created(): Promise<void> {
        this.poiGroupId = this.$route.params.poiGroupId as string;
        this.loadingStores();
    }

    async loadingStores(): Promise<void> {
        if (this.stores?.stores) {
            this.rawStoreRecords = this.stores.stores?.map((x) => new StoreView(this.company, x));
        } else {
            const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
                this.poiGroupId
            }/stores`;
            await this.fetchData(url);
        }
        this.searchStores();
    }

    async fetchData(url: string): Promise<void> {
        this.loading = true;
        try {
            const response = await requiredAuth<EntitiesStoresResponse>("get", url);
            if (response == null || response.data == null) {
                this.rawStoreRecords = [];
            } else {
                this.rawStoreRecords = response.data.stores?.map(
                    (x) => new StoreView(this.company, x)
                );
            }
        } catch (e) {
            console.log(e);
            this.showError("店舗情報の検索に失敗しました");
        } finally {
            this.loading = false;
        }
    }

    // 変更部分をDynamoDBへ一括反映
    async syncStoresSetting(): Promise<void> {
        // 変更を行っている店舗一覧を抽出する
        const stores = this.storeRecords.filter((rec) => rec.is_change);

        // ダイアログを表示
        this.dialog.show = true;
        let count = 0;
        this.dialog.title = "変更内容を反映中";
        this.dialog.errorCode = "";
        this.dialog.errorCause = "";
        this.dialog.acceptButton = "";
        this.dialog.cancelButton = "";
        this.dialog.acceptAction = "";
        this.dialog.percentage = 0;

        const post_url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
            this.poiGroupId
        }/stores`;
        const put_url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
            this.poiGroupId
        }/stores/`;

        try {
            const ps = parallels();
            const failedRows: StoreView[] = [];
            for (const store of stores) {
                this.dialog.message = `${store.name}、他${ps.size()}店舗 / ${
                    stores.length
                }店舗 を更新中`;
                // 新規登録 or 編集
                ps.add(
                    this.request(
                        store.storeData.unregistered ? "post" : "put",
                        store.storeData.unregistered ? post_url : `${put_url}${store.poiID}`,
                        store
                    ).then((status) => {
                        if (status === 200) {
                            count++;
                            this.dialog.percentage = (count / stores.length) * 100; // プログレスバーを進捗させる
                        } else {
                            console.log("失敗検出", status);
                            failedRows.push(store);
                        }
                    })
                );
                await ps.race(10); // 10件づつまとめて並列実行
            }
            await ps.all(); // 余った分を実行

            this.setStores(parseInt(this.poiGroupId, 10));
            this.setAreaStores(parseInt(this.poiGroupId, 10));
            if (failedRows.length > 0) {
                this.dialog.message = `${failedRows.length}店舗 更新に失敗しました（${failedRows
                    .map((r) => r.poiID)
                    .join(", ")}）`;
                this.dialog.acceptButton = "OK";
                this.dialog.acceptAction = "";
            } else {
                // すべてのデータ更新が完了したので、店舗ページの再作成(店舗情報構造化を含む)を実行
                requiredAuth<ControllersExecStoresinfoOutput>(
                    "post",
                    `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
                        this.poiGroupId
                    }/storesinfo/execStoresinfo`
                )
                    .then((res) => {
                        if (res.status !== 200) {
                            // 失敗しても ECS Taskが起動できなかっただけで、後から自動実行されるので無視しておく
                            console.log("execStoresinfo 実行失敗", res);
                        }
                    })
                    .catch((e) => {
                        // 失敗しても ECS Taskが起動できなかっただけで、後から自動実行されるので無視しておく
                        console.log("execStoresinfo exception catch", e);
                    })
                    .finally(() => {
                        this.dialog.percentage = 100;
                        this.dialog.message = `${stores.length} 件 更新が完了しました`;
                        this.dialog.acceptButton = "OK";
                        this.dialog.acceptAction = "";
                    });
            }
        } catch (e) {
            console.log(e);
            this.showError("店舗情報一覧の更新に失敗しました。");
        }
        // storeRecordsの中を修正した場合は、cheetah-gridに変更が合ったことを通知して再描画させる必要がある
        (this.$refs.cgrid as ListGrid<StoreView>).invalidate();
    }

    // １店舗づつ更新するための処理
    async request(method: HttpMethod, url: string, store: StoreView): Promise<number> {
        const retryMax = 1;

        const param = { structuredUpdate: 0 };
        let response: EntitiesStorePostResponse = null;
        let error: AxiosError = null;
        this.loading = true;
        try {
            const data = store.get_post_data();
            for (let retryCount = 0; retryCount <= retryMax; retryCount++) {
                await requiredAuth<EntitiesStorePostResponse>(method, url, param, data)
                    .then((res) => {
                        response = res.data;
                    })
                    .catch((e: AxiosError) => {
                        error = e;
                        console.log(e);
                    });
                if (response !== null) {
                    break;
                }
            }

            if (response === null) {
                return error.response?.status;
            }

            // 新規登録の場合（現在は存在しないはず）
            if (response.poiID !== -1) {
                store.storeData.unregistered = false;
                store.storeData.poiID = response.poiID;
            }
            // 差分をクリアして、差分なし状態へ
            store.commit();
            return 200;
        } finally {
            this.loading = false;
        }
    }

    get isAll(): boolean {
        return this.isShowAllStores;
    }
    set isAll(show: boolean) {
        this.setIsShowAllStores(show);
        this.searchStores();
    }

    async importFile(accept: boolean): Promise<void> {
        if (!this.xlsxFile) {
            return;
        }
        // xlsx読み取り
        const file: File = this.xlsxFile as any;
        let fields: string[];
        let rows: any[];
        try {
            const buf = await read(file);
            this.xlsxFile = null;
            [fields, rows] = arrayBufferToCsv(buf);
            if (fields.length === 0) {
                this.showError(`XLSXに ヘッダ行 がありません`);
                return;
            }
        } catch (e) {
            console.log(e);
            this.showError(`XLSXの読み込みに失敗しました`);
            return;
        }
        // XLSXのカラム(fields)とcolumnsが合致しなかったらエラーとする
        for (const column of columns) {
            if (fields.includes(column) === false) {
                this.showError(`XLSXに ${column} がありません`);
                return;
            }
        }
        // poiIDがresultsに存在するものかチェックする
        const results = this.rawStoreRecords;
        for (const row of rows) {
            const poiID = row[columns[1]];
            if (!poiID) continue; // poiIDが空の場合はその行は無視する
            if (!results.find((r) => `${r.storeData.poiID}` === poiID)) {
                this.showError(
                    `XLSXに不明な店舗IDがあります: ${poiID}\n` +
                        "「未登録の店舗も表示する」にチェックを入れてください"
                );
                return;
            }
            // クチコミ分析 から 登録済/未登録 までが 空文字か"ON"でなければエラーとみなす
            for (let i = 6; i < columns.length; i++) {
                if (row[columns[i]] !== "" && row[columns[i]] !== "ON") {
                    this.showError(`${columns[i]}はフラグ行です。空文字かONで記載してください`);
                    return;
                }
            }
        }

        // 更新された行をテーブルに反映する
        for (const row of rows) {
            const poiID = row[columns[1]];
            const result = results.find((r) => `${r.storeData.poiID}` === poiID);
            const keywords: string = row[columns[4]] ?? "";
            const review: boolean = row[columns[6]] === "ON";
            const post: boolean = row[columns[7]] === "ON";
            const citation: boolean = row[columns[8]] === "ON";
            const yplace: boolean = row[columns[9]] === "ON";
            const structuredPage: boolean = row[columns[10]] === "ON";
            const enabled: boolean = row[columns[11]] === "ON";
            // 変更されていない行は更新しない
            if (
                keywords === result.keyword &&
                result.review === review &&
                result.post === post &&
                result.citation === citation &&
                result.yplace === yplace &&
                result.structuredPage === structuredPage &&
                result.enabled === enabled
            ) {
                continue;
            }
            result.keyword = keywords;
            result.review = review;
            result.post = post;
            result.citation = citation;
            result.yplace = yplace;
            result.structuredPage = structuredPage;
            result.enabled = enabled;
        }
        // storeRecordsの中を修正した場合は、cheetah-gridに変更が合ったことを通知して再描画させる必要がある
        (this.$refs.cgrid as ListGrid<StoreView>).invalidate();
    }

    exportFile(): void {
        const companyName = useIndexedDb().company.name;
        const results = this.rawStoreRecords;
        // Sheet.js を用いて xlsx ファイルを作成する
        const aoa = []; // Array of arrays
        aoa.push(columns);
        for (const row of results) {
            const name = row.name ?? "";
            // キーワードが定義されていないときは店舗名から"企業名","店"を取り除いたものを出力
            const defaultKeyword = name.split(companyName).join("").split("店").join("").trim();
            const arr = [];
            arr.push(row.storeData.gmbLocationID ?? "");
            arr.push(row.poiID ?? "-1");
            arr.push(row.gmbStoreCode);
            arr.push(name);
            arr.push(row.keyword ?? defaultKeyword);
            arr.push(row.failed_count);
            arr.push(row.review ?? false ? "ON" : "");
            arr.push(row.post ?? false ? "ON" : "");
            arr.push(row.citation ?? false ? "ON" : "");
            arr.push(row.yplace ?? false ? "ON" : "");
            arr.push(row.structuredPage ?? false ? "ON" : "");
            arr.push(row.enabled ?? false ? "ON" : "");
            aoa.push(arr);
        }
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(aoa);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const buf: ArrayBuffer = XLSX.write(wb, {
            type: "array",
            bookType: "xlsx",
            bookSST: true,
        });
        const datetime = dayjs().format("YYYYMMDD");
        saveAs(
            new Blob([buf], { type: "application/octet-stream" }),
            `${wordDictionary.service.name}-店舗一覧エクスポート-${companyName}-${datetime}.xlsx`
        );
    }

    setKeywords(): void {
        this.$router.push({ name: "StoreKeywords" });
    }

    searchStores(): void {
        const storeRecords: StoreView[] | null = filterBySearchWord(
            this.rawStoreRecords,
            this.searchWord,
            this.isShowAllStores
        );
        if (storeRecords === null) {
            this.addSnackbarMessages({
                text: "不正な検索キーワードです",
                color: "danger",
            });
            return;
        }
        this.storeRecords = storeRecords;
        // この変数でcheetah-gridの遅延レンダリングを行っている
        // 遅延させないと、左のメニュー領域分の計算が狂ってスクロールバーが表示されてしまう
        this.initialized = true;
    }

    showError(message: string): void {
        this.dialog.message = message;
        this.dialog.acceptButton = "";
        this.dialog.cancelButton = "キャンセル";
        this.dialog.acceptAction = "";
        this.dialog.show = true;
    }

    /** GBP店舗同期ダイアログ出す */
    showSyncGMB(): void {
        // ダイアログを表示
        this.dialog.show = true;
        this.dialog.title = "GBP店舗同期";
        this.dialog.errorCode = "";
        this.dialog.errorCause = "";
        this.dialog.acceptButton = "OK";
        this.dialog.cancelButton = "キャンセル";
        this.dialog.message =
            "GBP上の店舗情報をチェックしてトストア側に同期させる処理を手動実行します（毎日21時に起動する処理です）。GBP上にあってトストア側にない店舗があれば未登録状態で追加します。";
        this.dialog.percentage = 0;
        this.dialog.acceptAction = "syncGMB";
    }

    // 店舗情報のDynamoDBへの動機ダイアログを出す
    showSyncStoresSetting(): void {
        if (this.storeRecords.find((rec) => rec.is_change) === undefined) {
            // 変更が無い
            this.showError("変更が有りませんでした");
            return;
        }
        // ダイアログを表示
        this.dialog.show = true;
        this.dialog.title = "店舗設定編集同期";
        this.dialog.errorCode = "";
        this.dialog.errorCause = "";
        this.dialog.acceptButton = "OK";
        this.dialog.cancelButton = "キャンセル";
        this.dialog.message = "編集した設定値をトストアに反映します";
        this.dialog.percentage = 0;
        this.dialog.acceptAction = "syncStoresSetting";
    }

    async acceptMatter(): Promise<void> {
        this.dialog.show = false;
        // acceptAction=true GBP店舗同期ダイアログのOKボタンによるイベント発火か否か
        switch (this.dialog.acceptAction) {
            case "syncGMB": {
                try {
                    await requiredAuth<any>(
                        "post",
                        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
                            this.poiGroupId
                        }/sync_gmb_stores`,
                        { noCache: 1 }
                    );
                    this.addSnackbarMessages({
                        text: "同期処理を開始しました。終了までしばらくお待ち下さい。",
                        color: "success",
                    });
                } catch (error) {
                    console.log("[Error] acceptMatter", (error as AxiosError).response);
                    this.dialog.show = true;
                    this.dialog.title = "GBP店舗同期エラー ";
                    this.dialog.errorCode = (error as AxiosError).response?.status.toString();
                    this.dialog.message =
                        "エラーが発生しました。お手数ですが担当者までお問い合わせください。";
                    this.dialog.errorCause =
                        (error as AxiosError<any>).response?.data?.errorMessage ?? "想定外のエラー";
                    this.dialog.acceptAction = "";
                    this.dialog.acceptButton = "閉じる";
                    this.dialog.cancelButton = "";
                }
                break;
            }
            case "syncStoresSetting": {
                this.syncStoresSetting();
                break;
            }
        }
    }
}
export default toNative(Stores);

export function filterBySearchWord(
    rawStoreRecords: StoreView[],
    searchWord: string,
    showAll: boolean
): StoreView[] | null {
    let records: StoreView[] = [];
    const trimmedSearchWord = searchWord?.trim().toLowerCase();
    if (trimmedSearchWord && trimmedSearchWord !== "") {
        records = rawStoreRecords.filter((store) => {
            if (store.name.toLowerCase().includes(trimmedSearchWord)) {
                return true;
            }
            if (store.keyword?.toLowerCase().includes(trimmedSearchWord)) {
                return true;
            }
            if (store.gmbStoreCode?.toLowerCase().includes(trimmedSearchWord)) {
                return true;
            }
            const poiID = store.poiID.toString();
            if (poiID.includes(trimmedSearchWord) || trimmedSearchWord.includes(poiID)) {
                return true;
            }
            return false;
        });
    } else {
        records = rawStoreRecords;
    }

    if (!showAll) {
        records = records.filter((r) => r.enabled === true);
    }
    return records;
}
