<!-- 承認・却下確認ダイアログ -->
<template>
  <v-dialog v-model="openModal" persistent width="800" @input="$emit('input', $event)">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">{{ title }}</v-card-title>

      <v-card-text align="center" class="text--primary" style="padding-top: 0.5rem">
        <p>{{ message }}</p>
      </v-card-text>

      <v-card-text v-if="cautions.length > 0" class="text--primary">
        <p>以下の事項にご注意ください。</p>
        <li v-for="cautionMessage of cautions" :key="cautionMessage">{{ cautionMessage }}</li>
      </v-card-text>

      <v-divider></v-divider>

      <v-form v-model="valid" @submit.prevent="submit">
        <v-card-actions>
          <v-text-field
            v-model="editRemark"
            :label="remarkRequired ? '備考(必須)' : '備考'"
            :required="remarkRequired"
            clearable
            class="mx-2"
            color="primary"
            :rules="remarkRequired ? [rules] : []"
          ></v-text-field>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="outlined" class="cancel-button mr-3" @click.stop="$emit('close-dialog')">
            キャンセル
          </v-btn>
          <v-btn color="primary" class="px-8" variant="flat" type="submit" :disabled="!valid">
            OK
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    remarkRequired: { type: Boolean, required: false },
    title: { type: String, required: true },
    message: { type: String, required: true },
    cautions: { type: Array<string>, default: [] },
    open: { type: Boolean, required: true, default: false },
    remark: { type: String, required: true },
  },
  emits: ["close-dialog", "submit", "input"],
  data() {
    return {
      editRemark: this.remark,
      valid: false,
    };
  },
  computed: {
    openModal: {
      get() {
        return this.open;
      },
      set(value: boolean) {
        this.$emit("input", value);
      },
    },
  },
  created() {
    this.editRemark = this.remark;
  },
  methods: {
    rules(value: string): string | null {
      return !value ? "備考の入力は必須です" : null;
    },
    submit(): void {
      this.$emit("submit", this.editRemark);
    },
  },
});
</script>
<style lang="scss" scoped>
.cancel-button {
  border-color: #808080 !important;
}

.error-message {
  color: red;
  margin-bottom: 0;
  margin-top: 0.25rem;
}
</style>
