<!-- 本来的にはステップごとに「確定」して画面を切り替えていくとわかりやすいが、ひとまずデザインを優先する -->
<template>
  <v-progress-linear
    v-if="isMobile"
    v-model="progressRate"
    bg-color="#C6C6C6"
    color="#4ad4be"
    height="25"
  >
    <template #default="{ value }">
      <strong>{{ Math.ceil(value) }}%</strong>
    </template>
  </v-progress-linear>
  <v-stepper
    v-model="postStep"
    non-linear
    :alt-labels="!isMobile"
    :mobile="isMobile"
    :class="isMobile ? 'mb-1' : ''"
  >
    <v-stepper-header>
      <template v-for="(step, name, index) in postSteps" :key="step.index">
        <v-stepper-item
          :value="step.index"
          :error="step.errorMessage !== ''"
          error-icon="mdi-alert"
          :step="step.index"
          :complete="step.isComplete"
          :title="step.name"
          :subtitle="step.errorMessage"
          :style="{ 'font-size': '14px' }"
          editable
          :edit-icon="'mdi-numeric-' + step.index"
          :class="isMobile ? 'pa-1' : ''"
          @click="scroll(name)"
        />
        <v-divider v-if="index < Object.keys(postSteps).length - 1 && !isMobile" />
      </template>
    </v-stepper-header>
    <v-card v-if="!isMobile" class="pa-3 mb-1">
      投稿名:
      <b>{{ postName }}</b>
    </v-card>
  </v-stepper>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export class StepStatus {
  name: string = ""; // ステップ名
  index: number = 0;
  enabled?: boolean = true; // ステップが有効かどうか
  isComplete?: boolean = false; // ステップが完了したか
  errorMessage?: string = ""; // ステップ内のエラーメッセージ
  childStep?: StepStatus[] = []; // 子ステップ

  constructor(init?: Partial<StepStatus>) {
    Object.assign(this, init);
  }
}

export class PostSteps {
  PostName: StepStatus;
  TargetSelect: StepStatus;
  FileUpload: StepStatus;
  ImageSelect: StepStatus;
  PostText: StepStatus;
  PostCustomize: StepStatus;
  PostExecute: StepStatus;

  constructor(init?: Partial<PostSteps>) {
    Object.assign(this, init);
  }
}

export default defineComponent({
  components: {},
  props: {
    postSteps: { type: Object as () => PostSteps, required: true },
    postName: { type: String, required: true },
    isMobile: { type: Boolean, required: true },
  },
  emits: ["scrollToStep"],
  data() {
    return {
      postStep: 1,
    };
  },
  computed: {
    progressRate() {
      // 進捗率に関係のないステップを除外
      const skipSteps = ["PostExecute"];
      const filteredSteps = Object.entries(this.postSteps)
        .filter(([key, step]) => !skipSteps.includes(key))
        .map(([key, step]) => step);
      const completedSteps = Object.values(filteredSteps).filter((step) => step.isComplete).length;
      const totalSteps = Object.keys(filteredSteps).length;
      return (completedSteps / totalSteps) * 100;
    },
  },
  watch: {
    postSteps: {
      deep: true,
      handler(): void {
        for (const [_, step] of Object.entries(this.postSteps)) {
          if (step.isComplete === false) {
            this.postStep = step.index;
            return;
          }
        }
      },
    },
  },
  methods: {
    scroll(stepName: string) {
      this.$emit("scrollToStep", stepName);
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;
:deep(.v-stepper-item .v-avatar) {
  background-color: #151515 !important;
  color: white;
}
:deep(.v-stepper-item.v-stepper-item--selected .v-avatar) {
  background-color: rgb(var(--v-theme-primary)) !important;
}
:deep(.v-stepper-item.v-stepper-item--complete .v-avatar) {
  background-color: #4ad4be !important;
}
:deep(.v-stepper-item.v-stepper-item--error .v-avatar) {
  background-color: transparent !important;
  .v-icon {
    color: rgb(var(--v-theme-error)) !important;
    font-size: 20px;
  }
}
:deep(.v-stepper-item .v-avatar) {
  font-weight: 600;
  font-size: 0.9em;
  .v-icon {
    font-size: 24px;
  }
}
</style>
