export enum PermissionType {
    CRUDAllowed = "操作可（承認/要求権限なし）",
    CRUDWithApproval = "操作可（承認権限あり）",
    CRUDWithApprovalRequest = "操作可（承認要求権限あり）",
    ReadOnly = "閲覧のみ可",
    AllDenied = "閲覧/操作不可",
    Unknown = "不明",
}

export const ViewRange = {
    1: "全企業",
    2: "全企業",
    3: "企業全体",
    4: "グループ単位",
    5: "店舗単位",
};
