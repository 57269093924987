const recipientPlaceholder = "{{{クチコミ投稿者名}}}";
const businessNamePlaceholder = "{{{ビジネス名}}}";
const targetPart = "（該当箇所）";

// 入力内容に応じて注意書きを生成
export function getDescription(template: string): string {
    let description = "";
    if (template.includes(recipientPlaceholder) || template === "")
        description += `${recipientPlaceholder}：クチコミ投稿者名に変換されます。\n`;
    if (template.includes(businessNamePlaceholder) || template === "")
        description += `${businessNamePlaceholder}：返信対象のGBPビジネス名に変換されます。\n`;
    if (template.includes(targetPart))
        description += `${targetPart}：自動挿入はされないため、ご自分で修正してください。\n`;
    return description.trimEnd();
}

export type ReplyTemplate = {
    feeling: "positive" | "negative";
    name: string;
    text: string;
};
export const replyTemplates: ReplyTemplate[] = [
    {
        feeling: "positive",
        name: "コメント無",
        text: `${recipientPlaceholder}様\nこの度は${businessNamePlaceholder}にご来店いただき誠にありがとうございました。\nまた、ご評価いただき重ねて感謝申し上げます。\n\nまたのご来店を心よりお待ちしております。`,
    },
    {
        feeling: "positive",
        name: "コメント有",
        text: `${recipientPlaceholder}様\nこの度は${businessNamePlaceholder}にご来店いただき誠にありがとうございました。\nまた、貴重なご意見につきましてもご共有いただき重ねて感謝申し上げます。\n\nまたのご来店を心よりお待ちしております。`,
    },

    {
        feeling: "negative",
        name: "コメント無",
        text: `${recipientPlaceholder}様\nこの度は${businessNamePlaceholder}にご来店いただき誠にありがとうございました。\nまた、ご評価をいただき重ねて感謝申し上げます。\n\n引き続きお気づきの点はお気軽にご指摘いただけますと幸いです。\nまたのご来店をお待ちしております。`,
    },
    {
        feeling: "negative",
        name: "誤情報",
        text: `${recipientPlaceholder}様\nこの度は${businessNamePlaceholder}にご来店いただき誠にありがとうございました。\nせっかくのご利用にもかかわらず、お客様にご不便をおかけしてしまい大変申し訳ございません。\n\n${targetPart}につきましては、店舗情報を改めて更新いたしました。\n今後とも何卒よろしくお願いいたします。`,
    },
    {
        feeling: "negative",
        name: "接客",
        text: `${recipientPlaceholder}様\nこの度は${businessNamePlaceholder}にご来店いただき誠にありがとうございました。\n\nせっかくのご利用にもかかわらず、スタッフの対応により御気分を害してしまい大変申し訳ございません。\nお客様に安心して気持ちよくご利用いただけますよう改善に努めてまいります。\n\n今後とも何卒よろしくお願いいたします。`,
    },
    {
        feeling: "negative",
        name: "待ち時間",
        text: `${recipientPlaceholder}様\nこの度は${businessNamePlaceholder}にご来店いただき誠にありがとうございました。\n\nせっかくのご利用にもかかわらず、お客様の貴重なお時間頂戴してしまい大変申し訳ございません。\nできる限りスムーズにご対応できるよう改善に努めてまいります。\n\n今後とも何卒よろしくお願いいたします。`,
    },
    {
        feeling: "negative",
        name: "清潔感",
        text: `${recipientPlaceholder}様\nこの度は${businessNamePlaceholder}にご来店いただき誠にありがとうございました。\n\nせっかくのご利用にもかかわらず、お客様の御気分を害してしまい大変申し訳ございません。\n安心して気持ちよくご利用いただけますよう、店内清掃をより一層徹底してまいります。\n\n今後とも何卒よろしくお願いいたします。`,
    },
    {
        feeling: "negative",
        name: "立地",
        text: `${recipientPlaceholder}様\nこの度は${businessNamePlaceholder}にご来店いただき誠にありがとうございました。\n\nせっかくのご利用にもかかわらず、ご不便をおかけしてしまい大変申し訳ございません。\nお客様に快適にお過ごしいただけるよう、改善できる点はないか協議・検討を進めてまいります。\n\n今後とも何卒よろしくお願いいたします。`,
    },
    {
        feeling: "negative",
        name: "設備",
        text: `${recipientPlaceholder}様\nこの度は${businessNamePlaceholder}にご来店いただき誠にありがとうございました。\n\nせっかくのご利用にもかかわらず、お客様にご不便おかけしてしまい大変申し訳ございません。\n${targetPart}につきましては、今後改善余地がないか検討してまいります。\n\n今後とも何卒よろしくお願いいたします。`,
    },
    {
        feeling: "negative",
        name: "設備（安全性）",
        text: `${recipientPlaceholder}様\nこの度は${businessNamePlaceholder}にご来店いただき誠にありがとうございました。\n\nせっかくのご利用にもかかわらず、${recipientPlaceholder}様にはご不快な思いをさせてしまい大変申し訳ございません。\n${targetPart}につきまして、再度確認を行い、再発防止に努めてまいります。\n\n引き続きお気づきの点はお気軽にご指摘いただけますと幸いです。\n今後とも何卒よろしくお願いいたします。`,
    },
    {
        feeling: "negative",
        name: "品揃え",
        text: `${recipientPlaceholder}様\nこの度は${businessNamePlaceholder}にご来店いただき誠にありがとうございました。\n\nせっかくのご利用にもかかわらず、お客様にご不便おかけし大変申し訳ございません。\nご指摘いただいた商品につきまして、お客様のご意見をもとに取り扱いを検討してまいります。\n\n今後とも何卒よろしくお願いいたします。`,
    },
    {
        feeling: "negative",
        name: "サービス・商品",
        text: `${recipientPlaceholder}様\nこの度は${businessNamePlaceholder}にご来店いただき誠にありがとうございました。\n\nせっかくのご利用にもかかわらず、お客様にご不便をおかけしてしまい大変申し訳ございません。\n${targetPart}につきまして、お客様のご意見を参考にさせていただき改善に努めてまいります。\n\n引き続きお気づきの点はお気軽にご指摘いただけますと幸いです。\n今後とも何卒よろしくお願いいたします。`,
    },
    {
        feeling: "negative",
        name: "価格",
        text: `${recipientPlaceholder}様\nこの度は${businessNamePlaceholder}にご来店いただき誠にありがとうございました。\n\nせっかくのご利用にもかかわらず、御気分を害してしまい大変申し訳ございません。\nご希望に沿えるかはわかりませんが、より低価格でご提供できるよう改善に努めてまいります。\n\n引き続きお気づきの点はお気軽にご指摘いただけますと幸いです。\n今後とも何卒よろしくお願いいたします。`,
    },
    {
        feeling: "negative",
        name: "お客様マナー",
        text: `${recipientPlaceholder}様\nこの度は${businessNamePlaceholder}にご来店いただき誠にありがとうございました。\n\nせっかくのご利用にもかかわらず、${recipientPlaceholder}様にはご不快な思いをさせてしまい大変申し訳ございません。\n皆様が快適にご利用いただけるよう店内での注意喚起に努めてまいります。\n\n引き続きお気づきの点はお気軽にご指摘いただけますと幸いです。\n今後とも何卒よろしくお願いいたします。`,
    },
];

// 定型文
export const customaryOpening = `${recipientPlaceholder}様
この度は${businessNamePlaceholder}にご来店いただき誠にありがとうございました。
`;

// AIクチコミ返信の元になるテンプレート
const replyTemplate = `ご評価をいただき重ねて感謝申し上げます。
引き続きお気づきの点はお気軽にご指摘いただけますと幸いです。
またのご来店をお待ちしております。`;

// ChatGPTに投げるプロンプト(ひとつのクチコミに返信する場合はクチコミ内容に踏み込む)
export const createReplyPromptSingle = `あなたは優秀な店長です。
店舗に寄せられたクチコミに対して返信する業務を担っています。
返信文を生成するときは、以下のステップに従って考えます。

1. 【テンプレート】を主軸にして返信文を1フレーズ考えます
2. 以下の条件を必ず守っているかをチェックして、違反している場合は修正します
  a. クチコミの中で主要となっているキーワードを含めること
  b. 日本の中学生でもわかり易い内容であること
  c. 簡潔に200文字以内でまとめること

このステップを守らない場合、本社の担当者に怒られて査定を下げられてしまいます。

【テンプレート】
"""
${replyTemplate}
"""`;

// ChatGPTに投げるプロンプト(複数のクチコミに返信する場合は一般的な返信に留める)
export const createReplyPromptMulti = `あなたは優秀な店長です。
店舗に寄せられたクチコミに対して返信する業務を担っています。
返信文を生成するときは、以下のステップに従って考えます。

1. 【テンプレート】を主軸にして返信文を1フレーズ考えます
2. 以下の条件を必ず守っているかをチェックして、違反している場合は修正します
    a. クチコミの中で主要となっているキーワードを含めること
    b. 日本の中学生でもわかり易い内容であること
    c. 簡潔に200文字以内でまとめること
    d. すべてのクチコミに返信できる共通の文章にすること
    e. 具体的な事象については書かないこと

このステップを守らない場合、本社の担当者に怒られて査定を下げられてしまいます。

【テンプレート】
"""
${replyTemplate}
"""`;
