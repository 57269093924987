<template>
  <div class="performance-container">
    <header class="performance-header d-flex flex-wrap">
      <div class="me-auto">
        <StoreSelector v-model="selectedTarget" @select="fetch(false)" />
      </div>
      <div class="d-flex flex-wrap align-center ms-auto">
        <div class="d-flex flex-column mr-4">
          <div class="d-flex comparison-area display-term mb-2">
            <div class="term-label">表示期間</div>
            <date-range-picker v-model="rangeCurr" :min="comparisonMin" :max="comparisonMax" />
          </div>
          <div class="d-flex comparison-area comparison-term">
            <div class="term-label">比較期間</div>
            <date-range-picker v-model="rangeDiff" :min="comparisonMin" :max="comparisonMax" />
          </div>
        </div>
        <button class="button is-primary applying-button mr-5" @click="fetch(true)">適用</button>
        <v-tooltip :text="reportToolTip" location="top" content-class="balloon" :transition="false">
          <template #activator="{ props }">
            <button
              v-bind="props"
              :style="{ width: '192px', height: '44px' }"
              class="button followed"
              @click="createReportAsync([0, 1, 2, 3, 4, 5, 6])"
            >
              全件レポート作成
            </button>
          </template>
        </v-tooltip>
      </div>
    </header>

    <div class="insight-container">
      <div class="gbp-head">
        <h2>GBPパフォーマンス分析</h2>
        <v-spacer />
        <div>
          <v-icon size="12" color="#757575">fas fa-file-excel</v-icon>
          <span>各種レポート</span>
          <button
            :disabled="!hasFacebookPosts"
            class="button is-primary report-post"
            @click="exportXLSXFileDownload('fbpost')"
          >
            <v-icon size="12" color="#fff">fab fa-facebook-f</v-icon>
          </button>
          <button
            :disabled="!hasInstagramPosts"
            class="button is-primary report-post"
            @click="exportXLSXFileDownload('igpost')"
          >
            <v-icon size="12" color="#fff">fab fa-instagram</v-icon>
          </button>
          <button
            :disabled="!hasYahooOption"
            class="button is-primary report-post"
            @click="createYahooReport()"
          >
            Y!
          </button>
          <div class="vertical-line"></div>
        </div>
        <button class="button followed" @click="createReport([0, 1, 2, 3])">レポート作成</button>
      </div>
      <v-card class="performance-card">
        <performance-2-summary
          ref="performance2Summary"
          :date-params="dateParams"
          :store-filter="storeFilter"
        />
      </v-card>
      <div v-if="company.keywords?.length > 0">
        <h2 class="chapter">表示順位分析</h2>
        <v-card class="performance-card">
          <v-card-title class="report-title">
            <span>自社/競合の主要キーワード平均表示順位（月別）</span>
            <v-spacer />
            <button class="button followed" @click="createReport([4])">レポート作成</button>
          </v-card-title>
          <performance-4-ranking ref="Performance4Ranking" :store-filter="storeFilter" />
        </v-card>
      </div>
      <h2 class="chapter">キーワード分析</h2>

      <v-card class="performance-card">
        <v-card-title class="report-title">
          <span>検索キーワードユニーク数（月別）</span>
          <v-spacer />
          <v-tooltip
            :text="reportToolTip"
            location="top"
            content-class="balloon"
            :transition="false"
          >
            <template #activator="{ props }">
              <button v-bind="props" class="button followed" @click="createReportAsync([5, 6])">
                レポート作成
              </button>
            </template>
          </v-tooltip>
        </v-card-title>
        <performance-5-keyword-unique ref="Performance5KeywordUnique" :store-filter="storeFilter" />
      </v-card>

      <v-card class="performance-card">
        <v-card-title class="report-title">
          <span>指定キーワードを含む検索ボリューム（月別）</span>
        </v-card-title>
        <v-card-text>
          <div class="wrapper">
            <performance-6-search-keyword-volume
              v-if="company.searchKeywordVolumeKeywords?.length > 0"
              ref="Performance6SearchKeywordVolume"
              :store-filter="storeFilter"
            />
            <v-card-subtitle class="report-subtitle report-subtitle-search-count">
              キーワード別検索ボリューム
            </v-card-subtitle>
            <performance-7-search-count
              ref="Performance7SearchCount"
              :date-params="dateParams"
              :store-filter="storeFilter"
            />
          </div>
        </v-card-text>
      </v-card>
      <div v-if="selectedTarget.isAll && userViewRange <= 3">
        <h2 class="chapter">店舗情報の完成度</h2>
        <v-card class="performance-card">
          <PerformanceCompleteness />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type {
  DomainPostedPoisResponse,
  EntitiesCompany,
  EntitiesStore,
  EntitiesUser,
  StorageResponse,
} from "@/types/ls-api";
import StoreSelector, { SelectedTarget } from "@/components/shared/store-selector.vue";
import DateRangePicker from "@/components/shared/date-picker/DateRangePicker.vue";
import type { SnackbarToast } from "@/components/shared/snackbar/snackbar-shared";
import type { CreateReportRequestBody } from "../dashboard/report-files/script";
import Performance2Summary from "./performance-2-1-summary.vue";
import { useSnackbar } from "@/storepinia/snackbar";
import Performance4Ranking from "./performance-4-ranking.vue";
import Performance5KeywordUnique from "./performance-5-search-keyword-unique.vue";
import Performance6SearchKeywordVolume from "./performance-6-search-keyword-volume.vue";
import Performance7SearchCount from "./performance-7-search-count.vue";
import PerformanceCompleteness from "./performance-completeness.vue";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import type { DateParams, StoreFilter } from "./performance";
import { requiredAuth } from "@/helpers";
import { TOAST_DURATION } from "@/const";
import { captureAndThrow } from "@/helpers/error";
import { api } from "@/helpers/api/performance";
import { useSs } from "@/storepinia/ss";
import { useIndexedDb } from "@/storepinia/idxdb";
dayjs.extend(minMax);

export default defineComponent({
  components: {
    StoreSelector,
    DateRangePicker,
    Performance2Summary,
    Performance4Ranking,
    Performance5KeywordUnique,
    Performance6SearchKeywordVolume,
    Performance7SearchCount,
    PerformanceCompleteness,
  },
  props: {
    target: { type: String, default: null },
  },
  data: () => {
    return {
      selectedTarget: SelectedTarget.fromSs(),
      rangeCurr: { from: useSs().newComparisonDates[0], to: useSs().newComparisonDates[1] },
      rangeDiff: { from: useSs().newComparisonDates[2], to: useSs().newComparisonDates[3] },
      comparisonMin: "",
      comparisonMax: "",
      headerCalcWidth: 320,
      hasFacebookPosts: false,
      hasInstagramPosts: false,

      dateParams: {} as DateParams,
      storeFilter: {} as StoreFilter,
      reportToolTip: "作成に30秒程度かかります" as string,
    };
  },

  computed: {
    canShowBeta: function (): boolean {
      return useIndexedDb().canShowBeta;
    },
    user: function (): EntitiesUser {
      return useIndexedDb().user;
    },
    userViewRange: function (): number {
      return useIndexedDb().userViewRange;
    },
    company: function (): EntitiesCompany {
      return useIndexedDb().company;
    },
    stores: function (): EntitiesStore[] {
      return useIndexedDb().stores.stores;
    },
    hasYahooOption: function (): boolean {
      // yahooplace が有効でない場合は false を返す
      if (!this.company.citation.yahooplace?.enabled) {
        return false;
      }
      // フィルター条件
      // 全店舗の場合は全てチェック対象とする、それ以外はselectedTargetに含まれる店舗のみチェック対象とする
      const filterStores = (store: EntitiesStore) => {
        return this.selectedTarget.isAll || this.selectedTarget.poiIds.includes(store.poiID);
      };
      // 店舗にyahooオプションがあるか、また連携設定されているか（yahooplace.placeSeqが設定されているかをチェックする
      const checkYahooplace = (store: EntitiesStore) => {
        return store.options?.includes("yahooplace") && store.yahooplace?.placeSeq != null;
      };

      // フィルタリングされた店舗の中から条件を満たすものがあるかどうかをチェックする
      return this.stores.filter(filterStores).some(checkYahooplace);
    },
  },
  created(): void {
    this.comparisonMax = dayjs().add(-1, "day").format("YYYY-MM-DD");
    this.comparisonMin = dayjs().startOf("month").add(-17, "month").format("YYYY-MM-DD");
  },
  mounted(): void {
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
    this.fetch(false);
    this.hasPosts();
  },
  unmounted(): void {
    document.getElementsByTagName("html")[0].style.overflow = "initial";
  },

  methods: {
    addSnackbar: function (message: SnackbarToast): void {
      useSnackbar().addSnackbarMessages(message);
    },
    async moveToReportDownload(): Promise<void> {
      let params: CreateReportRequestBody;
      const requiredBody: CreateReportRequestBody = {
        PreviousStartDate: this.rangeDiff.from,
        PreviousEndDate: this.rangeDiff.to,
        CurrentStartDate: this.rangeCurr.from,
        CurrentEndDate: this.rangeCurr.to,
      };
      if (this.selectedTarget.isArea) {
        params = { ...requiredBody, AreaID: this.selectedTarget.areaId };
      } else if (
        this.selectedTarget.isAll === false &&
        this.selectedTarget.isArea === false &&
        this.selectedTarget.poiIds.length > 0
      ) {
        params = { ...requiredBody, poiID: this.selectedTarget.poiIds[0] };
      } else {
        params = { ...requiredBody };
      }
      this.$router.push({
        name: "ReportFiles",
        params: { reportCreate: JSON.stringify(params) },
      });
      useIndexedDb().selectReport.selectReportType = "other";
    },
    /* 日付条件と店舗条件をpropsに渡すためにまとめる処理 */
    setFetchParams(): void {
      this.dateParams = {
        currentStartDate: this.rangeCurr.from,
        currentEndDate: this.rangeCurr.to,
        previousStartDate: this.rangeDiff.from,
        previousEndDate: this.rangeDiff.to,
      };
      if (this.selectedTarget.isAll) {
        this.storeFilter = {};
      } else if (this.selectedTarget.isArea && this.selectedTarget.areaIds.length > 0) {
        this.storeFilter = { areaIDs: this.selectedTarget.areaIds.join(",") };
      } else if (this.selectedTarget.poiIds.length > 0) {
        this.storeFilter = { poiID: this.selectedTarget.poiIds[0] };
      }
    },
    async fetch(termUpdate = false): Promise<void> {
      this.setFetchParams();
      useSs().newComparisonDates = [
        this.rangeCurr.from,
        this.rangeCurr.to,
        this.rangeDiff.from,
        this.rangeDiff.to,
      ];

      this.$nextTick(() => {
        (this.$refs.performance2Summary as InstanceType<typeof Performance2Summary>).fetch();
        (
          this.$refs.Performance7SearchCount as InstanceType<typeof Performance7SearchCount>
        ).fetch();
        // 期間変更は2と7だけ更新して終了
        if (termUpdate) {
          return;
        }
        // キーワード設定されている場合のみ実行
        if (this.company.keywords?.length > 0) {
          (this.$refs.Performance4Ranking as InstanceType<typeof Performance4Ranking>).fetch();
        }

        (
          this.$refs.Performance5KeywordUnique as InstanceType<typeof Performance5KeywordUnique>
        ).fetch();

        // 集計キーワード設定されている場合のみ実行
        if (this.company.searchKeywordVolumeKeywords?.length > 0) {
          (
            this.$refs.Performance6SearchKeywordVolume as InstanceType<
              typeof Performance6SearchKeywordVolume
            >
          ).fetch();
        }
      });
    },
    async exportXLSXFileDownload(
      target: string,
      params: Record<string, unknown> = null
    ): Promise<void> {
      try {
        let url = `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
          this.$route.params.poiGroupId
        }/`;
        if (this.selectedTarget.isAll) {
          // 全店舗
          url += `metrics/all/download`;
        } else if (this.selectedTarget.isArea) {
          // グループ
          url += `metricsArea/${this.selectedTarget.areaId}/download`;
        } else {
          // 個店
          url += `metrics/${this.selectedTarget.poiIds[0]}/download`;
        }

        const reqParams =
          params == null
            ? {
                startDate: this.rangeCurr.from,
                endDate: this.rangeCurr.to,
                diffStartDate: this.rangeDiff.from,
                diffEndDate: this.rangeDiff.to,
                target: target,
              }
            : params;
        await requiredAuth<StorageResponse>("get", url, reqParams)
          .then(async () => {
            this.addSnackbar({
              text: "XLSXファイルの作成を開始しました。<br>しばらくしてからレポートダウンロードをご確認ください",
              timeout: TOAST_DURATION,
              color: "success",
            });
          })
          .catch((e) => captureAndThrow("レポートファイル作成失敗", e));
      } catch (e) {
        captureAndThrow(`${target} XLSX出力に失敗`, e);
      }
    },
    async createReport(sheets: number[]): Promise<void> {
      const poiGroupId = parseInt(this.$route.params.poiGroupId as string);
      await api.report(sheets, poiGroupId, this.storeFilter, this.dateParams);
    },
    async createYahooReport(): Promise<void> {
      const poiGroupId = parseInt(this.$route.params.poiGroupId as string);
      await api.yahooReport(poiGroupId, this.storeFilter, this.dateParams);
    },
    async createReportAsync(sheets: number[]): Promise<void> {
      const poiGroupId = parseInt(this.$route.params.poiGroupId as string);
      await api.reportAsync(sheets, poiGroupId, this.storeFilter, this.dateParams);
      this.addSnackbar({
        text: "XLSXファイルの作成を開始しました。<br>しばらくしてからレポートダウンロードをご確認ください",
        timeout: TOAST_DURATION,
        color: "success",
      });
    },
    async getPostedPois(): Promise<DomainPostedPoisResponse> {
      const poiGroupId = parseInt(this.$route.params.poiGroupId as string);
      const result = await requiredAuth<DomainPostedPoisResponse>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/performance/posted_pois`
      );
      return result.data;
    },
    async hasPosts(): Promise<void> {
      const postedPois = await this.getPostedPois();

      // 全店舗の場合は全てチェック対象とする、それ以外はselectedTargetに含まれる店舗のみチェック対象とする
      const filterStores = (store: EntitiesStore) => {
        return this.selectedTarget.isAll || this.selectedTarget.poiIds.includes(store.poiID);
      };
      // 店舗に fbPageID が設定されている店舗
      const checkFacebook = (store: EntitiesStore) => {
        return store.fbPageID != null;
      };
      const targetStores = this.stores.filter(filterStores).filter(checkFacebook);

      // Facebookに投稿されている店舗を抽出
      const fbStores = targetStores.filter((store) => {
        return postedPois.facebook_pois.some((fbPoi) => fbPoi === store.poiID);
      });
      this.hasFacebookPosts = fbStores.length > 0;

      // Instagramに投稿されている店舗を抽出
      const igStores = targetStores.filter((store) => {
        return postedPois.instagram_pois.some((igPoi) => igPoi === store.poiID);
      });
      this.hasInstagramPosts = igStores.length > 0;

      return;
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

.performance-header {
  position: relative;
  z-index: calc(var(--z-index-loading) + 1);
  width: 100%;
  box-sizing: border-box;
  padding: 15px 20px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  justify-content: space-between;
}

%datePickerInput {
  background-repeat: no-repeat;
  background-size: 9px 12px;
  background-position: right center;
  padding-right: 1.25em;
  position: relative;
  cursor: pointer;
}

.comparison-area {
  background-color: #fff;
  font-size: 14px;

  .term-label {
    font-weight: bold;

    &::after {
      content: ":";
      margin: 0 0.25em;
    }
  }

  &.display-term {
    :deep() {
      color: color.$primary;
      border-color: color.$primary;
      input {
        color: color.$primary;
      }
    }
  }

  &.comparison-term {
    :deep() {
      color: #5ea2de;
      border-color: #5ea2de;
      input {
        color: #5ea2de;
      }
    }
  }

  .time-wave {
    padding: 2px;
  }
}

.applying-button {
  font-size: 12px;
  border-radius: 3px;
}

div.v-tooltip > :deep(.balloon) {
  font-weight: bold;
  font-size: 16px;
  &::after {
    display: block;
    position: absolute;
    left: calc((230px - 28px) / 2);
    bottom: -20px;
    width: 28px;
    height: 34px;
    content: "";
    transform: scale(0.5);
    background: url("@/assets/images/balloon_edge.svg") no-repeat center bottom;
  }
}
.insight-container {
  padding-bottom: 132px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 166px);
}

.gbp-head {
  display: flex;
  align-items: center;
  margin: 10px 0;

  h2 {
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }

  span {
    font-size: 16px;
    color: #757575;
    vertical-align: middle;
    font-weight: normal;
  }

  div.vertical-line {
    display: inline-block;
    vertical-align: middle;
    width: 2px;
    background-color: #d6d6d6;
    height: 28px;
    margin-left: 24px;
    margin-right: 24px;
  }

  button.report-post {
    vertical-align: middle;
    border-style: none;
    padding: 5px 10px;
    border-radius: 3px;
    width: 50px;
    height: 24px;
    margin: 0;

    &:first-of-type {
      margin-right: 5px;
    }
  }
}

.common-title {
  /* Figmaから共通部分を抽出 */
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: #333333 !important;
}

.performance-card {
  margin-bottom: 1rem;
}

/* 章タイトル */
.chapter {
  @extend .common-title;
  font-size: 20px;
  line-height: 30px;
  margin: 10px 0;
}

/* レポートタイトル */
.report-title {
  @extend .common-title;
  font-size: 24px;
  padding-bottom: 0px;
}

/* レポートサブタイトル */
.report-subtitle {
  @extend .common-title;
  font-size: 20px;
  line-height: 30px;
  padding-left: 1.5rem;
  padding-bottom: 0px;
}
.report-subtitle-search-count {
  margin-top: 16px;
  opacity: 1;
}

.wrapper {
  border: 1px solid #d6d6d6;
  margin-top: 16px;
}
</style>
