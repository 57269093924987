import type { GmbLocationInfo } from "./Row";
import type {
    MybusinessbusinessinformationTimePeriod,
    MybusinessbusinessinformationMoreHours,
    MybusinessbusinessinformationTimeOfDay,
    MybusinessbusinessinformationMoreHoursType,
} from "@/types/ls-api";
import { DateTimeFormatter, LocalDate, LocalTime } from "@js-joda/core";
import { myRenderer, moreHoursTypesRenderer } from "./table";

export const week = [
    { key: "Sun", localized: "日曜日", name: "SUNDAY" },
    { key: "Mon", localized: "月曜日", name: "MONDAY" },
    { key: "Tue", localized: "火曜日", name: "TUESDAY" },
    { key: "Wed", localized: "水曜日", name: "WEDNESDAY" },
    { key: "Thu", localized: "木曜日", name: "THURSDAY" },
    { key: "Fri", localized: "金曜日", name: "FRIDAY" },
    { key: "Sat", localized: "土曜日", name: "SATURDAY" },
];

const weekKeys = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// moreHoursと時間の形式は同じ
export type RegularHourPeriod = {
    periods: GmbLocationInfo["location"]["regularHours"]["periods"];
    isInvalid: boolean;
};

export type SpecialHourPeriod = {
    periods: GmbLocationInfo["location"]["specialHours"]["specialHourPeriods"];
    isInvalid: string;
};

/** 通常営業時間セルのバリデーション */
function regularHourValidator(value, callback): void {
    // 営業時間の詳細がnullなので、nullだった場合は空文字を入れている
    const regularHour: RegularHourPeriod = toHour(value ?? "");
    callback(regularHour.isInvalid === false);
}

/** 特別営業時間セルのバリデーション */
function specialHourValidator(value, callback): void {
    const specialHour: SpecialHourPeriod = toSpecialHour(value);
    callback(specialHour.isInvalid === "");
}

export function enabledMoreHours(
    types: MybusinessbusinessinformationMoreHoursType[]
): MybusinessbusinessinformationMoreHours[] {
    return types?.map((t) => {
        return {
            hoursTypeId: t.hoursTypeId,
            periods: [],
        };
    });
}

export interface HoursColumn {
    title: string;
    data: string;
    readOnly?: boolean;
    type?: string;
    validator?: any;
    editor?: string; // selectのときに設定する
    selectOptions?: string[]; // selectのときに設定する
    renderer?: any;
    enumValue?: string;
    width?: string;
    className?: string;
}

export function fetchHoursColumns(
    selectedItems?: MybusinessbusinessinformationMoreHoursType[]
): HoursColumn[] {
    // 基本は店舗ID・ビジネス名・各曜日の営業日・特別営業日をセット
    const columns: HoursColumn[] = [
        {
            title: "店舗ID",
            data: "poiID",
            type: "numeric",
            readOnly: true,
            className: "rowheader",
        },
        { title: "店舗コード", data: "storeCode", readOnly: true },
        { title: "ビジネス名", data: "title", readOnly: true },
    ];
    for (const w of week) {
        columns.push({
            title: `${w.localized}の営業時間`,
            data: `businessHours${w.key}`,
            validator: regularHourValidator,
        });
    }
    columns.push({
        title: "特別営業時間",
        data: "businessHoursSpecial",
        width: "300px",
        validator: specialHourValidator,
    });
    // 以下選択した営業時間の詳細の項目を足していく
    selectedItems?.forEach((item: MybusinessbusinessinformationMoreHoursType) => {
        week.forEach((w) => {
            columns.push({
                title: `${w.localized}の${item.localizedDisplayName}`,
                data: `moreHours.${item.hoursTypeId}${w.key}`,
                validator: regularHourValidator,
                renderer: moreHoursTypesRenderer,
            });
        });
    });
    columns.forEach((column) => {
        // 入力できないセル以外にrendererを設定する
        const cells = ["poiID", "storeCode", "title"];
        if (column.renderer == null && !cells.includes(column.data)) {
            column.renderer = myRenderer;
        }
    });
    return columns;
}

export function flattenMoreHours(moreHours: MybusinessbusinessinformationMoreHours[]): {
    [key: string]: string;
} {
    const fl: { [key: string]: string } = {};
    for (const mh of moreHours) {
        for (const w of week) {
            fl[`${mh.hoursTypeId}${w.key}`] = getHour(mh.periods, w.name);
        }
    }
    return fl;
}

export function genMoreHoursTypesKey(hoursTypeId: string): string[] {
    return week.map((w) => `moreHours.${hoursTypeId}${w.key}`);
}

export function parseMoreHoursTypeId(name: string): string {
    for (const key of weekKeys) {
        if (name.endsWith(key)) {
            return name.replace(key, "");
        }
    }
    return "";
}

function getDoubleDigit(t: number) {
    return t == null ? "00" : ("0" + t).slice(-2);
}
/**
 * GBPから返ってきたperiodsを画面のテーブルで表示するための形式に直す
 * @param periods ロケーションの営業時間のオブジェクトの配列
 * @param day 曜日を表すName(例: "MONDAY")
 */
export function getHour(periods: MybusinessbusinessinformationTimePeriod[], day: string): string {
    return periods
        ?.filter((p) => p.openDay === day && p.closeDay === day)
        .map(
            (p) =>
                `${getDoubleDigit(p.openTime.hours)}:${getDoubleDigit(
                    p.openTime.minutes
                )}-${getDoubleDigit(p.closeTime.hours)}:${getDoubleDigit(p.closeTime.minutes)}`
        )
        .join("|");
}
/**
 * 営業時間を画面のテーブルで表示するための形式に直す
 * @param x 営業時間
 * @param day 曜日を表すName(例: "MONDAY")
 */
export function getRegularHour(
    x: GmbLocationInfo["location"]["regularHours"],
    day: string
): string {
    const periods = x?.periods ?? [];
    return getHour(periods, day);
}
/**
 * 特別営業時間を画面のテーブルで表示するための形式に直す
 * @param x 特別営業時間
 */
export function getSpecialHour(x: GmbLocationInfo["location"]["specialHours"]): string {
    const periods = x?.specialHourPeriods ?? [];
    return periods
        .map((p) => {
            const sd = p.startDate;
            const date = LocalDate.of(sd.year, sd.month, sd.day).toString();
            if (p.closed) {
                return `${date}: x`;
            } else {
                const openTime = `${getDoubleDigit(p.openTime.hours)}:${getDoubleDigit(
                    p.openTime.minutes
                )}`;
                let closeTime = `${getDoubleDigit(p.closeTime.hours)}:${getDoubleDigit(
                    p.closeTime.minutes
                )}`;
                if (openTime === closeTime && closeTime === "00:00") {
                    closeTime = "24:00";
                }
                return `${date}: ${openTime}-${closeTime}`;
            }
        })
        .join("|");
}

function toMybusinessbusinessinformationTimeOfDay(
    timeStr: string
): MybusinessbusinessinformationTimeOfDay {
    const [hour, min] = timeStr.split(":");
    return {
        hours: Number(hour),
        minutes: Number(min),
    };
}

/**
 * 文字列で受け取った時間をGBPの形式にパースする
 * @param str 時間を表す文字列
 * @param day 曜日を表すName(例: "MONDAY")
 **/
export function toHour(str: string, day?: string): RegularHourPeriod {
    if (str == null) {
        return { periods: [], isInvalid: true };
    }
    const periodRegex = /(\d\d:\d\d-\d\d:\d\d)/;
    const periods: string[] = str.replace(/[\n,]/g, "|").split("|");
    for (const period of periods) {
        // 営業時間に不正な書式の日付けが入力された場合エラーフラグ立てる
        if (period !== "" && periodRegex.test(period) === false) {
            return { periods: [], isInvalid: true };
        }
    }
    let isInvalid = false;
    const regularHourPeriods: MybusinessbusinessinformationTimePeriod[] = periods
        .filter((p) => p !== "")
        .map((p) => {
            const times = p.split("-");
            let openTime: string;
            let closeTime: string;
            const ot = times[0].replace(/ /g, "");
            const ct = times[1].replace(/ /g, "");
            try {
                // 24:00を変換できるようにformatterを設定
                const formatter = DateTimeFormatter.ofPattern("HH:mm");
                openTime = LocalTime.parse(ot, formatter).toString();
                closeTime = LocalTime.parse(ct, formatter).toString();
            } catch (e) {
                // console.error("[toHour] parse error", e);
                openTime = "";
                closeTime = "";
                isInvalid = true;
            }
            return {
                openDay: day,
                openTime: toMybusinessbusinessinformationTimeOfDay(openTime),
                closeDay: day,
                closeTime: toMybusinessbusinessinformationTimeOfDay(closeTime),
            };
        });
    return { periods: regularHourPeriods, isInvalid };
}
const specialElemRegex = /(\d\d\d\d-\d\d-\d\d):\s*(\d\d:\d\d-\d\d:\d\d|x)/;
/**
 * 引数に与えられた文字列をGBPの特別営業時間の形式にパースして返す
 * @param str 特別営業時間
 */
export function toSpecialHour(str: string): SpecialHourPeriod {
    let isInvalid = "";
    const generalInvalidMessage = "特別営業時間の書式が不正です。";
    let periods:
        | string
        | string[]
        | GmbLocationInfo["location"]["specialHours"]["specialHourPeriods"] = str
        /* eslint no-irregular-whitespace: ["error", {"skipRegExps": true}] */
        .replace(/\n/g, "|")
        .replace(/[\s ]+/g, "") // 元々\sの横は半角ではなく全角スペース
        .replace(/,/g, "|")
        .split("|")
        .map((p) => p.trim());
    for (const period of periods) {
        // 特別営業時間に不正な書式の日付けが入力された場合エラーフラグ立てる
        if (period !== "" && !period.match(specialElemRegex)) {
            return { periods: [], isInvalid: generalInvalidMessage };
        }
    }
    periods = periods
        .filter((p) => p !== "" && p.match(specialElemRegex))
        .map((p) => {
            const m = p.match(specialElemRegex);
            const date = m[1];
            const range = m[2];
            // perse出来なかった場合は「不正な書式です」エラーフラグ立てる
            try {
                const localDate = LocalDate.parse(date);
                const startDate = {
                    year: localDate.year(),
                    month: localDate.monthValue(),
                    day: localDate.dayOfMonth(),
                };
                // 現実的ではない年が入力された場合エラーフラグ立てる
                if (startDate.year > 2099 || startDate.year < 2010) {
                    isInvalid = "特別営業時間に適切な日付を入力してください。";
                }
                const [openTime, closeTime] = range.split("-").map((x) => x.trim());
                if (range === "x") {
                    return { closed: true, startDate };
                }
                return {
                    closed: false,
                    openTime: toMybusinessbusinessinformationTimeOfDay(openTime),
                    closeTime: toMybusinessbusinessinformationTimeOfDay(closeTime),
                    startDate,
                };
            } catch (error) {
                console.error("[toSpecialHour Error]", error);
                isInvalid = generalInvalidMessage;
            }
        });
    return { periods, isInvalid };
}
