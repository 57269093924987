<template>
  <div class="dashboard">
    <!-- 共通ヘッダ -->
    <div class="fixed-header" :style="`width: calc(100% - ${headerCalcWidth}px)`">
      <!-- スマホ版 -->
      <div v-if="isMobile" class="mobile-fixed-header-wrapper pb-1 px-1">
        <span class="font-weight-bold text-h6">クチコミ返信</span>
        <div class="mobile-header-box pl-2 my-2">
          <span class="date-range-title">表示期間</span>
          <div class="d-flex justify-start align-center">
            <date-range-picker v-model="range" />
            <v-btn
              class="button ml-4"
              color="primary"
              text="適用"
              @click="doChildComponentSearch"
            />
          </div>
        </div>
        <StoreSelector
          ref="storeSelector"
          v-model="selectedTarget"
          class="mobile-header-box"
          @select="doChildComponentSearch"
        />
      </div>
      <!-- PC版 -->
      <div v-else class="fixed-header-wrapper">
        <div class="title-bar">
          <h2>クチコミ返信</h2>
          <div class="d-flex justify-end align-center">
            <span class="primary-bold mr-2">表示期間:</span>
            <date-range-picker v-model="range" />
            <button class="button is-primary apply-button ml-3" @click="doChildComponentSearch">
              適用
            </button>
          </div>
        </div>
        <StoreSelector
          ref="storeSelector"
          v-model="selectedTarget"
          @select="doChildComponentSearch"
        />
      </div>
    </div>
    <div v-if="!selectedTarget.isNone" class="review-reply-container">
      <ReviewSearchReply
        ref="reviewSearchReply"
        v-model:loading="loading"
        :report-name="dict.reportName"
        :data-export-loading="dataExportLoading"
        :review-search-response="reviewSearchResponse"
        :reviews-per-page="reviewsPerPage"
        :show-store-name="true"
        :review-reply-rates="reviewReplyRates"
        :is-mobile="isMobile"
        @review-search="reviewSearch"
        @reply-submit="replySubmit"
        @xlsx-export-submit="xlsxExportSubmit"
      />
    </div>
    <div v-else class="no-target">※表示条件を選択してください</div>
  </div>
</template>

<script lang="ts">
import dayjs from "dayjs";
import { requiredAuth } from "@/helpers";
import { headerCalcWidth } from "@/helpers/header";
import { captureAndThrow } from "@/helpers/error";
import { getOperationLogParams } from "@/routes/operation-log";
import { Component, Vue, toNative } from "vue-facing-decorator";
import { toUniversalStartOfDay, toUniversalEndOfDay } from "@/helpers/date";
import type {
  StorageReviewsSearchResponse,
  EntitiesReviewReplyRates,
  EntitiesReviewDownload,
} from "@/types/ls-api";
import wordDictionary from "@/word-dictionary";
import DateRangePicker from "@/components/shared/date-picker/DateRangePicker.vue";
import StoreSelector, { SelectedTarget } from "@/components/shared/store-selector.vue";
import ReviewSearchReply from "./parts/review-search-reply.vue";
import { getter, useIndexedDb } from "@/storepinia/idxdb";
import { useSs } from "@/storepinia/ss";
import { useSnackbar } from "@/storepinia/snackbar";
import type { CustomSnackbarToast } from "@/helpers/request";
import type { SnackbarToast } from "@/components/shared/snackbar/snackbar-shared";

type BaseParams = { endDate?: string; startDate?: string };
type ReviewSearchParams = {
  sortBy: string;
  order: string;
  word: string;
  starRatings: string;
  reviewType: string;
  hitsPerPage: number;
  page: number;
  commentExists?: boolean;
  replied?: boolean;
  updateTimeStart?: Date;
  updateTimeEnd?: Date;
};

@Component({
  components: { DateRangePicker, StoreSelector, ReviewSearchReply },
})
class ReviewReply extends Vue {
  company = getter().company;
  isMobile = getter().isMobile;
  range = {
    from: useSs().replyAggregationStartDate,
    to: useSs().replyAggregationEndDate,
  };
  selectedTarget: SelectedTarget = SelectedTarget.fromSs();

  loading: boolean = false;
  dataExportLoading: boolean = false;
  addSnackbarMessages = useSnackbar().addSnackbarMessages;

  // クチコミ返信率
  reviewReplyRates: Partial<EntitiesReviewReplyRates> = {};

  // クチコミ検索
  commentExists: "CommentUnspecified" | "Commented" | "NoComment" = "CommentUnspecified";
  replyStatus: "ReplyUnspecified" | "NoReplied" | "Replied" = "ReplyUnspecified";
  dict = wordDictionary.companyReviews;
  starRatings = [1, 2, 3, 4, 5];
  starRatingsChecked = [];
  reviewSearchResponse: Partial<StorageReviewsSearchResponse> = {};
  word = "";
  reviewsPerPage = 15;
  reviewOption = wordDictionary.stores.options.review;

  // 検索の実行(表示期間が変更された)
  async doChildComponentSearch(): Promise<void> {
    useSs().replyAggregationStartDate = this.range.from;
    useSs().replyAggregationEndDate = this.range.to;
    // クチコミ検索条件の内容も反映させる為、子コンポーネントのsearchを叩く
    (this.$refs.reviewSearchReply as InstanceType<typeof ReviewSearchReply>).search();
  }

  mounted(): void {
    const poiId = this.$route.query.poiID;
    if (poiId && !Number.isNaN(poiId)) {
      const idb = useIndexedDb();
      const enabledStores = idb.stores.stores.filter((s) => s.enabled);
      const selectedStore = enabledStores.find((s) => s.poiID === Number(poiId));

      this.selectedTarget.isAll = false;
      this.selectedTarget.isArea = false;
      this.selectedTarget.poiIds = [Number(poiId)];
      (this.$refs.storeSelector as InstanceType<typeof StoreSelector>).onStoreSelect(selectedStore);
      (this.$refs.storeSelector as InstanceType<typeof StoreSelector>).setSelection(
        this.selectedTarget
      );
    }

    // 遅延実行することで正確な表示期間で実行出来る
    this.$nextTick(() => {
      this.doChildComponentSearch();
    });
  }

  private getAdditionalPath() {
    if (!this.selectedTarget.isAll && this.selectedTarget.isArea && this.selectedTarget.areaId) {
      return `/areas/${this.selectedTarget.areaId}`;
    } else if (
      !this.selectedTarget.isAll &&
      !this.selectedTarget.isArea &&
      this.selectedTarget.poiIds.length > 0
    ) {
      return `/pois/${this.selectedTarget.poiIds[0]}`;
    }
    return "";
  }

  private async getReplyRate(params: Record<string, unknown>) {
    const apiPath = "reviews/replyRate" + this.getAdditionalPath();

    // クチコミ返信率
    await requiredAuth<EntitiesReviewReplyRates>(
      "get",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.company.poiGroupID}/${apiPath}`,
      params,
      null,
      null,
      null,
      false
    )
      .then((res) => {
        this.reviewReplyRates = res?.data;
      })
      .catch((e) => captureAndThrow("クチコミ返信率取得失敗", e));
  }

  private getBaseParams(): BaseParams {
    const params: BaseParams = {};
    if (this.range.to) {
      params.endDate = this.range.to;
    }

    if (this.range.from) {
      params.startDate = this.range.from;
    }
    return params;
  }

  async reviewSearch(params?: ReviewSearchParams): Promise<void> {
    this.loading = true;
    if (this.selectedTarget.isNone) {
      return;
    }
    const baseParams = this.getBaseParams();
    await Promise.all([
      this.getReviewSearchResult(params),
      this.getReplyRate({ ...baseParams, ...params }),
    ]);
    this.loading = false;
  }

  async getReviewSearchResult(params?: ReviewSearchParams): Promise<void> {
    const apiPath = "reviews/search" + this.getAdditionalPath();
    params = {
      ...params,
      hitsPerPage: this.reviewsPerPage,
      updateTimeStart: toUniversalStartOfDay(dayjs(this.range.from).toDate()),
      updateTimeEnd: toUniversalEndOfDay(dayjs(this.range.to).toDate()),
    };
    params.sortBy = params?.sortBy ?? "updateTime";
    params.order = params?.order ?? "desc";
    await requiredAuth<StorageReviewsSearchResponse>(
      "get",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.company.poiGroupID}/${apiPath}`,
      params,
      null,
      null,
      null,
      false
    )
      .then((res) => {
        this.reviewSearchResponse = res?.data;
        // 検索対象の店舗がクチコミオプション未契約ならばトースト表示
        if (res.status == 402) {
          this.addSnackbarMessages(this.getNoContractStoreToast());
          return;
        }
        // 検索対象の店舗がクチコミオプション未契約ならばトースト表示
        if (this.reviewSearchResponse.reviews?.length === 0) {
          this.addSnackbarMessages({
            text: "選択された条件に該当するデータはありませんでした",
            timeout: 3000,
          });
          return;
        }
      })
      .catch((e) => {
        captureAndThrow("クチコミ取得失敗", e);
      });
  }

  /** ReviewSearchReplyでsubmit行われているので使われていない??? */
  async replySubmit(replyMessage: string, params: Record<string, unknown>): Promise<void> {
    this.loading = true;
    await requiredAuth(
      "put",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.company.poiGroupID}/reviews/reply`,
      getOperationLogParams(this.$route, "reply"),
      {
        reviewIDs: params.reviewIDs,
        Comment: replyMessage,
      }
    )
      .catch((e) => captureAndThrow("クチコミ返信送信失敗", e))
      .finally(() => (this.loading = false));
    // replyAPIが返信時間を返していないのでクチコミを一括更新
    await this.reviewSearch();
  }

  async xlsxExportSubmit(params?: ReviewSearchParams): Promise<void> {
    const apiPath = "reviews" + this.getAdditionalPath() + "/downloads";
    const baseParams = this.getBaseParams();
    const downloadParams = {
      ...baseParams,
      ...params,
      updateTimeStart: toUniversalStartOfDay(dayjs(this.range.from).toDate()),
      updateTimeEnd: toUniversalEndOfDay(dayjs(this.range.to).toDate()),
    };

    this.dataExportLoading = true;

    let downloadId: string;
    await requiredAuth<EntitiesReviewDownload>(
      "post",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.company.poiGroupID}/${apiPath}`,
      downloadParams,
      null,
      null,
      { statusCode: 403, snackbarToast: this.getNoContractStoreToast() } as CustomSnackbarToast
    )
      .then((res) => {
        if (res.status == 403) {
          // Sentryを出さずにトースト表示にとどめて処理を中断する
          return;
        }
        if (res?.data?.downloadID == null) {
          throw new Error(JSON.parse(res?.request?.response)?.errorMessage);
        }
        downloadId = res?.data?.downloadID;
      })
      .catch((e) => captureAndThrow("クチコミファイル作成依頼失敗", e));

    if (downloadId == null) {
      // 処理を中断する(エラーだったら前段階でSentryに通知しているのでここではハンドリングしない)
      this.dataExportLoading = false;
      return;
    }

    // Lambdaのタイムアウト15分にマージン(100sec + API実行時間)をつけてポーリング
    let url = "";
    let i = 0;
    while (url == null || url == "") {
      i++;
      if (i > 1000) {
        throw Error("クチコミファイル作成タイムアウト");
      }

      await new Promise((resolve) => setTimeout(resolve, 1000)); // 1sec wait

      await requiredAuth<EntitiesReviewDownload>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
          this.company.poiGroupID
        }/${apiPath}/${downloadId}`
      )
        .then((res) => (url = res?.data.url))
        .catch((e) => captureAndThrow("クチコミファイル作成状態取得失敗", e));
    }
    this.dataExportLoading = false;
    window.open(url, "_self");
  }
  // サイドバーの有無で幅を調整する
  get headerCalcWidth(): number {
    return headerCalcWidth(useIndexedDb().isDrawerOpened, this.isMobile);
  }

  private getNoContractStoreToast(): SnackbarToast {
    // 同じオブジェクトを返すとmarkされてしまうので、毎回新しいオブジェクトを生成して返す
    return {
      text: "選択された条件に該当するクチコミオプション契約店舗はありませんでした",
      timeout: 3000,
    };
  }
}
export default toNative(ReviewReply);
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;
@use "@/components/style/devise.scss" as devise;
.dashboard {
  padding: 0;
  background-color: #fff;
}
.fixed-header:has(.mobile-fixed-header-wrapper) {
  width: 100% !important;
}
.fixed-header:not(.column.fixed-header) {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  background-color: #fff;
  z-index: calc(var(--z-index-loading) + 1);
}
.fixed-header-wrapper {
  margin-top: 80px;
  padding: 15px 0 10px 30px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
}
.mobile-fixed-header-wrapper {
  @extend .fixed-header-wrapper;
  padding: unset;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
  font-size: 0.9rem;
}
.mobile-fixed-header-wrapper .text-h6 {
  margin-left: 0.5rem;
}
.mobile-header-box {
  border: 1px solid color.$base-main-color;
  background-color: color.$base-sidebar-color;
  padding: 0.25rem 0.25rem;
  margin: 0.25rem 0.25rem;
}
.date-range-title {
  color: color.$graph-title;
  font-size: 0.9rem;
}
.title-bar {
  box-sizing: border-box;
  padding-right: 10px;
  padding-bottom: 10px;
  margin-top: -20px;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;

  h2 {
    margin-top: 2px;
    font-size: 22px;
    font-weight: bold;
  }
  .primary-bold {
    color: var(--primary);
    font-weight: bold;
  }
}

.no-target {
  margin-left: 30px;
  padding-top: 140px;
  padding-bottom: 10px;
}

.review-reply-container {
  margin-top: 110px;
}

@media (max-width: (devise.$sp)) {
  .review-reply-container {
    margin-top: 200px;
    margin-bottom: 50px;
    padding: 0.25rem;
    background-color: #f5f6f7;
  }
  .mobile-header-box > .v-input {
    margin-bottom: 0 !important;
  }
}
</style>
