import type {
    EntitiesFoodMenuVariationRow,
    EntitiesFoodMenuData,
    EntitiesFoodMenuStore,
    MybusinessMoney,
    EntitiesStore,
    MybusinessFoodMenu,
    MybusinessFoodMenuSection,
    MybusinessFoodMenuItem,
} from "@/types/ls-api";

type Store = EntitiesFoodMenuStore & {
    code: string;
    name: string;
};

/** メニュー設定タブのデータの行 */
export type FoodMenuRow = {
    id: number;
    isSection: boolean;
    /** セクションの displayName */
    sectionName?: string;
    /** メニューアイテムの displayName */
    displayName?: string;
    /** メニューアイテムの description */
    description?: string;
    price?: string;
    isVariable?: boolean;
    /** poiIdごとの可変設定 */
    varsByPoi: { [poiId: number]: string };
};

/** 可変項目設定タブのデータの行 */
type PoiPrice = {
    poiId: number;
    code: string;
    name: string;
} & { [itemId: number]: string };
/** 可変項目設定タブのヘッダ(1行目) */
type SectionHeader = {
    itemId: number;
    sectionName: string;
    itemHeaders: ItemHeader[];
};
/** 可変項目設定タブのヘッダ(2行目) */
type ItemHeader = {
    itemId: number;
    itemName: string;
};

export type MODE = "menus" | "variables";
export type ExportData = {
    menus: string[][];
    vars: string[][];
};
export class FoodMenuModel {
    private mode: MODE = "menus";
    private sequence: number = 0;
    currencyCode: string;
    stores: Store[] = [];
    items: FoodMenuRow[] = [];
    sectionHeaders: SectionHeader[]; // 可変項目設定タブを選んでいるときだけ使う
    poiPrices: PoiPrice[]; // 可変項目設定タブを選んでいるときだけ使う

    constructor(
        data: EntitiesFoodMenuData,
        fmss: EntitiesFoodMenuStore[],
        ss: EntitiesStore[],
        currencyCode: string
    ) {
        this.currencyCode = currencyCode;
        for (const fms of fmss) {
            const x = ss.find((s) => s.poiID === fms.poiID);
            if (!x) {
                continue;
            }
            this.stores.push(Object.assign({}, fms, { code: x.gmbStoreCode, name: x.name }));
        }
        if (0 !== (data?.menus?.length ?? 0)) {
            const menu = data.menus[0];
            for (const section of menu?.sections ?? []) {
                const sname = section?.labels[0]?.displayName ?? "";
                this.items.push({
                    id: this.sequence++,
                    isSection: true,
                    sectionName: sname,
                    varsByPoi: {},
                });
                for (const item of section.items ?? []) {
                    const cc = item?.attributes?.price?.currencyCode ?? "";
                    const c = parseTemplateCurrencyCode(cc);
                    const varsByPoi = c.isVariable
                        ? makeVarsByPoi(c.index, this.stores, data.variationRows ?? [])
                        : {};
                    this.items.push({
                        id: this.sequence++,
                        isSection: false,
                        displayName: item?.labels[0]?.displayName ?? "",
                        description: item?.labels[0]?.description ?? "",
                        price: toPriceText(item?.attributes?.price),
                        isVariable: c.isVariable,
                        varsByPoi,
                    });
                }
            }
        }
    }

    setMode(mode: MODE): void {
        if (this.mode === mode) {
            return;
        }
        if (mode === "variables") {
            this.startEditVariables();
        } else {
            this.endEditVariables();
        }
    }
    startEditVariables(): void {
        this.sectionHeaders = [];
        this.poiPrices = [];
        for (const store of this.stores) {
            this.poiPrices.push({
                poiId: store.poiID,
                name: store.name,
                code: store.code,
            });
        }
        const itemMap: { [id: number]: FoodMenuRow } = {};
        for (const item of this.items) {
            itemMap[item.id] = item;
        }
        for (const item of this.items) {
            if (item.isSection) {
                this.sectionHeaders.push({
                    itemId: item.id,
                    sectionName: item.sectionName,
                    itemHeaders: [],
                });
                continue;
            }
            if (!item.isVariable) {
                continue;
            }
            const itemId = item.id;
            this.sectionHeaders[this.sectionHeaders.length - 1].itemHeaders.push({
                itemId,
                itemName: itemMap[itemId].displayName,
            });
            for (const store of this.stores) {
                const poiId = store.poiID;
                const poiPrice = this.poiPrices.find((r) => r.poiId === poiId);
                poiPrice[itemId] = item.varsByPoi[poiId] ?? "";
            }
            item.varsByPoi = {};
        }
        this.mode = "variables";
    }
    endEditVariables(): void {
        for (const row of this.items) {
            if (row.isSection || !row.isVariable) {
                continue;
            }
            for (const poi of this.poiPrices) {
                row.varsByPoi[poi.poiId] = poi[row.id];
            }
        }
        this.mode = "menus";
    }
    add(row: FoodMenuRow, isSection: boolean): void {
        const idx = this.findIndex(row) + 1;
        if (isSection) {
            this.items.splice(idx, 0, {
                id: this.sequence++,
                isSection: true,
                sectionName: "",
                varsByPoi: {},
            });
        } else {
            this.items.splice(idx, 0, {
                id: this.sequence++,
                isSection: false,
                displayName: "",
                description: "",
                isVariable: false,
                price: "",
                varsByPoi: {},
            });
        }
    }
    getSectionRange(idx: number): [number, number] {
        const items = this.items;
        // セクションの開始位置を調べる
        let start = idx;
        for (; 0 < start && !items[start].isSection; start--) {}
        // セクションの終了位置を調べる
        let end = idx + 1;
        for (; end < items.length && !items[end].isSection; end++) {}
        return [start, end];
    }
    swap(row: FoodMenuRow, isUp: boolean): void {
        if (!this.canSwap(row, isUp)) {
            return;
        }
        const items = this.items;
        const idx = items.findIndex((i) => i.id === row.id);
        if (isUp) {
            if (row.isSection) {
                const [start, end] = this.getSectionRange(idx);
                const [prevstart, _prevend] = this.getSectionRange(start - 1);
                const tmp = items.splice(start, end - start);
                items.splice(prevstart, 0, ...tmp);
            } else {
                [items[idx - 1], items[idx]] = [items[idx], items[idx - 1]];
            }
        } else {
            if (row.isSection) {
                const [start, end] = this.getSectionRange(idx);
                const [_nextstart, nextend] = this.getSectionRange(end);
                items.splice(nextend, 0, ...items.slice(start, end));
                items.splice(start, end - start);
            } else {
                [items[idx], items[idx + 1]] = [items[idx + 1], items[idx]];
            }
        }
    }
    getValidator(col: "sectionName" | "displayName" | "description"): (s: string) => string {
        if (col === "sectionName") {
            return (s: string = "") => {
                return s.length === 0
                    ? "セクション名を入力してください"
                    : 140 < s.length
                    ? "セクション名は140文字以内で入力してください"
                    : null;
            };
        } else if (col === "displayName") {
            return (s: string = "") => {
                return s.length === 0
                    ? "アイテム名を入力してください"
                    : 140 < s.length
                    ? "アイテム名は140文字以内で入力してください"
                    : null;
            };
        } else {
            return (s: string = "") =>
                1000 < s.length ? "アイテムの説明は1000文字以内で入力してください" : null;
        }
    }
    getValidatorMessage(
        col: "sectionName" | "displayName" | "description"
    ): (row: FoodMenuRow) => string {
        const validator = this.getValidator(col);
        return (row: FoodMenuRow) => {
            if (!row) return null;
            if (row.isSection !== (col === "sectionName")) {
                return null;
            }
            return validator(row[col] ?? "");
        };
    }
    getPriceValidator(s: string = ""): string {
        if (s.match(/^(?:|x|-)$/)) {
            return "";
        }
        return s.match(/^[0-9]{1,12}(?:\.[0-9]{1,9})?$/)
            ? ""
            : "料金は「x」「-」または 0 〜 999999999999.999999999 の間の数値で入力してください";
    }
    getPriceValidatorMessage(itemId: number): (row: PoiPrice) => string {
        return (row: PoiPrice) => {
            const s = row[itemId];
            return this.getPriceValidator(s);
        };
    }
    canSwap(row: FoodMenuRow, isUp: boolean): boolean {
        if (!row) return false;
        if (isUp) {
            if (this.items.length <= 1) {
                return false;
            }
            if (row.isSection) {
                // セクションの場合、一番上でなければ上に行ける
                return this.items[0].id !== row.id;
            } else {
                // メニューアイテムの場合、一番上とその次でなければ上に行ける
                return this.items[0].id !== row.id && this.items[1].id !== row.id;
            }
        } else {
            const idx = this.findIndex(row);
            // 一番最後の項目なら下に行けない
            if (idx + 1 === this.items.length) {
                return false;
            }
            if (row.isSection) {
                // セクションの場合、一番下のセクションでなければ下に行ける
                for (let i = idx + 1; i < this.items.length; i++) {
                    if (this.items[i].isSection) {
                        return true;
                    }
                }
                return false;
            } else {
                // メニューアイテムの場合、一番下でなければ下に行ける
                return true;
            }
        }
    }
    /** 指定した行またはセクションに料金が含まれているか判定する */
    hasPrice(row: FoodMenuRow): boolean {
        const idx = this.findIndex(row);
        if (row.isSection) {
            const [start, end] = this.getSectionRange(idx);
            for (let i = start; i < end; i++) {
                const contains = !!Object.values(this.items[i].varsByPoi).find((val) => val);
                if (contains) {
                    return true;
                }
            }
            return false;
        } else {
            return !!Object.values(this.items[idx].varsByPoi).find((val) => val);
        }
    }
    remove(row: FoodMenuRow): void {
        const idx = this.findIndex(row);
        if (row.isSection) {
            // セクションの場合、セクションごと消す
            let end = idx + 1;
            for (; end < this.items.length && !this.items[end].isSection; end++) {}
            this.items.splice(idx, end - idx);
        } else {
            this.items.splice(idx, 1);
        }
    }
    findIndex(row: FoodMenuRow): number {
        return this.items.findIndex((elem) => elem.id === row.id);
    }
    getMenuData(): EntitiesFoodMenuData {
        if (this.items.length === 0) {
            return {};
        }
        const mode = this.mode;
        this.setMode("menus");
        let count = 0;
        const menu: MybusinessFoodMenu = { labels: [{ displayName: "menu" }], sections: [] };
        let section: MybusinessFoodMenuSection = { items: [] };
        for (const item of this.items) {
            if (item.isSection) {
                section = { labels: [{ displayName: item.sectionName }], items: [] };
                menu.sections.push(section);
                continue;
            }
            const idx = item.isVariable ? count++ : 0;
            const mi: MybusinessFoodMenuItem = {
                labels: [{ displayName: item.displayName, description: item.description }],
                attributes: {
                    price: makeMoney(this.currencyCode, item.price, item.isVariable, idx),
                },
            };
            section.items.push(mi);
        }
        this.setMode("variables");
        const variationRows: EntitiesFoodMenuVariationRow[] = [];
        for (const pp of this.poiPrices) {
            const row: EntitiesFoodMenuVariationRow = { poiID: pp.poiId, variations: [] };
            for (const sh of this.sectionHeaders) {
                for (const ih of sh.itemHeaders) {
                    row.variations.push(pp[ih.itemId] ?? "");
                }
            }
            // すべて空文字の店舗の行は保存しない
            if (row.variations.filter((v) => v !== "").length === 0) {
                continue;
            }
            variationRows.push(row);
        }
        this.setMode(mode);
        return { menus: [menu], variationRows };
    }
    makeExportData(): ExportData {
        const mode = this.mode;
        // メニュー設定タブのエクスポートデータを作成
        this.setMode("menus");
        const menus: string[][] = [
            ["セクション名", "アイテム名", "アイテムの説明", "アイテムの価格", "可変項目設定の列"],
        ];
        let count = 1;
        const mItemIdToIndex: { [itemId: number]: number } = {};
        for (const item of this.items) {
            if (item.isSection) {
                menus.push([item.sectionName, "", "", "", ""]);
            } else {
                let index = "";
                if (item.isVariable) {
                    mItemIdToIndex[item.id] = count;
                    index = `${count}`;
                    count++;
                }
                menus.push(["", item.displayName, item.description, item.price, index]);
            }
        }
        // 可変項目設定タブのエクスポートデータを作成
        this.setMode("variables");
        const header0 = ["", "", "セクション名→"];
        const header1 = ["", "", "アイテム名→"];
        const header2 = ["店舗ID", "店舗コード", "店舗名"];
        for (const sh of this.sectionHeaders) {
            for (const ih of sh.itemHeaders) {
                header0.push(sh.sectionName);
                header1.push(ih.itemName);
                header2.push(`${mItemIdToIndex[ih.itemId]}`);
            }
        }
        const vars: string[][] = [header0, header1, header2];
        for (const poiPrice of this.poiPrices) {
            const row: string[] = [`${poiPrice.poiId}`, poiPrice.code, poiPrice.name];
            for (const sh of this.sectionHeaders) {
                for (const ih of sh.itemHeaders) {
                    row.push(poiPrice[ih.itemId]);
                }
            }
            vars.push(row);
        }
        this.setMode(mode);
        return { menus, vars };
    }
    static fromExportData(
        exportData: ExportData,
        stores: Store[],
        currencyCode: string
    ): { fmm?: FoodMenuModel; errors: { i: number; j: number; error: string }[] } {
        const errors: { i: number; j: number; error: string }[] = [];
        // 可変設定項目タブのインデックスが重複している場合はエラー
        const colline: string[] = exportData.vars[2];
        const indexCheck: { [index: number]: string } = {};
        for (let j = 3; j < colline.length; j++) {
            if (colline[j] === "") continue; // インデックスが空文字列の列は無視する
            const index = parseInt(colline[j]);
            if (isNaN(index)) {
                errors.push({ i: 2, j, error: "インデックスが数値ではありません" });
                continue;
            }
            if (index in indexCheck) {
                errors.push({ i: 2, j, error: "インデックスが重複しています" });
                continue;
            }
            indexCheck[index] = colline[j];
        }
        if (0 < errors.length) return { errors };

        const vars: { [poiId: number]: { [index: string]: string } } = {};
        for (let i = 3; i < exportData.vars.length; i++) {
            const line = exportData.vars[i];
            if (line[0] === "") continue; // poiId が空の列は無視する
            const poiId = parseInt(line[0], 10);
            if (isNaN(poiId)) {
                errors.push({ i, j: 0, error: "店舗IDが数値ではありません" });
                continue;
            }
            if (stores.findIndex((s) => s.poiID === poiId) < 0) {
                // storesに存在しないpoiId行は無視する
                continue;
            }
            vars[poiId] = {};
            for (let j = 3; j < colline.length; j++) {
                if (colline[j] === "") continue; // インデックスが空文字列の列は無視する
                vars[poiId][colline[j]] = line[j];
            }
        }
        if (0 < errors.length) return { errors };

        const fmm = new FoodMenuModel({ menus: [], variationRows: [] }, [], [], currencyCode);
        fmm.stores = stores;
        for (let i = 1; i < exportData.menus.length; i++) {
            const menu = exportData.menus[i];
            const [sectionName, displayName, description, price, stridx] = menu;
            if (sectionName !== "") {
                if (displayName !== "") {
                    errors.push({ i, j: 1, error: "セクション行にアイテム名は入力できません" });
                }
                if (description !== "") {
                    errors.push({ i, j: 2, error: "セクション行にアイテムの説明は入力できません" });
                }
                if (price !== "") {
                    errors.push({ i, j: 3, error: "セクション行に金額は入力できません" });
                }
                if (stridx !== "") {
                    errors.push({ i, j: 4, error: "セクション行に可変項目設定列は入力できません" });
                }
                fmm.items.push({
                    id: fmm.sequence++,
                    isSection: true,
                    sectionName,
                    varsByPoi: {},
                });
            } else {
                if (displayName === "") {
                    errors.push({ i, j: 1, error: "アイテム名は必須です" });
                }
                // 金額の空白やカンマを除いて整数or小数であればOKとする
                const cleanedPrice = price.replace(",", "").trim();
                if (/[^0-9.]/.test(cleanedPrice)) {
                    errors.push({ i, j: 3, error: "金額が数値ではありません" });
                }
                const isVariable = stridx !== "";
                const index = parseInt(stridx, 10);
                if (isVariable && isNaN(index)) {
                    errors.push({ i, j: 4, error: "可変項目設定列が数値ではありません" });
                }
                const varsByPoi = {};
                if (isVariable) {
                    for (const store of stores) {
                        varsByPoi[store.poiID] = vars[store.poiID]?.[index] ?? "";
                    }
                }
                fmm.items.push({
                    id: fmm.sequence++,
                    isSection: false,
                    displayName,
                    description,
                    price: cleanedPrice,
                    isVariable,
                    varsByPoi,
                });
            }
        }
        if (0 < errors.length) return { errors };
        return { fmm, errors };
    }
}

function makeVarsByPoi(
    index: number,
    stores: Store[],
    variationRows: EntitiesFoodMenuVariationRow[]
): { [poiId: number]: string } {
    const result: { [poiId: number]: string } = {};
    for (const store of stores) {
        const row = variationRows.find((vr) => vr.poiID === store.poiID);
        result[store.poiID] = row?.variations?.[index] ?? "";
    }
    return result;
}
function toPriceText(m: MybusinessMoney): string {
    const cc = parseTemplateCurrencyCode(m?.currencyCode ?? "");
    const units = m?.units ?? 0;
    const nanos = m?.nanos ?? 0;
    if (cc.currencyCode === "") {
        return "";
    }
    if (nanos === 0) {
        return `${units}`;
    }
    return `${units}.${String(nanos).padStart(9, "0")}`;
}

function parseTemplateCurrencyCode(str: string): TemplateCurrencyCode {
    if (!REGEXP_TEMPLATE_CURRENCY_CODE.test(str)) {
        return null;
    }
    const gs = REGEXP_TEMPLATE_CURRENCY_CODE_SPLIT.exec(str);
    if (!gs) {
        return null;
    }
    const idx = parseInt("0" + gs[3], 10);
    return { isVariable: gs[1] === "VAR", currencyCode: gs[2], index: idx };
}
class TemplateCurrencyCode {
    isVariable: boolean;
    currencyCode: string;
    index: number;
}
const REGEXP_TEMPLATE_CURRENCY_CODE = /^(?:FIX,(?:|[A-Z]{3}),|VAR,(?:|[A-Z]{3}),[0-9]+)$/;
const REGEXP_TEMPLATE_CURRENCY_CODE_SPLIT = /^(FIX|VAR),(|[A-Z]{3}),(|[0-9]+)$/;
function makeMoney(
    currencyCode: string,
    price: string,
    isVariable: boolean,
    index: number
): MybusinessMoney {
    const money: MybusinessMoney = {};
    const x = price === "" ? "" : currencyCode;
    money.currencyCode = isVariable ? `VAR,${x},${index}` : `FIX,${x},`;
    if (price !== "") {
        const [units, nanos] = price.split(".");
        money.units = units;
        if (nanos) {
            money.nanos = parseInt(nanos + "0".repeat(9 - nanos.length), 10);
        }
    }
    return money;
}
