import { h } from "vue";
import type { IconSet, IconProps } from "vuetify";
import YahooLogo from "./yahoo_logo.vue";

/**
 * 使い方
 * 1. svgを埋め込んだVueコンポーネントをicon配下に配置
 * 2. 下記の定数にコンポーネントを追加
 * 3. `<v-icon icon="custom:xxxx" />` という形で使用することができる
 * 4. 必要に応じて src/index.ts でエイリアスを設定する
 */
const customSvgNameToComponent = {
    YahooLogo,
};

export const CustomIcons: IconSet = {
    component: (props: IconProps) =>
        h(props.tag, [h(customSvgNameToComponent[props.icon as string], { class: "v-icon__svg" })]),
};
