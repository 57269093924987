<template>
  <div v-if="enabled" class="completeness">
    <h3>基本情報</h3>
    <div class="circle-area large-circle-area">
      <CompletionRate
        :mode="1"
        :stores="address"
        :all-stores="allStores"
        :failed-stores="failedAddress"
        :circle-size="'large'"
      />

      <CompletionRate
        :mode="2"
        :stores="phone"
        :all-stores="allStores"
        :failed-stores="failedPhone"
        :circle-size="'large'"
      />

      <CompletionRate
        :mode="3"
        :stores="webSite"
        :all-stores="allStores"
        :failed-stores="failedWebSite"
        :circle-size="'large'"
      />

      <CompletionRate
        :mode="4"
        :stores="businessDescription"
        :all-stores="allStores"
        :failed-stores="failedBusinessDescription"
        :circle-size="'large'"
      />
    </div>
    <hr />
    <h3>各曜日の営業時間</h3>
    <div class="circle-area small-circle-area">
      <CompletionRate
        :mode="5"
        :stores="businessHoursMon"
        :all-stores="allStores"
        :failed-stores="failedBusinessHoursMon"
        :circle-size="'small'"
      />

      <CompletionRate
        :mode="6"
        :stores="businessHoursTues"
        :all-stores="allStores"
        :failed-stores="failedBusinessHoursTues"
        :circle-size="'small'"
      />

      <CompletionRate
        :mode="7"
        :stores="businessHoursWed"
        :all-stores="allStores"
        :failed-stores="failedBusinessHoursWed"
        :circle-size="'small'"
      />

      <CompletionRate
        :mode="8"
        :stores="businessHoursThurs"
        :all-stores="allStores"
        :failed-stores="failedBusinessHoursThurs"
        :circle-size="'small'"
      />

      <CompletionRate
        :mode="9"
        :stores="businessHoursFri"
        :all-stores="allStores"
        :circle-size="'small'"
        :failed-stores="failedBusinessHoursFri"
      />

      <CompletionRate
        :mode="10"
        :stores="businessHoursSat"
        :all-stores="allStores"
        :circle-size="'small'"
        :failed-stores="failedBusinessHoursSat"
      />

      <CompletionRate
        :mode="11"
        :stores="businessHoursSun"
        :all-stores="allStores"
        :circle-size="'small'"
        :failed-stores="failedBusinessHoursSun"
      />
    </div>
  </div>
</template>
<script lang="ts">
import type { EntitiesCompany } from "@/types/ls-api";
import { defineComponent } from "vue";
import CompletionRate from "./performance-completion-rate.vue";
import { useIndexedDb } from "@/storepinia/idxdb";
export default defineComponent({
  components: {
    CompletionRate,
  },

  computed: {
    company(): EntitiesCompany {
      return useIndexedDb().company;
    },
    enabled(): boolean {
      return "completion" in this.company && this.company.completion.allStores > 0;
    },
    address(): number {
      return (
        this.company.completion?.allStores - this.company.completion?.failedAddressStoreList?.length
      );
    },

    failedAddress(): number[] {
      return this.company.completion?.failedAddressStoreList;
    },

    phone(): number {
      return (
        this.company.completion?.allStores - this.company.completion?.failedPhoneStoreList?.length
      );
    },

    failedPhone(): number[] {
      return this.company.completion?.failedPhoneStoreList;
    },

    webSite(): number {
      return (
        this.company.completion?.allStores - this.company.completion?.failedWebSiteStoreList?.length
      );
    },

    failedWebSite(): number[] {
      return this.company.completion?.failedWebSiteStoreList;
    },

    businessDescription(): number {
      return (
        this.company.completion?.allStores -
        this.company.completion?.failedBusinessDescriptionStoreList?.length
      );
    },

    failedBusinessDescription(): number[] {
      return this.company.completion?.failedBusinessDescriptionStoreList;
    },

    businessHoursMon(): number {
      return (
        this.company.completion?.allStores -
        this.company.completion?.failedBusinessHoursStoreListByDay.mon?.length
      );
    },

    failedBusinessHoursMon(): number[] {
      return this.company.completion?.failedBusinessHoursStoreListByDay.mon;
    },

    businessHoursTues(): number {
      return (
        this.company.completion?.allStores -
        this.company.completion?.failedBusinessHoursStoreListByDay.tues?.length
      );
    },

    failedBusinessHoursTues(): number[] {
      return this.company.completion?.failedBusinessHoursStoreListByDay.tues;
    },

    businessHoursWed(): number {
      return (
        this.company.completion?.allStores -
        this.company.completion?.failedBusinessHoursStoreListByDay.wed?.length
      );
    },

    failedBusinessHoursWed(): number[] {
      return this.company.completion?.failedBusinessHoursStoreListByDay.wed;
    },

    businessHoursThurs(): number {
      return (
        this.company.completion?.allStores -
        this.company.completion?.failedBusinessHoursStoreListByDay.thurs?.length
      );
    },

    failedBusinessHoursThurs(): number[] {
      return this.company.completion?.failedBusinessHoursStoreListByDay.thurs;
    },

    businessHoursFri(): number {
      return (
        this.company.completion?.allStores -
        this.company.completion?.failedBusinessHoursStoreListByDay.fri?.length
      );
    },

    failedBusinessHoursFri(): number[] {
      return this.company.completion?.failedBusinessHoursStoreListByDay.fri;
    },

    businessHoursSat(): number {
      return (
        this.company.completion?.allStores -
        this.company.completion?.failedBusinessHoursStoreListByDay.sat?.length
      );
    },

    failedBusinessHoursSat(): number[] {
      return this.company.completion?.failedBusinessHoursStoreListByDay.sat;
    },

    businessHoursSun(): number {
      return (
        this.company.completion?.allStores -
        this.company.completion?.failedBusinessHoursStoreListByDay.sun?.length
      );
    },

    failedBusinessHoursSun(): number[] {
      return this.company.completion?.failedBusinessHoursStoreListByDay.sun;
    },

    allStores(): number {
      return this.company.completion?.allStores;
    },
  },
});
</script>
<style lang="scss" scoped>
.completeness {
  padding-bottom: 13px;
}

h3 {
  margin: 0 0 30px 20px;
  font-weight: 500;
  padding-top: 13px;
  font-size: 24px;
  color: #333;
}

.circle-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.large-circle-area {
  gap: 50px;
}

hr {
  border-top: 1px solid #d6d6d6;
  margin: 13px 10px 0 10px;
}

.small-circle-area {
  gap: 10px;
}
</style>
