import { apiBase } from "@/const";
import { requiredAuth } from "@/helpers";

import type { EntitiesStoresResponse, EntitiesStore } from "@/types/ls-api";

export const api = {
    listStores,
};

/**
 * 店舗一覧を取得する
 * @param poiGroupId 企業ID
 * @param uuid 店舗UUID (省略可能)
 * @returns 店舗一覧
 */
async function listStores(poiGroupId: number, uuid: string = ""): Promise<EntitiesStore[]> {
    const url = `${apiBase}/companies/${poiGroupId}/stores`;
    let queryParameters = {};
    if (uuid) {
        queryParameters = { uuid };
    }
    const res = await requiredAuth<EntitiesStoresResponse>("get", url, queryParameters);
    return res?.data?.stores ?? [];
}
