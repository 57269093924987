<template>
  <input
    v-model="date"
    type="date"
    :min="min"
    :max="max"
    placeholder="年/月/日"
    :required="required"
    :disabled="disabled || readonly"
    @keydown="keydowned"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import dayjs from "dayjs";
import isbetWeen from "dayjs/plugin/isBetween";
dayjs.extend(isbetWeen);

export const yyyymmdd = "YYYY-MM-DD";
export default defineComponent({
  props: {
    modelValue: { type: String, default: dayjs().format("YYYY-MM-DD") },
    min: { type: String, default: "2000-01-01" },
    max: { type: String, default: "2121-12-31" },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      keydown: { arrow: false, del: false },
      forceUpdate: false,
      forceUpdateIntermediateString: "",
    };
  },
  computed: {
    date: {
      get() {
        if (this.forceUpdate) {
          this.updateForceUpdateAtNextTick();
          return this.forceUpdateIntermediateString;
        }
        // value のフォーマットがおかしければ修正する
        const date = dayjs(this.modelValue);
        return date.isValid() ? date.format(yyyymmdd) : "";
      },
      set(val: string) {
        const { arrow, del } = this.keydown;
        this.keydown = { arrow: false, del: false };
        // console.log("date set", val, "modelValue", this.modelValue, this.min, this.max, arrow, del);
        // ピッカーの「削除」を押したり、2/29などの不正な日付が入力されると空文字が渡ってくるので、その対処をする
        if (val === "") {
          // 必須ではないのであれば一旦 modelValue を表示してから空文字にする
          if (!this.required) {
            this.forceUpdate = true;
            this.forceUpdateIntermediateString = this.modelValue;
            if (this.modelValue !== val) {
              this.$emit("update:modelValue", val);
            }
            return;
          }
          // 以下は必須の場合の処理
          const pickerdel = !arrow && !del; // キー押下でなければ、ピッカーの削除ボタンを押したと判断する
          if (del || pickerdel) {
            // 削除キー、またはピッカーの削除ボタンを押した場合は値を変えない
            val = this.modelValue;
          } else if (arrow) {
            // 矢印キーを押した場合
            const prev = dayjs(this.modelValue);
            if ([2, 4, 6, 9, 11].includes(prev.get("month") + 1)) {
              // 小の月で月初であれば月末に、そうでなければ月初にする
              if (prev.get("date") === 1) {
                val = prev.endOf("month").format(yyyymmdd);
              } else {
                val = prev.startOf("month").format(yyyymmdd);
              }
            } else {
              // 大の月であれば1日減らす
              val = prev.add(-1, "day").format(yyyymmdd);
            }
          }
          // input 要素を強制的に再描画させる
          this.forceUpdate = true;
        }
        // min ~ max の範囲内に収める
        const beforeAdjustmentVal = val;
        val = this.max && this.max < val ? this.max : val;
        val = this.min && val < this.min ? this.min : val;
        // 調整前の値と調整後の値が異なるなら、input 要素を強制的に再描画させる
        if (beforeAdjustmentVal !== val) {
          this.forceUpdate = true;
        }
        if (this.modelValue !== val) {
          this.$emit("update:modelValue", val);
        }
      },
    },
  },
  methods: {
    updateForceUpdateAtNextTick() {
      this.$nextTick(() => {
        this.forceUpdate = false;
        this.forceUpdateIntermediateString = "";
      });
    },
    keydowned(e: KeyboardEvent) {
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        this.keydown.arrow = true;
      } else if (e.key === "Delete" || e.key === "Backspace") {
        this.keydown.del = true;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

input[type="date"] {
  width: 7.2em;
  height: 2em;
  // background-color: white;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #dbdbdb;
  &:focus {
    border-color: color.$primary;
    outline: none;
  }
}
</style>
