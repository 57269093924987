import type {
    EntitiesFoodMenuGroup,
    EntitiesFoodMenuStore,
    MybusinessFoodMenu,
    MybusinessMenuLabel,
    MybusinessMoney,
} from "@/types/ls-api";

export type Group = EntitiesFoodMenuGroup & {
    stores?: Status[];
    counts?: Counts;
};
export interface Counts {
    ready: number;
    running: number;
    failure: number;
    success: number;
    canceled: number;
    modified: number;
}
export type Status = EntitiesFoodMenuStore & {
    name?: string;
    code?: string;
};
/** メニュー内のセクション名とアイテム名を、重複しない名称に変更する */
export function renameAllSectionsAndItems(menu: MybusinessFoodMenu): void {
    const secs: string[] = [];
    for (const sec of menu.sections ?? []) {
        rename(sec, secs);
        const items: string[] = [];
        for (const item of sec.items ?? []) {
            rename(item, items);
        }
    }
}
/** labelsが既出の名称の場合、プライム "′" を付与する */
export function rename(obj: { labels?: MybusinessMenuLabel[] }, knowns: string[]): void {
    if (!obj?.labels[0]?.displayName) {
        obj.labels = [{ displayName: "" }];
    }
    let name = obj.labels[0].displayName;
    while (knowns.includes(name)) {
        name = name + "′";
    }
    obj.labels[0].displayName = name;
    knowns.push(name);
}
/** 名前の配列を作成する。出現頻度の高い配列を優先する */
export function makeOrdersNameList<T>(sss: T[][]): T[] {
    if (sss.length === 0) {
        return [];
    }
    const hist: { [key: string]: number } = {};
    for (const ss of sss) {
        const key = ss.join("\t");
        hist[key] = hist[key] ? hist[key] + 1 : 1;
    }
    const longest = Math.max(...Object.values(hist));
    let main = sss.find((v) => hist[v.join("\t")] === longest);
    delete hist[main.join("\t")];
    while (0 < Object.keys(hist).length) {
        const longest = Math.max(...Object.values(hist));
        const sub = sss.find((v) => hist[v.join("\t")] === longest);
        const reminder = sub.filter((s) => !main.includes(s));
        main = main.concat(...reminder);
        delete hist[sub.join("\t")];
    }
    return main;
}
/** セクション名の配列と、セクション名をキーにしたアイテム名の配列を作成する */
export function extractNameOrders(
    poiIds: number[],
    menus: {
        [poiId: number]: MybusinessFoodMenu;
    }
): [string[], { [secname: string]: string[] }] {
    const secnamess: string[][] = [];
    const x: { [secname: string]: string[][] } = {};
    for (const poiId of poiIds) {
        const menu = menus[poiId];
        const secnames: string[] = [];
        for (const sec of menu.sections ?? []) {
            const secname = sec.labels[0].displayName;
            secnames.push(secname);
            const itemnames: string[] = [];
            for (const item of sec.items ?? []) {
                itemnames.push(item.labels[0].displayName);
            }
            if (!x[secname]) {
                x[secname] = [];
            }
            x[secname].push(itemnames);
        }
        secnamess.push(secnames);
    }
    const result: [string[], { [secname: string]: string[] }] = [[], {}];
    result[0] = makeOrdersNameList(secnamess);
    for (const secname of result[0]) {
        result[1][secname] = makeOrdersNameList(x[secname]);
    }
    return result;
}

/** 料金の表を作成する */
export function exportAllPricesCsv(
    secNames: string[],
    mItemNames: { [secname: string]: string[] },
    groups: Group[],
    menus: { [poiId: number]: MybusinessFoodMenu }
): string[][] {
    const header0 = ["", "", "", ""];
    const header1 = ["店舗ID", "店舗コード", "店舗名", "メニュー名"];
    for (const sec of secNames) {
        for (const item of mItemNames[sec]) {
            header0.push(sec);
            header1.push(item);
        }
    }
    const list: string[][] = [header0, header1];
    for (const group of groups) {
        for (const s of group.stores) {
            const prices: string[] = [];
            prices.push(`${s.poiID}`);
            prices.push(s.code);
            prices.push(s.name);
            prices.push(group.title);
            const menu = menus[s.poiID] ?? { sections: [] };
            for (const secname of secNames) {
                const sec = menu.sections.find((s) => s.labels[0].displayName === secname);
                const itemNames = mItemNames[secname];
                for (const itemname of itemNames) {
                    if (!sec) {
                        prices.push("");
                        continue;
                    }
                    const item = sec.items.find((i) => i.labels[0].displayName === itemname);
                    if (!item) {
                        prices.push("");
                    } else {
                        prices.push(toPriceText(item.attributes?.price));
                    }
                }
            }
            list.push(prices);
        }
    }
    return list;
}

/** MybusinessMoney を文字列にする */
function toPriceText(m: MybusinessMoney): string {
    if (!m) {
        return "";
    }
    const units = m.units ?? "";
    const nanos = m.nanos ?? 0;
    if (nanos === 0) {
        return units;
    }
    return `${units}.${String(nanos).padStart(9, "0")}`;
}
