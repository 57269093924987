import type { AxiosError } from "axios";
import { requiredAuth } from "@/helpers";
import { Component, Vue, toNative } from "vue-facing-decorator";
import { useSnackbar } from "@/storepinia/snackbar";
import TipableTitleGroup from "@/models/tipable-title-group";
import type {
    StorageReportFilesResponse,
    StorageReportFile,
    ReportgetReportDownloadResponse,
} from "@/types/ls-api";
import wordDictionary from "@/word-dictionary";

export interface CreateReportRequestBody {
    AreaID?: number;
    poiID?: number;
    PreviousStartDate: string;
    PreviousEndDate: string;
    CurrentStartDate: string;
    CurrentEndDate: string;
}

const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));

@Component({})
class ReportFiles extends Vue {
    poiGroupId: number;
    files: StorageReportFile[] = [];
    loading: boolean = false;
    errMessage: string = "";
    dict: Record<string, unknown> = wordDictionary.reportFiles;
    stateDict = {
        INPROGRESS: "処理中",
        FINISHED: "作成完了",
        FAILED: "作成失敗",
    };
    titles = new TipableTitleGroup([
        wordDictionary.reportFiles.name,
        wordDictionary.reportFiles.status,
        wordDictionary.reportFiles.download,
    ]);

    addSnackbarMessages = useSnackbar().addSnackbarMessages;

    async created(): Promise<void> {
        this.loading = true;
        this.poiGroupId = parseInt(this.$route.params.poiGroupId as string, 10);
        if (this.$route.params.reportCreate) {
            const requestBody: CreateReportRequestBody = JSON.parse(
                this.$route.params.reportCreate as string
            );
            await this.execReportCreate(requestBody);
            await sleep(4000);
        }

        const response = await requiredAuth<StorageReportFilesResponse>(
            "get",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/reports`
        );
        this.files = response?.data?.files || [];
        this.loading = false;
    }

    async download(file: StorageReportFile): Promise<void> {
        const params = {
            key: file.key,
            filename: file.title,
        };
        requiredAuth<ReportgetReportDownloadResponse>(
            "get",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/reports/download`,
            params
        )
            .then((response) => {
                // 受信したURLを使って改めてダウンロードを実行
                window.open(response.data.url, "_self");
            })
            .catch((e) => {
                console.log(e);
                throw Error("ファイルダウンロード時にエラーが発生しました。");
            });
    }

    async execReportCreate(params: CreateReportRequestBody): Promise<void> {
        requiredAuth<StorageReportFile>(
            "post",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
                this.$route.params.poiGroupId
            }/reports`,
            null,
            params
        )
            .then((response) => {
                if (response.status === 200 && response.data.status === "INPROGRESS") {
                    this.addSnackbarMessages({
                        text: "レポートを予約しました",
                        color: "success",
                    });
                    return;
                }
                this.addSnackbarMessages({
                    text: "レポートの予約に失敗しました",
                    color: "danger",
                });
            })
            .catch((e) => {
                const axiosError = e as AxiosError<any>;
                if (
                    axiosError.response?.status === 409 &&
                    axiosError.response?.data?.errorMessage === "指定のレポートは既に作成済み"
                ) {
                    this.addSnackbarMessages({
                        text: "指定されたレポートは既に作成済みです",
                        color: "warning",
                    });

                    return;
                }
                console.log(e);
                throw Error("レポートの予約に失敗しました");
            });
    }

    get perPage(): 20 {
        return 20;
    }

    get currentPage(): 1 {
        return 1;
    }
}
export default toNative(ReportFiles);
