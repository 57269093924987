<template>
  <div>
    <div v-if="files.length === 0 && !loading">{{ dict.resultMessageLoading }}</div>
    <div v-else>
      <o-table paginated :data="files" :current-page="currentPage" :loading="loading">
        <template #header="props">
          <span v-if="titles.tooltipExists(props.column.label)">
            <span>{{ props.column.label }}</span>
            <ToolTipIcon :label="titles.supplement(props.column.label)" position="bottom" />
          </span>
          <span v-if="!titles.tooltipExists(props.column.label)">
            {{ props.column.name }}
          </span>
        </template>
        <o-table-column v-slot="props" field="name" :label="titles.asNames[0]">
          <span :data-testid="`title-${props.row.title}`">{{ props.row.title }}</span>
        </o-table-column>
        <o-table-column v-slot="props" field="name" :label="titles.asNames[1]">
          <span :data-testid="`status-${props.row.title}`">{{ stateDict[props.row.status] }}</span>
        </o-table-column>
        <o-table-column v-slot="props" field="name" :label="titles.asNames[2]">
          <button
            class="button is-primary"
            :data-testid="`button-${props.row.title}`"
            :disabled="props.row.status != 'FINISHED'"
            @click="download(props.row)"
          >
            <v-icon color="white">fas fa-download</v-icon>
          </button>
        </o-table-column>
      </o-table>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
