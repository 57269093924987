<template>
  <v-dialog :model-value="modelValue" persistent width="500" scrollable>
    <v-card>
      <v-card-title v-if="0 < title.length" class="text-h5">{{ title }}</v-card-title>
      <v-progress-linear
        v-if="percentage !== null"
        :model-value="percentage"
        color="light-blue"
      ></v-progress-linear>
      <v-card-text>
        <div v-for="(text, index) in message.split('<br/>')" :key="index">
          <!--eslint-disable vue/no-v-html-->
          <div class="dialog-paragraph" v-html="sanitize(text)"></div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="0 < cancelButton.length"
          :disabled="cancelButtonDisabled"
          variant="text"
          @click="$emit('cancel')"
        >
          {{ cancelButton }}
        </v-btn>
        <v-btn
          v-if="0 < submitButton.length"
          :disabled="submitButtonDisabled"
          color="primary"
          variant="elevated"
          @click="$emit('submit')"
        >
          {{ submitButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import sanitizeHtml from "sanitize-html";

export default defineComponent({
  props: {
    modelValue: { type: Boolean, required: true },
    title: { type: String, required: false, default: "" },
    percentage: { type: Number, required: false, default: null },
    message: { type: String, required: false, default: "" },
    submitButton: { type: String, required: false, default: "" },
    submitButtonDisabled: { type: Boolean, required: false, default: false },
    cancelButton: { type: String, required: false, default: "" },
    cancelButtonDisabled: { type: Boolean, required: false, default: false },
  },
  emits: ["cancel", "submit"],
  async created() {},
  methods: {
    sanitize(text: string): string {
      return sanitizeHtml(text, {
        allowedTags: ["a", "b", "div", "hr", "br"],
        allowedAttributes: {
          a: ["href", "target"],
        },
        allowedSchemes: ["https"],
      });
    },
  },
});
</script>
<style lang="scss" scoped></style>
