import { Component, Vue, toNative } from "vue-facing-decorator";
import { requiredAuth } from "@/helpers";
import Notification from "./Notification.vue";
import type { FacebookSDK } from "@/types/Facebook";

import wordDictionary from "@/word-dictionary";
import { getter, action } from "@/storepinia/idxdb";
import { currentTheme } from "@/components/shared/theme";
import { useTheme } from "vuetify";

@Component({
    components: { Notification },
})
class Topbar extends Vue {
    clearAuth = action().clearAuth;
    isDrawerOpened = getter().isDrawerOpened;
    isMobile = getter().isMobile;
    setDrawerMenu = action().setDrawerMenu;
    root: string =
        import.meta.env.VITE_APP_ROOT_PATH == null ? "/" : import.meta.env.VITE_APP_ROOT_PATH;
    isLoading: boolean = false;

    dict: Record<string, unknown> = wordDictionary.topbar;
    theme = currentTheme();
    vuetifyTheme = useTheme();
    manualUrl = "documents/" + this.theme.appName + "_マニュアル.pdf";

    toggle() {
        this.setDrawerMenu(!this.isDrawerOpened);
    }

    async logout(): Promise<void> {
        try {
            this.isLoading = true;
            this.fbLogout();
            if (sessionStorage.AccessToken != null) {
                await requiredAuth<any>(
                    "post",
                    `${import.meta.env.VITE_APP_API_BASE}v1/auth/logout`,
                    null,
                    {
                        accessToken: sessionStorage.AccessToken,
                    }
                );
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.isLoading = false;
            sessionStorage.clear();
            window.location.hash = "#/login";
        }
    }

    async fbLogout(): Promise<void> {
        // 企業管理画面でログインしていないようであれば何もしない
        if (typeof window.global.FB === "undefined") {
            return;
        }
        // ログイン状況を見てconnectedになっていたらFacebookアプリからのログアウト処理を実施
        window.global.FB.getLoginStatus((response: FacebookSDK["statusResponse"]) => {
            if (response?.status == "connected") {
                window.global.FB.logout(() => {});
            }
        });
    }
}
export default toNative(Topbar);
