<template>
  <div class="mobile-preview-area">
    <p class="p-3 font-weight-bold">投稿メディア選択</p>
    <div class="pl-1">
      <post-preview
        v-model="gbpStepEnabled"
        :name="postSteps.PostCustomize.childStep[0].name"
        :icon="platformList.google.icon"
        :color="platformList.google.color"
        :is-mobile="isMobile"
        :readonly="readonly"
        @update:model-value="changePostData"
        @update-step="updateStepGBP"
      />
      <post-preview
        v-model="yahooStepEnabled"
        :name="postSteps.PostCustomize.childStep[1].name"
        :icon="platformList.yahoo.icon"
        :color="platformList.yahoo.color"
        :disabled="isDisabled('yahoo')"
        :is-mobile="isMobile"
        :readonly="readonly"
        @update:model-value="changePostData"
        @update-step="updateStepYahoo"
      />
      <post-preview
        v-model="igStepEnabled"
        :name="postSteps.PostCustomize.childStep[2].name"
        :icon="platformList.instagram.icon"
        :color="platformList.instagram.color"
        :disabled="isDisabled('instagram')"
        :is-mobile="isMobile"
        :readonly="readonly"
        @update:model-value="changePostData"
        @update-step="updateStepIG"
      />
      <post-preview
        v-model="fbStepEnabled"
        :name="postSteps.PostCustomize.childStep[3].name"
        :icon="platformList.facebook.icon"
        :color="platformList.facebook.color"
        :disabled="isDisabled('facebook')"
        :is-mobile="isMobile"
        :readonly="readonly"
        @update:model-value="changePostData"
        @update-step="updateStepFB"
      />
    </div>
    <p class="p-3 font-weight-bold">投稿内容カスタマイズ/プレビュー確認</p>
    <v-tabs v-model="previewTab" slider-color="primary">
      <v-tab
        v-for="(platform, key) in platformList"
        :key="key"
        :value="key"
        :disabled="isDisabled(key)"
      >
        <v-icon
          :icon="platform.icon"
          :color="platform.color"
          :size="platform.icon == '$yahoo' ? 30 : 24"
        />
      </v-tab>
    </v-tabs>
    <v-window v-model="previewTab">
      <v-window-item value="google" eager>
        <gmb-preview
          ref="preview_0"
          v-model="googlePost"
          :base-text="baseText.gmbBaseText"
          :target-name="targetName"
          :file-selections="files.googleFiles"
          :step="postSteps.PostCustomize.childStep[0]"
          :aspect-no-check="gbpAspectNoCheck"
          :is-edit="isEdit"
          :is-mobile="isMobile"
          :readonly="readonly"
          @update:model-value="changePostData"
          @update-step="updateStepGBP"
        />
      </v-window-item>
      <v-window-item value="yahoo" eager>
        <yahoo-preview
          ref="preview_1"
          v-model="yahooPost"
          :base-text="baseText.yahooBaseText"
          :target-name="targetName"
          :file-selections="files.yahooFiles"
          :step="postSteps.PostCustomize.childStep[1]"
          :is-edit="isEdit"
          :is-mobile="isMobile"
          :readonly="readonly"
          @update:model-value="changePostData"
          @update-step="updateStepYahoo"
        />
      </v-window-item>
      <v-window-item value="instagram" eager>
        <ig-preview
          ref="preview_2"
          v-model="instagramText"
          :base-text="baseText.igBaseText"
          :target-name="targetName"
          :file-selections="files.instagramFiles"
          :step="postSteps.PostCustomize.childStep[2]"
          :aspect-no-check="false"
          :is-mobile="isMobile"
          :readonly="readonly"
          @update:model-value="changePostData"
          @update-step="updateStepIG"
        />
      </v-window-item>
      <v-window-item value="facebook" eager>
        <fb-preview
          ref="preview_3"
          v-model="facebookText"
          :base-text="baseText.fbBaseText"
          :target-name="targetName"
          :file-selections="files.facebookFiles"
          :step="postSteps.PostCustomize.childStep[3]"
          :aspect-no-check="false"
          :is-mobile="isMobile"
          :readonly="readonly"
          @update:model-value="changePostData"
          @update-step="updateStepFB"
        />
      </v-window-item>
    </v-window>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import PostPreviewArea from "./post-preview-area.vue";
import PostPreview from "./post-preview.vue";

export default defineComponent({
  components: {
    PostPreview,
  },
  // 基本はPC版と同じ動作をする
  extends: PostPreviewArea,
  data() {
    return {
      isMobile: true,
      previewTab: "google",
      platformList: {
        google: {
          icon: "$google",
          color: "#34a853",
        },
        yahoo: {
          icon: "$yahoo",
          color: "#ff0033",
        },
        instagram: {
          icon: "$instagram",
          color: "#E1306C",
        },
        facebook: {
          icon: "$facebook",
          color: "#2577F2",
        },
      },
    };
  },
  methods: {
    isDisabled(platform: string): boolean {
      switch (platform) {
        case "google":
          return false;
        case "yahoo":
          return !this.platformEnabled.yahooPlace;
        case "instagram":
          return !this.platformEnabled.instagram;
        case "facebook":
          return !this.platformEnabled.facebook;
        default:
          return true;
      }
    },
  },
});
</script>
<style scoped lang="scss">
:deep(.v-switch__track) {
  opacity: 0.9;
}
</style>
