<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="341.333"
    height="192"
    viewBox="0 0 341.333 192"
  >
    <path
      d="M0.6,12.9C0,16-0.1,18.8,0.1,19c0.1,0.3,8.7,1.6,18.7,2.9C28.9,23.1,38.5,25,39.9,26c5.7,3.6,41.2,45.6,62.3,73.9l10.3,13.7
    v23c0,21.5-0.1,23.1-3,25.8c-3.3,3-11.5,5.3-25.1,6.7c-7,0.7-9,1.4-9,3.1c0,1.4-0.4,3.8-0.9,5.7l-0.9,3.1l61-0.9l61.2-0.9l0.9-5.6
    c1-6.7,1.7-6.4-21.8-7.8l-16.8-0.9v-51.5l13.3-13C184.9,87,214,63,229,52.9c7.4-5,11.1-6.4,24.7-9.1c16-3.3,16.1-3.3,20.2-9.4l4.1-6
    H166.7v3.1c0.1,8.7,0.6,9,16.5,10.8c15.4,1.9,19.1,2.7,19.1,4.4c0,4-54.3,51.8-56.7,50c-1.4-1-41.1-52.8-49.3-64.6
    C92.4,27,89.5,22.3,90,22c0.3-0.3,9.1-1.3,19.7-2.1c10.6-0.9,19.2-1.7,19.4-1.9c0.1-0.1,0.6-2.7,1.1-5.7l1-5.3H1.6L0.6,12.9
    L0.6,12.9z M292.9,25.8c-2.7,8-32.5,106.4-32.7,107.9c-0.1,1.4,3.4,3.1,10.1,5.3l10.3,3.1L308,95.7c15.1-25.7,28.9-48.9,30.7-51.6
    c2.6-3.6,3-5.1,1.6-5.8c-2-1.1-44.1-14.1-45.6-14.1C294,24.1,293.1,25,292.9,25.8L292.9,25.8z M244.4,158.7c-1,2.4-3.3,7.6-4.8,11.4
    c-1.7,3.8-2.9,7.1-2.6,7.4c1,1.1,26.8,8,27.4,7.4c0.3-0.3,2.9-5.4,5.4-11.3l5-10.7l-14.3-4.4l-14.3-4.4L244.4,158.7L244.4,158.7z"
    />
  </svg>
</template>
