import type { SnackbarToast } from "@/components/shared/snackbar/snackbar-shared";
import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";

export const useSnackbar = defineStore("snackbar", {
    state: () => ({
        snackbarMessages: [] as SnackbarToast[],
    }),
    getters: {
        isSnackbarShow(): boolean {
            // console.log(this.snackbarMessages.length);
            return this.snackbarMessages.length > 0;
        },
    },
    actions: {
        addSnackbarMessages(message: SnackbarToast): void {
            // console.log("state addSnackbarMessages", JSON.stringify(message));
            const newMessage = Object.assign(message, { id: uuidv4() });
            this.snackbarMessages.push(newMessage);
        },
        removeSnackbarMessages(targetId: string): void {
            // console.log("state removeSnackbarMessages", targetId);
            this.snackbarMessages.forEach((v, index) => {
                if (v.id === targetId) {
                    this.snackbarMessages.splice(index, 1);
                }
            });
        },
        markSnackbarMessages(targetId: string): void {
            // console.log("state markSnackbarMessages", targetId);
            this.snackbarMessages.forEach((v, index) => {
                if (v.id === targetId) {
                    v.marked = true;
                }
            });
        },
        resetSnackbarQueue(): void {
            // console.log("state resetSnackbarQueue");
            this.snackbarMessages.length = 0;
        },
    },
});
