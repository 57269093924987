<template>
  <div class="circular-container">
    <v-progress-circular
      :model-value="value"
      class="completion-circle"
      :class="circleSize + '-circle'"
      :size="size"
      :width="10"
      :color="color"
    >
      <v-icon
        v-if="value >= 70"
        class="checkmark"
        color="#e1f5f0"
        :size="circleSize === 'large' ? 132 : 88"
      >
        fas fa-check
      </v-icon>
      <div class="description">
        <div class="title">
          <v-icon v-if="icon" class="title-icon" size="32">{{ icon }}</v-icon>
          <span class="title-label">{{ label }}</span>
        </div>
        <div class="percentage">
          <span class="percentage-value">{{ value }}</span>
          <span class="unit">%</span>
        </div>
        <div class="stores_percentage">
          <span class="stores">{{ stores }}</span>
          <span class="all_stores">{{ allStores }}店舗</span>
        </div>
      </div>
    </v-progress-circular>
    <div v-if="stores != allStores">
      <router-link
        :to="{
          name: 'StoresSpreadForm',
          params: { poiGroupId: `${company.poiGroupID}` },
          query: { failedType: `${label}`, failedStores: `${failedStores}` },
        }"
      >
        <a class="link-to-stores" @click="beforeEnter">未記入の店舗へ</a>
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import wordDictionary from "@/word-dictionary";
import type { EntitiesCompany } from "@/types/ls-api";
import { useIndexedDb } from "@/storepinia/idxdb";

export default defineComponent({
  props: {
    mode: { type: Number, default: null },
    stores: { type: Number, default: null },
    allStores: { type: Number, default: null },
    failedStores: { type: Array, default: null },
    isLoading: { type: Boolean, default: null },
    circleSize: { type: String, default: "large" },
  },

  data: () => {
    return {
      dict: wordDictionary.metric.completeness,
    };
  },

  computed: {
    company(): EntitiesCompany {
      return useIndexedDb().company;
    },

    label(): string {
      switch (this.$props.mode) {
        case 1:
          return this.dict.address;
        case 2:
          return this.dict.phone;
        case 3:
          return this.dict.web_site;
        case 4:
          return this.dict.business_description;
        case 5:
          return this.dict.weekdays.MONDAY;
        case 6:
          return this.dict.weekdays.TUESDAY;
        case 7:
          return this.dict.weekdays.WEDNESDAY;
        case 8:
          return this.dict.weekdays.THURSDAY;
        case 9:
          return this.dict.weekdays.FRIDAY;
        case 10:
          return this.dict.weekdays.SATURDAY;
        case 11:
          return this.dict.weekdays.SUNDAY;
      }
      return "";
    },
    value(): number {
      return Math.floor((this.$props.stores / this.$props.allStores) * 100);
    },
    color(): string {
      const val = this.value;
      if (val >= 70) {
        return "#68CBB6";
      }
      if (val <= 30) {
        return "#E95454";
      }
      return "#f6ab00";
    },

    size(): string {
      if (this.$props.circleSize === "large") {
        return "280";
      } else if (this.$props.circleSize === "small") {
        return "180";
      }
      return "0";
    },
    icon(): string {
      switch (this.$props.mode) {
        case 1:
          return "fas fa-map-marker-alt";
        case 2:
          return "fas fa-phone-alt theme--light";
        case 3:
          return "fas fa-globe";
        case 4:
          return "fas fa-briefcase theme--light";
        default:
          return "";
      }
    },

    percentage(): string {
      return this.$props.stores + "/" + this.$props.allStores + this.dict.store_unit;
    },
  },
  methods: {
    beforeEnter(): void {
      useIndexedDb().selectReport.selectReportType = "other";
    },
  },
});
</script>
<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;
$black: #333;

.circular-container {
  position: relative;
  text-align: center;
}

.large-circle {
  .percentage-value {
    font-size: 42px;
  }
  .stores {
    font-size: 24px;
  }
}
.small-circle {
  .percentage-value {
    font-size: 32px;
  }
  .stores {
    font-size: 20px;
  }
}

.completion-circle {
  text-align: center;
}

.checkmark {
  position: absolute !important;
}

.description {
  position: relative;
}

.title {
  color: $black;
  margin-bottom: 0 !important;
  .title-icon {
    display: block;
    margin: 0 auto;
  }
  .title-label {
    font-size: 16px;
    font-weight: bold;
  }
}

.percentage {
  color: $black;
  font-weight: bold;
}

.unit {
  font-size: 24px;
}

.stores_percentage {
  font-weight: bold;
  align-self: start;
  .stores {
    display: block;
    line-height: 1em !important;
  }
  .all_stores {
    border-top: 1px solid $black;
    padding-top: 5px;
    margin-top: 5px;
    display: inline-block;
    color: $black;
    font-size: 16px;
  }
}

.link-to-stores {
  display: inline-block;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  color: color.$main-orange;
  &:hover {
    text-decoration: none;
  }
}
</style>
