<template>
  <div class="fb-images">
    <!-- 画像が1枚の場合 -->
    <v-row v-if="items.length === 1" align="center" no-gutters>
      <v-col class="col-full">
        <video
          v-if="isVideo"
          :src="items[0].videoUrl"
          :poster="items[0].imageUrl"
          controls
          class="video-poster"
        ></video>
        <fb-image
          v-else
          :image-url="items[0].imageUrl"
          :state="items[0].state"
          :class-name="'img-full'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
    </v-row>

    <!-- 画像が2枚の場合 -->
    <v-row v-if="items.length === 2" align="center" no-gutters>
      <v-col
        v-for="image in items"
        :key="image.imageUrl"
        :class="aspect < 1.0 ? 'col-half-v' : aspect === 1.0 ? 'col-half-square' : 'col-half-h'"
      >
        <fb-image
          :image-url="image.imageUrl"
          :state="image.state"
          :class-name="'img-cover'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
    </v-row>

    <!-- 画像が3枚の場合 -->
    <v-row v-if="items.length === 3 && aspect < 1.0" align="center" no-gutters>
      <v-col class="col-half-v">
        <fb-image
          :image-url="items[0].imageUrl"
          :state="items[0].state"
          :class-name="'img-cover'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
      <v-col class="col-half-v">
        <fb-image
          :image-url="items[1].imageUrl"
          :state="items[1].state"
          :class-name="'img-cover-half-v'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
        <fb-image
          :image-url="items[2].imageUrl"
          :state="items[2].state"
          :class-name="'img-cover-half-v'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
    </v-row>
    <v-row v-if="items.length === 3 && aspect === 1.0" align="center" no-gutters>
      <v-col class="col-one-third-square">
        <fb-image
          :image-url="items[0].imageUrl"
          :state="items[0].state"
          :class-name="'img-cover'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
      <v-col class="col-one-third-square">
        <fb-image
          :image-url="items[1].imageUrl"
          :state="items[1].state"
          :class-name="'img-cover'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
      <v-col class="col-one-third-square">
        <fb-image
          :image-url="items[2].imageUrl"
          :state="items[2].state"
          :class-name="'img-cover'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
    </v-row>
    <v-row v-if="items.length === 3 && aspect > 1.0" align="center" no-gutters>
      <v-col class="col-half-h">
        <fb-image
          :image-url="items[0].imageUrl"
          :state="items[0].state"
          :class-name="'img-cover'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
      <v-col class="col-half-square">
        <fb-image
          :image-url="items[1].imageUrl"
          :state="items[1].state"
          :class-name="'img-cover-half-h'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
      <v-col class="col-half-square">
        <fb-image
          :image-url="items[2].imageUrl"
          :state="items[2].state"
          :class-name="'img-cover-half-h'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
    </v-row>

    <!-- 画像が4枚以上の場合 -->
    <v-row v-if="items.length >= 4 && aspect < 1.0" align="center" no-gutters>
      <v-col class="col-two-third-v">
        <fb-image
          :image-url="items[0].imageUrl"
          :state="items[0].state"
          :class-name="'img-cover'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
      <v-col class="col-one-third-v">
        <div class="col-one-third-square">
          <fb-image
            :image-url="items[1].imageUrl"
            :state="items[1].state"
            :class-name="'img-cover'"
            :is-mobile="isMobile"
            :readonly="readonly"
            @edit-image="editImage"
          />
        </div>
        <div class="col-one-third-square">
          <fb-image
            :image-url="items[2].imageUrl"
            :state="items[2].state"
            :class-name="'img-cover'"
            :is-mobile="isMobile"
            :readonly="readonly"
            @edit-image="editImage"
          />
        </div>
        <div class="col-one-third-square div-overlay">
          <fb-image
            :image-url="items[3].imageUrl"
            :state="items[3].state"
            :class-name="'img-cover'"
            :is-mobile="isMobile"
            :readonly="readonly"
            @edit-image="editImage"
          />
          <p v-if="items.length > 4" class="txt-overlay overlay-fs-one-third">
            {{ additionalImages }}
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="items.length >= 4 && aspect === 1.0" align="center" no-gutters>
      <v-col class="col-half-square">
        <fb-image
          :image-url="items[0].imageUrl"
          :state="items[0].state"
          :class-name="'img-cover'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
      <v-col class="col-half-square">
        <fb-image
          :image-url="items[1].imageUrl"
          :state="items[1].state"
          :class-name="'img-cover'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
      <v-col class="col-half-square">
        <fb-image
          :image-url="items[2].imageUrl"
          :state="items[2].state"
          :class-name="'img-cover'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
      <v-col class="col-half-square">
        <fb-image
          :image-url="items[3].imageUrl"
          :state="items[3].state"
          :class-name="'img-cover'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
        <p v-if="items.length > 4" class="txt-overlay overlay-fs-half">
          {{ additionalImages }}
        </p>
      </v-col>
    </v-row>
    <v-row v-if="items.length >= 4 && aspect > 1.0" align="center" no-gutters>
      <v-col class="col-two-third-h">
        <fb-image
          :image-url="items[0].imageUrl"
          :state="items[0].state"
          :class-name="'img-cover'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
      <v-col class="col-one-third-h">
        <fb-image
          :image-url="items[1].imageUrl"
          :state="items[1].state"
          :class-name="'img-cover-one-third-h'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
      <v-col class="col-one-third-h">
        <fb-image
          :image-url="items[2].imageUrl"
          :state="items[2].state"
          :class-name="'img-cover-one-third-h'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
      </v-col>
      <v-col class="col-one-third-h">
        <fb-image
          :image-url="items[3].imageUrl"
          :state="items[3].state"
          :class-name="'img-cover-one-third-h'"
          :is-mobile="isMobile"
          :readonly="readonly"
          @edit-image="editImage"
        />
        <p v-if="items.length > 4" class="txt-overlay overlay-fs-one-third">
          {{ additionalImages }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getExtension, isVideoExtension } from "@/models/v2-file-selection";
import type { FileSelectionItem, FileSelectionState } from "@/models/v2-file-selection";
import FbImage from "./fb-image.vue";

export default defineComponent({
  components: { FbImage },
  props: {
    items: { type: Array as () => FileSelectionItem[], required: true },
    isMobile: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  emits: ["edit-image"],
  data() {
    return {
      aspect: 1.0,
      additionalImages: "0",
      hover: false,
      isVideo: false,
    };
  },
  watch: {
    items: {
      immediate: true,
      deep: true,
      async handler(): Promise<void> {
        // 画像の選択を解除した直後にitemsが反映されるのを待つ
        await this.$nextTick();
        // 投稿イメージ選択で選択されているファイルが無ければ処理終了
        if (this.items.length > 0) {
          try {
            // 画像の幅/高さ
            this.aspect = await this.loadImage(this.items[0].imageUrl);
            const ext = getExtension(this.items[0].file.name);
            this.isVideo = isVideoExtension(ext);
            if (this.items.length > 4) {
              this.additionalImages = "+" + (this.items.length - 3);
            }
          } catch (e) {
            console.log("画像の表示に失敗しました: %#v", e);
          }
        }
      },
    },
  },
  methods: {
    async loadImage(objectUrl: string): Promise<any> {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img.width / img.height);
        img.onerror = (e) => reject(e);
        img.src = objectUrl;
      });
    },
    editImage(order: FileSelectionState): void {
      // EditImageSelectedを作ってposts.vueのイベントを親コンポーネントを経由して発火させる
      this.$emit("edit-image", order);
    },
  },
});
</script>

<style lang="scss" scoped>
.fb-images {
  margin-top: 0.5rem;
  margin-left: -12px;
  margin-right: -12px;
  width: 308px;
}

.col-full {
  padding: 0;
  width: 308px;
  position: relative;
}

.col-half-square {
  display: flex;
  padding: 0;
  height: 153px;
  width: 153px;
  position: relative;
  margin-left: 1px;
  margin-bottom: 1px;
}

.col-half-v {
  padding: 0;
  height: 308px;
  width: 153px;
  margin-left: 1px;
}

.col-two-third-v {
  display: flex;
  padding: 0;
  height: 308px;
  width: 204px;
}

.col-one-third-square {
  display: flex;
  padding: 0;
  height: 102px;
  width: 101px;
  margin-left: 1px;
  margin-bottom: 1px;
}

.col-one-third-v {
  padding: 0;
  height: 308px;
  width: 103px;
}

.col-half-h {
  padding: 0;
  height: 153px;
  width: 308px;
  margin-bottom: 1px;
}

.col-two-third-h {
  padding: 0;
  height: 204px;
  width: 308px;
  margin-bottom: 1px;
}

.col-one-third-h {
  display: flex;
  padding: 0;
  height: 103px;
  width: 101px;
  position: relative;
  margin-left: 1px;
}

.video-poster {
  background-color: #000;
  width: 308px;
  object-fit: scale-down;
}

.img-full {
  max-width: 100%;
  height: auto;
  width: auto;
}

.img-cover {
  display: flex;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.img-cover-half-v {
  display: flex;
  height: 153px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 1px;
}

.img-cover-half-h {
  width: 100%;
  object-fit: cover;
}

.img-cover-one-third-h {
  width: 100%;
  object-fit: cover;
}

.div-overlay {
  position: relative;
}

.txt-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  margin: 0;
  padding: 0;
  color: white;
  font-weight: bold;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.overlay-fs-half {
  line-height: 154px;
  font-size: 1.6em;
}

.overlay-fs-one-third {
  line-height: 103px;
  font-size: 1.2em;
}
</style>
