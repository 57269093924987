<template>
  <v-dialog v-model="openModal" persistent width="auto" @input="$emit('input', $event)">
    <div class="modal-card" style="margin-left: unset">
      <header class="modal-card-head">
        <p :class="isMobile ? 'mobile-modal-card-title' : 'modal-card-title'">
          履歴画面の再読み込みが必要です
        </p>
      </header>
      <section class="modal-card-body">
        <p>
          履歴画面に表示されている投稿の状態が現状の状態と異なるため、履歴画面を再読み込みし最新の状態を表示する必要があります。
        </p>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click.prevent="$emit('reload-post-histories')">
          再読み込み
        </button>
      </footer>
    </div>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    openReloadConfirm: { type: Boolean, required: true, default: false },
    isMobile: { type: Boolean, default: false },
  },
  emits: ["input", "reload-post-histories"],
  computed: {
    openModal: {
      get() {
        return this.openReloadConfirm;
      },
      set(value: boolean) {
        this.$emit("input", value);
      },
    },
  },
});
</script>
<style lang="scss" scoped>
.modal-card-title {
  margin: 0;
}
.mobile-modal-card-title {
  @extend .modal-card-title;
  font-size: 1.1rem;
  font-weight: bold;
}
.date-highlight {
  color: red;
  font-size: 1.5rem;
}
</style>
