import { requiredAuth } from "@/helpers";
import type {
    EntitiesFoodMenuGroup,
    DomainQandaStoreList,
    EntitiesQandaStore,
    EntitiesQandaUpdate,
    EntitiesQandaApplicationTask,
    EntitiesCompany,
    DomainQandaApplicationTaskList,
} from "@/types/ls-api";

export const api = {
    setup,
    getQandaStore,
    getQandaStores,
    enqueueUpdateCacheTask,
    createApplyTask,
    enqueueApplyTask,
    getTasks,
};
/** 質問と回答、初期設定 */
async function setup(poiGroupId: number): Promise<EntitiesCompany> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/qanda/setup`;
    const res = await requiredAuth<EntitiesCompany>("post", url, null, {});
    return res.data;
}
/** 質問と回答、店舗設定取得 */
async function getQandaStore(poiGroupId: number, poiId: number): Promise<EntitiesQandaStore> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/qanda/stores/${poiId}?cache=true`;
    const fmgres = await requiredAuth<EntitiesFoodMenuGroup>("get", url);
    return fmgres.data;
}
/** 質問と回答、店舗設定一覧取得 */
async function getQandaStores(poiGroupId: number): Promise<EntitiesQandaStore[]> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/qanda/stores`;
    const fmgs: EntitiesQandaStore[] = [];
    let pageToken = "";
    do {
        const fmgres = await requiredAuth<DomainQandaStoreList>("get", url, {
            pageToken,
        });
        fmgs.push(...fmgres.data.list);
        pageToken = fmgres.data.nextPageToken ?? "";
    } while (pageToken !== "");
    return fmgs;
}
/** 質問と回答、キャッシュ更新タスク投入 */
async function enqueueUpdateCacheTask(
    poiGroupId: number,
    poiId: number
): Promise<EntitiesQandaStore> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/qanda/stores/${poiId}/enqueueUpdateCacheTask`;
    const res = await requiredAuth<EntitiesQandaStore>("post", url, {}, {});
    return res.data;
}
async function createApplyTask(
    poiGroupId: number,
    poiId: number,
    updates: EntitiesQandaUpdate[]
): Promise<EntitiesQandaStore> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/qanda/stores/${poiId}/createApplyTask`;
    const res = await requiredAuth<EntitiesQandaStore>("post", url, {}, { updates });
    return res.data;
}
async function enqueueApplyTask(
    poiGroupId: number,
    poiId: number
): Promise<EntitiesQandaApplicationTask> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/qanda/stores/${poiId}/enqueueApplyTask`;
    const res = await requiredAuth<EntitiesQandaApplicationTask>("post", url, {}, {});
    return res.data;
}
/** 質問と回答、反映タスク一覧取得 */
async function getTasks(
    poiGroupId: number,
    poiId: number
): Promise<DomainQandaApplicationTaskList> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/qanda/tasks`;
    const fmgres = await requiredAuth<DomainQandaApplicationTaskList>("get", url, { poiID: poiId });
    return fmgres.data;
}
