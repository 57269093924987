import * as XLSX from "xlsx";
import type { AmChartData } from "@/helpers/amchart";
import dayjs from "dayjs";
import type { CellObject } from "xlsx";
import { saveAs } from "file-saver";

export async function read(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result as ArrayBuffer);
        };
        reader.readAsArrayBuffer(blob);
    });
}

/**
 * xlsxファイルの一つ目のシートをCSVに変換します
 * 1行目をヘッダ行とみなし、ヘッダ行の値をキーとしたオブジェクトの配列を作成します。
 * @param buf ArrayBuffer
 * @param headerRow number: ヘッダ行が何行目か（デフォルト1行目）
 * @returns [fields, rows, sheetName] fields: ヘッダ行, rows: ヘッダ行以外の行, シート名
 */
export function arrayBufferToCsv(
    buf: ArrayBuffer,
    headerRow: number = 1
): [string[], any[], string] {
    if (headerRow < 1) {
        headerRow = 1;
    }
    const book: XLSX.WorkBook = XLSX.read(buf, { type: "array" });
    const sheet: XLSX.Sheet = book.Sheets[book.SheetNames[0]];
    const range = XLSX.utils.decode_range(sheet["!ref"]);
    if (range.s.r !== 0) {
        return [[], [], book.SheetNames[0]];
    }
    const fields = [];
    const rows = [];
    for (let c = range.s.c; c <= range.e.c; c++) {
        const cell = sheet[XLSX.utils.encode_cell({ r: headerRow - 1, c })];
        fields.push(cell?.w ?? "");
    }
    for (let r = headerRow; r <= range.e.r; r++) {
        const row = {};
        for (let c = range.s.c; c <= range.e.c; c++) {
            const cell: CellObject = sheet[XLSX.utils.encode_cell({ r, c })];
            const val = cell?.w ?? "";
            row[fields[c]] = val;
        }
        rows.push(row);
    }
    return [fields, rows, book.SheetNames[0]];
}

/**
 * xlsxファイルの一つ目のシートを string[][] に変換します
 * @param buf ArrayBuffer
 * @returns string[][]
 */
export function arrayBufferToStringsArray(buf: ArrayBuffer): string[][] {
    const book: XLSX.WorkBook = XLSX.read(buf, { type: "array" });
    const sheet: XLSX.Sheet = book.Sheets[book.SheetNames[0]];
    const range = XLSX.utils.decode_range(sheet["!ref"]);
    // 1行目が空ならば空のCSVとみなす
    if (range.s.r !== 0) {
        return [];
    }
    const rows: string[][] = [];
    for (let r = 0; r <= range.e.r; r++) {
        const row: string[] = [];
        for (let c = range.s.c; c <= range.e.c; c++) {
            const cell = sheet[XLSX.utils.encode_cell({ r, c })];
            const val = cell ? (cell.w ? cell.w : "") : "";
            row.push(val);
        }
        rows.push(row);
    }
    return rows;
}

/**
 * xlsxファイルを string[][][] に変換します
 * @param buf ArrayBuffer
 * @returns string[][][]
 */
export function arrayBufferToStringsArrays(buf: ArrayBuffer): string[][][] {
    const result: string[][][] = [];
    const book: XLSX.WorkBook = XLSX.read(buf, { type: "array" });
    for (let sheetNum = 0; sheetNum < book.SheetNames.length; sheetNum++) {
        const sheet: XLSX.Sheet = book.Sheets[book.SheetNames[sheetNum]];
        const range = XLSX.utils.decode_range(sheet["!ref"]);
        const rows: string[][] = [];
        for (let r = 0; r <= range.e.r; r++) {
            const row: string[] = [];
            for (let c = 0; c <= range.e.c; c++) {
                if (r < range.s.r || c < range.s.c) {
                    row.push("");
                    continue;
                }
                const cell = sheet[XLSX.utils.encode_cell({ r, c })];
                const val = cell ? (cell.w ? cell.w : "") : "";
                row.push(val);
            }
            rows.push(row);
        }
        result.push(rows);
    }
    return result;
}

export function convertToRows(
    chartData: AmChartData,
    title: string = null
): { [key: string]: string | number }[] {
    return (
        chartData?.labels.map((date, index, _) => {
            const row = {};
            row["date"] = typeof date == "string" ? date : dayjs(date).format("YYYY-MM-DD");
            if (title) {
                row["title"] = title;
            }
            chartData.datasets.forEach((dataset, i) => {
                row[i.toString()] = dataset.data[index] ?? "-";
            });
            return row;
        }) ?? []
    );
}

// 指定した数で配列を分割
function arraySplit<T = Record<string, unknown>>(array: T[], n: number): T[][] {
    return array.reduce(
        (acc: T[][], c, i: number) => (i % n ? acc : [...acc, ...[array.slice(i, i + n)]]),
        []
    );
}

export function exportFile(
    filename: string,
    headers: string[],
    columns: string[],
    rows: any[]
): void {
    const wb = XLSX.utils.book_new();

    // 巨大な行数を扱うとブラウザが落ちるため、適宜シートを分割する
    const xlsxRowsMax = 1048576;
    const rowsPack = arraySplit(rows, xlsxRowsMax / 2);
    let sheetIndex = 1;
    for (rows of rowsPack) {
        const aoa = []; // Array of arrays
        aoa.push(headers);
        for (const row of rows) {
            const arr = [];
            for (const column of columns) {
                const str = `${row[column]}`.replace(/\r\n/g, "\n").replace(/\r/g, "\n");
                arr.push(str);
            }
            aoa.push(arr);
        }
        const ws = XLSX.utils.aoa_to_sheet(aoa);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet" + sheetIndex.toString());
        sheetIndex++;
    }

    const buf: ArrayBuffer = XLSX.write(wb, {
        type: "array",
        bookType: "xlsx",
        bookSST: true,
    });
    saveAs(new Blob([buf], { type: "application/octet-stream" }), filename);
}

/**
 * 対象セルにコメントを挿入します
 */
export function addCommentToCell(
    cell: CellObject,
    comment: string,
    isHidden: boolean = true
): void {
    XLSX.utils.cell_add_comment(cell, comment);
    // コメントを初期表示するか
    cell.c.hidden = isHidden;
}

/**
 * XLSXのカラム名を取得します
 */
export function getXLSXColumnName(index: number): string {
    // Zを超える場合はAA, AB, ... となる
    if (index < 26) {
        return String.fromCharCode(65 + index);
    } else {
        return (
            String.fromCharCode(65 + Math.floor(index / 26) - 1) +
            String.fromCharCode(65 + (index % 26))
        );
    }
}
