<template>
  <div>
    <form>
      <div class="stores-head">
        <div class="table-menu-stores">
          <v-dialog v-model="dialog.show" width="500" persistent>
            <v-card>
              <v-card-title class="headline grey lighten-2" primary-title>
                {{ dialog.title }}
              </v-card-title>
              <v-progress-linear v-model="dialog.percentage" color="light-blue"></v-progress-linear>
              <v-card-text>
                <div v-for="(text, index) in dialog.message.split('\n')" :key="index">
                  {{ text }}
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="!dialog.cancelButton" @click="dialog.show = false">
                  {{ dialog.cancelButton }}
                </v-btn>
                <v-btn :disabled="!dialog.acceptButton" @click="acceptDialog">
                  {{ dialog.acceptButton }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-row class="mt-1 mb-n3" no-gutters>
            <v-col>
              <!-- グループ設定 -->
              <v-autocomplete
                v-model="areaTagId"
                :items="areaOptions"
                density="compact"
                width="600px"
                color="primary"
                placeholder="グループを選択して下さい"
                clearable
                variant="underlined"
              >
                <template #append>
                  <v-btn
                    data-testid="assignAreaTags"
                    class="select-button"
                    :disabled="!storeSelected || areaTagId == null"
                    @click.prevent="assignAreaTags"
                  >
                    {{ dict.assignAreaTag_button_label }}
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col v-if="canShowArea && canManageMenu">
              <!-- フードメニューグループ設定 -->
              <v-autocomplete
                v-model="foodMenuGroupId"
                :items="foodMenuGroups"
                item-value="id"
                density="compact"
                width="600px"
                color="primary"
                placeholder="フードメニューを選択して下さい"
                clearable
                variant="underlined"
              >
                <template #append>
                  <v-btn
                    data-testid="assignFoodMenuGroup"
                    class="select-button"
                    :disabled="!storeSelected || foodMenuGroupId == null"
                    @click.prevent="assignFoodMenuGroup"
                  >
                    {{ dict.assignFoodMenuGroup_button_label }}
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="auto" class="mt-1 mx-1">
              <o-upload v-model="xlsxFile" accept=".xlsx" @update:model-value="importFile()">
                <a class="button is-primary is-small" data-testid="importButton">
                  <span>XLSXインポート</span>
                </a>
              </o-upload>
            </v-col>
            <v-col cols="auto" class="mt-1 mx-1">
              <o-button
                variant="primary"
                size="small"
                data-testid="exportButton"
                @click="exportFile()"
              >
                XLSXエクスポート
              </o-button>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="d-flex flex-wrap mb-2">
        <v-btn
          data-testid="openReviewAlertModal"
          :disabled="!storeSelected"
          class="white-button"
          @click.prevent="openReviewAlertModal"
        >
          クチコミアラート設定を開く
        </v-btn>
        <v-dialog v-if="isModalActive" v-model="isModalActive" aria-role="dialog" aria-modal>
          <modal-form
            @complete="reviewAlertConditionSubmit"
            @cancel="isModalActive = false"
          ></modal-form>
        </v-dialog>
        <div class="d-flex align-center ml-auto" style="min-width: 480px">
          <v-text-field
            v-model="searchWord"
            data-testid="searchWord"
            label="検索キーワード"
            variant="underlined"
            density="compact"
            single-line
            hide-details
            clearable
            prepend-inner-icon="mdi-magnify"
            color="primary"
            class="mr-2 mb-1"
            style="width: 200px; max-width: 200px"
            @keypress.enter="searchStores"
            @click:clear="
              searchWord = '';
              searchStores();
            "
          ></v-text-field>
          <o-button data-testid="searchStores" variant="primary" size="small" @click="searchStores">
            検索キーワードで絞り込み
          </o-button>
          <ToolTipIcon
            :label="'「★」を入力することでクチコミの星数による検索をすることもできます。また、「通知しない」と入力することでクチコミアラート条件が設定されていない店舗の検索もできます。'"
            class="search-tool-tip"
            position="bottom"
          />
          <div data-testid="count" style="margin-left: 10px">{{ stores.length }}件表示中</div>
        </div>
      </div>
      <div v-if="stores.length === 0 && !loading">{{ dict.result_message_loading }}</div>
      <o-table
        v-else
        v-model:checked-rows="checkedRows"
        v-model:currentPage="currentPage"
        paginated
        :data="stores"
        :per-page="perPage"
        :loading="loading"
        checkable
      >
        <o-table-column field="storeCode" :label="dict.storeCode.title">
          <template #default="props">
            {{ props.row.gmbStoreCode }}
          </template>
          <template #header>
            {{ dict.storeCode.title }}
            <ToolTipIcon :label="dict.storeCode.supplement" position="bottom" />
          </template>
        </o-table-column>

        <o-table-column field="name" :label="dict.poiName.title">
          <template #default="props">
            <span :data-testid="`name-${props.row.poiID}`">{{ props.row.name }}</span>
          </template>
          <template #header>
            {{ dict.poiName.title }}
            <ToolTipIcon :label="dict.poiName.supplement" position="bottom" />
          </template>
        </o-table-column>

        <o-table-column field="areaTags" :label="dict.areaTag.title">
          <template #default="props">
            <v-chip-group v-if="props.row.areas && props.row.areas.length > 0" column>
              <v-chip
                v-for="areaId in props.row.areas"
                :key="areas[areaId]"
                small
                closable
                @click:close="withdrawAreaTag(props.row, areaId)"
              >
                {{ areas[areaId] }}
              </v-chip>
            </v-chip-group>
            <span v-else>なし</span>
          </template>
          <template #header>
            {{ dict.areaTag.title }}
            <ToolTipIcon :label="dict.areaTag.supplement" position="bottom" />
          </template>
        </o-table-column>

        <o-table-column
          v-if="canShowArea && canShowMenu"
          field="foodMenuGroupId"
          :label="dict.foodMenuGroup.title"
        >
          <template #default="props">
            {{ getFoodMenuGroupTitle(props.row.poiID) }}
          </template>
          <template #header>
            {{ dict.foodMenuGroup.title }}
            <ToolTipIcon :label="dict.foodMenuGroup.supplement" position="bottom" />
          </template>
        </o-table-column>

        <o-table-column field="alertCondition" :label="dict.alertCondition.title">
          <template #default="props">
            {{ reviewAlertConditionFormat(props.row) }}
          </template>
          <template #header>
            {{ dict.alertCondition.title }}
            <ToolTipIcon :label="dict.alertCondition.supplement" position="bottom" />
          </template>
        </o-table-column>

        <o-table-column v-if="canShowRivals" field="rivalName" :label="dict.rivalName.title">
          <template #default="props">
            {{ getRivalNameAndKeyword(props.row.rivals).name }}
          </template>
          <template #header>
            {{ dict.rivalName.title }}
            <ToolTipIcon :label="dict.rivalName.supplement" position="bottom" />
          </template>
        </o-table-column>

        <o-table-column v-if="canShowRivals" field="rivalKeyword" :label="dict.rivalKeyword.title">
          <template #default="props">
            {{ getRivalNameAndKeyword(props.row.rivals).keyword }}
          </template>
          <template #header>
            {{ dict.rivalKeyword.title }}
            <ToolTipIcon :label="dict.rivalKeyword.supplement" position="bottom" />
          </template>
        </o-table-column>

        <o-table-column v-if="canManageRivals" field="edit" :label="dict.edit.title">
          <template #default="props">
            <router-link
              :to="{
                name: 'StoresEdit',
                params: { poiGroupId: poiGroupId, poiId: props.row.poiID },
              }"
            >
              <o-icon class="white-button" icon="pencil"></o-icon>
            </router-link>
          </template>
          <template #header>
            {{ dict.edit.title }}
            <ToolTipIcon :label="dict.edit.supplement" position="bottom" />
          </template>
        </o-table-column>
      </o-table>
    </form>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
