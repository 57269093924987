<template>
  <input
    v-model="date"
    type="month"
    :min="min"
    :max="max"
    placeholder="年/月"
    :required="required"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import dayjs from "dayjs";
import isbetWeen from "dayjs/plugin/isBetween";
dayjs.extend(isbetWeen);

export const yyyymm = "YYYY-MM";
export default defineComponent({
  props: {
    modelValue: { type: String, default: "" },
    min: { type: String, default: "2000-01" },
    max: { type: String, default: "2121-12" },
    required: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      forceUpdate: false,
      forceUpdateIntermediateString: "",
    };
  },
  computed: {
    date: {
      get() {
        if (this.forceUpdate) {
          this.updateForceUpdateAtNextTick();
          return this.forceUpdateIntermediateString;
        }
        // value のフォーマットがおかしければ修正する
        return this.modelValue ? dayjs(this.modelValue).format(yyyymm) : "";
      },
      set(val: string) {
        // ピッカーの「削除」を押すと空文字が渡ってくるので、その対処をする
        if (val === "") {
          // 必須ではないのであれば一旦 modelValue を表示してから空文字にする
          if (!this.required) {
            this.forceUpdate = true;
            this.forceUpdateIntermediateString = this.modelValue;
            if (this.modelValue !== val) {
              this.$emit("update:modelValue", val);
            }
            return;
          }
          // 必須なのに空文字なら値を変えない
          val = this.modelValue;
          // input 要素を強制的に再描画させる
          this.forceUpdate = true;
        }
        // min ~ max の範囲内に収める
        const beforeAdjustmentVal = val;
        val = this.max && this.max < val ? this.max : val;
        val = this.min && val < this.min ? this.min : val;
        // 調整前の値と調整後の値が異なるなら、input 要素を強制的に再描画させる
        if (beforeAdjustmentVal !== val) {
          this.forceUpdate = true;
        }
        if (this.modelValue !== val) {
          this.$emit("update:modelValue", val);
        }
      },
    },
  },
  methods: {
    updateForceUpdateAtNextTick() {
      this.$nextTick(() => {
        this.forceUpdate = false;
        this.forceUpdateIntermediateString = "";
      });
    },
  },
});
</script>
<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

input[type="month"] {
  width: 7.2em;
  // background-color: white;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #dbdbdb;
  &:focus {
    border-color: color.$primary;
    outline: none;
  }
}
</style>
