import type { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "@/storepinia/snackbar";
import { requiredAuth } from "@/helpers";
import { Component, Prop, Vue, toNative } from "vue-facing-decorator";
import wordDictionary from "@/word-dictionary";
import type {
    EntitiesUser,
    EntitiesUsersResponse,
    ControllersMFAEnabledInput,
    ControllersMFAEnabledOutput,
    EntitiesRole,
} from "@/types/ls-api";
import { getter, action } from "@/storepinia/idxdb";
import type { FormContext } from "vee-validate";
import { makeSelectItems, type SelectItem } from "@/helpers/select-items";
import AutoCompleteCard from "@/components/shared/auto-complete-card/AutoCompleteCard.vue";
import { sort, uniq } from "@/helpers/arrays";
import { currentTheme } from "@/components/shared/theme";

@Component({ components: { AutoCompleteCard } })
class Form extends Vue {
    declare $refs: {
        observer: FormContext;
        areas: typeof AutoCompleteCard;
        stores: typeof AutoCompleteCard;
    };
    // オーナー追加モード
    @Prop() onlyOwner: boolean;
    // ユーザー追加モード
    @Prop() isNew: boolean;

    user = getter().user;
    areaStores = getter().areaStores;
    storesResponse = getter().stores;
    roleList = getter().roleList;
    canShowUser = getter().canShowUser;
    canShowProfile = getter().canShowProfile;
    canShowAllowedStoresOnly = getter().canShowAllowedStoresOnly;
    setUser = action().setUser;
    setRoles = action().setRoles;
    addSnackbarMessages = useSnackbar().addSnackbarMessages;

    // 店舗一覧
    poiGroupId: number;
    roleLv: number;
    currentPage: number;
    lastPage: number;
    message: string = "";
    isLoading: boolean = false;
    familyName: string = "";
    firstName: string = "";
    mailAddress: string = "";
    areaItems: SelectItem[] = []; // グループ権限での担当グループの選択肢
    areas: number[] = []; // グループ権限での選択したグループのareaID
    storeItems: SelectItem[] = []; // 店舗権限での担当店舗の選択肢
    stores: number[] = []; // 店舗権限での選択した店舗のpoiID
    reviewAreaItems: SelectItem[] = []; // クチコミアラート通知許可グループの選択肢
    reviewAreas: number[] = []; // クチコミアラート通知許可グループの選択したもの
    reviewStoreItems: SelectItem[] = []; // クチコミアラート通知許可店舗の選択肢
    reviewStores: number[] = []; // クチコミアラート通知許可店舗の選択したもの
    locationUpdateNotificationEnabled: boolean = false;
    useMFA: boolean = false;
    isApprovePostNotification: boolean = false;
    adminRoleLv: number[] = []; // 管理権限 roleLvリスト
    powerRoleLv: number[] = []; // パワーユーザー権限 roleLvリスト
    ownerRoleLv: number[] = []; // オーナー権限 roleLvリスト
    groupRoleLv: number[] = []; // グループ権限 roleLvリスト
    storeRoleLv: number[] = []; // 店舗権限 roleLvリスト
    storeMobileRoleLv: number[] = []; // 店舗権限(モバイル) roleLvリスト
    selectedRoleLv: number = null; // 現在選択している権限
    initialOwner: boolean = false;
    userId: string = null; // UUID
    accountId: string = "";
    isPushed: boolean = false;
    isInCharge: boolean = false;
    showMFAResetDialog: boolean = false;
    canPostApprove: boolean = false;

    get dict() {
        const dict = { ...wordDictionary.usersForm };

        dict.mailAddress.supplement = this.isNew
            ? "登録するユーザのメールアドレスを入力。該当メールアドレスにログイン情報が届きます。"
            : "メールアドレスは変更不可です。変更したい場合はユーザー削除し、再登録してください。";

        return dict;
    }

    mounted(): void {
        // 「ユーザー」である場合ページタイトル変える
        if (!this.canShowUser && this.canShowProfile) {
            document.title = `プロファイル管理 | ${currentTheme().appName}`;
        }
    }

    async resetMFARequest(silent: boolean = false): Promise<void> {
        this.isPushed = true;
        try {
            const req: ControllersMFAEnabledInput = { enabled: false };
            const resDisable = await requiredAuth<ControllersMFAEnabledOutput>(
                "post",
                `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/users/${
                    this.userId
                }/mfaEnabled`,
                null,
                req
            );
            if (resDisable.status == 200) {
                if (!silent) {
                    this.addSnackbarMessages({
                        text: this.dict.resetMFACautionDialog.success,
                        color: "success",
                    });
                }
            } else {
                if (!silent) {
                    this.showDangerToast(this.dict.resetMFACautionDialog.failedReset);
                }
            }
        } finally {
            this.showMFAResetDialog = false;
            this.isPushed = false;
        }
    }

    async fetchUser(): Promise<void> {
        this.userId = (this.$route.params.userId as string) ?? null;

        // auto-complete-cardの選択肢を作成
        // グループ権限での担当グループの選択肢を作成
        this.areaItems = this.areaStores.map((area) => {
            return { isHeader: false, id: area.areaID, title: area.name };
        });
        // 店舗権限での担当店舗の選択肢を作成
        ({ stores: this.storeItems } = makeSelectItems(
            this.areaStores,
            this.storesResponse?.stores.filter((store) => store.enabled),
            true
        ));
        // クチコミオプション契約が有効のグループ・店舗のみ表示する
        const reviewOption = wordDictionary.stores.options.review;
        ({ stores: this.reviewStoreItems, areas: this.reviewAreaItems } = makeSelectItems(
            this.areaStores,
            this.storesResponse?.stores
                .filter((store) => store.enabled)
                .filter((store) => store.options?.includes(reviewOption)),
            true
        ));

        // 管理権限が設定されているroleLvを抽出
        this.adminRoleLv = this.roleList
            .filter((role) => role.viewRange === 1)
            .map((role) => role.roleLv);
        // パワーユーザー権限が設定されているroleLvを抽出
        this.powerRoleLv = this.roleList
            .filter((role) => role.viewRange === 2)
            .map((role) => role.roleLv);
        // 企業参照権限が設定されているroleLvを抽出
        this.ownerRoleLv = this.roleList
            .filter((role) => role.viewRange === 3)
            .map((role) => role.roleLv);
        // グループ参照権限が設定されているroleLvを抽出
        this.groupRoleLv = this.roleList
            .filter((role) => role.viewRange === 4)
            .map((role) => role.roleLv);
        // 店舗参照権限が設定されているroleLvを抽出
        this.storeRoleLv = this.roleList
            .filter((role) => role.viewRange === 5)
            .map((role) => role.roleLv);
        // 店舗参照権限(モバイル)が設定されているroleLvを抽出
        this.storeMobileRoleLv = this.roleList
            .filter((role) => role.viewRange === 6)
            .map((role) => role.roleLv);

        if (this.userId) {
            const response = await this.getUser(this.userId);
            if (response == null || response.data == null || response.data.users == null) {
                return;
            }

            const xuser = response.data.users[0];
            this.firstName = xuser.firstName;
            this.familyName = xuser.familyName;
            this.mailAddress = xuser.mailAddress;
            this.accountId = xuser.uuID;
            this.selectedRoleLv = xuser.roleLv;
            this.initialOwner = xuser.owner ?? false;
            this.locationUpdateNotificationEnabled = xuser.locationUpdateNotificationEnabled;
            this.useMFA = xuser.useMFA;
            this.isInCharge = xuser.isInCharge;
            this.isApprovePostNotification = !xuser.isApprovePostNotificationDisabled;
            const xuserRole = this.roleList.find((role) => role.roleLv === xuser.roleLv);
            this.canPostApprove = xuserRole?.functions?.includes("post:approve") ?? false;

            // auto-complete-cardの選択状態を作成
            const validAreas = this.areaItems.map((a) => a.id);
            const validStores = this.storeItems.filter((s) => !s.isHeader).map((s) => s.id);
            const validReviewAreas = this.reviewAreaItems.map((a) => a.id);
            const validReviewStores = this.reviewStoreItems
                .filter((s) => !s.isHeader)
                .map((s) => s.id);
            this.areas = uniq(xuser.areas ?? []).filter((id) => validAreas.includes(id));
            this.stores = uniq(xuser.stores ?? []).filter((id) => validStores.includes(id));
            this.reviewAreas = uniq(xuser.reviewSubscriptions?.areas ?? []).filter((id) =>
                validReviewAreas.includes(id)
            );
            this.reviewStores = uniq(xuser.reviewSubscriptions?.stores ?? []).filter((id) =>
                validReviewStores.includes(id)
            );
        }

        // オーナー追加モードではオーナーを初期選択
        if (this.onlyOwner) {
            const ownerRole = this.roleList.find((role) => role.roleLv === 3);
            this.selectedRoleLv = ownerRole.roleLv;
            this.locationUpdateNotificationEnabled = true;
        }
    }

    async created(): Promise<void> {
        this.isLoading = true;
        try {
            this.roleLv = this.user.roleLv;
            this.poiGroupId = parseInt(this.$route.params.poiGroupId as string, 10);
            // 他のユーザが変更している可能性があるので取得し直す
            await this.setRoles(this.poiGroupId);
            await this.fetchUser();
        } catch (e) {
            console.error(e);
            throw Error("ユーザー情報の検索に失敗しました。");
        } finally {
            this.isLoading = false;
        }
    }

    goBack(): void {
        if (this.onlyOwner) {
            this.$router.push({
                name: "AdminCompanies",
                params: { defaultPage: `${this.currentPage}` },
            });
        } else {
            this.$router.push({
                name: "Users",
                params: { defaultPage: `${this.currentPage}` },
            });
        }
    }

    async submit(): Promise<void> {
        type Option = Parameters<typeof this.addSnackbarMessages>[0];
        const option: Option = { text: "", color: "danger", options: { top: false } };
        this.isPushed = true;
        const result = await this.$refs.observer.validate();
        if (!result.valid) {
            this.addSnackbarMessages({ ...option, text: this.dict.error_validate });

            this.isPushed = false;
            return;
        }
        // グループ権限が選択されている場合
        if (this.groupRoleLv.includes(Number(this.selectedRoleLv))) {
            // 担当店舗を空にする
            this.stores = [];
            // 担当グループが選択されていない場合はエラー
            if (this.areas.length === 0) {
                this.$refs.areas.validate();
                this.addSnackbarMessages({ ...option, text: this.dict.error_no_area_tag });
                this.isPushed = false;
                return;
            }
            // 担当グループの選択数が20以上の場合はエラー
            if (this.areas.length > 20) {
                this.$refs.areas.validate();
                this.addSnackbarMessages({ ...option, text: this.dict.error_limit_area_tag });
                this.isPushed = false;
                return;
            }
        }
        // 店舗権限が選択されている場合
        if (this.storeRoleLv.includes(Number(this.selectedRoleLv))) {
            // 担当グループを空にする
            this.areas = [];
            // 担当店舗が選択されていない場合はエラー
            if (this.stores.length === 0) {
                this.$refs.stores.validate();
                this.addSnackbarMessages({ ...option, text: this.dict.error_no_store_tag });
                this.isPushed = false;
                return;
            }
            // 担当店舗の選択数が20以上の場合はエラー
            if (this.stores.length > 20) {
                this.$refs.stores.validate();
                this.addSnackbarMessages({ ...option, text: this.dict.error_limit_store_tag });
                this.isPushed = false;
                return;
            }
        }

        this.message = "";
        this.isLoading = true;
        try {
            if (this.userId) {
                await this.update();
            } else {
                await this.create();
            }
            if (this.onlyOwner) {
                this.$router.push({
                    name: "AdminCompanies",
                    params: { defaultPage: `${this.currentPage}` },
                });
            }
        } catch (e) {
            const ex = e as AxiosError;
            this.showErrorMessage(ex);
        } finally {
            this.isLoading = false;
            this.isPushed = false;
        }
    }

    inputUser(): EntitiesUser {
        const user: EntitiesUser = {
            poiGroupID: this.poiGroupId,
            uuID: this.userId,
            owner: this.onlyOwner,
            firstName: this.firstName,
            familyName: this.familyName,
            mailAddress: this.mailAddress,
            roleLv: Number(this.selectedRoleLv),
            areas: sort(this.areas),
            stores: sort(this.stores),
            isInCharge: this.isInCharge,
            reviewSubscriptions: {
                areas: sort(this.reviewAreas),
                stores: sort(this.reviewStores),
            },
            locationUpdateNotificationEnabled: this.locationUpdateNotificationEnabled,
            isApprovePostNotificationDisabled: !this.isApprovePostNotification,
            useMFA: this.useMFA,
        };
        return user;
    }

    async create(): Promise<void> {
        this.message = "";
        this.isLoading = true;
        const createdUser = (await this.createUser(this.inputUser())).data;
        if (createdUser != null) {
            this.addSnackbarMessages({
                text: this.dict.createdDesc,
                color: "success",
            });
        }
        this.$router.push({
            name: "Users",
            params: { defaultPage: `${this.lastPage}` },
        });
    }

    showDangerToast(msg: string): void {
        this.addSnackbarMessages({
            text: msg,
            color: "danger",
        });
    }

    showErrorMessage(e: AxiosError): void {
        interface errorResponse {
            errorMessage?: string;
            statusCode?: number;
        }
        const errorRes: errorResponse = e.response.data;
        const errMsg = errorRes?.errorMessage;
        if (errorRes?.statusCode === 409) {
            this.showDangerToast(this.dict.error_user_name_exists);
        } else if (errorRes?.statusCode === 429) {
            this.showDangerToast(this.dict.error_exceeds_user_limit);
        } else if (errMsg.indexOf("verified") >= 0) {
            this.showDangerToast(this.dict.error_verified);
        } else {
            if (this.userId) {
                this.showDangerToast(this.dict.error_update);
            } else {
                this.showDangerToast(this.dict.error_create);
            }
            throw e;
        }
    }

    async update(): Promise<void> {
        const user = this.inputUser();
        const response = await this.updateUser(user);
        if (response.status === 200) {
            this.addSnackbarMessages({
                text: this.dict.updatedDesc,
                color: "success",
            });

            // 自分自身を編集したときはStoreを更新
            if (this.user.uuID === this.userId) {
                this.setUser(user);
            }
            if (!user.useMFA) {
                // MFAリセットを実行
                this.resetMFARequest(true);
            }
        }
        // ユーザープロファイルを編集する場合、戻る先がないため抑止
        if (this.canShowUser) {
            this.$router.push({
                name: "Users",
                params: { defaultPage: `${this.currentPage}` },
            });
        }
    }

    async updateUser(user: EntitiesUser): Promise<AxiosResponse<EntitiesUser>> {
        return await requiredAuth<EntitiesUser>(
            "put",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/users/${
                this.userId
            }`,
            null,
            user
        );
    }

    async createUser(user: EntitiesUser): Promise<AxiosResponse<EntitiesUser>> {
        return await requiredAuth<EntitiesUser>(
            "post",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/users`,
            null,
            user
        );
    }

    excludePrivilegedRole(): EntitiesRole[] {
        return this.roleList.filter((role) => role.privileged === false);
    }

    async getUser(userId: string): Promise<AxiosResponse<EntitiesUsersResponse>> {
        return requiredAuth<EntitiesUsersResponse>(
            "get",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/users/${userId}`,
            null
        );
    }

    buttonName(): string {
        return this.isNew ? "登録する" : "更新する";
    }
}
export default toNative(Form);
