<template>
  <!-- フードメニュー表示 -->
  <ul>
    <li v-for="(menu, i) in menus" :key="i">
      {{ toStringLabels(menu.labels) }}
      <ul>
        <li v-for="(section, j) in menu.sections" :key="j">
          {{ toStringLabels(section.labels) }}
          <ul>
            <li v-for="(item, k) in section.items" :key="k">
              {{ toStringLabels(item.labels) }}
              {{ toStringPrice(item) }}
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import type { MybusinessFoodMenuItem, MybusinessMenuLabel } from "@/types/ls-api";
// コンポーネント定義
export default defineComponent({
  props: {
    menus: { type: Array, required: true }, // MybusinessFoodMenu[]
  },
  data: () => {
    return {};
  },
  async created() {},
  methods: {
    toStringPrice(item: MybusinessFoodMenuItem): string {
      const units = `${item?.attributes?.price?.units ?? ""}`;
      const nanos = item?.attributes?.price?.nanos ?? 0;
      const cc = `${item?.attributes?.price?.currencyCode ?? ""}`;
      const nanosstr = nanos === 0 ? "" : `.${nanos}`.replace(/0+$/, "");
      return `${units}${nanosstr} ${cc}`;
    },
    toStringLabels(labels: MybusinessMenuLabel[]): string {
      return labels.map((l) => `${l.displayName},${l.description ?? ""}`).join("\n");
    },
  },
});
</script>
<style lang="scss" scoped></style>
