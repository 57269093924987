<template>
  <v-data-iterator :items="histories" :page="pageNumber" items-per-page="10">
    <template #default="{ items: iteratedItems }">
      <template v-for="(item, i) in iteratedItems" :key="i">
        <v-card class="mb-2">
          <div class="font-weight-bold px-2 my-2">
            <span>{{ convertDateTime(item.raw.createDateTime) }}</span>
          </div>
          <div class="font-weight-bold px-2 my-1">
            <span>{{ item.raw.title }}</span>
          </div>

          <table class="my-3 ml-2 info-table">
            <tr>
              <td>状態</td>
              <td>{{ convertState(item.raw.state) }}</td>
            </tr>
            <tr>
              <td>最終更新ユーザー</td>
              <td>{{ getUserName(item.raw) }}</td>
            </tr>
            <tr>
              <td>GBP種別</td>
              <td>{{ getGBPType(item.raw) }}</td>
            </tr>
            <tr>
              <td>掲載開始日時</td>
              <td>{{ convertDateTime(item.raw.posting, true) }}</td>
            </tr>
            <tr>
              <td>掲載終了日時</td>
              <td>{{ convertDateTime(item.raw.cancellationReservation, true) }}</td>
            </tr>
          </table>
          <v-card-actions class="flex-column justify-start">
            <div class="d-flex justify-center">
              <v-btn
                variant="outlined"
                rounded
                class="mr-2"
                :disabled="!item.raw.isEdit"
                @click="postEdit(item.raw)"
              >
                <template #prepend>
                  <v-icon class="pl-1">fas fa-pencil</v-icon>
                </template>
                {{ canManage ? "編集" : "詳細を確認" }}
              </v-btn>
              <v-btn
                v-if="canManage"
                variant="outlined"
                rounded
                class="mr-2"
                :disabled="!item.raw.isDelete"
                @click="postDelete(item.raw)"
              >
                <template #prepend>
                  <v-icon class="pl-1">far fa-trash-alt</v-icon>
                </template>
                削除
              </v-btn>
              <v-btn
                v-if="canManage"
                variant="outlined"
                rounded
                class="mr-2"
                :disabled="!item.raw.isRetry"
                @click="postRetry(item.raw)"
              >
                <template #prepend>
                  <v-icon class="pl-1">fas fa-person-running</v-icon>
                </template>
                再実行
              </v-btn>
            </div>
            <div v-if="!item.raw.incompleteNew" class="d-flex align-center mt-5 mb-2">
              <v-btn
                v-for="(platform, index) in platforms"
                :key="index"
                size="medium"
                class="platform-button"
                :disabled="!containsDestination(item.raw, platform.name)"
                @click.stop="showDetails(item.raw, platform.name)"
              >
                <!-- アイコンと文字表示を縦組みで表示する -->
                <div class="vertical-layout">
                  <v-icon :color="platform.color" :icon="platform.icon" :size="platform.iconSize" />
                  <!-- エラーがあった場合は文字色を変える -->
                  <!--eslint-disable vue/no-v-html-->
                  <span
                    class="font-weight-bold pl-1 mt-1"
                    :style="isFailed(item.raw[`${platform.name}State`])"
                    v-html="
                      convertStateForPlatform(
                        item.raw[`${platform.name}State`],
                        item.raw[`${platform.name}StoreCount`],
                        item.raw[`${platform.name}PostCount`],
                        item.raw[`${platform.name}ErrorCount`]
                      )
                    "
                  ></span>
                </div>
                <template v-if="containsDestination(item.raw, platform.name)" #append>
                  <v-icon>
                    {{
                      item.raw.show && item.raw.tab === platform.name
                        ? "mdi-chevron-up"
                        : "mdi-chevron-down"
                    }}
                  </v-icon>
                </template>
              </v-btn>
            </div>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="item.raw.show">
              <v-progress-linear v-if="item.raw.detailsLoading" indeterminate color="primary" />
              <v-window v-else v-model="item.raw.tab">
                <v-window-item v-for="platform in allPlatforms" :key="platform" :value="platform">
                  <v-list-item
                    v-for="(list, key) in item.raw[`${platform}FailedList`]"
                    :key="key"
                    class="mb-3"
                  >
                    <p class="font-weight-bold detail-title mb-2">▼ {{ key }}</p>
                    <v-list-item-subtitle
                      v-for="(status, statusKey) in list"
                      :key="statusKey"
                      class="detail-info pl-2 my-1"
                    >
                      <!-- 最終更新日時はまとめてカード部分に表示したかったが、親ファイルの最終更新日時が空だった -->
                      <span class="mr-1">{{ convertDateTime(status.lastUpdate) }}</span>
                      {{ status.poiID }} {{ status.storeName }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <div
                    v-if="Object.keys(item.raw[`${platform}FailedList`]).length === 0"
                    class="font-weight-bold detail-title ml-3 mt-3 mb-3"
                  >
                    投稿に失敗した店舗はありませんでした
                  </div>
                </v-window-item>
              </v-window>
            </div>
          </v-expand-transition>
        </v-card>
      </template>
    </template>
    <template #footer="{ pageCount }">
      <!-- ページ遷移したら上部まで戻る -->
      <v-pagination
        v-model="pageNumber"
        :length="pageCount"
        density="comfortable"
        active-color="primary"
        @click="pageTop"
      />
    </template>
  </v-data-iterator>
  <div v-if="loading" class="progress-circular-container">
    <v-progress-circular :size="64" :width="4" color="primary" indeterminate />
  </div>
  <v-dialog v-model="showModal" width="auto">
    <v-card>
      <v-card-text>
        ファイル一括での投稿の編集はスマートフォン版では対応しておりません。PC版でのご利用をお願いいたします。
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text="閉じる" @click="showModal = false" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { requiredAuth } from "@/helpers";
import { convertDateTime } from "@/helpers/date";
import { useIndexedDb } from "@/storepinia/idxdb";
import { getGBPType, getUserName, convertState } from "./utils";

import type {
  EntitiesV2HistoryPostData,
  ControllersHistoryDetailsOutput,
  EntitiesV2PoiStatus,
} from "@/types/ls-api";

type PlatformNames = "gbp" | "yahoo" | "fb" | "ig";

type CardPostData = EntitiesV2HistoryPostData & {
  show: boolean;
  detailsLoading: boolean;
  tab: "" | PlatformNames;
  gbpDetails: EntitiesV2PoiStatus[];
  yahooDetails: EntitiesV2PoiStatus[];
  fbDetails: EntitiesV2PoiStatus[];
  igDetails: EntitiesV2PoiStatus[];
  gbpFailedList: Record<string, FailedRecord[]>;
  yahooFailedList: Record<string, FailedRecord[]>;
  fbFailedList: Record<string, FailedRecord[]>;
  igFailedList: Record<string, FailedRecord[]>;
};

type FailedRecord = EntitiesV2PoiStatus & {
  storeName: string;
};

export default defineComponent({
  props: {
    items: {
      type: Array<EntitiesV2HistoryPostData>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["post-edit", "post-delete", "post-retry"],
  data() {
    return {
      pageNumber: 1 as number,
      stores: useIndexedDb().stores,
      platforms: [
        { name: "gbp", icon: "$google", color: "google", iconSize: "small" },
        { name: "yahoo", icon: "$yahoo", color: "yahoo", iconSize: "large" },
        { name: "ig", icon: "$instagram", color: "instagram", iconSize: "small" },
        { name: "fb", icon: "$facebook", color: "facebook", iconSize: "small" },
      ] as { name: PlatformNames; icon: string; color: string; iconSize: string }[],
      allPlatforms: ["gbp", "yahoo", "fb", "ig"] as PlatformNames[],
      showModal: false as boolean,
    };
  },
  computed: {
    canManage(): boolean {
      return useIndexedDb().canManagePost;
    },
    histories(): CardPostData[] {
      // 詳細を開くフラグを追加
      const histories = [...this.$props.items] as CardPostData[];
      histories.forEach((history) => {
        history.show = false;
        history.detailsLoading = false;
        history.tab = "";
        history.gbpDetails = [];
        history.yahooDetails = [];
        history.fbDetails = [];
        history.igDetails = [];
        history.gbpFailedList = {};
        history.yahooFailedList = {};
        history.fbFailedList = {};
        history.igFailedList = {};
      });
      return histories;
    },
  },
  methods: {
    getGBPType,
    getUserName,
    convertDateTime,
    convertState,
    // 投稿先媒体のところが狭いので表示を工夫するためのメソッド
    convertStateForPlatform(
      state: string,
      storeCount: number = 0,
      postCount: number = 0,
      errorCount: number = 0
    ) {
      const converted = convertState(state, storeCount, postCount, errorCount, false);
      // convertedに"("が含まれている場合に改行を挿入
      if (converted.includes("(")) {
        return converted.replace("(", "<br>(");
      }
      return converted;
    },
    postEdit(item: CardPostData): void {
      if (item.xlsxMode) {
        this.showModal = true;
      } else {
        this.$emit("post-edit", item as EntitiesV2HistoryPostData);
      }
    },
    postDelete(item: CardPostData): void {
      this.$emit("post-delete", item as EntitiesV2HistoryPostData);
    },
    postRetry(item: CardPostData): void {
      this.$emit("post-retry", item as EntitiesV2HistoryPostData);
    },
    containsDestination(item: CardPostData, platform: PlatformNames): boolean {
      const platformFileName = platform === "gbp" ? "gmb" : platform;
      const filtered =
        item.fileNames?.filter((fileName) => {
          return fileName.includes(`${platformFileName}_`);
        }) ?? [];
      return filtered.length > 0;
    },
    isFailed(state: string): string {
      return state.includes("FAILED") ? "color: red;" : "color: black;";
    },
    async showDetails(item: CardPostData, platform: PlatformNames): Promise<void> {
      if (!item.show || (item.show && item.tab == platform)) {
        item.show = !item.show;
      }
      item.tab = platform;

      if (item.show) {
        item.detailsLoading = true;

        // 詳細データをリセット
        item.gbpDetails = [];
        item.yahooDetails = [];
        item.fbDetails = [];
        item.igDetails = [];
        item.gbpFailedList = {};
        item.yahooFailedList = {};
        item.fbFailedList = {};
        item.igFailedList = {};

        const asyncList = item.fileNames?.map((filePath) => {
          return this.loadDetails(item, filePath);
        });
        if (asyncList?.length > 0) {
          await Promise.all(asyncList);
        }
        // 失敗投稿にフィルタリング
        for (const platform of this.allPlatforms) {
          item[`${platform}FailedList`] = this.filterByFailed(item[`${platform}Details`]);
        }

        item.detailsLoading = false;
      }
    },
    async loadDetails(data: CardPostData, filePath: string): Promise<void> {
      const pathList = filePath.split("/");
      const userUUID = pathList[3];
      const uuid = pathList[4];
      const fileName = pathList[5];
      try {
        const result = await requiredAuth<ControllersHistoryDetailsOutput>(
          "get",
          `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
            data.poiGroupID
          }/post/histories/details/${userUUID}/${uuid}/${fileName}`
        );
        if (result?.data?.file?.includes("gmb_")) {
          data.gbpDetails = data.gbpDetails.concat(result?.data?.details);
        } else if (result?.data?.file?.includes("yahoo_")) {
          data.yahooDetails = data.yahooDetails.concat(result?.data?.details);
        } else if (result?.data?.file?.includes("fb_")) {
          data.fbDetails = data.fbDetails.concat(result?.data?.details);
        } else if (result?.data?.file?.includes("ig_")) {
          data.igDetails = data.igDetails.concat(result?.data?.details);
        }
      } catch (e) {
        console.error(e);
      }
    },
    filterByFailed(data: EntitiesV2PoiStatus[]): Record<string, FailedRecord[]> {
      // 失敗・もしくは未連携エラーのみ抽出
      const grouped = data?.reduce((acc, d) => {
        if (d.status.includes("FAILED") || d.status.includes("UNREGISTERED")) {
          if (!acc[d.message]) {
            acc[d.message] = [];
          }
          const record = d as FailedRecord;
          record.storeName = this.stores.stores?.find((s) => s.poiID === record.poiID)?.name ?? "";
          acc[d.message].push(record);
        }
        return acc;
      }, {} as Record<string, FailedRecord[]>);
      return grouped;
    },
    pageTop() {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

.info-table {
  tr > td:nth-child(1) {
    background-color: #f5f5f5;
    text-align: center;
    padding: 0 0.25rem;
  }

  tr > td:nth-child(2) {
    padding-left: 1rem;
  }
}

.detail-title {
  font-size: 0.8rem;
  white-space: pre-wrap;
}

.detail-info {
  @extend .detail-title;
  opacity: unset;
}

.platform-button {
  margin: unset !important;
  .vertical-layout {
    // 投稿先媒体の数によって横幅のmin値は調整してください
    min-width: 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
  }
}
</style>
