<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">リンクの編集</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col>
              <v-select
                v-model="placeActionType"
                class="select-type"
                :items="selectItems"
                item-title="label"
                item-value="value"
                label="種別"
                :rules="[rules.required]"
                dense
              ></v-select>
            </v-col>
            <v-col>
              <v-checkbox v-model="isPreferred" label="このリンクを優先表示する" color="primary" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="uri" label="URL" :rules="[rules.required, rules.url]" />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text="キャンセル" variant="text" @click.prevent="close" />
      <v-btn color="primary" text="保存" variant="flat" @click.prevent="submit" />
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { VForm } from "vuetify/lib/components/index.mjs";
import type { PlaceActionLinkData } from "./place-action-links";
import { placeActionTypes } from "./place-action-links";
import { VuetifyValidator } from "@/helpers";

export default defineComponent({
  props: {
    editedItem: {
      type: Object as () => PlaceActionLinkData,
      default: () => ({} as PlaceActionLinkData),
    },
  },
  emits: ["update:editedItem", "cancel", "submit"],
  data: () => {
    return {
      selectedItem: null,
      rules: VuetifyValidator,
    };
  },
  computed: {
    selectItems() {
      return Object.entries(placeActionTypes).map(([key, value]) => ({
        value: key,
        label: value,
      }));
    },
    placeActionType: {
      get(): string {
        return this.editedItem.placeActionType;
      },
      set(newVal: string) {
        const setVal: PlaceActionLinkData = { ...this.editedItem, placeActionType: newVal };
        this.$emit("update:editedItem", setVal);
      },
    },
    isPreferred: {
      get(): boolean {
        return this.editedItem.isPreferred;
      },
      set(newVal: boolean) {
        const setVal: PlaceActionLinkData = { ...this.editedItem, isPreferred: newVal };
        this.$emit("update:editedItem", setVal);
      },
    },
    uri: {
      get(): string {
        return this.editedItem.uri;
      },
      set(newVal: string) {
        const setVal: PlaceActionLinkData = { ...this.editedItem, uri: newVal };
        this.$emit("update:editedItem", setVal);
      },
    },
  },
  methods: {
    close(): void {
      (this.$refs.form as VForm)?.resetValidation();
      this.$emit("cancel");
    },
    submit(): void {
      const validated = (this.$refs.form as VForm)?.validate();
      if (validated) {
        (this.$refs.form as VForm)?.resetValidation();
        this.$emit("submit");
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.select-type {
  padding-top: 1rem !important;
}
</style>
