<template>
  <div class="review-container">
    <h3>積み上げグラフと平均評点推移</h3>
    <div class="review-trend">
      <div v-if="loading" class="loading-circular">
        <v-progress-circular
          :size="50"
          :width="4"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <nav class="data-type-changer">
        <span
          :class="{ selected: graphDataType === 'amount' ? true : false }"
          @click="changeGraphDataType('amount')"
        >
          件数
        </span>
        <span
          :class="{ selected: graphDataType === 'ratio' ? true : false }"
          @click="changeGraphDataType('ratio')"
        >
          割合
        </span>
      </nav>
      <div id="stacked_chart_div" ref="stacked_chart_div"></div>
    </div>
  </div>
</template>
<script lang="ts">
import { requiredAuth } from "@/helpers";
import { Component, Prop, Vue, toNative } from "vue-facing-decorator";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { initChart, am5FontFamily } from "@/components/shared/am5-shared";
import dayjs from "dayjs";
import type { DomainReviewsRatingItem, DomainReviewsRatingSummary } from "@/types/ls-api";
import type { Rating, StarData, GraphData } from "@/components/shared/review-shared";
import { starColors } from "@/components/shared/review-shared";
import type { SelectedTarget } from "@/components/shared/store-selector.vue";
import { getter } from "@/storepinia/idxdb";

@Component({})
class ReviewTrend extends Vue {
  company = getter().company;
  stores = getter().stores;

  @Prop({ type: Object }) selectedTarget!: SelectedTarget;

  root: string = import.meta.env.VITE_APP_ROOT_PATH;

  /** グラフに表示するデータのタイプ [件数 or 割合] */
  graphDataType = "amount";
  /** APIから受け取ったデータ期間データ */
  starDataArr: StarData[];
  /** グラフ描画用に加工されたデータ */
  graphData: GraphData[];
  /** APIから受け取った合計データ **/
  sumData: DomainReviewsRatingItem;
  /** 期間平均データ */
  termAverageData: StarData;
  /** 最後にクリックしたバーの月(ドーナツ側選択月選んだ時の復元用) */
  private lastSelectedMonth: string;
  poiGroupId: number;

  loading = false;

  async pageLoad(dateFrom: Date, dateTo: Date): Promise<void> {
    this.poiGroupId = parseInt(this.$route.params.poiGroupId as string, 10);
    // 店舗選択の内容に応じたクエリを作ってAPIに渡す
    let targetQuery: string = "";
    if (this.selectedTarget.isNone) {
      return;
    }
    if (this.selectedTarget.isAll) {
      targetQuery = "";
    } else if (this.selectedTarget.isArea && this.selectedTarget.areaId > -1) {
      targetQuery = `&areaID=${this.selectedTarget.areaId}`;
    } else if (this.selectedTarget.poiIds.length > 0) {
      targetQuery = `&poiID=${this.selectedTarget.poiIds[0]}`;
    }

    this.starDataArr = [];
    this.lastSelectedMonth = "";
    let nextTime = dayjs(`${dateFrom.getFullYear()}-${dateFrom.getMonth() + 1}`);
    this.loading = true;
    // APIから取ってくる
    await requiredAuth<DomainReviewsRatingSummary>(
      "get",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
        this.poiGroupId
      }/reviews/rating-summary?&startYear=${dateFrom.getFullYear()}&startMonth=${
        dateFrom.getMonth() + 1
      }&endYear=${dateTo.getFullYear()}&endMonth=${dateTo.getMonth() + 1}${targetQuery}`
    )
      .then((res) => {
        if (res?.status === 200) {
          res.data?.ratingItems?.forEach((item) => {
            if (item.year === undefined || item.month === undefined) {
              // 合計データ(APIから返しているが、クライアント側でうまく使えなくて捨てています[ごめんなさい])
              this.sumData = item;
            } else {
              // 月毎データ
              while (item.year !== nextTime.year() || item.month !== nextTime.month() + 1) {
                this.makeStarData({
                  average: 0.0,
                  month: nextTime.month() + 1,
                  year: nextTime.year(),
                  star1: 0,
                  star2: 0,
                  star3: 0,
                  star4: 0,
                  star5: 0,
                });
                nextTime = dayjs(new Date(nextTime.year(), nextTime.month() + 1));
              }
              this.makeStarData(item);
              nextTime = dayjs(new Date(nextTime.year(), nextTime.month() + 1));
            }
          });
          this.makeGraphData();
          this.makeWholeTermData();
          this.makeStackedChart();
          this.$emit("updateDonut", this.termAverageData);
          this.loading = false;
        } else {
          console.error("[rating-summary]", res);
        }
      })
      .catch((e: any) => {
        console.error("[rating-summary]", e);
      });
  }

  /** APIから受け取ったデータを基に期間平均データを生成 */
  private makeWholeTermData(): void {
    const wholeTermRatings: Rating[] = [];
    for (let i = 0; i < 5; i++) {
      wholeTermRatings.push({
        star: i + 1,
        count: 0,
      });
    }
    for (let i = 0; i < this.starDataArr.length; i++) {
      const rating: Rating[] = this.starDataArr[i].ratings;
      for (const item of rating) {
        wholeTermRatings[item.star - 1].count += item.count;
      }
    }

    const wholeOfTotal =
      wholeTermRatings[0].count +
      wholeTermRatings[1].count +
      wholeTermRatings[2].count +
      wholeTermRatings[3].count +
      wholeTermRatings[4].count;

    this.termAverageData = {
      average:
        Math.round(
          ((wholeTermRatings[0].count +
            wholeTermRatings[1].count * 2 +
            wholeTermRatings[2].count * 3 +
            wholeTermRatings[3].count * 4 +
            wholeTermRatings[4].count * 5) /
            wholeOfTotal) *
            100
        ) / 100,
      ratings: wholeTermRatings,
    };
  }

  private async makeStarData(ratingSummary: DomainReviewsRatingItem): Promise<void> {
    const formattedMonth = dayjs(new Date(ratingSummary.year, ratingSummary.month - 1, 1)).format(
      "YYYY/MM"
    );
    // 重複による不正なデータ防止の為に同じmonthの値を持ってたら追加しない
    if (this.starDataArr.find((starData) => starData.month === formattedMonth)) {
      return;
    }
    // 左端と1月のグラフ以外は月だけ表示に加工する
    let displayedMonth = formattedMonth.replace(/\d{4}\//, "");
    displayedMonth = parseInt(displayedMonth, 10).toString();
    this.starDataArr.push({
      average: ratingSummary.average,
      displayedMonth: displayedMonth,
      month: formattedMonth,
      ratings: [
        { color: starColors[0], star: 1, count: ratingSummary.star1 },
        { color: starColors[1], star: 2, count: ratingSummary.star2 },
        { color: starColors[2], star: 3, count: ratingSummary.star3 },
        { color: starColors[3], star: 4, count: ratingSummary.star4 },
        { color: starColors[4], star: 5, count: ratingSummary.star5 },
      ],
    });
  }

  /** APIから受け取ったデータを基にグラフ表示用データを作る */
  private makeGraphData(): void {
    this.graphData = [];
    let i = 0;
    this.starDataArr.sort((a, b) => (a.month >= b.month ? 0 : -1));
    for (const item of this.starDataArr) {
      const graph: GraphData = {
        month: item.month,
        displayedMonth: i === 0 || item.displayedMonth === "1" ? item.month : item.displayedMonth,
        average: item.average,
        star1: item.ratings.find((rating) => rating.star === 1).count,
        star2: item.ratings.find((rating) => rating.star === 2).count,
        star3: item.ratings.find((rating) => rating.star === 3).count,
        star4: item.ratings.find((rating) => rating.star === 4).count,
        star5: item.ratings.find((rating) => rating.star === 5).count,
      };
      graph.total = graph.star1 + graph.star2 + graph.star3 + graph.star4 + graph.star5;
      graph.ratio1 = graph.star1 === 0 ? 0 : Math.round((graph.star1 / graph.total) * 10000) / 100;
      graph.ratio2 = graph.star2 === 0 ? 0 : Math.round((graph.star2 / graph.total) * 10000) / 100;
      graph.ratio3 = graph.star3 === 0 ? 0 : Math.round((graph.star3 / graph.total) * 10000) / 100;
      graph.ratio4 = graph.star4 === 0 ? 0 : Math.round((graph.star4 / graph.total) * 10000) / 100;
      graph.ratio5 = graph.star5 === 0 ? 0 : Math.round((graph.star5 / graph.total) * 10000) / 100;
      graph.marks =
        graph.total === 0
          ? 0
          : Math.round(
              ((graph.star1 +
                graph.star2 * 2 +
                graph.star3 * 3 +
                graph.star4 * 4 +
                graph.star5 * 5) /
                graph.total) *
                100
            ) / 100;
      this.graphData.push(graph);
      i++;
    }
  }

  makeStackedChart(reFocus = false): void {
    const eleId = "stacked_chart_div";
    // 高速でページ離脱するとAmcharts5のroot生成に失敗でエラーになるので中断する
    if (!document.getElementById(eleId)) {
      return;
    }
    let chart: am5xy.XYChart = am5.registry.entitiesById.stackedChart;
    if (chart) {
      // 表示期間変更時にグラフを再生成する為に、一旦破棄する
      chart.dispose();
    }

    const root: am5.Root = initChart(eleId);
    chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        id: "stackedChart",
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      })
    );

    const legends: am5.Legend = chart.children.push(
      am5.Legend.new(root, {
        id: "scLegend",
        nameField: "name",
        x: am5.percent(50),
        centerX: am5.percent(50),
        layout: am5.GridLayout.new(root, {
          maxColumns: 6,
          fixedWidthGrid: false,
        }),
      })
    );

    legends.markers.template.setAll({
      width: 10,
      height: 10,
    });
    const defaultFontSize = 10;
    legends.labels.template.setAll({
      fontSize: defaultFontSize,
      marginLeft: 5,
      marginRight: -35,
    });

    const xRenderer: am5xy.AxisRendererX = am5xy.AxisRendererX.new(root, {
      minGridDistance: 10,
      cellStartLocation: 0.2,
      cellEndLocation: 0.8,
    });
    xRenderer.grid.template.setAll({ location: 0.5 });
    xRenderer.labels.template.setAll({ location: 0.5, multiLocation: 0.5 });

    // X軸(年月)
    const categoryAxis: am5xy.CategoryAxis<am5xy.AxisRenderer> = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        id: "scCategoryAxis",
        maxDeviation: 0.2,
        renderer: xRenderer,
        startLocation: 0,
        endLocation: 1,
        dx: 0,
        categoryField: "displayedMonth",
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    categoryAxis.get("renderer").labels.template.setAll({
      maxWidth: 52,
      // ラベルのフォントサイズ自動調整
      oversizedBehavior: "fit",
      // ラベルのフォントサイズ手動調整
      fontSize: defaultFontSize,
      fontFamily: am5FontFamily,
      fill: am5.color("#333333"),
      paddingTop: 10,
    });
    // チャート下部のツールチップ消す
    categoryAxis.set(
      "tooltip",
      am5.Tooltip.new(root, {
        forceHidden: true,
      })
    );

    categoryAxis.data.setAll(this.graphData);
    const ken: am5.Label = am5.Label.new(root, {
      text: this.graphDataType === "ratio" ? "(%)" : "(件)",
      fontSize: defaultFontSize,
      fontFamily: am5FontFamily,
      position: "absolute",
      x: -28,
      y: 0,
      fill: am5.color("#333333"),
    });
    categoryAxis.children.unshift(ken);

    // Y軸(件数)
    const valueAxis: am5xy.ValueAxis<am5xy.AxisRenderer> = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        id: "scValueAxis",
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );
    valueAxis.get("renderer").labels.template.setAll({
      fontSize: defaultFontSize,
      fill: am5.color("#333333"),
    });
    if (this.graphDataType === "ratio") {
      valueAxis.setAll({
        strictMinMax: true,
        min: 0,
        max: 100,
      });
    }

    // Y軸(平均評点)
    const averageAxis: am5xy.ValueAxis<am5xy.AxisRenderer> = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
          minGridDistance: 20,
        }),
      })
    );
    averageAxis.setAll({
      strictMinMax: true,
      min: 0,
      max: 5,
    });

    const blue = "#42a4e4";
    const ten: am5.Label = am5.Label.new(root, {
      text: "(点)",
      fontSize: defaultFontSize,
      fontFamily: am5FontFamily,
      position: "absolute",
      x: -5,
      y: am5.percent(100),
      fill: am5.color(blue),
    });
    averageAxis.get("renderer").grid.template.setAll({
      visible: false,
    });
    // 評点の星数ラベルの色
    averageAxis.get("renderer").labels.template.setAll({
      fontSize: defaultFontSize,
      fontFamily: am5FontFamily,
      fill: am5.color(blue),
    });

    averageAxis.children.unshift(ten);
    chart.plotContainer.children.push(
      am5.Container.new(root, {
        id: "scSeriesContainer",
        x: 0,
        y: 0,
        width: am5.percent(100),
        height: am5.percent(100),
      })
    );

    for (let i = 0; i < 5; i++) {
      const starGrade = i + 1;
      let valueY: string;
      if (this.graphDataType === "ratio") {
        valueY = `ratio${starGrade}`;
      } else {
        valueY = `star${starGrade}`;
      }

      const series: am5xy.ColumnSeries = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          id: `scColumnSeries${i}`,
          name: `星${starGrade}つ`,
          xAxis: categoryAxis,
          yAxis: valueAxis,
          valueYField: valueY,
          categoryXField: "displayedMonth",
          maskContent: true,
          // 棒グラフの塗り色
          fill: am5.color(starColors[i]),
          stroke: am5.color("#f7ce93"),
          maskBullets: false,
          stacked: true,
          cursorOverStyle: "pointer",
        })
      );
      // 棒グラフクリックでデータを取得する為にtooltipを設定するがtooltip自体は表示しない
      const tooltip: am5.Tooltip = am5.Tooltip.new(root, {
        forceHidden: true,
      });
      series.events.on("click", this.onHitBar, this);
      series.columns.template.setAll({
        // 棒グラフの幅
        width: 20,
        strokeWidth: 5,
        strokeOpacity: 0,
        tooltip,
        tooltipText: "{categoryX}",
      });
      series.data.setAll(this.graphData);
      // Legendにseriesを関連付ける
      legends.data.push(series);
    }

    // 平均クチコミ評点
    const lineSeries: am5xy.LineSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "平均クチコミ評点（グラフ期間）",
        minBulletDistance: 10,
        xAxis: categoryAxis,
        yAxis: averageAxis,
        valueYField: "average",
        categoryXField: "displayedMonth",
        fill: am5.color(blue),
        stroke: am5.color(blue),
      })
    );
    lineSeries.strokes.template.setAll({
      strokeWidth: 1,
    });
    lineSeries.fills.template.setAll({
      visible: true,
    });

    // Legendにseriesを関連付ける
    legends.data.push(lineSeries);
    lineSeries.data.setAll(this.graphData);

    lineSeries.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 2,
          fill: lineSeries.get("fill"),
        }),
      });
    });

    // 何れかのグラフを選択した状態で「件数」と「割合」を切り替えた際に選択状態を復元する
    if (reFocus) {
      chart.plotContainer.events.on(
        "boundschanged",
        () => {
          this.focusBar(this.lastSelectedMonth);
        },
        true
      );
    }
  }

  defocusAllBars(): StarData {
    Object.keys(am5.registry.entitiesById).forEach((key) => {
      if (key.startsWith("scColumnSeries")) {
        for (const column of (am5.registry.entitiesById[key] as am5xy.ColumnSeries).columns.template
          .entities) {
          column.set("strokeOpacity", 0);
        }
      }
    });
    this.lastSelectedMonth = "";
    this.$emit("updateDonut", this.termAverageData, "");
    return this.termAverageData;
  }

  focusBar(pickedMonth?: string): void {
    const chosenData: StarData = this.starDataArr.find(
      (item) => item.month === this.lastSelectedMonth
    );
    this.$emit("updateDonut", chosenData, this.lastSelectedMonth);
    let targetMonth: string | number;
    if (pickedMonth) {
      targetMonth = pickedMonth;
    } else {
      targetMonth = this.lastSelectedMonth;
    }
    Object.keys(am5.registry.entitiesById).forEach((key) => {
      if (key.startsWith("scColumnSeries")) {
        for (const column of (am5.registry.entitiesById[key] as am5xy.ColumnSeries).columns.template
          .entities) {
          column.set(
            "strokeOpacity",
            (column.dataItem.dataContext as { month: string }).month === targetMonth ? 1 : 0
          );
        }
      }
    });
  }

  private onHitBar(e): void {
    const chosenMonth: string =
      e.target.columns.template.get("tooltip").adapters._entity.children._values[0]._dataItem
        .dataContext.month;
    if (chosenMonth === this.lastSelectedMonth) {
      this.lastSelectedMonth = "";
      this.defocusAllBars();
    } else {
      this.lastSelectedMonth = chosenMonth;
      this.focusBar();
    }
  }

  changeGraphDataType(type: string): void {
    if (type === this.graphDataType) {
      return;
    }
    /** valueYを選択したgraphDataTypeに切り替える為に
     * stackedChartを一旦破棄して再生成する */
    (am5.registry.entitiesById.stackedChart as am5xy.XYChart).dispose();
    this.graphDataType = type;
    this.makeStackedChart(this.lastSelectedMonth != "" ? true : false);
    this.$emit("changeGraphDataType", type);
  }
}
export default toNative(ReviewTrend);
</script>
<style lang="scss" scoped>
.review-container {
  margin: 10px;
  width: 100%;
  & > h3 {
    margin-left: 10px;
  }
}

$trendBlockHeight: 280px;

.review-trend {
  position: relative;
  box-sizing: border-box;
  margin: 10px;
  padding: 10px 0 15px 0;
  width: 100%;
  min-height: $trendBlockHeight;
  border: 1px solid #c6c6c6;
}

.loading-circular {
  position: absolute;
  width: 100%;
  // %だとcircularのサイズ調整が何故か無効になるのでpxで指定する
  height: $trendBlockHeight;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-loading);
}

.data-type-changer {
  font-size: 10px;
  margin: 0 0 0 10px;

  & > span {
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: #fff;

    &.selected {
      border-color: #c6c6c6;
    }
  }
}

#stacked_chart_div {
  width: 100%;
  height: 251px;
}
</style>
