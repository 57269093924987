<template>
  <v-card class="table-card" flat>
    <div class="custom-grid">
      <c-grid
        ref="gridTable"
        class="cgrid"
        :font="gridFont"
        :frozen-col-count="1"
        :theme="customTheme"
        style="font-size: small"
        :data="data"
      >
        <c-grid-column caption="店舗ID" :field="(item) => item.poiID" :column-style="columnStyle" />
        <c-grid-column
          width="100"
          caption="店舗コード"
          :field="(item) => item.storeCode"
          :column-style="columnStyle"
          :sort="sortColumn"
        />
        <c-grid-column
          :width="canManage ? 210 : 300"
          caption="ビジネス名"
          :field="(item) => item.storeName"
          :column-style="columnStyle"
          :sort="sortColumn"
        />
        <c-grid-column
          width="120"
          caption="プロバイダ"
          :field="(item) => convertStringByDict(item.providerType, providerTypes)"
          :column-style="getColumnStyle"
          :sort="sortColumn"
        />
        <c-grid-column
          caption="URL"
          :width="canManage ? 220 : 300"
          :field="(item) => item.uri"
          :column-style="getColumnStyle"
          :sort="sortColumn"
        />
        <c-grid-column
          caption="優先表示"
          column-type="check"
          :field="(item) => item.isPreferred"
          :action="checkIsPreferred"
          :column-style="getIsPreferredColumnStyle"
          :sort="sortColumn"
        />
        <c-grid-button-column
          v-if="canManage"
          width="60"
          :icon="getAddIcon"
          :column-style="{ textAlign: 'center', buttonBgColor: 'transparent' }"
          :disabled="(item) => !canAdd(item)"
          @click="addNewRecord"
        >
          新規作成
        </c-grid-button-column>
        <c-grid-button-column
          v-if="canManage"
          width="60"
          :icon="getEditIcon"
          :column-style="{ textAlign: 'center', buttonBgColor: 'transparent' }"
          :disabled="(item) => !canEdit(item)"
          @click="editItem"
        >
          編集
        </c-grid-button-column>
        <c-grid-button-column
          v-if="canManage"
          width="60"
          :icon="getDeleteIcon"
          :column-style="{ textAlign: 'center', buttonBgColor: 'transparent' }"
          :disabled="(item) => !canUndo(item) && !canEdit(item)"
          @click="(item) => (canUndo(item) ? undoDelete(item) : deleteItem(item))"
        >
          削除
        </c-grid-button-column>
      </c-grid>
      <v-dialog v-model="showEditDialog" max-width="600">
        <edit-dialog
          v-model:edited-item="editedItem"
          @cancel="cancelEditItem()"
          @submit="saveItem()"
        />
      </v-dialog>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EditDialog from "./place-action-link-edit-dialog.vue";
import {
  convertStringByDict,
  placeActionTypes,
  providerTypes,
  getTemporaryName,
} from "./place-action-links";
import type { PlaceActionLinkData } from "./place-action-links";

import type { ListGrid } from "cheetah-grid";
import type {
  CheetahGridIcon,
  CheetahGridColumnStyle,
  CustomSortState,
} from "@/components/shared/cheetah-grid-shared";
import { currentTheme } from "@/components/shared/theme";
import plusImage from "@/assets/images/plus-solid.svg";
import pencilImage from "@/assets/images/pencil-solid.svg";
import trashImage from "@/assets/images/trash-can-solid.svg";
import undoImage from "@/assets/images/undo-solid.svg";
import { useIndexedDb } from "@/storepinia/idxdb";

export default defineComponent({
  components: { EditDialog },
  props: {
    data: {
      type: Array as () => PlaceActionLinkData[],
      default: () => [] as PlaceActionLinkData[],
    },
    selectedType: { type: String, default: "" },
  },
  emits: ["updateItem", "saveItem", "addItem", "replaceItems", "sortData"],
  data: () => {
    return {
      canManage: useIndexedDb().canManagePlaceActionLinks,
      searchWord: "" as string,
      placeActionTypes,
      providerTypes,
      showEditDialog: false as boolean,
      defaultEditedItem: {
        poiID: -1,
        storeName: "",
        storeCode: "",
        locationName: "",
        providerType: "MERCHANT",
        placeActionType: "",
      } as PlaceActionLinkData,
      editedItem: null as PlaceActionLinkData,
      gridFont: "12.8px sans-serif" as string,
      // cheetah-gridのカスタムテーマ設定（失敗ステータス/ダーティーフラグの背景色を変更）
      customTheme: {
        // primaryカラー指定をする
        checkbox: {
          borderColor: currentTheme().vuetifyTheme.colors.primary,
          uncheckBgColor: "#fff",
          checkBgColor: currentTheme().vuetifyTheme.colors.primary,
        },
        defaultBgColor({ col, row, grid }: { col: number; row: number; grid: any }): string {
          if (col < grid.frozenColCount || row < grid.frozenRowCount) {
            return "#f0f0f0";
          }
          const rec = grid.dataSource.source[row - 1];
          const hasError = "#ff8888";
          const isDirty = "#aff";
          if (rec?.isError) {
            return hasError;
          } else if (rec?.dirtyFlag) {
            return isDirty;
          }
        },
      },
      columnStyle: {
        autoWrapText: false,
        lineHeight: "1.3em",
        textAlign: "left",
        textBaseline: "middle",
        textOverflow: "ellipsis",
      } as CheetahGridColumnStyle,
      // 直前に行ったソート順と対象列の保存
      customSortState: {
        col: 0,
        order: null,
      } as CustomSortState,
    };
  },
  methods: {
    getColumnStyle: function (): (rec: PlaceActionLinkData) => CheetahGridColumnStyle {
      const columnStyle = this.columnStyle;
      return function (item: PlaceActionLinkData): CheetahGridColumnStyle {
        if (item?.dirtyFlag && item?.deleteFlag) {
          return {
            ...columnStyle,
            visibility: "hidden",
          };
        }
        return columnStyle;
      };
    },
    getIsPreferredColumnStyle: function (): (rec: PlaceActionLinkData) => CheetahGridColumnStyle {
      const columnStyle = {
        ...this.columnStyle,
        textAlign: "center",
      };
      return function (item: PlaceActionLinkData): CheetahGridColumnStyle {
        if (
          (item?.dirtyFlag && item?.deleteFlag) ||
          item?.isEditable !== true ||
          item?.deleteFlag === true
        ) {
          return {
            ...columnStyle,
            visibility: "hidden",
          };
        }
        return columnStyle;
      };
    },
    gridUpdate(): void {
      (this.$refs.gridTable as ListGrid<unknown>)?.updateSize();
      (this.$refs.gridTable as ListGrid<unknown>)?.invalidate();
    },
    getAddIcon(item: PlaceActionLinkData): CheetahGridIcon | null {
      return this.canAdd(item) ? { src: plusImage, width: 20 } : null;
    },
    getEditIcon(item: PlaceActionLinkData): CheetahGridIcon | null {
      return this.canEdit(item) ? { src: pencilImage, width: 20 } : null;
    },
    getDeleteIcon(item: PlaceActionLinkData): CheetahGridIcon | null {
      return this.canUndo(item)
        ? { src: undoImage, width: 20 }
        : this.canEdit(item)
        ? { src: trashImage, width: 20 }
        : null;
    },
    canAdd(item: PlaceActionLinkData): boolean {
      return this.canEdit(item) || item.providerType === "NO_DATA";
    },
    canEdit(item: PlaceActionLinkData): boolean {
      return !!item.isEditable && item.providerType != "NO_DATA" && !item.deleteFlag;
    },
    canUndo(item: PlaceActionLinkData): boolean {
      return !!item.isEditable && item.providerType != "NO_DATA" && !!item.deleteFlag;
    },
    convertStringByDict,
    /* 新しく追加する */
    addNewRecord(item: PlaceActionLinkData): void {
      // 管理のために仮でnameを付ける
      this.editedItem = {
        ...this.defaultEditedItem,
        poiID: item.poiID,
        storeName: item.storeName,
        storeCode: item.storeCode,
        locationName: item.locationName,
        name: getTemporaryName(item.poiID),
        isEditable: true,
        placeActionType: this.selectedType,
      };
      this.showEditDialog = true;
    },
    /* 編集 */
    editItem(editedItem: PlaceActionLinkData): void {
      // 直接書き換えてしまうようなのでdeep copyする
      this.editedItem = { ...editedItem };
      this.showEditDialog = true;
    },
    /* 編集キャンセル */
    cancelEditItem(): void {
      this.editedItem = this.defaultEditedItem;
      this.showEditDialog = false;
    },
    /* 削除 */
    deleteItem(deleteItem: PlaceActionLinkData): void {
      const indexToRemove = this.data.findIndex((item) => item.name === deleteItem.name);
      if (indexToRemove !== -1) {
        deleteItem.deleteFlag = true;
        this.$emit("updateItem", indexToRemove, deleteItem);
        this.$emit("saveItem");
      }
    },
    /* 削除取り消し */
    undoDelete(targetItem: PlaceActionLinkData): void {
      const indexToUndo = this.data.findIndex((item) => item.name === targetItem.name);
      if (indexToUndo !== -1) {
        targetItem.deleteFlag = false;
        this.$emit("updateItem", indexToUndo, targetItem);
        this.$emit("saveItem");
      }
    },
    /* 新規・編集保存 */
    saveItem(): void {
      this.showEditDialog = false;
      // 新規だったら追加、編集だったら上書き
      if (this.editedItem.name.startsWith("Temporary/")) {
        this.$emit("addItem", this.editedItem);
        // データ無しがある場合は、データ無しレコードの削除フラグを立てておく
        const { index, item } = this.data.reduce(
          (acc, currentItem, currentIndex) => {
            if (
              currentItem.poiID === this.editedItem.poiID &&
              currentItem.placeActionType === this.editedItem.placeActionType &&
              currentItem.providerType === "NO_DATA"
            ) {
              acc.index = currentIndex;
              acc.item = currentItem;
            }
            return acc;
          },
          { index: -1, item: null }
        );
        if (index !== -1) {
          item.deleteFlag = true;
          this.$emit("updateItem", index, item);
        }
      } else {
        const index = this.data.findIndex((item) => item.name === this.editedItem.name);
        if (index !== -1) {
          this.$emit("updateItem", index, this.editedItem);
        }
      }
      this.singleCheck(this.editedItem);
      this.editedItem = this.defaultEditedItem;
      this.$emit("saveItem");
    },
    checkIsPreferred(updatedItem: PlaceActionLinkData): void {
      if (!this.canManage) {
        return;
      }
      // c-grid-columnにはdisabledがないので、条件に合致していたら早期リターンさせる
      if (updatedItem.isEditable !== true || updatedItem.deleteFlag === true) {
        return;
      }
      updatedItem.isPreferred = !updatedItem.isPreferred;
      this.editedItem = updatedItem;
      this.saveItem();
    },
    /* ロケーション・PlaceActionTypeごとにsingle-checkにするための処理 */
    singleCheck(updatedItem: PlaceActionLinkData): void {
      if (updatedItem?.isPreferred !== true) {
        return;
      }
      const newData = this.data.map((item) => {
        if (item.poiID === updatedItem.poiID) {
          if (item.name === updatedItem.name) {
            return updatedItem;
          }
          if (item.placeActionType === updatedItem.placeActionType) {
            // 前の値がtrueだった場合のみ反転させます
            const oldIsPreferred = item.isPreferred;
            item.isPreferred = oldIsPreferred ? false : oldIsPreferred;
            if (oldIsPreferred !== item.isPreferred) {
              item.dirtyFlag = true;
            }
          }
        }
        return item;
      });
      this.$emit("replaceItems", newData);
    },
    sortColumn(order: string | null, col: number, grid: ListGrid<PlaceActionLinkData>): void {
      // FIXME: タブを切り替えたときとキーワード絞り込みのときにソートがリセットされるが、ソートのアイコンはそのままになっている
      if (col !== this.customSortState.col) {
        // 直前のと違う列をソートしようとした場合はorderを初期化する
        this.customSortState.order = "desc";
        this.customSortState.col = col;
      } else {
        switch (this.customSortState.order) {
          case null:
            this.customSortState.order = "desc";
            break;
          case "desc":
            this.customSortState.order = "asc";
            break;
          case "asc":
            this.customSortState.order = null;
            break;
        }
      }
      // 実際にデータをソートする
      let orderVal: number = 1;
      const columnKeys: string[] = [
        "poiID",
        "storeCode",
        "storeName",
        "providerType",
        "uri",
        "isPreferred",
      ];
      let key = columnKeys[col];
      if (order === null) {
        key = columnKeys[0];
      } else if (order === "asc") {
        orderVal = -1;
      }
      this.$emit("sortData", key, orderVal);
      grid.invalidate();
      // ↑↓マーク制御
      grid.sortState.order = this.customSortState.order;
    },
  },
});
</script>

<style lang="scss" scoped>
.custom-grid {
  height: 98%;

  .cgrid {
    margin-top: -3rem;
    box-sizing: border-box;
    height: 100%;
    position: absolute !important;
    top: 50px;
    left: 0;
    right: 0;
    bottom: -70px;
  }
}

.message-col {
  padding-bottom: 1rem;
}

.table-top {
  margin-left: 0.5rem;
  margin-bottom: 1rem;
}

.button-group {
  margin-top: 1.25rem;
}

.new-record {
  background-color: #fff !important;
  vertical-align: middle;
}

.delete-record {
  text-decoration: line-through 1.5pt solid red;
}

.table-card {
  height: calc(100vh - 320px);
}
</style>
