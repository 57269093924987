<template>
  <div>
    <div>
      <div class="d-flex mb-2">
        <o-button
          data-testid="sync-gmb"
          class="me-auto"
          variant="primary"
          size="small"
          @click="showSyncGMB()"
        >
          GBP店舗同期
        </o-button>
        <div class="search-container">
          <div class="search-box">
            <v-text-field
              v-model="searchWord"
              data-testid="search-word"
              label="検索キーワード"
              variant="underlined"
              density="compact"
              single-line
              hide-details
              clearable
              prepend-inner-icon="mdi-magnify"
              color="primary"
              @keypress.enter="searchStores"
              @click:clear="
                searchWord = '';
                searchStores();
              "
            ></v-text-field>
          </div>
        </div>
        <o-button
          data-testid="filter-by-search-word"
          variant="primary"
          size="small"
          @click="searchStores"
        >
          絞り込み
        </o-button>
        <span style="margin-left: 10px">{{ serviceItems.length }}件表示中</span>
      </div>
      <div class="d-flex mb-2">
        <div class="ms-auto">
          <o-checkbox v-model="checkMode" @update:model-value="searchStores()">
            {{
              checkMode
                ? "選択(作業対象の店舗だけをチェックしてください)"
                : "コピー(コピー元の選択ボタンを押下してください)"
            }}
          </o-checkbox>
        </div>
      </div>
    </div>

    <div v-if="loading === false" class="custom-grid">
      <c-grid
        ref="cgrid"
        :frozen-col-count="5"
        style="font-size: small"
        class="text-sm-left cgrid"
        :font="gridFont"
        :theme="customTheme"
        :allow-range-paste="false"
        :delete-cell-value-on-del-key="true"
        :move-cell-on-tab-key="true"
        :move-cell-on-enter-key="true"
        :select-all-on-ctrl-a-key="true"
        :data="serviceItems"
        :disabled="loading"
      >
        <!-- コピー元をクリックするための選択用ボタン -->
        <c-grid-check-column v-if="checkMode" field="checked"></c-grid-check-column>
        <!-- 作業対象の店舗を選択するためのチェックボックス -->
        <c-grid-button-column
          v-else
          :caption="'選択'"
          @click="copyServiceItems"
        ></c-grid-button-column>

        <template v-for="(header, index) in headerItems" :key="index">
          <template v-if="header.isMulti">
            <c-grid-column-group
              :caption="header.caption"
              :header-style="{ bgColor: header.bgColor }"
            >
              <c-grid-column
                v-for="(mheader, mindex) in header.headers"
                :key="mindex"
                :field="mheader.field"
                :readonly="true"
                :disabled="mheader.disabled"
                :header-style="{
                  bgColor: mheader.bgColor,
                }"
                :column-style="{ textOverflow: 'ellipsis' }"
              >
                {{ mheader.caption }}
              </c-grid-column>
            </c-grid-column-group>
          </template>
          <template v-else>
            <c-grid-column
              :field="header.single.field"
              :column-style="{ textOverflow: 'ellipsis' }"
              :sort="sortColumn"
            >
              {{ header.single.caption }}
            </c-grid-column>
          </template>
        </template>
      </c-grid>

      <template v-if="confirmationStore">
        <v-dialog v-model="confirmationStore" width="700">
          <v-card>
            <v-card-title class="text-h5 gray lighten-2">
              <span>
                {{ confirmationServiceItem.id }}
                {{ confirmationServiceItem.storeCode }}
                {{ confirmationServiceItem.name }}
              </span>
            </v-card-title>

            <v-spacer></v-spacer>

            <template v-if="confirmationServiceItem.primaryCategory">
              <v-list-item two-line>
                <v-list-item-title>
                  {{ "primary カテゴリ : " + confirmationServiceItem.primaryCategory.categoryName }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-for="(si, index) in confirmationServiceItem.primaryCategory.ServiceItems"
                  :key="index"
                >
                  {{ si.serviceType }}
                  {{ si.name }}
                  {{ si.price }}
                  {{ si.description }}
                </v-list-item-subtitle>
              </v-list-item>
            </template>

            <template
              v-if="
                confirmationServiceItem.additionalCategories &&
                confirmationServiceItem.additionalCategories.length > 0
              "
            >
              <v-list-item
                v-for="(si, index) in confirmationServiceItem.additionalCategories"
                :key="index"
                two-line
              >
                <v-list-item-title>
                  {{ "additional カテゴリ : " + si.categoryName }}
                </v-list-item-title>
                <v-list-item-subtitle v-for="(i, iindex) in si.ServiceItems" :key="iindex">
                  {{ i.serviceType }}
                  {{ i.name }}
                  {{ i.price }}
                  {{ i.description }}
                </v-list-item-subtitle>
              </v-list-item>
            </template>

            <v-divider></v-divider>
            <span class="red--text pa-2 ma-2 mb-2">
              この店舗のカテゴリ・サービス機能が、他の店舗に上書きされます。よろしいですか？
            </span>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn variant="text" @click="confirmationStore = false">閉じる</v-btn>
              <v-btn color="primary" variant="text" @click="copyServiceItemExecute">
                コピー実行
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <v-dialog v-model="updateServiceItem.show" width="700" persistent>
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            {{ updateServiceItem.title }}
          </v-card-title>
          <v-progress-linear
            v-model="updateServiceItem.percentage"
            color="light-blue"
          ></v-progress-linear>
          <v-card-text>
            <p>{{ updateServiceItem.message }}</p>
            <p v-if="updateServiceItem.errorCode">
              <b>エラーコード:</b>
              {{ updateServiceItem.errorCode }}
            </p>
            <p v-if="updateServiceItem.errorCause.length > 0">
              <v-list-item-title>エラー理由</v-list-item-title>
              <template v-for="(c, i) in updateServiceItem.errorCause" :key="i">
                <div>
                  <v-divider></v-divider>
                  <!-- eslint-disable-next-line -->
                  <div v-html="c" class="errorDetails"></div>
                </div>
              </template>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              variant="text"
              :disabled="!updateServiceItem.cancelButton"
              @click="updateServiceItem.show = false"
            >
              {{ updateServiceItem.cancelButton }}
            </v-btn>
            <v-btn
              data-testid="dialog-accept"
              variant="text"
              :disabled="!updateServiceItem.acceptButton"
              @click="updateServiceItemsAcceptMatter"
            >
              {{ updateServiceItem.acceptButton }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog.show" width="500" persistent>
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            {{ dialog.title }}
          </v-card-title>
          <v-progress-linear v-model="dialog.percentage" color="light-blue"></v-progress-linear>
          <v-card-text>
            <p>{{ dialog.message }}</p>
            <p v-if="dialog.errorCode">
              <b>エラーコード:</b>
              {{ dialog.errorCode }}
            </p>
            <p v-if="dialog.errorCause">
              <b>エラー理由</b>
              <br />
              {{ dialog.errorCause }}
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn variant="text" :disabled="!dialog.cancelButton" @click="dialog.show = false">
              {{ dialog.cancelButton }}
            </v-btn>
            <v-btn
              data-testid="dialog-accept"
              variant="text"
              :disabled="!dialog.acceptButton"
              @click="acceptMatter"
            >
              {{ dialog.acceptButton }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-else class="custom-grid d-flex justify-center pa-1 align-center mb-1">
      <v-progress-circular indeterminate color="primary" :size="100"></v-progress-circular>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
