<template>
  <div>
    <h1 class="title is-4 is-spaced" style="margin-top: 15px">{{ reportName }}</h1>
    <div style="display: flex">
      <div class="search-area">
        <v-select
          v-if="!canShowAllowedStoresOnly"
          v-model="selectedAreaId"
          :items="areas"
          item-title="name"
          item-value="value"
          max-height="auto"
          autocomplete
          label="グループ"
          variant="underlined"
          density="compact"
          style="max-width: 400px"
          @update:model-value="changeCondition"
        />
        <div class="d-flex align-center">
          <v-checkbox
            v-model="needsManualUpdate"
            color="primary"
            :data-testid="`needs-manual-update`"
            :label="dict.needsManualUpdate.title"
            class="me-1 font-weight-bold"
            hide-details
            @update:model-value="changeCondition"
          />
          <ToolTipIcon :label="dict.needsManualUpdate.supplement" class="me-2" />

          <v-checkbox
            v-model="needsWaiting"
            color="primary"
            :data-testid="`needs-waiting`"
            :label="dict.needsWaiting.title"
            class="me-1 font-weight-bold"
            hide-details
            @update:model-value="changeCondition"
          />
          <ToolTipIcon class="tooltip" :label="dict.needsWaiting.supplement" />
        </div>
      </div>

      <div style="margin-left: auto; margin-top: auto">
        <v-alert
          v-model="showTopInfo"
          :data-testid="`hint`"
          border="start"
          colored-border
          type="info"
          closable
        >
          変更提案は「元に戻す」「承認する」以外に「店舗情報の一括更新」画面から正しい値をセットしても解消されます。
          <br />
          特に【未設定】に戻した属性に同じ変更提案がされる場合は「店舗情報の一括更新」画面から更新をお試しください。
          <br />
          Googleが必須と考える属性は「元に戻す」で【未設定】に書き換えてもすぐに同じ変更提案が行われます。
        </v-alert>

        <v-btn
          v-if="!showTopInfo"
          :data-testid="`hint-btn`"
          depressed
          color="info"
          style="margin-bottom: 16px"
          @click="showTopInfo = true"
        >
          <v-icon left dark>mdi-information</v-icon>
          変更提案に関するヒント
        </v-btn>
      </div>
    </div>

    <div v-if="storeUpdates.length === 0 && !loading">{{ dict.result_message_loading }}</div>
    <div v-else>
      <div class="d-flex aligh-center">
        <div class="d-flex aligh-center">
          <o-button
            :data-testid="`toggle-all-detail`"
            variant="none"
            size="small"
            class="white-button mt-3"
            style="margin-top: 6px"
            @click="toggleAllDetailRow()"
          >
            {{ isAllRowOpened ? "全行を閉じる" : "全行を開ける" }}
          </o-button>

          <v-checkbox
            v-if="canManageStoreUpdates"
            v-model="isAllRowChecked"
            label="全店舗を選択"
            color="primary"
            class="mr-6"
            :data-testid="`select-all-stores`"
            @update:model-value="setSelectAllDetailRow(isAllRowChecked)"
          />

          <v-checkbox
            v-if="canManageStoreUpdates"
            v-model="isAllItems"
            label="全項目を選択"
            color="primary"
            class="mr-4"
            :data-testid="`select-all-items`"
          />

          <v-autocomplete
            v-if="canManageStoreUpdates"
            v-model="selectedItems"
            :data-testid="`select-item`"
            :items="updateItems"
            item-title="text"
            item-value="value"
            label="項目を選択"
            variant="underlined"
            density="compact"
            chips
            small-chips
            multiple
            :disabled="isAllItems"
            color="primary"
            class="mt-2 mr-4"
            style="width: 400px"
          />
        </div>

        <div v-if="canManageStoreUpdates" class="d-flex flex-wrap ml-auto justify-end">
          <o-button
            variant="primary"
            :data-testid="`revert-btn`"
            :loading="revertLoading"
            :disabled="isSubmitButtonDisabled"
            style="margin-right: 0"
            @click="confirmRevert()"
          >
            選択した提案を元に戻す
          </o-button>

          <o-button
            variant="primary"
            :data-testid="`approval-btn`"
            class="approvalButton ml-3"
            :loading="adoptLoading"
            :disabled="isSubmitButtonDisabled"
            @click="confirmAdopt()"
          >
            選択した提案を承認する
          </o-button>
        </div>
      </div>

      <o-table
        ref="table"
        v-model:checked-rows="checkedRows"
        paginated
        backend-pagination
        :checkable="canManageStoreUpdates"
        :is-row-checkable="(row) => row.status === 1 && !isAllRowChecked"
        detailed
        custom-detail-row
        show-detail-icon
        detail-key="poiID"
        :data="storeUpdates"
        :current-page="currentPage"
        :per-page="perPage"
        :total="areaTotal"
        :loading="loading"
        @page-change="onPageChange"
      >
        <o-table-column :label="dict.name.title">
          <template #default="props">
            <span :data-testid="`name-${props.row.poiID}`">
              {{ getStoreName(props.row.poiID) }}
            </span>
          </template>
          <template #header>
            {{ dict.name.title }}
            <ToolTipIcon :label="dict.name.supplement" position="bottom" />
          </template>
        </o-table-column>
        <o-table-column :label="dict.updateTime.title">
          <template #default="props">
            <span :data-testid="`updateTime-${props.row.poiID}`">
              {{ formatDateTime(props.row.detectedTime) }}
            </span>
          </template>
          <template #header>
            {{ dict.updateTime.title }}
            <ToolTipIcon :label="dict.updateTime.supplement" position="bottom" />
          </template>
        </o-table-column>
        <o-table-column :label="dict.status.title">
          <template #default="props">
            <!-- 「手動更新が必要」でdashboardURLが分かる場合はリンクを張る -->
            <span :data-testid="`status-${props.row.poiID}`">
              <span
                v-if="props.row.status === 30 && getStoreLink(props.row.poiID) !== ''"
                style="vertical-align: top; font-weight: bold"
              >
                <a :href="getStoreLink(props.row.poiID)" target="_blank">
                  {{ getStateName(props.row.status, props.row.nextAction, props.row.errorItems) }}
                </a>
              </span>
              <span v-else style="vertical-align: top">
                {{ getStateName(props.row.status, props.row.nextAction, props.row.errorItems) }}
              </span>
              <ToolTipIcon
                v-if="props.row.nextAction != '' && props.row.nextAction != null"
                :label="props.row.nextAction"
              />
            </span>
          </template>
          <template #header>
            {{ dict.status.title }}
            <ToolTipIcon :label="dict.status.supplement" position="bottom" />
          </template>
        </o-table-column>
        <o-table-column :label="dict.caption.title">
          <template #default="props">
            <span :data-testid="`caption-${props.row.poiID}`">
              {{ props.row.diffItems.length }}
            </span>
          </template>
          <template #header>
            {{ dict.caption.title }}
            <ToolTipIcon :label="dict.caption.supplement" position="bottom" />
          </template>
        </o-table-column>
        <o-table-column :label="dict.source.title">
          <template #default />
          <template #header>
            {{ dict.source.title }}
            <ToolTipIcon :label="dict.source.supplement" position="bottom" />
          </template>
        </o-table-column>
        <o-table-column :label="dict.changes.title">
          <template #default />
          <template #header>
            {{ dict.changes.title }}
            <ToolTipIcon :label="dict.changes.supplement" position="bottom" />
          </template>
        </o-table-column>

        <template #detail="props">
          <tr v-for="(item, index) in props.row.diffItems" :key="props.row.poiID + '_' + index">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td :data-testid="`caption-${props.row.poiID}-${index}`">
              {{ item.caption === "" ? "---" : item.caption }}
            </td>
            <td :data-testid="`source-${props.row.poiID}-${index}`">
              {{ item.source === "" ? "---" : item.source }}
            </td>
            <td :data-testid="`changes-${props.row.poiID}-${index}`">
              {{ item.changes === "" ? "---" : item.changes }}
            </td>
          </tr>
        </template>
      </o-table>
    </div>
    <v-dialog v-model="dialog.show" width="500" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ dialog.title }}
        </v-card-title>
        <v-progress-linear v-model="dialog.percentage" color="light-blue"></v-progress-linear>
        <v-card-text>
          <div v-for="(text, index) in dialog.message.split('\n')" :key="index">
            {{ text }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!dialog.cancelButton" @click="dialog.cancelClick">
            {{ dialog.cancelButton }}
          </v-btn>
          <v-btn :disabled="!dialog.acceptButton" @click="dialog.acceptClick">
            {{ dialog.acceptButton }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, toNative } from "vue-facing-decorator";
import { requiredAuth } from "@/helpers";
import TipableTitleGroup from "@/models/tipable-title-group";
import wordDictionary from "@/word-dictionary";
import type {
  GoogleupdatesgetGoogleUpdatesResponse,
  EntitiesStore,
  EntitiesStoreUpdateDiff,
  EntitiesGoogleUpdatesPutResponse,
  EntitiesUpdateItem,
} from "@/types/ls-api";
import { readableStrDate } from "@/helpers/filters";
import { parallels } from "@/helpers/parallel-promise";
import { apiBase } from "@/const";
import type { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { getOperationLogParams } from "@/routes/operation-log";
import { useSnackbar } from "@/storepinia/snackbar";
import { getDashboardUrl } from "@/helpers/gmb";
import { getter } from "@/storepinia/idxdb";

interface UpdateItem {
  text: string;
  value: EntitiesUpdateItem;
}

@Component({})
class StoreUpdates extends Vue {
  @Prop({ type: Date }) dateTo: Date;
  @Prop({ type: Date }) dateFrom: Date;

  areaStores = getter().areaStores;
  company = getter().company;
  stores = getter().stores;
  canShowAllowedStoresOnly = getter().canShowAllowedStoresOnly;
  canManageStoreUpdates = getter().canManageStoreUpdate;
  addSnackbarMessages = useSnackbar().addSnackbarMessages;

  showTopInfo: boolean = false;
  loading: boolean = true;
  revertLoading: boolean = false;
  adoptLoading: boolean = false;
  poiGroupId: number;
  areas: any[] = [];
  selectedAreaId: number = 0;
  needsManualUpdate: boolean = false;
  needsWaiting: boolean = false;
  enabledStores: EntitiesStore[] = [];
  storeUpdates: EntitiesStoreUpdateDiff[] = [];
  checkedRows = [];

  dict: any = wordDictionary.storeUpdates;
  basic = wordDictionary.storeLocation;
  structured = wordDictionary.storeLocalBusiness;
  reportName: string = wordDictionary.reports.storeUpdates;

  isAllItems = true;
  updateItemMap = new Map<string, EntitiesUpdateItem>();
  updateItems: UpdateItem[] = [];
  selectedItems: EntitiesUpdateItem[] = [];

  // demoのときにボタンを押せなくするためのパラメータ
  isSubmitButtonDisabled = false;

  // ページング設定
  perPage: number = 100;
  currentPage: number = 1;
  areaTotal: number = 0;
  updateTotal: number = 0;
  defaultOpenedDetails = [];

  titles = new TipableTitleGroup([
    this.dict.name,
    this.dict.updateTime,
    this.dict.status,
    this.dict.caption,
    this.dict.source,
    this.dict.changes,
  ]);

  async created(): Promise<void> {
    if (import.meta.env.VITE_APP_APP_ENV == "demo") {
      // デモ環境の場合は送信ボタンを無効にする
      this.isSubmitButtonDisabled = true;
    }
    this.poiGroupId = this.company.poiGroupID;
    this.areas = this.getAreaItems();
    this.enabledStores = this.stores.stores?.filter((s) => s?.enabled === true);
    this.updateItemMap = new Map<string, EntitiesUpdateItem>(
      Object.entries(this.company.storeUpdates?.updateItems ?? {})
    );

    await this.pageLoad();

    if (this.areaTotal === 0) {
      this.addSnackbarMessages({
        text: "現在、Google変更提案を受けた店舗はありません。",
        color: "info",
      });
      return;
    }

    if (this.$route.query.updateTime != null) {
      let queryUpdateTime = dayjs(this.$route.query.updateTime.toString(), "YYYY-MM-DD-HH-mm-ss");
      queryUpdateTime = queryUpdateTime.add(1, "second"); // millisecond分があるため1秒だけ足しておく
      const dataUpdateTime = dayjs(this.storeUpdates[0].updateTime);

      if (dataUpdateTime.isAfter(queryUpdateTime)) {
        this.addSnackbarMessages({
          text: "通知メールより後に確認された変更提案がありました。お手数ですが改めてご確認ください。",
          color: "warning",
          timeout: 10000,
        });
        return;
      }
    }

    if (this.$route.query.adopt != null) {
      this.isAllRowChecked = true;
      this.setSelectAllDetailRow(true);
      this.isAllItems = true;
    }

    if (this.$route.query.adopt === "true") {
      this.confirmAdoptAllRow();
    } else if (this.$route.query.adopt === "false") {
      this.confirmRevertAllRow();
    }
  }

  async pageLoad(): Promise<void> {
    this.loading = true;
    await this.getStoreUpdates(
      this.currentPage,
      this.selectedAreaId,
      this.perPage,
      this.needsManualUpdate,
      this.needsWaiting
    ).then((res) => {
      const googleUpdates = res?.data?.googleUpdates ?? [];
      this.storeUpdates = googleUpdates.filter((s) => s.diffItems?.length > 0);
      this.areaTotal = res?.data?.count;
    });
    this.checkedRows = [];

    // 項目一覧を生成
    this.storeUpdates.map((s) => {
      s.diffItems.map((d) => {
        this.updateItemMap.set(d.caption, {
          updateMask: d.updateMask,
          attributeMask: d.attributeMask,
        });
      });
    });
    this.updateItems.splice(0);
    this.updateItemMap.forEach((value, key) => {
      this.updateItems.push({ text: key, value: value });
    });
    this.loading = false;
  }

  async putStoreUpdates(updates: EntitiesStoreUpdateDiff[], isAdopt: boolean): Promise<void> {
    try {
      let succeededCount = 0;
      this.dialog.message = "";

      const updatesPacks: EntitiesStoreUpdateDiff[][] = [];
      for (var i = 0; i < updates.length; i += 100) {
        updatesPacks.push(updates.slice(i, i + 100));
      }

      const promises = [];
      for (const pack of updatesPacks) {
        promises.push(
          this.putStoreUpdate(pack, isAdopt)
            .then((res) => {
              this.dialog.message += `\n・${this.getStoreName(pack[0].poiID)} を含む${
                pack.length
              }件を更新予約`;
              succeededCount += parseInt(res?.data ?? 0);
              this.dialog.percentage = (succeededCount / updates.length) * 100;
            })
            .catch((e) => {
              console.log(e);
            })
        );
      }

      // 100店舗ずつまとめて並列処理
      await Promise.all(promises);
      this.dialog.percentage = 100;
      this.dialog.title = "結果";
      this.dialog.message = `・${succeededCount}件を更新予約しました\n\n\n※GBP上に反映されるまで時間がかかります。「反映待ちの店舗を表示」にチェックをいれて状況を確認して下さい。`;
      this.dialog.acceptButton = "OK";
      this.pageLoad();
    } catch (e) {
      console.log(e);
      throw Error("店舗情報の更新予約に失敗しました。");
    }
  }

  async putStoreUpdate(pack: EntitiesStoreUpdateDiff[], isAdopt: boolean): Promise<any> {
    const url = `${apiBase}/companies/${this.poiGroupId}/storeUpdates`;
    const poiIds = pack.map((r) => r.poiID);
    const actionType = isAdopt ? "bulk-put" : "bulk-revert";
    const updateMasks = this.isAllItems ? [] : this.selectedItems.map((i) => i.updateMask);
    const attributeMasks = this.isAllItems ? [] : this.selectedItems.map((i) => i.attributeMask);
    const request = {
      adopt: isAdopt,
      poiIDs: poiIds,
      isAllItems: this.isAllItems,
      updateMasks: updateMasks.filter((v) => v),
      attributeMasks: attributeMasks.filter((v) => v),
    };
    return await requiredAuth<EntitiesGoogleUpdatesPutResponse>(
      "put",
      url,
      getOperationLogParams(this.$route, actionType, "stores_gmb_update"),
      request
    ).then((res) => {
      return res;
    });
  }

  validateSelectedState(): boolean {
    if (this.checkedRows.length === 0) {
      this.addSnackbarMessages({
        text: "対象の店舗にチェックを付けてください",
        color: "warning",
      });
      return true;
    }
    if (!this.isAllItems && this.selectedItems.length === 0) {
      this.addSnackbarMessages({
        text: "対象の項目を選択してください",
        color: "warning",
      });
      return true;
    }
    return false;
  }

  async confirmRevert(): Promise<void> {
    if (this.validateSelectedState()) {
      return;
    }
    if (this.isAllRowChecked) {
      await this.confirmRevertAllRow();
    } else {
      this.confirmRevertSelectedRow();
    }
  }

  async confirmAdopt(): Promise<void> {
    if (this.validateSelectedState()) {
      return;
    }
    if (this.isAllRowChecked) {
      await this.confirmAdoptAllRow();
    } else {
      this.confirmAdoptSelectedRow();
    }
  }

  confirmRevertSelectedRow(): void {
    this.setDialog(
      "確認",
      "選択中の" +
        this.checkedRows.length +
        "件の店舗情報に対して、提案があった内容を却下して元に戻してもよろしいですか？\n" +
        "特定のケースでは変更提案を『承認』してから元に戻します。その場合もエンドユーザーへの影響はありません。",
      "キャンセル",
      "元に戻す"
    );
    this.dialog.acceptClick = this.revertSelectedRow;
  }

  confirmAdoptSelectedRow(): void {
    if (this.validateSelectedState()) {
      return;
    }
    this.setDialog(
      "確認",
      "選択中の" +
        this.checkedRows.length +
        "件の店舗情報に対して、提案があった内容を承認してもよろしいですか？",
      "キャンセル",
      "承認する"
    );
    this.dialog.acceptClick = this.adoptSelectedRow;
  }

  async confirmRevertAllRow(): Promise<void> {
    this.revertLoading = true;
    await this.getAllStoreUpdatesCount();
    this.revertLoading = false;

    if (this.updateTotal === 0) {
      this.addSnackbarMessages({
        text: "元に戻せる店舗はありませんでした。",
        color: "warning",
      });
      return;
    }

    this.setDialog(
      "確認",
      `【全てのグループ】の${this.updateTotal}件の店舗情報に対して、提案があった内容を却下して元に戻してもよろしいですか？\n` +
        "特定のケースでは変更提案を『承認』してから元に戻します。その場合もエンドユーザーへの影響はありません。",
      "キャンセル",
      "全店舗を元に戻す"
    );
    this.dialog.acceptClick = this.revertAllRow;
  }

  async confirmAdoptAllRow(): Promise<void> {
    this.adoptLoading = true;
    await this.getAllStoreUpdatesCount();
    this.adoptLoading = false;

    if (this.updateTotal === 0) {
      this.addSnackbarMessages({
        text: "承認できる店舗はありませんでした。",
        color: "warning",
      });
      return;
    }

    this.setDialog(
      "確認",
      `【全てのグループ】の${this.updateTotal}件の店舗情報に対して、提案があった内容を承認してもよろしいですか？`,
      "キャンセル",
      "全店舗を承認する"
    );
    this.dialog.acceptClick = this.adoptAllRow;
  }

  async revertSelectedRow(): Promise<void> {
    this.setDialog(this.checkedRows.length + "件の店舗情報を元に戻しています");
    this.putStoreUpdates(this.checkedRows, false);
  }

  async adoptSelectedRow(): Promise<void> {
    this.setDialog(this.checkedRows.length + "件の店舗情報を承認しています");
    this.putStoreUpdates(this.checkedRows, true);
  }

  async revertAllRow(): Promise<void> {
    const targetRows = await this.getAllStoreUpdates();
    this.setDialog(targetRows.length + "件の店舗情報を元に戻しています");
    await this.putStoreUpdates(targetRows, false);
  }

  async adoptAllRow(): Promise<void> {
    const targetRows = await this.getAllStoreUpdates();
    this.setDialog(targetRows.length + "件の店舗情報を承認しています");
    await this.putStoreUpdates(targetRows, true);
  }

  async getStoreUpdates(
    page: number = 1,
    areaId: number = 0,
    pageSize: number = 200,
    needsManualUpdate: boolean = false,
    needsWaiting: boolean = false
  ): Promise<AxiosResponse<GoogleupdatesgetGoogleUpdatesResponse>> {
    const url = `${apiBase}/companies/${this.poiGroupId}/googleUpdates`;
    const params = {
      page,
      areaId,
      pageSize,
      needsManualUpdate,
      needsWaiting,
    };
    return await requiredAuth<GoogleupdatesgetGoogleUpdatesResponse>("get", url, params);
  }

  async getAllStoreUpdatesCount(): Promise<void> {
    await this.getStoreUpdates().then((res) => {
      this.updateTotal = res?.data?.count ?? 0;
    });
    return;
  }

  async getAllStoreUpdates(maxPageSize: number = 200): Promise<EntitiesStoreUpdateDiff[]> {
    const storeUpdates: EntitiesStoreUpdateDiff[] = [];
    const allArea = 0;

    const ps = parallels();
    for (var page = 1; page <= Math.ceil(this.updateTotal / maxPageSize); page++) {
      ps.add(
        this.getStoreUpdates(page, allArea, maxPageSize).then((res) => {
          storeUpdates.push(...(res?.data?.googleUpdates ?? []));
        })
      );
      await ps.race(10); // 10件づつまとめて並列実行
    }
    await ps.all(); // 余った分を実行
    return storeUpdates;
  }

  getStoreName(poiId: number): string {
    const stores = this.enabledStores.filter((s) => s.poiID == poiId);
    return stores.length > 0 && stores[0].enabled ? stores[0].name : "未登録店舗";
  }

  // GBP管理画面のダッシュボードURLを返す
  getStoreLink(poiId: number): string {
    const stores = this.enabledStores.filter((s) => s.poiID == poiId);
    return stores.length > 0 && stores[0].enabled ? getDashboardUrl(stores[0].gmbLocationID) : "";
  }

  formatDateTime(str: string): string {
    return readableStrDate(str);
  }

  getStateName(status: number, nextAction: string, errorItems: string[]): string {
    // 変更提案あり かつ 「【元に戻す】を実行しましたが同じ変更提案をされました」
    if (status === 1 && nextAction === this.dict.same_change_request_message) {
      return this.dict.same_change_request_state;
    }
    if (status === 30) {
      // 手動更新が必要 かつ エラー項目名の配列が返ってきていればステータスに合わせて表示
      const errorItemsString = errorItems?.length > 0 ? `（${errorItems.join(",")}）` : "";
      return `${this.dict.stateNames[status]}${errorItemsString}`;
    }
    return this.dict.stateNames[status];
  }

  private getAreaItems(): any[] {
    // 自社店舗一覧を作成
    const areaItems: any[] = [];
    areaItems.push({ name: "全てのグループ", value: 0 });
    this.areaStores?.forEach((a) => {
      if (a.stores.length) {
        areaItems.push({
          name: a?.name,
          value: a?.areaID,
        });
      }
    });
    return areaItems;
  }

  async changeCondition(): Promise<void> {
    this.currentPage = 1;
    await this.pageLoad();
  }

  isAllRowOpened = false;
  isAllRowChecked = false;

  toggleAllDetailRow(): void {
    if (this.isAllRowOpened) {
      this.setOpenAllDetailRow(false);
      this.isAllRowOpened = false;
    } else {
      this.setOpenAllDetailRow(true);
      this.isAllRowOpened = true;
    }
  }

  setOpenAllDetailRow(doOpen: boolean = true): void {
    const table: any = this.$refs.table;
    if (table?.data == null) {
      return;
    }
    table.data.forEach((row) => {
      if (
        (doOpen && !table.isVisibleDetailRow(row)) ||
        (!doOpen && table.isVisibleDetailRow(row))
      ) {
        table.toggleDetails(row);
      }
    });
  }

  setSelectAllDetailRow(doCheck: boolean = true): void {
    const table: any = this.$refs.table;
    if (table?.data == null) {
      return;
    }
    this.checkedRows = doCheck ? table.data : [];
  }

  async onPageChange(page: number) {
    this.currentPage = page;
    this.pageLoad();
  }

  dialog = {
    show: false,
    percentage: 0,
    title: "",
    message: "",
    cancelButton: "",
    acceptButton: "",
    cancelClick: async (): Promise<void> => {},
    acceptClick: async (): Promise<void> => {},
  };

  setDialog(
    title: string = "",
    message: string = "",
    cancelButton: string = "",
    acceptButton: string = ""
  ): void {
    this.dialog.show = true;
    this.dialog.title = title;
    this.dialog.message = message;
    this.dialog.cancelButton = cancelButton;
    this.dialog.acceptButton = acceptButton;
    this.dialog.cancelClick = async () => {
      this.dialog.show = false;
    };
    this.dialog.acceptClick = async () => {
      this.dialog.show = false;
    };
  }
}
export default toNative(StoreUpdates);
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;
@use "@/components/style/button.scss" as button;

.search-area-checkboxes {
  display: flex;
  align-items: baseline;
  :deep(.tooltip) {
    bottom: 3px;
  }
}

div.pagination {
  ul.pagination-list {
    margin: 0;
    list-style: none;
  }
}

div.search-area {
  background: color.$active-sidebar-color;
  padding: 1rem;
  margin-bottom: 1rem;
}

.table-content {
  white-space: pre-line;
  // 冒頭の空白行を消すための対応(Chromeのみ)
  &::first-line {
    line-height: 0;
  }
}

.iconPosition {
  padding-top: 5px;
  padding-left: 10px;
}

.buttonSection {
  text-align: right;
}

// 承認するボタン
.approvalButton {
  border-style: solid;
  border-width: 1px;
  &.is-primary {
    border-color: var(--primary) !important;
    background-color: #fff !important;
    color: var(--primary) !important;
    &:hover {
      color: #fff !important;
    }
  }
  &.is-danger {
    border-color: #ff3860;
    background-color: #fff;
    color: #ff3860;
  }
}
// 赤ボタンhover時殆ど見た目変わってなかったので変化つける
.button.is-danger {
  &:hover {
    opacity: 0.66;
  }
}
</style>
