<template>
  <v-row v-if="isShow" justify="center">
    <v-dialog
      v-for="dialog in dialogs"
      :key="dialog.id"
      :model-value="true"
      persistent
      max-width="640"
    >
      <v-card>
        <v-card-title v-if="dialog.title !== ''" class="text-h5 bg-grey-lighten-2">
          {{ dialog.title }}
        </v-card-title>
        <v-card-text class="d-flex flex-row align-center">
          <v-icon v-if="dialog.hasIcon && dialog.icon !== ''" size="large" :color="dialog.type">
            {{ dialog.icon }}
          </v-icon>
          <v-col class="text-body-1">
            {{ dialog.message }}
          </v-col>
        </v-card-text>
        <v-card-actions class="text-h5 bg-grey-lighten-2">
          <v-spacer />
          <v-btn v-if="dialog.cancelText !== ''" variant="flat" @click="remove(dialog.id)">
            {{ dialog.cancelText }}
          </v-btn>
          <v-btn
            v-if="dialog.confirmText !== ''"
            variant="flat"
            :color="dialog.type"
            @click="
              dialog.onConfirm();
              remove(dialog.id);
            "
          >
            {{ dialog.confirmText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { useDialog } from "@/storepinia/dialog";

export default defineComponent({
  props: {},
  data() {
    return { show: true };
  },
  computed: {
    ...mapState(useDialog, ["dialogs"]),
    isShow(): boolean {
      return this.dialogs.length !== 0;
    },
  },
  async created() {},
  methods: {
    remove(id: number): void {
      useDialog().remove(id);
    },
  },
});
</script>
<style lang="scss" scoped>
* {
  text-transform: none !important;
}
</style>
