<template>
  <v-dialog v-model="openModal" persistent width="800" @input="$emit('input', $event)">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ isApproveRequest ? "投稿承認要求" : "投稿確認" }}
      </v-card-title>

      <v-card-text class="text--primary">
        <p>以下の事項にご注意ください。</p>
        <li v-for="message of messages" :key="message">{{ message }}</li>
      </v-card-text>

      <template v-if="isManualApproveSelect && approveUserUUIDList.length > 0">
        <v-divider></v-divider>
        <v-card-text align="center" class="text--primary" style="padding-top: 0.5rem">
          <p>承認者を手動で選択するか、自動承認を選んでください</p>
        </v-card-text>

        <v-radio-group v-model="approveUserMode">
          <v-radio label="自動承認者選択" value="auto" color="primary" />
          <v-radio label="手動承認者選択" value="manual" color="primary" />
        </v-radio-group>

        <v-card-text v-if="approveUserMode == 'manual'">
          <p>承認者を選択してください</p>
          <v-checkbox
            v-for="item in approveUserUUIDList"
            :key="item.uuid"
            v-model="item.checked"
            :label="item.family_name + ' ' + item.first_name"
            hide-details
            color="primary"
          />
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-text-field
            v-model="requestRemark"
            label="備考"
            clearable
            class="mx-2"
            color="primary"
          />
        </v-card-actions>
      </template>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          variant="outlined"
          class="cancel-button mr-3"
          :disabled="disabled"
          @click.stop="$emit('close-dialog')"
        >
          キャンセル
        </v-btn>
        <v-btn
          v-if="!isApproveRequest"
          color="primary"
          class="px-8"
          variant="flat"
          :disabled="disabled"
          @click.prevent="$emit('submit-post')"
        >
          OK
        </v-btn>
        <v-btn
          v-else
          :disabled="!validate || disabled"
          color="primary"
          class="px-8"
          variant="flat"
          @click.prevent="submitRequest"
        >
          承認依頼
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import type {
  ControllersApproveUsersOutput,
  ControllersApproveUser,
  EntitiesCompany,
} from "@/types/ls-api";
import { requiredAuth } from "@/helpers/request";
import { useIndexedDb } from "@/storepinia/idxdb";

class ApproveUser {
  constructor(user: ControllersApproveUser) {
    this.uuid = user.uuid;
    this.family_name = user.family_name;
    this.first_name = user.first_name;
    this.checked = false;
  }
  uuid: string;
  family_name: string;
  first_name: string;
  checked: boolean;
}

export default defineComponent({
  props: {
    disabled: { type: Boolean, required: false },
    openConfirm: { type: Boolean, required: true, default: false },
    // 承認依頼権限のユーザーが投稿を作成している場合はtrue
    isApproveRequest: { type: Boolean, default: false },
    // 承認者の手動選択オプションが有効になっている企業はtrue
    isManualApproveSelect: { type: Boolean, default: false },
    messages: { type: Array as () => Array<string>, default: () => [] as string[] },
  },
  emits: ["close-dialog", "submit-post", "input"],
  data() {
    return {
      requestRemark: "",
      approveUserMode: "auto",
      approveUserUUIDList: [] as ApproveUser[],
    };
  },
  computed: {
    openModal: {
      get() {
        return this.openConfirm;
      },
      set(value: boolean) {
        this.$emit("input", value);
      },
    },
    company(): EntitiesCompany {
      return useIndexedDb().company;
    },
    validate(): boolean {
      if (this.isApproveRequest && this.approveUserMode === "manual") {
        for (const user of this.approveUserUUIDList) {
          if (user.checked === true) {
            return true;
          }
        }
        return false;
      }
      return true;
    },
  },
  async created() {
    if (this.isManualApproveSelect) {
      await this.fetch();
    }
  },
  methods: {
    async fetch(): Promise<void> {
      // 承認者一覧を取得
      try {
        const res = await requiredAuth<ControllersApproveUsersOutput>(
          "get",
          `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
            this.company.poiGroupID
          }/roles/approve/users`,
          { target: "post" }
        );
        if (res.status === 200) {
          this.approveUserUUIDList = [];
          for (const user of res.data.users) {
            this.approveUserUUIDList.push(new ApproveUser(user));
          }
          if (this.approveUserUUIDList.length > 0) {
            this.approveUserMode = "manual";
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    submitRequest(): void {
      const uuidList = this.approveUserUUIDList
        .filter((user) => user.checked)
        .map((user) => user.uuid);
      this.$emit("submit-post", uuidList, this.requestRemark);
    },
  },
});
</script>
<style lang="scss" scoped>
.date-highlight {
  color: red;
  font-size: 1.5rem;
}

.broken-video {
  color: red;
}

.cancel-button {
  border-color: #808080 !important;
}
</style>
