import type { AxiosError } from "axios";
import { requiredAuth } from "@/helpers";
import { Component, Vue, toNative } from "vue-facing-decorator";
import * as filters from "@/helpers/filters";
import TipableTitleGroup from "@/models/tipable-title-group";
import wordDictionary from "@/word-dictionary";
import type { EntitiesArea, EntitiesAreasResponse } from "@/types/ls-api";
import { useSnackbar } from "@/storepinia/snackbar";
import { action } from "@/storepinia/idxdb";
import { useDialog } from "@/storepinia/dialog";

@Component({})
class Areas extends Vue {
    poiGroupId: number;
    areas: Array<Partial<EntitiesArea>> = [];
    loading: boolean = false;
    setAreas = action().setAreas;
    setAreaStores = action().setAreaStores;
    addSnackbarMessages = useSnackbar().addSnackbarMessages;
    reviewAlertConditionFormat = filters.reviewAlertConditionFormat;

    dict = wordDictionary.areas;
    titles = new TipableTitleGroup([wordDictionary.areas.name, wordDictionary.areas.edit]);

    async pageLoad(): Promise<void> {
        this.loading = true;

        this.poiGroupId = parseInt(this.$route.params.poiGroupId as string, 10);
        const response = await requiredAuth<EntitiesAreasResponse>(
            "get",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/areas`
        );
        if (response == null || response.data == null || response.data.areas == null) {
            this.areas = [];
        } else {
            this.areas = response.data.areas;
        }

        this.loading = false;
    }

    async mounted(): Promise<void> {
        await this.pageLoad();
    }

    deleteConfirm(row: EntitiesArea): void {
        useDialog().confirm({
            title: "グループ削除",
            message: "本当に削除してよろしいですか?",
            type: "error",
            hasIcon: true,
            onConfirm: async () => await this.deleteArea(row.areaID),
        });
    }

    async deleteArea(areaId: number): Promise<void> {
        try {
            this.loading = false;
            const response = await requiredAuth<EntitiesArea>(
                "delete",
                `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
                    this.poiGroupId
                }/areas/${areaId}`
            );

            if (response == null || response.data == null) {
                // 権限不足
            } else {
                this.setAreas(this.poiGroupId);
                this.setAreaStores(this.poiGroupId);
                this.addSnackbarMessages({
                    text: "グループを削除しました",
                    color: "success",
                });
            }
            this.pageLoad();
        } catch (e) {
            const axiosError = e as AxiosError;
            this.showErrorMessage(axiosError);
        } finally {
            this.loading = false;
        }
    }

    showErrorMessage(e: AxiosError): void {
        if (e.response?.status === 409) {
            this.addSnackbarMessages({
                text: "グループに所属しているユーザーが存在するため削除できませんでした。",
                color: "danger",
                options: { top: false },
            });
            return;
        }
        console.error(e);
        throw Error("グループ削除に失敗しました。");
    }
}
export default toNative(Areas);
