<template>
  <div class="review-container">
    <h3>選択月のクチコミ評点と件数</h3>
    <div class="review-donut">
      <div v-if="isLoading" class="loading-circular">
        <v-progress-circular
          :size="50"
          :width="4"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-select
        :key="refreshKey"
        v-model="selectedTerm"
        color="primary"
        class="term-selector"
        :items="termItems"
        item-title="label"
        item-value="value"
        label="表示対象"
        :full-width="false"
        single-line
        variant="outlined"
        density="compact"
        :hide-details="true"
        @update:model-value="onNarrowMonthChange"
      ></v-select>
      <div
        id="review-donut-chart"
        ref="chartdiv"
        :class="isLoading ? 'amloading' : 'amcontrast'"
      ></div>

      <dl class="tool-tip-board">
        <dt class="star-average">平均点</dt>
        <dd class="star-average">{{ average }}（{{ totalItems.toLocaleString() }}件）</dd>
        <dt class="star-grade star-grade-5" :style="starColor5">星5つ</dt>
        <dd class="star-grade">{{ ratios[4] }}%（{{ starCounts[4].toLocaleString() }}件）</dd>
        <dt class="star-grade star-grade-4" :style="starColor4">星4つ</dt>
        <dd class="star-grade">{{ ratios[3] }}%（{{ starCounts[3].toLocaleString() }}件）</dd>
        <dt class="star-grade star-grade-3" :style="starColor3">星3つ</dt>
        <dd class="star-grade">{{ ratios[2] }}%（{{ starCounts[2].toLocaleString() }}件）</dd>
        <dt class="star-grade star-grade-2" :style="starColor2">星2つ</dt>
        <dd class="star-grade">{{ ratios[1] }}%（{{ starCounts[1].toLocaleString() }}件）</dd>
        <dt class="star-grade star-grade-1" :style="starColor1">星1つ</dt>
        <dd class="star-grade">{{ ratios[0] }}%（{{ starCounts[0].toLocaleString() }}件）</dd>
      </dl>
    </div>
  </div>
</template>
<script lang="ts">
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import { initChart, am5FontFamily } from "@/components/shared/am5-shared";
import { Component, Model, Vue, toNative } from "vue-facing-decorator";
import type { EntitiesAreaStores } from "@/types/ls-api";
import type { StarData } from "@/components/shared/review-shared";
import { starColors } from "@/components/shared/review-shared";
import dayjs from "dayjs";
import { getter } from "@/storepinia/idxdb";

type TermItem = {
  value: string;
  label: string;
};

type ChartData = {
  count: number;
  star: number;
  color: {
    stroke: am5.Color;
    fill: am5.Color;
  };
};

@Component({})
class ReviewDonut extends Vue {
  company = getter().company;
  targetAreaStores: EntitiesAreaStores[] = [];
  stores = getter().stores;
  @Model({ name: "loading" }) isLoading!: boolean;

  root: string = import.meta.env.VITE_APP_ROOT_PATH;

  get starColor5(): Record<string, string> {
    return { "--star-color5": starColors[4] };
  }
  get starColor4(): Record<string, string> {
    return { "--star-color4": starColors[3] };
  }
  get starColor3(): Record<string, string> {
    return { "--star-color3": starColors[2] };
  }
  get starColor2(): Record<string, string> {
    return { "--star-color2": starColors[1] };
  }
  get starColor1(): Record<string, string> {
    return { "--star-color1": starColors[0] };
  }

  selectedTerm = "";
  private readonly fixedTermItem: TermItem = { value: "", label: "指定期間平均" };
  termItems: TermItem[] = [this.fixedTermItem];
  refreshKey = 0;
  average = 0;
  totalItems = 0;
  /** 積み上げグラフの表示モード ドーナツ側で変更することはない */
  graphDataType = "amount";
  /** 評点詳細表示用割合 */
  ratios: number[] = [0, 0, 0, 0, 0];
  /** 評点詳細表示用星数 */
  starCounts: number[] = [0, 0, 0, 0, 0];
  // mount時に空のドーナツ生成しておく
  starData: StarData = {
    average: 0,
    ratings: [
      {
        star: 1,
        count: 0,
      },
      {
        star: 2,
        count: 0,
      },
      {
        star: 3,
        count: 0,
      },
      {
        star: 4,
        count: 0,
      },
      {
        star: 5,
        count: 0,
      },
    ],
  };

  async mounted(): Promise<void> {
    this.makeReviewDonut();
  }

  private putDonutColors(baseData: StarData): StarData {
    const stars: StarData = Object.assign({}, baseData);
    this.totalItems = 0;
    let i = 0;
    for (i = 0; i < starColors.length; i++) {
      const rating = stars.ratings[i];
      rating.color = starColors[i];
      this.totalItems += rating.count;
    }

    // this.totalItems確定後もう一度ループ回して各星のratioを算出する
    for (i = 0; i < starColors.length; i++) {
      const rating = stars.ratings[i];
      this.starCounts[i] = rating.count;
      this.ratios[i] =
        rating.count === 0 ? 0 : Math.round((rating.count / this.totalItems) * 10000) / 100;
    }
    return stars;
  }

  private makePieChartData(stars: StarData): ChartData[] {
    const pieChartData = stars.ratings.map((item) => {
      return {
        count: item.count,
        star: item.star,
        color: {
          stroke: am5.color(item.color),
          fill: am5.color(item.color),
        },
      };
    });
    return pieChartData;
  }

  private makeReviewDonut(): void {
    const eleId = "review-donut-chart";
    // 高速でページ離脱するとAmcharts5のroot生成に失敗でエラーになるので中断する
    if (!document.getElementById(eleId)) {
      return;
    }
    // チャート初期化
    const root: am5.Root = initChart("review-donut-chart");
    const chart: am5percent.PieChart = root.container.children.push(
      am5percent.PieChart.new(root, {
        maxWidth: 232,
        radius: 85,
        innerRadius: 60,
      })
    );
    const stars: StarData = this.putDonutColors(this.starData);
    const pieChartData = this.makePieChartData(stars);

    const pieSeries: am5percent.PieSeries = chart.series.push(
      am5percent.PieSeries.new(root, {
        id: "donutPieSeries",
        name: "選択月のクチコミ評点と件数",
        valueField: "count",
        categoryField: "star",
      })
    );

    pieSeries.labels.template.setAll({
      templateField: "color",
      visible: false,
    });
    pieSeries.ticks.template.setAll({ visible: false, forceHidden: true });
    pieSeries.slices.template.set("templateField", "color");
    pieSeries.data.setAll(pieChartData);
    pieSeries.ticks.template.set("disabled", true);

    const tooltip: am5.Tooltip = pieSeries.set(
      "tooltip",
      am5.Tooltip.new(root, {
        getFillFromSprite: false,
      })
    );

    tooltip.label.setAll({
      fill: am5.color("#333333"),
      fontFamily: am5FontFamily,
    });

    tooltip.get("background").setAll({
      fill: am5.color("#ffffff"),
      shadowColor: am5.color("#000000"),
      shadowBlur: 4,
      shadowOffsetY: 2,
      shadowOpacity: 0.5,
    });

    // ツールチップに星と数量と割合をいっぺんに表示させる為にマウスオーバー時にthis.ratiosを参照する必要がある
    pieSeries
      .get("tooltip")
      .adapters.add("labelText", (text: string, target: am5.Tooltip): string => {
        const targetDataItem: { star?: number } = target.children?.values[0]?.dataItem?.dataContext;
        if (targetDataItem) {
          const average = this.ratios[targetDataItem.star - 1];
          target.label.set("text", `{star}: ${average}% ({count})`);
        }
        return text;
      });

    // // 円グラフをクリックしても位置ずらさない
    pieSeries.slices.template.states.create("active", {
      shiftRadius: 0,
    });

    const averageLabelContainer: am5.Container = am5.Container.new(root, {});
    averageLabelContainer.setAll({
      x: am5.percent(50),
      y: am5.percent(50),
      centerX: am5.percent(50),
      centerY: am5.percent(32),
    });

    chart.children.unshift(averageLabelContainer);

    averageLabelContainer.children.push(
      am5.Label.new(root, {
        id: "averageLabel",
        text: "0",
        fontSize: 25,
        fontWeight: "700",
        fontFamily: am5FontFamily,
        textAlign: "center",
      })
    );

    averageLabelContainer.children.push(
      am5.Label.new(root, {
        id: "totalItemsLabel",
        text: "0件",
        fontSize: 14,
        fontFamily: am5FontFamily,
        textAlign: "center",
        y: 42,
      })
    );
  }

  onNarrowMonthChange(): void {
    if (this.selectedTerm === "") {
      this.$emit("showTermAverage");
    } else {
      this.$emit("showSoloMonth");
    }
  }

  changeDonutData(starData: StarData, chosenTerm: string): void {
    // ページ高速離脱エラー対策
    const averageLabel: am5.Label = am5.registry.entitiesById.averageLabel;
    const totalItemsLabel: am5.Label = am5.registry.entitiesById.totalItemsLabel;
    const donutPieSeries: am5percent.PieSeries = am5.registry.entitiesById.donutPieSeries;
    if (!averageLabel || !totalItemsLabel || !donutPieSeries) {
      return;
    }
    const stars: StarData = this.putDonutColors(starData);
    const pieChartData = this.makePieChartData(stars);
    // 小数第2位までに丸める
    this.average = isNaN(stars.average) ? 0 : Math.round(stars.average * 100) / 100;
    averageLabel.set("text", this.average.toString());
    totalItemsLabel.set("text", this.totalItems.toString() + "件");

    donutPieSeries.data.setAll(pieChartData);
    if (chosenTerm === undefined || chosenTerm === "") {
      // 平均だったら
      this.termItems = [this.fixedTermItem];
      this.selectedTerm = "";
    } else {
      const newLabel = dayjs(chosenTerm).format("YYYY年M月");
      this.termItems[1] = {
        value: chosenTerm,
        label: `選択月（${newLabel}）`,
      };
      this.selectedTerm = chosenTerm;
    }
    this.refreshKey++;
  }

  changeGraphDataType(type: string): void {
    this.graphDataType = type;
  }
}
export default toNative(ReviewDonut);
</script>

<style lang="scss" scoped>
.review-container {
  margin: 10px 0 0 0;
}

$donutBlockHeight: 305px;
.review-donut {
  box-sizing: border-box;
  position: relative;
  margin: 10px 0;
  padding: 10px;
  box-sizing: border-box;
  width: 500px;
  height: $donutBlockHeight;
  border: 1px solid #c6c6c6;
}

.v-select {
  display: block;
}

:deep() {
  .v-input__slot {
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .v-input .v-label {
    font-size: 14px;
  }

  .v-text-field > .v-input__control > .v-input__slot:before,
  .v-text-field > .v-input__control > .v-input__slot:after {
    border-style: none;
  }

  .term-selector {
    max-width: 250px;
    margin: 0;
    padding: 0;
  }
}

$circleSize: 220px;
#review-donut-chart {
  width: $circleSize;
  height: $circleSize;
  margin-left: 39px;
}

.tool-tip-board {
  position: absolute;
  top: 80px;
  right: 10px;
  font-size: 12px;
  display: grid;
  grid-template-columns: 54px 1fr;
  row-gap: 1em;

  dt {
    &::before {
      content: "";
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: 5px;
    }
  }

  dd {
    width: 13em;

    &:before {
      content: "…";
      display: inline;
      margin: 0 0.25em;
    }
  }

  dt.star-average {
    &::before {
      background-color: #ee9d27;
      border-radius: 50%;
    }
  }

  .star-grade-5 {
    &::before {
      background-color: var(--star-color5);
    }
  }

  .star-grade-4 {
    &::before {
      background-color: var(--star-color4);
    }
  }

  .star-grade-3 {
    &::before {
      background-color: var(--star-color3);
    }
  }

  .star-grade-2 {
    &::before {
      background-color: var(--star-color2);
    }
  }

  .star-grade-1 {
    &::before {
      background-color: var(--star-color1);
    }
  }
}

.loading-circular {
  position: absolute;
  width: 100%;
  // %だとcircularのサイズ調整が何故か無効になるのでpxで指定する
  height: $donutBlockHeight;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-loading);
}
</style>
