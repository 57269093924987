<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p style="font-size: 1.2rem; font-weight: bold">Facebookデータ削除リクエストを承りました</p>
      </header>
      <section class="modal-card-body">
        <p>データ削除状況を確認するには下記ボタンからご確認ください。 (別タブで開きます)</p>
      </section>
      <footer class="modal-card-foot">
        <router-link
          :to="{ name: 'AdminFacebookDeletion', params: { confirmationCode: propCode } }"
          target="_blank"
          class="link"
        >
          <button class="button is-primary mr-1" type="button">
            削除リクエストの結果を確認する
          </button>
        </router-link>
        <button class="button" type="button" @click="$emit('cancel')">確認しない</button>
      </footer>
    </div>
  </form>
</template>
<script lang="ts">
import { defineComponent } from "vue";
// Facebookデータ削除ステータス確認用モーダル
export default defineComponent({
  props: {
    propCode: { type: String, default: "" },
  },
  emits: ["cancel"],
});
</script>
