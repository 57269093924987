<template>
  <section>
    <div class="image-uploader mr-4">
      <o-field>
        <o-upload
          v-model="imageFiles"
          multiple
          :accept="acceptFileTypes"
          :loading="loading"
          :disabled="loading || disabled"
          drag-drop
          :expanded="isMobile"
        >
          <div v-if="isMobile" class="mobile-upload has-text-centered">
            <b>タップしてファイルをアップロード</b>
            <div class="mt-4">
              <v-icon class="mr-4">far fa-images</v-icon>
              <v-icon class="mr-4">far fa-file-video</v-icon>
              <v-icon>far fa-file-archive</v-icon>
              <div class="mt-4">.png / .jpg / .mov / .mp4 / .zip</div>
            </div>
          </div>
          <div v-else class="upload">
            <div class="col-left">
              ここをクリックするか、PNG/JPG/MOV/MP4/ZIPを
              <br />
              ドラッグ＆ドロップしてアップロードすることができます
            </div>
            <div class="col-right">
              <div>
                <p class="no-margin-bottom has-text-centered">
                  <o-icon pack="far" icon="images" class="icon" />
                  <o-icon pack="far" icon="file-video" class="icon" />
                  <o-icon pack="far" icon="file-archive" class="icon" />
                  <br />
                  .png / .jpg / .jpeg / .mov / .mp4 /.zip
                </p>
                <div>
                  <div class="warn-right">
                    <p style="margin: 4px 0">
                      <v-icon icon="$google" size="16" />
                      <span
                        v-if="company.canUseGbpConsole"
                        style="padding-left: 2px"
                        class="warning-text"
                      >
                        jpg/png(共に25MB以下), mov/mp4(75MB以下)
                      </span>
                      <span v-else style="padding-left: 2px" class="warning-text">
                        jpg/png(共に25MB以下)
                      </span>
                    </p>
                    <p style="margin: 4px 0">
                      <v-icon icon="$yahoo" size="20" />
                      <span style="padding-left: 2px" class="warning-text">
                        jpg/png(共に10MB以下)
                      </span>
                    </p>
                    <p style="margin: 4px 0">
                      <v-icon icon="$instagram" size="16" />
                      <span style="padding-left: 7px" class="warning-text">
                        jpg (8.3MB以下), mov/mp4(100MB以下)
                      </span>
                    </p>
                    <p style="margin: 4px 0">
                      <v-icon icon="$facebook" size="16" />
                      <span style="padding-left: 7px" class="warning-text">
                        jpg (3MB以下), png (1MB以下), mov/mp4(1GB以下)
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </o-upload>
      </o-field>
    </div>
    <div style="margin-right: auto; margin-top: auto">
      <v-btn
        v-if="!showFileNote"
        color="#CFD8DC"
        style="margin-top: 5px"
        prepend-icon="mdi-information"
        @click="showFileNote = true"
      >
        <span style="padding-top: 2px">アップロードできる画像・動画について</span>
      </v-btn>
      <v-alert
        v-model="showFileNote"
        :border="false"
        colored-border
        color="#CFD8DC"
        closable
        style="margin-top: 5px"
        max-width="800px"
      >
        <v-data-table
          :headers="fileNoteHeaders"
          :items="fileNoteItems(company.canUseGbpConsole)"
          hide-default-footer
          calculate-widths
          :density="isMobile ? 'compact' : 'default'"
          class="elevation-1 alert-table"
          :items-per-page="-1"
        >
          <template #bottom />
        </v-data-table>
      </v-alert>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, toNative } from "vue-facing-decorator";
import { useSnackbar } from "@/storepinia/snackbar";
import wordDictionary from "@/word-dictionary";
import type { PlatformName, FileSelectionItem, FileSelectionMap } from "@/models/v2-file-selection";
import {
  platformList,
  getExtension,
  getFileNameWithExtension,
  getMimeType,
  getObjectURL,
  getVideoElement,
  isImage,
  isVideo,
} from "@/models/v2-file-selection";
import * as zipjs from "@zip.js/zip.js/dist/zip-full";
import type { FileValidationResult, HasSelectedVideo } from "./validator";
import { checkMaxFiles, validateExtension, validateFileSize, validateVideo } from "./validator";
import { TOAST_CRITICAL_DURATION } from "@/const";
import { getter } from "@/storepinia/idxdb";

@Component({
  components: {},
  emits: ["update:modelValue", "set-loading", "checkSelectedVideo"],
})
export class FileUploader extends Vue {
  @Prop({ required: true }) modelValue!: FileSelectionMap;
  @Prop({ required: true }) loading!: boolean;
  @Prop({ type: Boolean }) gbpAspectNoCheck: boolean;
  @Prop({ type: Object }) hasSelectedVideo: HasSelectedVideo;
  @Prop({ type: String, required: true }) selectedPlatform: PlatformName;
  @Prop({ required: true }) isMobile!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  acceptFileTypes = ".jpeg,.jpg,.mov,.mp4,.png,.zip";
  imageFiles: File[] = [];
  tabIndex: number = 0;
  showFileNote = false;
  fileNoteHeaders = [
    { title: "", key: "title", sortable: false, width: 100 },
    { title: "Google Business Profile", key: "gmb", sortable: false, width: 120 },
    { title: "Yahoo!プレイス", key: "yahoo", sortable: false, width: 120 },
    { title: "Instagram", key: "ig", sortable: false, width: 110 },
    { title: "Facebook", key: "fb", sortable: false, width: 100 },
  ];
  fileNoteItems = (
    canUseGbpConsole: boolean
  ): {
    title: string;
    gmb: string | number;
    yahoo: string | number;
    ig: string | number;
    fb: string | number;
  }[] => [
    { title: "画像枚数", gmb: canUseGbpConsole ? 10 : 1, yahoo: 1, ig: 1, fb: 10 },
    { title: "動画本数", gmb: canUseGbpConsole ? 1 : 0, yahoo: 0, ig: 1, fb: 1 },
    {
      title: "画像・動画同時",
      gmb: canUseGbpConsole ? "可" : "-",
      yahoo: "不可",
      ig: "不可",
      fb: "不可",
    },
    { title: "画像拡張子", gmb: "jpg, png", yahoo: "jpg, png", ig: "jpg", fb: "jpg, png" },
    {
      title: "動画拡張子",
      gmb: canUseGbpConsole ? "mov, mp4" : "-",
      yahoo: "-",
      ig: "mov, mp4",
      fb: "mov, mp4",
    },
    {
      title: "画像サイズ",
      gmb: "400x300px (10KB以上) 〜 10000x10000px (25MB以内)",
      yahoo: "横 720px 以上、縦 720px 以上 (10MB以下)",
      ig: "幅: 320px 〜 1440px (8.3MB以内)",
      fb: "jpg (3MB以内), png (1MB以内)",
    },
    {
      title: "画像比率",
      gmb: canUseGbpConsole ? "4:3, 4:9, 2:1 (1枚), 1:1 (複数枚)" : "4:3, 4:9, 2:1",
      yahoo: "自由",
      ig: "1:1, 4:3, 4:5, 16:9, 1.91:1",
      fb: "自由",
    },
    {
      title: "動画サイズ",
      gmb: canUseGbpConsole ? "75MB以内" : "-",
      yahoo: "-",
      ig: "100MB以内",
      fb: "1KB〜1GB",
    },
    {
      title: "動画解像度",
      gmb: canUseGbpConsole ? "縦:720px以上" : "-",
      yahoo: "-",
      ig: "横:1920px以内",
      fb: "自由",
    },
    {
      title: "再生時間",
      gmb: canUseGbpConsole ? "30秒以内" : "-",
      yahoo: "-",
      ig: "3秒〜60秒",
      fb: "1秒〜20分",
    },
    {
      title: "動画比率",
      gmb: canUseGbpConsole ? "自由" : "-",
      yahoo: "-",
      ig: "4:5 〜 16:9",
      fb: "9:16, 16:9",
    },
  ];
  addSnackbarMessages = useSnackbar().addSnackbarMessages;
  company = getter().company;

  @Watch("imageFiles", { deep: true })
  async fileSelection(): Promise<void> {
    try {
      if (this.imageFiles.length === 0) {
        return;
      }
      this.$emit("set-loading", true);
      for (var imageFile of this.imageFiles) {
        const fileName = imageFile.name;
        var ext = getExtension(fileName);
        if (!this.isAcceptableExt(ext)) {
          continue;
        }

        if (ext === "zip") {
          const entries = await this.getEntries(imageFile);
          for (var entry of entries) {
            if (entry.directory) continue;
            if (entry.filename.indexOf("__MACOSX") > -1) {
              continue;
            }
            ext = getExtension(entry.filename);
            if (!this.isAcceptableExt(ext)) {
              continue;
            }
            const mimeType = getMimeType(ext);
            const blobData = await entry.getData(new zipjs.BlobWriter(), {});
            const file = new File([blobData], entry.filename, {
              lastModified: entry.lastModDate.getTime(),
              type: mimeType,
            });
            await this.checkLimitations(file, ext);
          }
        } else if (ext.length > 0) {
          await this.checkLimitations(imageFile, ext);
        }
      }
      this.imageFiles = [];
    } finally {
      this.$emit("set-loading", false);
    }
  }

  private async getEntries(file: File) {
    const blobReader = new zipjs.BlobReader(file);
    const zipReader = new zipjs.ZipReader(blobReader, { filenameEncoding: "utf-8" });
    return zipReader.getEntries();
  }

  private isAcceptableExt(ext: string): boolean {
    const acceptableExts = this.acceptFileTypes.split(",");
    for (var acceptableExt of acceptableExts) {
      acceptableExt = acceptableExt.replace(".", "");
      if (ext === acceptableExt) {
        return true;
      }
    }
    return false;
  }

  async checkLimitations(imageFile: File, ext: string): Promise<void> {
    const [imageURL, videoURL] = await getObjectURL(imageFile, ext);
    let videoElem: HTMLVideoElement;
    if (isVideo(imageFile)) {
      if (videoURL === "") {
        // getObjectURL()時点でエラーハンドリングしているので何もしない
        return;
      }
      await getVideoElement(videoURL)
        .then((res) => {
          videoElem = res;
        })
        .catch((ex) => {
          // Chrome非対応により動画ファイルのデータを取得できないケースがあるが、GBP側が変換してくれるため続行する
        });
      this.validateFiles(imageFile, ext, videoElem, imageURL, videoURL);
    } else if (isImage(imageFile)) {
      const imageCheck = new Image();
      imageCheck.onerror = () => {
        console.error("[checkLimitations] Broken file.");
        this.addSnackbarMessages({
          text: "画像ファイルの読み込みに失敗しました。ファイルが破損していないかご確認ください。",
          color: "danger",
          timeout: TOAST_CRITICAL_DURATION,
        });
      };
      imageCheck.onload = () => {
        this.validateFiles(imageFile, ext, videoElem, imageURL, videoURL);
      };
      imageCheck.src = imageURL;
    }
  }

  private validateFiles(
    imageFile: File,
    ext: string,
    videoElem: HTMLVideoElement,
    imageURL: string,
    videoURL: string
  ): void {
    // 予め動画選択状況を調べておく
    this.$emit("checkSelectedVideo");

    for (const platform of platformList) {
      // GBPコンソールが使えない場合は、Googleへ動画投稿できない
      if (platform === "google" && !this.company.canUseGbpConsole && isVideo(imageFile)) {
        const state: FileValidationResult = {
          state: "rejected",
          message: wordDictionary.v2post.validator.rejectMovie,
        };
        this.updateFileSelectionMap(platform, imageFile, state, imageURL, videoURL);
        continue;
      }
      let state = validateExtension(ext, platform);
      if (isVideo(imageFile) === false) {
        const gbp10KBCheck = validateFileSize(platform, imageFile);
        if (gbp10KBCheck.state === "rejected") {
          // アップロードされたファイルが10KB未満の場合GBP選べませんよトーストを出す
          const toastLabel = platform === "google" ? "Google Business Profile" : platform;
          this.addSnackbarMessages({
            text: `${toastLabel}では${gbp10KBCheck.toastMessage}`,
            color: "warning",
            timeout: TOAST_CRITICAL_DURATION,
          });
          state = gbp10KBCheck;
        }
      }

      const items = this.modelValue.get(platform);
      state = checkMaxFiles(this.company.canUseGbpConsole, items, ext, platform, state);
      const aspectNoCheck = platform === "google" ? this.gbpAspectNoCheck : false;
      if (isVideo(imageFile) && videoElem) {
        state = validateVideo(
          videoElem,
          platform,
          imageFile,
          state,
          aspectNoCheck,
          this.hasSelectedVideo
        );
      }
      this.updateFileSelectionMap(platform, imageFile, state, imageURL, videoURL);
    }
    this.$emit("update:modelValue", this.modelValue);
  }

  updateFileSelectionMap(
    platform: PlatformName,
    imageFile: File,
    imageState: FileValidationResult,
    imageURL: string,
    videoURL: string
  ): void {
    const imgFileObj = getFileNameWithExtension(imageFile.name);
    const newItem: FileSelectionItem = {
      file: imageFile,
      imageUrl: imageURL,
      videoUrl: videoURL,
      s3FileName: "",
      state: imageState.state,
      rejectMessage: imageState.message,
      ancestor: imgFileObj,
      revision: { google: 0, yahoo: 0, instagram: 0, facebook: 0 },
    };

    var items: FileSelectionItem[] = this.modelValue.get(platform);

    // まだ syncSelectionMap 内に存在しないファイルであれば追加を、すでに存在している場合は更新を行う
    for (const [idx, item] of Object.entries(items)) {
      if (item.file.name.indexOf(imageFile.name) > -1) {
        items[idx] = newItem;
        return;
      }
    }
    items.push(newItem);
  }
}
export default toNative(FileUploader);
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

.upload {
  background: transparent linear-gradient(180deg, #fff 0%, #f8f8f8 100%) 0% 0% no-repeat padding-box;
}

.col-left {
  display: grid;
  float: left;
  font-size: 0.8rem;
  height: 100%;
  padding: 10px;
  place-items: center;
  white-space: nowrap;
  margin: auto;
}

.mobile-upload {
  padding: 20px 0;
  place-items: center;
  font-size: 0.9rem;
}

.col-right {
  color: color.$base-font-color;
  float: right;
  font-size: 0.8rem;
  padding: 10px;
  white-space: nowrap;
}

.mobile-right {
  @extend .col-right;
  padding-left: unset;
  padding-right: unset !important;
}

.icon {
  color: color.$base-font-color;
}

.warn-right {
  color: color.$main-orange;
  float: right;
  font-size: 0.8rem;
  margin-bottom: 0;
  padding: 10px;
  padding-left: 5px;
  white-space: nowrap;
}

.warning-text {
  white-space: normal;
}

.no-margin-bottom {
  margin-bottom: 0;
}

:deep(.v-data-table td),
th {
  vertical-align: middle;
}

.alert-table {
  font-size: 0.8rem;
}
</style>
