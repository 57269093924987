import type { ErrorDialogMessage } from "@/components/shared/review-shared";
import { defineStore } from "pinia";

export type DialogContent = {
    title?: string;
    message: string;
    type?: "primary" | "warning" | "error";
    hasIcon?: boolean;
    icon?: string;
    cancelText?: string;
    confirmText?: string;
    onConfirm: () => void;
};
type DialogContentInternal = Required<DialogContent> & {
    id: number;
};
export const useDialog = defineStore("dialog", {
    state: () => ({
        count: 0,
        dialogs: [] as DialogContentInternal[],
        sentryDialogMessage: null as ErrorDialogMessage,
    }),
    getters: {},
    actions: {
        confirm(confirmDialog: DialogContent): void {
            const id = this.count++;
            const type = confirmDialog.type ?? "primary";
            const hasIcon = confirmDialog.hasIcon ?? false;
            const icon = hasIcon ? confirmDialog.icon ?? getIcon(confirmDialog.type) : "";
            const dialog: DialogContentInternal = {
                id,
                title: confirmDialog.title ?? "",
                message: confirmDialog.message,
                type,
                hasIcon,
                icon,
                cancelText: confirmDialog.cancelText ?? "Cancel",
                confirmText: confirmDialog.confirmText ?? "OK",
                onConfirm: confirmDialog.onConfirm,
            };
            this.dialogs.push(dialog);
        },
        remove(id: number): void {
            for (;;) {
                const rmid = this.dialogs.findIndex((dc) => dc.id === id);
                if (rmid < 0) {
                    break;
                }
                this.dialogs.splice(rmid, 1);
            }
        },
        showSentry(message?: ErrorDialogMessage): void {
            if (!message) {
                const defaultErrorMessage: ErrorDialogMessage = {
                    title: `システムに問題が発生したため、
                              <br />
                              処理を中止しました。`,
                    detail: `ブラウザを更新するボタンを押して再度お試しください。
                              <br />
                              問題が解決しない場合にはカスタマーサクセス担当者に
                              <br />
                              お問い合わせください。`,
                    buttonType: "reload",
                };
                this.sentryDialogMessage = defaultErrorMessage;
            } else {
                this.sentryDialogMessage = message;
            }
        },
        hideSentry() {
            this.sentryDialogMessage = null;
        },
    },
});
function getIcon(type: "primary" | "warning" | "error"): string {
    if (type === "primary") {
        return "";
    } else if (type === "warning") {
        return "mdi-alert";
    } else {
        return "mdi-alert-circle";
    }
}
