<template>
  <!-- 画像カルーセル -->
  <v-row v-if="files.length > 0">
    <v-col :class="files.length === 1 ? 'col-single' : 'col-multi'">
      <v-hover v-slot="{ isHovering, props }">
        <v-carousel
          v-model="carouselCounter"
          v-bind="props"
          hide-delimiters
          height="auto"
          :show-arrows-on-hover="files.length !== 1"
          :show-arrows="files.length !== 1"
          class="carousel"
        >
          <span class="carousel-counter">{{ carouselCounter + 1 }}/{{ files.length }}</span>
          <span
            v-show="isHovering && !files[carouselCounter].videoUrl && !isMobile && !readonly"
            class="edit-span"
          >
            <v-btn
              prepend-icon="mdi-settings"
              class="edit-button"
              flat
              rounded
              :readonly="readonly"
              @click="clickEdit(files[carouselCounter].state)"
            >
              編集
            </v-btn>
          </span>
          <v-carousel-item v-for="(file, i) in files" :key="i">
            <div :class="files.length === 1 ? 'img-box-single' : 'img-box-multi'">
              <video
                v-if="file.videoUrl"
                :src="file.videoUrl"
                :poster="file.imageUrl"
                :class="files.length === 1 ? 'img-single' : 'img-multi'"
                controls
              />
              <v-img
                v-else
                :src="file.imageUrl"
                :contain="true"
                :class="files.length === 1 ? 'img-single' : 'img-multi'"
              />
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { FileSelectionItem, FileSelectionState } from "@/models/v2-file-selection";

export default defineComponent({
  props: {
    // 画像ファイルのObject Urlの配列
    files: { type: Array as () => FileSelectionItem[], required: true },
    isMobile: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  emits: ["edit-image"],
  data() {
    return {
      carouselCounter: 0,
      hover: false,
    };
  },
  methods: {
    clickEdit(order: FileSelectionState): void {
      // console.log("gmb-carousel clickEdit", order);
      // EditImageSelectedを作ってposts.vueのイベントをgmb-previewを経由して発火させる
      this.$emit("edit-image", {
        platform: "google",
        order: order as number,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.col-single {
  padding: 0;
}

.col-multi {
  padding: 0;
}

.img-box-single {
  background-color: #fff;
  width: 308px;
  text-align: center;
  margin: 0 !important;
}

.img-single {
  background-color: #fff;
  width: 308px;
  object-fit: fill;
}

.img-box-multi {
  width: 308px;
  text-align: center;
  margin: 0 !important;
}

.img-multi {
  background-color: #fff;
  width: 308px;
  object-fit: scale-down;
}

.edit-span {
  position: absolute;
  top: 9px;
  width: 100%;
  text-align: center;
  z-index: var(--z-index-loading);
}

.edit-button {
  background-color: rgba(0, 0, 0, 0.54) !important;
  color: white !important;
  border: none;
}

.carousel-counter {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.54);
  border-radius: 15px;
  color: #fff;
  font-weight: bold;
  min-width: 30px;
  padding: 4px 8px;
  left: 245px;
  top: 10px;
  text-align: center;
  z-index: var(--z-index-loading);
}

.carousel {
  margin: 0;
}

.show-btn {
  color: rgba(255, 255, 255, 1) !important;
}

:deep(.v-responsive) {
  overflow: visible;
}
</style>
