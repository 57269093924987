<template>
  <v-row class="gmb-header" align="center">
    <v-col cols="auto">
      <div class="profile-icon-circle" />
    </v-col>
    <v-col class="d-flex flex-column align-start">
      <div class="profile-name">
        {{ targetName }}
      </div>
      <div class="profile-date">たった今</div>
    </v-col>
    <v-spacer />
    <v-col cols="auto">
      <v-icon size="15" color="#757575">fas fa-ellipsis-v</v-icon>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    targetName: { type: String, required: true },
  },
});
</script>

<style lang="scss" scoped>
.row {
  margin-top: 0;
  margin-bottom: 0;
}
.gmb-header {
  width: 308px;
  text-align: center;
}
.profile-icon-circle {
  width: 40px;
  height: 40px;
  margin: 4px;
  background: #a7a7a7 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  border-radius: 50%;
}
.profile-name {
  // プレビューなのでプレビュー先のフォント指定を模している
  font-family: Roboto, HelveticaNeue, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.profile-date {
  // プレビューなのでプレビュー先のフォント指定を模している
  font-family: Roboto, HelveticaNeue, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
}
</style>
