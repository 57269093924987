<template>
  <v-dialog v-model="isShow" hide-overlay persistent width="400">
    <v-card>
      <!-- 見た目のためにtitleを入れている-->
      <v-card-title />
      <v-card-text class="text--primary">
        {{ textMessage }}
      </v-card-text>
      <v-progress-linear indeterminate color="primary" height="15" />
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
  },
  computed: {
    isShow(): boolean {
      return this.show;
    },
    textMessage(): string {
      return this.message;
    },
  },
});
</script>
<style lang="scss" scoped></style>
