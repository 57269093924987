<template>
  <!-- 返信テンプレートの選択 -->
  <h3>返信例文一覧</h3>
  <v-tabs v-model="selectedTab" grow class="feeling-tabs" color="primary" slider-color="primary">
    <v-tab>ポジティブ</v-tab>
    <v-tab>ネガティブ</v-tab>
  </v-tabs>
  <!-- テンプレート下書き -->
  <div v-if="selectedTab < 2">
    <h4>テンプレート選択</h4>
    <div class="inner">
      <v-select
        v-model="selectedLabel"
        :items="templateLabels"
        outlined
        dense
        hide-details
      ></v-select>
      <v-textarea
        v-if="selectedTab !== 2"
        v-model="selectedTemplate"
        outlined
        readonly
        rows="10"
        color="primary"
      />
      <div class="d-flex justify-center">
        <v-btn class="button is-primary" style="width: 200px" small @click="copyTemplate">
          引用する
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { replyTemplates } from "./reply-templates";

import type { PropType } from "vue";
import type { ReplyTemplate } from "./reply-templates";

export default defineComponent({
  components: {},
  props: {
    reviews: { type: Array as PropType<Array<string>>, default: () => [] as string[] },
  },
  emits: ["copyTemplate"],
  data: () => {
    return {
      name: "reply",
      selectedTab: 0,
      chosenFeeling: [] as ReplyTemplate[],
      positiveTemplates: [] as ReplyTemplate[],
      negativeTemplates: [] as ReplyTemplate[],
      templateLabels: [] as string[],
      selectedLabel: "",
      selectedTemplate: "",
      loading: false,
    };
  },
  computed: {
    repliedReviews(): string[] {
      return this.reviews?.filter((r: string) => r?.length > 0);
    },
    replyReviewsExists(): boolean {
      return this.repliedReviews?.length > 0;
    },
  },
  watch: {
    selectedTab: {
      deep: false,
      immediate: false,
      handler: async function (feeling: number) {
        this.onFeelingSelect(feeling);
      },
    },
    selectedLabel: {
      deep: false,
      immediate: false,
      handler: async function () {
        this.onTemplateSelect();
      },
    },
  },
  created(): void {},
  mounted(): void {
    this.positiveTemplates = replyTemplates.filter((item) => item.feeling === "positive");
    this.negativeTemplates = replyTemplates.filter((item) => item.feeling === "negative");
    this.onFeelingSelect(0);
  },
  methods: {
    onFeelingSelect(feeling: number): void {
      // 「ポジティブ」タブをクリックした場合feeling = 0が返ってくる
      this.chosenFeeling = feeling === 0 ? this.positiveTemplates : this.negativeTemplates;
      // 選択したfeelingに基づいてテンプレートセレクタを生成する
      this.templateLabels = this.chosenFeeling.map((item) => {
        return item.name;
      });
      // feeling切り替えた直後は先頭のテンプレートを選択
      this.selectedLabel = this.templateLabels[0];
      this.onTemplateSelect();
    },
    onTemplateSelect(): void {
      // 選択したテンプレートをプレビュー欄に表示する
      this.selectedTemplate = this.chosenFeeling.find(
        (item) => item.name === this.selectedLabel
      ).text;
    },
    copyTemplate(): void {
      // 返信内容の入力欄に選択したテンプレートを転写
      this.$emit("copyTemplate", this.selectedTemplate);
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

$gray: #dfdfdf;

h3 {
  text-align: left;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.75rem;
}

h4 {
  text-align: left;
  font-size: 0.8rem;
  padding: 0.5rem;
  color: #aaa;
  + .inner {
    margin: 0 0.25rem;
  }
}

.feeling-tabs {
  .v-tabs-slider-wrapper {
    height: 0 !important;
  }

  :deep(.v-slide-group__content) {
    position: relative;
    margin: 0 5px 0;
    &::before,
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      display: block;
      width: 5px;
      height: 1px;
      border-bottom: 1px solid color.$primary;
    }
    &::before {
      left: -5px;
    }
    &::after {
      right: -5px;
    }
  }

  .v-tab {
    font-size: 0.8rem !important;
    font-weight: 600;
    height: 3rem;
    width: auto;
    padding: 2px 0.5rem 0 0 !important;
    border-width: 1px 1px 0 1px;
    border-radius: 3px 3px 0 0;
    border-style: solid;
    border-color: $gray;
    border-bottom: 1px solid color.$primary;
    &.v-tab--selected {
      border-color: color.$primary;
      border-bottom: 1px solid white;
    }
  }

  .v-tab--active {
    border-width: 3px 1px 0 1px;
    border-color: color.$primary;
  }
  :deep(.v-tab__slider) {
    top: 0;
  }
}

.v-select {
  margin-bottom: 10px;
  font-weight: 600;
  .v-input__slot {
    padding: 0 0 0 5px !important;
  }
}

textarea {
  display: block;
  box-sizing: border-box;
  padding: 14px 16px;
  width: 100%;
  min-height: 17em;
  height: 100%;
  max-height: calc(100% - 200px);
  border: 1px solid $gray;
  border-radius: 3px;
  &:focus {
    border-color: color.$primary;
    outline: none;
  }
}
</style>
