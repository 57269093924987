/**
 * GBPのリソース名から location/456 の部分を取り出す
 *
 * @param s accounts/123/locations/456/attributes などの location/456 の部分を含む文字列
 * @returns location/456 の部分。マッチしなければ空文字列を返す
 */
export function trimLocationName(s: string): string {
    const m = s?.match(/locations\/[^/:]+/) ?? null;
    return m === null ? "" : m[0];
}

/**
 * GBPのリソース名からダッシュボードURLを生成する
 *
 * @param locationName accounts/123/locations/456/attributes などの location/456 の部分を含む文字列
 * @returns https://business.google.com/n/${locationID}/profile?hl=ja マッチしなければ空文字列を返す
 */
export function getDashboardUrl(locationName: string): string {
    const locationId = trimLocationName(locationName);
    return locationId === ""
        ? ""
        : `https://business.google.com/n/${locationId.split("/")[1]}/profile?hl=ja`;
}
