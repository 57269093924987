<template>
  <v-card :disabled="!step.enabled" color="transparent" elevation="0">
    <!-- 投稿プレビュー -->
    <v-form ref="form" v-model="isValidateForm" style="margin-top: 12px">
      <v-card class="preview-row mb-2 pb-3" justify="center">
        <ig-header :target-name="targetName" />

        <v-row v-if="files.length > 0">
          <v-col class="image-col">
            <v-hover v-slot="{ isHovering, props }">
              <div v-if="files.length > 0" v-bind="props" class="image-box">
                <span
                  v-show="isHovering && !files[0].videoUrl && !isMobile && !readonly"
                  class="edit-span"
                >
                  <v-btn class="edit-button" flat rounded @click="editImage(files[0].state)">
                    <v-icon color="white" left>fas fa-cog</v-icon>
                    編集
                  </v-btn>
                </span>
                <video
                  v-if="files[0].videoUrl"
                  :src="files[0].videoUrl"
                  :poster="files[0].imageUrl"
                  controls
                  class="image"
                />
                <v-img v-else :src="files[0].imageUrl" class="image" />
              </div>
            </v-hover>
          </v-col>
        </v-row>
        <v-row v-if="fileErrorMessages != ''">
          <v-col cols="12">
            <p class="error-message">{{ fileErrorMessages }}</p>
          </v-col>
        </v-row>
        <v-row class="text-medium-emphasis">
          <v-col cols="auto" class="ml-2">
            <v-icon icon="far fa-heart" />
          </v-col>
          <v-col cols="auto">
            <v-icon icon="far fa-comment" />
          </v-col>
          <v-col cols="auto">
            <v-icon icon="far fa-paper-plane" />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" style="padding: 10px 20px">
            <v-textarea
              v-model="text"
              :rules="[rules.maxLength(2200), rules.maxHash(30)]"
              hint="ハッシュタグは30個まで追加可能です"
              rows="1"
              auto-grow
              variant="underlined"
              hide-details="auto"
              persistent-counter
              class="detail-text"
              color="primary"
              counter="2200"
              :readonly="readonly"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import type { StepStatus } from "../post-stepper.vue";
import IgHeader from "./ig-header.vue";
import type { FileSelectionItem, FileSelectionState } from "@/models/v2-file-selection";
import { platformOfficialNames, sortFileSelectionItemList } from "@/models/v2-file-selection";
import { VuetifyValidator } from "@/helpers";
import { validateFile } from "../validator";
import { defineComponent } from "vue";

export default defineComponent({
  components: { IgHeader },
  props: {
    modelValue: { type: String, required: true }, // v-model
    baseText: { type: String, required: true },
    targetName: { type: String, required: true },
    fileSelections: { type: Array as () => FileSelectionItem[], required: true },
    step: { type: Object as () => StepStatus, required: true },
    aspectNoCheck: { type: Boolean, required: true },
    isMobile: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  emits: ["update:modelValue", "update-step", "edit-image"],
  data() {
    return {
      isValidateForm: false,
      fileErrorMessages: "",
      rules: VuetifyValidator,
      text: "",
      files: [] as FileSelectionItem[],
    };
  },
  watch: {
    isValidateForm: {
      handler(): void {
        this.isCompleted();
      },
    },
    baseText: {
      immediate: true,
      handler(): void {
        // 共通テキストが変更されたら変更を上書き
        this.text = this.baseText;
      },
    },
    text: {
      handler(): void {
        this.$emit("update:modelValue", this.text);
        this.validateFiles();
      },
    },
    fileSelections: {
      deep: true,
      immediate: true,
      async handler(): Promise<void> {
        // 選択済みのファイルを順番通りにソートした上でfilesに格納する
        this.files = sortFileSelectionItemList(this.fileSelections);
        // 画像選択されたらプレビューを有効化する
        if (this.files.length > 0 && !this.$props.step.enabled) {
          this.$props.step.enabled = true;
        }
        await this.validateFiles();
      },
    },
    step: {
      deep: true,
      immediate: true,
      async handler() {
        await this.validateFiles();
      },
    },
  },
  methods: {
    isCompleted(): void {
      this.$emit("update-step", {
        isComplete: this.isValidateForm && this.fileErrorMessages == "",
        errorMessage: `${platformOfficialNames.instagram}への投稿では${this.fileErrorMessages}`,
      });
    },
    async validateFiles(): Promise<void> {
      // vue 上でpropが変更されるのを待つ
      await this.$nextTick();
      if (!this.$props.step.enabled) {
        this.fileErrorMessages = "";
        return;
      }
      if (this.files.length == 0) {
        this.fileErrorMessages = "JPEG画像または動画のアップロードが必須です";
        this.$emit("update-step", {
          errorMessage: `${platformOfficialNames.instagram}への投稿では${this.fileErrorMessages}`,
        });
        this.isCompleted();
        return;
      }
      var errorMessages: string[] = [];
      await validateFile(this.files[0], 1, "instagram", errorMessages, this.aspectNoCheck);
      // 冗長なのでpreviewに表示するエラー文は短くする
      this.fileErrorMessages = errorMessages
        .map((m) => m.replace(platformOfficialNames.instagram, ""))
        .join("\n");
      this.$emit("update-step", { errorMessage: errorMessages.join("\n") });
      this.isCompleted();
    },
    editImage(order: FileSelectionState): void {
      // EditImageSelectedを作ってposts.vueのイベントを親コンポーネントを経由して発火させる
      this.$emit("edit-image", { platform: "instagram", order: order });
    },
  },
});
</script>

<style lang="scss" scoped>
.post-preview {
  max-width: 340px; // 実物大
  padding: 0;
}

.row {
  margin-top: 0 !important;
  margin-bottom: 0;
}

.preview-row {
  margin: 0;
  padding: 0 16px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 2px 3px #00000029;
  border-radius: 6px;
  opacity: 1;
}

.full-row {
  width: 308px;
}

.title-text {
  width: 308px;
  padding: 10px 12px 0 12px;
  font-size: 18px;
  line-height: 22px;
  overflow: hidden;
}

.detail-text {
  padding-top: 0;
  border: #707070;
}

.label-col {
  padding-right: 0;
}

.title-row {
  padding: 5px 12px 0 12px;
}

.input-row {
  padding: 0 12px;
}

.input-col {
  padding: 0;
}

.button-col {
  padding: 0 4px 4px 0;
}

:deep(.v-text-field input) {
  line-height: 21px;
}

.image-col {
  padding: 0;
}

.image-box {
  width: 308px;
  margin: 0 !important;
  position: relative;
}

.image {
  background-color: #000;
  width: 308px;
  object-fit: scale-down;
}

.edit-span {
  position: absolute;
  top: 9px;
  width: 100%;
  text-align: center;
  z-index: var(--z-index-loading);
}

.edit-button {
  background-color: rgba(0, 0, 0, 0.54) !important;
  color: white !important;
  border: none;
}

.error-message {
  color: red;
  font-size: 0.75rem;
  white-space: pre-wrap;
}
</style>
