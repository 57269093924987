import type { AxiosError } from "axios";
import { requiredAuth } from "@/helpers";
import { Component, Vue, toNative } from "vue-facing-decorator";
import type { EntitiesArea, EntitiesAreasResponse } from "@/types/ls-api";
import wordDictionary from "@/word-dictionary";
import { sentimentLevels } from "@/const";
import { useSnackbar } from "@/storepinia/snackbar";
import { action } from "@/storepinia/idxdb";
import type { FormContext } from "vee-validate";

@Component({})
class Form extends Vue {
    declare $refs: { observer: FormContext };
    setAreas = action().setAreas;
    setAreaStores = action().setAreaStores;
    addSnackbarMessages = useSnackbar().addSnackbarMessages;
    area: Partial<EntitiesArea> = {
        name: "",
        alertKeywords: [],
        alertSentimentRanges: [],
        alertStarRatings: [],
    };
    poiGroupId = NaN;

    isNew: boolean = true;
    isLoading: boolean = false;
    dict = wordDictionary.area_form;

    starRatings = [1, 2, 3, 4, 5];

    sentimentLevels = [...sentimentLevels];

    async mounted(): Promise<void> {
        this.isLoading = true;

        try {
            this.poiGroupId = parseInt(this.$route.params.poiGroupId as string, 10);
            const areaId = parseInt(this.$route.params.areaId as string, 10);
            this.isNew = isNaN(areaId);

            if (this.isNew) {
                return;
            }

            const response = await requiredAuth<EntitiesAreasResponse>(
                "get",
                `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/areas`
            );

            if (response == null || response.data == null || response.data.areas == null) {
                this.area = null;
            } else {
                const area = response.data.areas.find((area) => area.areaID === areaId);
                if (area) {
                    this.area = {
                        ...area,
                        alertKeywords: area.alertKeywords ?? [],
                        alertSentimentRanges: area.alertSentimentRanges ?? [],
                        alertStarRatings: area.alertStarRatings ?? [],
                    };
                }
            }
        } catch (e) {
            console.error(e);
            throw Error("グループ情報の検索に失敗しました。");
        } finally {
            this.isLoading = false;
        }
    }

    async submit(): Promise<void> {
        const result = await this.$refs.observer.validate();
        if (!result.valid) {
            this.addSnackbarMessages({
                text: "入力に誤りがあります。内容を確認してください。",
                color: "danger",
                options: { top: false },
            });
            return;
        }

        this.isLoading = true;
        this.upsert();
    }

    async upsert(): Promise<void> {
        try {
            const param = { ...this.area };

            const response = await requiredAuth<EntitiesArea>(
                this.isNew ? "post" : "put",
                this.isNew
                    ? `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/areas`
                    : `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/areas/${
                          this.area.areaID
                      }`,
                null,
                param
            );

            if (response == null || response.data == null) {
                // 権限不足
            } else {
                this.setAreas(this.poiGroupId);
                this.setAreaStores(this.poiGroupId);
                const message = this.isNew ? "グループを登録しました" : "グループを更新しました";
                this.addSnackbarMessages({
                    text: message,
                    color: "success",
                    options: { top: true },
                });
            }
            this.$router.push({ name: "Areas" });
        } catch (e) {
            const axiosError = e as AxiosError;
            this.showErrorMessage(axiosError);
        } finally {
            this.isLoading = false;
        }
    }

    showErrorMessage(e: AxiosError): Promise<void> {
        if (e.response?.status === 429) {
            this.addSnackbarMessages({
                text: "作成可能グループ数の上限を超えました。",
                color: "danger",
                options: { top: true },
            });
            return;
        } else if (e.response?.status === 409) {
            const errMsg = e.response.data.errorMessage;
            this.addSnackbarMessages({
                text: errMsg,
                color: "danger",
                options: { top: true },
            });
            return;
        }
        console.error(e);
        throw Error(this.isNew ? this.dict.error_register : this.dict.error_update);
    }

    goBack(): void {
        this.$router.push({ name: "Areas" });
    }
}
export default toNative(Form);
