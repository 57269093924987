import wordDictionary from "../word-dictionary";

const mediaCategoryNames = wordDictionary.storeLocationForm.mediaCategories;

export const mediaCategories = [
    {
        // ロゴ
        ...mediaCategoryNames[0],
        accepts: ".png,.jpg,.jpeg",
        friendlyAccepts: "PNG/JPG",
        messages: ["横250x縦250以上で10KB〜5MBの画像ファイル"],
        canMultiple: false,
    },
    {
        // カバー
        ...mediaCategoryNames[1],
        accepts: ".png,.jpg,.jpeg",
        friendlyAccepts: "PNG/JPG",
        messages: [
            "横250x縦250以上で10KB〜5MBの画像ファイル",
            "画像比率が横16:縦9のものを使用してください",
        ],
        canMultiple: false,
    },
    {
        // 外観
        ...mediaCategoryNames[2],
        accepts: ".png,.jpg,.jpeg,.mov,.mp4",
        friendlyAccepts: "PNG/JPG/MOV/MP4",
        messages: [
            "画像ファイルサイズは横250x縦250以上で10KB〜5MB",
            "動画ファイルは最大30秒、最大75MB、解像度720p以上",
        ],
        canMultiple: true,
    },
    {
        // 店内
        ...mediaCategoryNames[3],
        accepts: ".png,.jpg,.jpeg,.mov,.mp4",
        friendlyAccepts: "PNG/JPG/MOV/MP4",
        messages: [
            "画像ファイルサイズは横250x縦250以上で10KB〜5MB",
            "動画ファイルは最大30秒、最大75MB、解像度720p以上",
        ],
        canMultiple: true,
    },
    {
        // チーム
        ...mediaCategoryNames[4],
        accepts: ".png,.jpg,.jpeg",
        friendlyAccepts: "PNG/JPG",
        messages: ["横250x縦250以上で10KB〜5MBの画像ファイル"],
        canMultiple: true,
    },
    {
        // 動画
        ...mediaCategoryNames[5],
        accepts: ".mov,.mp4",
        friendlyAccepts: "MOV/MP4",
        messages: ["最大30秒、最大75MB、解像度720p以上"],
        canMultiple: true,
    },
];
