<template>
  <v-dialog
    v-model="openModal"
    persistent
    :width="isApproveRequest ? 800 : 'auto'"
    content-class="confirmation-dialog"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        {{ target }}を{{ processName }}してもよろしいですか？
      </v-card-title>
      <v-card-text>
        <p v-if="warning?.length > 0" class="dialog-warning">
          {{ warning }}
        </p>
        <p class="post-date">投稿日時: {{ date }}</p>
        <p class="post-title">タイトル: {{ title }}</p>
      </v-card-text>

      <template v-if="isApproveRequest && isManualApproveSelect && approveUserUUIDList.length > 0">
        <v-divider></v-divider>
        <v-card-text align="center" class="text--primary" style="padding-top: 0.5rem">
          <p>承認者を手動で選択するか、自動承認を選んでください</p>
        </v-card-text>

        <v-radio-group v-model="approveUserMode">
          <v-radio label="自動承認者選択" value="auto" color="primary" />
          <v-radio label="手動承認者選択" value="manual" color="primary" />
        </v-radio-group>

        <v-card-text v-if="approveUserMode == 'manual'">
          <p>承認者を選択してください</p>
          <v-checkbox
            v-for="item in approveUserUUIDList"
            :key="item.uuid"
            v-model="item.checked"
            :label="item.family_name + ' ' + item.first_name"
            hide-details
            color="primary"
          />
        </v-card-text>
      </template>

      <v-divider />

      <v-card-actions v-if="isApproveRequest">
        <v-text-field v-model="requestRemark" label="備考" clearable color="primary" class="mx-2" />
      </v-card-actions>

      <v-card-actions class="dialog-button-container">
        <v-spacer></v-spacer>
        <v-btn variant="outlined" @click.stop="$emit('close-modal')">キャンセル</v-btn>
        <v-btn
          v-if="isApproveRequest"
          :disabled="!validate"
          variant="flat"
          class="primary post-button"
          @click.prevent="submitRequest()"
        >
          {{ processName }}
        </v-btn>
        <v-btn
          v-else
          color="primary post-button"
          variant="flat"
          @click.prevent="$emit('submit', row, [])"
        >
          {{ processName }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type {
  ControllersApproveUser,
  ControllersApproveUsersOutput,
  EntitiesCompany,
} from "@/types/ls-api";
import { requiredAuth } from "@/helpers/request";
import { useIndexedDb } from "@/storepinia/idxdb";

class ApproveUser {
  constructor(user: ControllersApproveUser) {
    this.uuid = user.uuid;
    this.family_name = user.family_name;
    this.first_name = user.first_name;
    this.checked = false;
  }
  uuid: string;
  family_name: string;
  first_name: string;
  checked: boolean;
}

export default defineComponent({
  props: {
    openConfirm: { type: Boolean, required: true, default: false },
    target: { type: String, required: true },
    processName: { type: String, required: true },
    date: { type: String, required: true },
    title: { type: String, required: true },
    warning: { type: String, required: false, default: "" },
    row: { type: Object, required: true },
    isManualApproveSelect: { type: Boolean, required: false, default: false },
    isApproveRequest: { type: Boolean, required: false, default: false },
    defaultRemark: { type: String, required: false, default: "" },
  },
  emits: ["update:modelValue", "submit", "close-modal"],
  data() {
    return {
      // 承認者の手動設定が有効な場合、承認者一覧をセット
      approveUserUUIDList: [] as ApproveUser[],
      requestRemark: "",
      approveUserMode: "auto",
      isLoading: false,
    };
  },
  computed: {
    openModal: {
      get() {
        return this.openConfirm;
      },
      set(value: boolean) {
        this.$emit("update:modelValue", value);
      },
    },
    company(): EntitiesCompany {
      return useIndexedDb().company;
    },
    validate(): boolean {
      if (this.isApproveRequest && this.approveUserMode === "manual") {
        for (const user of this.approveUserUUIDList) {
          if (user.checked === true) {
            return true;
          }
        }
        return false;
      }
      return true;
    },
  },
  async created(): Promise<void> {
    if (this.isManualApproveSelect) {
      await this.fetch();
    }
  },
  beforeUpdate() {
    this.requestRemark = this.defaultRemark || "";
  },
  methods: {
    async fetch(): Promise<void> {
      // 承認者一覧を取得
      try {
        const res = await requiredAuth<ControllersApproveUsersOutput>(
          "get",
          `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
            this.company.poiGroupID
          }/roles/approve/users`,
          { target: "post" }
        );
        if (res.status === 200) {
          this.approveUserUUIDList = [];
          for (const user of res.data.users) {
            this.approveUserUUIDList.push(new ApproveUser(user));
          }
          if (this.approveUserUUIDList.length > 0) {
            this.approveUserMode = "manual";
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    submitRequest(): void {
      const uuidList = this.approveUserUUIDList
        .filter((user) => user.checked)
        .map((user) => user.uuid);
      this.$emit("submit", this.row, uuidList, this.requestRemark);
    },
  },
});
</script>
<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

.confirmation-dialog {
  .dialog-button-container {
    padding: 20px !important;
  }

  .v-btn {
    box-shadow: none;
  }
}

.confirmation-dialog {
  .post-date {
    margin: 20px 0 0 0;
  }

  .post-title {
    margin: 0;
  }
}

.dialog-warning {
  margin: 20px 0 0 0;
  color: color.$base-red;
  font-weight: bold;
}
</style>
