import type { DomainPerformanceSeries } from "@/types/ls-api";

/* 期間指定ありのAPIの日付パラメータをまとめた定義 */
export interface DateParams {
    currentStartDate: string;
    currentEndDate: string;
    previousStartDate: string;
    previousEndDate: string;
}

/* 店舗・グループのフィルター条件をまとめた定義(空だったら全店舗) */
export interface StoreFilter {
    poiID?: number;
    areaIDs?: string;
}

/* グラフ凡例の表示・非表示を制御するためにDomainPerformanceSeriesを拡張 */
export interface DomainPerformanceSeriesWith extends DomainPerformanceSeries {
    seriesId?: string;
    index?: number;
}

/* 新インサイト分析の各グラフのツールチップ・ボタンのbackgroundなどの色 */
export const fillColors = ["#FDF5E9", "#EFF6FC", "#FEF1F1", "#F1FAEA"] as string[];
/* 新インサイト分析の各グラフの線の色 */
export const lineColors = ["#EE9D26", "#84C3EC", "#F17573", "#73C92D"] as string[];
