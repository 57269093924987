<template>
  <v-menu
    ref="pickermenu"
    v-model="menu"
    style="height: 10px"
    :close-on-content-click="false"
    transition="scale-transition"
    :disabled="disabled"
  >
    <template #activator>
      <v-text-field v-model="time" class="time-input" readonly :disabled="disabled"></v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="inputTime"
      format="24hr"
      no-title
      scrollable
      @click:minute="setTime()"
    ></v-time-picker>
  </v-menu>
</template>
<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
