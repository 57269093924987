import { requiredAuth } from "@/helpers";

import type { AxiosResponse } from "axios";
import type { CustomSnackbarToast } from "@/helpers/request";
import type { EntitiesRole, EntitiesPermissionCategory } from "@/types/ls-api.d";
import type { OperationLogParams } from "@/routes/operation-log";

export const api = {
    createRole,
    copyRole,
    updateRole,
    deleteRole,
    getPermissionCategories,
};

async function createRole(
    poiGroupID: number,
    oplogParameter: OperationLogParams,
    data: EntitiesRole
): Promise<string> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupID}/roles`;
    // 403はエラーメッセージをトースト表示したいので、requiredAuthの第5引数でトーストを無効化する
    const res = await requiredAuth<EntitiesRole>("post", url, oplogParameter, data, null, {
        statusCode: 403,
    } as CustomSnackbarToast)
        .then((res) => {
            if (res.status === 200) {
                return "";
            } else if (res.status === 403) {
                const errorResponse = JSON.parse(res?.request?.response);
                return errorResponse?.errorMessage || "権限の作成に失敗しました";
            } else {
                return "権限の作成に失敗しました";
            }
        })
        .catch((error) => {
            console.error("[createRole ERROR]", error);
            return "権限の作成に失敗しました";
        });
    return res;
}

async function copyRole(
    poiGroupID: number,
    roleLv: number,
    oplogParameter: OperationLogParams
): Promise<string> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupID}/roles/${roleLv}/copy`;
    const res = await requiredAuth<EntitiesRole>("post", url, oplogParameter, null, null, {
        statusCode: 403,
    } as CustomSnackbarToast)
        .then((res) => {
            if (res.status === 200) {
                return "";
            } else if (res.status === 403) {
                const errorResponse = JSON.parse(res?.request?.response);
                return errorResponse?.errorMessage || "権限のコピーに失敗しました";
            } else {
                return "権限のコピーに失敗しました";
            }
        })
        .catch((error) => {
            console.error("[copyRole ERROR]", error);
            return "権限のコピーに失敗しました";
        });
    return res;
}

async function updateRole(
    poiGroupID: number,
    oplogParameter: OperationLogParams,
    data: EntitiesRole
): Promise<string> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupID}/roles/${
        data.roleLv
    }`;
    const res = await requiredAuth<EntitiesRole>("put", url, oplogParameter, data, null, {
        statusCode: 403,
    } as CustomSnackbarToast)
        .then((res) => {
            if (res.status === 200) {
                return "";
            } else if (res.status === 403) {
                const errorResponse = JSON.parse(res?.request?.response);
                return errorResponse?.errorMessage || "権限の更新に失敗しました";
            } else {
                return "権限の更新に失敗しました";
            }
        })
        .catch((error) => {
            console.error("[updateRole ERROR]", error);
            return "権限の更新に失敗しました";
        });
    return res;
}

async function deleteRole(
    poiGroupID: number,
    roleLv: number,
    oplogParameter: OperationLogParams
): Promise<string> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupID}/roles/${roleLv}`;
    const res = await requiredAuth<EntitiesRole>("delete", url, oplogParameter, null, null, {
        statusCode: 403,
    } as CustomSnackbarToast)
        .then((res) => {
            if (res.status === 200) {
                return "";
            } else if (res.status === 403) {
                const errorResponse = JSON.parse(res?.request?.response);
                return errorResponse?.errorMessage || "権限の削除に失敗しました";
            } else {
                return "権限の削除に失敗しました";
            }
        })
        .catch((error) => {
            console.error("[deleteRole ERROR]", error);
            return "権限の削除に失敗しました";
        });
    return res;
}

async function getPermissionCategories(
    poiGroupID: number
): Promise<AxiosResponse<EntitiesPermissionCategory[]>> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupID}/permissionCategories`;
    return await requiredAuth<EntitiesPermissionCategory[]>("get", url);
}
