import type { definitions } from "@/types/swagger";
import { AxiosError, AxiosHeaders } from "axios";
import type { AxiosResponse } from "axios";
import pRetry, { AbortError } from "p-retry";

export type ErrorResponse = definitions["entities.ErrorResponse"];

export type GbpResponse<T> = {
    data?: T;
    error?: definitions["googleapi.Error"];
    statusCode?: number;
};
/** p-retry の AbortError に GbpResponse を持たせるためのエラークラス */
export class GbpResponseError extends Error {
    res: GbpResponse<any>;
    constructor(res: GbpResponse<any>) {
        super();
        this.res = res;
    }
    /** AxiosErrorに変換する。これは既存のエラーメッセージ生成関数をそのまま使うため */
    toAxiosError() {
        const ares: AxiosResponse<ErrorResponse> = {
            status: this.res.statusCode,
            statusText: "",
            data: {
                statusCode: this.res.statusCode,
                GMBError: { error: this.res.error },
                errorMessage: "GBPでエラー発生",
            },
            headers: new AxiosHeaders(),
            config: null,
        };
        const message = "Request failed with status code " + this.res.statusCode;
        const code = AxiosError.ERR_BAD_RESPONSE;
        return new AxiosError<ErrorResponse>(message, code, null, null, ares);
    }
}

/**
 * GBP が 429 または 500 を返したときにリトライする。
 * それ以外の statusCode とトストアが返したエラーはリトライしない。
 * この関数は AxiosError または GbpResponseError を投げる。
 */
export const retry = async function <T>(f: () => Promise<GbpResponse<T>>): Promise<GbpResponse<T>> {
    return pRetry(
        async () => {
            let res: GbpResponse<T>;
            try {
                res = await f();
            } catch (e: any) {
                throw new AbortError(e); // トストアが返したエラーはリトライしない
            }
            // 正常系
            if (res.statusCode >= 200 && res.statusCode <= 299) {
                return res;
            }
            // 以下は GBP が返したエラーに応じた処理。429 または 500 ならばリトライ、それ以外はリトライしない
            if (res.statusCode === 429 || res.statusCode === 500) {
                throw new GbpResponseError(res);
            } else {
                throw new AbortError(new GbpResponseError(res));
            }
        },
        {
            // 5回リトライ、つまり6回APIを実行する。
            // リトライ間隔は1,2,4,8,16秒、つまり最大31秒待つ
            retries: 5,
            minTimeout: Number(import.meta.env.VITE_APP_RETRY_MIN_TIMEOUT ?? 1000),
            onFailedAttempt: (error) => {
                console.log(
                    `${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`
                );
            },
        }
    );
};

/** GMBError.error.detailsのerrorDetails */
export type ErrorDetails = {
    code: number;
    message: string;
    value: string;
};
/** GMBError.error.details */
export type GmbErrorDetails = {
    "@type": string;
    errorDetails: ErrorDetails[];
};

// ls-APIから返ってきたエラーの詳細
class ErrorInfo {
    status: string = "";
    errorCode: number;
    errorDetailMessages: string[] = [];
    responseDetails = "";
    isGBPError: boolean = false;

    constructor(error: any) {
        this.responseDetails = error?.response?.detail ?? "";
    }

    /** 権限が不足している場合はtrue */
    get isForbidden(): boolean {
        return this.errorCode === 403;
    }

    /** リソース上限に達している場合はtrue */
    get isRateLimitExceeded(): boolean {
        return this.errorCode === 429;
    }

    /** GBPErrorかつ400番台の場合はtrue */
    get isGBPErrorAndErrorCodeIs400(): boolean {
        return this.isGBPError === true && this.errorCode >= 400 && this.errorCode < 500;
    }

    /** GBPErrorかつ500番台の場合はtrue */
    get isGBPErrorAndErrorCodeIs500(): boolean {
        return this.isGBPError === true && this.errorCode >= 500 && this.errorCode < 600;
    }

    setStatus(status: string) {
        this.status = status ?? "unknown";
    }

    getErrorMessage(): string {
        if (this.isForbidden) {
            return "権限が不足しています";
        }
        if (this.isRateLimitExceeded) {
            return "当月のアップロードファイル数が上限に達しています";
        }
        if (this.errorDetailMessages.length === 0) {
            if (this.responseDetails == "") {
                return "システムエラーが発生しました";
            }
            return this.responseDetails;
        }
        return this.errorDetailMessages[0];
    }
}

/**
 * GMBエラー詳細をパースする
 * @param error エラー
 * @returns エラー詳細
 */
export function parseGMBErrorDetails(error: AxiosError<ErrorResponse>): ErrorInfo {
    const result = new ErrorInfo(error);

    if (error?.response?.data?.GMBError?.error?.details == null) {
        // GMBErrorが返ってきていないがエラーだった場合(権限エラー、RateLimitExceededなど)
        result.errorCode = error?.response?.status;
    } else {
        // GMBErrorが返ってきている場合(=GBP側でエラーが発生した)
        const gbpError = error?.response?.data?.GMBError?.error;
        result.setStatus("Googleからエラーが返却されました");
        result.errorCode = gbpError?.code;
        result.isGBPError = true;
        result.errorDetailMessages =
            gbpError?.details
                ?.map((detail) => (detail as GmbErrorDetails)?.errorDetails)
                ?.map((detailItem) => detailItem?.[0]?.message) ?? [];
    }
    return result;
}

type ErrorReasonAndSolution = {
    reasons: string[];
    solution: string;
    isDiscontinued: boolean;
};

/**
 * エラーコードからエラーメッセージを取得する
 * @param error エラー
 * @returns エラーメッセージ
 * */
export function getErrorMessage(error: ErrorInfo): ErrorReasonAndSolution {
    if (error.isRateLimitExceeded) {
        // 429は実質非GMBErrorなレスポンスが返ってくるので例外的に対応
        // アップロード上限に達してる場合は処理を継続できない
        return {
            reasons: ["当月のアップロードファイル数が上限に達しています。"],
            solution: "アップロードは中断されました。",
            isDiscontinued: true,
        };
    }
    if (error.isGBPError) {
        // GMBErrorの場合
        const reasons: string[] = [];
        let solution: string = "";
        if (error.isGBPErrorAndErrorCodeIs400) {
            // 429以外の400番台エラー
            reasons.push(error.responseDetails);
            solution = "原因になっている箇所を修正した後、もう一度投稿し直してください。";
        } else {
            // 500番台エラー
            reasons.push(error.responseDetails);
            solution = "しばらく時間を置いた後、もう一度お試しください。";
        }
        reasons.push(...error.errorDetailMessages);
        return {
            reasons: reasons,
            solution: solution,
            isDiscontinued: false,
        };
    }
    // 非GMBErrorの場合
    return {
        reasons: ["システムエラーが発生しました。"],
        solution:
            "お手数ですが、時間を置いてリトライするか、Google Business Profileの画面での操作をお試しください。",
        isDiscontinued: false,
    };
}
