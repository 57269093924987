<template>
  <div>
    <div v-if="!hasData" class="no-data">指定された条件にヒットするデータなし</div>
    <div ref="bubblediv" :class="hasData ? 'review-bubble' : 'no-data-bubble'" />
  </div>
</template>

<script lang="ts">
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import { Component, Prop, Vue, toNative } from "vue-facing-decorator";
import type { ControllersKeywordRankingOutput, ControllersKeywordEntity } from "@/types/ls-api";

@Component({})
class ReviewBubble extends Vue {
  @Prop({ type: Boolean }) isGood: boolean;
  @Prop({ type: Function }) selectRankingRow: (row) => void;
  keywordRanking: ControllersKeywordRankingOutput = null;
  hasData: boolean = true;
  chart: am4plugins_forceDirected.ForceDirectedTree;
  series: am4plugins_forceDirected.ForceDirectedSeries;

  async mounted(): Promise<void> {
    this.updateBubble(this.keywordRanking, this.isGood);
  }

  private convertToChartData(entities: ControllersKeywordEntity[] = [], isGood: boolean): any[] {
    if (entities?.length === 0) {
      return [];
    }

    const list = [];
    const first = entities[0].name;
    let before = "";

    // バブルを昇順に並べるため、一位のバブルおよび一つ前のバブルとリンクする
    entities.map((entity) => {
      const data = {
        name: entity.name,
        value: isGood ? entity.goodCount : entity.badCount,
        goodCount: entity.goodCount,
        badCount: entity.badCount,
        link: [first, before],
      };
      before = entity.name;
      list.push(data);
    });
    return list;
  }

  private defaultFontSize: number = 14;
  private updateBubble(keywordRanking: ControllersKeywordRankingOutput, isGood: boolean): void {
    if (this.chart) {
      // 初期化済みならデータだけ更新
      this.series.data = this.convertToChartData(keywordRanking?.entities, isGood);
      this.changeBubbleFont();
      return;
    }

    am4core.useTheme(am4themes_animated);
    const elm = this.$refs.bubblediv as HTMLElement;
    this.chart = am4core.create(elm, am4plugins_forceDirected.ForceDirectedTree);
    this.chart.focusable = true;
    this.chart.hoverable = true;
    this.chart.hoverOnFocus = true;

    this.series = this.chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries());
    this.series.dataFields.name = "name";
    this.series.dataFields.id = "name";
    this.series.dataFields.value = "value";
    this.series.dataFields.linkWith = "link";
    this.series.links.template.distance = 1;
    this.series.nodes.template.tooltipText = this.isGood
      ? "[bold]{name}[/]\n\nGood: {goodCount}"
      : "[bold]{name}[/]\n\nBad: {badCount}";
    this.series.nodes.template.fillOpacity = 1;
    this.series.nodes.template.outerCircle.disabled = true;
    this.series.nodes.template.outerCircle.filters.push(new am4core.DropShadowFilter());
    this.series.nodes.template.label.text = "[bold]{name}";
    this.series.nodes.template.label.hideOversized = true;
    this.series.nodes.template.label.truncate = true;
    this.series.nodes.template.propertyFields.x = "x";
    this.series.nodes.template.propertyFields.y = "y";
    this.series.colors.baseColor = this.isGood
      ? am4core.color("#68CBB6")
      : am4core.color("#F27573");
    this.series.colors.step = 0;
    this.series.fontSize = this.defaultFontSize;
    this.series.minRadius = 30;
    this.series.maxRadius = 90;
    this.series.manyBodyStrength = -5;
    this.series.centerStrength = 0.5;
    this.series.links.template.strokeOpacity = 0;
    this.series.data = this.convertToChartData(keywordRanking?.entities, isGood);
    this.changeBubbleFont();

    this.chart.interactionsEnabled = true;
    this.chart.validateLayout();
    this.chart.validatePosition();
  }

  changeBubbleFont(): void {
    var count: number = 1;
    var maxFontSize: number = 45;
    var previousVal: number = 0;
    this.series.nodes.template.events.on("ready", (event) => {
      // 上位３位までは順位に応じてフォントを大きくする(同列は同じフォントサイズ)
      if (previousVal > event.target.dataItem.value) {
        if (count === 3) {
          return;
        }
        maxFontSize *= 0.75;
        count += 1;
      }
      const fontSize = Math.max(
        this.defaultFontSize,
        Math.min(maxFontSize, Math.ceil(event.target.measuredWidth * 0.5))
      );
      event.target.fontSize = fontSize;
      maxFontSize = fontSize;
      previousVal = event.target.dataItem.value;
    });
  }

  changeBubbleData(keywordRanking: ControllersKeywordRankingOutput, isGood: boolean): void {
    this.keywordRanking = keywordRanking;
    this.hasData = keywordRanking?.entities.length > 0;
    this.updateBubble(keywordRanking, isGood);
  }
}
export default toNative(ReviewBubble);
</script>
<style lang="scss" scoped>
.review-bubble {
  width: 100%;
  box-sizing: border-box;
  margin: 10px;
  padding: 15px;
  height: 500px;
}
// v-ifで消すとamchartsを初期化できないので高さ0にして隠しておく
.no-data-bubble {
  height: 0px;
}
.no-data {
  margin: 10px 20px;
  padding: 10px 0 4px 0;
  color: rgba(0, 0, 0, 0.38);
  font: normal normal 14px/20px sans-serif;
}
</style>
