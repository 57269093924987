<template>
  <form @submit.prevent="submit()">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p data-testid="modalTitle" class="modal-card-title">クチコミアラート設定</p>
      </header>
      <section class="modal-card-body">
        <o-field horizontal label="星数">
          <v-btn-toggle
            v-model="reviewAlertCondition.alertStarRatings"
            dense
            multiple
            class="toggle-button-group"
          >
            <v-btn
              v-for="starRating in starRatings"
              :key="starRating"
              class="toggle-button"
              active-class="primary"
              elevation="0"
              outlined
              :value="starRating"
            >
              <span v-for="n in starRating" :key="n">
                <i class="mdi mdi-star mdi-24px" />
              </span>
            </v-btn>
          </v-btn-toggle>
        </o-field>
        <o-field horizontal label="感情スコア">
          <v-btn-toggle
            v-model="reviewAlertCondition.alertSentimentRanges"
            dense
            multiple
            class="toggle-button-group"
          >
            <v-btn
              v-for="sentimentLevel in sentimentLevels"
              :key="sentimentLevel.value"
              class="toggle-button"
              active-class="primary"
              elevation="0"
              outlined
              :value="sentimentLevel.value"
            >
              {{ sentimentLevel.name }}
            </v-btn>
          </v-btn-toggle>
        </o-field>

        <o-field horizontal label="キーワード">
          <o-inputitems
            v-model="reviewAlertCondition.alertKeywords"
            ellipsis
            icon="label"
            placeholder="指定キーワードを含むクチコミ投稿を通知します。"
          ></o-inputitems>
        </o-field>
      </section>
      <footer class="modal-card-foot">
        <v-btn class="button" variant="outlined" @click.prevent="cancel">キャンセル</v-btn>
        <v-btn class="button is-primary" @click.prevent="submit">設定</v-btn>
      </footer>
    </div>
  </form>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { sentimentLevels } from "@/const";

export default defineComponent({
  emits: ["complete", "cancel"],
  data() {
    return {
      reviewAlertCondition: {
        alertSentimentRanges: [],
        alertKeywords: [],
        alertStarRatings: [],
      },
      starRatings: [1, 2, 3, 4, 5],
      sentimentLevels: [...sentimentLevels],
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    submit() {
      this.$emit("complete", this.reviewAlertCondition);
    },
  },
});
</script>
<style lang="scss" scoped>
.field {
  align-items: center;
}
</style>
