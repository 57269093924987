<template>
  <v-dialog v-model="showDialog" width="500" scrollable>
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        {{ title }}
      </v-card-title>
      <v-progress-linear v-model="percentage" color="light-blue"></v-progress-linear>
      <v-card-text>
        <div v-for="(text, index) in message.split('<br/>')" :key="index">
          <!--eslint-disable vue/no-v-html-->
          <div class="dialog-paragraph" v-html="sanitize(text)"></div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text="OK" variant="flat" :disabled="button" @click="onOKClick" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue, toNative, Watch } from "vue-facing-decorator";
import sanitizeHtml from "sanitize-html";

@Component({})
class SubmitDialog extends Vue {
  @Prop({ default: false, type: Boolean, required: true }) show: boolean;
  @Prop({ default: "", type: String, required: true }) title: string;
  @Prop({ default: 0, type: Number, required: true }) percentage: number;
  @Prop({ default: "", type: String, required: true }) message: string;
  @Prop({ default: false, type: Boolean, required: true }) button: boolean;
  showDialog: boolean = false;

  @Watch("show")
  toggleDialog(): void {
    this.showDialog = this.show;
  }

  sanitize(text: string): string {
    return sanitizeHtml(text, {
      allowedTags: ["a", "b", "div", "hr", "br"],
      allowedAttributes: {
        a: ["href", "target"],
      },
      allowedSchemes: ["https"],
    });
  }

  onOKClick(): void {
    this.$emit("setShow", false);
  }
}
export default toNative(SubmitDialog);
</script>
<style lang="scss" scoped>
:deep(.headline) {
  min-height: 32px;
  box-sizing: content-box;
}
:deep(.v-card__text) {
  height: 400px;
}
:deep(.v-dialog .dialog-paragraph) {
  margin: 0;
  a {
    color: #7957d5;
    font-weight: bold;
  }
  hr {
    display: block;
    border: 1px dashed #ccc;
    margin: 1em 0;
  }
}
</style>
