<template>
  <v-container class="post-preview">
    <v-card :disabled="!step.enabled" color="transparent" elevation="0">
      <!-- 投稿種別選択 -->
      <v-row align="center">
        <v-col cols="auto" style="padding-right: 0">
          <span>投稿種別</span>
        </v-col>
        <v-col class="post-type-col">
          <v-select
            v-model="post.postType"
            :items="currentPostTypes"
            item-title="name"
            item-value="id"
            hide-details
            density="compact"
            bg-color="white"
            color="primary"
            :readonly="readonly"
          />
        </v-col>
      </v-row>
      <!-- 投稿プレビュー -->
      <!-- 最新情報 -->
      <v-form v-if="post.postType === 'info'" ref="info" v-model="isValidateForm">
        <v-row class="preview-row">
          <gmb-header :target-name="targetName" />
          <gmb-carousel
            :files="files"
            :is-mobile="isMobile"
            :readonly="readonly"
            @edit-image="$emit('edit-image', $event)"
          />
          <v-row align="center">
            <v-col class="mt-n4">
              <p class="error-message">{{ previewErrorMessages }}</p>
            </v-col>
            <v-col cols="12" style="padding: 10px 20px">
              <v-textarea
                v-model="post.title"
                :rules="[rules.required, rules.maxLength(1487)]"
                counter="1487"
                rows="1"
                auto-grow
                variant="underlined"
                hide-details="auto"
                persistent-counter
                class="detail-text"
                color="primary"
                :readonly="readonly"
              />
            </v-col>
            <v-col class="button-col">
              <v-btn
                v-if="post.button != 'なし'"
                color="blue"
                variant="text"
                @click="buttonClick()"
              >
                <div v-if="post.button === '今すぐ電話'">今すぐ電話する</div>
                <div v-else>
                  {{ post.button }}
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
        <v-row align="center">
          <v-col cols="auto">
            <span>ボタンの追加</span>
          </v-col>
          <v-col>
            <v-select
              v-model="post.button"
              :items="gmbButtonTypes"
              :menu-props="{ maxHeight: 400 }"
              hide-details
              density="compact"
              bg-color="white"
              color="primary"
              :readonly="readonly"
            />
          </v-col>
        </v-row>
        <v-row v-if="post.button !== 'なし' && post.button !== '今すぐ電話'">
          <v-col cols="10">
            <v-text-field
              v-model="post.buttonUrl"
              label="ボタンのリンク"
              :rules="[rules.required, rules.multiByteUrl, rules.maxLength(buttonUrlMaxLength)]"
              variant="underlined"
              hide-details="auto"
              color="primary"
              :readonly="readonly"
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              variant="text"
              color="primary"
              icon="fas fa-external-link-alt"
              class="jump-url-button"
              title="入力したURLのページを別タブで開きます"
              :disabled="isInvalidUrl()"
              @click="jumpUrl"
            />
          </v-col>
        </v-row>
        <p v-else-if="post.button === '今すぐ電話'" class="phone-desc text-indent-1">
          ※店舗情報で設定されている電話番号が設定されます
        </p>
      </v-form>
      <!-- イベント -->
      <v-form v-if="post.postType === 'event'" ref="event" v-model="isValidateForm">
        <v-row class="preview-row">
          <gmb-header :target-name="targetName" />
          <gmb-carousel
            :files="files"
            :is-mobile="isMobile"
            :readonly="readonly"
            @edit-image="$emit('edit-image', $event)"
          />
          <v-row>
            <p class="error-message">{{ previewErrorMessages }}</p>
          </v-row>
          <v-row class="title-text">
            {{ titleString() }}
          </v-row>
          <v-row class="term">
            {{ termString() }}
          </v-row>
          <v-row align="center">
            <v-col cols="12" style="padding: 10px 20px">
              <v-textarea
                v-model="post.detail"
                :rules="[rules.maxLength(1487)]"
                counter="1487"
                rows="1"
                auto-grow
                variant="underlined"
                hide-details="auto"
                persistent-counter
                class="detail-text"
                color="primary"
                :readonly="readonly"
              />
            </v-col>
            <v-col class="button-col">
              <v-btn
                v-if="post.button != 'なし'"
                color="blue"
                variant="text"
                @click="buttonClick()"
              >
                <div v-if="post.button === '今すぐ電話'">今すぐ電話する</div>
                <div v-else>
                  {{ post.button }}
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="post.title"
              label="イベントのタイトル"
              counter="58"
              :rules="[rules.required, rules.maxLength(58)]"
              variant="underlined"
              hide-details="auto"
              persistent-counter
              color="primary"
              :readonly="readonly"
            />
          </v-col>
        </v-row>
        <v-row class="input-row detail-switch">
          <v-spacer />
          <v-col>
            <v-switch
              v-model="hasTime"
              color="primary"
              label="時間を追加"
              :readonly="readonly"
              @change="changeHasTime"
            />
          </v-col>
        </v-row>
        <v-row align="center" class="input-row">
          <v-col class="label-col" cols="3">開始日時</v-col>
          <v-col cols="5">
            <date-picker
              v-model="post.start"
              :min="oneYearAgo"
              :max="post.end"
              required
              :readonly="readonly"
            />
          </v-col>
          <v-col v-if="hasTime" cols="4" class="input-col">
            <o-input
              v-model="post.startTime"
              placeholder="09:00"
              type="time"
              :disabled="readonly"
            />
          </v-col>
        </v-row>
        <v-row align="center" class="input-row">
          <v-col class="label-col" cols="3">終了日時</v-col>
          <v-col cols="5">
            <date-picker
              v-model="post.end"
              :min="post.start"
              :max="eventLimitDate"
              required
              :readonly="readonly"
            />
          </v-col>
          <v-col v-if="hasTime" cols="4" class="input-col">
            <o-input v-model="post.endTime" placeholder="09:00" type="time" :disabled="readonly" />
          </v-col>
        </v-row>
        <v-row v-if="dateTimeErrorMessage != ''">
          <p class="form-error-message">
            {{ dateTimeErrorMessage }}
          </p>
        </v-row>
        <v-row align="center">
          <v-col cols="auto">
            <span>ボタンの追加</span>
          </v-col>
          <v-col>
            <v-select
              v-model="post.button"
              :items="gmbButtonTypes"
              :menu-props="{ maxHeight: 400 }"
              hide-details
              density="compact"
              bg-color="white"
              color="primary"
              :readonly="readonly"
            />
          </v-col>
        </v-row>
        <v-row v-if="post.button !== 'なし' && post.button !== '今すぐ電話'">
          <v-col cols="22">
            <v-text-field
              v-model="post.buttonUrl"
              label="ボタンのリンク"
              :rules="[rules.required, rules.multiByteUrl, rules.maxLength(buttonUrlMaxLength)]"
              variant="underlined"
              hide-details="auto"
              color="primary"
              :readonly="readonly"
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              variant="text"
              color="primary"
              icon="fas fa-external-link-alt"
              class="jump-url-button"
              title="入力したURLのページを別タブで開きます"
              :disabled="isInvalidUrl()"
              @click="jumpUrl"
            />
          </v-col>
        </v-row>
        <p v-else-if="post.button === '今すぐ電話'" class="phone-desc text-indent-1">
          ※店舗情報で設定されている電話番号が設定されます
        </p>
      </v-form>
      <!-- 特典 -->
      <v-form v-if="post.postType === 'benefits'" ref="benefits" v-model="isValidateForm">
        <v-row class="preview-row">
          <gmb-header :target-name="targetName" />
          <gmb-carousel
            :files="files"
            :is-mobile="isMobile"
            :readonly="readonly"
            @edit-image="$emit('edit-image', $event)"
          />
          <v-row>
            <p class="error-message">{{ previewErrorMessages }}</p>
          </v-row>
          <v-row class="title-text">
            {{ titleString() }}
          </v-row>
          <v-row align="center">
            <v-col cols="12" style="padding: 10px 20px">
              <v-textarea
                v-model="post.detail"
                :rules="[rules.maxLength(1487)]"
                counter="1487"
                rows="1"
                auto-grow
                variant="underlined"
                hide-details="auto"
                persistent-counter
                class="detail-text"
                color="primary"
                :readonly="readonly"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="post.link" class="button-col">
              <v-btn variant="text" color="blue" @click="buttonClick(post.link)">
                オンラインで特典を利用
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="post.couponCode">
            <v-col class="coupon">
              <v-row>
                <v-col class="coupon-detail">このコードをお店で提示してください</v-col>
              </v-row>
              <v-row class="coupon-code">
                <v-col>
                  {{ post.couponCode }}
                </v-col>
              </v-row>
              <v-row class="coupon-detail">
                <v-col>有効期間: {{ termString("YYYY/MM/DD") }}</v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="post.termsOfService">
            <v-col class="couponRule">
              <details>
                <summary style="cursor: pointer">利用規約</summary>
                <div>{{ post.termsOfService }}</div>
              </details>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col class="title-col">
            <v-text-field
              v-model="post.title"
              label="特典のタイトル"
              counter="58"
              :rules="[rules.required, rules.maxLength(58)]"
              variant="underlined"
              hide-details="auto"
              persistent-counter
              color="primary"
              :readonly="readonly"
            />
          </v-col>
        </v-row>
        <v-row align="center" class="input-row">
          <v-col class="label-col" cols="3">開始日</v-col>
          <v-col cols="5">
            <date-picker
              v-model="post.start"
              :min="oneYearAgo"
              :max="post.end"
              required
              :readonly="readonly"
            />
          </v-col>
        </v-row>
        <v-row align="center" class="input-row">
          <v-col class="label-col" cols="3">終了日</v-col>
          <v-col cols="5">
            <date-picker
              v-model="post.end"
              :min="post.start"
              next-button
              required
              :readonly="readonly"
            />
          </v-col>
        </v-row>
        <v-row v-show="dateTimeErrorMessage != ''">
          <p class="form-error-message">
            {{ dateTimeErrorMessage }}
          </p>
        </v-row>
        <v-row class="input-row">
          <v-text-field
            v-model="post.couponCode"
            label="クーポンコード（省略可）"
            :rules="[rules.maxLength(58)]"
            counter="58"
            variant="underlined"
            hide-details="auto"
            persistent-counter
            color="primary"
            :readonly="readonly"
          />
        </v-row>
        <v-row class="input-row">
          <v-text-field
            v-model="post.link"
            label="特典へのリンク（省略可）"
            :rules="[rules.multiByteUrl, rules.maxLength(1500)]"
            counter="1500"
            variant="underlined"
            hide-details="auto"
            persistent-counter
            color="primary"
            :readonly="readonly"
          />
        </v-row>
        <v-row class="input-row">
          <v-textarea
            v-model="post.termsOfService"
            label="利用規約（省略可）"
            :rules="[rules.maxLength(5000)]"
            counter="5000"
            variant="underlined"
            hide-details="auto"
            persistent-counter
            color="primary"
            :readonly="readonly"
          />
        </v-row>
      </v-form>
      <!-- 商品 -->
      <v-form v-if="post.postType === 'product'" ref="product" v-model="isValidateForm">
        <v-row class="preview-row product-row">
          <v-row class="category-string">
            {{ categoryString() }}
          </v-row>
          <gmb-carousel
            v-if="isVideoInProduct === false"
            :files="files"
            :is-mobile="isMobile"
            :readonly="readonly"
            @edit-image="$emit('edit-image', $event)"
          />
          <v-row>
            <p class="error-message">{{ previewErrorMessages }}</p>
          </v-row>
          <v-row class="title-text">
            {{ titleString("商品・サービス名") }}
          </v-row>
          <v-row class="term">
            {{ priceString() }}
          </v-row>
          <v-row align="center">
            <v-col cols="12" style="padding: 10px 20px">
              <v-textarea
                v-model="post.detail"
                :rules="[rules.maxLength(987)]"
                counter="987"
                rows="1"
                auto-grow
                variant="underlined"
                hide-details="auto"
                persistent-counter
                class="detail-text"
                color="primary"
                :readonly="readonly"
              />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-btn
              :disabled="!post.buttonUrl"
              variant="text"
              color="blue"
              class="product-button"
              @click="buttonClick()"
            >
              <v-icon icon="mdi-earth" color="blue" />
              サイトを見る
            </v-btn>
          </v-row>
        </v-row>
        <v-row class="title-row">
          <v-text-field
            v-model="post.title"
            label="商品・サービス名"
            hint="※Google Business Profile の投稿種別 : 商品で、絵文字や記号など使用できない文字が存在すると投稿できません"
            counter="58"
            variant="underlined"
            persistent-counter
            :rules="[rules.required, rules.maxLength(58), rules.noEmoji, rules.noSymbol]"
            auto-details="auto"
            color="primary"
            :readonly="readonly"
          />
        </v-row>
        <v-row class="input-row category-input">
          <v-combobox
            v-model="post.category"
            label="カテゴリ"
            hint="※Google Business Profile の投稿種別 : 商品で、絵文字や記号など使用できない文字が存在すると投稿できません"
            counter="58"
            variant="underlined"
            persistent-counter
            color="primary"
            :rules="[rules.required, rules.maxLength(58), rules.noEmoji, rules.noSymbol]"
            :items="categories"
            :loading="loading"
            :readonly="readonly"
          />
        </v-row>
        <v-row align="center" class="input-row">
          <v-text-field
            v-model="post.price"
            label="商品価格（JPY）"
            :rules="[rules.maxPriceLength(12), rules.positiveNumber, rules.decimalPoint]"
            variant="underlined"
            hide-details="auto"
            type="number"
            hint="省略可"
            persistent-hint
            color="primary"
            :readonly="readonly"
          />
        </v-row>
        <v-row>
          <v-col cols="10">
            <v-text-field
              v-model="post.buttonUrl"
              label="商品のランディング ページ URL"
              :rules="[rules.multiByteUrl, rules.maxLength(landingPageUrlMaxLength)]"
              variant="underlined"
              hide-details="auto"
              persistent-counter
              hint="省略可"
              persistent-hint
              color="primary"
              :readonly="readonly"
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              variant="text"
              color="primary"
              icon="fas fa-external-link-alt"
              class="jump-url-button"
              title="入力したURLのページを別タブで開きます"
              :disabled="isInvalidUrl()"
              @click="jumpUrl"
            >
              <v-icon small>fas fa-external-link-alt</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <!-- COVID-19の最新情報 -->
      <v-form v-if="post.postType === 'covid19'" ref="covid19" v-model="isValidateForm">
        <v-row class="preview-row">
          <gmb-header :target-name="targetName" />
          <!-- COVID-19は画像なし -->
          <v-row>
            <p class="error-message">{{ previewErrorMessages }}</p>
          </v-row>
          <v-row align="center">
            <v-col cols="12" style="padding: 10px 20px">
              <v-textarea
                v-model="post.title"
                :rules="[rules.required, rules.maxLength(1487)]"
                counter="1487"
                rows="1"
                auto-grow
                hide-details="auto"
                variant="underlined"
                persistent-counter
                class="detail-text"
                color="primary"
                :readonly="readonly"
              />
            </v-col>
            <v-col class="button-col">
              <v-btn
                v-if="post.button != 'なし'"
                variant="text"
                color="blue"
                @click="buttonClick()"
              >
                {{ post.button }}
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
        <v-row align="center">
          <v-col cols="auto">
            <span>ボタンの追加</span>
          </v-col>
          <v-col>
            <v-select
              v-model="post.button"
              :items="gmbButtonTypes"
              hide-details
              density="compact"
              bg-color="white"
              color="primary"
              :readonly="readonly"
            />
          </v-col>
        </v-row>
        <v-row v-if="post.button !== 'なし'" class="input-row">
          <v-col cols="22">
            <v-text-field
              v-model="post.buttonUrl"
              label="ボタンのリンク"
              :rules="[rules.required, rules.multiByteUrl, rules.maxLength(buttonUrlMaxLength)]"
              variant="underlined"
              hide-details="auto"
              color="primary"
              :readonly="readonly"
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              variant="text"
              color="primary"
              icon="fas fa-external-link-alt"
              class="jump-url-button"
              title="入力したURLのページを別タブで開きます"
              :disabled="isInvalidUrl()"
              @click="jumpUrl"
            >
              <v-icon small>fas fa-external-link-alt</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { VForm } from "vuetify/lib/components/index.mjs";
import type { StepStatus } from "../post-stepper.vue";
import { PostGMBForm } from "./gmb-request";
import GmbHeader from "./gmb-header.vue";
import GmbCarousel from "./gmb-carousel.vue";
import { useSnackbar } from "@/storepinia/snackbar";
import type { FileSelectionItem, GMBPostType } from "@/models/v2-file-selection";
import { platformOfficialNames, sortFileSelectionItemList } from "@/models/v2-file-selection";
import DatePicker from "@/components/shared/date-picker/DatePicker.vue";
import dayjs from "dayjs";
import { VuetifyValidator, validator } from "@/helpers";
import { validateFile } from "../validator";
import { requiredAuth } from "@/helpers/request";
import type { StorageGetV2PostProductionCategories } from "@/types/ls-api";
import { getter } from "@/storepinia/idxdb";

interface GMBPostTypeDict {
  id: GMBPostType;
  name: string;
  canAddButton: boolean;
}
const // 投稿種別のマスタ
  gmbPostTypes: GMBPostTypeDict[] = [
    { id: "info", name: "最新情報", canAddButton: true },
    { id: "event", name: "イベント", canAddButton: true },
    { id: "benefits", name: "特典", canAddButton: false },
    { id: "product", name: "商品", canAddButton: false },
    { id: "covid19", name: "COVID-19の最新情報", canAddButton: true },
  ];

export default defineComponent({
  components: { GmbHeader, GmbCarousel, DatePicker },
  props: {
    modelValue: { type: Object as () => PostGMBForm, required: true }, // v-model
    baseText: { type: String, required: true },
    targetName: { type: String, required: true },
    fileSelections: { type: Array as () => FileSelectionItem[], required: true },
    step: { type: Object as () => StepStatus, required: true },
    aspectNoCheck: { type: Boolean },
    isEdit: { type: Boolean },
    isMobile: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  emits: ["update:modelValue", "edit-image", "update-step"],
  data: () => {
    return {
      company: getter().company,
      addSnackbarMessages: useSnackbar().addSnackbarMessages,

      post: new PostGMBForm(),

      gmbButtonTypes: ["なし", "予約", "オンライン注文", "購入", "詳細", "登録", "今すぐ電話"],
      today: dayjs().format("YYYY-MM-DD"),
      oneYearAgo: dayjs().subtract(1, "years").format("YYYY-MM-DD"),
      buttonUrlMaxLength: 2000,
      landingPageUrlMaxLength: 1500,
      rules: VuetifyValidator as typeof VuetifyValidator,
      // 選択可能な投稿種別（編集時は投稿種別を変更不可にする）
      currentPostTypes: gmbPostTypes as GMBPostTypeDict[],
      eventLimitDate: dayjs().add(1, "years").format("YYYY-MM-DD"),

      isValidateForm: false,
      fileErrorMessages: "", // 4-2. 選択済の画像・動画の編集に表示するエラーメッセージ
      previewErrorMessages: "", // 6. 投稿内容プレビューに表示するエラーメッセージ（短縮する）
      dateTimeErrorMessage: "",
      hasTime: false,
      files: [] as FileSelectionItem[],
      loading: false,
      categories: [] as string[],
      // 「商品」に動画含まれてしまってないかフラグ
      isVideoInProduct: false,
    };
  },
  watch: {
    // propを監視
    modelValue: {
      immediate: true,
      async handler(newval: PostGMBForm, oldval) {
        this.post.postType = newval.postType;
        this.post.title = newval.title;
        this.post.detail = newval.detail;
        this.post.button = newval.button?.length > 0 ? newval.button : "なし";
        this.post.buttonUrl = newval.buttonUrl?.length > 0 ? newval.buttonUrl : "";
        this.post.start = newval.start?.length > 0 ? newval.start : this.today;
        this.post.end = newval.end?.length > 0 ? newval.end : "";
        this.post.startTime = newval.startTime?.length > 0 ? newval.startTime : "";
        this.post.endTime = newval.endTime?.length > 0 ? newval.endTime : "";
        this.hasTime = false;
        if (newval.startTime?.length > 0 || newval.endTime?.length > 0) {
          this.hasTime = true;
        }
        this.post.couponCode = newval.couponCode?.length > 0 ? newval.couponCode : "";
        this.post.link = newval.link?.length > 0 ? newval.link : "";
        this.post.termsOfService =
          this.post.termsOfService?.length > 0 ? newval.termsOfService : "";
        this.post.category = newval.category?.length > 0 ? newval.category : "";
        this.post.price = newval.price?.length > 0 ? newval.price : "";
        // 強制的にバリデートする
        await this.$nextTick(); // VForm.validate() を呼ぶ前には $nextTick が必要
        await (this.$refs[this.post.postType] as VForm)?.validate();
        // 編集時は投稿種別を変更不可にする
        if (this.isEdit) {
          this.currentPostTypes = gmbPostTypes.filter((type) => type.id === this.post.postType);
        }
      },
    },
    isEdit: {
      immediate: true,
      handler(newval: boolean, oldval) {
        // GBPコンソールが使えない場合は、商品投稿できない
        if (!this.company.canUseGbpConsole) {
          this.currentPostTypes = gmbPostTypes.filter((t) => t.id !== "product");
        }
      },
    },
    baseText: {
      immediate: true,
      handler(newval: string, oldval) {
        // 共通テキストが変更されたら変更を上書き
        this.post.detail = this.baseText;

        // 「最新情報」「COVID-19の最新情報」の場合はタイトルも変更
        if (this.post.postType === "info" || this.post.postType === "covid19") {
          this.post.title = this.baseText;
        }
      },
    },
    fileSelections: {
      immediate: true,
      deep: true,
      async handler(newval, oldval) {
        // 選択済みのファイルを順番通りにソートした上でfilesに格納する
        this.files = sortFileSelectionItemList(this.fileSelections);
        // 画像選択されたらプレビューを有効化する
        if (this.files.length > 0 && !this.$props.step.enabled) {
          this.$props.step.enabled = true;
        }
        await this.validateFiles();
      },
    },
    post: {
      deep: true,
      async handler() {
        if (this.post.postType === "product") {
          // 投稿タイプが「商品」場合はボタンを「なし」にする
          this.post.button = "なし";
        } else {
          // 投稿タイプを変更した場合は、選択不可能なボタンなら初期化する
          this.post.button = this.checkButtonItem() ? this.post.button : "なし";
          this.post.buttonUrl = this.checkButtonItem() ? this.post.buttonUrl : "";
          // ボタンタイプが「なし」の場合はボタンURLを初期化する
          if (this.post.button === "なし") {
            this.post.buttonUrl = "";
          }
        }
        this.changeHasTime();
        this.validateDatetime();
        await this.$nextTick(); // VForm.validate() を呼ぶ前には $nextTick が必要
        await (this.$refs[this.post.postType] as VForm)?.validate();
        await this.validateFiles();
        this.$emit("update:modelValue", this.post);
      },
    },
    isValidateForm: {
      handler(newval: boolean, oldval) {
        this.isCompleted(newval, oldval);
      },
    },
    step: {
      deep: true,
      immediate: true,
      async handler() {
        await this.validateFiles();
      },
    },
  },
  async created() {
    await this.fetch_production_categories();
  },
  methods: {
    async fetch_production_categories(nextKey: string = ""): Promise<void> {
      if (nextKey.length === 0) {
        this.categories = [];
        this.loading = true;
      }
      const res = await requiredAuth<StorageGetV2PostProductionCategories>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
          this.company.poiGroupID
        }/post/categories`,
        { nextKey: nextKey }
      );
      if (res.data?.categories?.length > 0) {
        this.categories = this.categories.concat(res.data.categories);
      }
      if (res.data?.nextKey?.length > 0) {
        await this.fetch_production_categories(res.data.nextKey);
      } else {
        this.loading = false;
      }
    },

    isCompleted(newval?, oldval?): void {
      this.validateDatetime();
      this.$emit("update-step", {
        isComplete:
          this.isValidateForm && this.dateTimeErrorMessage == "" && this.fileErrorMessages == "",
        errorMessage: `${this.fileErrorMessages}`,
      });
    },

    async validateFiles(): Promise<void> {
      this.fileErrorMessages = "";
      this.previewErrorMessages = "";

      // 無効ならばエラーメッセージは出さない
      await this.$nextTick();
      if (!this.$props.step.enabled) {
        return;
      }

      // 細かいバリデーションが不要なケース
      if (this.post.postType == "covid19") {
        if (this.files.length > 0) {
          this.fileErrorMessages = "「COVID-19の最新情報」への画像の投稿はできません";
        }
      } else if (this.post.postType == "product") {
        if (this.files.find((file) => file.videoUrl)) {
          this.fileErrorMessages = "「商品」への動画の投稿はできません";
        } else if (this.files.length !== 1) {
          this.fileErrorMessages =
            this.files.length === 0
              ? "「商品」への投稿では画像のアップロードが必須です"
              : "「商品」への投稿では画像を1枚にする必要があります";
        }
      }
      if (this.fileErrorMessages !== "") {
        this.previewErrorMessages = this.fileErrorMessages;
        this.fileErrorMessages = `${platformOfficialNames.google} ${this.fileErrorMessages}`;
        this.$emit("update-step", {
          isComplete: false,
          errorMessage: `${this.fileErrorMessages}`,
        });
        return;
      }

      this.isVideoInProduct = false;

      // アスペクト比・画像サイズなどのバリデーション
      const errorMessages: string[] = [];
      for (const file of this.files) {
        await validateFile(file, this.files.length, "google", errorMessages, this.aspectNoCheck);
      }
      if (errorMessages.length === 0) {
        this.fileErrorMessages = "";
        this.previewErrorMessages = "";
        this.isCompleted();
        return;
      }
      this.fileErrorMessages = errorMessages.join("\n");
      this.$emit("update-step", { isComplete: false, errorMessage: this.fileErrorMessages });

      // 冗長なのでpreviewに表示するエラー文は短くする
      this.previewErrorMessages = errorMessages
        .map((m) => m.replace(platformOfficialNames.google + " ", ""))
        .join("\n");
    },

    // 選択中のボタンが選択中の投稿タイプで使えるか
    checkButtonItem(): boolean {
      if (!this.gmbButtonTypes.includes(this.post.button)) {
        return false;
      }
      return gmbPostTypes.find((type) => type.id === this.post.postType)?.canAddButton ?? false;
    },

    buttonClick(text: string = null): void {
      let message = "";
      if (this.post.button === "今すぐ電話") {
        message = "店舗情報の電話番号に発信されます";
      } else if (text === null) {
        message =
          this.post.buttonUrl === "" ? "ボタンのリンクを設定してください" : this.post.buttonUrl;
      } else {
        message = text === "" ? "リンクを設定してください" : text;
      }
      this.addSnackbarMessages({
        text: message,
        color: "info",
      });
    },

    changeHasTime(): void {
      if (!this.hasTime) {
        this.post.startTime = "";
        this.post.endTime = "";
        this.dateTimeErrorMessage = "";
      }
    },

    isInvalidUrl(): boolean {
      const urlLengthMax: Record<string, any> = {
        max: this.buttonUrlMaxLength,
      };
      // 空文字か、URLの形式を満たさないか、最大文字長を超える場合は不正なURLとする
      return (
        this.post.buttonUrl === "" ||
        validator.multiByteUrl.validate(this.post.buttonUrl, []) === false ||
        validator.maxLength.validate(this.post.buttonUrl, urlLengthMax) === false
      );
    },

    jumpUrl(): void {
      window.open(this.post.buttonUrl, "_blank");
    },

    validateDatetime(): void {
      this.dateTimeErrorMessage = "";
      // 日付指定があるのはイベントと特典のみ
      if (!["event", "benefits"].includes(this.post.postType)) {
        return;
      }
      // form.validate()で一括バリデーションされないようなのでこちらでもチェックする
      if (this.post.start.length === 0 || this.post.end.length === 0) {
        this.dateTimeErrorMessage = "開始日・終了日ともに必須項目です";
      }
      // 予め値が入っている状態ではDatePickerのバリデーションが動かないので、コード上でバリデーションする
      if (dayjs(this.post.end).isBefore(dayjs(), "day")) {
        this.dateTimeErrorMessage = "終了日が過去の日付に設定されています";
        return;
      }

      const startDateTime = dayjs(`${this.post.start} ${this.post.startTime}`);
      const endDateTime = dayjs(`${this.post.end} ${this.post.endTime}`);
      if (this.post.postType === "event" && endDateTime.diff(startDateTime, "day") > 366) {
        // イベントで一年より長い期間(一年後の今日 = 366日後までは有効)を指定していたらバリデーションエラーとする
        this.dateTimeErrorMessage = "イベントの期間は1年以内で設定してください";
        return;
      }

      if (this.post.startTime.length === 0 && this.post.endTime.length === 0) {
        // 両方とも時間がセットされていない場合はOK
        return;
      }

      if (this.post.startTime.length === 0) {
        this.dateTimeErrorMessage = "終了時刻をセットした場合、開始時刻は必須項目です";
        return;
      }
      if (this.post.endTime.length === 0) {
        this.dateTimeErrorMessage = "開始時刻をセットした場合、終了時刻は必須項目です";
        return;
      }

      if (startDateTime.diff(endDateTime) >= 0) {
        this.dateTimeErrorMessage =
          "開始日と終了日が同日である場合、終了時刻を開始時刻より前に設定できません";
        return;
      }
      if (endDateTime.isBefore(dayjs())) {
        this.dateTimeErrorMessage = "終了日時が現在時刻より前に設定されています";
        return;
      }
    },

    titleString(title: string = "タイトル"): string {
      if (!this.post.title) {
        return title + "未設定";
      } else {
        return this.post.title;
      }
    },

    termString(format: string = "M月D日"): string {
      const startDate = dayjs(this.post.start);
      const endDate = dayjs(this.post.end);
      if (startDate.isValid() || endDate.isValid()) {
        const startDateString = startDate.isValid() ? startDate.format(format) : "";
        const endDateString = endDate.isValid() ? endDate.format(format) : "";
        return `${startDateString} ${this.post.startTime} 〜 ${endDateString} ${this.post.endTime}`;
      } else {
        return "期間未設定";
      }
    },

    categoryString(): string {
      if (!this.post.category) {
        return "カテゴリ未設定";
      } else {
        return this.post.category;
      }
    },

    priceString(): string {
      if (!this.post.price) {
        return "";
      } else {
        return `￥${Number(this.post.price).toLocaleString()}`;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

.post-preview {
  max-width: 340px; // 実物大
  padding: 0;
}

.row {
  margin-top: 0 !important;
  margin-bottom: 0;
}

.preview-row {
  margin: 0;
  padding: 0 16px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 2px 3px #00000029;
  border-radius: 6px;
  opacity: 1;
}

.full-row {
  width: 308px;
}

.title-text {
  width: 308px;
  padding: 10px 12px 0 12px;
  font-size: 18px;
  line-height: 22px;
  overflow: hidden;
}

.detail-text {
  padding-top: 0;
  border: #707070;
}

.label-col {
  padding-right: 0;
}

.category-string {
  padding: 12px 10px 5px 10px;
}

.term {
  width: 308px;
  margin-bottom: 0;
  padding-left: 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
}

.title-row {
  padding: 5px 12px 0 12px;
}

.input-row {
  padding: 0 12px;
}

.input-col {
  padding: 0;
}

.button-col {
  padding: 0 4px 4px 4px;
}

.product-button {
  border: 1px solid;
  border-color: #dadce0;
  border-radius: 24px;
  height: 40px;
  margin: 5px 0 10px 20px;
}

.coupon {
  background-color: #f8f9fa;
  border: 2px dashed #dadce0;
  border-radius: 6px;
  margin: 12px;
  padding-bottom: 15px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 15px;
  text-align: center;
}

.coupon-detail {
  letter-spacing: 0.025em;
  // プレビューなのでプレビュー先のフォント指定を模している
  font-family: Roboto, Arial, sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  text-align: center;
}

.coupon-code {
  font-size: 24px;
  font-weight: bolder;
  line-height: 32px;
  text-align: center;
  word-break: break-all;
}

.couponRule {
  width: 308px;
  color: #5f6368;
  font-size: 11px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.product-row {
  display: block;
}

.detail-switch {
  height: 40px;
  justify-items: right;
}

:deep(.v-text-field input) {
  line-height: 21px;
}

.error-message {
  color: red;
  font-size: 0.75rem;
  white-space: pre-wrap;
}

.form-error-message {
  @extend .error-message;

  white-space: unset;
  padding-left: 1.5rem;
}

.v-icon {
  color: color.$primary;

  &:hover {
    color: #363636;
  }
}

.jump-url-button {
  margin-top: 20px;
  margin-left: -15px;
}

.v-row {
  margin: 0;
}

.phone-desc {
  font-size: 0.85rem;
  margin: 0 0 0 20px;
}
.text-indent-1 {
  text-indent: -1em;
}
</style>
