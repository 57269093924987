<template>
  <v-fade-transition @after-leave="remove">
    <v-card
      v-if="show"
      class="snackbar-message d-flex align-center mb-1"
      :color="SNACKBAR_COLORS[snackbarColor]"
    >
      <!-- eslint-disable-next-line -->
      <div class="ml-4 my-2" v-html="sanitize(message.text)"></div>
      <v-spacer></v-spacer>
      <v-btn variant="text" @click="remove()">
        <v-icon color="rgba(51, 51, 51, 0.3)">mdi mdi-close-circle mdi-24px</v-icon>
      </v-btn>
    </v-card>
  </v-fade-transition>
</template>
<script lang="ts">
import { Component, Vue, Prop, toNative } from "vue-facing-decorator";
import sanitizeHtml from "sanitize-html";
import type { SnackbarConfig, SnackbarToast } from "./snackbar-shared";
import { TOAST_DURATION } from "@/const";

@Component({ emits: ["remove", "mark"] })
class SnackbarMessage extends Vue {
  @Prop({ required: true }) message: SnackbarToast;
  activeTimeout = -1;
  readonly SNACKBAR_COLORS: { [color: string]: string } = {
    danger: "#ff2b56",
    info: "#209cee",
    success: "#23d160",
    warning: "#ffdd57",
    dark: "#363636",
    black: "#0a0a0a",
  };
  @Prop() snackbarConfig: SnackbarConfig;
  show = false;

  get snackbarColor(): string {
    return this.message?.color ?? this.SNACKBAR_COLORS.dark;
  }

  async mounted(): Promise<void> {
    if (this.message.marked) {
      // mark済みだったら表示せず削除
      this.remove();
    } else {
      this.show = true;
      // メッセージコンポーネントがマウントされた時点からカウント開始
      this.setTimeout();
    }
  }

  setTimeout(): void {
    window.clearTimeout(this.activeTimeout);
    const timeout = this.message?.timeout ?? TOAST_DURATION;
    // ブラウザリロード時ゴミが溜まらない様に一度表示済みマークをつける
    this.$emit("mark");
    // タイムアウト時間が設定されている場合は
    if (timeout) {
      // 指定時間後にremoveを呼び出す
      this.activeTimeout = window.setTimeout(() => {
        this.show = false;
      }, timeout);
    }
  }

  // 親コンポーネントにremoveを発火
  remove(): void {
    this.$emit("remove");
  }
  sanitize(text: string): string {
    return sanitizeHtml(text, {
      allowedTags: ["br"],
    });
  }
}
export default toNative(SnackbarMessage);
</script>
<style lang="scss" scoped>
.snackbar-message {
  border-radius: 24px !important;
  min-height: 48px;
  font-size: 15px;
  line-height: 160%;
}
</style>
