<template>
  <div class="dashboard">
    <!-- 共通ヘッダ -->
    <div class="fixed-header" :style="`width: calc(100% - ${headerCalcWidth}px)`">
      <div class="fixed-header-wrapper">
        <o-field position="is-right">
          <!-- ヘルスチェック -->
          <div class="health-check comparison-area">
            <span class="word-display">表示期間:</span>
            <date-range-picker v-model="range" />
            <button class="button is-primary" style="margin: 0 10px" @click="search">適用</button>
          </div>
        </o-field>
      </div>
    </div>
    <!-- ヘルスチェック -->
    <div class="text-h6 px-3">{{ dict.reportName }}</div>

    <v-card class="mt-5 pa-4">
      <v-row dense>
        <v-col cols="1">実行内容</v-col>
        <v-col cols="5">
          <v-autocomplete
            v-model="selectedUiTypes"
            :items="uiTypes"
            label="画面種別"
            density="compact"
            chips
            small-chips
            multiple
            single-line
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="selectedActionTypes"
            :items="actionTypes"
            label="実行内容"
            density="compact"
            chips
            small-chips
            multiple
            single-line
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="1">グループ</v-col>
        <v-col cols="10">
          <v-autocomplete
            v-model="selectedArea"
            :items="areas"
            density="compact"
            solo
            item-title="name"
            item-value="areaID"
          ></v-autocomplete>
        </v-col>
        <v-col cols="1">
          <v-btn @click.prevent="clearAreaSelection">
            <v-icon>mdi mdi-window-close mdi-24px</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="1">メイン担当者</v-col>
        <v-col cols="1">
          <v-checkbox v-model="isInCharge" class="mx-4" color="primary" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="1">属性更新</v-col>
        <v-col cols="11">
          <div class="checkboxes-wrapper">
            <v-checkbox
              v-for="gmbField in gmbFields"
              :key="gmbField.key"
              v-model="selectedGmbFields"
              :label="gmbField.title"
              :value="gmbField.key"
              class="mx-4"
              color="primary"
              density="compact"
            />
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="1"></v-col>
        <v-col cols="11">
          <v-btn class="primary" @click="search">検索</v-btn>
        </v-col>
      </v-row>
    </v-card>

    <div class="d-flex mt-8 mb-4">
      <v-alert
        v-show="hasMore"
        density="compact"
        :value="true"
        icon="mdi-information"
        class="elevation-1 mr-4"
      >
        ログ件数が50件を超えています。超過分はXLSXエクスポートしたファイルからご確認ください。
      </v-alert>
      <div class="ml-auto">
        <v-btn class="primary" @click="xlsxExport">XLSXエクスポート</v-btn>
      </div>
    </div>

    <v-data-table :headers="headers" :items="logItems" :items-per-page="50" class="elevation-1">
      <template #bottom />
    </v-data-table>

    <div v-if="loading" class="progress-circular-container">
      <v-progress-circular
        :size="80"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
