<template>
  <div>
    <!-- 画像追加ダイアログ -->
    <v-dialog v-model="imageDialog.show" width="677" persistent class="uploading-dialog">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>画像追加</v-card-title>
        <v-card-text>
          <o-upload
            v-if="!imageDialog.mediaFile"
            class="upload-part"
            :accept="allowedExtensions"
            drag-drop
            @update:model-value="loadFile"
          >
            <section class="section">
              <div class="content has-text-centered">
                <strong>
                  ここをクリックするか、
                  <br />
                  PNG/JPG/MOV/MP4をドラッグ＆ドロップして
                  <br />
                  画像登録
                </strong>
              </div>
            </section>
          </o-upload>
          <div v-else>
            <v-icon v-if="imageDialog.mediaFile.mediaFormat.name === 'VIDEO'" x-large>
              far fa-file-video
            </v-icon>
            <v-img v-else contain :src="imageDialog.mediaFile.src" />
            <span>{{ imageDialog.mediaFile.file.name }}</span>
            <span v-if="imageDialog.mediaFile.width && imageDialog.mediaFile.height">
              横{{ imageDialog.mediaFile.width }} x 縦{{ imageDialog.mediaFile.height }}
            </span>
          </div>
          <p v-if="imageError !== ''" class="img-file-error">
            {{ imageError }}
          </p>
          <v-select
            v-model="imageDialog.selectedCategory"
            :items="imageDialog.categoryInfos"
            item-title="title"
            item-value="category"
            label="画像のカテゴリ"
            style="width: 80%"
          />
          <div class="store-selector">
            <auto-complete-card
              v-model="imageDialog.selectedStores"
              :items="imageDialog.storeList"
              :disabled="allSelect"
              label="追加する店舗"
              unit="店舗"
              variant="filled"
              style="width: 80%"
            />
            <v-checkbox v-model="allSelect" class="all-select" hide-details color="primary">
              <template #label>
                <span class="text-black">全ての店舗</span>
              </template>
            </v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="imageDialog.show = false">キャンセル</v-btn>
          <v-btn
            color="primary"
            :disabled="
              imageError !== '' ||
              !imageDialog.selectedCategory ||
              (allSelect === false && imageDialog.selectedStores.length === 0)
            "
            @click="addImage"
          >
            追加
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 反映中のダイアログ (プログレスバーつき) -->
    <v-dialog v-model="applyDialog.show" width="500" persistent>
      <v-card class="reflection-dialog">
        <v-card-title class="headline grey lighten-2" primary-title>変更内容を反映中</v-card-title>
        <v-progress-linear v-model="applyDialog.percentage" color="light-blue"></v-progress-linear>
        <v-card-text>
          <div>{{ applyDialog.message }}</div>
          <ul
            v-if="applyDialog.errorMessages && applyDialog.errorMessages.length > 0"
            class="error-dialog"
          >
            <li v-for="(errorMes, index) in applyDialog.errorMessages" :key="index">
              <p class="error-head">{{ errorMes.message }}</p>
              <dl v-if="errorMes.action" class="error-action">
                <dt>操作</dt>
                <dd>
                  {{ errorMes.action }}
                </dd>
              </dl>
              <dl v-if="errorMes.store" class="error-store">
                <dt>店舗コード / 店舗名</dt>
                <dd>{{ errorMes.store }}</dd>
              </dl>
              <dl v-if="errorMes.status" class="error-status">
                <dt>エラーステータス</dt>
                <dd>{{ errorMes.status }}</dd>
              </dl>
              <dl v-if="errorMes.reasons" class="error-reason">
                <dt>原因</dt>
                <dd v-for="(reason, reasonIndex) in errorMes.reasons" :key="reasonIndex">
                  {{ reason }}
                </dd>
              </dl>
              <ul v-if="errorMes.solutions" class="error-solution">
                <li v-for="(solution, solIndex) in errorMes.solutions" :key="solIndex">
                  {{ solution }}
                </li>
              </ul>
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            variant="text"
            :disabled="!applyDialog.button"
            @click="applyDialog.show = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- XLSXインポートダイアログ -->
    <xlsx-import-dialog
      v-if="showXlsxImportDialog"
      ref="xlsxImportDialog"
      :files="[xlsxFile]"
      :stores="selectedAreaStores"
      :media-files="mediaFiles"
      :finish="importFileDialogFinish"
      :abort-import="abortImport"
      @get-target-store-data="getTargetStoreData"
    ></xlsx-import-dialog>

    <!-- グループ選択欄 -->
    <div class="d-flex align-center mb-2">
      <auto-complete-card
        v-if="!canShowAllowedStoresOnly"
        v-model="selectedAreaIDs"
        :items="areaItems"
        :disabled="isDirty"
        label="グループを選択して下さい"
        unit="グループ"
        show-all-select
        :chip-count="6"
        hint=""
        :persistent-hint="false"
        hide-details
        single-line
        density="compact"
        :clearable="false"
        data-testid="area-selector"
        class="me-1 w-25"
      />
      <v-btn
        v-if="!canShowAllowedStoresOnly"
        color="primary"
        size="small"
        :disabled="isDirty"
        data-testid="show-groups"
        @click="selectAreas()"
      >
        表示
      </v-btn>
      <v-text-field
        v-model="searchWord"
        :disabled="isNotOperationAllowed"
        label="検索キーワード"
        variant="underlined"
        density="compact"
        single-line
        hide-details
        clearable
        prepend-inner-icon="mdi-magnify"
        color="primary"
        style="width: 200px; max-width: 200px"
        @keypress.enter="selectAreas()"
        @click:clear="
          searchWord = '';
          selectAreas();
        "
      />
      <v-btn
        color="primary"
        class="filter-button mr-2"
        size="small"
        :disabled="isNotOperationAllowed"
        @click="selectAreas()"
      >
        絞り込み
      </v-btn>
      <v-btn
        v-if="canManage"
        class="primary mr-5"
        size="small"
        :disabled="isNotOperationAllowed || !isDirty"
        @click="selectAreas(true)"
      >
        変更内容を破棄
      </v-btn>
      <v-btn
        class="primary"
        size="small"
        :disabled="
          featureToggle.getStatus('companies_x_gmbmedia_histories') === 'STOP' ||
          isInactiveMediaHistories
        "
        tag="router-link"
        :to="{ name: 'GMBMediaHistories' }"
      >
        画像の更新履歴
      </v-btn>
    </div>
    <!-- ボタン類 -->
    <div class="d-flex align-center">
      <v-btn
        v-if="canManage"
        class="primary"
        size="small"
        :disabled="isNotOperationAllowed"
        @click="showImageDialog()"
      >
        画像追加
      </v-btn>
      <v-btn
        v-if="canManage"
        class="primary mr-5"
        size="small"
        :disabled="isNotOperationAllowed || !isDirty"
        @click="applyChanges"
      >
        反映
      </v-btn>
      <input ref="importInput" type="file" hidden @change="importFile" />
      <v-btn
        v-if="canManage"
        class="primary"
        size="small"
        :disabled="isNotOperationAllowed || isLoading > 0"
        @click="($refs.importInput as HTMLInputElement).click()"
      >
        XLSXインポート
      </v-btn>
      <v-btn
        class="primary"
        size="small"
        :disabled="isNotOperationAllowed || isLoading > 0"
        @click="loadBatchOfStores"
      >
        XLSXエクスポート
      </v-btn>
      <ToolTipIcon
        :label="'「XLSXエクスポート」はすぐに出力され、画像の一括追加に使えます。「XLSX全件クスポート」は選択中の全グループ、または検索キーワードで絞り込まれた全ての店舗の画像データを読み込んでXLSXファイルを出力するため時間はかかりますが、画像の一括削除にも使えます。'"
      />
      <span v-if="selectedAreaStores.length > 0" class="ml-4">
        <span v-if="isLoading === 3">
          インポート対象読み込み中
          {{ Math.floor((numOfLoadedStores / targetStores.length) * 100) }}％完了
        </span>
        <span v-else-if="isLoading === 2">
          全件読み込み中
          {{ Math.floor((numOfLoadedStores / selectedAreaStores.length) * 100) }}％完了
        </span>
        <span v-else-if="isLoading === 1">読み込み中</span>
        全{{ selectedAreaStores.length }}件
        <span v-if="applyDialog.show" class="upload-progression">
          未反映{{ uploadProgression }}件
        </span>
      </span>
    </div>
    <v-pagination
      v-model="page"
      :disabled="isLoading > 0 ? true : false"
      :length="displayedStores.length"
      density="compact"
      size="default"
      class="mb-1"
      @update:model-value="getStorePics('normal')"
    />
    <!-- 画像一覧表 -->
    <div v-if="displayedStores.length !== 0" style="height: calc(100vh - 230px)" class="table">
      <table class="imagetable">
        <thead>
          <tr>
            <th>店舗ID</th>
            <th style="min-width: 105px">店舗コード</th>
            <th>店舗名</th>
            <th colspan="100%">画像</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="store of displayedStores[page - 1]" :key="store.poiId">
            <td>
              {{ store.poiId }}
            </td>
            <td>
              <div>{{ store.storeCode }}</div>
            </td>
            <td>
              <div style="width: 150px">{{ store.storeName }}</div>
            </td>
            <!-- 画像表示セル 読込中 -->
            <td v-if="store.isLoading === true">読み込み中</td>
            <!-- 画像表示セル start -->
            <td v-else-if="store.columns.length === 0">画像が登録されておりません</td>
            <td v-else class="image-columns">
              <div
                v-for="column of store.columns"
                :key="column.key"
                :class="column.manipulation === 'NONE' ? '' : 'dirty'"
              >
                <v-select
                  v-if="getCategoryInfo(column.category).canChangeFrom && canManage"
                  v-model="column.category"
                  :items="column.getCanChangeToCategoryInfos()"
                  item-title="title"
                  item-value="category"
                  dense
                  solo
                  @update:model-value="changeCategory(column)"
                />
                <span v-else>
                  {{ getCategoryInfo(column.category).title }}
                </span>
                <span v-if="canManage">
                  <v-checkbox
                    v-if="column.manipulation !== 'ADD_IMAGE'"
                    v-model="column.deleteCheckbox"
                    label="削除"
                    dense
                    color="primary"
                    @change="toggleDelete(store, column)"
                  ></v-checkbox>
                  <v-btn v-else @click="toggleDelete(store, column)">追加をやめる</v-btn>
                </span>
                <v-icon
                  v-if="column.mediaFile && column.mediaFile.mediaFormat.name === 'VIDEO'"
                  x-large
                >
                  far fa-file-video
                </v-icon>
                <v-img
                  v-if="column.src"
                  contain
                  max-height="200"
                  max-width="300"
                  :src="column.src"
                />
              </div>
            </td>
            <!-- 画像表示セル end -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import type { AxiosError } from "axios";
import pLimit from "p-limit";
import { requiredAuth } from "@/helpers";
import type {
  EntitiesGbpResponseMybusinessListMediaItemsResponse,
  EntitiesStore,
  EntitiesStoresResponse,
  MybusinessListMediaItemsResponse,
  MybusinessMediaItem,
  StorageGMBMediaPostResponse,
} from "@/types/ls-api";
type EntitiesStores = EntitiesStore[];
import { CategoryInfo, MediaFile, allowedExtensions } from "./MediaColumn";
import { type FeatureToggle } from "@/routes/FeatureToggle";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import wordDictionary from "@/word-dictionary";
import dayjs from "dayjs";
import XlsxImportDialog from "./XlsxImportDialog.vue";
import { getOperationLogParams } from "@/routes/operation-log";
import { TOAST_CRITICAL_DURATION } from "@/const";
type MediaItem = MybusinessMediaItem;
export type Manipulation = "NONE" | "CHANGE_CATEGORY" | "ADD_IMAGE" | "DELETE";
import { useSnackbar } from "@/storepinia/snackbar";
import { useIndexedDb } from "@/storepinia/idxdb";
import { makeSelectItems, type SelectItem } from "@/helpers/select-items";
import AutoCompleteCard from "@/components/shared/auto-complete-card/AutoCompleteCard.vue";
import { parseGMBErrorDetails, getErrorMessage, retry } from "./error";
import { type GmbErrorDetails, GbpResponseError } from "./error";
import { api } from "@/helpers/api/gmb-media";

/** GBPErrorか否かとステータスコード */
type ErrorWithGBP = {
  errorCode: number;
  isGBPError: boolean;
};

// 反映エラーダイアログに出す内容
type ReflectionErrorMessage = {
  message: string;
  action: string;
  status: string;
  reasons?: string[];
  store: string;
  solutions?: string[];
};

type LoadedStore = {
  loaded: boolean;
  updated: boolean;
  loadedMediaItems?: MediaColumn[];
};
type LoadedStores = {
  [key: string]: LoadedStore;
};
type OneErrorMessage = {
  isDiscontinued: boolean;
  reflectionErrorMessage: ReflectionErrorMessage;
};

type MediaItemsArr = {
  result: MybusinessListMediaItemsResponse;
  oneStoreData: StoreWithImages;
};

type PickingMode = "all" | "import" | "normal";

export class MediaColumn {
  static counter: number = 0;
  key: number;
  manipulation: Manipulation;
  category: string;
  deleteCheckbox: boolean = false;
  src: string;
  mediaFile: MediaFile | null;
  mediaItem: MediaItem | null;
  static ofMediaFile(mf: MediaFile, category: string): MediaColumn {
    const ci = CategoryInfo.of(category);
    if (!ci) {
      return null;
    }
    const mc = new MediaColumn();
    mc.key = MediaColumn.counter++;
    mc.manipulation = "ADD_IMAGE";
    mc.category = category;
    mc.src = mf.src;
    mc.mediaFile = mf;
    return mc;
  }
  static ofMediaItem(mi: MediaItem): MediaColumn {
    const ci = CategoryInfo.of(mi.locationAssociation.category);
    if (!ci) {
      return null;
    }
    const mc = new MediaColumn();
    mc.key = MediaColumn.counter++;
    mc.manipulation = "NONE";
    mc.category = ci.category;
    // PROFILEの場合サムネイルが404になるので変わりにgoogleUrlを用いる
    mc.src = mc.category == "PROFILE" ? mi.googleUrl : mi.thumbnailUrl;
    mc.mediaItem = mi;
    return mc;
  }
  getCanChangeToCategoryInfos(): CategoryInfo[] {
    const initialValue = this.getInitialCategory();
    if (initialValue) {
      const ini = CategoryInfo.of(initialValue);
      if (!ini.canChangeFrom) {
        return [ini];
      }
    }
    return CategoryInfo.values.filter((ci) => ci.canChangeTo || initialValue === ci.category);
  }
  getInitialCategory(): string {
    return this.mediaItem?.locationAssociation?.category ?? this.category;
  }
  applyChangeCategory(): void {
    const categoryInfo = CategoryInfo.of(this.category);
    if (this.mediaItem) {
      const initialCategory = this.getInitialCategory();
      if (initialCategory === categoryInfo.category) {
        this.manipulation = "NONE";
      } else {
        this.manipulation = "CHANGE_CATEGORY";
      }
    }
    this.deleteCheckbox = false;
  }
  applyDeleteCheckbox(): void {
    if (this.deleteCheckbox) {
      this.manipulation = "DELETE";
    } else {
      this.applyChangeCategory();
    }
  }
  getThumbnail(): string {
    if (this.mediaFile) {
      return this.mediaFile.file.name;
    }
    return this.mediaItem.thumbnailUrl;
  }
}
export class StoreWithImages {
  poiId: number;
  storeCode: string;
  storeName: string;
  gmbName: string;
  columns: MediaColumn[];
  isLoading: boolean;
  static of(store: EntitiesStore): StoreWithImages {
    const s = new StoreWithImages();
    s.poiId = store.poiID;
    s.storeCode = store.gmbStoreCode;
    s.storeName = store.name;
    s.gmbName = store.gmbLocationID;
    s.columns = [];
    s.isLoading = null;
    return s;
  }

  toString(): string {
    return this.storeName;
  }
}

// コンポーネント定義
export default defineComponent({
  components: { XlsxImportDialog, AutoCompleteCard },
  props: {
    poiGroupId: { type: String, required: true, default: "0" },
  },
  data: () => {
    return {
      featureToggle: {} as FeatureToggle,
      imageDialog: {
        show: false,
        mediaFile: null as MediaFile,
        storeList: [] as SelectItem[], // 店舗一覧(オートコンプリート用、重複あり)
        selectedStores: [] as number[],
        categoryInfos: [] as CategoryInfo[], // カテゴリプルダウン用
        selectedCategory: null as string,
      },
      applyDialog: {
        show: false,
        percentage: 0,
        message: "",
        errorMessages: [] as ReflectionErrorMessage[],
        button: false,
      },
      isDirty: false,
      areaItems: [] as SelectItem[],
      getCategoryInfo: CategoryInfo.of,
      stores: [] as EntitiesStores,
      selectedAreaIDs: [] as number[],
      mediaFiles: [] as MediaFile[],
      selectedAreaStores: [] as StoreWithImages[], // 店舗一覧(テーブル用、重複なし)
      locationMediaCache: {} as { [name: string]: MediaItem[] },
      showXlsxImportDialog: false,
      xlsxFile: null as File,
      isLoading: 0,
      // 現在のページ
      page: 1,
      // 1ページに何件表示するか
      pageSize: 10,
      // ページ毎に分割した店舗一覧データ
      displayedStores: [] as StoreWithImages[][],
      // 店舗毎読み込み済みフラグ
      loadedStores: {} as LoadedStores,
      // 読み込み済み店舗数
      numOfLoadedStores: 0,
      allLoadTImer: null as NodeJS.Timeout,
      // vueインスタンスが破棄される際に店舗画像中断させる為のフラグ
      isDestroyed: false,
      // 未反映件数
      uploadProgression: 0,
      // 画像追加の全店舗選択チェックボックス
      allSelect: false,
      searchWord: "",
      // 追加しようとした画像が壊れているか、またはファイルサイズが上限下限を超えている場合のエラーメッセージ
      imageError: "",
      // XLSXインポート対象のストア数
      targetStores: [] as StoreWithImages[],
      // 店舗権限ユーザかどうか
      canShowAllowedStoresOnly: useIndexedDb().canShowAllowedStoresOnly,
      // 履歴画面制御
      isInactiveMediaHistories: useIndexedDb().isInactiveMediaHistories,
      // 操作権限があるかどうか
      canManage: useIndexedDb().canManageMedia,
    };
  },
  computed: {
    selectedStoresCount(): number {
      return Array.from(new Set(this.imageDialog.selectedStores)).length;
    },
    isNotOperationAllowed(): boolean {
      if (this.canShowAllowedStoresOnly) {
        return this.displayedStores?.length === 0;
      }
      return this.selectedAreaIDs.length === 0;
    },
    allowedExtensions(): string {
      return allowedExtensions;
    },
  },
  beforeUnmount: function () {
    this.isDestroyed = true;
    // XLSX全件ダウンロードチェックタイマー止める
    clearInterval(this.allLoadTImer);
  },
  async created() {
    this.featureToggle = this.$route.meta.featureToggle as FeatureToggle;
    // グループプルダウン作成
    ({ areas: this.areaItems } = makeSelectItems(
      useIndexedDb().areaStores,
      useIndexedDb().stores?.stores,
      false
    ));
    // 店舗一覧取得
    // Vuexに既にstores保持していたらそちらを使う
    let storeData: EntitiesStore[];
    if (useIndexedDb().stores?.stores) {
      storeData = useIndexedDb().stores.stores;
    } else {
      const storesres = await requiredAuth<EntitiesStoresResponse>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/stores`
      );
      storeData = storesres.data.stores;
    }
    await api.gmbapiinit(Number(this.poiGroupId)); // GBPアクセストークンの初期化
    this.stores = (storeData ?? []).filter((s) => s.enabled);
    // 店舗権限の場合は全店舗を初期表示する
    if (this.canShowAllowedStoresOnly) {
      this.selectAreas();
    }
  },
  methods: {
    async selectAreas(destruction: boolean = false) {
      if (this.selectedAreaIDs.length === 0 && !this.canShowAllowedStoresOnly) {
        // グループ指定しないで「表示」クリックするとそのままではSentryエラーが出るのを抑止
        return;
      }
      this.imageDialog.storeList = [];
      this.selectedAreaStores = [];
      this.isDirty = false;
      const displayedStores: StoreWithImages[] = [];
      // ページリセットする
      this.page = 1;
      // 選択グループ変更読み込み済み店舗フラグ一旦降ろす
      if (destruction === true) {
        // 「変更内容を破棄」の場合
        Object.keys(this.loadedStores).forEach((label: string): void => {
          this.loadedStores[label].loaded = false;
          // 未確定の変更があるかチェック
          if (this.loadedStores[label].loadedMediaItems) {
            for (const mediaColumn of this.loadedStores[label].loadedMediaItems) {
              if (
                mediaColumn.manipulation !== "NONE" &&
                this.loadedStores[label]?.loadedMediaItems
              ) {
                // 変更が生じていたらキャッシュ消す
                delete this.loadedStores[label].loadedMediaItems;
                break;
              }
            }
          }
        });
      } else {
        // 「表示」(グループの変更)の場合
        Object.keys(this.loadedStores).forEach((label: string): void => {
          this.loadedStores[label].loaded = false;
        });
      }

      for (const store of this.stores) {
        const canUse =
          this.selectedAreaIDs.filter((id) => (store.areas ?? []).includes(id)).length !== 0;
        // 店舗参照権限ではエリア参照できないので全表示のみ、それ以外はエリアに紐付く店舗のみ
        if (canUse || this.canShowAllowedStoresOnly) {
          const storeWithImage: StoreWithImages = StoreWithImages.of(store);
          if (this.checkStoreMatching(storeWithImage) === true) {
            this.selectedAreaStores.push(storeWithImage);
            displayedStores.push(storeWithImage);
            if (!this.loadedStores[store.poiID]) {
              this.loadedStores[store.poiID] = { loaded: false, updated: false };
            }
          }
        }
      }
      // 画像追加ダイアログの店舗プルダウンアイテムを作成
      if (this.searchWord || this.canShowAllowedStoresOnly) {
        // 検索キーワード絞り込みしてるor店舗ユーザの場合はグループ名見出し無しの画像追加リストを生成する
        this.imageDialog.storeList = this.selectedAreaStores.map((s) => {
          return { isHeader: false, id: s.poiId, title: s.storeName };
        });
      } else {
        // 選択したグループの店舗だけを表示する
        const areas = useIndexedDb().areaStores.filter((a) =>
          this.selectedAreaIDs.includes(a.areaID)
        );
        ({ stores: this.imageDialog.storeList } = makeSelectItems(areas, this.stores, false));
        // グループが一つだけなら、グループ行を除去する
        if (this.imageDialog.storeList.filter((s) => s.isHeader).length === 1) {
          this.imageDialog.storeList = this.imageDialog.storeList.filter((s) => !s.isHeader);
        }
      }
      if (displayedStores.length > 0) {
        // pageSize単位でストア格納する
        this.displayedStores = this.divide(displayedStores, this.pageSize);
        await this.getStorePics("normal");
      }
    },

    /** 店舗毎に accounts.locations.media.list を実行して、サムネイルを表示していく */
    async getStorePics(mode: PickingMode, importTargets?: StoreWithImages[][]): Promise<void> {
      let storeData: StoreWithImages[];
      this.numOfLoadedStores = 0;
      if (mode === "all") {
        // XLSX全件エクスポートクリックの場合
        this.isLoading = 2;
        for (storeData of this.displayedStores) {
          await this.pickStores(storeData, mode);
        }
      } else if (mode === "import") {
        // XLSXインポートで必要な分を抜粋の場合
        this.isLoading = 3;
        for (storeData of importTargets) {
          await this.pickStores(storeData, mode);
        }
      } else {
        // 通常の店舗データロード
        this.isLoading = 1;
        storeData = this.displayedStores[this.page - 1];
        await this.pickStores(storeData, mode);
      }
    },
    /** データロードする必要がある店舗の選別をする */
    async pickStores(storeData: StoreWithImages[], mode: PickingMode): Promise<void> {
      try {
        // 一旦ページ内全部の店舗を「読み込み中」にする
        for (const s of storeData) {
          s.isLoading = true;
        }
        const tasks: (() => Promise<MediaItemsArr>)[] = [];
        for (const s of storeData) {
          const loadedOne = this.loadedStores[s.poiId];
          if (loadedOne.loaded === true) {
            // ロード済みでグループ変更もされてないならisLoadingフラグを下ろすだけで何もしない
            s.isLoading = false;
          } else if (loadedOne.loadedMediaItems) {
            // 別のグループ選択するも既にキャッシュ済みならそれを使う
            s.columns = loadedOne.loadedMediaItems;
            s.isLoading = false;
            this.loadedStores[s.poiId].loaded = true;
            this.numOfLoadedStores++;
          } else {
            if (loadedOne.updated === true) {
              // 画像アップロード反映後ページ遷移して戻ってきたときに重複しない様にカラムのをリセットする
              s.columns.length = 0;
            }
            // キャッシュが無いものは普通にAPI叩いて取りに行く
            tasks.push(() => this.getMediaItems(s, ""));
          }
        }
        await this.getPromisedItems(mode, tasks);
      } catch (error) {
        console.log("[pickStores ERROR]", error);
      }
    },

    /** listMediaItem の実行と、そこで発生したエラーの処理をまとめた関数 */
    async getMediaItems(store: StoreWithImages, pageToken: string): Promise<MediaItemsArr> {
      let response: EntitiesGbpResponseMybusinessListMediaItemsResponse = null;
      try {
        response = await retry(() =>
          api.listMediaItem(Number(this.poiGroupId), store.poiId, 2500, pageToken)
        );
      } catch (e: any) {
        console.error("[getMediaItems] ERROR", e);
        if (axios.isAxiosError(e)) {
          throw e; // AxiosError はそのまま throw する
        }
        response = (e as GbpResponseError).res; // GbpResponseError に含まれている GbpResponse を取り出す
      }
      const statusCode = response.statusCode;
      if (statusCode === 200) {
        return { result: response.data, oneStoreData: store };
      }
      const errorText = getGbpErrorMessage(statusCode);
      useSnackbar().addSnackbarMessages({
        text: errorText,
        color: "danger",
        timeout: TOAST_CRITICAL_DURATION,
      });
      return null;
    },
    /** 1ページ分の店舗画像MediaItem取得し終わった際の処理 */
    async getPromisedItems(
      mode: PickingMode,
      tasks: (() => Promise<MediaItemsArr>)[]
    ): Promise<void> {
      try {
        // 同時実行数を 5 に制限しつつ、すべての店舗のMediaItem取得を実行する
        const limit = pLimit(5);
        const ps = tasks.map((fn) => limit(fn));
        const messages = await Promise.all(ps);
        if (this.isDestroyed === true) {
          // 店舗画像の一括更新ページから離脱していたら中止する
          return;
        }
        // 読み込み未完了フラグ
        let incomplete = false;
        for (const message of messages) {
          if (!message) continue; // エラーが出ていた場合は null なのでスキップ
          const store: StoreWithImages = message.oneStoreData;
          for (const mediaItem of message.result.mediaItems ?? []) {
            const mc = MediaColumn.ofMediaItem(mediaItem);
            if (!mc) {
              continue;
            }
            // src が重複していなければ、店舗に画像を追加する
            if (!store.columns.some((column) => mc.src === column.src)) {
              store.columns.push(mc);
            }
          }

          // カバーとロゴを先頭に持っていく
          store.columns.sort((a, b) => {
            return CategoryInfo.compare(a.category, b.category);
          });

          const pageToken = message.result.nextPageToken;
          if (pageToken) {
            // 店舗画像続き(nextPageToken)あったらそれを読みに行く
            // pageSize: 2500 設定ならば、実運用上はまずこの分岐は発生しないと思われる

            // 読み込み未完了フラグ立てる
            incomplete = true;
            this.getPromisedItems(mode, [() => this.getMediaItems(store, pageToken)]);
          } else {
            store.isLoading = false;
            // 既に読み込んだ画像をもう一度読み込みに行かない様にフラグ立てる
            this.loadedStores[store.poiId].loaded = true;
            // ロード済み店舗カウントアップ
            this.numOfLoadedStores++;
            this.loadedStores[store.poiId].loadedMediaItems = Object.assign([], store.columns);
          }
        }

        if (incomplete === false && this.isLoading === 1) {
          // 単ページ読み込み(isLoading = 1)ならばここでローディング状態は解除
          this.isLoading = 0;
        }
      } catch (e: any) {
        console.error("[getPromisedItems ERROR]", e);
        useSnackbar().addSnackbarMessages({
          text: "しばらく時間を置いた後、もう一度お試しください。",
          color: "danger",
          timeout: TOAST_CRITICAL_DURATION,
        });
      }
    },

    /** 指定されたサイズごとに配列を分割する */
    divide<T>(array: T[], size: number): T[][] {
      if (size < 1) {
        size = 1;
      }
      const dividedArray: T[][] = [];
      while (array.length) {
        dividedArray.push(array.splice(0, size));
      }
      return dividedArray;
    },
    showImageDialog() {
      this.imageError = "";
      this.imageDialog.mediaFile = null;
      this.imageDialog.categoryInfos = [];
      this.imageDialog.selectedCategory = null;
      this.imageDialog.selectedStores = [];
      this.imageDialog.show = true;
    },
    async loadFile(file: File) {
      this.imageError = "";
      // 新しいファイルならばメディアファイル一覧に追加する
      const newmf = await MediaFile.of(file);
      if (newmf.imageError !== "") {
        // 画像破損だったらエラー表示出す
        this.imageError = newmf.imageError;
        return;
      }
      let mf = this.mediaFiles.find((mf) => mf.equals(newmf));
      if (!mf) {
        this.mediaFiles.push(newmf);
        mf = newmf;
      }
      this.imageDialog.mediaFile = mf;
      this.imageDialog.categoryInfos = mf.getMakeableCategoryInfos();
    },
    addImage() {
      this.imageDialog.show = false;
      // すべての店舗のチェックがついていた場合は、全店舗のidを用いる
      const selectedStores = this.allSelect
        ? this.selectedAreaStores.map((s) => s.poiId)
        : this.imageDialog.selectedStores;

      const ci = CategoryInfo.of(this.imageDialog.selectedCategory);
      for (const id of selectedStores) {
        const store = this.selectedAreaStores.find((s) => s.poiId === id);
        const newCol = MediaColumn.ofMediaFile(this.imageDialog.mediaFile, ci.category);
        if (ci.canDuplicate) {
          store.columns.unshift(newCol);
        } else {
          for (let i = store.columns.length - 1; 0 <= i; i--) {
            const col = store.columns[i];
            if (col.category === ci.category) {
              col.deleteCheckbox = true;
              this.toggleDelete(store, col);
            }
          }
          store.columns.unshift(newCol);
        }

        store.columns.sort((a, b) => {
          return CategoryInfo.compare(a.category, b.category);
        });
        if (this.loadedStores[store.poiId]?.loadedMediaItems) {
          // 正しく追加を反映させる為に、一度読み込んだフラグ(loaded)は立たせつつ、キャッシュ削除しておく
          delete this.loadedStores[store.poiId].loadedMediaItems;
        }
        this.loadedStores[store.poiId].updated = false;
      }
      this.updateIsDirty();
    },
    storeCheckBoxIcon() {
      if (this.imageDialog.selectedStores.length === this.selectedAreaStores.length)
        return "mdi-close-box";
      if (this.imageDialog.selectedStores.length > 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    filterAreaStores: (item: StoreWithImages, queryText: string, itemText: string) => {
      const storeName = item?.storeName?.toLocaleLowerCase();
      const searchText = queryText?.toLocaleLowerCase();
      return storeName?.includes(searchText);
    },
    toggleDelete(s: StoreWithImages, c: MediaColumn) {
      if (c.manipulation === "ADD_IMAGE") {
        // 追加した画像であれば表から削除する
        const index = s.columns.findIndex((col) => col.key === c.key);
        s.columns.splice(index, 1);
      } else {
        // 既存の画像であればカラムに変更を適用する
        c.applyDeleteCheckbox();
        // 変更されたのが重複できないタイプで、削除フラグをOFFにする操作ならば、その他の同じタイプのものを削除する
        if (CategoryInfo.of(c.category).canDuplicate === false && c.deleteCheckbox === false) {
          for (let i = s.columns.length - 1; 0 <= i; i--) {
            const col = s.columns[i];
            if (col.key !== c.key && col.category === c.category) {
              s.columns.splice(i, 1);
            }
          }
        }
      }
      this.updateIsDirty();
    },
    changeCategory(c: MediaColumn) {
      c.applyChangeCategory();
      this.updateIsDirty();
    },
    updateIsDirty() {
      for (const s of this.selectedAreaStores) {
        for (const c of s.columns) {
          if (c.manipulation !== "NONE") {
            this.isDirty = true;
            return;
          }
        }
      }
      this.isDirty = false;
    },
    async applyChanges() {
      this.applyDialog.show = true;
      this.applyDialog.percentage = 0;
      this.applyDialog.button = false;
      this.applyDialog.errorMessages.length = 0;

      const uploadedImages: { [key: string]: string } = {};
      const uploads: [StoreWithImages, MediaColumn][] = [];
      const uploadErrorMessages: ReflectionErrorMessage[] = [];

      for (const store of this.selectedAreaStores) {
        for (const column of store.columns) {
          if (column.manipulation !== "NONE") {
            uploads.push([store, column]);
          }
        }
      }
      const poiGroupId = Number(this.poiGroupId);
      let count = 0;
      this.uploadProgression = uploads.length;
      for (const [row, c] of uploads) {
        if (this.selectedAreaStores.length === 0) {
          return;
        }

        this.applyDialog.message = `${row.storeName} を更新中`;
        await this.$nextTick();

        const index = row.columns.findIndex((col) => col.key === c.key);
        try {
          // カテゴリの変更
          if (c.manipulation === "CHANGE_CATEGORY") {
            const data: MediaItem = {
              name: c.mediaItem.name,
              locationAssociation: { category: c.category },
            };
            const oplog = getOperationLogParams(this.$route, "image-put");
            const res = await retry(() => api.patchMediaItem(poiGroupId, row.poiId, data, oplog));
            row.columns.splice(index, 1, MediaColumn.ofMediaItem(res.data));
            // キャッシュの内容を更新
            this.loadedStores[row.poiId].loadedMediaItems = row.columns;
          }
          // 画像の追加
          else if (c.manipulation === "ADD_IMAGE") {
            // アップロードしていない画像をアップロードする
            if (!uploadedImages[c.mediaFile.key]) {
              const supres = await requiredAuth<StorageGMBMediaPostResponse>(
                "post",
                `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/startUpload`,
                getOperationLogParams(this.$route, "image-put")
              );
              uploadedImages[c.mediaFile.key] = supres.data.getUrl;
              const putUrl = supres.data.putUrl;
              await axios.put(putUrl, await c.mediaFile.load(), {
                headers: { "Content-Type": c.mediaFile.file.type },
              });
            }
            // 画像をGMBに登録する
            const getUrl = uploadedImages[c.mediaFile.key];
            const data: MediaItem = {
              mediaFormat: c.mediaFile.mediaFormat.name,
              sourceUrl: getUrl,
              locationAssociation: { category: c.category },
            };
            const oplog = getOperationLogParams(this.$route, "image-put");
            const res = await retry(() => api.putMediaItem(poiGroupId, row.poiId, data, oplog));
            row.columns.splice(index, 1, MediaColumn.ofMediaItem(res.data));
          }
          // 画像をGMBから削除する
          else if (c.manipulation === "DELETE") {
            const oplog = getOperationLogParams(this.$route, "image-put");
            const _ = await retry(() =>
              api.deleteMediaItem(poiGroupId, row.poiId, c.mediaItem.name, oplog)
            );
            row.columns.splice(index, 1);
            // キャッシュの内容を更新
            this.loadedStores[row.poiId].loadedMediaItems = row.columns;
          }
          // 反映終わったら該当店舗に"updated"フラグを立てておく
          this.loadedStores[row.poiId].updated = true;
        } catch (e) {
          // retry は AxiosError または GbpResponseError を投げる。GbpResponseError の場合は AxiosError に変換してエラーメッセージを生成する。
          // これは既存のエラーメッセージ生成関数をそのまま使うことで、既存と同じ形式でエラーメッセージを生成するため。
          console.error(`[${c.manipulation} Error]`, e);
          let ex: AxiosError = null;
          if (axios.isAxiosError(e)) {
            ex = e as AxiosError;
          } else if (e instanceof GbpResponseError) {
            ex = (e as GbpResponseError).toAxiosError();
          }
          const uploadErrorMessage: OneErrorMessage = this.makeErrorMessage(
            ex,
            c,
            row
          ) as OneErrorMessage;
          uploadErrorMessages.push(uploadErrorMessage.reflectionErrorMessage);
          if (uploadErrorMessage.isDiscontinued === true) {
            // アップロード上限に達してる429エラーの場合はここで終了
            this.applyDialog.message = "";
            this.applyDialog.errorMessages = uploadErrorMessages;
            this.applyDialog.button = true;
            return;
          }
        }

        // プログレスバーを進捗させる
        count++;
        this.applyDialog.percentage = (count / uploads.length) * 100;
        this.uploadProgression = uploads.length - count + uploadErrorMessages.length;
        await this.$nextTick();
      }
      this.updateIsDirty();
      if (uploadErrorMessages.length > 0) {
        // エラーがあった場合はエラーダイアログを出す
        this.applyDialog.message = "";
        this.applyDialog.errorMessages = uploadErrorMessages;
      } else {
        this.applyDialog.message = "完了しました";
      }
      this.applyDialog.button = true;
    },

    /** アップロードエラーのダイアログの中身生成 */
    makeErrorMessage(
      e: AxiosError,
      column?: MediaColumn,
      row?: StoreWithImages
    ): OneErrorMessage | ErrorWithGBP {
      // trueだったら反映時のダイアログ生成用 falseはget時のエラーメッセージ生成用
      const forReflection = column && row ? true : false;
      let action: string;
      if (forReflection) {
        action = `${column.manipulation}, ${column.category}, ${column.getThumbnail()}`;
      }

      if (forReflection && e?.response?.data == null) {
        // ネットワークエラーとかでdataが得られなかった場合
        return {
          isDiscontinued: true,
          reflectionErrorMessage: {
            message: "Google Business Profileへの反映に失敗しました。",
            action,
            status: "unknown",
            store: `${row.storeCode} / ${row.storeName}`,
            solutions: ["アップロードは中断されました。"],
          },
        };
      }

      // エラー情報をパース
      const errorInfo = parseGMBErrorDetails(e);

      if (!forReflection) {
        const errorWithGBP: ErrorWithGBP = {
          errorCode: errorInfo.errorCode,
          isGBPError: errorInfo.isGBPError,
        };
        return errorWithGBP;
      }

      this.updateIsDirty();
      // エラーメッセージを生成
      const errorMessages = getErrorMessage(errorInfo);
      let errorMessage: string = "";
      if (
        errorInfo.isRateLimitExceeded ||
        errorInfo.isGBPErrorAndErrorCodeIs400 ||
        errorInfo.isGBPErrorAndErrorCodeIs500
      ) {
        errorMessage = `${column.getThumbnail()}の反映に失敗しました。`;
      } else {
        errorMessage = "システムエラーが発生しました。";
      }
      return {
        isDiscontinued: errorMessages.isDiscontinued,
        reflectionErrorMessage: {
          message: errorMessage,
          action,
          status: errorInfo.status,
          reasons: errorMessages.reasons,
          store: `${row.storeCode}, ${row.storeName}`,
          solutions: [errorMessages.solution],
        },
      };
    },

    /** XLSX全件エクスポートやインポート時に対象ストアをまとめてロードする */
    async loadBatchOfStores(
      e: PointerEventInit | null,
      dividedStores?: StoreWithImages[][]
    ): Promise<void> {
      if (this.isLoading > 0) {
        // ボタン二度押し防止
        return;
      }
      let isImportMode: boolean;
      if (e === null && dividedStores) {
        isImportMode = true;
        useSnackbar().addSnackbarMessages({
          text: "インポート対象の全てのデータを取得しています。<br>そのまましばらくお待ちください。",
          color: "info",
          timeout: 2500,
        });
        await this.getStorePics("import", dividedStores);
      } else {
        isImportMode = false;
        useSnackbar().addSnackbarMessages({
          text: "選択したグループの全てのデータを取得しています。<br>そのまましばらくお待ちください。",
          color: "info",
          timeout: 2500,
        });
        await this.getStorePics("all");
      }
      const incomplete = this.checkCompletion(isImportMode);
      /** 一店舗に画像がたくさん登録されていて、分割でmediaItem読み込みが発生していた場合、その分が読み込み完了になるまでチェックし続ける必要がある
       * pageSize: 2500 設定ならば実運用上ではそのケースはほぼ発生しないと思われる
       */
      if (incomplete === true) {
        this.allLoadTImer = setInterval(() => {
          this.checkCompletion(isImportMode);
        }, 1000);
      }
    },
    checkCompletion(isImportMode: boolean): boolean {
      let incomplete = false;
      const stores = isImportMode ? this.targetStores : this.selectedAreaStores;
      if (!stores) {
        return;
      }
      for (const store of stores) {
        if (!this.loadedStores[store.poiId].loaded) {
          // 一個でも未完あったら走査終了
          incomplete = true;
          break;
        }
      }
      if (incomplete === false) {
        // 全データダウンロード出来たらexportする
        this.isLoading = 0;
        clearInterval(this.allLoadTImer);
        if (isImportMode) {
          // インポートモードだったらXlsxImportDialogのimportFileを叩く
          (this.$refs.xlsxImportDialog as InstanceType<typeof XlsxImportDialog>)?.importFile();
        } else {
          this.exportFile();
        }
      }
      return incomplete;
    },

    async exportFile() {
      const aoa = []; // Array of arrays
      // ヘッダ行を追加する
      const maxImageCount = this.selectedAreaStores.reduce((prev, curr) => {
        return Math.max(prev, curr.columns.length);
      }, 0);
      const columns: string[] = ["店舗ID", "店舗コード", "ビジネス名"];
      for (let i = 0; i < maxImageCount; i++) {
        columns.push("");
      }
      aoa.push(columns);
      // 店舗ごとの行を追加する
      for (const s of this.selectedAreaStores) {
        const arr = [];
        arr.push(s.poiId);
        arr.push(s.storeCode);
        arr.push(s.storeName);
        for (const column of s.columns) {
          if (column.manipulation === "DELETE") {
            arr.push("");
          } else if (column.mediaItem) {
            arr.push(`${column.category}: ${column.mediaItem.thumbnailUrl}`);
          } else {
            arr.push(`${column.category}: ${column.mediaFile.file.name}`);
          }
        }
        aoa.push(arr);
      }
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(aoa);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const buf: ArrayBuffer = XLSX.write(wb, {
        type: "array",
        bookType: "xlsx",
        bookSST: true,
      });
      const companyName = useIndexedDb().company.name;
      const datetime = dayjs().format("YYYYMMDD");
      const filename = `${wordDictionary.service.name}-店舗画像一覧エクスポート-${companyName}-${datetime}.xlsx`;
      saveAs(new Blob([buf], { type: "application/octet-stream" }), filename);
    },
    async importFile(e) {
      const file: File = e.target.files[0];
      if (!file) {
        return;
      }
      e.target.value = "";
      this.xlsxFile = file;
      this.showXlsxImportDialog = true;
    },
    abortImport(): void {
      clearInterval(this.allLoadTImer);
      this.showXlsxImportDialog = false;
      this.targetStores = null;
      this.isLoading = 0;
    },
    async importFileDialogFinish(
      deleteCols: { poiId: number; key: number }[],
      deleteCancelCols: { poiId: number; key: number }[],
      changeCols: { poiId: number; key: number; category: string }[],
      addCols: { poiId: number; category: string; thumbnail: string; mediaFile: MediaFile }[]
    ) {
      this.showXlsxImportDialog = false;

      // インポート内容を反映していく
      const find = (poiId: number, key: number): [StoreWithImages, MediaColumn] => {
        const store = this.selectedAreaStores.find((s) => s.poiId === poiId);
        if (!store) {
          return [null, null];
        }
        const col = store.columns.find((c) => c.key === key);
        return [store, col];
      };
      for (const deleteCol of deleteCols) {
        const [store, col] = find(deleteCol.poiId, deleteCol.key);
        col.deleteCheckbox = true;
        this.toggleDelete(store, col);
      }
      for (const changeCol of changeCols) {
        const [_, col] = find(changeCol.poiId, changeCol.key);
        col.category = changeCol.category;
        this.changeCategory(col);
      }
      for (const deleteCancelCol of deleteCancelCols) {
        const [store, col] = find(deleteCancelCol.poiId, deleteCancelCol.key);
        col.deleteCheckbox = false;
        this.toggleDelete(store, col);
      }
      for (const addCol of addCols) {
        await this.loadFile(addCol.mediaFile.file);
        this.imageDialog.selectedStores = [addCol.poiId];
        this.imageDialog.selectedCategory = addCol.category;
        this.addImage();
      }
      // 役目が終わったのでnullにする
      this.targetStores = null;
    },
    /** 検索キーワードにマッチするか */
    checkStoreMatching(store: StoreWithImages): boolean {
      if (!this.searchWord || this.searchWord === "") {
        return true;
      }
      this.searchWord = this.searchWord?.trim();
      const searchWord = this.searchWord?.toLowerCase();
      // 店舗名でマッチするか
      if (store.storeName?.toLowerCase().includes(searchWord)) {
        return true;
      }
      // 企業IDでマッチするか
      if (this.searchWord === store.poiId.toString()) {
        return true;
      }
      // 店舗コードでマッチするか
      if (store.storeCode?.toLowerCase().includes(searchWord)) {
        return true;
      }

      return false;
    },
    /** XLSXインポート対象のストアデータを取得する */
    async getTargetStoreData(targetStoreIds: number[]): Promise<void> {
      const targetStores: StoreWithImages[] = [];
      for (const poiID of targetStoreIds) {
        const store = this.selectedAreaStores.find((store) => poiID === store.poiId);
        if (store) {
          targetStores.push(store);
        }
      }
      if (targetStores.length === 0) {
        // インポート対象の店舗が存在しなかったら
        (
          this.$refs.xlsxImportDialog as InstanceType<typeof XlsxImportDialog>
        ).changeLoadingStatus();
      } else {
        // pageSize単位でストア格納する
        // divideすると参照が無くなるのでcheckCompletionで使う様にコピーしとく
        this.targetStores = Object.assign([], targetStores);
        const dividedStores = this.divide(targetStores, this.pageSize);
        this.loadBatchOfStores(null, dividedStores);
      }
    },
    isValidJsonForGmbErrorDetails(jsonString: string): boolean {
      try {
        const _: GmbErrorDetails = JSON.parse(jsonString);
        return true; // パース成功
      } catch (error) {
        return false; // パース失敗
      }
    },
  },
});

function getGbpErrorMessage(statusCode: number): string {
  if (statusCode === 429) {
    return `アクセス試行回数が上限に達しました。<br>少し時間を置いてリロードして下さい。<br>エラーコード[${statusCode}]`;
  } else if (statusCode >= 400 && statusCode < 500) {
    return `システムエラーが発生しました。<br>少し時間を置いてリロードして下さい。<br>エラーコード[${statusCode}]`;
  } else if (statusCode >= 500) {
    return `ただいまGoogle側のサービスが一時的に利用できません。<br>少し時間を置いてリロードして下さい。<br>エラーコード[${statusCode}]`;
  } else {
    return `システムエラーが発生しました。<br>少し時間を置いてリロードして下さい。<br>エラーコード[${statusCode}]`;
  }
}
</script>

<style lang="scss" scoped>
:deep(.v-btn__content) {
  color: inherit;
}

button,
.history-button {
  box-shadow: none;
}

.upload-progression {
  margin-left: 1em;
}

.media-edit-header {
  display: flex;
  align-items: flex-end;
}

.area-selector {
  max-width: 600px;
  margin-right: 10px;
}

:deep(.v-select.v-select--chips .v-select__selections) {
  min-height: 0;
}

.searchbox {
  max-width: 300px;
  margin-left: 20px;
  margin-right: 10px;

  &.v-input--is-disabled {
    background-color: #d7d8d9;
  }
}

:deep(.v-text-field.v-input--dense:not(.v-text-field--outlined) input) {
  padding-bottom: 5px;
}
.filter-button {
  &.button.is-primary[disabled] {
    color: rgba(0, 0, 0, 0.26);
    background-color: #d7d8d9;
    opacity: 1;
    cursor: auto;

    &:hover {
      color: rgba(0, 0, 0, 0.26);
      background-color: #d7d8d9 !important;
    }
  }
}
.head-buttons {
  margin-top: 24px;
  display: flex;
}

.change-cancel-button {
  margin-left: 10px;
  margin-right: 20px;
}

.buttons {
  margin-top: 0.5rem;

  button {
    margin-right: 10px;

    &.reflection-button {
      margin-right: 20px;
    }
  }
}

.store-selector {
  display: flex;
}

.all-select {
  margin-left: 10px;
}

.scroll {
  overflow-y: scroll;
  height: 250px;
}

.table {
  overflow-x: scroll;
  overflow-y: scroll;
  margin: 0;
}

.v-card {
  box-shadow: none;
}

.dirty {
  background: #aff;
}

.imagetable {
  border-collapse: collapse;
  border: 1px 0 1px 1px solid #ccc;

  thead th {
    position: sticky;
    top: 0;
    background-color: #f0f0f0;
    z-index: var(--z-index-loading);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-collapse: collapse;
      border: 1px solid #ccc;
    }
  }

  thead th:nth-child(1),
  thead th:nth-child(2),
  thead th:nth-child(3) {
    /* 行内の他のセルより手前に表示する */
    z-index: calc(var(--z-index-loading) + 1);
  }

  th:nth-child(1),
  th:nth-child(2),
  th:nth-child(3),
  td:nth-child(1),
  td:nth-child(2),
  td:nth-child(3) {
    /* 横スクロール時に固定する */
    position: sticky;
    background-color: #f0f0f0;
    z-index: var(--z-index-loading);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-collapse: collapse;
      border: 1px solid #ccc;
    }
  }

  th:nth-child(1),
  td:nth-child(1) {
    min-width: 90px;
    max-width: 90px;
    left: 0;
  }

  th:nth-child(2),
  td:nth-child(2) {
    min-width: 120px;
    max-width: 120px;
    left: 90px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 150px;
    left: 210px;
  }

  td {
    background-color: #fff;
  }
}

.image-columns {
  display: flex;
  gap: 10px;

  & > div {
    width: 300px;
    padding: 10px;

    .theme--light.v-image {
      padding: 10px;
    }
  }
}

.v-pagination {
  margin-bottom: 20px;
  padding: 0;
}

:deep() {
  .v-pagination {
    button {
      box-shadow: none;
    }
  }
}

:deep(.v-pagination__item--active) {
  cursor: auto;
}

:deep(.reflection-dialog) {
  .error-dialog {
    height: calc(75vh - 48px);
    overflow-y: auto;

    & > li {
      border-bottom: 1px dashed;
      padding-bottom: 1em;
      margin-bottom: 1em;

      &:last-of-type {
        border-bottom-style: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    .error-head {
      margin: 0 0 1em 0;
    }

    dl {
      margin-bottom: 1em;
    }

    dt {
      font-weight: bold;
    }

    .error-solution {
      padding: 0;
      margin: 0;
    }
  }

  .v-card__actions > .v-btn.v-btn {
    margin-left: auto;
  }
}

.uploading-dialog {
  line-height: 160%;
}

:deep(.upload .upload-draggable) {
  border-width: 2px;
  background-color: #eee;
  margin-top: 30px;

  strong {
    display: block;
    margin-bottom: 60px;
    line-height: 160%;
    font-size: 1.2em;
  }

  p {
    margin: 0 0 10px 0;
  }

  .section {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.acceptable-size-list {
  margin: 1em 0 0 0;

  p {
    display: inline-block;
    margin: 0 0 0.5em 0;
    padding-bottom: 2px;
    border-bottom: 4px solid #cdcdcd;
  }

  dl {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 190px auto 1fr;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  dt {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  dd {
    margin-bottom: 10px;
    margin-left: 0;

    &:nth-of-type(2n - 1) {
      &::after {
        content: "〜";
        margin: 0 0.25em;
      }
    }
  }
}

.img-file-error {
  color: red;
  margin-top: 1em;
}

.invalid-image-size {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
// Invalid aspect ratio. Got: 800x781 (1.024328), valid ratio 1.777778.
