import { Component, Prop, Vue, toNative } from "vue-facing-decorator";
import Contents from "./contents";
import Sidebar from "./sidebar";
import Topbar from "./topbar";
import { checkIfApplicationUpdated } from "@/helpers/version-check";
import wordDictionary from "@/word-dictionary";

@Component({
    components: { Contents, Topbar, Sidebar },
})
class Root extends Vue {
    @Prop notActive: boolean;
    dict = wordDictionary.service;
    snackbar = false;

    async mounted(): Promise<void> {
        // 5分間隔でアプリケーション更新チエック
        await this.checkUpdate();
        setInterval(this.checkUpdate, 5 * 60 * 1000);
    }

    private async checkUpdate() {
        try {
            const updated = await checkIfApplicationUpdated();
            if (updated === false) {
                return;
            }
            this.snackbar = true;
        } catch (e) {
            console.warn("[checkUpdate] Error", e);
        }
    }
}
export default toNative(Root);
