<template>
  <div class="modal-card" style="width: 1000px">
    <header class="modal-card-head">
      <p class="modal-card-title">返信に失敗したクチコミがあります</p>
    </header>
    <section class="modal-card-body">
      <p>
        {{ replyErrorMessage }}
      </p>
      <o-table v-if="reviews.length > 0" :data="reviews" sticky-header scrollable>
        <o-table-column
          v-for="(column, idx) in columns"
          :key="idx"
          v-slot="{ row }"
          v-bind="column"
        >
          {{ row[column.field] }}
        </o-table-column>
      </o-table>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click.stop="$emit('close')">閉じる</button>
    </footer>
  </div>
</template>
<script lang="ts">
import { getter } from "@/storepinia/idxdb";
import type { EntitiesBigQueryRawReview, StorageReplyFailedReview } from "@/types/ls-api";
import { Component, Vue, Prop, toNative } from "vue-facing-decorator";

interface DisplayFormatReview {
  updateTime: string;
  storeName: string;
  reviewerDisplayName: string;
  comment: string;
  reason: string;
}

@Component
export class ModalReplyError extends Vue {
  stores = getter().stores;

  @Prop({ type: Array }) failedReviews: StorageReplyFailedReview[];
  @Prop({ type: String }) replyErrorMessage;
  name = "reply";
  replyMessage = "";
  validationError = false;

  reviewPostTime(time: string): string {
    const d = new Date(time);
    const utcDate = new Date(
      Date.UTC(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        d.getHours(),
        d.getMinutes(),
        d.getSeconds()
      )
    );
    return utcDate.toLocaleString();
  }

  get reviews(): DisplayFormatReview[] {
    return this.failedReviews.map((x) => {
      return {
        updateTime: this.reviewPostTime(x.review.updateTime.toString()),
        storeName: this.getStoreName(x.review),
        reviewerDisplayName: x.review.reviewerDisplayName,
        comment: x.review.comment,
        reason: x.reason,
      } as DisplayFormatReview;
    });
  }

  getStoreName(review: EntitiesBigQueryRawReview): string {
    if (review.isRival) {
      return "競合店";
    } else {
      return this.stores.stores.find((s) => s.poiID === review.poiID)?.name;
    }
  }

  get columns(): { field: string; label: string }[] {
    return [
      {
        field: "updateTime",
        label: "最終更新時刻",
      },
      {
        field: "storeName",
        label: "店舗",
      },
      {
        field: "reviewerDisplayName",
        label: "投稿者",
      },
      {
        field: "comment",
        label: "内容",
      },
      {
        field: "reason",
        label: "状態",
      },
    ];
  }
}
export default toNative(ModalReplyError);
</script>
