import type { EntitiesV2YahooPostData, EntitiesYahooFeedType } from "@/types/ls-api";

export interface YahooPostTypeDict {
    id: EntitiesYahooFeedType;
    name: string;
}

// 投稿種別のマスタ
export const YahooPostTypes = [
    { id: "NOTICE", name: "お知らせ" },
    { id: "CAMPAIGN", name: "キャンペーン" },
    { id: "DAYOFF", name: "営業状況" },
    { id: "ETC", name: "その他" },
] as YahooPostTypeDict[];

export class YahooPostForm {
    postType: EntitiesYahooFeedType;
    title: string;
    description: string;
    pinnedStartDate: string;
    pinnedStartTime: string;
    pinnedEndDate: string;
    pinnedEndTime: string;

    constructor(
        postType: EntitiesYahooFeedType = "NOTICE",
        title: string = "",
        description: string = "",
        pinnedStartDate: string = "",
        pinnedStartTime: string = "",
        pinnedEndDate: string = "",
        pinnedEndTime: string = ""
    ) {
        this.postType = postType;
        this.title = title;
        this.description = description;
        this.pinnedStartDate = pinnedStartDate;
        this.pinnedStartTime = pinnedStartTime;
        this.pinnedEndDate = pinnedEndDate;
        this.pinnedEndTime = pinnedEndTime;
    }

    convertFrom(postData: EntitiesV2YahooPostData) {
        this.postType = postData.feedType;
        this.title = postData.title;
        this.description = postData.text;
        this.pinnedStartDate = postData.pinnedStartDate;
        this.pinnedStartTime = postData.pinnedStartTime;
        this.pinnedEndDate = postData.pinnedEndDate;
        this.pinnedEndTime = postData.pinnedEndTime;
    }

    convertTo(): EntitiesV2YahooPostData {
        return {
            feedType: this.postType,
            title: this.title,
            text: this.description,
            pinnedStartDate: this.pinnedStartDate,
            pinnedStartTime: this.pinnedStartTime,
            pinnedEndDate: this.pinnedEndDate,
            pinnedEndTime: this.pinnedEndTime,
        };
    }
}
