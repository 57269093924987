<template>
  <div>
    <!-- ヘッダ部分 -->
    <v-card>
      <v-row class="my-1 mx-1">
        <v-col>
          <div class="text-h6 font-weight-bold">権限管理</div>
        </v-col>
        <v-spacer />
        <v-col align="end">
          <v-btn color="primary" variant="outlined" text="権限作成" @click="roleCreate">
            <template #prepend>
              <v-icon color="primary">fas fa-plus</v-icon>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <!-- リスト部分 -->
    <div class="table_box mt-3 mr-1">
      <table>
        <tr v-for="(row, index) in data" :key="index">
          <th :class="index === 'name' ? 'sticky-cross' : 'sticky'">
            {{ labels[index] }}
          </th>
          <template v-for="(value, rowIndex) in row">
            <!-- 権限名 -->
            <td
              v-if="index === 'name' && typeof value === 'object'"
              :key="`name-cell-${index}-${rowIndex}`"
              class="name-cell"
            >
              <div class="d-flex justify-space-between align-center">
                <div class="role-name">{{ value.name }}</div>
                <v-menu
                  v-model="value.menuOverlay"
                  :close-on-content-click="false"
                  location="bottom end"
                >
                  <template #activator="{ props }">
                    <button class="menu-button" v-bind="props">
                      <font-awesome-icon :icon="['fas', 'ellipsis-v']" />
                    </button>
                  </template>
                  <v-sheet>
                    <v-btn :disabled="!value.editOK" @click.prevent="roleEdit(value.roleLv)">
                      <v-icon>fas fa-edit</v-icon>
                    </v-btn>
                    <v-btn :disabled="!value.copyOK" @click.prevent="roleCopy(value.roleLv)">
                      <v-icon>far fa-copy</v-icon>
                    </v-btn>
                    <v-btn
                      :disabled="!value.editOK"
                      color="#E95454"
                      @click.prevent="showDialog(value)"
                    >
                      <v-icon>far fa-trash-alt</v-icon>
                    </v-btn>
                  </v-sheet>
                </v-menu>
              </div>
            </td>
            <!-- 説明 -->
            <td
              v-else-if="index === 'description'"
              :key="`description-cell-${index}-${rowIndex}`"
              class="description-cell"
            >
              <div :class="value === noDescription ? 'description no-description' : 'description'">
                {{ value }}
              </div>
              <!-- 長文はクリックで表示 -->
              <v-overlay
                v-if="value != null"
                activator="parent"
                location-strategy="connected"
                scroll-strategy="block"
              >
                <v-card class="pa-2" width="300px">
                  <v-card-text class="description-details">{{ value }}</v-card-text>
                </v-card>
              </v-overlay>
            </td>
            <!-- 各権限の付与状況 -->
            <td
              v-else-if="isDefinedInPermissionType(value)"
              :key="`status-cell-${index}-${rowIndex}`"
              :class="getIconClass(value)"
            >
              <font-awesome-icon :icon="getIconName(value)" size="lg" />
              <p class="permission-message">{{ getIconMessage(value) }}</p>
            </td>
            <!-- その他 -->
            <td v-else :key="`${index}-${rowIndex}`">{{ value }}</td>
          </template>
        </tr>
      </table>
      <v-dialog v-model="showConfirmDialog" width="auto">
        <v-card>
          <v-card-title>権限の削除</v-card-title>
          <v-card-text>
            <p>{{ confirmedRole.name }} を削除しますか？</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn variant="outlined" @click.prevent="showConfirmDialog = false">キャンセル</v-btn>
            <v-btn color="primary" variant="flat" @click.prevent="roleDelete">削除</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-overlay :model-value="isLoading" persistent class="align-center justify-center">
      <v-progress-circular color="primary" indeterminate size="64" />
    </v-overlay>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { api } from "@/helpers/api/roles";
import { convertDateTime } from "@/helpers/date";
import { getOperationLogParams } from "@/routes/operation-log";
import { useSnackbar } from "@/storepinia/snackbar";
import { useIndexedDb } from "@/storepinia/idxdb";
import { PermissionType, ViewRange } from "./utils";

import type { EntitiesPermissionCategory } from "@/types/ls-api.d";

type RoleName = {
  name: string;
  roleLv: number;
  menuOverlay: boolean;
  copyOK: boolean;
  editOK: boolean;
};

type TableData = { [key: string]: string[] | PermissionType[] | RoleName[] };

export default defineComponent({
  data: () => ({
    isLoading: false as boolean,
    permissionCategories: [] as EntitiesPermissionCategory[],
    data: {} as TableData,
    labels: {
      name: "権限名",
      description: "説明",
      createdAt: "登録日時",
      viewRange: "アクセス可能範囲",
    },
    showConfirmDialog: false as boolean,
    confirmedRole: {} as RoleName,
    userViewRange: useIndexedDb().userViewRange,
    setRoles: useIndexedDb().setRoles,
    roles: useIndexedDb().roleList,
    noDescription: "説明なし",
  }),
  computed: {
    poiGroupID: function (): number {
      return parseInt(this.$route.params.poiGroupId as string);
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchPermissionCategories();
    await this.fetchData();
  },
  methods: {
    addErrorMessage(message: string) {
      useSnackbar().addSnackbarMessages({
        text: message,
        color: "danger",
      });
    },
    async fetchPermissionCategories() {
      await api
        .getPermissionCategories(this.poiGroupID)
        .then((res) => {
          this.permissionCategories = res.data;
          // labelをマスタから自動設定する
          this.permissionCategories.forEach((category) => {
            this.labels[category.categoryName] = category.displayName;
          });
        })
        .catch((ex) => {
          console.error(ex);
          this.addErrorMessage("権限の取得に失敗しました");
        });
    },
    async fetchData() {
      // indexedDBを更新することで最新の権限情報を取得します
      this.isLoading = true;
      await this.setRoles(this.poiGroupID)
        .then(() => {
          this.formatData();
        })
        .catch((ex) => {
          console.error(ex);
          this.addErrorMessage("権限一覧の取得に失敗しました");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    formatData() {
      const formattedData: TableData = {};

      // formattedDataのキーを初期化します。各キーの値は空の配列です
      Object.keys(this.labels).forEach((key) => {
        formattedData[key] = [];
      });

      this.roles.forEach((role) => {
        // viewRangeによって表示する権限を制限します(オーナー以下しか表示しない)
        if (role.viewRange < 3) return;

        const formattedRole = {
          name: {
            name: role.caption,
            roleLv: role.roleLv,
            menuOverlay: false,
            copyOK: role.copyOK,
            editOK: role.poiGroupID == 0 || this.userViewRange > role.viewRange ? false : true,
          },
          description: role.description || this.noDescription,
          createdAt: convertDateTime(role.createdAt),
          viewRange: ViewRange[role.viewRange as keyof typeof ViewRange],
        };

        this.permissionCategories.forEach((permission) => {
          const categoryName = permission.categoryName;
          // formattedRoleの各権限に対応する値を設定します
          formattedRole[categoryName] = this.getPermissionStatus(categoryName, role.functions);
        });

        // formattedRoleの各キーの値を、formattedDataの対応する配列に追加します
        Object.keys(formattedRole).forEach((key) => {
          formattedData[key].push(formattedRole[key]);
        });
      });
      this.data = formattedData;
    },
    // permissionCategoriesから権限の状態を取得
    getPermissionStatus(category: string, functions: string[]): PermissionType {
      const targetCategory = this.permissionCategories.find((c) => c.categoryName === category);
      if (targetCategory) {
        // permission(string[])がtargetCategory.permissions(functionsを持つオブジェクト)のリストの合致するものがあるか調べる
        const permission = targetCategory.permissions.find((p) =>
          p.functions.every((f) => functions.includes(f))
        );
        if (permission) {
          return PermissionType[permission.permissionType as keyof typeof PermissionType];
        }
      }
      return PermissionType.Unknown;
    },
    isDefinedInPermissionType(value: any): value is PermissionType {
      return Object.values(PermissionType).includes(value);
    },
    getIconClass(value: PermissionType) {
      switch (value) {
        case PermissionType.CRUDAllowed:
          return "all-allowed";
        case PermissionType.CRUDWithApprovalRequest:
          return "with-approval";
        case PermissionType.CRUDWithApproval:
          return "with-approval";
        case PermissionType.ReadOnly:
          return "read-only";
        case PermissionType.AllDenied:
          return "all-denied";
        default:
          return "all-denied";
      }
    },
    getIconName(value: PermissionType) {
      switch (value) {
        case PermissionType.CRUDAllowed:
        case PermissionType.CRUDWithApprovalRequest:
        case PermissionType.CRUDWithApproval:
          return ["fas", "fa-check"];
        case PermissionType.ReadOnly:
          return ["fas", "fa-eye"];
        case PermissionType.AllDenied:
          return ["fas", "fa-xmark"];
        default:
          return ["fas", "fa-xmark"];
      }
    },
    getIconMessage(value: PermissionType) {
      switch (value) {
        case PermissionType.CRUDWithApprovalRequest:
          return "承認要求権限あり";
        case PermissionType.CRUDWithApproval:
          return "承認権限あり";
        case PermissionType.ReadOnly:
          return "閲覧のみ可";
        case PermissionType.Unknown:
          return "権限の更新が必要です";
        default:
          return "";
      }
    },
    roleCreate() {
      this.$router.push({
        name: "CustomRolesNew",
        params: { poiGroupId: this.poiGroupID.toString() },
      });
    },
    roleEdit(roleLv: number) {
      this.$router.push({
        name: "CustomRolesEdit",
        params: {
          poiGroupId: this.poiGroupID.toString(),
          roleLv: roleLv.toString(),
        },
      });
    },
    async roleCopy(roleLv: number) {
      this.isLoading = true;
      await api
        .copyRole(this.poiGroupID, roleLv, getOperationLogParams(this.$route, "copy"))
        .then((res) => {
          if (res != "") {
            this.addErrorMessage(res);
          } else {
            this.fetchData();
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showDialog(value: RoleName) {
      this.confirmedRole = value;
      this.showConfirmDialog = true;
    },
    async roleDelete() {
      this.showConfirmDialog = false;
      this.isLoading = true;
      await api
        .deleteRole(
          this.poiGroupID,
          this.confirmedRole.roleLv,
          getOperationLogParams(this.$route, "delete")
        )
        .then((res) => {
          if (res != "") {
            this.addErrorMessage(res);
          } else {
            this.fetchData();
            useSnackbar().addSnackbarMessages({
              text: `${this.confirmedRole.name} を削除しました`,
              color: "success",
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
</script>
<style lang="scss" scoped>
.table_box {
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  max-height: 90vh;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
.table-base {
  vertical-align: middle;
  border: 0.5px solid #b3b3b3;
  white-space: nowrap;
}
td {
  @extend .table-base;
  font-size: 0.8rem;
  padding: 0.5rem;
  min-width: 180px;
  max-width: 180px;
}

.sticky {
  @extend .table-base;
  font-size: 0.9rem;
  padding: 1rem;
  position: sticky;
  max-width: 200px;
  top: 0;
  left: 0;
  background: none;
  border-left: none;
  border-right: none;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-left: 0.5px solid #b3b3b3;
    border-right: 0.5px solid #b3b3b3;
    background: #fff;
    z-index: -1;
  }
}

.sticky-cross {
  @extend .sticky;
  position: sticky;
  top: 0;
  left: 0;
  background: none;
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: none;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0.5px solid #b3b3b3;
    background: #fff;
    z-index: -1;
  }
}

.name-cell {
  padding: unset;
  padding-left: 0.75rem;
  justify-content: space-between !important;
  font-size: 0.9rem;
  position: sticky;
  top: 0;
  left: 0;
  background: none;
  border-top: none;
  border-bottom: none;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: 0.5px solid #b3b3b3;
    border-bottom: 0.5px solid #b3b3b3;
    background: #fff;
    z-index: -1;
  }

  .role-name {
    white-space: normal;
    padding-right: 0.25rem;
  }
}
.menu-button {
  padding: 0.95rem 0.5rem;
  border: none;
  border-radius: 0;
  background: #fafafa;

  :deep(.v-icon) {
    padding: 0.25rem;
    z-index: inherit;
  }
}

.description-cell {
  padding: 0.25rem;
  .description {
    font-size: 0.75rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
  }
  .no-description {
    color: #aaaaaa;
  }
}
.description-details {
  padding: 0.25rem;
}

.icon-base {
  text-align: center !important;
}
.all-allowed {
  @extend .icon-base;
  color: #73c92d;
  background: linear-gradient(0deg, rgba(115, 201, 45, 0.05), rgba(115, 201, 45, 0.05));
}
.with-approval {
  @extend .all-allowed;
  color: #333333;
  padding: unset;
}
.read-only {
  @extend .icon-base;
  color: #6893b0;
  background: linear-gradient(0deg, rgba(104, 147, 176, 0.05), rgba(104, 147, 176, 0.05));
  padding: unset;
}
.all-denied {
  @extend .icon-base;
  color: #e95454;
  background: linear-gradient(0deg, rgba(233, 84, 84, 0.05), rgba(233, 84, 84, 0.05));
}
.permission-message {
  font-size: 0.75rem;
}
</style>
