import { getThumbnails } from "video-metadata-thumbnails";
import type { IOption } from "video-metadata-thumbnails/lib/video/ioption";
import type { IThumbnail } from "video-metadata-thumbnails/lib/video/ithumbnail";

/**
 * ビデオファイルからサムネイルを取得する
 * @param file ビデオファイル
 * @returns サムネイル画像のURL
 **/
export async function getVideoThumbnail(file: File): Promise<string> {
    const ioOption = {
        quality: 0.6,
        end: 0.1,
    } as IOption;
    // video-metadata-thumbnails の getThumbnails は iOS で動作しないため iOS の場合はサムネイルを取得しない
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        return "";
    }
    const thumbnails = await getThumbnails(file, ioOption).catch(() => {
        // エラー例: details: DEMUXER_ERROR_NO_SUPPORTED_STREAMS: FFmpegDemuxer: no supported streams
        return [{ blob: null }] as IThumbnail[];
    });
    // 上記エラー以外にもthumbnails[0]がundefinedだったり、blobがnullで返ってくる場合がある
    if (thumbnails[0]?.blob != null) {
        return URL.createObjectURL(thumbnails[0].blob);
    }
    return "";
}

/**
 * プレビューできない動画のサムネイル用のダミー画像を取得する
 * @param text 画像に表示する文言
 * @param width ダミー画像の幅, デフォルト200
 * @param height ダミー画像の高さ, デフォルト200
 * @returns ダミー画像のURL
 */
export function getDummyImageUrl(text: string, width: number = 200, height: number = 200): string {
    // キャンバスのコンテキスト取得
    const cvs = document.createElement("canvas");
    cvs.setAttribute("width", width.toString());
    cvs.setAttribute("height", height.toString());
    const ctx = cvs.getContext("2d");
    ctx.save();
    // キャンバスを塗りつぶし
    ctx.fillStyle = "#000000";
    ctx.fillRect(0, 0, width, height);
    ctx.restore();
    // 文字列を描画
    const fontSize = 19;
    ctx.font = fontSize + "px serif";
    ctx.fillStyle = "#FFFFFF";
    ctx.textAlign = "center";
    const x = cvs.width / 2;
    let y = cvs.height / 5;
    // 改行を考慮
    text?.split("\n").forEach((line) => {
        ctx.fillText(line, x, y);
        y += fontSize * 1.5;
    });
    return cvs.toDataURL();
}
