import { Component, Vue, Prop, toNative } from "vue-facing-decorator";

@Component({})
class ToolTipIcon extends Vue {
    @Prop({ type: String, default: "" }) label: string;
    @Prop({ type: Boolean, default: true }) multilined: boolean;
    @Prop({ type: String, default: "dark" }) variant: string;
    @Prop({ type: String, default: "right" }) position: string;
    @Prop({ type: String, default: "small" }) size: string;
    @Prop({ type: String, default: "24px" }) iconSize: string;
}
export default toNative(ToolTipIcon);
