import { Component, Vue, toNative } from "vue-facing-decorator";
import { captureAndThrow } from "@/helpers/error";
import type {
    EntitiesArea,
    EntitiesAreasResponse,
    StorageHealthCheckItemResponse,
    StorageHealthCheckDownloadResponse,
} from "@/types/ls-api";
import DateRangePicker from "@/components/shared/date-picker/DateRangePicker.vue";
import { requiredAuth } from "@/helpers";
import { headerCalcWidth } from "@/helpers/header";
import wordDictionary from "@/word-dictionary";
import { getter, useIndexedDb } from "@/storepinia/idxdb";
import { useSs } from "@/storepinia/ss";
import type { VDataTable } from "vuetify/components/index.mjs";

type Header = Pick<VDataTable["headers"][number], "key" | "title" | "align" | "width">;
const columnWidth = 100;
@Component({ components: { DateRangePicker } })
class HealthCheckLog extends Vue {
    company = getter().company;
    range = {
        from: useSs().healthCheckAggregationStartDate,
        to: useSs().healthCheckAggregationEndDate,
    };

    gmbFields = [
        { title: "住所", key: "gmbLocationAddress" },
        { title: "ブランド", key: "gmbLocationBrand" },
        { title: "ビジネス情報", key: "gmbLocationBusinessInfo" },
        { title: "収容人数", key: "gmbLocationCapacity" },
        { title: "カテゴリー", key: "gmbLocationCategory" },
        { title: "サービス", key: "gmbLocationServiceItems" },
        { title: "通貨", key: "gmbLocationCurrency" },
        { title: "ビジネス名", key: "gmbLocationName" },
        { title: "営業時間", key: "gmbLocationOpenInfo" },
        { title: "支払い方法", key: "gmbLocationPayment" },
        { title: "電話番号", key: "gmbLocationPhone" },
        { title: "価格帯", key: "gmbLocationPriceRange" },
        { title: "提供地域", key: "gmbLocationServiceArea" },
        { title: "喫煙有無", key: "gmbLocationSmoking" },
        { title: "場所ごとの営業時間", key: "gmbLocationSpecialOpenInfo" },
        { title: "ウェブサイト", key: "gmbLocationWebsite" },
    ];

    selectedGmbFields: string[] = [];

    get headers(): Header[] {
        return [
            { title: "企業ID", key: "poiGroupId" },
            { title: "グループID", key: "areaId" },
            { title: "店舗ID", key: "poiId" },
            { title: "日時", key: "date", width: columnWidth * 2 },
            { title: "実行時識別子", key: "requestId", width: columnWidth * 2 },
            { title: "画面識別子", key: "uiType" },
            { title: "実行内容", key: "actionType" },
            { title: "処理結果", key: "state" },
            { title: "HTTPレスポンスコード", key: "httpCode" },
            { title: "メイン担当者", key: "isInCharges" },
            { title: "操作者識別子", key: "operatorId", width: columnWidth * 2 },
            { title: "住所 更新", key: "gmbLocationAddress" },
            { title: "ブランド 更新", key: "gmbLocationBrand" },
            { title: "ビジネス情報 更新", key: "gmbLocationBusinessInfo" },
            { title: "収容人数 更新", key: "gmbLocationCapacity" },
            { title: "カテゴリー 更新", key: "gmbLocationCategory" },
            { title: "サービス 更新", key: "gmbLocationServiceItems" },
            { title: "通貨 更新", key: "gmbLocationCurrency" },
            { title: "ビジネス名 更新", key: "gmbLocationName" },
            { title: "営業時間 更新", key: "gmbLocationOpenInfo" },
            { title: "支払い方法 更新", key: "gmbLocationPayment" },
            { title: "電話番号 更新", key: "gmbLocationPhone" },
            { title: "価格帯 更新", key: "gmbLocationPriceRange" },
            { title: "提供地域 更新", key: "gmbLocationServiceArea" },
            { title: "喫煙有無 更新", key: "gmbLocationSmoking" },
            { title: "場所ごとの営業時間 更新", key: "gmbLocationSpecialOpenInfo" },
            { title: "ウェブサイト 更新", key: "gmbLocationWebsite" },
            { title: "新規で変更提案を検出", key: "gmbLocationNewDetection" },
            { title: "投稿内容(GBP投稿での指示データ)", key: "postRaw" },
            {
                title: "参照内容(REST API でクライアントへの応答内容(JSON or string))",
                key: "getRaw",
            },
            { title: "GBP API Request", key: "gmbReqRaw" },
            { title: "GBP API Response", key: "gmbResRaw" },
            { title: "GBP API Response Status", key: "gmbResState" },
        ].map((header) => {
            return {
                width: columnWidth,
                ...header,
            };
        });
    }

    res: StorageHealthCheckItemResponse = {};
    loading: boolean = false;
    reportName: string = "ヘルスチェック";
    dict = wordDictionary.healthCheck;

    areas: EntitiesArea[] = [{ name: "", areaID: -1 }];
    selectedArea: number = -1;

    uiTypes = [
        "area_analysis",
        "area_report",
        "area_review",
        "area_summary",
        "company_report",
        "search_reply",
        "company_summary",
        "login",
        "report_download",
        "store",
        "store_report",
        "store_review",
        "store_summary",
        "stores_gmb_media",
        "stores_gmb_post",
        "stores_gmb_update",
        "stores_update",
        "v2_post",
        "stores_update",
        "service_items",
        "companies_form",
        "foodmenu_list",
        "citation_settings",
        "facebook-proxy",
        "custom-roles",
    ];
    selectedUiTypes: string[] = [];

    actionTypes = [
        "addition-put",
        "batch-update",
        "bulk-put",
        "bulk-revert",
        "copy",
        "del-benefits-exec",
        "del-benefits-reserved",
        "del-covid19-exec",
        "del-covid19-reserved",
        "del-event-exec",
        "del-event-reserved",
        "del-info-exec",
        "del-info-reserved",
        "del-product-exec",
        "del-product-reserved",
        "download",
        "edit-benefits-exec",
        "edit-benefits-reserved",
        "edit-covid19-exec",
        "edit-covid19-reserved",
        "edit-event-exec",
        "edit-event-reserved",
        "edit-info-exec",
        "edit-info-reserved",
        "edit-product-exec",
        "edit-product-reserved",
        "exec",
        "export",
        "get",
        "image-put",
        "import",
        "new-benefits-exec",
        "new-benefits-reserved",
        "new-covid19-exec",
        "new-covid19-reserved",
        "new-event-exec",
        "new-event-reserved",
        "new-info-exec",
        "new-info-reserved",
        "new-product-exec",
        "new-product-reserved",
        "post",
        "put",
        "reply",
        "reserved",
        "retry-benefits-reserved",
        "retry-covid19-reserved",
        "retry-event-reserved",
        "retry-info-reserved",
        "retry-product-reserved",
        "rivals-update",
        "struct_put",
        "struct-put",
        "v2-post-new-reserved",
        "v2-post-edit-reserved",
        "v2-post-delete-reserved",
        "v2-post-retry-reserved",
        "v2-post-new-requested",
        "v2-post-edit-requested",
        "v2-post-delete-requested",
        "v2-post-new-approved",
        "v2-post-edit-approved",
        "v2-post-delete-approved",
        "v2-post-new-rejected",
        "v2-post-edit-rejected",
        "v2-post-delete-rejected",
        "v2-post-new-request-withdrawn",
        "v2-post-edit-request-withdrawn",
        "v2-post-delete-request-withdrawn",
        "v2-post-delete",
        "v2-post-delete-incomplete",
        "v2-post-new-file-reserved",
        "v2-post-edit-file-reserved",
        "v2-post-delete-file-reserved",
        "v2-post-retry-file-reserved",
        "v2-post-new-file-requested",
        "v2-post-edit-file-requested",
        "v2-post-delete-file-requested",
        "v2-post-new-file-approved",
        "v2-post-edit-file-approved",
        "v2-post-delete-file-approved",
        "v2-post-new-file-rejected",
        "v2-post-edit-file-rejected",
        "v2-post-delete-file-rejected",
        "v2-post-new-file-request-withdrawn",
        "v2-post-edit-file-request-withdrawn",
        "v2-post-delete-file-request-withdrawn",
        "stores-update-create",
        "update_auto_block_on",
        "update_auto_block_off",
        "place-action-links-create",
        "place-action-links-patch",
        "place-action-links-delete",
        "page-linkage",
        "deauthorize",
        "delete",
    ];
    selectedActionTypes = [];

    customerPoiGroupId: number;

    isInCharge: boolean = false;

    async created(): Promise<void> {
        this.customerPoiGroupId = parseInt(this.$route.params.customerPoiGroupId as string, 10);

        switch (this.$route.query.type) {
            case "inChargeLogins":
                this.isInCharge = true;
                this.selectedUiTypes = ["login"];
                this.selectedActionTypes = ["exec"];
                break;
            case "businessUpdates":
                this.selectedUiTypes = ["store", "stores_update", "stores_gmb_update"];
                this.selectedActionTypes = [
                    "put",
                    "bulk-put",
                    "bulk-revert",
                    "batch-update",
                    "place-action-links-create",
                    "place-action-links-patch",
                    "place-action-links-delete",
                ];
                break;
            case "mediaUploads":
                this.selectedUiTypes = ["store", "stores_gmb_media"];
                this.selectedActionTypes = ["image-put"];
                break;
            case "reviewReplies":
                this.selectedUiTypes = ["search_reply"];
                this.selectedActionTypes = ["reply"];
                break;
            case "gmbPosts":
                this.selectedUiTypes = ["stores_gmb_post", "v2_post"];
                this.selectedActionTypes = [
                    "new-covid19-reserved",
                    "new-info-reserved",
                    "new-benefits-reserved",
                    "new-event-reserved",
                    "new-product-reserved",
                    "v2-post-new-reserved",
                    "v2-post-edit-reserved",
                    "v2-post-delete-reserved",
                    "v2-post-retry-reserved",
                    "v2-post-new-requested",
                    "v2-post-edit-requested",
                    "v2-post-delete-requested",
                    "v2-post-new-approved",
                    "v2-post-edit-approved",
                    "v2-post-delete-approved",
                    "v2-post-new-rejected",
                    "v2-post-edit-rejected",
                    "v2-post-delete-rejected",
                    "v2-post-new-request-withdrawn",
                    "v2-post-edit-request-withdrawn",
                    "v2-post-delete-request-withdrawn",
                    "v2-post-delete",
                    "v2-post-delete-incomplete",
                    "v2-post-new-file-reserved",
                    "v2-post-edit-file-reserved",
                    "v2-post-delete-file-reserved",
                    "v2-post-retry-file-reserved",
                    "v2-post-new-file-requested",
                    "v2-post-edit-file-requested",
                    "v2-post-delete-file-requested",
                    "v2-post-new-file-approved",
                    "v2-post-edit-file-approved",
                    "v2-post-delete-file-approved",
                    "v2-post-new-file-rejected",
                    "v2-post-edit-file-rejected",
                    "v2-post-delete-file-rejected",
                    "v2-post-new-file-request-withdrawn",
                    "v2-post-edit-file-request-withdrawn",
                    "v2-post-delete-file-request-withdrawn",
                ];
                break;
            case "gmbStoresUpdateCreate":
                this.selectedUiTypes = ["stores_update"];
                this.selectedActionTypes = ["stores-update-create"];
                break;
        }

        // 対象企業のグループ一覧取得
        const response = await requiredAuth<EntitiesAreasResponse>(
            "get",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.customerPoiGroupId}/areas`
        );
        this.areas = response?.data?.areas ?? [];
        this.areas.unshift({ name: "", areaID: -1 });

        this.loading = true;
        this.res = await createTableData(
            this.company.poiGroupID,
            this.customerPoiGroupId,
            this.range.from,
            this.range.to,
            this.optionalParams()
        );
        this.loading = false;
    }

    get hasMore(): boolean {
        return this?.res?.hasMore ?? false;
    }

    get logItems(): any[] {
        return this.res.items;
    }

    getOptionSymbol(haveOption: boolean): string {
        return haveOption ? "○" : "";
    }

    async search(): Promise<void> {
        useSs().healthCheckAggregationStartDate = this.range.from;
        useSs().healthCheckAggregationEndDate = this.range.to;
        this.loading = true;
        this.res = await createTableData(
            this.company.poiGroupID,
            this.customerPoiGroupId,
            this.range.from,
            this.range.to,
            this.optionalParams()
        );
        this.loading = false;
    }

    private optionalParams(): any {
        const params: any = {};

        if (this.selectedArea >= 0) {
            params.areaId = this.selectedArea;
        }

        if (this.selectedUiTypes?.length) {
            params.uiTypes = this.selectedUiTypes.join(",");
        }

        if (this.selectedActionTypes?.length) {
            params.actionTypes = this.selectedActionTypes.join(",");
        }

        if (this.isInCharge === true) {
            params.byInCharge = true;
        }

        this.selectedGmbFields.forEach((g) => {
            params[g] = true;
        });

        return params;
    }

    clearAreaSelection(): void {
        this.selectedArea = -1;
    }

    async xlsxExport(): Promise<void> {
        this.loading = true;

        const params = this.optionalParams();
        console.log("params: " + params);

        let url: string;
        await requiredAuth<StorageHealthCheckDownloadResponse>(
            "post",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
                this.company.poiGroupID
            }/healthChecks/${this.customerPoiGroupId}/downloads`,
            {
                startDate: this.range.from,
                endDate: this.range.to,
                ...params,
            }
        )
            .then((res) => (url = res?.data.url))
            .catch((e) => captureAndThrow("ヘルスチェック詳細ログ作成依頼失敗", e));

        window.open(url, "_self");

        this.loading = false;
    }
    // サイドバーの有無で幅を調整する
    get headerCalcWidth(): number {
        return headerCalcWidth(useIndexedDb().isDrawerOpened);
    }
}
export default toNative(HealthCheckLog);

async function callHealthChecksAPI(
    poiGroupId: number,
    customerPoiGroupID: number,
    startDate: string,
    endDate: string,
    params: any
): Promise<StorageHealthCheckItemResponse> {
    const res = await requiredAuth<StorageHealthCheckItemResponse>(
        "get",
        `${
            import.meta.env.VITE_APP_API_BASE
        }v1/companies/${poiGroupId}/healthChecks/${customerPoiGroupID}`,
        {
            startDate: startDate,
            endDate: endDate,
            ...params,
        }
    );
    return res.data;
}

async function createTableData(
    poiGroupId: number,
    customerPoiGroupID: number,
    dateFrom: string,
    dateTo: string,
    params: any
): Promise<any> {
    const healthCheckData = await callHealthChecksAPI(
        poiGroupId,
        customerPoiGroupID,
        dateFrom,
        dateTo,
        params
    );
    if (healthCheckData.items == null) {
        return [];
    }
    return healthCheckData;
}
