<template>
  <div>
    <div class="register">
      <div id="dummyAreaButton" data-testid="area-register">
        <router-link
          :to="{ name: 'AreasNew', params: { poiGroupId: poiGroupId } }"
          class="button is-primary"
        >
          {{ dict.register_button_label }}
        </router-link>
      </div>
    </div>
    <div v-if="areas.length === 0 && !loading">{{ dict.result_message_loading }}</div>
    <div v-else>
      <o-table paginated :data="areas" :loading="loading">
        <template #header="props">
          <span>{{ props.column.label }}</span>
          <ToolTipIcon
            v-if="titles.tooltipExists(props.column.label)"
            :label="titles.supplement(props.column.label)"
            position="bottom"
          />
        </template>
        <o-table-column field="name" :label="dict.name.title">
          <template #default="props">
            <router-link
              :data-testid="`name-${props.row.areaID}`"
              :to="{
                path: `/companies/${poiGroupId}/stores`,
                query: { areaId: props.row.areaID },
              }"
            >
              {{ props.row.name }}
            </router-link>
          </template>
          <template #header>
            {{ dict.name.title }}
            <ToolTipIcon :label="dict.name.supplement" position="right" />
          </template>
        </o-table-column>

        <o-table-column field="name" label="クチコミアラート条件">
          <template #default="props">
            {{ reviewAlertConditionFormat(props.row) }}
          </template>
        </o-table-column>

        <o-table-column :label="dict.edit.title">
          <template #default="props">
            <router-link
              :to="{
                name: 'AreasEdit',
                params: { poiGroupId: poiGroupId, areaId: props.row.areaID },
              }"
            >
              <o-icon class="white-button" icon="pencil"></o-icon>
            </router-link>
          </template>
          <template #header>
            {{ dict.edit.title }}
            <ToolTipIcon :label="dict.edit.supplement" position="bottom" />
          </template>
        </o-table-column>
        <o-table-column field="delete" label>
          <template #default="props">
            <button
              :data-testid="`deleteArea-${props.row.areaID}`"
              class="button is-primary"
              @click="deleteConfirm(props.row)"
            >
              {{ dict.col_delete_button_label }}
            </button>
          </template>
        </o-table-column>
      </o-table>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
