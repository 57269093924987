<template>
  <v-app id="inspire">
    <topbar />
    <sidebar />
    <v-main>
      <div id="contents">
        <contents />
      </div>
    </v-main>
    <v-snackbar v-model="snackbar" top :timeout="-1">
      <div style="margin-bottom: 10px">新しい{{ dict.fullName }}がリリースされました!</div>
      <div class="d-flex justify-center">
        <v-btn onclick="location.reload(true);return false" color="primary">
          リロードして最新アプリに更新
        </v-btn>
      </div>
      <template #actions>
        <v-btn icon color="gray" variant="text" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
