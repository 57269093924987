import { requiredAuth } from "@/helpers";
import type { AxiosResponse } from "axios";
import type { LoadingStatus } from "@/helpers/api/utils";

import type {
    DomainPlaceActionLinks,
    DomainPlaceActionLinksList,
    DomainCreatePlaceActionLinkRequest,
    DomainPatchPlaceActionLinkRequest,
} from "@/types/ls-api";
import type { OperationLogParams } from "@/routes/operation-log";

export const api = {
    batchGetPlaceActionLinks,
    createPlaceActionLink,
    patchPlaceActionLink,
    deletePlaceActionLink,
};

// batchGetPlaceActionLinks の戻り値の型定義
export type BatchGetPlaceActionLinksResult = LoadingStatus & {
    result: DomainPlaceActionLinks[];
};

/** locationNameのchunkからプレイスアクションリンク一覧を取得 */
async function batchGetPlaceActionLinks(
    poiGroupID: number,
    locationNamesChunks: string[][]
): Promise<BatchGetPlaceActionLinksResult> {
    const status: LoadingStatus = { completed: false, error: null };

    // (設定できる値はすべて空の状態で保持する必要がある)
    const batchGetPromises: Promise<AxiosResponse<DomainPlaceActionLinksList>>[] = [];
    for (const locationNames of locationNamesChunks) {
        batchGetPromises.push(batchGet(poiGroupID, locationNames));
    }

    const result: DomainPlaceActionLinks[] = [];
    await Promise.all(batchGetPromises)
        .then((responses: AxiosResponse<DomainPlaceActionLinksList>[]) => {
            for (const res of responses) {
                // linkのlistが無い場合はスキップ
                if (res.data?.list == null) {
                    continue;
                }
                result.push(...res.data.list);
            }
            status.completed = true;
        })
        .catch((error: any) => {
            console.error("[error]", error?.response);
            status.error = error;
        });
    return { ...status, result };
}

/** プレイスアクションリンク一覧を取得 */
async function batchGet(
    poiGroupId: number,
    locationNames: string[]
): Promise<AxiosResponse<DomainPlaceActionLinksList>> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/placeActionLinks/batchGet`;
    return requiredAuth<DomainPlaceActionLinksList>("post", url, null, { locationNames });
}

/** プレイスアクションリンク作成 */
async function createPlaceActionLink(
    poiGroupId: number,
    poiID: number,
    oplogParameter: OperationLogParams,
    data: DomainCreatePlaceActionLinkRequest
): Promise<AxiosResponse<DomainPlaceActionLinks>> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/placeActionLinks/stores/${poiID}`;
    return await requiredAuth<DomainPlaceActionLinks>("post", url, oplogParameter, data);
}

/** プレイスアクションリンク編集 */
async function patchPlaceActionLink(
    poiGroupId: number,
    poiID: number,
    oplogParameter: OperationLogParams,
    data: DomainPatchPlaceActionLinkRequest
): Promise<AxiosResponse<DomainPlaceActionLinks>> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/placeActionLinks/stores/${poiID}`;
    return await requiredAuth<DomainPlaceActionLinks>("patch", url, oplogParameter, data);
}

/** プレイスアクションリンク削除 */
async function deletePlaceActionLink(
    poiGroupId: number,
    poiID: number,
    oplogParameter: OperationLogParams,
    name: string
): Promise<AxiosResponse<DomainPlaceActionLinks>> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/placeActionLinks/stores/${poiID}`;
    return await requiredAuth<DomainPlaceActionLinks>("delete", url, oplogParameter, { name });
}
