<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ orgName }}を削除してもよろしいですか？</p>
      </header>
      <section class="modal-card-body">
        <o-checkbox v-model="canDelete" data-testid="can-delete">削除する</o-checkbox>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$emit('cancel')">キャンセル</button>
        <button
          data-testid="delete-confirm"
          class="button is-primary"
          type="button"
          :disabled="!canDelete"
          @click="deleteCompany()"
        >
          削除
        </button>
      </footer>
    </div>
  </form>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    propOrgId: { type: Number, default: 0 },
    propOrgName: { type: String, default: "" },
    propCanDelete: { type: Boolean, default: false },
  },
  emits: ["cancel", "delete"],
  data() {
    return {
      orgId: this.propOrgId,
      orgName: this.propOrgName,
      canDelete: this.propCanDelete,
    };
  },
  methods: {
    deleteCompany() {
      this.$emit("delete");
    },
  },
});
</script>
