<template>
  <div>
    <v-card-text>
      <div class="wrapper">
        <div id="search-keyword-unique" style="min-height: 360px" />
      </div>
    </v-card-text>
    <v-overlay :model-value="loading" persistent contained class="align-center justify-center">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { requiredAuth } from "@/helpers";
import { captureAndThrow } from "@/helpers/error";
import type {
  DomainPerformanceSearchKeywordUniqueResponse,
  DomainPerformanceSeries,
} from "@/types/ls-api";
import type { StoreFilter } from "./performance";
import { initChart } from "@/components/shared/am5-shared";

export default defineComponent({
  props: {
    storeFilter: {
      type: Object as () => StoreFilter,
      required: false,
      default: () => ({} as StoreFilter),
    },
  },
  data: () => {
    return {
      loading: false as boolean,
      data: {} as DomainPerformanceSeries,
      barColor: "#9EAECD" as string,
    };
  },
  computed: {
    poiGroupID: function (): number {
      return parseInt(this.$route.params.poiGroupId as string);
    },
  },
  methods: {
    async fetch() {
      // リトライ処理を入れている
      for (let i = 0; i < 1000; i++) {
        this.loading = true;

        const dt1 = new Date().getTime();
        if (i > 0) {
          // 5sec wait
          let dt2 = new Date().getTime();
          const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));
          while (dt2 < dt1 + 5 * 1000) {
            await sleep(500);
            dt2 = new Date().getTime();
          }
        }

        await Promise.all([this.fetchSearchKeywordUnique()]);

        if (!this.loading) {
          break;
        }
      }
      await this.createGraph();
    },
    async fetchSearchKeywordUnique() {
      await requiredAuth<DomainPerformanceSearchKeywordUniqueResponse>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
          this.poiGroupID
        }/performance/search_keyword_unique`,
        this.storeFilter
      )
        .then((res) => {
          this.data = res?.data;
          this.loading = false;
        })
        .catch((e) => {
          if (e.response?.status === 503) {
            console.log("503エラー、5秒後にリトライ");
          } else {
            captureAndThrow("検索キーワードのユニーク数取得エラー", e);
          }
        });
    },
    /* amCharts表示 */
    async createGraph() {
      const eleId = "search-keyword-unique";
      // 高速でページ離脱するとAmcharts5のroot生成に失敗でエラーになるので中断する
      if (!document.getElementById(eleId)) {
        return;
      }
      const data = this.data?.data;
      // 日付整形する
      for (const item of data) {
        item.date = item.date.replace(/(\d+)-(\d+)-\d+/, "$1/$2");
      }
      // チャート初期化
      const root: am5.Root = initChart(eleId);
      const chart: am5xy.XYChart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          layout: root.verticalLayout,
        })
      );

      // Create X-Axis
      const xAxis: am5xy.CategoryAxis<am5xy.AxisRenderer> = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0.2,
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 40,
            cellStartLocation: 0.2,
            cellEndLocation: 0.8,
          }),
          startLocation: 0,
          endLocation: 1,
          dx: 0,
          categoryField: "date",
        })
      );
      xAxis.get("renderer").grid.template.setAll({
        location: 0.5,
      });
      xAxis.get("renderer").labels.template.setAll({
        maxWidth: 52,
        // ラベルのフォントサイズ自動調整
        oversizedBehavior: "fit",
        // ラベルのフォントサイズ手動調整
        fontSize: 11,
        fill: am5.color("#333333"),
        paddingTop: 10,
      });
      xAxis.data.setAll(data);

      // Craete Y-axis
      const yAxis: am5xy.ValueAxis<am5xy.AxisRenderer> = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );
      // amcharts4のtitleの替わりにlabelを使って表現する
      const ken: am5.Label = am5.Label.new(root, {
        text: "(件)",
        position: "absolute",
        x: -32,
        y: am5.percent(95),
      });
      yAxis.children.unshift(ken);

      const series: am5xy.ColumnSeries = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "ユニーク数",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          categoryXField: "date",
          maskContent: true,
          // 棒グラフの塗り色
          fill: am5.color(this.barColor),
          maskBullets: false,
        })
      );

      // ツールチップ
      const tooltip: am5.Tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        pointerOrientation: "horizontal",
      });
      tooltip.get("background").setAll({
        fill: am5.color("#EBEDF0"),
        shadowColor: am5.color("#000000"),
        shadowBlur: 4,
        shadowOffsetY: 2,
        shadowOpacity: 0.5,
      });
      series.columns.template.setAll({
        tooltip,
        tooltipHTML:
          "<div style='line-height: 1.5em; text-align: left;'>{date}<br/>ユニーク数 : {valueY.formatNumber('#,###')}件</div>",
        // 棒グラフの幅
        width: am5.percent(50),
      });
      series.data.setAll(data);
    },
  },
});
</script>
<style lang="scss" scoped>
.wrapper {
  border: 1px solid #d6d6d6;
  padding: 20px;
}

#search-keyword-unique {
  width: 100%;
  height: 360px;
}
</style>
