<template>
  <o-tooltip
    :label="label"
    :multiline="multilined"
    :variant="variant"
    :position="position"
    :size="size"
  >
    <v-icon :size="iconSize">mdi mdi-help-circle</v-icon>
  </o-tooltip>
</template>
<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
