import { requiredAuth } from "@/helpers";
import type { ControllersHistoryDetailsOutput, EntitiesV2HistoryPostData } from "@/types/ls-api";

export const api = {
    loadAllStatus,
};

export async function loadAllStatus(
    postData: EntitiesV2HistoryPostData
): Promise<ControllersHistoryDetailsOutput[]> {
    const asyncList = postData?.fileNames?.map((item: string) => {
        const pathList = item.split("/");
        return getDetails(postData.poiGroupID, pathList[3], pathList[4], pathList[5]);
    });
    if (asyncList?.length > 0) {
        return await Promise.all(asyncList);
    }
    return [];
}

async function getDetails(
    poiGroupID: number,
    userUUID: string,
    uuid: string,
    fileName: string
): Promise<ControllersHistoryDetailsOutput> {
    try {
        const url = `${
            import.meta.env.VITE_APP_API_BASE
        }v2/companies/${poiGroupID}/post/histories/details/${userUUID}/${uuid}/${fileName}`;
        const result = await requiredAuth<ControllersHistoryDetailsOutput>("get", url);
        return result?.data;
    } catch (e) {
        console.log(e);
        return null;
    }
}
