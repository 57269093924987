<template>
  <div>
    <submit-dialog
      :show="dialog.show"
      :title="dialog.title"
      :percentage="dialog.percentage"
      :message="dialog.message"
      :button="!dialog.button"
      @set-show="setSubmitShow"
    />
    <message-dialog
      :show="importDialog.show"
      :title="importDialog.title"
      :message="importDialog.message"
      :cancel-button="importDialog.cancelButton"
      @on-cancel="importDialog.show = false"
    />
    <message-dialog
      :show="showConfirmDialog"
      title=""
      message="表示タブ以外に更新データがあります。変更反映を続行しますか？"
      cancel-button="キャンセル"
      accept-button="OK"
      @on-cancel="showConfirmDialog = false"
      @on-accept="submit()"
    />
    <v-row>
      <v-col md="auto">
        <v-tabs
          v-model="selectedType"
          slider-color="primary"
          bg-color="#fff"
          @update:model-value="changeTab()"
        >
          <v-tab v-for="(type, key) of placeActionTypes" :key="key" :value="key" class="tab-label">
            {{ type }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col>
        <v-tooltip>
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              size="small"
              variant="flat"
              color="primary"
              class="download-button"
              @click="exportData()"
            >
              <v-icon x-small left>fas fa-file-download</v-icon>
              全件エクスポート
            </v-btn>
          </template>
          <span>現在設定されているデータを全件エクスポートします(更新不可含む)</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-window v-model="selectedType">
      <v-window-item
        v-for="(_, key) of placeActionTypes"
        :key="key"
        :value="key"
        :transition="false"
        :reverse-transition="false"
      >
        <data-table
          :ref="`${key}-table`"
          v-model:data="filteredData"
          :selected-type="selectedType"
          @save-item="saveItem"
          @add-item="addItem"
          @update-item="updateItem"
          @replace-items="replaceItems"
          @sort-data="sortData"
        />
        <div v-if="isLoading" class="progress-circular-container">
          <v-progress-circular :size="80" :width="4" color="primary" indeterminate />
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { trimLocationName } from "@/helpers/gmb";
import {
  placeActionTypes,
  providerTypes,
  convertStringByDict,
  updateXLSXColumns,
  saveFile,
  Model,
} from "./place-action-links";
import DataTable from "./place-action-links-table.vue";
import SubmitDialog from "./submit-dialog.vue";
import MessageDialog from "./import-dialog.vue";

import type { PlaceActionLinkData } from "./place-action-links";
import { useIndexedDb } from "@/storepinia/idxdb";

const model: Model = new Model();
export default defineComponent({
  components: { DataTable, SubmitDialog, MessageDialog },
  props: {
    searchWord: { type: String, default: "" },
    areaList: { type: Array as () => number[], default: () => [] as number[] },
  },
  emits: ["updateDisplayedCount", "changeUpdate", "updatePrevErrorMessage"],
  data: () => {
    return {
      filteredData: [] as Array<PlaceActionLinkData>,
      // 最初のタブが初期表示される
      selectedType: "" as string,
      placeActionTypes,
      isLoading: false as boolean,
      onlyDirtyRows: false as boolean,
      // エラー通知・変更内容を反映する際に使用するダイアログパラメータ
      dialog: {
        show: false,
        percentage: 0,
        title: "",
        message: "",
        button: true,
      },
      // xlsxインポート時に使用するダイアログパラメータ
      importDialog: {
        show: false,
        title: "XLSXインポート",
        message: "",
        cancelButton: "",
      },
      // 変更反映前の確認ダイアログ
      showConfirmDialog: false as boolean,
    };
  },
  computed: {
    poiGroupId: function (): number {
      return parseInt(this.$route.params.poiGroupId as string);
    },
    stores: function () {
      return useIndexedDb()
        .stores.stores?.filter((s) => !!s.enabled)
        ?.map((s) => {
          return { ...s, trimLocationName: trimLocationName(s.gmbLocationID) };
        });
    },
  },
  async created(): Promise<void> {
    await model.init(this);
    await this.fetch();
  },
  beforeUnmount(): void {
    model.isDestroyed = true;
    model.data = [];
  },
  methods: {
    setFilter(): void {
      model.filter(this.searchWord, this.areaList, this.onlyDirtyRows);
    },
    /* テーブルからのデータ追加 */
    addItem(newItem: PlaceActionLinkData) {
      // propsを直接編集できないため、書き換え用のメソッドを親に用意している
      this.filteredData.push({ ...newItem });
    },
    /* テーブルからのデータ書き換え */
    updateItem(index: number, newItem: PlaceActionLinkData) {
      this.filteredData.splice(index, 1, { ...newItem });
    },
    /* テーブルからのデータ上書き */
    replaceItems(newItems: PlaceActionLinkData[]) {
      this.filteredData.splice(0, this.filteredData.length, ...newItems);
    },
    /* 変更行のみ表示にチェック入れたら/外したら */
    usePickingDirtyRows(onlyDirtyRows: boolean): void {
      this.onlyDirtyRows = onlyDirtyRows;
      this.changeTab();
    },
    showDialog(title: string, message: string, showButton: boolean): void {
      this.dialog.title = title;
      this.dialog.message = message;
      this.dialog.button = showButton;
      this.dialog.show = true;
    },
    setSubmitShow(show: boolean): void {
      if (show === false) {
        // ダイアログ閉じた時点でタイトルと本文初期化しておく
        this.dialog.title = "";
        this.dialog.message = "";
      }
      this.dialog.show = show;
    },
    /* データを取得して成形する */
    async fetch(): Promise<void> {
      this.isLoading = true;
      try {
        const errorMsg = await model.fetch(this.poiGroupId);
        if (errorMsg == "") {
          this.changeTab();
        } else {
          this.showDialog("プレイスアクションリンク一覧の取得に失敗しました。", errorMsg, true);
        }
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          this.showDialog(
            "プレイスアクションリンク一覧の取得に失敗しました。",
            e.message ?? "",
            true
          );
        }
      } finally {
        this.isLoading = false;
      }
    },
    /* タブを変えたときの処理 */
    changeTab(): void {
      if (this.selectedType == null) {
        return;
      }
      this.isLoading = true;
      this.setFilter();
      this.$nextTick(() => {
        const filtered = model.filteredData.filter((d) => d.placeActionType === this.selectedType);
        this.filteredData.splice(0, this.filteredData.length, ...filtered);
        this.$emit("updateDisplayedCount", this.filteredData.length);
        const grid = this.$refs[`${this.selectedType}-table`] as InstanceType<typeof DataTable>;
        if (grid != null) {
          grid[0]?.gridUpdate();
        }
      });
      this.isLoading = false;
    },
    /* 子コンポーネントのデータを元に変更を保存し、親に変更を伝える */
    saveItem(): void {
      model.updateDirtyFlag(this.filteredData);
      this.checkChanges();
      this.changeTab();
    },
    /* 差分があったか検出して親コンポーネントに情報を伝える */
    checkChanges(): void {
      // dirtyFlagが1つでもあれば差分ありとみなす
      const hasDirtyFlag = !!model.data?.some((d) => !!d.dirtyFlag);
      // 結果で「変更内容を反映」ボタンの状態を書き換える
      this.$emit("changeUpdate", hasDirtyFlag);
    },
    /* 変更反映ボタン前に確認ダイアログを出す */
    async submitConfirm(): Promise<void> {
      const notSelectedTypeData =
        model.data?.filter((d) => !!d.dirtyFlag && d.placeActionType !== this.selectedType) || [];
      // 表示されているプレイスアクションリンク種別タブ以外にデータが残っていたら確認画面を出す
      if (notSelectedTypeData.length > 0) {
        this.showConfirmDialog = true;
        return;
      }
      await this.submit();
    },
    /* 変更反映ボタンを押下したときの処理 */
    async submit(): Promise<void> {
      this.showConfirmDialog = false;
      let errorMessage = "";
      errorMessage = await model.initGBPAccessToken(this.poiGroupId);
      if (errorMessage !== "") {
        this.showDialog("変更内容の反映を開始できませんでした", errorMessage, true);
        return;
      }

      this.dialog.title = "変更内容を反映中";
      this.dialog.button = false;
      this.$emit("updatePrevErrorMessage", "");
      // プログレスバー用のパラメータ
      this.dialog.percentage = 0;
      const total = model.data.filter((d) => !!d.dirtyFlag)?.length ?? 0;
      let updateCount = 0;
      this.dialog.show = true;
      // FIXME: quota制限が怖いので直列で実施します
      for (const d of model.data) {
        if (!d.dirtyFlag) {
          continue;
        }
        const errHeader = `・<b>${d.poiID}</b> ${d.storeName} (${convertStringByDict(
          d.placeActionType,
          placeActionTypes
        )})`;
        const messageList = model.verify(d);
        if (messageList.length > 0) {
          const message = messageList.join("<br/>");
          errorMessage += "--------------------<br/>";
          errorMessage += `${errHeader}<br/>${message}<br/>`;
          continue;
        }
        const message = await model.commitChange(this.poiGroupId, this.$route, d);
        if (message === "") {
          // プログレスバーを進捗させる
          updateCount++;
          this.dialog.percentage = (updateCount / total) * 100;
        } else {
          errorMessage += "--------------------<br/>";
          errorMessage += `${errHeader}<br/>${message}<br/>`;
        }
      }
      model.initData();
      this.checkChanges();
      this.changeTab();
      // ダイアログ更新
      let dialogTitle = "反映が完了しました";
      let dialogMessage = `${total}件中${updateCount}件反映しました。<br/><br/>`;
      if (errorMessage.length > 0) {
        dialogTitle += " (エラーあり)";
        let message = "以下の店舗はエラーがあり反映できませんでした。<br/>";
        message += "エラー内容をご確認の上、修正と再反映をお試しください。<br/>";
        message += errorMessage;
        // 親コンポーネントにある前回のエラーメッセージを更新する
        this.$emit("updatePrevErrorMessage", message);
        dialogMessage += message;
      }
      this.dialog.title = dialogTitle;
      this.dialog.message = dialogMessage;
      this.dialog.percentage = 100;
      this.dialog.button = true;
    },
    async importFile(xlsxFile: File): Promise<void> {
      // インポート前にデータを初期化する
      model.initData();
      const message = await model.importFile(xlsxFile);
      this.checkChanges();
      this.changeTab();
      this.importDialog.message = message;
      this.importDialog.cancelButton = message.includes("インポートしました") ? "OK" : "キャンセル";
      this.importDialog.show = true;
    },
    /* 表示データに基づいて更新用のファイルをエクスポート */
    async exportUpdateFile(): Promise<void> {
      const aoa = []; // Array of arrays
      // ヘッダ行を追加する
      aoa.push(updateXLSXColumns);
      // データを追加する
      for (const s of this.filteredData) {
        // note: placeActionLink.nameを使って更新するので、インポート時に抜けがあっても問題無し
        if (s.providerType !== "NO_DATA" && s.isEditable !== true) {
          continue;
        }
        const arr = [];
        arr.push(s.poiID);
        arr.push(s.storeCode);
        arr.push(s.storeName);
        // Temporaryはシステム内の操作のためのnameなので出力しない
        if (s.name.startsWith("Temporary/")) {
          arr.push("");
        } else {
          arr.push(s.name);
        }
        arr.push(s.placeActionType);
        arr.push(s.uri);
        arr.push(s?.isPreferred || false);
        arr.push(""); // 削除フラグは初期は空
        aoa.push(arr);
      }
      const mode = `${convertStringByDict(this.selectedType, placeActionTypes)}更新用`;
      saveFile(aoa, useIndexedDb().company.name, mode);
    },
    /* 全件データ(現在表示されているデータ)をエクスポートする */
    async exportData(): Promise<void> {
      const aoa = []; // Array of arrays
      // ヘッダ行を追加する
      const columns: string[] = [
        "店舗ID",
        "店舗コード",
        "ビジネス名",
        "リンクの管理名",
        "プロバイダ",
        "編集可能",
        "URL",
        "種別",
        "優先表示",
        "作成日時",
        "更新日時",
      ];
      aoa.push(columns);
      // データを追加する
      for (const s of model.data) {
        // 独自設定したものなのでスキップ
        if (s.providerType === "NO_DATA") {
          continue;
        }
        const arr = [];
        arr.push(s.poiID);
        arr.push(s.storeCode);
        arr.push(s.storeName);
        arr.push(s.name);
        arr.push(s.providerType);
        arr.push(s.isEditable);
        arr.push(s.uri);
        arr.push(s.placeActionType);
        arr.push(s?.isPreferred || false);
        arr.push(s.createTime);
        arr.push(s.updateTime);
        aoa.push(arr);
      }
      saveFile(aoa, useIndexedDb().company.name, "データ全件");
    },
    sortData(key: string, orderVal: number): void {
      const sortedData = this.filteredData.sort((a, b) => {
        const aDash = this.getSortItem(key, a);
        const bDash = this.getSortItem(key, b);
        if (aDash > bDash) {
          return 1 * orderVal;
        } else {
          return -1 * orderVal;
        }
      });
      this.filteredData.splice(0, this.filteredData.length, ...sortedData);
    },
    getSortItem(key: string, item: PlaceActionLinkData): string {
      switch (key) {
        case "poiID":
          return item.poiID.toString();
        case "storeCode":
          // note: 数字でも文字列としてのソートになっている
          return item.storeCode;
        case "storeName":
          return item.storeName;
        case "name":
          return item.name;
        case "providerType":
          return convertStringByDict(item.providerType, providerTypes);
        case "uri":
          return item.uri;
        case "isPreferred":
          return item.isPreferred ? "1" : "0";
        default:
          return "";
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.download-button {
  margin-top: 0.5rem;
}
.title {
  color: #000;
  flex-grow: 1;
  flex-shrink: 0;
  line-height: 1;
  background: #f5f6f7;
  margin-top: 0.5rem;
}
.content {
  margin-top: 1rem;
}
.tab-label {
  font-weight: bold;
}
</style>
