<template>
  <o-tooltip :label="message" :multilined="true" type="dark" :position="position" size="medium">
    <v-icon :color="color" large>{{ icon }}</v-icon>
  </o-tooltip>
</template>

<script lang="ts">
import { FeatureToggle } from "@/routes/FeatureToggle";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    ft: { type: Object, required: false, default: FeatureToggle },
    part: { type: String, required: false, default: "" },
    position: { type: String, required: false, default: "right" },
  },
  computed: {
    message(): string {
      return this.ft.getMessage(this.part);
    },
    status(): any {
      return this.ft.getStatus(this.part);
    },
    icon(): string {
      if (this.status === "OK") {
        return "";
      } else if (this.status === "INFO") {
        return "mdi mdi-alert-circle";
      } else if (this.status === "WARN") {
        return "mdi mdi-alert";
      } else {
        return "mdi mdi-do-not-disturb";
      }
    },
    color(): string {
      if (this.status === "OK") {
        return "";
      } else if (this.status === "INFO") {
        return "blue";
      } else if (this.status === "WARN") {
        return "orange";
      } else {
        return "red";
      }
    },
  },
  created() {},
});
</script>
<style lang="scss" scoped></style>
