<template>
  <VeeForm ref="observer" as="">
    <form data-testid="form" @submit.prevent="submit()">
      <Field
        v-slot="{ handleChange, value, errors }"
        v-model="name"
        rules="required"
        :name="dict.storeName.title"
      >
        <span class="tooltip-target">{{ dict.storeName.title }}</span>
        <ToolTipIcon :label="dict.storeName.supplement" />
        <o-field id="name">
          <o-input
            ref="name"
            data-testid="name"
            name="name"
            maxlength="64"
            :has-counter="false"
            disabled
            :model-value="value"
            @update:model-value="handleChange"
          ></o-input>
        </o-field>
        <p class="error-message">{{ errors[0] }}</p>
      </Field>

      <span class="tooltip-target">{{ dict.rivals.title }}</span>
      <ToolTipIcon :label="dict.rivals.supplement" />
      <div style="display: flex; margin: 10px">
        <Field
          v-slot="{ handleChange, value, errors }"
          v-model="rival.name"
          :name="dict.rivalName.title"
        >
          <div style="margin-right: 20px">
            <span>{{ dict.rivalName.title }}</span>
            <ToolTipIcon :label="dict.rivalName.supplement" />
            <o-field id="rivalName" style="width: 300px">
              <o-input
                ref="rivalName"
                data-testid="rivalName"
                name="rivalName"
                maxlength="64"
                :has-counter="false"
                :model-value="value"
                @update:model-value="handleChange"
              ></o-input>
            </o-field>
            <p class="error-message">{{ errors[0] }}</p>
          </div>
        </Field>

        <Field
          v-slot="{ handleChange, value, errors }"
          v-model="rival.keyword"
          :rules="`required:${rival.name !== ''}`"
          :name="'競合店舗を設定する場合、' + dict.rivalKeyword.title"
          :immediate="true"
        >
          <div style="flex-grow: 1">
            <span>{{ dict.rivalKeyword.title }}</span>
            <ToolTipIcon :label="dict.rivalKeyword.supplement" />
            <o-field id="keyword">
              <o-input
                ref="keyword"
                data-testid="rivalKeyword"
                name="keyword"
                maxlength="128"
                :has-counter="false"
                :model-value="value"
                @update:model-value="handleChange"
              ></o-input>
            </o-field>
            <p data-testid="rivalNameErrorMessage" class="error-message">
              {{ errors[0] }}
            </p>
          </div>
        </Field>
      </div>

      <div>
        <button data-testid="goBack" class="button is-primary" @click.prevent="goBack">
          {{ dict.button_go_back }}
        </button>
        <button data-testid="update" class="button is-primary">{{ dict.button_update }}</button>
      </div>
      <div v-if="isLoading" class="progress-circular-container">
        <v-progress-circular
          :size="80"
          :width="4"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </form>
  </VeeForm>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
