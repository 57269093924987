<template>
  <v-app-bar id="app-header" class="app-header" color="#ffffff" height="60">
    <v-app-bar-nav-icon class="hamburger-btn" @click.stop="toggle()" />
    <span>
      <img class="app-toolbar-logo" :src="theme.logoTop" />
    </span>
    <v-spacer />
    <!-- バージョン（PCのみ） -->
    <div v-if="!isMobile" class="app-version">
      {{ dict.app_version }}
      <span class="current-version">{{ dict.current_version }}</span>
    </div>
    <!-- 通知 -->
    <Notification v-if="!isMobile" />
    <!-- マニュアル（PCのみ） -->
    <div v-if="!isMobile" class="nav-button">
      <v-tooltip bottom transition="fade-transition">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            size="x-small"
            variant="outlined"
            :href="manualUrl"
            download
            icon="fas fa-book"
            color="primary"
          ></v-btn>
        </template>
        <span>{{ dict.manual }}</span>
      </v-tooltip>
    </div>
    <!-- リリースノート（PCのみ） -->
    <div v-if="!isMobile" class="nav-button">
      <v-tooltip bottom transition="fade-transition">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            size="x-small"
            variant="outlined"
            href="https://blog.tostore.jp/"
            target="_blank"
            icon="fas fa-info"
            color="primary"
            rel="noopener noreferrer"
          ></v-btn>
        </template>
        <span>{{ dict.release_notes }}</span>
      </v-tooltip>
    </div>
    <!-- ログアウト -->
    <div class="nav-button mr-4">
      <v-tooltip bottom transition="fade-transition">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            size="x-small"
            variant="outlined"
            icon="fas fa-power-off"
            color="primary"
            @click="logout"
          ></v-btn>
        </template>
        <span>{{ dict.logout }}</span>
      </v-tooltip>
    </div>
  </v-app-bar>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
