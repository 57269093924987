<template>
  <v-dialog v-model="show" width="500" persistent>
    <v-card>
      <v-card-title class="headline">
        <div>{{ title }}</div>
      </v-card-title>
      <v-card-text>
        <div v-for="(text, index) in message.split('<br/>')" :key="index">
          {{ text }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="cancelButton !== ''" variant="text" @click="onCancelClick">
          {{ cancelButton }}
        </v-btn>
        <v-btn v-if="acceptButton !== ''" variant="text" @click="onAcceptClick">
          {{ acceptButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, toNative } from "vue-facing-decorator";
@Component({ emits: ["onAccept", "onCancel"] })
class ImportDialog extends Vue {
  @Prop({ default: false, type: Boolean, required: true }) show: boolean;
  @Prop({ default: "", type: String, required: true }) title: string;
  @Prop({ default: "", type: String, required: true }) message: string;
  @Prop({ default: "", type: String, required: true }) cancelButton: string;
  @Prop({ default: "", type: String, required: false }) acceptButton: string;

  onAcceptClick(): void {
    this.$emit("onAccept");
  }

  onCancelClick(): void {
    this.$emit("onCancel");
  }
}
export default toNative(ImportDialog);
</script>
