<template>
  <div class="d-flex flex-wrap justify-start align-center">
    <v-select
      v-model="selectedCondition"
      class="condition-selector"
      color="primary"
      :items="conditions"
      label="表示条件を選択"
      single-line
      variant="outlined"
      density="compact"
      :hide-details="true"
      @update:model-value="onConditionSelect"
      @input="onUserAction"
    />

    <template v-if="selectedCondition === 'グループ'">
      <div class="mx-2 mb-1">:</div>
      <div class="mb-1">
        <v-autocomplete
          v-model="selectedGroup"
          class="group-selector"
          color="primary"
          :items="areaStores"
          item-title="name"
          item-value="areaID"
          label="グループを選択"
          single-line
          variant="outlined"
          density="compact"
          :hide-details="true"
          :return-object="true"
          @update:model-value="onGroupSelect"
          @input="onUserAction"
        />
      </div>
    </template>

    <template v-else-if="selectedCondition === '個店'">
      <div class="mx-2 mb-1">:</div>
      <div class="mb-1">
        <v-autocomplete
          v-model="selectedPrefecture"
          class="prefecture-selector"
          color="primary"
          :items="filteredPrefectures"
          label="都道府県を選択"
          single-line
          variant="outlined"
          density="compact"
          :hide-details="true"
          @update:model-value="onPrefecturesSelect"
        />
      </div>
      <div class="mx-1 mb-1 text-medium-emphasis">▶︎</div>
      <div class="mb-1">
        <v-autocomplete
          v-model="selectedStore"
          class="store-selector"
          color="primary"
          :items="candidates"
          item-title="name"
          item-value="poiID"
          label="個店選択"
          single-line
          variant="outlined"
          density="compact"
          :hide-details="true"
          :return-object="true"
          @update:model-value="onStoreSelect"
          @input="onUserAction"
        />
      </div>
    </template>
    <div class="num-of-selected-stores ml-3 mr-auto mb-1">{{ numOfSelectedStores }}店舗選択中</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, toNative } from "vue-facing-decorator";
import type { EntitiesAreaStores, EntitiesStore } from "@/types/ls-api";
import { useSs } from "@/storepinia/ss";
import { getter, useIndexedDb } from "@/storepinia/idxdb";
import { Conditions } from "@/components/shared/store-selector-type";

export class SelectedTarget {
  isAll: boolean = true;
  isArea: boolean = false;
  areaId: number = null;
  poiIds: number[] = [];
  constructor(isAll: boolean, isArea: boolean, areaId: number, poiIds: number[]) {
    this.isAll = isAll;
    this.isArea = isArea;
    this.areaId = areaId;
    this.poiIds = poiIds;
  }

  static fromSs(): SelectedTarget {
    const ss = useSs();
    if (ss.selectedCondition === Conditions.Group && ss.selectedGroup > 0) {
      return new SelectedTarget(false, true, ss.selectedGroup, []); // グループ
    } else if (ss.selectedCondition === Conditions.Store && ss.selectedStore > 0) {
      return new SelectedTarget(false, false, 0, [ss.selectedStore]); // 個店
    } else {
      return new SelectedTarget(true, false, 0, []); // 全店舗
    }
  }
  // なんの値も選択されていない
  get isNone(): boolean {
    if (!this.isAll && !this.isArea && this.areaId === null && this.poiIds.length === 0) {
      return true;
    }
    return false;
  }
  // 新インサイト用 areaIDを配列にして返す
  get areaIds(): number[] {
    if (this.areaId) {
      return [this.areaId];
    } else {
      return [];
    }
  }
}

const prefectures: string[] = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];

@Component({ emits: ["update:modelValue", "select", "on-user-action"] })
class StoreSelector extends Vue {
  @Prop() readonly modelValue: SelectedTarget; // v-model
  @Prop({ type: Boolean }) readonly useOrphanStoreGroup: boolean;
  isInitialized = false;
  conditions: Conditions[] = [];
  areaStores: EntitiesAreaStores[];
  enabledStores: EntitiesStore[] = [];
  selectedCondition: Conditions = Conditions.Null;
  selectedGroup: EntitiesAreaStores = null;
  selectedStore: EntitiesStore = null;
  numOfSelectedStores = 0;
  /** 県下の候補店舗 */
  candidates: EntitiesStore[] = [];
  filteredPrefectures: string[];
  selectedPrefecture: string = "";
  user = getter().user;
  roleList = getter().roleList;

  mounted(): void {
    const idb = useIndexedDb();
    this.enabledStores = idb.stores.stores.filter((s) => s.enabled);
    // グループ一覧作成
    this.areaStores = [...idb.areaStores];
    if (this.useOrphanStoreGroup) {
      const orphanStoreGroup = { name: "グループ未登録", stores: [] };
      orphanStoreGroup.stores = this.enabledStores.filter((store) => !store.areas);
      if (0 < orphanStoreGroup.stores.length) {
        this.areaStores.unshift(orphanStoreGroup);
      }
    }
    this.candidates = this.enabledStores;
    // 店舗が存在している都道府県のみを選択肢として表示する
    this.filteredPrefectures = [];
    this.filteredPrefectures.push("すべて");
    for (const prefecture of prefectures) {
      for (const store of this.enabledStores) {
        if (store.administrativeArea === prefecture) {
          this.filteredPrefectures.push(prefecture);
          break;
        }
      }
    }
    if (idb.userViewRange === 5) {
      // 店舗のみ参照権限がある
      this.conditions = [Conditions.Store];
    } else {
      this.conditions = [Conditions.All, Conditions.Group, Conditions.Store];
    }

    const ss = useSs();
    if (ss.selectedCondition === Conditions.Group) {
      // グループ
      this.selectedCondition = Conditions.Group;
      this.selectedGroup = this.areaStores.find((a) => a.areaID === ss.selectedGroup);
      this.selectedStore = null;
      this.onGroupSelect();
    } else if (ss.selectedCondition === Conditions.Store || idb.userViewRange == 5) {
      // 個店
      // 店舗権限ユーザに切り替えた場合にsessionStorageのリストアによって全店舗になるので強制的に切り替えて一番上の店舗を表示する
      this.selectedCondition = Conditions.Store;
      this.selectedGroup = this.areaStores.find((a) => a.areaID === ss.selectedGroup);
      if (ss.selectedStore == 0) {
        this.selectedStore = this.enabledStores[0];
      } else {
        this.selectedStore = this.enabledStores.find((s) => s.poiID === ss.selectedStore);
      }
      this.selectedPrefecture = this.selectedStore?.administrativeArea ?? "すべて";
      this.onStoreSelect(this.selectedStore);
    } else {
      // 全店舗
      this.selectedCondition = Conditions.All;
      this.selectedGroup = null;
      this.selectedStore = null;
      this.onConditionSelect();
    }
    this.isInitialized = true;
  }
  selectStore(st: SelectedTarget) {
    this.$emit("update:modelValue", st); // v-model を更新する
    if (this.isInitialized) {
      this.$emit("select"); // 初期化済みなら select を実行する
    }
  }
  onUserAction(): void {
    if (this.isInitialized) {
      this.$emit("on-user-action"); // 初期化済みなら on-user-action を実行する
    }
  }

  onConditionSelect(): void {
    this.selectedGroup = null;
    this.selectedStore = null;
    if (this.selectedCondition === Conditions.All) {
      const ss = useSs();
      ss.selectedCondition = this.selectedCondition;
      ss.selectedGroup = 0;
      ss.selectedStore = 0;
      const poiIds: number[] = this.enabledStores.map((store) => store.poiID);
      this.numOfSelectedStores = poiIds.length;
      this.selectStore(new SelectedTarget(true, false, 0, []));
    } else {
      this.numOfSelectedStores = 0;
    }
  }

  onGroupSelect(): void {
    const selectedGroupId = this.selectedGroup?.areaID;
    // console.log("onGroupSelect", selectedGroupId, this.selectedGroup);
    if (selectedGroupId == null) {
      return;
    }
    const ss = useSs();
    ss.selectedCondition = Conditions.Group;
    ss.selectedGroup = selectedGroupId;
    ss.selectedStore = 0;
    this.selectedStore = null;
    this.selectedGroup = this.areaStores.find((store) => store.areaID === selectedGroupId);
    if (!this.selectedGroup) {
      return;
    }
    const poiIds: number[] = this.selectedGroup.stores.map((store) => store.poiID);
    this.numOfSelectedStores = poiIds.length;
    this.selectStore(new SelectedTarget(false, true, selectedGroupId, poiIds));
  }

  onPrefecturesSelect(): void {
    if (this.selectedPrefecture === "すべて" || this.selectedPrefecture == null) {
      this.candidates = this.enabledStores;
    } else {
      this.candidates = this.enabledStores.filter(
        (store) => store.administrativeArea === this.selectedPrefecture
      );
    }
    this.numOfSelectedStores = 0;
  }

  onStoreSelect(chosenStore: EntitiesStore): void {
    if (chosenStore == null) {
      this.numOfSelectedStores = 0;
      return;
    }
    const ss = useSs();
    ss.selectedCondition = Conditions.Store;
    ss.selectedGroup = 0;
    ss.selectedStore = chosenStore.poiID;
    this.numOfSelectedStores = 1;
    const areaId = chosenStore.areas ? chosenStore.areas[0] : -1;
    this.selectStore(new SelectedTarget(false, false, areaId, [chosenStore.poiID]));
  }

  setSelection(selectedTarget: SelectedTarget): void {
    this.selectedCondition = selectedTarget.isAll
      ? Conditions.All
      : selectedTarget.isArea
      ? Conditions.Group
      : Conditions.Store;
    if (selectedTarget.isAll) {
      this.selectedGroup = null;
      this.selectedStore = null;
    } else if (selectedTarget.isArea) {
      this.selectedGroup = this.areaStores.find((a) => a.areaID === selectedTarget.areaId);
      this.selectedStore = null;
    } else {
      this.selectedGroup = null;
      this.selectedStore = this.enabledStores.find((s) => s.poiID === selectedTarget.poiIds[0]);
      this.selectedPrefecture = "すべて";
    }
  }
}
export default toNative(StoreSelector);
</script>
<style lang="scss" scoped>
.condition-selector {
  max-width: 8em;
  min-width: 6em;
}
.group-selector {
  min-width: 11em;
}
.prefecture-selector {
  min-width: 11em;
}
.store-selector {
  min-width: 12em;
  max-width: 20em;
}
.num-of-selected-stores {
  color: var(--primary);
  font-weight: bold;
}
</style>
