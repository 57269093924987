<template>
  <v-hover v-slot="{ isHovering, props }">
    <v-img v-bind="props" :src="imageUrl" :class="className">
      <span v-show="isHovering && !isMobile && !readonly" class="edit-span">
        <v-btn class="edit-button" flat rounded @click="clickEdit(state)">
          <v-icon color="white" left>fas fa-cog</v-icon>
          編集
        </v-btn>
      </span>
    </v-img>
  </v-hover>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { FileSelectionState } from "@/models/v2-file-selection";

export default defineComponent({
  props: {
    imageUrl: { type: String, required: true },
    state: { type: [Number, String] as unknown as () => FileSelectionState, required: true },
    className: { type: String, required: true },
    isMobile: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  emits: ["edit-image"],
  data() {
    return {
      isHover: false,
    };
  },
  methods: {
    clickEdit(order: FileSelectionState): void {
      // EditImageSelectedを作ってposts.vueのイベントを親コンポーネントを経由して発火させる
      this.$emit("edit-image", order);
    },
  },
});
</script>

<style lang="scss" scoped>
.edit-span {
  position: absolute;
  top: 9px;
  width: 100%;
  text-align: center;
}

.edit-button {
  background-color: rgba(0, 0, 0, 0.54) !important;
  color: white !important;
  border: none;
}
</style>
