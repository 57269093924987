<template>
  <div id="main">
    <snackbar />
    <dialogs />
    <error-dialog />
    <router-view />
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative } from "vue-facing-decorator";
import ErrorDialog from "./components/shared/error-dialog.vue";
import Snackbar from "./components/shared/snackbar/Snackbar.vue";
import Dialogs from "./components/shared/dialogs.vue";
import { currentTheme } from "@/components/shared/theme";

@Component({
  components: { ErrorDialog, Snackbar, Dialogs },
})
class App extends Vue {
  created() {
    const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
    const theme = currentTheme();
    favicon.href = theme.favicon;

    // CSS Variablesを動的に変更する
    const colors = theme.vuetifyTheme.colors;
    const style = {
      "--primary": colors.primary,
      "--primary-invert": colors.primaryInvert,
      "--primary-hover": colors.primaryHover,
      "--primary-tab-back": colors.primaryTabBack,
      "--active-sidebar": colors.activeSidebar,
      "--primary-first": "#f5dda5",
      "--primary-second": "#f5eddc",
      "--primary-third": "#f2f2f2",
      "--base-button-back": "#ffffff",
      "--login-input": colors.loginInput,
      "--login-text": colors.loginText,
      "--login-text-bg": colors.loginTextBg,
    };
    const root: HTMLHtmlElement = document.querySelector(":root");
    for (const [key, value] of Object.entries(style)) {
      root.style.setProperty(key, value);
    }
  }
}
export default toNative(App);
</script>

<style lang="scss">
@use "@/components/style/font.scss" as font;
@use "@/components/style/color.scss" as color;
@use "@/components/style/button.scss" as button;
@use "@/components/style/oruga-theme-bulma.scss";

:root {
  // ぐるぐるやstickyなヘッダとかの小物系
  --z-index-loading: 1;
  // 最上部のヘッダー
  --z-index-header: 200;
  // サイドバー
  --z-index-sidebar: 100;
  // モーダル系
  --z-index-modal: 300;
}

html,
body {
  font-family: font.$font-family;
}

#main {
  min-height: inherit;
  .calendar-picker {
    z-index: var(--z-index-modal) !important;
  }
}

div.toast {
  margin-top: 60px !important;
}

.dialog.modal.is-active {
  z-index: var(--z-index-modal);
}

.tippy-tooltip {
  width: 100% !important;
}

.b-table {
  &.is-loading {
    opacity: 1;
    &:after {
      border-left-color: color.$primary;
      border-bottom-color: color.$primary;
    }
  }
}

.progress-circular-container {
  position: fixed;
  z-index: var(--z-index-loading);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
</style>
