<template>
  <div>
    <form @submit.prevent="load()">
      <h2 class="subtitle">{{ dict.title }}</h2>
      <span class="tooltip-target required-highlight">{{ dict.brandNames.title }}</span>
      <span>
        <ToolTipIcon :label="dict.brandNames.supplement" />
      </span>
      <div>
        <v-text-field
          v-model="brandNames"
          density="compact"
          variant="outlined"
          single-line
          hide-details
          color="primary"
          class="bg-white my-2"
          style="width: 400px"
          @keydown.enter.exact="preventEnter"
          @keyup.enter.exact="preventEnter"
        />
      </div>
      <div>
        <button class="button is-primary" @click.prevent="goBack">
          {{ dict.button_go_back }}
        </button>
        <button class="button is-primary">
          {{ dict.button_extract }}
        </button>
      </div>
    </form>
    <form v-if="storeKeywords.length !== 0" @submit.prevent="submit()">
      <div>
        <table class="table">
          <thead>
            <tr>
              <th>店舗ID</th>
              <th>店舗名</th>
              <th>キーワード</th>
              <th>市区町村名</th>
              <th>町域名</th>
              <th>最寄駅名</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(sk, index) of storeKeywords" :key="sk.poiId">
              <td>{{ sk.poiID }}</td>
              <td>{{ sk.name }}</td>
              <td>
                <input
                  v-model="storeKeywords[index].keyword"
                  type="text"
                  class="input-keyword"
                  @keydown.enter.exact="preventEnter"
                  @keyup.enter.exact="preventEnter"
                />
              </td>
              <td>{{ sk.cityName }}</td>
              <td>{{ sk.townName }}</td>
              <td>{{ sk.stations }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <button class="button is-primary">
          {{ dict.button_submit }}
        </button>
        <span>
          (キーワード未設定{{ noKeywordStores }}店舗中、上記{{
            storeKeywords.length
          }}店舗にキーワードを設定します)
        </span>
      </div>
    </form>
    <div v-if="canShowTable && storeKeywords.length === 0" class="no-results">
      {{ dict.noResults }}
    </div>
    <div v-if="isLoading" class="progress-circular-container">
      <v-progress-circular
        :size="80"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative } from "vue-facing-decorator";
import { apiBase } from "@/const";
import { requiredAuth } from "@/helpers";
import type {
  EntitiesCompanyStoreKeywords,
  EntitiesStoreLatLngCode,
  EntitiesStore,
} from "@/types/ls-api";
import wordDictionary from "@/word-dictionary";
import { useSnackbar } from "@/storepinia/snackbar";
import { parallels } from "@/helpers/parallel-promise";
import { getter, action } from "@/storepinia/idxdb";

@Component({})
class StoreKeywords extends Vue {
  dict = wordDictionary.storeKeywords;
  poiGroupId: number = -1;
  brandNames: string = "";
  canShowTable: boolean = false;
  isLoading: boolean = false;
  dialog = {
    show: false,
    percentage: 0,
    title: "",
    message: "",
    button: true,
  };
  storeKeywords = [];
  noKeywordStores: number = 0;

  stores = getter().stores;
  setStores = action().setStores;
  addSnackbarMessages = useSnackbar().addSnackbarMessages;

  showDialog(title: string, message: string, showButton: boolean): void {
    this.dialog.title = title;
    this.dialog.message = message;
    this.dialog.button = showButton;
    this.dialog.show = true;
  }

  async created(): Promise<void> {
    this.poiGroupId = parseInt(this.$route.params.poiGroupId as string, 10);
  }

  async load(): Promise<void> {
    this.isLoading = true;
    await this.getStoreKeywords();
    this.canShowTable = true;
    this.isLoading = false;
  }

  async submit(): Promise<void> {
    try {
      this.isLoading = true;
      const params: EntitiesCompanyStoreKeywords = {
        poiGroupID: this.poiGroupId,
        storeKeywords: this.storeKeywords.map((sk) => {
          return {
            poiID: sk.poiID,
            keyword: sk.keyword,
          };
        }),
      };
      const response = await requiredAuth<boolean>(
        "put",
        `${apiBase}/companies/${this.poiGroupId}/storeKeywords`,
        null,
        params
      );

      if (response) {
        this.addSnackbarMessages({
          text: "店舗キーワードを更新しました",
          color: "success",
        });
        await this.setStores(this.poiGroupId);
        this.storeKeywords = [];
        await this.getStoreKeywords();
      }
    } catch (e) {
      console.log(e);
      this.showDialog(
        "店舗キーワードの登録に失敗しました。",
        (e instanceof Error && e.message) ?? "",
        true
      );
    } finally {
      this.isLoading = false;
    }
  }

  goBack(): void {
    this.$router.push({ name: "AdminStores" });
  }

  preventEnter(e: ErrorEvent): void {
    e.preventDefault();
  }

  /** 指定されたサイズごとに配列を分割する */
  private divide<T>(array: T[], size: number): T[][] {
    if (size < 1) {
      size = 1;
    }
    const dividedArray: T[][] = [];
    while (array.length) {
      dividedArray.push(array.splice(0, size));
    }
    return dividedArray;
  }

  async getStoreKeywords(): Promise<void> {
    // 同時呼び出し上限
    const concurrency = 10;
    // 一度の呼び出しの際に取得する店舗数
    const batchSize = 20;

    // 有効でキーワード未設定の店舗
    const enabledAndNoKeywords = this.stores.stores.filter(
      (s) => s.enabled && (!s.keywords || s.keywords.length == 0 || s.keywords[0].length == 0)
    );
    this.noKeywordStores = enabledAndNoKeywords.length;

    // キーワード未設定の店舗を20店舗ずつに分割する
    let storesChunks: EntitiesStore[][] = this.divide(enabledAndNoKeywords, batchSize);

    // 1画面に表示する店舗は200までに制限したいので処理対象を 20店舗 * 10 に減らしている
    // (1回の作業を200店舗ぐらいに制限した方がCSメンバーも作業しやすいと思われるためこのような仕様にしている)
    storesChunks = storesChunks.slice(0, concurrency);

    let errorCount = 0;
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/storeKeywords`;
    const ps = parallels();

    // 20店舗分のキーワード抽出処理を10並列で動かす (合計で最大200店舗分のキーワードを取得する)
    for (const stores of storesChunks) {
      const paramStores = stores.map((s) => {
        const storeLatLngCode: EntitiesStoreLatLngCode = {
          poiID: s.poiID as number,
          name: s.name,
          gmbLocationID: s.gmbLocationID,
          gmbStoreCode: s.gmbStoreCode,
          latitude: s.storeGeo?.latitude,
          longitude: s.storeGeo?.longitude,
        };
        return storeLatLngCode;
      });
      ps.add(
        requiredAuth<EntitiesCompanyStoreKeywords>("post", url, null, {
          brands: this.brandNames,
          stores: paramStores,
        })
          .then((response: any) => {
            this.storeKeywords = this.storeKeywords.concat(response.data?.storeKeywords);
          })
          .catch((error: any) => {
            console.error("キーワード抽出失敗: ", error?.response);
            errorCount++;
          })
      );
      await ps.race(concurrency);
    }
    await ps.all();

    if (errorCount > 0) {
      this.addSnackbarMessages({
        text: "キーワード抽出に失敗しました",
        color: "danger",
      });
    }
  }
}
export default toNative(StoreKeywords);
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

.table {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 10px;
  table-layout: auto;

  :deep(thead) {
    background-color: color.$base-grey;
  }
  :deep(tbody tr td) {
    padding: 8px 14px;
    border-bottom: 1px solid color.$base-grey;
  }
}

th {
  font-size: 0.8rem;
}
td {
  font-size: 0.8rem;
}

.input-keyword {
  outline: 1px solid color.$base-grey;
  padding: 2px 5px;
  &:focus {
    outline: solid color.$primary;
  }
}

.no-results {
  margin-top: 20px;
}
</style>
