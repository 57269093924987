<template>
  <div>
    <v-dialog v-model="isShow" width="415" @click:outside="close">
      <v-card>
        <v-card-title class="card-header">
          <v-btn icon class="close-button" @click="close">
            <v-icon color="#AAAAAA">fas fa-xmark</v-icon>
          </v-btn>
          <img src="@/assets/images/error_icon.svg" class="error-icon" />
        </v-card-title>

        <v-card-text v-show="isErrorMessageShow">
          <!--eslint-disable vue/no-v-html-->
          <div class="title" v-html="sanitize(sentryDialogMessage?.title)"></div>
          <div class="message" v-html="sanitize(sentryDialogMessage?.detail)"></div>
          <!--eslint-enable-->
        </v-card-text>

        <v-card-actions
          v-if="sentryDialogMessage && sentryDialogMessage.buttonType === 'reload'"
          class="card-actions justify-center"
        >
          <v-btn variant="elevated" color="primary" @click="reload()">ブラウザを更新する</v-btn>
        </v-card-actions>
        <v-card-actions
          v-else-if="sentryDialogMessage && sentryDialogMessage.buttonType === 'login'"
          class="card-actions justify-center"
        >
          <v-btn variant="elevated" color="primary" @click="goToLoginPage()">ログイン画面へ</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import sanitizeHtml from "sanitize-html";
import { useDialog } from "@/storepinia/dialog";
import { mapState } from "pinia";

export default defineComponent({
  data: () => {
    return {
      root: import.meta.env.VITE_APP_ROOT_PATH == null ? "/" : import.meta.env.VITE_APP_ROOT_PATH,
      isErrorMessageShow: true,
    };
  },
  computed: {
    ...mapState(useDialog, ["sentryDialogMessage"]),
    isShow(): boolean {
      return !!this.sentryDialogMessage;
    },
  },
  methods: {
    close(): void {
      const buttonType = this.sentryDialogMessage.buttonType;
      useDialog().hideSentry();
      if (buttonType === "login") {
        // 「ログインへ」ボタン出すダイアログだった場合はインサイト分析に飛ばす(TM-5607)
        this.$router.push({ name: "Performance" });
      }
    },

    reload(): void {
      location.reload();
    },

    goToLoginPage(): void {
      this.$router.push({ name: "UserLogin" });
    },

    sanitize(text: string): string {
      return sanitizeHtml(text, {
        allowedTags: ["br"],
      });
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-dialog) {
  border-radius: 0px;
}

:deep(.v-card) {
  border-radius: 0px !important;
}

.card-header {
  position: relative;
  height: 250px;
  background-color: #cccccc;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
}

.error-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 93px;
}

.title {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  padding: 30px 20px;
  color: #000000;
  background: #ffffff;
}

.message {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  background: #ffffff;
}

.card-actions {
  padding-bottom: 40px !important;

  :deep(.v-btn) {
    border-radius: 5px;
    box-shadow: none;

    padding: 5px 20px;
    gap: 10px;
    width: 195px;
    height: 34px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }
}
</style>
