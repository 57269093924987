import type { AxiosError } from "axios";
import { requiredAuth } from "@/helpers";
import { Component, Vue, toNative } from "vue-facing-decorator";
import wordDictionary from "@/word-dictionary";
import type { EntitiesUser, EntitiesUsersResponse, EntitiesRole } from "@/types/ls-api";
import { useSnackbar } from "@/storepinia/snackbar";
import { getter } from "@/storepinia/idxdb";
import { useDialog } from "@/storepinia/dialog";
import { currentTheme } from "@/components/shared/theme";
import type { ListGrid } from "cheetah-grid";
import type { CustomSortState } from "@/components/shared/cheetah-grid-shared";

type DefaultBgColorArgs = {
    row: number;
    col: number;
    grid: ListGrid<UserView>;
};

class UserView {
    constructor(
        myself: EntitiesUser,
        user: EntitiesUser,
        groups: { [groupId: string]: string },
        role: EntitiesRole
    ) {
        this.myself = myself;
        this.user = user;
        this.groups = groups;
        this.role = role;
    }

    private myself: EntitiesUser;
    private user: EntitiesUser;
    private groups: { [areaId: string]: string };
    private role: EntitiesRole;

    get uuid(): string {
        return this.user.uuID;
    }
    get firstName(): string {
        return this.user.firstName;
    }
    get familyName(): string {
        return this.user.familyName;
    }
    get email(): string {
        return this.user.mailAddress;
    }
    get group(): string {
        return this.user.areas.map((areaId) => this.groups[areaId.toString()]).join(", ");
    }
    get roleLv(): number {
        return this.user.roleLv;
    }
    get viewRange(): number {
        return this.role.viewRange;
    }
    get roleName(): string {
        return this.role.caption;
    }
    get mfaEnabled(): boolean {
        return this.user.useMFA;
    }
    get editCaption(): string {
        return wordDictionary.users.edit.title;
    }
    get deleteCaption(): string {
        if (this.myself.uuID === this.user.uuID) {
            return "";
        }
        return wordDictionary.users.deleteButtonName;
    }
}

@Component({})
class Users extends Vue {
    poiGroupId: string;
    isAdmin: boolean = false;
    loading: boolean = false;
    user = getter().user;
    roleList = getter().roleList;
    areas = getter().areas;
    addSnackbarMessages = useSnackbar().addSnackbarMessages;

    // APIから取得した全てのユーザー情報
    users: UserView[] = [];
    // フィルターを適用した後のユーザー情報
    userRecord: UserView[] = [];

    dict = wordDictionary.users;

    // cheetah-gridのカスタムテーマ設定
    customTheme = {
        checkbox: {
            borderColor: currentTheme().vuetifyTheme.colors.primary,
        },
        defaultBgColor({ col, row, grid }: DefaultBgColorArgs): string {
            if (col < grid.frozenColCount || row < grid.frozenRowCount) {
                return "#f0f0f0";
            }
            return "#ffffff";
        },
        button: {
            color: currentTheme().vuetifyTheme.colors.primaryInvert,
            bgColor: currentTheme().vuetifyTheme.colors.primary,
        },
    };
    gridFontSize = 12.8;
    gridFont = `${this.gridFontSize}px sans-serif`;
    searchWord = "";
    initialized: boolean = false;

    // 直前に行ったソート順と対象列の保存
    private customSortState: CustomSortState = {
        col: 0,
        order: null,
    };
    // cheetah-gridヘッダ部をクリックされたイベント
    sortColumn(order: number, col: number, grid: ListGrid<UserView>): void {
        if (col !== this.customSortState.col) {
            // 直前のと違う列をソートしようとした場合はorderを初期化する
            this.customSortState.order = "desc";
            this.customSortState.col = col;
        } else {
            switch (this.customSortState.order) {
                case null:
                    this.customSortState.order = "desc";
                    break;
                case "desc":
                    this.customSortState.order = "asc";
                    break;
                case "asc":
                    this.customSortState.order = null;
            }
        }
        // 実際にデータをソートする
        this.sortRecord(col, this.customSortState.order);
        // ↑↓マーク制御
        grid.sortState.order = this.customSortState.order;
    }
    // クリックされたカラムでソート処理を実行
    private sortRecord(col: number, order: string | null = "asc"): void {
        let orderVal: number = 1;
        // カラムの並び順
        const keys: string[] = [
            "email",
            "firstName",
            "familyName",
            "group",
            "viewRange", // ロール名でソートしても分かりづらいのでviewRangeでソートする
        ];
        let key = keys[col];
        if (order === null) {
            // 昇りでも降りでもない場合は初期の並び順であるemailの昇り順でのソートになる
            key = keys[0];
        } else if (order === "asc") {
            orderVal = -1;
        }
        this.users = this.users.sort((a, b) => {
            const aDash: string | number | boolean = a[key];
            const bDash: string | number | boolean = b[key];
            if (aDash > bDash) {
                return 1 * orderVal;
            } else {
                return -1 * orderVal;
            }
        });
        this.searchUsers();
    }
    searchUsers(): void {
        const userRecords: UserView[] | null = this.filterBySearchWord(this.users, this.searchWord);
        if (userRecords === null) {
            this.addSnackbarMessages({
                text: "不正な検索キーワードです",
                color: "danger",
            });
            return;
        }
        this.userRecord = userRecords;
        // この変数でcheetah-gridの遅延レンダリングを行っている
        // 遅延させないと、左のメニュー領域分の計算が狂ってスクロールバーが表示されてしまう
        this.initialized = true;
    }
    filterBySearchWord(allUsers: UserView[], searchWord: string): UserView[] | null {
        const rawUserRecords = allUsers;
        let records: UserView[] = [];
        const trimmedSearchWord = searchWord?.trim().toLowerCase();
        if (trimmedSearchWord && trimmedSearchWord !== "") {
            records = rawUserRecords.filter((user) => {
                if (user.firstName.toLowerCase().includes(trimmedSearchWord)) {
                    return true;
                }
                if (user.familyName?.toLowerCase().includes(trimmedSearchWord)) {
                    return true;
                }
                if (user.email?.toLowerCase().includes(trimmedSearchWord)) {
                    return true;
                }
                if (user.group?.toLowerCase().includes(trimmedSearchWord)) {
                    return true;
                }
                if (user.roleName?.toLowerCase().includes(trimmedSearchWord)) {
                    return true;
                }
                return false;
            });
        } else {
            records = rawUserRecords;
        }
        return records;
    }
    async created(): Promise<void> {
        this.poiGroupId = this.$route.params.poiGroupId as string;
        this.isAdmin = this.user.poiGroupID === 0;
        await this.fetchUsers();
    }

    // MFASetting 権限を持っている場合 true を返す
    isMFASetting(): boolean {
        const roles = this.roleList?.find((x) => x.roleLv === this.user.roleLv);
        if (roles === undefined) {
            // roleが見つからない
            return false;
        }
        // mfasetting:post があれば有効と判定
        return roles.functions?.indexOf("mfasetting:post") >= 0;
    }

    async fetchUsers(): Promise<void> {
        this.loading = true;
        try {
            const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/users`;
            const response = await requiredAuth<EntitiesUsersResponse>("get", url);
            if (response == null || response.data == null || response.data.users == null) {
                return;
            }
            this.users = response.data.users.map((user) => {
                const u: EntitiesUser = {
                    ...user,
                    poiGroupID: user.poiGroupID || 0, // poiGroupID が 0 だとレスポンスから省かれてしまうため、ここで設定する
                    areas: user.areas || [], // areas が空だとレスポンスから省かれてしまうため、ここで追加する
                };
                const r = this.roleList.find((x) => x.roleLv === u.roleLv);
                return new UserView(this.user, u, this.areas, r);
            });
            this.userRecord = this.users;
        } catch (e) {
            console.error(e);
            throw Error("ユーザー情報の検索に失敗しました。");
        } finally {
            this.loading = false;
        }
    }

    generateErrorMessage(e: AxiosError<any>): string {
        const err = e.response.data.errorMessage;
        if (err.includes("UserNotFoundException")) {
            return wordDictionary.users.generateErrorMessage_UserNotFoundException_message;
        } else if (err.includes("ConditionalCheckFailedException")) {
            return wordDictionary.users.generateErrorMessage_delete_unable;
        } else if (err.includes("exceeds")) {
            return wordDictionary.users.generateErrorMessage_exceeds_message;
        } else {
            throw Error(wordDictionary.users.generateErrorMessage_message);
        }
    }

    deleteConfirm(row: UserView): void {
        useDialog().confirm({
            title: "ユーザ削除 : " + row.familyName + " " + row.firstName,
            message: "本当に削除してよろしいですか?",
            type: "error",
            hasIcon: true,
            onConfirm: async () => await this.deleteUser(row.uuid),
        });
    }

    async editUser(row: UserView): Promise<void> {
        this.$router.push({
            name: "UsersEdit",
            params: {
                poiGroupId: this.poiGroupId,
                userId: row.uuid,
            },
        });
    }

    async deleteUser(userId: string): Promise<void> {
        this.loading = true;
        const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
            this.poiGroupId
        }/users/${userId}/${this.isAdmin}`;
        try {
            const response = await requiredAuth<EntitiesUser>("delete", url, null, {});
            if (response.status === 200) {
                this.addSnackbarMessages({
                    text: this.dict.desc_deleted,
                    color: "success",
                });
            }
            await this.fetchUsers();
        } catch (e) {
            console.error(e);
            throw Error(this.generateErrorMessage(e as AxiosError));
        } finally {
            this.loading = false;
        }
    }

    isMyself(row: UserView): boolean {
        return row.uuid === this.user.uuID;
    }
}
export default toNative(Users);
