<template>
  <v-dialog v-model="isShow" class="info" width="800">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ imageName }}
      </v-card-title>

      <v-spacer />

      <v-row justify="center" class="media">
        <video v-if="isVideo" :src="url" controls autoplay muted class="video"></video>
        <v-img v-else :src="url" :max-height="600" :max-width="700" :contain="true" />
      </v-row>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text="閉じる" variant="text" @click.stop="close" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { Component, Vue, toNative } from "vue-facing-decorator";
import { isVideoExtension } from "@/models/v2-file-selection";

@Component({})
class V2PostFileImageDialog extends Vue {
  isShow: boolean = false;
  imageName: string = "";
  url: string = "";

  // 動画の場合は動画を再生できるようにする
  get isVideo(): boolean {
    try {
      const ext = this.url.split(".").pop().split("?")[0]; // 末尾の "?counter=" を除去

      if (isVideoExtension(ext)) {
        return true;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  /**
   * 画像・動画プレビューのためのダイアログを表示する
   * @param imageName - 対象の画像・動画のファイル名
   * @param url - 対象の画像・動画のURL
   * */
  show(imageName: string, url: string): void {
    this.imageName = imageName;
    this.url = url;
    this.isShow = true;
  }

  close(): void {
    this.isShow = false;
    this.imageName = "";
    this.url = "";
  }
}
export default toNative(V2PostFileImageDialog);
</script>
<style lang="scss" scoped>
.media {
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: unset !important;
  margin-left: unset !important;
}
.video {
  width: 100%;
  max-width: 700px;
}
</style>
