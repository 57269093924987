<template>
  <header class="mb-3">
    <div class="my-1 ml-1">
      <span class="font-weight-bold text-h6">投稿履歴</span>
      <router-link v-if="canManage" :to="{ name: 'V2Posts' }">
        <v-btn class="primary ml-4 mb-2" size="small" variant="text">投稿を作成</v-btn>
      </router-link>
    </div>
    <v-card class="px-2" elevation="0">
      <div class="my-3">
        <date-range-picker v-model="range" class="input-box" />
      </div>
      <div class="my-3">
        <v-text-field
          v-model="word"
          data-testid="search-word"
          label="検索キーワード"
          variant="underlined"
          density="compact"
          single-line
          hide-details
          clearable
          prepend-inner-icon="mdi-magnify"
          color="primary"
          @keypress.enter="filter"
          @click:clear="clearSearchWord"
        >
          <template #append>
            <v-btn
              class="search-button primary"
              data-testid="filter-by-search-word"
              size="small"
              @click="filter"
            >
              絞り込み
            </v-btn>
          </template>
        </v-text-field>
      </div>
    </v-card>
    <div class="d-flex justify-end my-4 mr-2">
      <span class="mx-4">{{ showHistories }}件表示中</span>
      <v-btn
        size="medium"
        icon="mdi-cached"
        :disabled="asyncLoading"
        variant="text"
        color="primary"
        @click="reload"
      />
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HistoriesHeader from "./histories-header.vue";
import DateRangePicker from "@/components/shared/date-picker/DateRangePicker.vue";

export default defineComponent({
  components: {
    DateRangePicker,
  },
  extends: HistoriesHeader,
});
</script>

<style lang="scss" scoped></style>
