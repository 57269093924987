<template>
  <div>
    <v-container class="post-preview">
      <v-card :disabled="!step.enabled" :readonly="readonly" color="transparent" elevation="0">
        <v-form ref="form" v-model="isValidateForm">
          <v-row class="preview-row">
            <fb-header :target-name="targetName" />
            <v-row align="center">
              <v-col cols="12" style="padding: 10px 20px">
                <v-textarea
                  v-model="text"
                  :rules="[rules.maxLength(63206), rules.maxHash(30)]"
                  hint="ハッシュタグは30個まで追加可能です"
                  rows="1"
                  auto-grow
                  variant="underlined"
                  hide-details="auto"
                  persistent-counter
                  class="detail-text"
                  color="primary"
                  counter="63206"
                  :readonly="readonly"
                />
              </v-col>
            </v-row>
            <fb-images
              :items="files"
              :is-mobile="isMobile"
              :readonly="readonly"
              @edit-image="editImage"
            />
            <v-row v-if="fileErrorMessages != ''" align="center" class="my-1">
              <p class="error-message">{{ fileErrorMessages }}</p>
            </v-row>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script lang="ts">
import type { StepStatus } from "../post-stepper.vue";
import FbHeader from "./fb-header.vue";
import FbImages from "./fb-images.vue";
import type { FileSelectionItem } from "@/models/v2-file-selection";
import type { FileSelectionState } from "@/models/v2-file-selection";
import {
  platformOfficialNames,
  sortFileSelectionItemList,
  isImage,
  isVideo,
} from "@/models/v2-file-selection";
import { VuetifyValidator } from "@/helpers";
import { validateFile } from "../validator";
import { defineComponent } from "vue";

export default defineComponent({
  components: { FbHeader, FbImages },
  props: {
    modelValue: { type: String, required: true }, // v-model
    baseText: { type: String, required: true },
    targetName: { type: String, required: true },
    fileSelections: { type: Array as () => FileSelectionItem[], required: true },
    step: { type: Object as () => StepStatus, required: true },
    aspectNoCheck: { type: Boolean },
    isMobile: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  emits: ["update:modelValue", "edit-image", "update-step"],
  data: () => ({
    isValidateForm: false,
    fileErrorMessages: "",
    rules: VuetifyValidator,
    text: "",
    files: [] as FileSelectionItem[],
  }),
  watch: {
    isValidateForm: {
      handler() {
        this.isCompleted();
      },
    },
    baseText: {
      immediate: true,
      handler() {
        this.text = this.baseText;
      },
    },
    text: {
      handler() {
        this.$emit("update:modelValue", this.text);
        this.validateFiles();
      },
    },
    fileSelections: {
      deep: true,
      immediate: true,
      async handler() {
        // 選択済みのファイルを順番通りにソートした上でfilesに格納する
        this.files = sortFileSelectionItemList(this.fileSelections);
        // 画像選択されたらプレビューを有効化する
        if (this.files.length > 0 && !this.$props.step.enabled) {
          this.$props.step.enabled = true;
        }
        await this.validateFiles();
      },
    },
    step: {
      deep: true,
      immediate: true,
      async handler() {
        await this.validateFiles();
      },
    },
  },
  methods: {
    isCompleted(): void {
      this.$emit("update-step", {
        isComplete: this.isValidateForm && this.fileErrorMessages == "",
        errorMessage: `${platformOfficialNames.facebook}への投稿では${this.fileErrorMessages}`,
      });
    },
    async validateFiles(): Promise<void> {
      // vue 上でpropが変更されるのを待つ
      await this.$nextTick();
      if (!this.$props.step.enabled) {
        this.fileErrorMessages = "";
        return;
      }
      // 画像もテキストも無い
      if (this.files.length == 0 && this.text == "") {
        this.fileErrorMessages = "画像・動画を選択するか投稿内容の入力が必要です";
        this.$emit("update-step", {
          errorMessage: `${platformOfficialNames.facebook}への投稿では${this.fileErrorMessages}`,
        });
        this.isCompleted();
        return;
      }
      // FBで画像と動画を同時に選択している(動画は1本のみ)
      const allImage = this.files.every((f) => isImage(f.file));
      const validVideo = this.files.every((f) => isVideo(f.file)) && this.files.length == 1;
      if (!allImage && !validVideo) {
        this.fileErrorMessages = "画像と動画の同時投稿には対応しておりません";
        this.$emit("update-step", {
          errorMessage: `${platformOfficialNames.facebook}への投稿では${this.fileErrorMessages}`,
        });
        this.isCompleted();
        return;
      }
      // 画像のアスペクト比やサイズのチェック
      var errorMessages: string[] = [];
      for (const file of this.files) {
        await validateFile(file, this.files.length, "facebook", errorMessages, this.aspectNoCheck);
      }
      // 冗長なのでpreviewに表示するエラー文は短くする
      this.fileErrorMessages = errorMessages
        .map((m) => m.replace(platformOfficialNames.facebook, ""))
        .join("\n");
      this.$emit("update-step", { errorMessage: errorMessages.join("\n") });
      this.isCompleted();
    },
    editImage(order: FileSelectionState): void {
      // EditImageSelectedを作ってposts.vueのイベントを親コンポーネントを経由して発火させる
      this.$emit("edit-image", { platform: "facebook", order: order });
    },
  },
});
</script>

<style lang="scss" scoped>
.post-preview {
  max-width: 340px;
  padding: 0;
  margin-top: 12px;
  margin-bottom: 1rem;
}

.row {
  margin-top: 0 !important;
  margin-bottom: 0;
}

.preview-row {
  margin: 0;
  padding: 0 16px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 2px 3px #00000029;
  border-radius: 6px;
  opacity: 1;
}

.detail-text {
  padding-top: 0;
  border: #707070;
  font-size: 0.8em;
}

.error-message {
  color: red;
  font-size: 0.75rem;
  white-space: pre-wrap;
  padding-left: 1rem;
}
</style>
