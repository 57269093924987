import { TOAST_CRITICAL_DURATION } from "../const";
import { getVideoThumbnail, getDummyImageUrl } from "@/helpers/video";
import { useSnackbar } from "@/storepinia/snackbar";

export type PlatformName = "google" | "yahoo" | "instagram" | "facebook";
export const platformList: PlatformName[] = ["google", "yahoo", "instagram", "facebook"];
export const platformOfficialNames: { [key in PlatformName]: string } = {
    google: "Google Business Profile",
    yahoo: "Yahoo!プレイス",
    instagram: "Instagram",
    facebook: "Facebook",
};

// number は選択された画像の掲載順、"deselected"は非選択、"disabled"は選択不可を表す
export type FileSelectionState = number | "deselected" | "disabled" | "rejected";
export type FileNameObj = { fullName: string; fileName: string; ext: string };
export type FileRevisions = { google: number; yahoo: number; instagram: number; facebook: number };
export type FileSelectionItem = {
    file: File;
    imageUrl: string;
    videoUrl: string;
    s3FileName: string;
    state: FileSelectionState;
    rejectMessage: string;
    // ユーザーがアップロードした画像ファイル。
    // レイアウト保存で新規保存の際はこれとrevisionを基に新規ファイル名を命名する為に用いる
    ancestor?: FileNameObj;
    revision?: FileRevisions;
};

export type FileSelectionItemWithPlatform = FileSelectionItem & {
    pf: string;
};

export type CandidateFileItem = FileSelectionItemWithPlatform & {
    google: FileSelectionItemWithPlatform;
    yahoo: FileSelectionItemWithPlatform;
    instagram: FileSelectionItemWithPlatform;
    facebook: FileSelectionItemWithPlatform;
};

export type FileSelectionMap = Map<PlatformName, FileSelectionItem[]>;

export type GMBPostType = "info" | "event" | "benefits" | "product" | "covid19";

export function getExtension(fileName: string): string {
    const idx = fileName.lastIndexOf(".");
    if (idx === -1) return "";
    return fileName.slice(idx + 1).toLowerCase();
}

export function getFileNameWithExtension(fileNameStr: string): FileNameObj {
    const fileNameObj = fileNameStr.match(/(.+)\.(\w+)$/);
    if (fileNameObj) {
        return {
            fullName: fileNameObj[0],
            fileName: fileNameObj[1],
            ext: fileNameObj[2].toLowerCase(),
        };
    } else {
        return null;
    }
}

export type IsVideoFunc = (file: File) => boolean;
// File.typeから動画かどうかを判定する
export function isVideo(file: File): boolean {
    const isMovFile: boolean = file.type == "video/quicktime";
    const isMp4File: boolean = file.type == "video/mp4";
    return isMovFile || isMp4File;
}

export function isVideoExtension(ext: string): boolean {
    const isMovExt: boolean = ext === "mov";
    const isMp4Ext: boolean = ext === "mp4";
    return isMovExt || isMp4Ext;
}

export function isImage(file: File): boolean {
    return file.type == "image/jpeg" || file.type == "image/png";
}

export function getMimeType(ext: string): string {
    var mime = "";
    if (ext === "jpg" || ext === "jpeg") {
        mime = "image/jpeg";
    } else if (ext === "png") {
        mime = "image/png";
    } else if (ext === "mov" || ext === "mp4") {
        mime = "video/quicktime";
    }
    return mime;
}

export async function getObjectURL(file: File, ext: string): Promise<[string, string]> {
    var imageURL = "";
    var videoURL = "";

    const isVideo: boolean = ext === "mov" || ext === "mp4";
    if (!isVideo) {
        imageURL = window.URL.createObjectURL(file);
        return [imageURL, videoURL];
    }

    const videoThumbnail = await getVideoThumbnail(file);
    if (videoThumbnail == "") {
        useSnackbar().addSnackbarMessages({
            text: "ブラウザが未対応の動画形式です。投稿はできますがプレビューできません。",
            color: "warning",
            timeout: TOAST_CRITICAL_DURATION,
        });

        videoURL = URL.createObjectURL(file);
        imageURL = getDummyImageUrl("プレビューできない\n動画形式\n\n\n投稿は可能です");
        return [imageURL, videoURL];
    }
    videoURL = URL.createObjectURL(file);
    imageURL = videoThumbnail;
    return [imageURL, videoURL];
}

export async function getVideoElement(videoURL: string): Promise<any> {
    return new Promise((resolve, reject) => {
        let elem: HTMLVideoElement;
        const element = document.createElement("video");
        element.ondurationchange = (event) => {
            const path = event.composedPath && event.composedPath();
            if (path) {
                elem = path[0] as HTMLVideoElement;
            }
            resolve(elem);
        };
        element.onerror = (e) => reject(e);
        element.src = videoURL;
    });
}

export type EditImageSelected = { platform: PlatformName; order: number };

export type AspectRatio = { label: string; value: number };
export const arSquare: AspectRatio = { label: "1：1", value: 1 / 1 };
export const arTwoFirst: AspectRatio = { label: "2：1", value: 2 / 1 };
export const arFourThirds: AspectRatio = { label: "4：3", value: 4 / 3 };
export const arFourFifth: AspectRatio = { label: "4：5", value: 4 / 5 };
export const arFourNinth: AspectRatio = { label: "4：9", value: 4 / 9 };
export const arSixteenNinth: AspectRatio = { label: "16：9", value: 16 / 9 };
export const arNinetyFirst: AspectRatio = { label: "1.91 : 1", value: 1.91 / 1 };
export const arNineSixteenth: AspectRatio = { label: "9：16", value: 9 / 16 };
export const aspectRatioList: Array<AspectRatio> = [
    arSquare,
    arFourThirds,
    arSixteenNinth,
    arNinetyFirst,
];

export type TextareaOption = {
    defaultContent: string;
    textColor: string;
    font: string;
    hashtagBackgroundColor: string;
    hashtagColor: string;
    placeholder: string;
    isEditMode: boolean;
};

// FileSelectionItem[]を選択済みのものだけにフィルタし、番号順にソートする
export function sortFileSelectionItemList(list: FileSelectionItem[]): FileSelectionItem[] {
    return list
        .filter((i) => typeof i.state === "number")
        .sort((a, b) => {
            if (a.state < b.state) return -1;
            if (a.state > b.state) return 1;
            return 0;
        });
}
