import { Component, Vue, Prop, toNative } from "vue-facing-decorator";
import dayjs from "dayjs";
import type {
    StorageHealthCheck,
    StorageHealthCheckMetrics,
    StorageHealthCheckMetricsResponse,
    StorageHealthCheckResponse,
} from "@/types/ls-api";
import DateRangePicker from "@/components/shared/date-picker/DateRangePicker.vue";
import { requiredAuth } from "@/helpers";
import { headerCalcWidth } from "@/helpers/header";
import wordDictionary from "@/word-dictionary";
import { getter, useIndexedDb } from "@/storepinia/idxdb";
import { useSs } from "@/storepinia/ss";
import type { VDataTable } from "vuetify/components/index.mjs";

interface TableData extends StorageHealthCheck, StorageHealthCheckMetrics {
    poiGroupId: number;
    updateCountSum: number;
    metricsCurrent: StorageHealthCheckMetrics;
    metricsPrev: StorageHealthCheckMetrics;
}
type Header = Pick<VDataTable["headers"][number], "key" | "title" | "align" | "width">;

const columnWidth = 100;
@Component({ components: { DateRangePicker } })
class HealthCheck extends Vue {
    @Prop({ type: Date }) dateTo: Date;
    @Prop({ type: Date }) dateFrom: Date;
    footerProps = {
        itemsPerPageText: "表示件数",
    };
    headers = [
        {
            title: "企業名",
            align: "start",
            key: "componyName",
            width: columnWidth * 2,
        },
        {
            title: "ID",
            align: "none",
            key: "poiGroupId",
            width: columnWidth,
        },
        {
            title: "ビジネス情報（店舗情報の一括管理）",
            key: "bulkStores",
            width: columnWidth,
        },
        { title: "画像・動画一括登録", key: "bulkMedia", width: columnWidth },
        { title: "サイテーション", key: "citation", width: columnWidth },
        { title: "口コミ", key: "review", width: columnWidth },
        { title: "投稿を作成", key: "post", width: columnWidth },
        {
            title: "店舗情報の一括管理＞構造化用情報",
            key: "structure",
            width: columnWidth,
        },
        { title: "メイン担当のログイン回数", key: "inChargeLogins", width: columnWidth },
        { title: "判定", key: "filler1", width: columnWidth },
        {
            title: "①ビジネス情報の更新（回数)",
            key: "businessUpdates",
            width: columnWidth,
        },
        { title: "判定", key: "filler2", width: columnWidth },
        { title: "②画像登録（回数)", key: "mediaUploads", width: columnWidth },
        { title: "判定", key: "filler3", width: columnWidth },
        { title: "③口コミ返信（回数)", key: "reviewReplies", width: columnWidth },
        { title: "判定", key: "filler4", width: columnWidth },
        { title: "④投稿 (回数)", key: "posts", width: columnWidth },
        { title: "判定", key: "filler5", width: columnWidth },
        {
            title: "GBP更新提案検出（回数）",
            key: "gmbStoresUpdateCreate",
            width: columnWidth,
        },
        { title: "①②③④合計", key: "updateCountSum", width: columnWidth },
        { title: "全体更新回数判定", key: "filler6", width: columnWidth },
        { title: "閾値以上の更新ができている機能数", key: "filler7", width: columnWidth },
        { title: "ヘルスチェック対象機能数", key: "filler8", width: columnWidth },
        {
            title: "①~④の閾値以上の更新率（判定〇数/開放機能数）",
            key: "filler9",
            width: columnWidth,
        },
        { title: "ヘルススコアステージ", key: "filler10", width: columnWidth },
        { title: "表示回数", key: "metricsCurrent.views", width: columnWidth },
        {
            title: "サイトアクセス数",
            key: "metricsCurrent.actionsWebsite",
            width: columnWidth,
        },
        {
            title: "ルートの照会数",
            key: "metricsCurrent.actionsDrivingDirections",
            width: columnWidth,
        },
        { title: "通話数", key: "metricsCurrent.actionsPhones", width: columnWidth },
        { title: "合計成果数", key: "metricsCurrent.totalAction", width: columnWidth },
        { title: "表示回数（前年）", key: "metricsPrev.views", width: columnWidth },
        {
            title: "サイトアクセス数（前年）",
            key: "metricsPrev.actionsWebsite",
            width: columnWidth,
        },
        {
            title: "ルートの照会数（前年）",
            key: "metricsPrev.actionsDrivingDirections",
            width: columnWidth,
        },
        { title: "通話数（前年）", key: "metricsPrev.actionsPhones", width: columnWidth },
        {
            title: "合計成果数（前年）",
            key: "metricsPrev.totalAction",
            width: columnWidth,
        },
    ] as Header[];

    company = getter().company;
    range = {
        from: useSs().healthCheckAggregationStartDate,
        to: useSs().healthCheckAggregationEndDate,
    };

    tableDatas: TableData[] = [];
    loading: boolean = false;
    reportName: string = "ヘルスチェック";
    dict = wordDictionary.healthCheck;

    async created(): Promise<void> {
        this.loading = true;
        this.tableDatas = await createTableData(
            this.company.poiGroupID,
            this.range.from,
            this.range.to
        );
        this.loading = false;
    }
    async search(): Promise<void> {
        useSs().healthCheckAggregationStartDate = this.range.from;
        useSs().healthCheckAggregationEndDate = this.range.to;
        this.loading = true;
        this.tableDatas = await createTableData(
            this.company.poiGroupID,
            this.range.from,
            this.range.to
        );
        this.loading = false;
    }

    getOptionSymbol(haveOption: boolean): string {
        return haveOption ? "○" : "";
    }

    createHealthCheckLogURL(poiGroupId: number, logType: string): string {
        return `health-check/${poiGroupId}/health-check-logs?type=${logType}`;
    }
    // サイドバーの有無で幅を調整する
    get headerCalcWidth(): number {
        return headerCalcWidth(useIndexedDb().isDrawerOpened);
    }
}
export default toNative(HealthCheck);

async function callHealthChecksAPI(
    poiGroupId: number,
    startDate: string,
    endDate: string
): Promise<StorageHealthCheckResponse> {
    const res = await requiredAuth<StorageHealthCheckResponse>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/healthChecks`,
        {
            startDate: startDate,
            endDate: endDate,
        }
    );
    return res.data;
}

async function callHealthCheckMetricsAPI(
    poiGroupId: number,
    startDate: string,
    endDate: string
): Promise<StorageHealthCheckMetricsResponse> {
    const loop = true;
    let i = 0;
    while (loop) {
        i++;
        const res = await requiredAuth<StorageHealthCheckMetricsResponse>(
            "get",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/healthCheckMetrics`,
            {
                startDate: startDate,
                endDate: endDate,
            }
        );
        if (res.data.status === "created") {
            return res.data;
        }
        if (res.data.status === "error") {
            throw Error("metricsデータ取得エラー:" + res.data.message);
        }
        if (i > 500) {
            throw Error("metricsデータ取得タイムアウト:" + res.data.message);
        }
        await new Promise((resolve) => setTimeout(resolve, 1000)); // 1sec wait
    }
}

async function createTableData(
    poiGroupId: number,
    dateFrom: string,
    dateTo: string
): Promise<TableData[]> {
    const healthCheckData = await callHealthChecksAPI(poiGroupId, dateFrom, dateTo);
    if (!healthCheckData.item) {
        return [];
    }
    const healthCheckMetricsDataCurrent = await callHealthCheckMetricsAPI(
        poiGroupId,
        dateFrom,
        dateTo
    );
    const healthCheckMetricsDataPrev = await callHealthCheckMetricsAPI(
        poiGroupId,
        dayjs(dateFrom).add(-1, "year").format("YYYY-MM-DD"),
        dayjs(dateTo).add(-1, "year").format("YYYY-MM-DD")
    );
    const tableData: TableData[] = healthCheckData.item?.map((i) => {
        return Object.assign(
            {
                poiGroupId: i.poiGroupId,
                updateCountSum: i.businessUpdates + i.mediaUploads + i.reviewReplies + i.posts,
                metricsCurrent: healthCheckMetricsDataCurrent.item?.find(
                    (x) => x.poiGroupId === i.poiGroupId
                ),
                metricsPrev: healthCheckMetricsDataPrev.item?.find(
                    (x) => x.poiGroupId === i.poiGroupId
                ),
            },
            i
        );
    });
    return tableData;
}
