<template>
  <header class="v2histories-header">
    <div class="v2histories-header-left">
      <date-range-picker v-model="range" class="input-box" />
      <v-text-field
        v-model="word"
        data-testid="search-word"
        label="検索キーワード"
        variant="underlined"
        density="compact"
        single-line
        hide-details
        clearable
        prepend-inner-icon="mdi-magnify"
        color="primary"
        style="width: 200px; max-width: 200px"
        @keypress.enter="filter"
        @click:clear="clearSearchWord"
      />
      <v-btn
        class="search-button primary"
        data-testid="filter-by-search-word"
        size="small"
        @click="filter"
      >
        絞り込み
      </v-btn>

      <span class="filter-count">{{ showHistories }}件表示中</span>
      <v-btn class="primary xlsx-export" :elevation="0" size="small" @click="exportFile">
        XLSXエクスポート
      </v-btn>
    </div>
    <div v-if="canManage" class="v2histories-header-right">
      <v-btn
        :elevation="0"
        class="reload-button"
        icon="mdi-cached"
        :disabled="asyncLoading"
        @click="reload"
      />
      <router-link :to="{ name: 'V2Posts' }" class="button is-primary v2post_register">
        投稿を作成
      </router-link>

      <router-link v-slot="{ navigate }" :to="{ name: 'V2PostFile' }">
        <v-btn :elevation="0" class="followed file-post-button" @click="navigate">
          ファイルでの投稿
        </v-btn>
      </router-link>
      <router-link v-slot="{ href, navigate }" :to="{ name: 'V2PostTemplate' }">
        <a :href="href" class="v2post_template" @click="navigate">投稿テンプレートの作成</a>
      </router-link>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useIndexedDb } from "@/storepinia/idxdb";
import DateRangePicker from "@/components/shared/date-picker/DateRangePicker.vue";
import type { DateRange } from "@/components/shared/date-picker/DateRangePicker.vue";

export default defineComponent({
  components: {
    DateRangePicker,
  },
  props: {
    searchWord: {
      type: String,
      default: "",
    },
    searchRange: {
      type: Object as () => DateRange,
      required: true,
    },
    showHistories: {
      type: Number,
      default: 0,
    },
    asyncLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:searchWord", "update:searchRange", "filter", "clear", "export", "reload"],
  computed: {
    canManage(): boolean {
      return useIndexedDb().canManagePost;
    },
    word: {
      get(): string {
        return this.searchWord;
      },
      set(val: string) {
        this.$emit("update:searchWord", val);
      },
    },
    range: {
      get(): DateRange {
        return this.searchRange;
      },
      set(val: DateRange) {
        this.$emit("update:searchRange", val);
      },
    },
  },
  methods: {
    filter(): void {
      this.$emit("filter");
    },
    clearSearchWord(): void {
      this.$emit("clear");
    },
    exportFile(): void {
      this.$emit("export");
    },
    reload(): void {
      this.$emit("reload");
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;
.v2histories-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;

  .input-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 3px 20px 0 0;
    font-size: 14px;
  }

  .v2histories-header-left {
    display: flex;
    align-items: center;
    padding-top: 12px;
    .search-box {
      margin-top: 8px;
      padding-top: 0;
    }
    .search-button {
      margin-left: 20px;
      width: 100px;
    }
    .filter-count {
      margin-left: 5px;
      min-width: 110px;
    }

    .xlsx-export {
      margin: 0 0 0 20px;
    }
  }

  .v2histories-header-right {
    button,
    a {
      vertical-align: middle;
    }
    button {
      margin-right: 5px;
    }
    .v2post_register {
      margin-right: 0.5em;
    }
    a:not(.v2post_register) {
      display: inline-block;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.file-post-button {
  font-size: 16px;
  letter-spacing: 0;
}
.v2post_template {
  color: color.$primary;
}
.reload-button {
  color: color.$primary;
  background-color: transparent;
}
</style>
