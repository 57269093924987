<template>
  <div>
    <div class="px-1 mobile-review-search-conditions">
      <v-select
        v-model="reviewType"
        :items="reviewTypes"
        item-title="text"
        item-value="value"
        label="自社/競合"
      />
      <v-select
        v-model="commentExists"
        :items="commentExistsList"
        item-title="text"
        item-value="value"
        label="コメント有無"
      />
      <v-select
        v-model="replyStatus"
        :items="replyStatusList"
        item-title="text"
        item-value="value"
        label="返信状態"
      />
      <o-field horizontal data-testid="reviews-search-by-word" label="検索ワード" grouped>
        <o-input
          v-model="word"
          data-testid="reviews-search-by-word-box"
          placeholder="Search..."
          type="search"
        ></o-input>
      </o-field>
      <o-field horizontal data-testid="reviews-search-star-rating" label="星数">
        <v-btn-toggle v-model="starRatingsChecked" density="compact" multiple>
          <v-btn
            v-for="starRating in starRatings"
            :key="starRating"
            class="toggle-button"
            selected-class="toggle-button-selected"
            elevation="0"
            :data-testid="`reviews-search-star-rating-checkbox-${starRating}`"
            :value="starRating"
          >
            <i class="mdi mdi-star mdi-24px" />
            {{ starRating }}
          </v-btn>
        </v-btn-toggle>
      </o-field>
      <div class="d-flex justify-start align-center">
        <v-checkbox
          v-model="sortByReplyPriority"
          data-testid="reviews-search-sort-by-priority-checkbox"
          class="sort-by-reply-priority-checkbox"
          color="primary"
          hide-details
          label="返信優先度で並び替える"
        />
        <ToolTipIcon
          class="mobile-tooltip-icon"
          position="top"
          data-testid="reviews-search-sort-by-priority-tooltip"
          :label="replyPrioritySortTooltip"
        />
      </div>
    </div>
    <div class="d-flex align-center mt-2">
      <o-button
        data-testid="reviews-search-clear-button"
        class="button white-button mr-2"
        @click="clearSearchCondition"
      >
        クリア
      </o-button>
      <o-button
        data-testid="reviews-search-search-button"
        variant="primary"
        expanded
        @click="search"
      >
        検索
      </o-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import reviewSearchConditions from "./review-search-conditions.vue";

export default defineComponent({
  extends: reviewSearchConditions,
  data() {
    return {
      reviewTypes: [
        { text: "自社", value: "Own" },
        { text: "競合", value: "Rival" },
        { text: "自社/競合", value: "Both" },
      ],
      commentExistsList: [
        { text: "指定なし", value: "CommentUnspecified" },
        { text: "コメントあり", value: "Commented" },
        { text: "コメントなし", value: "NoComment" },
      ],
      replyStatusList: [
        { text: "指定なし", value: "ReplyUnspecified" },
        { text: "未返信", value: "NotReplied" },
        { text: "返信済み", value: "Replied" },
      ],
    };
  },
});
</script>

<style scoped lang="scss">
@use "@/components/style/color.scss" as color;

.mobile-review-search-conditions {
  padding: 0.5rem;
  background-color: color.$base-sidebar-color;
  border: 1px solid color.$base-main-color;
  border-radius: 5px !important;
}

:deep(.field-label) {
  font-size: 0.8rem;
  color: color.$graph-title !important;

  label {
    color: unset;
  }
}

.toggle-button {
  display: flex;
  margin: 0 1.5vw 0 0;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 900;
  letter-spacing: 0;
  border: 1px solid #000000 !important;
  border-radius: 2rem !important;

  .mdi-star {
    color: #ffd83d !important;
  }
}
.toggle-button-selected {
  color: color.$white;
  border-color: #40cdb0 !important;
  background-color: #4ad4be;

  .mdi-star {
    color: white !important;
  }
}

.sort-by-reply-priority-checkbox {
  font-size: 0.9rem;
  margin-left: -0.5rem;
  max-width: 13rem;

  :deep(label) {
    font-size: 0.9rem;
    opacity: unset;
  }
}

.mobile-tooltip-icon {
  padding-bottom: 0.2rem !important;
}
</style>
