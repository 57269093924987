<template>
  <div>
    <v-spacer />
    <!-- 折れ線グラフ -->
    <v-card-text>
      <div v-if="data.length === 0" class="no-data">データが存在しません</div>
      <div v-else class="wrapper">
        <v-btn-toggle
          v-model="chartMode"
          class="comparison-switch"
          density="compact"
          variant="outlined"
          mandatory
        >
          <v-btn value="own">自社表示</v-btn>
          <v-btn selected-class="rival-graph" value="rival">競合比較</v-btn>
        </v-btn-toggle>
        <div id="ranking-chart" />
      </div>
    </v-card-text>
    <v-overlay :model-value="loading" persistent contained class="align-center justify-center">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_ja_JP from "@amcharts/amcharts5/locales/ja_JP";
import { initChart } from "@/components/shared/am5-shared";
import { requiredAuth } from "@/helpers";
import { captureAndThrow } from "@/helpers/error";
import type { DomainPerformanceRankingResponse } from "@/types/ls-api";
import type { DomainPerformanceSeriesWith, StoreFilter } from "./performance";
import { fillColors, lineColors } from "./performance";

type ChartMode = "own" | "rival";

// コンポーネント定義
export default defineComponent({
  props: {
    storeFilter: {
      type: Object as () => StoreFilter,
      required: false,
      default: () => ({} as StoreFilter),
    },
  },
  data: () => {
    return {
      monthsToDisplay: 18 as number, // 表示する期間を過去18ヶ月に固定
      data: [] as DomainPerformanceSeriesWith[],
      loading: false as boolean,
      fillColors: fillColors,
      lineColors: lineColors,
      rivalColor: "#9747FF",
      chartMode: "own" as ChartMode,
      keywordLabels: [] as string[],
      targetKeyword: "",
      highestRank: 1,
      lowestRank: 20,
    };
  },
  computed: {
    poiGroupID: function (): number {
      return parseInt(this.$route.params.poiGroupId as string);
    },
  },
  watch: {
    chartMode: {
      handler: function (mode: ChartMode) {
        if (mode === "rival") {
          // 競合比較モード
          const firstItemName = this.targetKeyword;
          for (const item of this.data) {
            const series: am5xy.LineSeries = am5.registry.entitiesById[item.seriesId];
            if (item.name === firstItemName) {
              series?.show();
            } else {
              series?.hide();
            }
          }
        } else {
          // 自社表示モード
          for (const item of this.data) {
            const series: am5xy.LineSeries = am5.registry.entitiesById[item.seriesId];
            if (item.type === "rival") {
              // ライバルのチャートを隠す
              series?.hide();
            } else {
              series?.show();
            }
          }
        }
      },
    },
  },
  methods: {
    /* データ取得処理 */
    async fetch() {
      // リトライ処理を入れている
      for (let i = 0; i < 1000; i++) {
        this.loading = true;

        const dt1 = new Date().getTime();
        if (i > 0) {
          // 5sec wait
          let dt2 = new Date().getTime();
          const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));
          while (dt2 < dt1 + 5 * 1000) {
            await sleep(500);
            dt2 = new Date().getTime();
          }
        }

        await this.fetchRanking();
        if (!this.loading) {
          break;
        }
      }
      this.chartMode = "own";
      await this.createGraph();
    },
    async fetchRanking() {
      await requiredAuth<DomainPerformanceRankingResponse>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupID}/performance/ranking`,
        this.storeFilter
      )
        .then((res) => {
          this.data = res?.data?.seriesList ?? [];
          this.fillMissingData();
          this.loading = false;
        })
        .catch((e) => {
          if (e.response?.status === 503) {
            console.log("503エラー、5秒後にリトライ");
          } else {
            captureAndThrow("ランキング取得エラー", e);
          }
        });
    },
    /* 欠損しているデータをnull埋めする */
    fillMissingData() {
      const monthArray: string[] = [];
      const result: DomainPerformanceSeriesWith[] = [];
      this.keywordLabels = [];

      // 当月含む過去18ヶ月の月の配列を生成
      for (let i = 0; i < this.monthsToDisplay; i++) {
        const date = new Date();
        date.setMonth(date.getMonth() - i);
        // 以下の形式は"2006-01-01"
        monthArray.push(
          `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-01`
        );
      }
      monthArray.reverse();

      // 自社が常に先頭に並ぶ様にする
      this.data.sort((a, b) => {
        return a.type.localeCompare(b.type);
      });

      // データをループし、欠損している月のデータをnullで埋める
      this.data.forEach((d, index) => {
        const filledData = [];
        monthArray.forEach((m) => {
          const targetData = d?.data.find((datum) => datum?.date === m);
          if (targetData) {
            if (targetData.value === 0) {
              // 0だと1位より上に向かってグラフ描画されてしまうので最下位(20位)に置き換える
              targetData.value = this.lowestRank;
            }
            filledData.push(targetData);
          } else {
            filledData.push({ date: m, value: null });
          }
        });

        if (d.type === "own") {
          this.keywordLabels.push(d.name);
        }

        result.push({
          name: d.name,
          data: filledData,
          type: d.type,
          index,
        });
      });
      // データを書き換える
      this.data.splice(0, this.data.length, ...result);
      this.targetKeyword = this.keywordLabels[0];
    },
    /* amCharts表示 */
    async createGraph() {
      const eleId = "ranking-chart";
      if (
        !document.getElementById(eleId) || // 高速でページ離脱するとAmcharts5のroot生成に失敗でエラーになるので中断する
        this.data.length === 0
      ) {
        return;
      }
      const root: am5.Root = initChart(eleId);

      root.locale = am5locales_ja_JP;
      // 日付整形する;
      for (const item of this.data) {
        for (const d of item.data) {
          d.date = d.date.replace(/(\d+)-(\d+)-\d+/, "$1/$2");
        }
      }

      const chart: am5xy.XYChart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          layout: root.verticalLayout,
        })
      );

      const legends: am5.Legend = chart.children.push(
        am5.Legend.new(root, {
          nameField: "キーワード",
          centerX: am5.percent(50),
          x: am5.percent(50),
          y: am5.percent(95),
          layout: am5.GridLayout.new(root, {
            maxColumns: 4,
            fixedWidthGrid: false,
          }),
        })
      );
      legends.markers.template.setAll({
        marginRight: 35,
        width: 23,
      });
      legends.labels.template.setAll({
        marginLeft: -30,
        marginRight: -35,
        fontSize: 14.5,
      });

      // ツールチップ表示する為にcursorを加える
      const cursor: am5xy.XYCursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineX.set("visible", false);
      cursor.lineY.set("visible", false);

      const xRenderer: am5xy.AxisRendererX = am5xy.AxisRendererX.new(root, {
        minGridDistance: 40,
        cellStartLocation: 0.2,
        cellEndLocation: 0.8,
      });
      xRenderer.grid.template.setAll({ location: 0.5 });
      xRenderer.labels.template.setAll({ location: 0.5, multiLocation: 0.5 });

      // X軸(年月)
      const categoryAxis: am5xy.CategoryAxis<am5xy.AxisRenderer> = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0.2,
          renderer: xRenderer,
          startLocation: 0,
          endLocation: 1,
          dx: 0,
          categoryField: "date",
        })
      );

      categoryAxis.get("renderer").labels.template.setAll({
        maxWidth: 52,
        // ラベルのフォントサイズ自動調整
        oversizedBehavior: "fit",
        // ラベルのフォントサイズ手動調整
        fontSize: 11,
        fill: am5.color("#333333"),
        paddingTop: 10,
      });

      // 当月含む過去18ヶ月の月の配列を生成
      const monthArray: { date: string }[] = [];
      for (let i = 0; i < this.monthsToDisplay; i++) {
        const date = new Date();
        date.setMonth(date.getMonth() - i);
        // 以下の形式は"2006-01"
        monthArray.push({
          date: `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, "0")}`,
        });
      }
      monthArray.reverse();
      categoryAxis.data.setAll(monthArray);

      // Y軸(順位)
      const valueAxis: am5xy.ValueAxis<am5xy.AxisRenderer> = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxPrecision: 0,
          renderer: am5xy.AxisRendererY.new(root, {
            minGridDistance: 40,
            inversed: true,
          }),
        })
      );

      valueAxis.setAll({
        min: 1,
        max: 20,
        strictMinMax: true,
      });

      valueAxis.get("renderer").labels.template.setAll({
        fill: am5.color("#ffffff"),
      });
      for (let i = 0; i < 20; i++) {
        if (i % 2 !== 0) {
          this.createGrid(valueAxis, i, "位");
        }
      }
      this.createGrid(valueAxis, this.lowestRank, "位以下");

      chart.plotContainer.events.on("boundschanged", () => {
        // 10位のグリッド線を非表示にする
        valueAxis.get("renderer").grid.values[14].hide();
      });

      const rivalIndexes: number[] = this.data
        .filter((item) => item.type === "rival")
        .map((item) => item.index);

      // 折れ線グラフ
      for (let i = 0; i < this.data.length; i++) {
        const item = this.data[i];
        const color = item.type === "rival" ? this.rivalColor : this.lineColors[i];
        const seriesId = `p4Series${i}`;
        item.seriesId = seriesId;
        const lineSeries: am5xy.LineSeries = chart.series.push(
          am5xy.LineSeries.new(root, {
            id: seriesId,
            name: item.name,
            minBulletDistance: 10,
            xAxis: categoryAxis,
            yAxis: valueAxis,
            valueYField: "value",
            categoryXField: "date",
            stroke: am5.color(color),
            showTooltipOn: "always",
          })
        );
        lineSeries.strokes.template.setAll({
          strokeWidth: 2,
        });
        lineSeries.fills.template.setAll({
          visible: true,
        });

        // Legendにseriesを関連付ける
        legends.data.push(lineSeries);

        // 競合のグラフ隠す
        if (item.type === "rival") {
          lineSeries.hide();
        }

        // ツールチップ
        const tooltip: am5.Tooltip = lineSeries.set(
          "tooltip",
          am5.Tooltip.new(root, {
            getFillFromSprite: false,
            getLabelFillFromSprite: false,
            keepTargetHover: false,
            pointerOrientation: "horizontal",
          })
        );

        // // 検索キーワード別スコアランキングの場合は、値がlowestRankならばツールチップには「圏外」と表記する
        lineSeries
          .get("tooltip")
          .adapters.add("labelText", (text: string, target: am5.Tooltip): string => {
            const targetDataItem = target.dataItem;
            if (targetDataItem) {
              const tooltipTextColor = item.type === "rival" ? "#ffffff" : "333333";
              const displayedRank =
                (targetDataItem.dataContext as { value: number }).value === this.lowestRank
                  ? "圏外"
                  : "{valueYWorking.formatNumber('#,###.#')}位";
              target.label.setAll({
                html: `<div style='line-height: 1.5em; text-align: left; color:${tooltipTextColor}'>
          {date}<br>
          {name} : ${displayedRank}</div>`,
              });
            }
            return text;
          });

        tooltip.get("background").setAll({
          fill: am5.color(item.type === "rival" ? this.rivalColor : this.fillColors[i]),
          shadowColor: am5.color("#000000"),
          shadowBlur: 4,
          shadowOffsetY: 2,
          shadowOpacity: 0.5,
        });

        lineSeries.data.setAll(item.data);
      }

      // 凡例の制御
      let index = 0;
      for (const legend of legends.dataItems) {
        const legendItem = legend.component.children.values[index];
        // 並び順をuserDataに格納する
        legendItem.set("userData", index);
        legendItem.set("stateAnimationDuration", 100);

        if (rivalIndexes.includes(index)) {
          // 競合のlegendを隠す
          legendItem.hide();
        } else {
          // 凡例クリック
          legendItem.events.on("click", (e) => {
            const storedIndex: number = e.target.get("userData");
            if (this.chartMode === "rival") {
              this.targetKeyword = this.data[storedIndex].name;
              this.onKeywordSelect();
            }
          });
        }
        index++;
      }
    },
    /** グリッド(目盛り)を任意の間隔で刻む */
    createGrid(axis: am5xy.ValueAxis<am5xy.AxisRenderer>, value: number, unit: string) {
      const rangeDataItem = axis.makeDataItem({
        value,
        endValue: value,
      });

      axis.createAxisRange(rangeDataItem);
      rangeDataItem.get("label").setAll({
        fill: am5.color("#333333"),
        text: value + unit,
        location: 10,
      });
    },
    /* グラフ線の色の取得 */
    getColor(type: string, index: number): string {
      return type === "rival" ? this.rivalColor : this.lineColors[index];
    },

    /* 競合比較 凡例でのキーワード選択時の制御 */
    onKeywordSelect(): void {
      for (const seriesItem of this.data) {
        const series: am5xy.LineSeries = am5.registry.entitiesById[seriesItem.seriesId];

        if (seriesItem.name === this.targetKeyword) {
          if (series._display.visible === false) {
            series.show();
          } else if (seriesItem.type === "own") {
            series.hide(0);
          }
        } else {
          series.hide(0);
        }
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@use "./performance.scss";

.wrapper {
  text-align: right;
  border: 1px solid #d6d6d6;
  padding-bottom: 15px;
}

.no-data {
  color: rgba(0, 0, 0, 0.38);
  height: 435px;
}

.comparison-switch {
  margin-top: 20px;
  margin-right: 20px;

  & > .v-btn.v-btn--active {
    background-color: var(--primary);
    color: white;
    font-weight: bold;
    &.rival-graph {
      background-color: #9747ff;
    }
  }
}

#ranking-chart {
  height: 435px;
}

.rival-tag {
  display: none;
}
</style>
