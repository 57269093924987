<template>
  <div>
    <div class="summary-container">
      <!-- パフォーマンス分析: 表示回数 -->
      <div v-if="isImpression" class="circle-and-legend-wrapper">
        <h4>
          <v-icon :color="impressionTotal.color" size="24">{{ impressionTotal.icon }}</v-icon>
          <span>{{ impressionTotal.label }}</span>
        </h4>

        <div class="circle-and-legends">
          <!-- 円グラフ: 表示回数 -->
          <div class="circle-container">
            <!-- v-if で強制的に矢印アイコンを再描画 -->
            <div v-if="!isLoading" class="total-value">
              <v-icon :color="arrowColor(impressionTotal)" size="150" style="opacity: 0.1">
                {{ arrowIcon(impressionTotal) }}
              </v-icon>
              <div class="total-value-inner">
                <v-icon :color="impressionTotal.color" size="32">{{ impressionTotal.icon }}</v-icon>
                <p class="current-value">{{ impressionTotal.current.toLocaleString() }}</p>
                <p class="previous-value">({{ impressionTotal.previous.toLocaleString() }})</p>
                <p v-if="!isLoading" :class="averageClass(impressionTotal)">
                  <span>{{ ratio(impressionTotal) }}</span>
                  <v-icon :color="arrowColor(impressionTotal)" size="24" class="icon-arrow">
                    {{ arrowIcon(impressionTotal) }}
                  </v-icon>
                </p>
              </div>
            </div>
            <div id="pie-chart-impression" ref="pie-chart-impression" class="circle-chart"></div>
          </div>

          <dl class="custom-legends">
            <!-- 凡例: 検索経由, MAP経由 -->
            <div v-for="(item, index) in [impressionSearch, impressionMap]" :key="index">
              <dt>
                <v-icon class="dt-icon" :color="item.color" size="20">fas fa-square</v-icon>
                <v-icon class="dt-icon" :color="colors.gray" size="20">
                  {{ item.icon }}
                </v-icon>
                <label>{{ item.label }}</label>
              </dt>
              <dd>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-row justify="end">
                        <span class="current-value">{{ item.current.toLocaleString() }}</span>
                      </v-row>
                      <v-row justify="end">
                        <span class="previous-value">({{ item.previous.toLocaleString() }})</span>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-row v-if="!isLoading" align="start" :class="averageClass(item)">
                        <span>{{ ratio(item) }}</span>
                        <v-icon size="20" :color="arrowColor(item)" class="icon-arrow">
                          {{ circleArrowIcon(item) }}
                        </v-icon>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <!-- パフォーマンス分析: 反応数 -->
      <div v-if="!isImpression" class="circle-and-legend-wrapper">
        <h4>
          <v-icon :color="reactionTotal.color" size="24">{{ reactionTotal.icon }}</v-icon>
          <span>{{ reactionTotal.label }}</span>
        </h4>

        <div class="circle-and-legends">
          <!-- 円グラフ: 表示回数 -->
          <div class="circle-container">
            <!-- v-if で強制的に矢印アイコンを再描画 -->
            <div v-if="!isLoading" class="total-value">
              <v-icon :color="arrowColor(reactionTotal)" size="150" style="opacity: 0.1">
                {{ arrowIcon(reactionTotal) }}
              </v-icon>
              <div class="total-value-inner">
                <v-icon :color="reactionTotal.color" size="32">{{ reactionTotal.icon }}</v-icon>
                <p class="current-value">{{ reactionTotal.current.toLocaleString() }}</p>
                <p class="previous-value">({{ reactionTotal.previous.toLocaleString() }})</p>
                <p :class="averageClass(reactionTotal)">
                  <span>{{ ratio(reactionTotal) }}</span>
                  <v-icon :color="arrowColor(reactionTotal)" size="24" class="icon-arrow">
                    {{ arrowIcon(reactionTotal) }}
                  </v-icon>
                </p>
              </div>
            </div>
            <div id="pie-chart-reaction" ref="pie-chart-reaction" class="circle-chart"></div>
          </div>

          <dl class="custom-legends">
            <!-- 凡例: WEBサイト遷移数, 通話数, 経路検索数 -->
            <div
              v-for="(item, index) in [reactionWebsite, reactionCall, reactionRoute]"
              :key="index"
            >
              <dt>
                <v-icon class="dt-icon" :color="item.color" size="20">fas fa-square</v-icon>
                <v-icon class="dt-icon" :color="colors.gray" size="20">
                  {{ item.icon }}
                </v-icon>
                <label>{{ item.label }}</label>
              </dt>
              <dd>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-row justify="end">
                        <span class="current-value">{{ item.current.toLocaleString() }}</span>
                      </v-row>
                      <v-row justify="end">
                        <span class="previous-value">({{ item.previous.toLocaleString() }})</span>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-row v-if="!isLoading" align="start" :class="averageClass(item)">
                        <span>{{ ratio(item) }}</span>
                        <v-icon size="20" :color="arrowColor(item)" class="icon-arrow">
                          {{ circleArrowIcon(item) }}
                        </v-icon>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <v-overlay :model-value="isLoading" persistent contained class="align-center justify-center">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import type { DomainPerformanceSummaryResponse } from "@/types/ls-api";
import { initChart } from "@/components/shared/am5-shared";

type SummaryData = {
  previous: number;
  current: number;
  label?: string;
  color?: string;
  icon?: string;
};

const colors = {
  black: "#000000",
  gray: "#757675",
  upper: "#68cab5",
  downer: "#e95555",
  slightNess: "#f9e827",
  viaSearch: "#9caecc",
  viaMap: "#bbc6dd",
  website: "#9caecc",
  call: "#bbc6dd",
  route: "#cfd7e7",
};

export default defineComponent({
  props: {
    isLoading: { type: Boolean, default: true },
    isImpression: { type: Boolean, default: true }, // true:表示回数, false:反応数
    performanceSummary: {
      type: Object as () => DomainPerformanceSummaryResponse,
      default: () =>
        ({
          current: {
            business_impressions_maps: 0,
            business_impressions_search: 0,
            business_direction_requests: 0,
            call_clicks: 0,
            website_clicks: 0,
          },
          previous: {
            business_impressions_maps: 0,
            business_impressions_search: 0,
            business_direction_requests: 0,
            call_clicks: 0,
            website_clicks: 0,
          },
        } as DomainPerformanceSummaryResponse),
    },
  },
  data() {
    return {
      chart: null as am5percent.PieChart,
      colors: colors,
      activationColors: {
        active: "#EE9D26",
        inActive: "#d3d3d3",
      },
      impressionTotal: {
        current: 0,
        previous: 0,
        label: "表示回数",
        color: colors.gray,
        icon: "far fa-eye",
      } as SummaryData,
      impressionSearch: {
        current: 0,
        previous: 0,
        label: "検索経由",
        color: colors.viaSearch,
        icon: "fas fa-magnifying-glass-location",
      } as SummaryData,
      impressionMap: {
        current: 0,
        previous: 0,
        label: "Map経由",
        color: colors.viaMap,
        icon: "fas fa-map-location-dot",
      } as SummaryData,
      reactionTotal: {
        current: 0,
        previous: 0,
        label: "反応数",
        color: colors.gray,
        icon: "far fa-circle-check",
      } as SummaryData,
      reactionWebsite: {
        current: 0,
        previous: 0,
        label: "WEBサイト遷移数",
        color: colors.website,
        icon: "fa fa-globe",
      } as SummaryData,
      reactionCall: {
        current: 0,
        previous: 0,
        label: "通話数",
        color: colors.call,
        icon: "fas fa-phone",
      } as SummaryData,
      reactionRoute: {
        current: 0,
        previous: 0,
        label: "経路検索数",
        color: colors.route,
        icon: "fas fa-route",
      } as SummaryData,
    };
  },
  watch: {
    performanceSummary: {
      immediate: false,
      handler(newVal, oldVal) {
        if (this.isImpression) {
          this.reloadImpression();
        } else {
          this.reloadReaction();
        }
      },
    },
  },
  methods: {
    isUpper(data: SummaryData): boolean {
      return data.current !== 0 && data.current >= data.previous;
    },
    averageClass(data: SummaryData): string {
      if (data.previous === data.current) {
        return "average";
      }
      return this.isUpper(data) ? "average upper" : "average downer";
    },
    arrowColor(data: SummaryData): string {
      if (data.previous === data.current) {
        return this.colors.gray;
      }
      return this.isUpper(data) ? colors.upper : colors.downer;
    },
    arrowIcon(data: SummaryData): string {
      if (data.previous === data.current) {
        return "";
      }
      return this.isUpper(data) ? "fas fa-arrow-up" : "fas fa-arrow-down";
    },
    circleArrowIcon(data: SummaryData): string {
      if (data.previous === data.current) {
        return "";
      }
      return this.isUpper(data) ? "fas fa-circle-arrow-up" : "fas fa-circle-arrow-down";
    },
    reloadImpression(): void {
      this.impressionSearch.previous = this.performanceSummary.previous.business_impressions_search;
      this.impressionSearch.current = this.performanceSummary.current.business_impressions_search;

      this.impressionMap.previous = this.performanceSummary.previous.business_impressions_maps;
      this.impressionMap.current = this.performanceSummary.current.business_impressions_maps;

      this.impressionTotal.previous = this.impressionSearch.previous + this.impressionMap.previous;
      this.impressionTotal.current = this.impressionSearch.current + this.impressionMap.current;

      this["chart"] = this.createPieChart(
        "pie-chart-impression",
        [this.impressionSearch, this.impressionMap],
        this.arrowColor(this.impressionTotal)
      );
    },
    reloadReaction(): void {
      this.reactionWebsite.previous = this.performanceSummary.previous.website_clicks;
      this.reactionWebsite.current = this.performanceSummary.current.website_clicks;

      this.reactionCall.previous = this.performanceSummary.previous.call_clicks;
      this.reactionCall.current = this.performanceSummary.current.call_clicks;

      this.reactionRoute.previous = this.performanceSummary.previous.business_direction_requests;
      this.reactionRoute.current = this.performanceSummary.current.business_direction_requests;

      this.reactionTotal.previous =
        this.reactionWebsite.previous + this.reactionCall.previous + this.reactionRoute.previous;
      this.reactionTotal.current =
        this.reactionWebsite.current + this.reactionCall.current + this.reactionRoute.current;

      this["chart"] = this.createPieChart(
        "pie-chart-reaction",
        [this.reactionWebsite, this.reactionCall, this.reactionRoute],
        this.arrowColor(this.reactionTotal)
      );
    },
    ratio(data: SummaryData): string {
      if (data.previous === 0 || data.current === 0) {
        return "---%";
      }
      return (
        Math.abs(
          Math.round(((data.current - data.previous) / data.previous) * 10000) / 100
        ).toLocaleString() + "%"
      );
    },
    createPieChart(ele: string, data: SummaryData[], circleColor: string): am5percent.PieChart {
      // 高速でページ離脱するとAmcharts5のroot生成に失敗でエラーになるので中断する
      if (!document.getElementById(ele)) {
        return;
      }
      // チャート初期化
      const root: am5.Root = initChart(ele);
      const chart: am5percent.PieChart = root.container.children.push(
        am5percent.PieChart.new(root, {
          maxWidth: 300,
          radius: 140,
          innerRadius: 125,
        })
      );

      const pieSeries: am5percent.PieSeries = chart.series.push(
        am5percent.PieSeries.new(root, {
          name: "Series",
          valueField: "current",
          categoryField: "label",
        })
      );

      pieSeries.labels.template.setAll({
        templateField: "color",
        visible: false,
      });
      pieSeries.ticks.template.setAll({ visible: false, forceHidden: true });

      const pieChartData = data.map((item) => {
        return {
          current: item.current,
          icon: item.icon,
          label: item.label,
          previous: item.previous,
          color: {
            stroke: am5.color(item.color),
            fill: am5.color(item.color),
          },
        };
      });

      pieSeries.slices.template.set("templateField", "color");
      pieSeries.data.setAll(pieChartData);
      pieSeries.ticks.template.set("disabled", true);

      // // 円グラフをクリックしても位置ずらさない
      pieSeries.slices.template.states.create("active", {
        shiftRadius: 0,
      });

      // 内側の円を描画する
      am5.ready(() => {
        chart.children.push(
          am5.Graphics.new(root, {
            strokeWidth: 5,
            fillOpacity: 0,
            stroke: am5.color(circleColor),
            draw: (disply) => {
              disply.drawCircle(chart.seriesContainer.x(), chart.seriesContainer.y(), 115);
            },
          })
        );
      });

      return chart;
    },
  },
});
</script>

<style lang="scss" scoped>
.summary-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  min-width: 620px;
}
.circle-and-legend-wrapper {
  padding: 20px 0;
  color: #333;
  background-color: #fff;
  h4 {
    font-size: 24px;
    font-weight: bold;
    & > span {
      vertical-align: -2px;
    }
  }
}
.circle-and-legends {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
  height: 300px;
}

$circleSize: 300px;
.circle-container {
  position: relative;
  width: $circleSize;
  height: $circleSize;
}
.circle-chart {
  width: $circleSize !important;
  height: $circleSize !important;
  position: absolute;
  top: 0;
  left: 0;
}

.total-value {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: $circleSize !important;
  height: $circleSize !important;
  left: 22px;
  top: 22px;
}

.upper {
  color: #68cab5;
}
.downer {
  color: #e95555;
}

.total-value-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin: 0;
    &.current-value {
      font-weight: bold;
      font-size: 28px;
      margin-top: 20px;
    }
    &.previous-value {
      font-size: 20px;
    }
    &.average {
      font-size: 24px;
      font-weight: bold;
    }
    .icon-arrow {
      vertical-align: middle;
      margin-bottom: 8px;
    }
  }
}

.previous-value {
  color: #5ea2de;
}

.custom-legends {
  width: 360px;
  margin: 0;
  font-size: 20px;
  margin-left: 30px;
  dt,
  dd {
    cursor: pointer;
  }
  dt {
    display: inline-block;
    vertical-align: middle;
    user-select: none;
    & > label {
      vertical-align: -2px;
      cursor: pointer;
    }
    .dt-icon {
      margin-right: 5px;
    }
  }
  dd {
    margin-left: 10px;
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
    .current-value {
      display: inline-block;
      vertical-align: top;
      font-size: 24px;
      font-weight: bold;
      min-width: 80px;
      text-align: right;
    }
    .previous-value {
      display: block;
      min-width: 95px;
      text-align: right;
    }
    .average {
      display: inline-block;
      vertical-align: top;
      margin-left: 0.5em;
      font-weight: bold;
      span,
      .icon-arrow {
        vertical-align: middle;
        margin-bottom: 2px;
      }
    }
  }
}

.square {
  display: block;
  border-radius: 2px;
  width: 10px;
  height: 10px;
}

/* 以下は画面サイズによるレイアウト崩れへの応急処置 */
.custom-legends {
  width: auto;
  margin: 0;
  padding-left: 30px;
  flex-basis: 57%;
}
._circle-and-legends {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
}
._circle-container {
  flex-basis: 43%;
  width: 100%;
  height: 100%;
}
a.v-tab > div {
  width: calc(100% - 2em);
}
.circle-and-legend-wrapper {
  width: 100%;
}
.circle-chart {
  width: 100%;
  height: 100%;
}
.total-value {
  left: inherit;
  top: inherit;
  width: 100%;
  height: 100%;
}
.total-value-inner {
  position: inherit;
  top: inherit;
  bottom: inherit;
  display: flex;
  justify-content: center;
}
.circle-and-legends {
  flex-wrap: nowrap;
}
.circle-and-legend-wrapper h4 {
  font-size: 1.5rem;
}
</style>
