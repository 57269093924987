<template>
  <div>
    <month-picker v-model="from" :min="min" :max="max" required />
    &nbsp;〜&nbsp;
    <month-picker v-model="to" :min="min" :max="max" required />
  </div>
</template>

<script lang="ts">
import dayjs from "dayjs";
import MonthPicker, { yyyymm } from "./MonthPicker.vue";
import { defineComponent } from "vue";
import { useSnackbar } from "@/storepinia/snackbar";

export interface MonthRange {
  from: string;
  to: string;
}

export default defineComponent({
  components: { MonthPicker },
  props: {
    modelValue: { type: Object as () => MonthRange, required: true },
    min: { type: String, default: "2000-01" },
    max: { type: String, default: "2121-12" },
    maxDuration: { type: Number, default: 12 },
  },
  emits: ["update:modelValue"],
  computed: {
    from: {
      get(): string {
        return this.modelValue.from;
      },
      set(val: string) {
        val = val || this.from;
        this.changed(val, this.to, true);
      },
    },
    to: {
      get(): string {
        return this.modelValue.to;
      },
      set(val: string) {
        val = val || this.to;
        this.changed(this.from, val, false);
      },
    },
  },
  methods: {
    changed(from: string, to: string, isFrom: boolean): void {
      // console.log("changed", from, to, isFrom);
      if (to < from) {
        if (isFrom) {
          to = from;
        } else {
          from = to;
        }
      }
      const dfrom = dayjs(from, yyyymm);
      const dto = dayjs(to, yyyymm);
      const duration = dto.diff(dfrom, "month");
      if (this.maxDuration < duration) {
        if (isFrom) {
          to = dfrom.add(this.maxDuration, "month").format(yyyymm);
        } else {
          from = dto.add(-this.maxDuration, "month").format(yyyymm);
        }
        useSnackbar().addSnackbarMessages({
          text: `表示期間は最大${this.maxDuration + 1}ヶ月間までを指定してください`,
          color: "warning",
        });
      }
      this.$emit("update:modelValue", { from, to });
    },
  },
});
</script>
<style lang="scss" scoped></style>
