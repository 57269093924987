import { requiredAuth } from "@/helpers";
import type { EntitiesCompaniesResponse, EntitiesCompany } from "@/types/ls-api";
export const api = {
    getCompany,
};
/** 企業を取得 */
async function getCompany(poiGroupId: number): Promise<EntitiesCompany> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}`;
    const res = await requiredAuth<EntitiesCompaniesResponse>("get", url);
    return res.data.companies[0];
}
