<template>
  <!-- ヘッダ部分 -->
  <v-card>
    <v-row class="my-1 mx-1">
      <v-col>
        <div class="text-h6 font-weight-bold">{{ title }}</div>
      </v-col>
    </v-row>
  </v-card>
  <!-- フォーム部分 -->
  <v-card class="mt-4" elevation="0">
    <v-form ref="form" class="px-4 pt-4">
      <div class="font-weight-bold">権限名</div>
      <v-text-field
        v-model="form.caption"
        class="ml-3"
        counter="20"
        :rules="[rules.required, rules.maxLength(20)]"
        color="primary"
        placeholder="権限名を入力してください"
        variant="underlined"
      />
      <div class="mt-4 font-weight-bold">説明</div>
      <v-textarea
        v-model="form.description"
        rows="1"
        counter="100"
        auto-grow
        class="ml-3"
        :rules="[rules.maxLength(100)]"
        color="primary"
        placeholder="説明を入力してください"
        variant="underlined"
      />
      <div class="mt-4 font-weight-bold">アクセス可能範囲</div>
      <v-radio-group v-model="form.viewRange" inline class="mt-2" :rules="[rules.required]">
        <template v-for="(viewRange, viewRangeIndex) in viewRangeList" :key="viewRangeIndex">
          <v-radio
            class="label-text mr-6"
            :label="viewRange.text"
            :value="viewRange.value"
            color="primary"
          />
        </template>
      </v-radio-group>
      <!-- 権限選択 -->
      <template v-for="(category, categoryIndex) in permissionCategories" :key="categoryIndex">
        <div class="mt-4 font-weight-bold">{{ category.displayName }}</div>
        <v-radio-group
          v-model="selectedPermissions[category.categoryName]"
          inline
          :rules="[rules.required]"
        >
          <template
            v-for="(permission, permissionIndex) in category.permissions"
            :key="permissionIndex"
          >
            <v-radio
              class="label-text mr-6"
              :label="PermissionType[permission.permissionType]"
              :value="permission.permissionType"
              color="primary"
            />
          </template>
        </v-radio-group>
      </template>
    </v-form>
  </v-card>
  <div class="my-4">
    <v-btn text="キャンセル" color="#EBEDF0" @click.prevent="cancel" />
    <v-btn color="primary" text="登録する" class="ml-5" @click.prevent="submit" />
  </div>
  <v-overlay :model-value="isLoading" persistent class="align-center justify-center">
    <v-progress-circular color="primary" indeterminate size="64" />
  </v-overlay>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { VuetifyValidator } from "@/helpers";
import { api } from "@/helpers/api/roles";
import { useIndexedDb } from "@/storepinia/idxdb";
import { useSnackbar } from "@/storepinia/snackbar";
import { getOperationLogParams } from "@/routes/operation-log";
import { PermissionType, ViewRange } from "./utils";

import type { VForm } from "vuetify/lib/components/index.mjs";
import type { EntitiesRole, EntitiesPermissionCategory } from "@/types/ls-api.d";

type SelectedPermission = {
  [categoryName: string]: PermissionType;
};

export default defineComponent({
  data() {
    return {
      title: "" as string,
      // フォームのデータ
      form: {
        name: "",
        caption: "",
        description: "",
        viewRange: 3,
        functions: [],
        copyOK: true,
        clientType: 1,
      } as EntitiesRole,
      // フォームのバリデーションルール
      rules: VuetifyValidator as typeof VuetifyValidator,
      // viewRangeの選択肢
      viewRangeList: [
        { text: ViewRange[3], value: 3 },
        { text: ViewRange[4], value: 4 },
        { text: ViewRange[5], value: 5 },
      ],
      permissionCategories: [] as EntitiesPermissionCategory[],
      PermissionType,
      selectedPermissions: {} as SelectedPermission,
      roles: useIndexedDb().roleList,
      isLoading: false as boolean,
    };
  },
  computed: {
    poiGroupID: function (): number {
      return parseInt(this.$route.params.poiGroupId as string);
    },
  },
  async created() {
    this.title = this.$route.meta.title as string;
    this.isLoading = true;
    await this.fetchPermissionCategories();

    if (this.$route.name === "CustomRolesEdit") {
      const roleLv = parseInt(this.$route.params.roleLv as string);
      const role = this.roles.find((role) => role.roleLv === roleLv);
      if (!role) {
        this.addErrorMessage("編集対象の取得に失敗しました");
        return;
      }
      // 権限リストから各権限カテゴリの権限レベルを取得
      this.permissionCategories.forEach((category) => {
        return category.permissions.some((permission) => {
          // permission.functionsのすべての要素がrole.functionsに含まれているかチェック
          if (permission.functions.every((func) => role.functions.includes(func))) {
            this.selectedPermissions[category.categoryName] =
              permission.permissionType as PermissionType;
            // 一致したらループを抜ける
            return true;
          }
        });
      });
      this.form = role;
    } else {
      // 新規作成時の各権限カテゴリの権限レベルの初期値は「全て可」
      this.permissionCategories.forEach((category) => {
        this.selectedPermissions[category.categoryName] = Object.keys(
          PermissionType
        )[0] as PermissionType;
      });
    }
    this.isLoading = false;
  },
  methods: {
    addErrorMessage(message: string) {
      useSnackbar().addSnackbarMessages({
        text: message,
        color: "danger",
      });
    },
    async fetchPermissionCategories() {
      await api
        .getPermissionCategories(this.poiGroupID)
        .then((res) => {
          this.permissionCategories = res.data;
        })
        .catch((ex) => {
          console.error(ex);
          this.addErrorMessage("権限の取得に失敗しました");
        });
    },
    cancel() {
      this.$router.push({ name: "CustomRoles" });
    },
    // フォームの送信
    async submit() {
      // バリデーション
      const { valid } = await (this.$refs.form as VForm).validate();
      if (!valid) {
        this.addErrorMessage("入力に誤りがあります。内容を確認してください。");
        return;
      }

      // 選択された権限に対応するfunctionを取得してセット(カスタマイズ対象外の権限はbackendでセットされる)
      const selectedFunctions = [];
      this.permissionCategories.forEach((category) => {
        category.permissions.forEach((permission) => {
          if (permission.permissionType === this.selectedPermissions[category.categoryName]) {
            selectedFunctions.push(...permission.functions);
          }
        });
      });
      this.form.functions = [...new Set(selectedFunctions)];

      // routeによってcreateかupdateを実行
      if (this.$route.name === "CustomRolesNew") {
        this.form.poiGroupID = this.poiGroupID;
        this.form.name = "custom_role";
        await this.create();
      } else {
        await this.update();
      }
    },
    async create() {
      this.isLoading = true;
      await api
        .createRole(this.poiGroupID, getOperationLogParams(this.$route, "post"), this.form)
        .then((res) => {
          if (res != "") {
            this.addErrorMessage(res);
          } else {
            this.$router.push({ name: "CustomRoles" });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async update() {
      this.isLoading = true;
      await api
        .updateRole(this.poiGroupID, getOperationLogParams(this.$route, "put"), this.form)
        .then((res) => {
          if (res != "") {
            this.addErrorMessage(res);
          } else {
            this.$router.push({ name: "CustomRoles" });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-label) {
  opacity: unset !important;
}
</style>
