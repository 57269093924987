import { apiBase } from "@/const";
import { requiredAuth } from "@/helpers";
import axios from "axios";

import type { AxiosResponse } from "axios";
import type {
    StorageGMBMediaPostResponse,
    MybusinessMediaItem,
    EntitiesGbpResponseMybusinessListMediaItemsResponse,
    EntitiesGbpResponseMybusinessMediaItem,
    EntitiesGbpResponseMybusinessEmpty,
} from "@/types/ls-api";
import type { OperationLogParams } from "@/routes/operation-log";
import type { MediaFile } from "@/components/root/contents/gmbmedia/MediaColumn";
import type { definitions } from "@/types/swagger";
type MediaItem = definitions["mybusiness.MediaItem"];

export const api = {
    gmbapiinit,
    listMediaItem,
    patchMediaItem,
    putMediaItem,
    deleteMediaItem,
    uploadMedia,
    putMedia,
};

class RequestError extends Error {
    response: AxiosResponse;
    constructor(message: string, response: AxiosResponse) {
        super(message);
        this.response = response;
    }
}

// FIXME: すべてのAPIをGmbMediaEdit.vueから移植しているわけではないので、必要に応じて追加してください

async function gmbapiinit(poiGroupID: number): Promise<boolean> {
    try {
        const url = `${apiBase}/companies/${poiGroupID}/gmbapiinit`;
        await requiredAuth("get", url);
        return true;
    } catch (e) {
        return false;
    }
}

async function listMediaItem(
    poiGroupID: number,
    poiID: number,
    pageSize: number,
    pageToken: string
): Promise<EntitiesGbpResponseMybusinessListMediaItemsResponse> {
    const url = `${apiBase}/companies/${poiGroupID}/stores/${poiID}/gmb/media`;
    const res = await requiredAuth<EntitiesGbpResponseMybusinessListMediaItemsResponse>(
        "get",
        url,
        {
            pageToken,
            pageSize,
        }
    );
    return res.data;
}

async function patchMediaItem(
    poiGroupID: number,
    poiID: number,
    item: MediaItem,
    oplog: OperationLogParams
): Promise<EntitiesGbpResponseMybusinessMediaItem> {
    const url = `${apiBase}/companies/${poiGroupID}/stores/${poiID}/gmb/media`;
    const res = await requiredAuth<EntitiesGbpResponseMybusinessMediaItem>(
        "patch",
        url,
        oplog,
        item
    );
    return res.data;
}

async function putMediaItem(
    poiGroupID: number,
    poiID: number,
    item: MediaItem,
    oplog: OperationLogParams
): Promise<EntitiesGbpResponseMybusinessMediaItem> {
    const url = `${apiBase}/companies/${poiGroupID}/stores/${poiID}/gmb/media`;
    const res = await requiredAuth<EntitiesGbpResponseMybusinessMediaItem>("put", url, oplog, item);
    return res.data;
}

async function deleteMediaItem(
    poiGroupID: number,
    poiID: number,
    mediaName: string,
    oplog: OperationLogParams
): Promise<EntitiesGbpResponseMybusinessEmpty> {
    const url = `${apiBase}/companies/${poiGroupID}/stores/${poiID}/gmb/media`;
    const res = await requiredAuth<EntitiesGbpResponseMybusinessEmpty>("delete", url, {
        mediaName,
        ...oplog,
    });
    return res.data;
}

/**
 * GMBメディアをアップロードする
 * @returns アップロードされたメディアの取得用URL
 **/
async function uploadMedia(
    poiGroupID: number,
    file: MediaFile,
    operationLogParams: OperationLogParams
): Promise<string> {
    const url = `${apiBase}/companies/${poiGroupID}/startUpload`;
    const res = await requiredAuth<StorageGMBMediaPostResponse>("post", url, operationLogParams);
    if (res?.status == 403) {
        // 権限不足の場合requiredAuthでエラーがthrowされないのでここでthrowする
        throw new RequestError("権限不足", res);
    } else if (res?.data == null) {
        throw new RequestError("アップロードに失敗しました", res);
    }
    const putUrl = res?.data?.putUrl;
    const config = {
        headers: { "Content-Type": file.file.type },
    };
    await axios.put(putUrl, await file.load(), config);
    return res?.data?.getUrl;
}

/**
 * GMBメディアを更新する
 * @returns 更新されたメディアの情報
 **/
async function putMedia(
    poiGroupID: number,
    poiID: number,
    data: MybusinessMediaItem,
    operationLogParams: OperationLogParams
): Promise<MybusinessMediaItem> {
    const url = `${apiBase}/companies/${poiGroupID}/stores/${poiID}/gmb/media`;
    const res = await requiredAuth<MybusinessMediaItem>("put", url, operationLogParams, data);
    if (res?.status == 403) {
        throw new RequestError("権限不足", res);
    } else if (res?.data == null) {
        throw new RequestError("アップロードに失敗しました", res);
    }
    return res?.data;
}
