import type { ColumnDefine } from "cheetah-grid";
import type {
    EntitiesStore,
    EntitiesYahooPlaceBusiness,
    MybusinessbusinessinformationAttribute,
    MybusinessbusinessinformationAttributeMetadata,
} from "@/types/ls-api";

export type Columnx = ColumnDefine<Row> & { gbp?: string; yp?: string };
export const columns: Columnx[] = [
    { caption: "店舗ID", field: "poiID" },
    { caption: "店舗コード", field: "storeCode", width: 100 },
    { caption: "ビジネス名", field: "storeName", width: 210 },
    { caption: "URL Facebook", field: "facebook", gbp: "attributes/url_facebook", width: 120 },
    { caption: "URL Instagram", field: "instagram", gbp: "attributes/url_instagram", width: 120 },
    { caption: "URL LinkedIn", field: "linkedin", gbp: "attributes/url_linkedin", width: 120 },
    { caption: "URL Pinterest", field: "pinterest", gbp: "attributes/url_pinterest", width: 120 },
    { caption: "URL TikTok", field: "tiktok", gbp: "attributes/url_tiktok", width: 120 },
    { caption: "URL Twitter", field: "twitter", gbp: "attributes/url_twitter", width: 120 },
    { caption: "URL YouTube", field: "youtube", gbp: "attributes/url_youtube", width: 120 },
    {
        caption: "Yahoo!プレイスWebサイトURL",
        field: "yahooPlaceWebSite",
        yp: "officialSiteUrl",
        width: 210,
    },
    { caption: "LINE 公式アカウント名", field: "line", yp: "lineOfficialAccount", width: 120 },
];

export class Row {
    poiID: number;
    locationName: string;
    storeCode: string;
    storeName: string;
    areas: number[];
    categoryId: string;
    yahooPlaceSeq: number;
    isDirty: boolean = false;
    facebook: string = "";
    instagram: string = "";
    linkedin: string = "";
    pinterest: string = "";
    tiktok: string = "";
    twitter: string = "";
    youtube: string = "";
    yahooPlaceWebSite: string = "";
    line: string = "";
    enabled: { [key: string]: boolean } = {};
    backup: { [key: string]: string } = {};
    /** constructor */
    constructor(s: EntitiesStore, categoryId: string) {
        this.poiID = s.poiID;
        this.locationName = s.gmbLocationID?.replace(/^accounts\/[^/]+\//, "");
        this.storeCode = s.gmbStoreCode;
        this.storeName = s.name;
        this.areas = s.areas ?? [];
        this.categoryId = categoryId;
        this.yahooPlaceSeq = s.yahooplace?.placeSeq ?? 0;
    }
    // GBPの属性(facebook..youtube)を反映
    setGbpAttributes(attributes: MybusinessbusinessinformationAttribute[]) {
        for (const attr of attributes ?? []) {
            const column = columns.find((c) => c.gbp === attr.name);
            if (!column) continue;
            const field = column.field as string;
            this[field] = attr.uriValues?.[0]?.uri ?? "";
        }
        for (const column of columns) {
            if (!column.gbp) continue;
            const field = column.field as string;
            this.backup[field] = this[field];
        }
    }
    /** Yahoo の WebsサイトURL・lineOfficialAccount を反映 */
    setYahoo(ybp: EntitiesYahooPlaceBusiness): void {
        const enabled = this.yahooPlaceSeq !== 0;
        this.enabled["line"] = enabled;
        this.enabled["yahooPlaceWebSite"] = enabled;
        if (this.yahooPlaceSeq !== 0 && ybp) {
            this.yahooPlaceWebSite = ybp.officialSiteUrl ?? "";
            this.line = ybp.lineOfficialAccount ?? "";
        }
        this.backup.yahooPlaceWebSite = this.yahooPlaceWebSite;
        this.backup.line = this.line;
    }
    /** GBPの属性(facebook..youtube)の enabled を設定 */
    setEnabled(ams: MybusinessbusinessinformationAttributeMetadata[]): void {
        // 各カラムごとに GBP の属性があるかどうかを設定
        for (const column of columns) {
            if (!column.gbp) continue;
            const field = column.field as string;
            const am = ams.find((am) => am.parent === column.gbp);
            this.enabled[field] = !!am;
        }
    }
    updateIsDirty(): void {
        for (const column of columns) {
            if (!column.gbp && !column.yp) continue;
            const field = column.field as string;
            if (this.hasFieldChanged(field)) {
                this.isDirty = true;
                return;
            }
        }
        this.isDirty = false;
    }
    hasFieldChanged(field: string): boolean {
        return this[field] !== this.backup[field];
    }
}
