import { Conditions } from "@/components/shared/store-selector-type";
import { defineStore } from "pinia";
import type { PiniaPluginContext } from "pinia";
import dayjs, { type Dayjs } from "dayjs";
import { useIndexedDb } from "@/storepinia/idxdb";

export const useSs = defineStore("tostoreSessions", {
    state: () => ({
        selectedCondition: (() => {
            const viewRange = useIndexedDb().userViewRange;
            if (viewRange === 5) {
                return Conditions.Store;
            }
            return Conditions.All;
        })() as Conditions,
        selectedGroup: 0,
        selectedStore: (() => {
            const viewRange = useIndexedDb().userViewRange;
            if (viewRange === 5) {
                const user = useIndexedDb().user;
                if (user?.stores?.length > 0) {
                    return user?.stores[0];
                }
            }
            return 0;
        })() as number,
        aggregationStartDate: (() => {
            // demoの場合は固定の日付を返す
            if (import.meta.env.VITE_APP_APP_ENV == "demo") {
                return dayjs("2022-11-01").format("YYYY-MM");
            }
            return dayjs().add(-55, "days").format("YYYY-MM");
        })(),
        aggregationEndDate: (() => {
            if (import.meta.env.VITE_APP_APP_ENV == "demo") {
                return dayjs("2023-01-31").format("YYYY-MM");
            }
            return dayjs().format("YYYY-MM");
        })(),
        replyAggregationStartDate: (() => {
            // demoの場合は固定の日付を返す
            if (import.meta.env.VITE_APP_APP_ENV == "demo") {
                return date(dayjs("2022-11-01"));
            }
            return date(dayjs().add(-55, "days"));
        })(),
        replyAggregationEndDate: (() => {
            if (import.meta.env.VITE_APP_APP_ENV == "demo") {
                return date(dayjs("2023-01-31"));
            }
            return date(dayjs());
        })(),
        healthCheckAggregationStartDate: date(dayjs().add(-30, "days")),
        healthCheckAggregationEndDate: date(dayjs()),
        comparisonDates: (() => {
            // demo環境の場合は期間を固定にする
            // レポートの見え方が良い比較期間を指定している
            if (import.meta.env.VITE_APP_APP_ENV == "demo") {
                return [
                    dayjs("2023-01-01"),
                    dayjs("2023-03-31"),
                    dayjs("2022-09-01"),
                    dayjs("2022-11-30"),
                ].map(date);
            }
            // それ以外の環境
            // 当日
            const defEndDate = dayjs();
            // ４週間前
            const defStartDate = dayjs().add(-27, "days");
            // 4週間 + 1日前
            const defCEndDate = defStartDate.add(-1, "days");
            // ８週間 + 1日前
            const defCStartDate = defCEndDate.add(-27, "days");
            return [defStartDate, defEndDate, defCStartDate, defCEndDate].map(date);
        })(),
        newComparisonDates: makeNewComparisonDates(dayjs()),
    }),
    persist: {
        storage: sessionStorage,
        afterRestore: (ctx: PiniaPluginContext) => {
            console.log("tostoreSessions was restored by sessionStorage", ctx.store);
        },
    },
});
/** インサイト分析画面の期間を作成する (単体テストのために state から切り出している) */
export const makeNewComparisonDates = (today: Dayjs) => {
    // demo環境の場合は期間を固定にする
    // レポートの見え方が良い比較期間を指定している
    if (import.meta.env.VITE_APP_APP_ENV == "demo") {
        return [
            dayjs("2023-01-01"),
            dayjs("2023-03-31"),
            dayjs("2022-09-01"),
            dayjs("2022-11-30"),
        ].map(date);
    }
    // それ以外の環境
    // 先月の月初
    const defStartDate = today.set("date", 1).add(-1, "months");
    // 先月末
    const defEndDate = today.set("date", 1).add(-1, "days");
    // 先々月末
    const defCEndDate = today.set("date", 1).add(-1, "months").add(-1, "days");
    // 先々月月初
    const defCStartDate = today.set("date", 1).add(-2, "months");
    return [defStartDate, defEndDate, defCStartDate, defCEndDate].map(date);
};
function date(d: Dayjs): string {
    return d.startOf("day").format("YYYY-MM-DD");
}
