import type {
    ControllersYahooAuthControllerDisconnectYahooAccountInput as DisconnectInput,
    EntitiesCompany,
    EntitiesUser,
    EntitiesUsersResponse,
    EntitiesYahooAccount,
    MybusinessaccountmanagementAccount,
    MybusinessaccountmanagementListAccountsResponse,
    StorageResponse,
} from "@/types/ls-api";
import { requiredAuth } from "@/helpers";
export const api = {
    getAccounts,
    setGmbAccount,
    getYahooAccounts,
    disconnectYahooAccount,
    getUsers,
    initGBPToken,
};
/** アカウント一覧を取得 */
async function getAccounts(poiGroupId: number): Promise<MybusinessaccountmanagementAccount[]> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/setup/gbp/accounts`;
    const accs: MybusinessaccountmanagementAccount[] = [];
    let pageToken = "";
    do {
        const res = await requiredAuth<MybusinessaccountmanagementListAccountsResponse>(
            "get",
            url,
            { pageToken }
        );
        accs.push(...res.data.accounts);
        pageToken = res.data.nextPageToken ?? "";
    } while (pageToken !== "");
    return accs;
}
/** companies テーブルに gmbAccount を設定する */
async function setGmbAccount(poiGroupId: number, gmbAccount: string): Promise<EntitiesCompany> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/setup/setGmbAccount`;
    const res = await requiredAuth<EntitiesCompany>("post", url, null, { gmbAccount });
    return res.data;
}
/** ユーザ一覧取得 */
async function getUsers(poiGroupId: number): Promise<EntitiesUser[]> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/users`;
    const response = await requiredAuth<EntitiesUsersResponse>("get", url);
    return response?.data?.users || [];
}
/** Yahooアカウント一覧を取得する */
async function getYahooAccounts(poiGroupId: number): Promise<EntitiesYahooAccount[]> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/yahoo/accounts`;
    const res = await requiredAuth<EntitiesYahooAccount[]>("get", url);
    return res.data || [];
}
/** Yahooアカウントの紐づけを解除する */
async function disconnectYahooAccount(poiGroupId: number, uuid: string): Promise<DisconnectInput> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/yahoo/disconnect`;
    const res = await requiredAuth<DisconnectInput>("post", url, null, { uuid });
    return res.data;
}

/** GBPアクセストークン初期化 */
async function initGBPToken(poiGroupId: number): Promise<StorageResponse> {
    return await requiredAuth<StorageResponse>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/gmbapiinit`
    );
}
