<template>
  <!-- PC版の返信モーダル -->
  <VeeForm v-if="!isMobile" ref="observer" as="">
    <form class="modal-card" @submit.prevent="submit()">
      <header class="modal-card-head">
        <v-icon color="#aaa" size="x-small" class="mr-2">fas fa-reply</v-icon>
        <h2 class="modal-card-title">返信</h2>
      </header>
      <section class="modal-card-body">
        <div class="modal-card-body-left">
          <v-row>
            <v-col><h3>返信内容</h3></v-col>
            <v-spacer />
            <v-col class="button-position">
              <AIGenerateButton :reviews="reviews" @copy-template="copyTemplate"></AIGenerateButton>
            </v-col>
          </v-row>

          <Field
            v-slot="{ handleChange, value }"
            v-model="replyMessage"
            rules="required|maxLength:4096"
            name="返信内容"
          >
            <v-textarea
              color="primary"
              class="reply-textarea"
              rows="10"
              placeholder="返信内容を入力"
              outlined
              hide-details="auto"
              :messages="placeholderDescription"
              :model-value="value"
              @update:model-value="handleChange"
            >
              <template #message="{ message }">
                <!-- 改行して表示ができないので、ここで分割して改行タグを入れる -->
                <p v-for="m in message.split('\n')" :key="m" class="description">
                  <span>※ {{ m }}</span>
                  <br />
                </p>
              </template>
            </v-textarea>
            <div v-show="validationError">
              <p class="has-text-danger">返信は1文字以上、4096Byte以下にする必要があります。</p>
            </div>
          </Field>
          <br />
          <ReplyTargets :reviews="reviews" />
        </div>
        <div class="modal-card-body-right">
          <ReplyTemplate @copy-template="copyTemplate" />
        </div>
      </section>
      <footer class="modal-card-foot">
        <v-btn class="button" type="button" @click.stop="$emit('close')">キャンセル</v-btn>
        <v-btn type="submit" class="button is-primary" :disabled="isSubmitButtonDisabled">
          送信
        </v-btn>
      </footer>
    </form>
  </VeeForm>
  <!-- SP版の返信モーダル -->
  <v-card v-else flat>
    <header class="modal-card-head">
      <v-btn icon style="background-color: transparent" @click.stop="$emit('close')">
        <v-icon color="#555">fas fa-xmark</v-icon>
      </v-btn>
      <h2 class="modal-card-title">返信</h2>
    </header>
    <v-card-text>
      <v-tabs v-model="selectedTab" grow class="tabs" color="primary" slider-color="primary">
        <v-tab key="reply" value="reply">
          <v-icon size="small" class="ml-1 mr-1">fas fa-pen</v-icon>
          返信を作成
        </v-tab>
        <v-tab key="targets" value="targets">
          <v-icon size="small" class="ml-1 mr-1">fas fa-comments</v-icon>
          返信対象
        </v-tab>
        <v-tab key="tepmplate" value="template">
          <v-icon size="small" class="ml-1 mr-1">fas fa-quote-left</v-icon>
          例文を参照
        </v-tab>
      </v-tabs>
      <div v-if="selectedTab === 'reply'" class="inner">
        <div class="d-flex justify-end">
          <AIGenerateButton :reviews="reviews" @copy-template="copyTemplate" />
        </div>

        <VeeForm ref="observer" as="">
          <form class="mt-2" @submit.prevent="submit()">
            <Field
              v-slot="{ handleChange, value }"
              v-model="replyMessage"
              rules="required|maxLength:4096"
              name="返信内容"
            >
              <v-textarea
                color="primary"
                class="reply-textarea"
                rows="10"
                placeholder="返信内容を入力"
                outlined
                hide-details="auto"
                :messages="placeholderDescription"
                :model-value="value"
                @update:model-value="handleChange"
              >
                <template #message="{ message }">
                  <!-- 改行して表示ができないので、ここで分割して改行タグを入れる -->
                  <p v-for="m in message.split('\n')" :key="m" class="description">
                    <span>{{ m }}</span>
                  </p>
                </template>
              </v-textarea>
              <div v-show="validationError">
                <p class="has-text-danger">返信は1文字以上、4096Byte以下にする必要があります。</p>
              </div>
            </Field>
            <div class="mb-5 d-flex flex-row">
              <v-btn class="button mr-2" type="button" @click.stop="$emit('close')">
                キャンセル
              </v-btn>
              <v-btn
                type="submit"
                class="button is-primary"
                style="flex: 1"
                :disabled="isSubmitButtonDisabled"
              >
                送信
              </v-btn>
            </div>
          </form>
        </VeeForm>
      </div>
      <div v-if="selectedTab === 'targets'">
        <ReplyTargets :reviews="reviews" />
      </div>
      <div v-if="selectedTab === 'template'">
        <ReplyTemplate @copy-template="copyTemplate" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { VuetifyValidator } from "@/helpers";
import { getDescription } from "./reply-templates";
import AIGenerateButton from "./modal-reply-generate.vue";
import ReplyTargets from "./modal-reply-targets.vue";
import ReplyTemplate from "./modal-reply-template.vue";
import { getter } from "@/storepinia/idxdb";

import type { PropType } from "vue";
import type { FormContext } from "vee-validate";

export default defineComponent({
  components: { AIGenerateButton, ReplyTargets, ReplyTemplate },
  props: {
    reviews: { type: Array as PropType<Array<string>>, default: () => [] as string[] },
  },
  emits: ["complete", "close"],
  data: () => {
    return {
      name: "reply",
      replyMessage: "",
      validationError: false,
      rules: VuetifyValidator as typeof VuetifyValidator,
      loading: false,
      placeholderDescription: getDescription(""),
      isSubmitButtonDisabled: false, // demo環境の場合に、送信ボタンを無効化するためのフラグ
      isMobile: false,
      selectedTab: "reply",
    };
  },
  computed: {},
  watch: {},
  created(): void {
    if (import.meta.env.VITE_APP_APP_ENV == "demo") {
      // デモ環境の場合は送信ボタンを無効にする
      this.isSubmitButtonDisabled = true;
    }
    this.isMobile = getter().isMobile;
  },
  mounted(): void {},
  methods: {
    async submit(): Promise<void> {
      const observer = this.$refs.observer as FormContext;
      const result = await observer.validate();
      if (!result.valid) {
        this.validationError = true;
        return;
      }
      this.$emit("complete", this.replyMessage);
    },
    copyTemplate(selectedTemplate: string): void {
      // 返信内容の入力欄に選択したテンプレートを転写
      this.replyMessage = selectedTemplate;
      // テンプレートに特定の文字列が含まれていたら、注意書きを表示
      this.placeholderDescription = getDescription(this.replyMessage);
      if (this.isMobile) {
        this.selectedTab = "reply";
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

$gray: #dfdfdf;

.tabs {
  margin: 0.25rem 0 0 !important;
  .v-tabs-slider-wrapper {
    height: 0 !important;
  }

  :deep(.v-slide-group__content) {
    position: relative;
    margin: 0 5px 0;
    &::before,
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      display: block;
      width: 5px;
      height: 1px;
      border-bottom: 1px solid color.$primary;
    }
    &::before {
      left: -5px;
    }
    &::after {
      right: -5px;
    }
  }

  .v-tab {
    font-size: 0.8rem !important;
    font-weight: 600;
    height: 3rem;
    width: auto;
    padding: 2px 0.5rem 0 0 !important;
    border-width: 1px 1px 0 1px;
    border-radius: 3px 3px 0 0;
    border-style: solid;
    border-color: $gray;
    border-bottom: 1px solid color.$primary;
    &.v-tab--selected {
      border-color: color.$primary;
      border-bottom: 1px solid white;
    }
  }

  .v-tab--active {
    border-width: 3px 1px 0 1px;
    border-color: color.$primary;
  }
  :deep(.v-tab__slider) {
    top: 0;
  }
}

.v-select {
  margin-bottom: 10px;
  font-weight: 600;
  .v-input__slot {
    padding: 0 0 0 5px !important;
  }
}
:deep(.v-input__details) {
  flex-wrap: wrap;
}
.description {
  font-size: 0.8rem;
  margin-bottom: unset;
  margin-top: 0.1rem;
  line-height: 1.25rem;
  color: red;
  &::before {
    content: "※";
  }
  + .description {
    margin-top: 0.25rem;
  }
}

.modal-card {
  width: auto;
  max-width: 1200px;
  max-height: calc(100vh - 40px);

  h2 {
    font-size: 16px;
    color: #aaa;
  }
}
.modal-card-head {
  height: 15px;
  padding: 20px 10px;
}
.modal-card-title {
  font-size: 1rem;
  color: #555;
}
.modal-card-foot {
  border-radius: 0;
  border-style: none;
}
.modal-card-body {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 440px;
}

.v-card-text {
  padding: 0 !important;
  .inner {
    margin: 0.5rem 0.25rem;
  }
}
h3 {
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}
.reply-textarea {
  margin-top: 0;
  margin-bottom: 10px;
  .v-input__slot::before {
    border-width: 0;
  }
}

.v-text-field--outlined fieldset {
  border-color: $gray !important;
  border-radius: 3px !important;
}

.media-content {
  font-size: 16px;
}

.modal-card-body-left {
  min-width: 360px;
}

.modal-card-body-right {
  text-align: center;
}

.button-position {
  text-align: right;
}
</style>
