export interface Tipable {
    title: string;
    supplement?: string;
}

// word-dictionaryのタイトル・ツールチップペアを.vueで扱う情報に抽出するためのクラス
export default class TipableTitleGroup {
    private tipableTitles: Tipable[];

    constructor(tipableTitles: Tipable[]) {
        this.tipableTitles = tipableTitles;
    }

    tooltipExists(title: string): boolean {
        const tipableTitle = this.tipableTitles.find((tt) => tt.title === title);
        if (tipableTitle == null || tipableTitle.supplement == null) {
            return false;
        }
        return true;
    }

    supplement(title: string): string {
        const tipableTitle = this.tipableTitles.find((tt) => tt.title === title);
        if (tipableTitle == null || tipableTitle.supplement == null) {
            return "";
        }
        return tipableTitle.supplement;
    }

    get asNames(): string[] {
        return this.tipableTitles.map((tt) => tt.title);
    }
}
