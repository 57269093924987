import type { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import axios from "axios";
import type { ControllersRefreshOutput } from "@/types/ls-api";
import type { SnackbarToast } from "@/components/shared/snackbar/snackbar-shared";
import { useSnackbar } from "@/storepinia/snackbar";
export type HttpMethod = "get" | "post" | "put" | "patch" | "delete";

export interface ReportResponse {
    data: any;
    status: number;
    statusText: string;
    headers: any;
    config: AxiosRequestConfig;
    request?: any;
    hasError?: boolean;
    errorStatus?: number;
    errorObject?: Record<string, unknown>;
}

export async function request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return axios(config);
}

/** requiredAuth呼び出し元からSnackbarを上書きしたい時に使う。
 * 2022年9月2日現在、TM-3019のグループ外の権限足りないユーザーからの投稿(403)ケース対応のみに使用。
 * 2023年10月19日現在、TM-7076 店舗権限で権限不足の場合に無視する場合に対応
 */
export type CustomSnackbarToast = {
    statusCode: number;
    snackbarToast: SnackbarToast;
};

export async function requiredAuth<T>(
    method: HttpMethod,
    url: string,
    params?: any,
    data?: any,
    contentType: string = "application/json;charset=UTF-8",
    customSnackbarToast?: CustomSnackbarToast,
    needsInquery: boolean = true
): Promise<AxiosResponse<T>> {
    const headers = {
        "Content-Type": contentType,
        Authorization: `Bearer ${sessionStorage.IdToken}`,
    };
    const config: AxiosRequestConfig = { method, headers, url, params, data };
    return requiredAuthWithConfig<T>(config, customSnackbarToast, needsInquery);
}
export async function requiredAuthWithConfig<T>(
    config: AxiosRequestConfig,
    customSnackbarToast?: CustomSnackbarToast,
    needsInquery: boolean = true
): Promise<AxiosResponse<T>> {
    // Authorization ヘッダがなければ設定する
    config.headers = config.headers ? config.headers : {};
    if (!config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${sessionStorage.IdToken}`;
    }

    // Snackbar呼び出す
    const addSnackbar = useSnackbar().addSnackbarMessages;

    try {
        return await request<T>(config);
    } catch (e: any) {
        // 例外がAxiosErrorでない場合はそのまま再送出
        if (!axios.isAxiosError(e)) {
            throw e;
        }
        const error = e as AxiosError<any>;
        if (!error.response) {
            // error.response オブジェクトがないのは低レイヤーのエラーの場合
            // トーストを表示して例外を再送出する。error.message が 'Network Error' の場合はフレンドリーなメッセージにする
            const message =
                error.message === "Network Error"
                    ? "ネットワークエラーが発生しました。<br>インターネット接続をご確認の上再度お試しください。"
                    : error.message;
            addSnackbar({ text: message, color: "danger" });
            throw error;
        }

        // 下記のステータスのエラーの場合はエラーを握りつぶして、代わりの処理を行う
        // エラーのダイアログが表示されないように
        const response = error.response;
        if (response.status === 401) {
            if (response.data?.message === "The incoming token has expired") {
                // IDトークン有効期限切れにつきリフレッシュトークンで更新して、リトライ
                if ((await refreshAccessToken()) != null) {
                    config.headers["Authorization"] = `Bearer ${sessionStorage.IdToken}`;
                    return await request<T>(config);
                }
            } else {
                // ログイン中にログインユーザーが削除された場合などは、ログイン画面に飛ばす
                gotoLogin();
            }
            return;
        } else if (response.status === 402) {
            // 現在のURLが既に未契約画面であれば何もしない
            if (window.location.toString().includes("inquiries")) {
                return;
            }
            if (needsInquery) {
                addSnackbar({ text: "未契約の機能です。" });
                gotoInquiry(config.url);
            }
            return response;
        } else if (response.status === 403) {
            if (customSnackbarToast?.statusCode === 403) {
                if (customSnackbarToast.snackbarToast !== undefined) {
                    addSnackbar(customSnackbarToast.snackbarToast);
                }
            } else {
                addSnackbar({ text: "権限が不足しています。", color: "danger", timeout: 5000 });
            }
            response.data = null;
            return response;
        } else if (response.status === 503) {
            // 現在のURLが既にエラー画面であれば何もしない
            if (window.location.toString().includes("error")) {
                return;
            }
            // レスポンスの errorMessage を取得する
            const errorMessage = response.data?.errorMessage ?? "";
            gotoErrorPage(errorMessage);
            return;
        }
        // 上記以外のステータスのエラーはそのまま再送出
        throw error;
    }
}

export async function refreshAccessToken(): Promise<boolean> {
    try {
        if (sessionStorage.RefreshToken == null) {
            throw Error("未ログイン状態につきログイン画面に遷移");
        }
        const auth: ControllersRefreshOutput = (
            await requiredAuth<ControllersRefreshOutput>(
                "post",
                `${import.meta.env.VITE_APP_API_BASE}v1/auth/refresh`,
                null,
                { refreshToken: sessionStorage.RefreshToken }
            )
        ).data;
        sessionStorage.IdToken = auth.idToken;
        sessionStorage.AccessToken = auth.accessToken;
        return true;
    } catch (error) {
        // リフレッシュトークン有効期限切れ → ログイン画面に飛ばしてメッセージ
        gotoLogin();
        return null;
    }
}

export function gotoLogin(): void {
    let url = window.location.hash;
    if (url[0] === "#") {
        url = url.slice(1);
    }
    window.location.href = `/#/login?timeout=1&redirect=${url}`;
}

export function gotoInquiry(api: string): void {
    let url = window.location.hash;
    if (url[0] === "#") {
        url = url.slice(1);
    }
    if (url.includes("inquiries")) {
        return;
    }
    const pattern = /\/companies\/(\d+)\/(.+)/;
    const result = url.match(pattern);
    const poiGroupId = result[1] ?? 0;
    const newHash = `/companies/${poiGroupId}/inquiries/new?from=${url}&api=${api}`;
    // #の後を置換して遷移させる
    window.location.href = window.location.toString().replace(url, newHash);
}

export function gotoErrorPage(message: string): void {
    let url = window.location.hash;
    if (url[0] === "#") {
        url = url.slice(1);
    }
    const pattern = /\/companies\/(\d+)\/(.+)/;
    const result = url.match(pattern);
    const poiGroupId = result[1] ?? 0;
    const newHash = `/companies/${poiGroupId}/error?message=${encodeURIComponent(message)}`;
    window.location.href = window.location.toString().replace(url, newHash);
}
