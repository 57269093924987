// 同時実行数を制御しつつ並列実行するため、Promiseのリストを管理して Promise.race で実行
// 参考: https://zenn.dev/sora_kumo/articles/539d7f6e7f3c63
export function parallels(ps = new Set<Promise<unknown>>()): {
    add: (p: Promise<unknown>) => Set<Promise<unknown>>;
    race: (limit: number) => Promise<unknown>;
    all: () => Promise<unknown[]>;
    size: () => number;
} {
    return {
        add: (p: Promise<unknown>) =>
            ps.add(!!p.then(() => ps.delete(p)).catch(() => ps.delete(p)) && p), // 成功したPromiseを削除
        race: (limit: number) => ps.size >= limit && Promise.race(ps),
        all: () => Promise.all(ps),
        size: () => ps.size,
    };
}
