import { apiBase } from "@/const";
import { requiredAuth } from "@/helpers";
import { getOperationLogParams } from "@/routes/operation-log";
import type {
    EntitiesStore,
    EntitiesStoresResponse,
    EntitiesYahooPlaceBusiness,
    EntitiesYahooResponseDomainYahooPlaceBusinessList,
    EntitiesYahooResponseEntitiesYahooPlaceBusiness,
    EntitiesYahooResponseString,
} from "@/types/ls-api";
import type { RouteLocationNormalized } from "vue-router";
export const api = {
    listPlaceBusiness,
    getPlaceBusiness,
    putStoreYahooplace,
    listStores,
    patchStore,
};
async function listPlaceBusiness(
    poiGroupId: number,
    uuid: string,
    size: number,
    page: number
): Promise<EntitiesYahooResponseDomainYahooPlaceBusinessList> {
    const url = `${apiBase}/companies/${poiGroupId}/yahoo/stores`;
    const res = await requiredAuth<EntitiesYahooResponseDomainYahooPlaceBusinessList>("get", url, {
        uuid,
        size,
        page,
    });
    return res.data;
}
async function getPlaceBusiness(
    poiGroupId: number,
    poiId: number
): Promise<EntitiesYahooResponseEntitiesYahooPlaceBusiness> {
    const url = `${apiBase}/companies/${poiGroupId}/yahoo/stores/${poiId}`;
    const res = await requiredAuth<EntitiesYahooResponseEntitiesYahooPlaceBusiness>("get", url);
    return res.data;
}
async function putStoreYahooplace(
    route: RouteLocationNormalized,
    poiGroupId: number,
    poiId: number,
    param?: EntitiesStore["yahooplace"]
): Promise<EntitiesStore> {
    const url = `${apiBase}/companies/${poiGroupId}/stores/${poiId}/yahooplace`;
    const res = await requiredAuth<EntitiesStore>(
        "put",
        url,
        getOperationLogParams(route, "page-linkage"),
        { yahooplace: param }
    );
    return res.data;
}
async function listStores(poiGroupId: number, uuid: string): Promise<EntitiesStore[]> {
    const url = `${apiBase}/companies/${poiGroupId}/stores`;
    const res = await requiredAuth<EntitiesStoresResponse>("get", url, { uuid });
    return res.data.stores ?? [];
}
async function patchStore(
    poiGroupId: number,
    poiId: number,
    ypb: EntitiesYahooPlaceBusiness
): Promise<EntitiesYahooResponseString> {
    const url = `${apiBase}/companies/${poiGroupId}/yahoo/stores/${poiId}`;
    const res = await requiredAuth<EntitiesYahooResponseString>("patch", url, null, ypb);
    return res.data;
}
