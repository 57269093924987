<template>
  <!-- PC用サイドメニュー -->
  <v-navigation-drawer
    v-if="!isMobile"
    id="app-sidebar"
    :key="updateKey"
    :rail="mini"
    permanent
    :class="{ opened: drawer }"
    @transitionend="transitionend"
  >
    <v-list id="sidebar">
      <v-tooltip transition="fade-transition" :disabled="isDrawerOpened" right>
        <template #activator="{ props }">
          <v-list-item v-bind="props" @click.stop="openSubMenu">
            <template #prepend>
              <v-icon size="16" :color="gearColor">fas fa-gear</v-icon>
            </template>
            <v-list-item-title class="menu-title">{{ companyName }}</v-list-item-title>
          </v-list-item>
        </template>
        <span>{{ dict.process_confButton_label }}</span>
      </v-tooltip>
      <v-list-item v-show="isDrawerOpened">
        <v-list-item-title class="profile">{{ userName }}</v-list-item-title>
      </v-list-item>

      <!-- スマホ版に切り替える -->
      <div v-if="isMobileDevice && isDrawerOpened" class="mt-3 mb-5 d-flex justify-center">
        <v-btn variant="elevated" class="changeButton" @click="changeDeviceMode">
          {{ dictReport.changeSPMode }}
        </v-btn>
      </div>

      <v-tooltip transition="fade-transition" :disabled="isDrawerOpened" right>
        <template #activator="{ props }">
          <v-list-item
            v-if="isInactiveHealthCheck || canShowHealthCheck"
            :disabled="isInactiveHealthCheck"
            v-bind="props"
            class="power-health-check"
            @click="jumpTo(pageItems.healthCheck)"
          >
            <!-- パワーユーザー以上 ヘルスチェックリンク -->
            <template #prepend>
              <v-icon size="16" :color="isActive(pageItems.healthCheck)">fas fa-heartbeat</v-icon>
            </template>
            <v-list-item-title class="menu-title">
              {{ dict.power_health_check }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>{{ dict.power_health_check }}</span>
      </v-tooltip>

      <!-- 分析 -->
      <v-list-subheader v-show="isDrawerOpened" class="sidebar-title">
        {{ dictReport.title }}
      </v-list-subheader>
      <!-- パフォーマンス分析 -->
      <v-tooltip transition="fade-transition" :disabled="isDrawerOpened" right>
        <template #activator="{ props }">
          <v-list-item
            v-if="isInactiveSummary || canShowSummary"
            v-bind="props"
            :disabled="isInactiveSummary"
            class="sidebar-item"
            @click="jumpTo(pageItems.summary)"
          >
            <template #prepend>
              <v-icon size="16" :color="isActive(pageItems.summary)">fas fa-chart-area</v-icon>
            </template>
            <v-list-item-title v-show="isDrawerOpened" class="menu-title">
              {{ dictReport.summary }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>{{ dictReport.summary }}</span>
      </v-tooltip>
      <!-- クチコミ分析 -->
      <v-tooltip transition="fade-transition" :disabled="isDrawerOpened" right>
        <template #activator="{ props }">
          <v-list-item
            v-if="isInactiveReview || canShowReview"
            v-bind="props"
            :disabled="isInactiveReview"
            class="sidebar-item"
            @click="jumpTo(pageItems.review)"
          >
            <template #prepend>
              <v-icon size="16" :color="isActive(pageItems.review)">fas fa-comments</v-icon>
            </template>
            <v-list-item-title v-show="isDrawerOpened" class="menu-title">
              {{ dictReport.review }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>{{ dictReport.review }}</span>
      </v-tooltip>

      <!-- レポートダウンロード -->
      <v-tooltip transition="fade-transition" :disabled="isDrawerOpened" right>
        <template #activator="{ props }">
          <v-list-item
            v-if="isInactiveReport || canShowReport"
            :disabled="isInactiveReport"
            v-bind="props"
            class="sidebar-item"
            @click="jumpTo(pageItems.reportDownload)"
          >
            <template #prepend>
              <v-icon size="16" :color="isActive(pageItems.reportDownload)">
                fas fa-file-download
              </v-icon>
            </template>
            <v-list-item-title v-show="isDrawerOpened" class="menu-title">
              {{ dictReport.reportDownload }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>{{ dictReport.reportDownload }}</span>
      </v-tooltip>
    </v-list>

    <!-- 運用・管理 -->
    <v-list class="management-list">
      <!-- 店舗情報 -->
      <v-list-subheader v-show="isDrawerOpened" class="sidebar-title">
        {{ dictReport.storeTitle }}
      </v-list-subheader>
      <v-list-subheader v-show="isDrawerOpened" class="sub-title">
        <v-icon size="14" color="primary">fas fa-caret-down</v-icon>
        {{ dictReport.subTitleStoreInfo }}
      </v-list-subheader>
      <!-- 基本情報 -->
      <v-tooltip transition="fade-transition" :disabled="isDrawerOpened" right>
        <template #activator="{ props }">
          <v-list-item
            v-if="isInactiveStoreLocation || canShowStoreLocation"
            :disabled="isInactiveStoreLocation"
            v-bind="props"
            class="sidebar-item"
            @click="jumpTo(pageItems.storeInfoBatch)"
          >
            <template #prepend>
              <v-icon size="16" :color="isActive(pageItems.storeInfoBatch)">fas fa-shop</v-icon>
            </template>
            <v-list-item-title v-show="isDrawerOpened" class="menu-title">
              {{ dictReport.storeInfoBatch }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>{{ dictReport.storeInfoBatch }}</span>
      </v-tooltip>

      <!-- 詳細情報 -->
      <v-tooltip transition="fade-transition" :disabled="isDrawerOpened" right>
        <template #activator="{ props }">
          <v-list-item
            v-if="isInactiveMediaLink || canShowMediaLink"
            :disabled="isInactiveMediaLink"
            v-bind="props"
            class="sidebar-item"
            @click="jumpTo(pageItems.mediaLink)"
          >
            <template #prepend>
              <v-icon size="16" :color="isActive(pageItems.mediaLink)">
                fas fa-rectangle-list
              </v-icon>
            </template>
            <v-list-item-title v-show="isDrawerOpened" class="menu-title">
              {{ dictReport.storeInfoDetail }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>{{ dictReport.storeInfoDetail }}</span>
      </v-tooltip>

      <!-- 変更提案 -->
      <v-tooltip transition="fade-transition" :disabled="isDrawerOpened" right>
        <template #activator="{ props }">
          <v-list-item
            v-if="isInactiveStoreUpdate || canShowStoreUpdate"
            v-bind="props"
            :disabled="isInactiveStoreUpdate"
            class="sidebar-item"
            @click="jumpTo(pageItems.storeUpdatesS)"
          >
            <template #prepend>
              <v-icon size="16" :color="isActive(pageItems.storeUpdatesS)">
                fas fa-exclamation-triangle
              </v-icon>
            </template>
            <v-list-item-title v-show="isDrawerOpened" class="menu-title">
              {{ dictReport.storeUpdates }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>{{ dictReport.storeUpdates }}</span>
      </v-tooltip>

      <!-- 画像 -->
      <v-tooltip transition="fade-transition" :disabled="isDrawerOpened" right>
        <template #activator="{ props }">
          <v-list-item
            v-if="isInactiveMedia || canShowMedia"
            v-bind="props"
            :disabled="isInactiveMedia"
            class="sidebar-item"
            @click="jumpTo(pageItems.storeImageBatch)"
          >
            <template #prepend>
              <v-icon size="16" :color="isActive(pageItems.storeImageBatch)">
                fas fa-file-image
              </v-icon>
            </template>
            <v-list-item-title v-show="isDrawerOpened" class="menu-title">
              {{ dictReport.storeImageBatch }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>{{ dictReport.storeImageBatch }}</span>
      </v-tooltip>
    </v-list>
    <v-list class="management-list">
      <v-list-subheader v-show="isDrawerOpened" class="sub-title">
        <v-icon size="14" color="primary">fas fa-caret-down</v-icon>
        {{ dictReport.subTitleAction }}
      </v-list-subheader>
      <!-- 投稿 -->
      <v-tooltip transition="fade-transition" :disabled="isDrawerOpened" right>
        <template #activator="{ props }">
          <v-list-item
            v-if="isInactivePost || canShowPost"
            v-bind="props"
            :disabled="isInactivePost"
            class="sidebar-item"
            @click="jumpTo(pageItems.gmbpost)"
          >
            <template #prepend>
              <v-icon size="16" :color="isActive(pageItems.gmbpost)">fas fa-bullhorn</v-icon>
            </template>
            <v-list-item-title v-show="isDrawerOpened" class="menu-title">
              {{ dictReport.gmbpost }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>{{ dictReport.gmbpost }}</span>
      </v-tooltip>

      <!-- メニュー -->
      <v-tooltip v-if="canShowArea" transition="fade-transition" :disabled="isDrawerOpened" right>
        <template #activator="{ props }">
          <v-list-item
            v-if="isInactiveMenu || canShowMenu"
            v-bind="props"
            :disabled="isInactiveMenu"
            class="sidebar-item"
            @click="jumpTo(pageItems.foodMenu)"
          >
            <template #prepend>
              <v-icon size="16" :color="isActive(pageItems.foodMenu)">fas fa-clipboard-list</v-icon>
            </template>
            <v-list-item-title v-show="isDrawerOpened" class="menu-title">
              {{ dictReport.foodMenu }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>{{ dictReport.foodMenu }}</span>
      </v-tooltip>

      <!-- クチコミ返信 -->
      <v-tooltip transition="fade-transition" :disabled="isDrawerOpened" right>
        <template #activator="{ props }">
          <v-list-item
            v-if="isInactiveReview || canShowReview"
            v-bind="props"
            :disabled="isInactiveReview"
            class="sidebar-item"
            @click="jumpTo(pageItems.reviewReply)"
          >
            <template #prepend>
              <v-icon size="16" :color="isActive(pageItems.reviewReply)">
                far fa-comment-dots
              </v-icon>
            </template>
            <v-list-item-title v-show="isDrawerOpened" class="menu-title">
              {{ dictReport.reviewReply }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>{{ dictReport.reviewReply }}</span>
      </v-tooltip>
    </v-list>
    <!-- サブメニュー -->
    <v-menu v-model="isSubMenuOpened" attach="#app-sidebar" offset-y>
      <v-list v-show="menuLinks().length > 0" dense class="floating-conf-button">
        <v-list-item
          v-for="menu in menuLinks()"
          v-show="menu.isInactive || menu.canShow"
          :key="menu.routeName"
          :disabled="menu.isInactive"
          @click="beforeEnter(menu.routeName, menu.routeParams)"
        >
          <v-list-item-title class="sub-menu">
            {{ menu.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-navigation-drawer>

  <!-- SP用ドロップダウンメニュー -->
  <v-navigation-drawer
    v-if="isMobile"
    id="app-dropdown-menu"
    :key="updateKey"
    v-model="isDrawerOpened"
    location="top"
    :scrim="false"
    @transitionend="transitionend"
  >
    <!-- 企業名・ユーザー名 -->
    <v-list-item class="menu-header">
      <div class="d-flex pt-4 pb-4">
        <div class="company-icon mr-4">
          <v-icon>fas fa-building</v-icon>
        </div>
        <div>
          <v-row class="company">{{ companyName }}</v-row>
          <v-row class="user">{{ userName }}</v-row>
        </div>
      </div>
    </v-list-item>

    <v-list class="management-list mt-4">
      <!-- 投稿 -->
      <v-list-item
        v-if="isInactivePost || canManagePost"
        :disabled="isInactivePost"
        class="sidebar-item-sp"
        :style="isActiveItem(pageItems.gmbpost)"
        @click="jumpTo(pageItems.gmbpost)"
      >
        <template #prepend>
          <v-icon size="24">fas fa-bullhorn</v-icon>
        </template>
        <v-list-item-title class="menu-title-sp">
          {{ dictReport.gmbpost }}
        </v-list-item-title>
      </v-list-item>

      <!-- 投稿履歴 -->
      <v-list-item
        v-if="isInactivePost || canShowPost"
        :disabled="isInactivePost"
        class="sidebar-item-sp"
        :style="isActiveItem(pageItems.gmbpostHistory)"
        @click="jumpTo(pageItems.gmbpostHistory)"
      >
        <template #prepend>
          <v-icon size="24">fas fa-clipboard-list</v-icon>
        </template>
        <v-list-item-title class="menu-title-sp">
          {{ dictReport.gmbpostHistory }}
        </v-list-item-title>
      </v-list-item>

      <!-- クチコミ返信 -->
      <v-list-item
        v-if="isInactiveReview || canShowReview"
        :disabled="isInactiveReview"
        class="sidebar-item-sp"
        :style="isActiveItem(pageItems.reviewReply)"
        @click="jumpTo(pageItems.reviewReply)"
      >
        <template #prepend>
          <v-icon size="24">far fa-comment-dots</v-icon>
        </template>
        <v-list-item-title class="menu-title-sp">
          {{ dictReport.reviewReply }}
        </v-list-item-title>
      </v-list-item>

      <!-- 店舗画像一括更新 -->
      <v-list-item
        v-if="isInactiveMedia || canManageMedia"
        :disabled="isInactiveMedia"
        class="sidebar-item-sp"
        :style="isActiveItem(pageItems.storeImageBatch)"
        @click="jumpTo(pageItems.storeImageBatch)"
      >
        <template #prepend>
          <v-icon size="24">fas fa-file-image</v-icon>
        </template>
        <v-list-item-title class="menu-title-sp">
          {{ dictReport.storeImageBatch }}
        </v-list-item-title>
      </v-list-item>

      <!-- PC版に切り替える -->
      <v-list-item class="mt-5 d-flex justify-center">
        <v-btn variant="elevated" class="changeButton" @click="changeDeviceMode">
          {{ dictReport.changePCMode }}
        </v-btn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
