<template>
  <VeeForm ref="observer" as="" data-testid="form">
    <div class="form-information">{{ dict.requiredDesc }}</div>

    <Field
      v-slot="{ handleChange, value, errors }"
      v-model="area.name"
      rules="required"
      :name="dict.name.title"
    >
      <span class="tooltip-target required-highlight">{{ dict.name.title }}</span>
      <span>
        <ToolTipIcon :label="dict.name.supplement" />
      </span>
      <o-field id="areaName">
        <o-input
          ref="areaName"
          data-testid="areaName"
          name="areaName"
          maxlength="64"
          :has-counter="false"
          :model-value="value"
          @update:model-value="handleChange"
        />
      </o-field>
      <p data-testid="areaNameErrorMessage" class="error-message">
        {{ errors[0] }}
      </p>
    </Field>

    <h2 class="subtitle">クチコミアラート設定</h2>
    <o-field horizontal label="星数">
      <v-btn-toggle
        v-model="area.alertStarRatings"
        density="compact"
        multiple
        class="toggle-button-group"
      >
        <v-btn
          v-for="starRating in starRatings"
          :key="starRating"
          class="toggle-button"
          selected-class="toggle-button-selected"
          elevation="0"
          variant="outlined"
          :data-testid="`starRating-${starRating}`"
          :value="starRating"
        >
          <span v-for="n in starRating" :key="n">
            <i class="mdi mdi-star mdi-24px" />
          </span>
        </v-btn>
      </v-btn-toggle>
    </o-field>
    <o-field horizontal label="感情スコア">
      <v-btn-toggle
        v-model="area.alertSentimentRanges"
        density="compact"
        multiple
        class="toggle-button-group"
      >
        <v-btn
          v-for="sentimentLevel in sentimentLevels"
          :key="sentimentLevel.value"
          class="toggle-button"
          selected-class="toggle-button-selected"
          elevation="0"
          variant="outlined"
          :data-testid="`sentimentLevel-${sentimentLevel.value}`"
          :value="sentimentLevel.value"
        >
          {{ sentimentLevel.name }}
        </v-btn>
      </v-btn-toggle>
    </o-field>

    <o-field data-testid="alertKeywords" horizontal label="キーワード">
      <o-inputitems
        v-model="area.alertKeywords"
        ellipsis
        icon="label"
        placeholder="指定キーワードを含むクチコミ投稿を通知します。"
      ></o-inputitems>
    </o-field>

    <div>
      <button data-testid="goBack" class="button is-primary" @click.prevent="goBack">
        {{ dict.button_go_back }}
      </button>

      <button v-if="isNew" class="button is-primary" data-testid="register" @click="submit">
        {{ dict.button_register }}
      </button>
      <button v-else class="button is-primary" data-testid="update" @click="submit">
        {{ dict.button_update }}
      </button>
    </div>
    <div v-if="isLoading" class="progress-circular-container">
      <v-progress-circular
        :size="80"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </VeeForm>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
