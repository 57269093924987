import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export const am5FontFamily = "Roboto, sans-serif";

/** Amcharts5の各チャートを初期化する */
export function initChart(eleId: string, animation = true): am5.Root {
    am5.array.each(am5.registry.rootElements, (existedRoot) => {
        if (existedRoot?.dom?.id === eleId) {
            existedRoot.dispose();
        }
    });

    // rootを再生成
    const root = am5.Root.new(eleId);
    if (animation) {
        root.setThemes([am5themes_Animated.new(root)]);
    }
    return root;
}
