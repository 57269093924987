import { Component, Prop, Vue, toNative } from "vue-facing-decorator";

@Component({})
class TimePicker extends Vue {
    @Prop() time: string;
    @Prop() disabled: boolean;
    menu: boolean = null;
    inputTime: string;

    created(): void {
        this.inputTime = this.time;
    }

    setTime(): void {
        (this.$refs.pickermenu as any).save(this.inputTime);
        this.$emit("update", this.inputTime);
    }
}
export default toNative(TimePicker);
