import type { LocalDate } from "@js-joda/core";
import { ZoneId, DateTimeFormatter, ZonedDateTime } from "@js-joda/core";
import "@js-joda/timezone";

export const rangeLabel = (s: string, separator: string, unit: string): string => {
    const splited = s.split(separator);
    return `${splited[0]}${unit}${splited.length > 1 ? "~" : ""}`;
};

const omitYearFormatter = DateTimeFormatter.ofPattern("M/d");

export function omitYear(date: LocalDate): string {
    return date.format(omitYearFormatter);
}

export function oneYearAgo(date: Date): Date {
    const dt = new Date(date.getTime());
    dt.setFullYear(dt.getFullYear() - 1);
    return dt;
}

export function oneMonthAgo(endDate: Date): Date {
    const dt = new Date(endDate.getTime());

    // 4週間とみた時の開始日
    dt.setDate(dt.getDate() - 27);

    return dt;
}

export function toEndOfDay(d: Date): Date {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59, 999);
}

export function toUniversalStartOfDay(d: Date): Date {
    return new Date(new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0).toUTCString());
}

export function toUniversalEndOfDay(d: Date): Date {
    return new Date(
        new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59, 999).toUTCString()
    );
}

export function toLocalDateString(d: Date, isMonthTo = false): string {
    if (isMonthTo) {
        // 月指定の終了日は月末にする
        const date = new Date(Date.UTC(d.getFullYear(), d.getMonth(), 1));
        date.setMonth(date.getMonth() + 1);
        date.setDate(0);
        const lastDate = date.getDate();
        return new Date(Date.UTC(d.getFullYear(), d.getMonth(), lastDate))
            .toISOString()
            .slice(0, 10);
    } else {
        return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
            .toISOString()
            .slice(0, 10);
    }
}

const dateTimeWithSecondsPattern = "yyyy/MM/dd HH:mm:ss";
const dateTimeWithoutSecondsPattern = "yyyy/MM/dd HH:mm";
/**
 * RFC3339形式から読みやすい形式に日時を変換
 */
export function convertDateTime(
    rfc3339: string | undefined,
    withoutSeconds: boolean = false
): string {
    if (rfc3339 === undefined) {
        return "";
    }
    const pattern = withoutSeconds ? dateTimeWithoutSecondsPattern : dateTimeWithSecondsPattern;
    try {
        return ZonedDateTime.parse(rfc3339)
            .withZoneSameInstant(ZoneId.of("Asia/Tokyo"))
            .format(DateTimeFormatter.ofPattern(pattern));
    } catch (error) {
        console.error(error);
        return "";
    }
}

export function sortByDateProperty(property: string, order: "asc" | "desc") {
    return (a: { [key: string]: string }, b: { [key: string]: string }): number => {
        const dateA = new Date(a[property]).getTime();
        const dateB = new Date(b[property]).getTime();
        return order === "asc" ? dateA - dateB : dateB - dateA;
    };
}
