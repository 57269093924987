import type {
    EntitiesQandaUpdate,
    EntitiesQandaStore,
    EntitiesStore,
    MybusinessqandaQuestion,
    MybusinessqandaAnswer,
} from "@/types/ls-api";
import dayjs from "dayjs";

export class QandaRow {
    constructor(qas: EntitiesQandaStore, store: EntitiesStore) {
        this.id = qas.poiID;
        this.qas = qas;
        this.store = store;
        this.detailRows = makeDetailRow(
            qas.cache?.questions ?? [],
            qas.currentApplicationTask?.updates ?? [],
            qas.currentApplicationTask?.status ?? ""
        );
    }
    id: number;
    qas: EntitiesQandaStore;
    store?: EntitiesStore;
    detailRows: DetailRow[];
    /** このインスタンスの qanda_stores を変更する */
    setQandaStore(qas: EntitiesQandaStore) {
        this.id = qas.poiID;
        this.qas = qas;
        this.detailRows = makeDetailRow(
            qas.cache?.questions ?? [],
            qas.currentApplicationTask?.updates ?? [],
            qas.currentApplicationTask?.status ?? ""
        );
    }
    setCacheIsNotUpdated() {
        this.qas.cacheSummary.updatedAt = "";
    }
    /** キャッシュが更新済みか否かを判定する */
    isCacheUpdated(): boolean {
        if (!this.qas.cacheSummary?.updatedAt || !this.qas.cacheSummary?.updateRequestedAt) {
            return false;
        }
        // キャッシュ更新をリクエストしてから即座にキャッシュが更新された場合に
        // updatedAt < updateRequestedAt となる場合がある（サーバの時計が少しずれていると予想される）
        // よって、updatedAt に1秒加算して比較するようにする
        const updateRequestedAt = dayjs(this.qas.cacheSummary.updateRequestedAt);
        const updatedAt = dayjs(this.qas.cacheSummary.updatedAt);
        return updateRequestedAt.isBefore(updatedAt.add(1, "second"));
    }
    /** 行が編集可能か判定する */
    isEditable(): boolean {
        if (!this.isCacheUpdated()) {
            return false;
        }
        return ["PENDING", "SUCCESS", "FAILURE", "CANCELED"].includes(
            this.qas.currentApplicationTask?.status ?? "PENDING"
        );
    }
    getCountQuestionsFromMerchant(): number {
        const c1 = this.qas.cacheSummary?.count?.questionsFromMerchantWithTostoreAnswer ?? 0;
        const c2 = this.qas.cacheSummary?.count?.questionsFromMerchantWithoutTostoreAnswer ?? 0;
        return c1 + c2;
    }
    getCountQuestionsFromOther(): number {
        const c1 = this.qas.cacheSummary?.count?.questionsFromOtherWithTostoreAnswer ?? 0;
        const c2 = this.qas.cacheSummary?.count?.questionsFromOtherWithoutTostoreAnswer ?? 0;
        return c1 + c2;
    }
}

export type DetailRow = {
    id: number;
    question: MybusinessqandaQuestion;
    answer: MybusinessqandaAnswer;
    beforeQuestion: string;
    afterQuestion: string;
    author: string;
    isOwnerQuestion: boolean;
    beforeAnswer: string;
    afterAnswer: string;
    otherAnswerCount: number;
};

function makeDetailRow(
    questions: MybusinessqandaQuestion[],
    updates: EntitiesQandaUpdate[],
    status: string
): DetailRow[] {
    if (["SUCCESS", "FAILURE", "CANCELED"].includes(status)) {
        updates = [];
    }
    const rows = [] as DetailRow[];
    let count = 0;
    for (const q of questions) {
        const updateQ = updates.find(
            (u) =>
                u.criteria === q.name && ["UPDATE_QUESTION", "DELETE_QUESTION"].includes(u?.action)
        );
        const updateA = updates.find(
            (u) => u.criteria === q.name && ["UPSERT_ANSWER", "DELETE_ANSWER"].includes(u?.action)
        );
        const answer = q.topAnswers?.find((a) => a?.author?.type === "MERCHANT");
        const otherAnswerCount = (q.topAnswers?.length ?? 0) - (answer ? 1 : 0);
        rows.push({
            id: count++,
            question: q,
            answer: answer,
            beforeQuestion: q.text ?? "",
            afterQuestion: (updateQ ? updateQ?.question : q.text) ?? "",
            author: q.author?.type === "MERCHANT" ? "オーナー" : q.author?.displayName ?? "",
            isOwnerQuestion: q.author?.type === "MERCHANT",
            beforeAnswer: answer?.text ?? "",
            afterAnswer: (updateA ? updateA?.answer : answer?.text) ?? "",
            otherAnswerCount,
        });
    }
    for (const update of updates) {
        if (update.action === "CREATE_QUESTION_AND_ANSWER") {
            rows.push({
                id: count++,
                question: null,
                answer: null,
                beforeQuestion: "",
                afterQuestion: update.question ?? "",
                author: "オーナー",
                isOwnerQuestion: true,
                beforeAnswer: "",
                afterAnswer: update.answer ?? "",
                otherAnswerCount: 0,
            });
        }
    }
    return rows;
}

const hasErrorQ = function (i: number, poiId: number, text: string, errors: string[]): boolean {
    // 0文字の場合は質問削除する
    if (text.length === 0 || text === "-") {
        // 質問削除のケース(XLSXでは空文字、処理上では"-"で表現している)
        return false;
    } else if (text.length < 10) {
        errors.push(`${i + 1}行目 店舗ID: ${poiId} 質問は10文字以上で入力してください`);
        return true;
    } else if (250 < text.length) {
        errors.push(`${i + 1}行目 店舗ID: ${poiId} 質問は250文字以内で入力してください`);
        return true;
    }
};
const hasErrorA = function (i: number, poiId: number, text: string, errors: string[]): boolean {
    if (1000 < text.length) {
        errors.push(`${i + 1}行目 店舗ID: ${poiId} 回答は1000文字以内で入力してください`);
        return true;
    }
};
export function makeUpdatesFromImportData(
    data: string[][],
    rows: QandaRow[]
): [Map<number, EntitiesQandaUpdate[]>, string[]] {
    const errors = [] as string[];
    const umap = new Map<number, EntitiesQandaUpdate[]>();

    for (let i = 1; i < data.length; i++) {
        const line = data[i];
        const poiId = parseInt(line[0]);
        if (!umap.has(poiId)) {
            umap.set(poiId, []);
        }
        const updates = umap.get(poiId);
        const row = rows.find((s) => s.qas.poiID === poiId);
        if (!row) {
            errors.push(`${i}行目 店舗ID: ${poiId} が見つかりません`);
            continue;
        }
        // 質問ID が空欄の場合は新規登録
        if (line[2] === "") {
            // 質問ID と 質問が入力されていなければ空行とみなしてスキップ
            if (line[3] === "") {
                continue;
            }
            if (hasErrorQ(i, poiId, line[3], errors)) continue;
            if (hasErrorA(i, poiId, line[4], errors)) continue;
            updates.push({
                action: "CREATE_QUESTION_AND_ANSWER",
                question: line[3],
                answer: line[4],
            });
            continue;
        }
        const question = row.qas.cache?.questions?.find(
            (q) => /questions\/([^/]+)/.exec(q.name)?.[1] === line[2]
        );
        if (!question) {
            errors.push(`${i}行目 店舗ID: ${poiId} 質問ID: ${line[2]} が見つかりません`);
            continue;
        }
        if (hasErrorQ(i, poiId, line[3], errors)) continue;
        if (line[3] === "") {
            console.log("質問を削除する");
            updates.push({
                action: "DELETE_QUESTION",
                criteria: question.name,
                question: "-",
            });
        } else if (question.text !== line[3]) {
            updates.push({
                action: "UPDATE_QUESTION",
                criteria: question.name,
                question: line[3],
            });
        }
        const a = question.topAnswers?.find((a) => a?.author?.type === "MERCHANT")?.text ?? "";
        if (a === line[4]) {
            continue;
        }
        if (hasErrorA(i, poiId, line[4], errors)) continue;
        if (line[4] === "") {
            updates.push({
                action: "DELETE_ANSWER",
                criteria: question.name,
                answer: line[4],
            });
        } else {
            updates.push({
                action: "UPSERT_ANSWER",
                criteria: question.name,
                answer: line[4],
            });
        }
    }
    return [umap, errors];
}
