import type { Row, FlattenedLocation, GmbLocationInfo } from "./Row";
import { flattenLocation, diffMasks } from "./Row";
import Handsontable from "handsontable";
import dot from "dot-object";
import objectPath from "object-path";
import type { SnackbarToast } from "@/components/shared/snackbar/snackbar-shared";
import { getDashboardUrl } from "@/helpers/gmb";
import { divide } from "@/helpers/api/utils";

import { requiredAuth } from "@/helpers";
import type {
    EntitiesStoresResponse,
    EntitiesLocalBusinessInfo,
    EntitiesDepartment,
    EntitiesOpeningHoursSpecification,
    EntitiesRival,
    EntitiesStore,
    EntitiesGMBLocationInfo,
    MybusinessbusinessinformationCategory,
    ControllersBatchGetLocationsOutput,
    MybusinessbusinessinformationAttributeMetadata,
    MybusinessbusinessinformationAttribute,
    MybusinessbusinessinformationMoreHoursType,
    MybusinessbusinessinformationMoreHours,
} from "@/types/ls-api";
import type { AttributeColumn } from "@/helpers/attribute";
import {
    convertMetadatasToAttributeColumns,
    uniqueAndSort,
    initialColumns,
    convertMetadataToAttributeColumns,
} from "@/helpers/attribute";
import { REGEX_URL_OR_BLANK, REGEX_MULTI_URL_OR_BLANK } from "@/helpers/validator";
import type { RegularHourPeriod, SpecialHourPeriod } from "./business-hours";
import {
    week,
    fetchHoursColumns,
    toHour,
    toSpecialHour,
    enabledMoreHours,
    genMoreHoursTypesKey,
    parseMoreHoursTypeId,
} from "./business-hours";
import { localBusinessTypeHierarchy } from "@/const";
import wordDictionary from "@/word-dictionary";

import type { mybusiness_v4 } from "@/types/gmb/mybusiness/v4";
import type { AxiosResponse } from "axios";
import type { LoadingStatus } from "@/helpers/api/utils";
import { useIndexedDb } from "@/storepinia/idxdb";
import { useSnackbar } from "@/storepinia/snackbar";
import { columns as mediaLinkColumns } from "@/components/root/contents/media-link/model";
type Attribute = MybusinessbusinessinformationAttribute;
type AttributeMetadata = MybusinessbusinessinformationAttributeMetadata;
type RepeatedEnumAttributeValue = mybusiness_v4.Schema$RepeatedEnumAttributeValue;

export type CharacterLimit = {
    limit: number;
    label: string;
    // GBP APIへPatchを行う際に文字数制限をチェックする場合はtrue
    gbpCheck: boolean;
};
export const characterLimits: { [key: string]: CharacterLimit } = {
    storeCode: {
        limit: 64,
        label: "店舗コード",
        gbpCheck: true,
    },
    title: {
        limit: 125,
        label: "ビジネス名",
        gbpCheck: true,
    },
    description: {
        limit: 750,
        label: "ビジネス情報",
        gbpCheck: true,
    },
    structuredPageID: {
        limit: 64,
        label: "情報統一化用タグ",
        gbpCheck: false,
    },
};

export interface GmbLocationListResult {
    locations: GmbLocationInfo[];
    nextPageToken: string;
    totalSize: number;
}
export interface GridSettingsPlus extends Handsontable.GridSettings {
    row: number;
    isDirty: boolean;
}

export interface Column {
    title: string;
    data: string;
    type?: string;
    readOnly?: boolean;
    className?: string;
    wordWrap?: boolean;
    width?: string;
    filter?: boolean;
    strict?: boolean;
    source?: any;
    renderer?: any;
    validator?: any;
}

const openStatuses = wordDictionary.storeLocationForm.openStatuses.map((x) => x.name);

const infoColumns: Column[] = [
    { title: "店舗ID", data: "poiID", type: "numeric", readOnly: true, className: "rowheader" },
    { title: "店舗コード", data: "storeCode", validator: storeCodeValidator },
    { title: "ビジネス名", data: "title", validator: nameValidator },
    // ↓GBP API移行でステータス取得出来なくなったのでコメントアウト
    // { title: "ステータス", data: "locationState", readOnly: true },
    { title: "住所 1", data: "address1" },
    { title: "住所 2", data: "address2" },
    { title: "住所 3", data: "address3" },
    { title: "住所 4", data: "address4" },
    { title: "住所 5", data: "address5" },
    { title: "行政区域", data: "prefecture" },
    { title: "国または地域", data: "regionCode", readOnly: true },
    { title: "郵便番号", data: "zipCode" },
    { title: "緯度", data: "latitude", type: "numeric", readOnly: true },
    { title: "経度", data: "longitude", type: "numeric", readOnly: true },
    { title: "電話番号（メイン）", data: "primaryPhone" },
    { title: "電話番号（サブ）", data: "additionalPhones" },
    { title: "ウェブサイト", data: "webSite", validator: webSiteURLValidator },
    {
        title: "メインカテゴリ",
        data: "primaryCategory",
        type: "autocomplete",
        strict: true,
        filter: true,
        renderer: myAutocompleteRenderer,
    },
    { title: "追加カテゴリ", data: "additionalCategories" },
    {
        title: "ビジネス情報",
        data: "description",
        type: "text",
        width: "600px",
        validator: descriptionValidator,
    },
    { title: "開業日", data: "openingDate" },
    {
        title: "開業/閉業",
        data: "openStatus",
        type: "dropdown",
        source: openStatuses,
    },
    { title: "ラベル", data: "labels" },
    {
        title: "AdWords 住所表示オプションの電話番号",
        data: "adPhones",
        width: "150px",
    },
    {
        title: "情報統一化用タグ",
        data: "structuredPageID",
        readOnly: true,
        wordWrap: false,
        validator: structuredPageIDValidator,
    },
];

infoColumns.forEach((column) => {
    // readOnly or strict 以外のセルにrendererを設定する
    if (column.readOnly !== true && column.strict !== true) {
        column.renderer = myRenderer;
    }
});

const structColumns: Column[] = [
    { title: "店舗ID", data: "poiID", type: "numeric", readOnly: true, className: "rowheader" },
    { title: "店舗コード", data: "storeCode", readOnly: true },
    { title: "ビジネス名", data: "title", readOnly: true },
    { title: "支払情報", data: "paymentAccepted" },
    { title: "通貨", data: "currenciesAccepted" },
    { title: "価格帯", data: "priceRange" },
    { title: "提供地域", data: "areaServed" },
    { title: "収容人数", data: "maximumAttendeeCapacity", type: "numeric" },
    { title: "喫煙有無", data: "smokingAllowed", validator: /^[ox]?$/ },
    { title: "ブランド名", data: "brandName" },
    {
        title: "ブランドロゴ画像",
        data: "brandLogo",
        validator: webSiteURLValidator,
    },
    { title: "ブランドスローガン", data: "brandSlogan" },
    { title: "場所ごとの営業時間", data: "department" },
    {
        title: "カテゴリー",
        data: "primaryType",
        type: "autocomplete",
        strict: true,
        filter: true,
        renderer: myAutocompleteRenderer,
    },
    {
        title: "サブカテゴリー",
        data: "subType",
        type: "autocomplete",
        strict: true,
        filter: true,
        renderer: myAutocompleteRenderer,
    },
];
structColumns.forEach((column) => {
    // poiID以外のセルにrendererを設定する
    if (!["poiID", "storeCode", "locationName", "primaryType", "subType"].includes(column.data)) {
        column.renderer = myRenderer;
    }
});

const rivalColumns: Column[] = [
    { title: "店舗ID", data: "poiID", type: "numeric", readOnly: true, className: "rowheader" },
    { title: "店舗コード", data: "storeCode", readOnly: true },
    { title: "ビジネス名", data: "title", readOnly: true },
    { title: "競合店名", data: "rivalName" },
    { title: "競合店キーワード", data: "rivalKeyword" },
];
rivalColumns.forEach((column) => {
    // poiID以外のセルにrendererを設定する
    const cells = ["poiID", "storeCode", "locationName"];
    if (!cells.includes(column.data)) {
        column.renderer = myRenderer;
    }
});

const nowrapCols = ["locationName", "title", "webSite", "businessHoursSpecial", "description"];
/** 変更のあったセルの色を変更するセルレンダラー */
export function myRenderer(
    instance: Handsontable,
    td: HTMLElement,
    rownum: number,
    col: number,
    prop: string,
    value: any,
    cellProperties: Handsontable.GridSettings
): void {
    const table: Model = (instance as any).model;
    // value が null の場合は空文字列として扱う
    value = value ?? "";
    // 行データを本来の行番号から取得する(ソートへの対応)
    const originalRowNum: number = (instance.getCellMeta(rownum, 0) as GridSettingsPlus).row;
    const row: Row = instance.getSourceDataAtRow(originalRowNum) as Row;
    const initialValue = table.getSource(row?.name, prop) ?? "";

    if (cellProperties.valid === false) {
        // バリデーションに引っかかっていた場合は赤にする
        td.style.background = "#F00";
    } else if (cellProperties.readOnly == true) {
        // 編集不可セルは灰色にする
        td.style.background = table.canManage ? "#AAA" : "#fff";
    } else if (initialValue !== value) {
        // 元の値とvalueが異なる場合はセルの色を変更する
        td.style.background = "#AFF";
    }
    if (nowrapCols.includes(prop)) {
        td.className = "nowrap";
    }
    Handsontable.dom.fastInnerHTML(td, value);
}
//  (instance: _Handsontable.Core, TD: HTMLElement, row: number, col: number, prop: string | number, value: any, cellProperties: GridSettings): HTMLElement;

export function myAutocompleteRenderer(
    instance: Handsontable,
    td: HTMLElement,
    rownum: number,
    column: number,
    prop: string | number,
    value: any,
    cellProperties: Handsontable.GridSettings
): void {
    // eslint-disable-next-line  prefer-rest-params
    Handsontable.renderers.AutocompleteRenderer.apply(instance, arguments as any);

    const table: Model = (instance as any).model;
    // value が null の場合は空文字列として扱う
    value = value ?? "";
    // 行データを本来の行番号から取得する(ソートへの対応)
    const originalRowNum: number = (instance.getCellMeta(rownum, 0) as GridSettingsPlus).row;
    const row: Row = instance.getSourceDataAtRow(originalRowNum) as Row;
    const initialValue = table.getSource(row?.name, prop as string) ?? "";
    if (initialValue !== value) {
        // 元の値とvalueが異なる場合はセルの色を変更する
        td.style.background = "#AFF";
    }
}
export function myAttributeRenderer(
    instance: Handsontable,
    td: HTMLElement,
    rownum: number,
    col: number,
    prop: string,
    value: any,
    cellProperties: Handsontable.GridSettings
): void {
    const table: Model = (instance as any).model;
    value = value ?? "";
    // 行データを本来の行番号から取得する(ソートへの対応)
    const originalRowNum: number = (instance.getCellMeta(rownum, 0) as GridSettingsPlus).row;
    const row: Row = instance.getSourceDataAtRow(originalRowNum) as Row;
    const initialValue = table.getSource(row?.name, prop) ?? "";

    if (!table.isEditable(row?.name, prop)) {
        // 変更できない属性はセルの色を変更する
        td.style.background = "#AAA";
        td.innerHTML = "<div></div>";
    } else if (value && cellProperties.valid === false) {
        // 値が入っていて、バリデーションに引っかかっていた場合は赤にする
        td.style.background = "#F00";
    } else if (initialValue !== value) {
        // 元の値とvalueが異なる場合はセルの色を変更する
        td.style.background = "#AFF";
    }
    Handsontable.dom.fastInnerHTML(td, value);
}

export function moreHoursTypesRenderer(
    instance: Handsontable,
    td: HTMLElement,
    rownum: number,
    col: number,
    prop: string,
    value: any,
    cellProperties: Handsontable.GridSettings
): void {
    const table: Model = (instance as any).model;
    // value が null の場合は空文字列として扱う
    value = value ?? "";
    // 行データを本来の行番号から取得する(ソートへの対応)
    const originalRowNum: number = (instance.getCellMeta(rownum, 0) as GridSettingsPlus).row;
    const row: Row = instance.getSourceDataAtRow(originalRowNum) as Row;
    const initialValue = table.getSource(row?.name, prop) ?? "";

    if (cellProperties.readOnly == true || !table.isEditableMoreHoursTypes(row?.name, prop)) {
        // 編集不可または変更できない営業時間の詳細のセルの色は灰色にする
        td.style.background = table.canManage ? "#AAA" : "#fff";
    } else if (value && cellProperties.valid === false) {
        // 値が入っていて、バリデーションに引っかかっていた場合は赤にする
        td.style.background = "#F00";
    } else if (initialValue !== value) {
        // 元の値とvalueが異なる場合はセルの色を変更する
        td.style.background = "#AFF";
    }
    if (nowrapCols.includes(prop)) {
        td.className = "nowrap";
    }
    Handsontable.dom.fastInnerHTML(td, value);
}

/** 店舗コードのバリデーション */
function storeCodeValidator(value: string, callback): void {
    callback(value.length <= characterLimits.storeCode.limit);
}

/** ビジネス名のバリデーション */
function nameValidator(value: string, callback): void {
    callback(value.length <= characterLimits.title.limit);
}

/** ビジネス情報のバリデーション */
function descriptionValidator(value: string, callback): void {
    callback(value.length <= characterLimits.description.limit);
}

/** ウェブサイトURLのバリデーション */
function webSiteURLValidator(value: string, callback): void {
    // マルチバイト文字を含んだURLの場合に備え一旦入力内容をencodeして評価する
    const encodedValue = encodeURI(value);
    const reg = new RegExp(REGEX_URL_OR_BLANK);
    callback(reg.test(encodedValue));
}

/** 情報統一化用タグのバリデーション */
function structuredPageIDValidator(value: string, callback): void {
    // 半角英数字とハイフンのみ許可
    const reg = /^[0-9a-zA-Z-]*$/;
    callback(
        value.length > 0 &&
            value.length <= characterLimits.structuredPageID.limit &&
            reg.test(value)
    );
}
/**
 * Handsontableのベースとなる設定
 * 「店舗情報」も「属性情報」もこれを元にして設定を作成する
 */
const hotSettings: Handsontable.GridSettings = {
    columns: [],
    data: [],
    colHeaders: true,
    rowHeaders: false,
    manualColumnResize: true,
    manualRowResize: false,
    // renderAllRows: false,
    preventOverflow: "horizontal",
    allowInsertRow: false,
    allowInsertColumn: false,
    autoWrapRow: false,
    autoWrapCol: false,
    fixedColumnsLeft: 3, // 先頭の3列を固定表示
    columnSorting: true,
    // stretchH: "none",
    /** readOnlyの行のセルを読み取り専用にする */
    cells(row, column, prop) {
        const hoti: Handsontable = (this as any).instance;
        const model: Model = (hoti as any).model;
        const line: Row = hoti.getSourceDataAtRow(row) as Row;
        if (!line) {
            return {};
        }
        if (line.readOnly === true) {
            return { readOnly: true };
        }
        const key = prop.toString();
        const isAttributeNotEditable =
            key.startsWith("attributes.") && !model.isEditable(line.name, key);
        const isMoreHourTypeNotEditable =
            key.startsWith("moreHours.") && !model.isEditableMoreHoursTypes(line.name, key);
        if (isAttributeNotEditable || isMoreHourTypeNotEditable) {
            return { readOnly: true };
        }
        return {};
    },
    /** 値が更新されたときに、行ヘッダのダーティフラグを更新する */
    afterChange(changes: Array<[number, string | number, any, any]>, source: string) {
        const self = this as Handsontable;
        const model: Model = (self as any).model;
        changes = changes ?? []; // 初回の loadData 時などに null が来るので ?? [] にする
        // 変更分をmodelに反映する
        for (const change of changes) {
            if (change[2] === change[3]) {
                continue;
            }
            const rownum = change[0];
            const cellMeta: GridSettingsPlus = self.getCellMeta(rownum, 0) as GridSettingsPlus;
            const row = self.getSourceDataAtRow(cellMeta.row) as Row;
            model.setVal(row.name, change[1] as string, change[3]);
        }
        // 行ヘッダのダーティフラグを更新する
        const rownums: number[] = Array.from(new Set(changes.map((c) => c[0]))); // 更新されたrownumをまとめる
        for (const rownum of rownums) {
            const cellMeta: GridSettingsPlus = self.getCellMeta(rownum, 0) as GridSettingsPlus;
            const row = self.getSourceDataAtRow(cellMeta.row) as Row;
            // 行のすべてのセルを走査して更新されている値があるか調べる
            const isDirty = model.isDirtyRow(row.name);
            updateModificationMark(self, isDirty, cellMeta, rownum);
        }
        (self as any).parent?.updateDirtyFlag();

        // テーブル下段がずれて描画される問題を補正する
        document.getElementsByClassName("wtHolder")[0]?.scrollBy(0, -1);
    },
    // コピーできる最大行数・列数（handsontable.d.ts の定義が間違っているため、anyでセットする）
    copyPaste: { pasteMode: "", rowsLimit: 40000, columnsLimit: 40000 } as any,
};
export enum TableType {
    Unknown = -1,
    Info = 0,
    Attr = 1,
    Hours = 2,
    Struct = 3,
    Rivals = 4,
    Custom = 5,
    PlaceActionLinks = 6,
}

type StoreAreas = {
    [key: string]: number[];
};

export class Model {
    displayNameToCategoryMap: { [displayName: string]: MybusinessbusinessinformationCategory } = {};
    categoryNameToDisplayNameMap: { [name: string]: string } = {};
    private attributeMap: { [categoryId: string]: AttributeMetadata[] } = {};
    moreHoursTypesMap: { [categoryId: string]: MybusinessbusinessinformationMoreHoursType[] } = {};
    private editableAttributes: {
        [categoryId: string]: { [joinedAttributeId: string]: boolean };
    } = {};
    private editableMoreHoursTypes: {
        [categoryId: string]: { [hoursTypeId: string]: boolean };
    } = {};
    private openStatusMap = new Map(
        wordDictionary.storeLocationForm.openStatuses.map((x) => [x.name, x.value])
    );
    private sources: { [name: string]: Row } = {};
    private rawSources: { [name: string]: GmbLocationInfo } = {};
    private allLocations: { [name: string]: Row } = {};
    private stores: EntitiesStore[];
    private storesWithStructOption: number[] = [];
    bulkUpdateWorkers: number = 0;
    private storeAreas: StoreAreas = {};
    // vueインスタンスが破棄される際にbatchGetループ処理を中断させる為のフラグ
    isDestroyed: boolean = false;
    // 操作権限の有無
    canManage: boolean = true;

    get structOptionCount(): number {
        return this.storesWithStructOption.length;
    }
    async init(): Promise<void> {
        // 管理者が企業を変更した場合などに備えて初期化
        this.sources = {};
        this.allLocations = {};
        this.isDestroyed = false;

        // カテゴリ一覧を取得する
        const categories = await useIndexedDb().getCategories();
        for (const c of categories) {
            this.displayNameToCategoryMap[c.displayName] = c;
            this.categoryNameToDisplayNameMap[c.name] = c.displayName;
        }

        // 情報統一化タグの編集有無を取得して、編集機能を有効化する
        const canEditStructuredPageID = await useIndexedDb().structuredIDEdit;
        if (canEditStructuredPageID) {
            const t = infoColumns.find((c) => c.data === "structuredPageID");
            t.readOnly = false;
            t.renderer = myRenderer;
        }
    }

    /** storesのgmbLocationIDをaccount/xxxxxを除いた状態で一覧で取得する */
    private async getLocationNames(poiGroupId: number): Promise<string[]> {
        const storesRes = await requiredAuth<EntitiesStoresResponse>(
            "get",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/stores`
        );
        this.bulkUpdateWorkers = storesRes?.data?.bulkUpdateWorkers;
        this.stores = (storesRes?.data?.stores ?? []).filter((s) => s.enabled);
        this.stores.sort((a, b) => a.poiID - b.poiID);

        const names: string[] = this.stores.map(
            (store) => store.gmbLocationID.match("(?<=accounts/[0-9]*/)(.*)")[0]
        );
        return names;
    }

    /**
     * accounts.locations.list を実行する。
     * ページング処理を行って全ロケーションを取得する。ロケーションを読み込む毎に LoadingStatus を返す。
     */
    async *fetch(poiGroupId: number, accountName: string): AsyncGenerator<LoadingStatus> {
        /** storesのgmbLocationIDをaccount/xxxxxを除いた状態で一覧で取得する */
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const locationNames: string[] = await this.getLocationNames(poiGroupId);
        yield { completed: false };
        // GBPアクセストークンの初期化
        const ret = await requiredAuth<any>(
            "get",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/gmbapiinit`
        )
            .then(() => {
                return true;
            })
            .catch((error) => {
                const snackbarToast: SnackbarToast = {
                    text: `内部処理エラーが発生しました。システム管理者にお問い合わせください。 ${error.response?.status}`,
                    timeout: 5000,
                };
                useSnackbar().addSnackbarMessages(snackbarToast);
                return false;
            });
        if (!ret) {
            // GBPアクセストークンの初期化失敗を検出したら、処理を中断させる
            yield { completed: false };
            return;
        }

        // グループ絞り込み用にストアのグループ情報だけ抜き出してまとめる
        for (const store of this.stores) {
            this.storeAreas[store.poiID] = store.areas;
        }

        // batchGet同時呼び出し上限
        const concurrency = 10;
        // 一度の呼び出しの際に取得する件数。即ち concurrency * batchSize が並列接続によって一度に取ってくる最大数
        const batchSize = 100;

        const locationNamesChunks: string[][] = divide(locationNames, batchSize);
        const batchSets: string[][][] = divide(locationNamesChunks, concurrency);
        const url = `${
            import.meta.env.VITE_APP_API_BASE
        }v1/companies/${poiGroupId}/${accountName}/locations/batchGet`;
        for (const oneBatches of batchSets) {
            if (this.isDestroyed === true) {
                // 店舗情報の一括更新ページから離脱時batchGet中止させる
                return;
            }
            // 実際にbatchGetを叩きに行く
            const status: Promise<LoadingStatus> = this.getLocationsBatch(url, oneBatches);
            yield status;
        }
    }

    private async getLocationsBatch(
        url: string,
        locationNamesChunks: string[][]
    ): Promise<LoadingStatus> {
        const status: LoadingStatus = { completed: false };
        // (設定できる値はすべて空の状態で保持する必要がある)
        const batchGetPromises: Promise<AxiosResponse<ControllersBatchGetLocationsOutput>>[] = [];
        for (const locationNames of locationNamesChunks) {
            batchGetPromises.push(
                requiredAuth<ControllersBatchGetLocationsOutput>("post", url, null, {
                    names: locationNames,
                })
            );
        }

        await Promise.all(batchGetPromises)
            .then((message: any) => {
                let newLocations: ControllersBatchGetLocationsOutput["locations"] = [];
                for (const res of message) {
                    // キャッシュが無い場合200が返ってくるが、locationsが無いのでエラーになる問題に対処
                    if (res.data?.locations == null) {
                        continue;
                    }
                    newLocations = newLocations.concat(res.data.locations);
                }
                const account = this.stores[0].gmbLocationID.replace(/locations\/[0-9]+/, "");
                for (const l of newLocations) {
                    const store = this.stores.find((s) => {
                        if (s.gmbLocationID === account + l.location.name) {
                            return true;
                        }
                    });
                    if (!store) {
                        continue;
                    }
                    const fl = flattenLocation(
                        { location: l.location },
                        l.attributes,
                        l.location.moreHours,
                        store.localBusinessInfo,
                        store.rivals,
                        this.categoryNameToDisplayNameMap
                    );
                    const r: Row = {
                        rownum: 0,
                        poiID: store.poiID,
                        readOnly: store.readOnly,
                        structuredPageID: store.structuredPageID,
                        ...fl,
                    };
                    this.allLocations[l.location.name] = r;
                    this.sources[l.location.name] = JSON.parse(JSON.stringify(r));
                    this.rawSources[l.location.name] = { location: l.location };
                }
                const locations = Object.values(this.allLocations);
                locations.sort((a, b) => a.poiID - b.poiID);
                locations.forEach((l, i) => {
                    l.rownum = i;
                    this.sources[l.name].rownum = i;
                });
                status.completed = true;
            })
            .catch((error: any) => {
                console.error("[error]", error);
                status.error = error;
            });
        await this.fetchAttributeMetadata();
        await this.fetchMoreHoursTypes();
        return status;
    }

    /**
     * GridSettingsを作成する
     */
    getGridSettings(
        type: TableType,
        canManage: boolean,
        selectedItems?: MybusinessbusinessinformationMoreHoursType[]
    ): Handsontable.GridSettings {
        const settings: Handsontable.GridSettings = { ...hotSettings };
        this.canManage = canManage;

        // type: 店舗情報
        if (type === TableType.Info) {
            const categoryDisplayNames = Object.keys(this.displayNameToCategoryMap);
            infoColumns
                .filter((col) => col.data === "primaryCategory")
                .forEach((col) => (col.source = categoryDisplayNames));
            settings.columns = infoColumns;
            // AutoRowSizeプラグインを無効化
            settings.autoRowSize = false;
        }

        // type: 店舗属性
        if (type === TableType.Attr) {
            // 属性一覧からカラム一覧を作成する
            let acs: AttributeColumn[] = [];
            for (const am of Object.values(this.attributeMap)) {
                acs = acs.concat(convertMetadatasToAttributeColumns(am));
            }
            acs = uniqueAndSort(acs);
            // メディアリンク画面で表示するカラムを除去する
            acs = acs.filter((ac) => !mediaLinkColumns.some((col) => col.gbp === ac.attributeId));
            // 店舗ID, 店舗コード, ビジネス名 を先頭に追加
            acs = initialColumns.concat(uniqueAndSort(acs));
            settings.columns = acs;
            // AutoRowSizeプラグインを有効化
            settings.autoRowSize = { syncLimit: 300, allowSampleDuplicates: true };
        }

        // type: 営業時間
        if (type === TableType.Hours) {
            // カテゴリからカラム一覧を作成する
            settings.columns = fetchHoursColumns(selectedItems);
            // AutoRowSizeプラグインを有効化
            settings.autoRowSize = { syncLimit: 300, allowSampleDuplicates: true };
        }

        // type: 構造化情報
        if (type === TableType.Struct) {
            structColumns
                .filter((col) => col.data === "primaryType")
                .forEach((col) => (col.source = this.structTypes));
            structColumns
                .filter((col) => col.data === "subType")
                .forEach((col) => (col.source = this.structSubTypes));
            settings.columns = structColumns;
            // AutoRowSizeプラグインを無効化
            settings.autoRowSize = false;
        }

        // type: 競合店
        if (type === TableType.Rivals) {
            settings.columns = rivalColumns;
            // AutoRowSizeプラグインを無効化
            settings.autoRowSize = false;
        }

        // 操作権限が無い場合はreadOnlyにする
        if (!canManage && Array.isArray(settings.columns)) {
            settings.columns.forEach((col) => {
                col.readOnly = true;
            });
        }

        return settings;
    }
    get structTypes(): string[] {
        const mainTypes = [""];
        mainTypes.push(...localBusinessTypeHierarchy.map((lbt) => lbt.name));
        return mainTypes;
    }
    get structSubTypes(): string[] {
        const subTypes = [""];
        const nested = localBusinessTypeHierarchy.map((lbt) => lbt.subcategories);
        subTypes.push(...Array.prototype.concat.apply([], nested).map((lbt) => lbt.name));
        return subTypes;
    }
    get categoryIds(): string[] {
        return Object.values(this.allLocations)
            .map((l) => l.primaryCategory)
            .map((displayName) => this.displayNameToCategoryMap[displayName])
            .filter((c) => c?.name)
            .map((c) => c.name);
    }
    // 本来は動的にメインカテゴリに応じてサブカテゴリを動的に変える
    // private structSubTypes(primaryType): string[] {
    //     return (
    //         localBusinessTypeHierarchy
    //             .find((lbt) => lbt.name == primaryType)
    //             ?.subcategories.map((cat) => cat.name) ?? []
    //     );
    // }
    async fetchAttributeMetadata(): Promise<void> {
        // 属性一覧をまだ取得していないカテゴリIDを作成
        for (const categoryId of this.categoryIds) {
            if (this.attributeMap[categoryId]) {
                continue;
            }
            let ams = await useIndexedDb().getAttributeMetadata(categoryId);
            ams = ams.filter((am) => !am.deprecated); // isDeprecatedのものは除外する
            this.attributeMap[categoryId] = ams;
            // 編集可能な属性をmapで持つようにする
            const acs: AttributeColumn[] = convertMetadatasToAttributeColumns(ams);
            const x: { [attributeId: string]: boolean } = {};
            for (const ac of acs) {
                x[ac.data] = true;
            }
            this.editableAttributes[categoryId] = x;
        }
    }
    async fetchMoreHoursTypes(): Promise<void> {
        // カテゴリIDから営業時間一覧を作成
        for (const categoryId of this.categoryIds) {
            if (this.moreHoursTypesMap[categoryId]) {
                continue;
            }
            // category一覧取得時にはmoreHoursの情報が抜けているので1つずつAPIを叩いて取得
            const category = await requiredAuth<any>(
                "get",
                `${import.meta.env.VITE_APP_API_BASE}v1/categories?${categoryId
                    .replace("categories/", "")
                    .replace(":", "=")}`
            );
            const result = (
                typeof category.data === "string" ? JSON.parse(category.data) : category.data
            ).categories;
            const mhs: MybusinessbusinessinformationMoreHours[] =
                result.length > 0 ? result[0]?.moreHoursTypes : [];
            this.moreHoursTypesMap[categoryId] = mhs;
            // 編集可能な営業時間の詳細をmapで持つようにする
            const emh = enabledMoreHours(mhs);
            const x: { [hoursTypeId: string]: boolean } = {};
            if (emh) {
                for (const ac of emh) {
                    x[ac.hoursTypeId] = true;
                }
            }
            this.editableMoreHoursTypes[categoryId] = x;
        }
    }
    getLocations(
        searchWord: string,
        failedStores: string,
        tableType: TableType,
        selectedAreaIDs: number[],
        onlyDirtyRows: boolean = false,
        dirtyRows: number[] = []
    ): Row[] {
        searchWord = searchWord ?? "";
        failedStores = failedStores ?? "";
        let locations = Object.values(this.allLocations).slice();
        locations.sort((a, b) => a.poiID - b.poiID);
        // タブによって表示する店舗を変える
        locations = this.filterLocationsByTableType(locations, tableType);
        const storeAreas: StoreAreas = this.storeAreas;

        // グループ絞り込み
        if (selectedAreaIDs.length > 0) {
            locations = locations.filter((location): boolean => {
                if (storeAreas[location.poiID] === undefined) {
                    return false;
                }
                for (const areaId of selectedAreaIDs) {
                    if (storeAreas[location.poiID].includes(areaId) === true) {
                        return true;
                    }
                }
            });
        }

        // 指定キーワードと変更行のフィルタ
        // 更に変更行チェックが入ってたら変更行でフィルタ
        if (onlyDirtyRows) {
            locations = locations.filter((l) => dirtyRows.includes(l.poiID));
        }
        // 指定キーワードでフィルタ
        if ((searchWord ?? "") !== "") {
            locations = locations.filter((r) => Object.values(r).toString().includes(searchWord));
        }

        // 情報未入力の項目をフィルタ
        if (failedStores.length > 0) {
            locations = locations.filter((r) => failedStores.includes(r.poiID.toString()));
        }

        // handsontable が setter を設定するので、モデル側でデータを変更するたびにrender が走ってしまう
        // これを防ぐためにコピーして返す
        return JSON.parse(JSON.stringify(locations));
    }
    filterLocationsByTableType(locations: Row[], tableType: TableType): Row[] {
        // 構造化情報ページのタブの場合は構造化情報オプションが有効な店舗のみ表示する
        if (tableType === TableType.Struct) {
            this.storesWithStructOption = this.stores
                .filter((store) => store.options != null)
                .filter((store) => store.options.includes("structuredPage"))
                .map((store) => store.poiID);
            return locations.filter((location) =>
                this.storesWithStructOption.includes(location.poiID)
            );
        }
        return locations;
    }
    getVal(name: string, prop: string): any {
        return dot.pick(prop, this.allLocations[name]);
    }
    setVal(name: string, prop: string, val: any): void {
        dot.set(prop, val, this.allLocations[name]);
    }
    getSourceRow(name: string): FlattenedLocation {
        return this.sources[name];
    }
    getSource(name: string, prop: string): any {
        return dot.pick(prop, this.sources[name]);
    }
    isEditable(name: string, prop: string): boolean {
        const categoryId =
            this.displayNameToCategoryMap[this.allLocations[name]?.primaryCategory]?.name ?? "";
        return this.editableAttributes[categoryId]?.[prop] ?? false;
    }
    isEditableMoreHoursTypes(name: string, prop: string): boolean {
        const categoryId =
            this.displayNameToCategoryMap[this.allLocations[name]?.primaryCategory]?.name ?? "";
        const typeId = parseMoreHoursTypeId(prop.replace("moreHours.", ""));
        return this.editableMoreHoursTypes[categoryId]?.[typeId] ?? false;
    }
    isDirtyRow(name: string): boolean {
        // 行のすべてのセルを走査して更新されている値があるか調べる
        // →内部的にパラメータを空でもいいから持っていたほうが良い
        const row = this.allLocations[name];
        const source = this.getSourceRow(name);
        if (!row || !source) {
            return false;
        }
        for (const key of Object.keys(source)) {
            if (key.startsWith("attributes") || key.startsWith("moreHours")) {
                continue;
            }
            const value = dot.pick(key, row) ?? "";
            const initialValue = source[key] ?? "";
            if (value !== initialValue) {
                return true;
            }
        }
        const category = this.displayNameToCategoryMap[row.primaryCategory];
        if (category?.name) {
            // 属性の比較
            for (const key of Object.keys(this.editableAttributes[category.name])) {
                const value = dot.pick(key, row) ?? "";
                const initialValue = dot.pick(key, source) ?? "";
                if (value !== initialValue) {
                    return true;
                }
            }
            // 特別営業時間の比較
            for (const key of Object.keys(this.editableMoreHoursTypes[category.name])) {
                for (const k of genMoreHoursTypesKey(key)) {
                    const value = dot.pick(k, row) ?? "";
                    const initialValue = dot.pick(k, source) ?? "";
                    if (value !== initialValue) {
                        return true;
                    }
                }
            }
        } else {
            console.error("unknown primaryCategory", row);
        }
        return false;
    }
    getCategoryId(name: string): string {
        return this.displayNameToCategoryMap[this.allLocations[name]?.primaryCategory]?.name ?? "";
    }
    updateModificationMark(
        instance: Handsontable,
        isDirty: boolean,
        cellMeta: GridSettingsPlus,
        rownum: number
    ): void {
        const originalRowNum = cellMeta.row;
        let cellClassName: string = cellMeta.className as string;
        if (isDirty === true) {
            cellClassName += " dirtyrow";
        } else {
            cellClassName = cellClassName.replace(/ dirtyrow/g, "");
        }
        instance.setCellMetaObject(rownum, 0, {
            row: originalRowNum,
            className: cellClassName,
            isDirty,
        });
    }
    pushIfNotInclude(array: string[], key: string): void {
        if (!array.includes(key)) {
            array.push(key);
        }
    }
    // GBP側の不具合でUpdateMaskにaddress.addressLinesが使えないため、addressの一部が更新された場合でもaddress全体を更新する
    addAddressColumns(changedColumns: string[]): void {
        const addressColumns = [
            "regionCode",
            "zipCode",
            "prefecture",
            "address1",
            "address2",
            "address3",
            "address4",
            "address5",
        ];
        if (1 <= changedColumns.filter((x) => addressColumns.includes(x)).length) {
            this.pushIfNotInclude(changedColumns, "regionCode");
            this.pushIfNotInclude(changedColumns, "zipCode");
            this.pushIfNotInclude(changedColumns, "prefecture");
            this.pushIfNotInclude(changedColumns, "address1");
        }
    }
    buildLocationPatch(name: string): Patch {
        const l = this.allLocations[name];
        const source = this.sources[name];
        // 変更のあった項目名を列挙する(attributes配下以外)
        const changedColumns = Object.keys(source)
            .filter((key) => !key.startsWith("attributes."))
            .filter((key) => {
                return (l[key] ?? "") !== source[key];
            });

        // FIXME: 仮対応 addressの一部が更新された場合は、address全体を更新する
        this.addAddressColumns(changedColumns);

        // 単純に更新すればよいものを処理する
        const simple = [
            "prefecture",
            "regionCode",
            "zipCode",
            "primaryPhone",
            "webSite",
            "adPhones",
        ];
        const result: GmbLocationInfo = {};
        // patchLocation時にnameをkeyにupdateするので必ず持たせる
        objectPath.set(result, "location.name", l.name);
        const updateItems: string[] = [];
        for (const key of changedColumns) {
            if (simple.includes(key)) {
                const value = l[key];
                const diffMask = diffMasks[key];
                objectPath.set(result, `location.${diffMask}`, value);
                updateItems.push(diffMask);
            }
        }

        // 文字数制限系の処理
        Object.keys(characterLimits).forEach((key: string) => {
            if (changedColumns.includes(key)) {
                const characterLimit = characterLimits[key];
                if (characterLimit.gbpCheck) {
                    const diffMask = diffMasks[key];
                    objectPath.set(result, `location.${diffMask}`, l[key]);
                    if (l[key].length > characterLimit.limit) {
                        throw Error(
                            `<b>${l.poiID}</b> ${l.title}<br>${characterLimit.label}は${characterLimit.limit}文字以内で入力してください。`
                        );
                    }
                    updateItems.push(diffMask);
                }
            }
        });

        // address1-5を処理する
        const addresses = ["address1", "address2", "address3", "address4", "address5"];
        if (1 <= changedColumns.filter((x) => addresses?.includes(x))?.length) {
            const addressLines = addresses.map((a) => l[a] ?? "").filter((v) => v !== "");
            objectPath.set(result, "location.storefrontAddress.addressLines", addressLines);
            updateItems.push("storefrontAddress.addressLines");
        }
        // latlngを処理する
        if (changedColumns.includes("latitude") && changedColumns.includes("longitude")) {
            if (l.latitude !== "" && l.longitude !== "") {
                result.location.latlng = { latitude: l.latitude, longitude: l.longitude };
            }
            updateItems.push("latlng");
        }
        // PhoneNumbersを処理する
        // primaryPhoneとadditionalPhonesは個別更新はできないため、片方だけ変更があった場合でもPhoneNumbersの中身すべてをリクエストに含める
        if (
            changedColumns.includes("primaryPhone") ||
            changedColumns.includes("additionalPhones")
        ) {
            objectPath.set(result, "location.phoneNumbers.primaryPhone", l.primaryPhone);
            objectPath.set(
                result,
                "location.phoneNumbers.additionalPhones",
                l.additionalPhones
                    .replace(/[\n,]/g, "|")
                    .split("|")
                    .filter((s) => s !== "")
            );
            updateItems.push("phoneNumbers");
        }
        // additionalCategoriesを処理する
        if (
            changedColumns.includes("primaryCategory") ||
            changedColumns.includes("additionalCategories")
        ) {
            const displayName = l.primaryCategory;
            const primaryCategory = this.displayNameToCategoryMap[displayName];
            if (!primaryCategory) {
                throw Error(`メインカテゴリが正しくありません<br/>・${l.title}`);
            }
            objectPath.set(result, "location.categories.primaryCategory", primaryCategory);
            const cats = l.additionalCategories
                .replace(/[\n,]/g, "|")
                .split("|")
                .filter((s) => s !== "");
            const additionalCategories = [];
            for (const cat of cats) {
                const category = this.displayNameToCategoryMap[cat];
                if (!category) {
                    throw Error(`追加カテゴリが正しくありません<br/>・${l.title}`);
                }
                additionalCategories.push({ ...category });
            }
            objectPath.set(
                result,
                "location.categories.additionalCategories",
                additionalCategories
            );
            updateItems.push("categories");
        }
        // businessHoursを処理する
        const businessHoursLabels = [
            ["businessHoursSun", "SUNDAY"],
            ["businessHoursMon", "MONDAY"],
            ["businessHoursTue", "TUESDAY"],
            ["businessHoursWed", "WEDNESDAY"],
            ["businessHoursThu", "THURSDAY"],
            ["businessHoursFri", "FRIDAY"],
            ["businessHoursSat", "SATURDAY"],
        ];

        let isInvalid = false;
        const businessHours = businessHoursLabels.map((b) => b[0]);
        if (1 <= changedColumns.filter((x) => businessHours?.includes(x))?.length) {
            let periods = [];
            for (const labels of businessHoursLabels) {
                const regularHourPeriod: RegularHourPeriod = toHour(l[labels[0]], labels[1]);
                if (regularHourPeriod.isInvalid === true) {
                    isInvalid = true;
                    break;
                } else {
                    periods = periods.concat(regularHourPeriod.periods);
                }
            }
            objectPath.set(result, "location.regularHours.periods", periods);
            if (isInvalid === true) {
                updateItems.push("[Error]regularHours.periods");
            } else {
                updateItems.push("regularHours");
            }
        }
        // businessHoursSpecialを処理する
        if (changedColumns.includes("businessHoursSpecial")) {
            const specialHourPeriod: SpecialHourPeriod = toSpecialHour(l.businessHoursSpecial);
            const periods: GmbLocationInfo["location"]["specialHours"]["specialHourPeriods"] =
                specialHourPeriod.periods;
            objectPath.set(result, "location.specialHours.specialHourPeriods", periods);
            if (specialHourPeriod.isInvalid !== "") {
                updateItems.push("[Error]specialHourPeriods");
            } else {
                updateItems.push("specialHours.specialHourPeriods");
            }
        }

        // openingDateを処理する
        if (changedColumns.includes("openingDate")) {
            objectPath.set(result, "location.openInfo.openingDate", toOpeningDate(l.openingDate));
            updateItems.push("openInfo.openingDate");
        }
        // openStatusを処理する
        if (changedColumns.includes("openStatus")) {
            objectPath.set(
                result,
                "location.openInfo.status",
                this.openStatusMap.get(l.openStatus)
            );
            updateItems.push("openInfo.status");
        }
        // labelsを処理する
        if (changedColumns.includes("labels")) {
            result.location.labels = l.labels.replace(/[\n,]/g, "|").split("|");
            updateItems.push("labels");
        }

        // カテゴリーが取得できていなければmoreHoursとattributes配下の処理は行わない
        const category = this.displayNameToCategoryMap[l.primaryCategory];
        if (!category) {
            return {
                gmbLocation: result,
                oldGmbLocation: this.rawSources[l.name],
                updateItems,
                updateAttributes: [],
                localBusinessInfo: null,
                rivals: null,
                isRivalsUpdated: false,
            };
        }

        // moreHoursを処理する
        const moreHours: MybusinessbusinessinformationMoreHours[] = [];
        const mh_list = this?.moreHoursTypesMap[category?.name];
        isInvalid = false;
        let isChanged = false;
        moreHoursList: for (const mh of mh_list) {
            let periods = [];
            for (const w of week) {
                const key = `moreHours.${mh.hoursTypeId}${w.key}`;
                const after = dot.pick(key, l) ?? "";
                const before = dot.pick(key, source) ?? "";
                // 他に設定されている営業時間の詳細もリクエストに含めないと消えてしまうのでフラグ管理する
                if (after !== before) {
                    isChanged = true;
                }
                const moreHoursPeriod: RegularHourPeriod = toHour(after, w.name);
                if (moreHoursPeriod.isInvalid === true) {
                    isInvalid = true;
                    // 不正な時点で処理終了(更新対象に追加しない)
                    updateItems.push(`[Error]moreHours.${mh.hoursTypeId}`);
                    continue moreHoursList;
                } else {
                    periods = periods.concat(moreHoursPeriod.periods);
                    isInvalid = false;
                }
            }
            if (periods.length > 0) {
                moreHours.push({ hoursTypeId: mh.hoursTypeId, periods: periods });
            }
        }
        objectPath.set(result, "location.moreHours", moreHours);
        if (isInvalid === false && isChanged === true) {
            updateItems.push("moreHours");
        }

        // 変更のあった項目名を列挙する(attributes配下)
        const attributeMasks: string[] = [];
        const ams = this?.attributeMap[category?.name];
        const attributes: Attribute[] = [];
        if (ams !== undefined) {
            for (const am of ams) {
                if (am.valueType === "REPEATED_ENUM") {
                    let isChanged: boolean = false;
                    const values: RepeatedEnumAttributeValue = {
                        setValues: [],
                        unsetValues: [],
                    };
                    const acs = convertMetadataToAttributeColumns(am);
                    for (const ac of acs) {
                        const after = dot.pick(ac.data, l) ?? "";
                        const before = dot.pick(ac.data, source) ?? "";
                        if (after === "o") {
                            values.setValues.push(ac.enumValue);
                        } else if (after === "x") {
                            values.unsetValues.push(ac.enumValue);
                        }
                        if (after !== before) {
                            isChanged = true;
                        }
                    }
                    if (isChanged) {
                        attributeMasks.push(am.parent);
                        // setValuesとunsetValuesが両方とも空でなければ追加する
                        // (追加したら{code: 3, message: "Attribute must have a value"} というエラーになる)
                        if (0 < values.setValues?.length || 0 < values.unsetValues?.length) {
                            attributes.push({
                                name: am.parent,
                                valueType: am.valueType,
                                repeatedEnumValue: values,
                            });
                        }
                    }
                    continue;
                }
                // REPEATED_ENUM 以外
                const key = `attributes.${am.parent}`;
                const after = dot.pick(key, l) ?? "";
                const before = dot.pick(key, source) ?? "";
                // 変更がなければ次へ行く
                if (after === before) {
                    continue;
                }
                attributeMasks.push(am.parent);
                // 更新後が空ならばattributeMaskだけに追加してattributesには追加しない
                if (after === "") {
                    continue;
                }
                // タイプごとに処理を行う
                if (am.valueType === "BOOL") {
                    attributes.push({
                        name: am.parent,
                        valueType: am.valueType,
                        values: after === "o" ? [true] : [false],
                    });
                } else if (am.valueType === "ENUM") {
                    attributes.push({
                        name: am.parent,
                        valueType: am.valueType,
                        values: [after],
                    });
                } else if (am.valueType === "URL") {
                    // URLは改行で区切って UrlAttributeValue[] の形式にする
                    // パイプ|で区切ってたら改行区切りに置き換えてやる
                    const uriValues: Attribute["uriValues"] = after
                        .replace(/\|/g, "\n")
                        .split("\n")
                        .filter((uri) => !!uri)
                        .map((uri) => {
                            return { uri };
                        });
                    attributes.push({
                        name: am.parent,
                        valueType: am.valueType,
                        uriValues,
                    });
                }
            }
        }
        if (attributeMasks?.length !== 0) {
            result.attributes = attributes;
        }

        // 店舗ページ構造化用情報（現状、updateMaskはない）
        const structCols = [
            "paymentAccepted",
            "currenciesAccepted",
            "priceRange",
            "areaServed",
            "maximumAttendeeCapacity",
            "smokingAllowed",
            "brandName",
            "brandLogo",
            "brandSlogan",
            "department",
            "primaryType",
            "subType",
            "structuredPageID",
        ];
        for (const key of changedColumns) {
            if (structCols.includes(key)) {
                updateItems.push(key);
            }
        }
        result.localBusinessInfo = { brand: {}, department: [] };
        result.localBusinessInfo.paymentAccepted = l?.paymentAccepted ?? "";
        result.localBusinessInfo.currenciesAccepted = l.currenciesAccepted ?? "";
        result.localBusinessInfo.priceRange = l.priceRange ?? "";
        result.localBusinessInfo.areaServed = l.areaServed ?? "";
        result.localBusinessInfo.maximumAttendeeCapacity =
            parseInt(l?.maximumAttendeeCapacity.toString(), 10) ?? null;
        result.localBusinessInfo.smokingAllowed = l?.smokingAllowed === "o";
        result.localBusinessInfo.brand.name = l?.brandName ?? "";
        result.localBusinessInfo.brand.logo = l?.brandLogo ?? "";
        result.localBusinessInfo.brand.slogan = l?.brandSlogan ?? "";
        result.localBusinessInfo.department = getDepartment(l?.department ?? "");
        result.localBusinessInfo.type =
            l.subType !== "" ? l.subType : l.primaryType !== "" ? l.primaryType : "LocalBusiness";

        // 競合店舗情報
        const rivalCols = ["rivalName", "rivalKeyword"];
        var isRivalsUpdated = false;
        for (const key of changedColumns) {
            if (rivalCols.includes(key)) {
                updateItems.push(key);
                isRivalsUpdated = true;
            }
        }

        const rivals: EntitiesRival[] = [];
        if (isRivalsUpdated) {
            // 名称 + キーワード が入力されていたら更新対象とする
            if (l.rivalName !== "" && l.rivalKeyword !== "") {
                const rival: EntitiesRival = {};
                rival.name = l.rivalName;
                rival.keyword = l.rivalKeyword;
                rival.rivalID = 0;
                rival.latestReviewCollectedAt = null;
                rivals.push(rival);
            }
        }
        return {
            gmbLocation: result,
            oldGmbLocation: this.rawSources[l.name],
            updateItems,
            updateAttributes: attributeMasks,
            localBusinessInfo: result.localBusinessInfo,
            rivals,
            isRivalsUpdated,
        };
    }
    verifyInfo(l: Row): string[] {
        const errorDetails: string[] = [];

        if (!l.storeCode) {
            errorDetails.push(
                "店舗コードは必須です。他店舗と重複しない番号・名前・コードなどを設定してください。"
            );
        }
        if (!l.title) {
            errorDetails.push("ビジネス名は必須です。");
        }
        if (!l.address1 && !l.address2 && !l.address3 && !l.address4 && !l.address5) {
            errorDetails.push("住所は必須です。");
        }
        if (!l.prefecture) {
            errorDetails.push("都道府県は必須です。");
        }
        if (!l.zipCode) {
            errorDetails.push("郵便番号は必須です。");
        }
        if (!l.primaryCategory) {
            errorDetails.push("メインカテゴリは必須です。");
        }
        if (this.openStatusMap.get(l.openStatus) === undefined) {
            errorDetails.push(`開業/閉業として選択可能なのは${openStatuses}です。`);
        }
        if (!l.structuredPageID) {
            errorDetails.push(
                "構造化ページIDは必須です。他店舗と重複しない英数字と'-'を設定してください。"
            );
        }
        return errorDetails;
    }
    verifyAttr(l: Row): string[] {
        const errorDetails: string[] = [];
        const category = this.displayNameToCategoryMap[l.primaryCategory];
        if (!category) {
            errorDetails.push("まず店舗情報タブでメインカテゴリを設定してください。");
            return errorDetails;
        }

        const ams = this.attributeMap[category?.name];
        // これ以降はattributesのチェック
        for (const am of ams) {
            if (am.valueType === "REPEATED_ENUM") {
                for (const ac of convertMetadataToAttributeColumns(am)) {
                    const value = dot.pick(ac.data, l) ?? "";
                    if (["", "o", "x"].includes(value) === false) {
                        errorDetails.push(`${am.displayName}が正しくありません`);
                    }
                }
            }
            const ac = convertMetadataToAttributeColumns(am)[0];
            const value = dot.pick(ac.data, l) ?? "";
            if (value === "") {
                continue;
            }
            if (
                (am.valueType === "BOOL" && ["o", "x"].includes(value) === false) ||
                (am.valueType === "ENUM" &&
                    am.valueMetadata?.map((m) => m.value).includes(value) === false)
            ) {
                errorDetails.push(`${am.displayName}が正しくありません`);
            }
            if (am.valueType === "URL") {
                const regex = am.repeatable ? REGEX_MULTI_URL_OR_BLANK : REGEX_URL_OR_BLANK;
                // URLをパイプ|で区切ってた場合改行区切り扱いで判定する
                const replacedUrl = value.replace(/\|/g, "\n");
                if (regex.test(replacedUrl) === false) {
                    errorDetails.push(`${am.displayName}が正しくありません`);
                }
            }
        }
        return errorDetails;
    }
    verifyHours(l: Row): string[] {
        const errorDetails: string[] = [];
        const errMsg =
            "書式に誤りがあります。<br/>店舗の営業時間は 10:00-20:00 のように開始時刻と終了時刻を<br/> - (ハイフン)でつないで入力してください。<br/>24時間営業の場合は 00:00-24:00 と入力してください。";
        // 通常営業時間エラー詳細出すためにここでもtoHourやる
        let isInvalidBusinessHour = false;
        for (const w of week) {
            const regularHourPeriod: RegularHourPeriod = toHour(l[`businessHours${w.key}`], w.name);
            if (regularHourPeriod.isInvalid === true) {
                isInvalidBusinessHour = true;
                break;
            }
        }
        if (isInvalidBusinessHour === true) {
            errorDetails.push(`営業時間の${errMsg}`);
        }
        // 特別営業時間エラー詳細出す為にここでもtoSpecialHourやる
        const bhs = toSpecialHour(l.businessHoursSpecial).isInvalid;
        if (bhs !== "") {
            errorDetails.push(bhs);
        }
        // 営業時間の詳細のエラー
        const category = this.displayNameToCategoryMap[l.primaryCategory];
        const mhs = this.moreHoursTypesMap[category?.name];
        for (const mh of mhs) {
            for (const w of week) {
                // 設定が無い場合はundefinedになるのでスキップする
                if (l.moreHours[`${mh.hoursTypeId}${w.key}`] == null) {
                    continue;
                }
                const regularHourPeriod: RegularHourPeriod = toHour(
                    l.moreHours[`${mh.hoursTypeId}${w.key}`],
                    w.name
                );
                if (regularHourPeriod.isInvalid === true) {
                    errorDetails.push(`${mh.localizedDisplayName}の${errMsg}`);
                    break;
                }
            }
        }
        return errorDetails;
    }
    verifyStruct(l: Row): string[] {
        const errorDetails: string[] = [];
        // 構造化用情報
        return errorDetails;
    }
    verifyRivals(l: Row): string[] {
        const errorDetails: string[] = [];

        // 競合店情報の全消しはOKとする
        const isClear = l.rivalKeyword === "" && l.rivalName === "";
        if (!isClear) {
            // 競合店名のみor競合店キーワードだけが入力されていたらエラーを出す
            if (l.rivalKeyword !== "" && l.rivalName === "") {
                errorDetails.push(
                    "キーワード '" + l.rivalKeyword + "' を設定する競合店名を入力してください。"
                );
            }
            if (l.rivalKeyword === "" && l.rivalName !== "") {
                errorDetails.push(
                    "競合店を設定する場合、競合店名と競合店キーワードを設定してください。"
                );
            }
        }

        return errorDetails;
    }
    verifyCustom(l: Row): string[] {
        const errorDetails: string[] = [];
        return errorDetails;
    }
    verifyPlaceActionLink(l: Row): string[] {
        const errorDetails: string[] = [];
        return errorDetails;
    }

    verify(name: string, tableType: TableType): string[] {
        const l = this.allLocations[name];

        switch (tableType) {
            case TableType.Info:
                return this.verifyInfo(l);
            case TableType.Attr:
                return this.verifyAttr(l);
            case TableType.Hours:
                return this.verifyHours(l);
            case TableType.Struct:
                return this.verifyStruct(l);
            case TableType.Rivals:
                return this.verifyRivals(l);
            case TableType.Custom:
                return this.verifyCustom(l);
            case TableType.PlaceActionLinks:
                return this.verifyPlaceActionLink(l);
        }
    }
    /** 新しいflで上書きする */
    updateCurrent(fl: FlattenedLocation): void {
        updateFlattenedLocation(this.allLocations[fl.name], fl);
    }
    /** 新しいflで上書きする */
    updateSource(fl: FlattenedLocation): void {
        updateFlattenedLocation(this.sources[fl.name], fl);
    }
    updateStructuredPageID(poiID: number, fl: FlattenedLocation, structuredPageID: string): void {
        const store = this.stores.find((store) => store.poiID === poiID);
        if (store) {
            store.structuredPageID = structuredPageID;
            this.sources[fl.name]["structuredPageID"] = structuredPageID;
        }
    }

    /** 更新できない属性を空欄にする */
    async deleteUneditableAttribute(name: string): Promise<void> {
        const row = this.allLocations[name];
        await this.fetchAttributeMetadata();
        const category = this.displayNameToCategoryMap[row.primaryCategory];
        if (!category) {
            return;
        }
        const editableAttribute = this.editableAttributes[category?.name];
        for (const key of Object.keys(row.attributes)) {
            if (!editableAttribute[`attributes.${key}`]) {
                delete row.attributes[key];
            }
        }
    }
    /** 更新できない営業時間の詳細を空欄にする */
    async deleteUneditableMoreHoursTypes(name: string): Promise<void> {
        const row = this.allLocations[name];
        await this.fetchMoreHoursTypes();
        const category = this.displayNameToCategoryMap[row.primaryCategory];
        if (!category) {
            return;
        }
        const editableMoreHoursTypes = this.editableMoreHoursTypes[category?.name];
        for (const key of Object.keys(row.moreHours)) {
            if (!editableMoreHoursTypes[parseMoreHoursTypeId(key)]) {
                delete row.moreHours[key];
            }
        }
    }
    /** 店舗のdashboardURLを変換して渡す */
    getDashboardURL(poiID: number): string {
        const targetStore: EntitiesStore = this.stores.find((store) => store.poiID === poiID);
        return getDashboardUrl(targetStore.gmbLocationID ?? "");
    }
}
export interface Patch {
    gmbLocation: EntitiesGMBLocationInfo;
    oldGmbLocation: EntitiesGMBLocationInfo;
    localBusinessInfo: EntitiesLocalBusinessInfo;
    updateItems: string[];
    updateAttributes: string[];
    rivals: EntitiesRival[];
    isRivalsUpdated: boolean;
}

function toOpeningDate(str: string): GmbLocationInfo["location"]["openInfo"]["openingDate"] {
    str = str.trim() || "";
    if (str === "") {
        return null;
    }
    const d = str.split("-");
    if (d.length === 3) {
        return { year: parseInt(d[0], 10), month: parseInt(d[1], 10), day: parseInt(d[2], 10) };
    } else if (d.length === 2) {
        return { year: parseInt(d[0], 10), month: parseInt(d[1], 10), day: 0 };
    }
    return null;
}
/** 新しいflで上書きする */
function updateFlattenedLocation(row: FlattenedLocation, fl: FlattenedLocation) {
    // 属性以外を上書き
    for (const key of Object.keys(fl)) {
        if (!["attributes", "moreHours"].includes(key)) {
            row[key] = fl[key];
        }
    }
    // 属性を上書き
    if (fl.attributes) {
        row.attributes = Object.assign({}, fl.attributes);
    }
    if (fl.moreHours) {
        row.moreHours = Object.assign({}, fl.moreHours);
    }
}

function updateModificationMark(
    instance: Handsontable,
    isDirty: boolean,
    cellMeta: GridSettingsPlus,
    rownum: number
): void {
    const originalRowNum = cellMeta.row;
    let cellClassName: string = cellMeta.className as string;
    if (isDirty === true) {
        cellClassName += " dirtyrow";
    } else {
        cellClassName = cellClassName.replace(/ dirtyrow/g, "");
    }
    instance.setCellMetaObject(rownum, 0, {
        row: originalRowNum,
        className: cellClassName,
        isDirty,
    });
}

const dayOfWeek = wordDictionary.storeLocationForm.weekdays;
const dayOfWeekDict = Object.keys(dayOfWeek).reduceRight(function (ret, k) {
    return (ret[dayOfWeek[k]] = k), ret;
}, {});

// 00:00〜24:00の時刻、あるいは空
function isTimeOrEmpty(str) {
    return /^$|^(?:[01]?[0-9]|2[0-3]):(?:[0-5][0-9])$|^24:00$/.test(str);
}

function getDepartment(departmentString): EntitiesDepartment[] {
    const departmentList = [];
    departmentString.split("\n").forEach((row) => {
        const cols = row.split("|");
        if (cols.length === 3) {
            const name: string = cols[0];
            const opens: string = cols[1].split("-")[0].trim() ?? "";
            const closes: string = cols[1].split("-")[1].trim() ?? "";
            if (!isTimeOrEmpty(opens)) {
                throw new Error(
                    `場所ごとの営業時間に誤りがあります。開店時刻「${opens}」は正しい時刻ではありません。`
                );
            }
            if (!isTimeOrEmpty(closes)) {
                throw new Error(
                    `場所ごとの営業時間に誤りがあります。閉店時刻「${closes}」は正しい時刻ではありません。`
                );
            }
            const dayOfWeek: string[] = cols[2]
                .split(",")
                .map((day) => dayOfWeekDict[day])
                .filter((v) => v)
                .filter((elem, index, self) => self.indexOf(elem) === index);
            const spec: EntitiesOpeningHoursSpecification = {
                opens: opens,
                closes: closes,
                dayOfWeek: dayOfWeek,
            };
            const department: EntitiesDepartment = {
                name: name,
                openingHoursSpecification: [spec],
            };
            departmentList.push(department);
        } else {
            if (row !== "") {
                throw new Error(
                    `場所ごとの営業時間に誤りがあります。「${row}」は正しい書式ではありません。「場所名|00:00-24:00|月,火,水」のように指定してください。`
                );
            }
        }
    });

    return departmentList;
}
