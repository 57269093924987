import * as am4charts from "@amcharts/amcharts4/charts";

export interface Rating {
    color?: string;
    star: number;
    count: number;
}
export type StarData = {
    average?: number;
    ratings?: Rating[];
    month?: string;
    displayedMonth?: string;
};

export type GraphData = {
    month?: string;
    displayedMonth?: string;
    marks?: number;
    total?: number;
    average: number;
    star1: number;
    star2: number;
    star3: number;
    star4: number;
    star5: number;
    ratio1?: number;
    ratio2?: number;
    ratio3?: number;
    ratio4?: number;
    ratio5?: number;
};

export type ErrorDialogMessage = {
    title: string;
    detail: string;
    buttonType: "reload" | "login" | "none";
};

export const starColors: string[] = ["#f27573", "#f7a8a7", "#ecedf0", "#a4e9de", "#67cbb6"];
export function createStarLegends(
    chart: am4charts.PieChart | am4charts.XYChart,
    markerDisabled: boolean = false
): void {
    chart.legend = new am4charts.Legend();
    const legend: am4charts.Legend = chart.legend;
    legend.position = "bottom";
    legend.fontSize = 10;
    legend.maxWidth = 212;
    legend.valueLabels.template.width = 0;
    legend.valueLabels.template.align = "left";
    legend.valueLabels.template.textAlign = "start";
    legend.itemContainers.template.marginLeft = 0;
    legend.itemContainers.template.marginRight = 0;
    legend.itemContainers.template.paddingLeft = 0;
    legend.itemContainers.template.paddingRight = 0;
    const marker = legend.markers.template;
    marker.disabled = markerDisabled;
    marker.width = 10;
    marker.height = 10;
}
