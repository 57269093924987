<template>
  <div>
    <div v-if="isLoading" class="progress-circular-container">
      <v-progress-circular
        :size="80"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card elevation="1" class="mb-1">
      <v-card-text>[メニュー編集] {{ title }}</v-card-text>
    </v-card>
    <div class="d-flex flex-wrap mb-1">
      <v-btn text="XLSXエクスポート" color="primary" @click="exportXlsx()" />
      <input ref="importInput" type="file" hidden @change="importXlsx" />
      <v-btn
        v-if="canManage"
        class="ml-2 mr-auto"
        text="XLSXインポート"
        data-testid="menu-import-xlsx"
        @click="($refs.importInput as HTMLInputElement).click()"
      />
      <v-spacer />
      <v-btn
        :to="{ name: 'FoodMenuGroupList' }"
        params="{poiGroupId}"
        class="bg-grey-lighten-1 mx-1"
      >
        <template #prepend>
          <v-icon>fa-regular fa-arrow-alt-circle-left</v-icon>
        </template>
        {{ canManage ? "保存せず一覧に戻る" : "一覧に戻る" }}
      </v-btn>
      <v-btn
        v-if="canManage"
        variant="outlined"
        class="mx-1"
        data-testid="menu-clear-button"
        @click="refresh()"
      >
        変更内容を破棄
      </v-btn>
      <v-btn
        v-if="canManage"
        color="primary"
        class="mx-1"
        data-testid="menu-save-button"
        @click="save(false)"
      >
        保存して一覧に戻る
      </v-btn>
    </div>
    <v-tabs
      v-model="tab"
      color="primary"
      class="bg-white"
      slider-color="primary"
      @update:model-value="changeTab"
    >
      <v-tab :value="0">メニュー設定</v-tab>
      <v-tab :value="1">可変項目設定</v-tab>
    </v-tabs>
    <v-window v-model="tab" style="height: calc(100vh - 240px)">
      <!-- メニュー設定タブ -->
      <v-window-item :value="0" :transition="false" :reverse-transition="false">
        <div class="custom-grid">
          <v-btn
            v-if="model.items.length === 0"
            depressed
            color="primary"
            @click="add($event, true)"
          >
            最初のセクションを追加
          </v-btn>
          <c-grid
            ref="grid0"
            :data="model.items"
            :font="gridFont"
            :theme="customTheme"
            style="font-size: small"
            class="text-sm-left cgrid pb-12"
            @resize="($refs.grid0 as ListGrid<FoodMenuRow>).updateSize()"
          >
            <c-grid-button-column
              v-if="canManage"
              caption="↑"
              width="32"
              :disabled="isSwapDisabled(true)"
              :column-style="swapStyle(true)"
              @click="swap($event, true)"
            />
            <c-grid-button-column
              v-if="canManage"
              caption="↓"
              width="32"
              :disabled="isSwapDisabled(false)"
              :column-style="swapStyle(false)"
              @click="swap($event, false)"
            />
            <c-grid-button-column
              v-if="canManage"
              caption="+"
              width="32"
              :header-style="{ bgColor: '#f7ce93' }"
              :column-style="{ color: 'black', buttonBgColor: '#ddd', bgColor: '#f7ce93' }"
              @click="add($event, true)"
            />
            <c-grid-input-column
              caption="セクション名"
              field="sectionName"
              width="240"
              input-type="string"
              :readonly="(r) => !isSection(r) || !canManage"
              :header-style="{ bgColor: '#f7ce93' }"
              :column-style="bgStyle(true)"
              :helper-text="(s) => `${s.length}/140`"
              :input-validator="model.getValidator('sectionName')"
              :message="model.getValidatorMessage('sectionName')"
            />
            <c-grid-button-column
              v-if="canManage"
              caption="+"
              width="32"
              :header-style="{ bgColor: '#fef5e6' }"
              :column-style="{ color: 'black', buttonBgColor: '#ddd', bgColor: '#fef5e6' }"
              @click="add($event, false)"
            />
            <c-grid-input-column
              caption="アイテム名"
              field="displayName"
              width="240"
              input-type="string"
              :readonly="(r) => isSection(r) || !canManage"
              :header-style="{ bgColor: '#fef5e6' }"
              :helper-text="(s) => `${s.length}/140`"
              :input-validator="model.getValidator('displayName')"
              :message="model.getValidatorMessage('displayName')"
            />
            <c-grid-input-column
              caption="アイテムの説明"
              field="description"
              width="240"
              input-type="string"
              :readonly="(r) => isSection(r) || !canManage"
              :helper-text="(s) => `${s.length}/1000`"
              :input-validator="model.getValidator('description')"
              :message="model.getValidatorMessage('description')"
            />
            <c-grid-input-column
              caption="アイテムの価格"
              width="100"
              field="price"
              input-type="number"
              :readonly="(r) => isSection(r) || !canManage"
            />
            <c-grid-check-column
              caption="可変"
              field="isVariable"
              :disabled="isSection"
              :readonly="(r) => isSection(r) || !canManage"
            />
            <c-grid-button-column
              v-if="canManage"
              caption="削除"
              width="48"
              :column-style="{ color: 'black', buttonBgColor: '#ddd' }"
              @click="remove($event, true)"
            />
          </c-grid>
        </div>
      </v-window-item>
      <!-- 可変項目設定タブ -->
      <v-window-item :value="1" :transition="false" :reverse-transition="false">
        <div class="custom-grid">
          <c-grid
            ref="grid1"
            :data="model.poiPrices"
            :font="gridFont"
            :theme="customTheme"
            style="font-size: small"
            class="text-sm-left cgrid"
          >
            <c-grid-column caption="店舗ID" field="poiId" width="80" />
            <c-grid-column caption="店舗コード" field="code" width="80" />
            <c-grid-column caption="店舗名" field="name" width="240" />
            <c-grid-column-group
              v-for="sh in model.sectionHeaders"
              :key="sh.itemId"
              :caption="sh.sectionName"
            >
              <c-grid-input-column
                v-for="ih in sh.itemHeaders"
                :key="ih.itemId"
                :caption="ih.itemName"
                :field="`${ih.itemId}`"
                input-type="string"
                :helper-text="(s) => `'x':アイテムを削除、'-':料金を空にする、'数値':料金を上書き`"
                :input-validator="model.getPriceValidator"
                :message="model.getPriceValidatorMessage(ih.itemId)"
                :readonly="!canManage"
              />
            </c-grid-column-group>
          </c-grid>
        </div>
      </v-window-item>
    </v-window>

    <!-- 削除ダイアログ -->
    <v-dialog v-model="removeDialog.show" max-width="290">
      <v-card>
        <v-card-title class="text-h5"></v-card-title>
        <v-card-text>可変項目設定タブで料金が設定されています。削除しますか？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="removeDialog.show = false">キャンセル</v-btn>
          <v-btn
            color="primary"
            @click="
              remove(removeDialog.row, false);
              removeDialog.show = false;
            "
          >
            削除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- XLSXインポートダイアログ -->
    <v-dialog v-model="importDialog.show" width="600" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>XLSXインポート</v-card-title>
        <v-card-text>
          読み込んだファイルにエラーがあります
          <v-list>
            <v-list-item v-for="(item, index) in importDialog.errors" :key="index">
              <v-list-item-title>
                {{ item.i + 1 }} 行 {{ item.j + 1 }}列 {{ item.error }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="
              importDialog.errors = [];
              importDialog.show = false;
            "
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 保存確認ダイアログ -->
    <v-dialog v-model="saveDialog.show" max-width="600">
      <confirm-dialog
        message="反映予約が取り消されますがよろしいですか？"
        cancel-button="キャンセル"
        submit-button="保存する"
        @cancel="saveDialog.show = false"
        @submit="save(true)"
      />
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import type { ListGrid } from "cheetah-grid";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import wordDictionary from "@/word-dictionary";
import type {
  EntitiesFoodMenuGroup,
  EntitiesFoodMenuStore,
  EntitiesStore,
  MybusinessbusinessinformationMetadata,
} from "@/types/ls-api";
import type { ExportData, FoodMenuRow } from "@/components/root/contents/food-menu/food-menu-model";
import { FoodMenuModel } from "@/components/root/contents/food-menu/food-menu-model";
import { api } from "@/helpers/api/food-menu";
import ConfirmDialog from "./confirm-dialog.vue";
import { trimLocationName } from "@/helpers/gmb";
import { arrayBufferToStringsArrays, read } from "@/helpers/xlsxtools";
import type { SnackbarToast } from "@/components/shared/snackbar/snackbar-shared";
import { useSnackbar } from "@/storepinia/snackbar";
import { useIndexedDb } from "@/storepinia/idxdb";
import { getOperationLogParams } from "@/routes/operation-log";
import { TOAST_CRITICAL_DURATION } from "@/const";
import { currentTheme } from "@/components/shared/theme";

// コンポーネント定義
export default defineComponent({
  components: { ConfirmDialog },
  data: () => {
    const model: FoodMenuModel = new FoodMenuModel({ menus: [], variationRows: [] }, [], [], "JPY");
    return {
      fmg: null as EntitiesFoodMenuGroup,
      title: "",
      tab: 0,
      model,
      gridFont: `12.8px sans-serif`,
      isLoading: false,
      removeDialog: { show: false, row: null as FoodMenuRow },
      importDialog: { show: false, errors: [] },
      saveDialog: { show: false },
      customTheme: {
        checkbox: {
          borderColor: currentTheme().vuetifyTheme.colors.primary,
          uncheckBgColor: "#fff",
          checkBgColor: currentTheme().vuetifyTheme.colors.primary,
        },
      },
    };
  },
  computed: {
    poiGroupId: function () {
      return parseInt(this.$route.params.poiGroupId as string, 10);
    },
    foodMenuGroupId: function () {
      return parseInt(this.$route.params.foodMenuGroupId as string, 10);
    },
    canManage(): boolean {
      return useIndexedDb().canManageMenu;
    },
  },
  async created() {
    await this.getData();
    (this.$refs.grid0 as ListGrid<unknown>).invalidate();
  },
  methods: {
    addSnackbar: function (message: SnackbarToast): void {
      useSnackbar().addSnackbarMessages(message);
    },
    async changeTab(tab: unknown) {
      const mode = tab === 0 ? "menus" : "variables";
      const grid = tab === 0 ? "grid0" : "grid1";
      this.model.setMode(mode);
      await this.$nextTick();
      (this.$refs[grid] as ListGrid<unknown>)?.updateSize();
      (this.$refs[grid] as ListGrid<unknown>)?.invalidate();
    },
    /** データを読み込む */
    async getData() {
      this.isLoading = true;
      try {
        const fmg = await api.getFoodMenuGroup(this.poiGroupId, this.foodMenuGroupId);
        this.fmg = fmg;
        this.title = fmg.title;
        const fmss = await api.getFoodMenuStores(this.poiGroupId, this.foodMenuGroupId);
        const mfms: { [poiId: number]: EntitiesFoodMenuStore } = {};
        fmss.forEach((s) => (mfms[s.poiID] = s));
        // locations.metadata を取得して vuex に保存している EntitiesStore[] を canHaveFoodMenus == true のものだけに限定して用いる
        const idxdb = useIndexedDb();
        const locationNames = idxdb.stores.stores
          .filter((s) => !!mfms[s.poiID])
          .map((s) => trimLocationName(s.gmbLocationID));
        const ls = await api.getLocationInParallel(
          this.poiGroupId,
          idxdb.company.gmbAccount,
          locationNames,
          "name,metadata",
          100,
          10
        );
        const mmd: { [locname: string]: MybusinessbusinessinformationMetadata } = {};
        ls.forEach((l) => (mmd[l.location.name] = l.location.metadata));
        const stores: EntitiesStore[] = idxdb.stores.stores.filter(
          (s) => mmd[trimLocationName(s.gmbLocationID)]?.canHaveFoodMenus
        );
        this.model = new FoodMenuModel(fmg.menuData, fmss, stores, "JPY");
      } finally {
        this.isLoading = false;
      }
    },

    /** 変更内容を破棄 ボタン */
    async refresh() {
      await this.getData();
      (this.$refs.grid0 as ListGrid<FoodMenuRow>).invalidate();

      this.addSnackbar({
        text: "変更内容を破棄しました",
        color: "info",
      });
    },
    /** 保存して一覧に戻る ボタン */
    async save(isConfirmed: boolean) {
      if (!isConfirmed && this.fmg.applicationScheduledTime) {
        // 保存確認ダイアログを表示する
        this.saveDialog.show = true;
        return;
      }
      this.isLoading = true;
      try {
        const oplogParams = getOperationLogParams(this.$route, "put", "foodmenu_list");
        const menuData = this.model.getMenuData();
        const fmg = await api.putFoodMenuGroup(
          this.poiGroupId,
          this.foodMenuGroupId,
          {
            menuData: menuData,
          },
          oplogParams
        );
        // 文字列だったらエラーが返ってる
        if (typeof fmg === "string") {
          this.addSnackbar({
            text: fmg,
            color: "danger",
            timeout: TOAST_CRITICAL_DURATION,
          });
        } else {
          this.addSnackbar({
            text: "保存しました",
            color: "info",
          });

          await this.$router.push({ name: "FoodMenuGroupList" }); // V2投稿履歴画面に遷移
        }
      } finally {
        this.isLoading = false;
      }
    },
    /** XLSXエクスポート */
    exportXlsx() {
      const data: ExportData = this.model.makeExportData();
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(data.menus), "メニュー設定");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(data.vars), "可変項目設定");
      const buf: ArrayBuffer = XLSX.write(wb, {
        type: "array",
        bookType: "xlsx",
        bookSST: true,
      });
      const companyName = useIndexedDb().company.name;
      const datetime = dayjs().format("YYYYMMDD");
      const filename = `${wordDictionary.service.name}-メニューエクスポート-${companyName}-${datetime}.xlsx`;
      saveAs(new Blob([buf], { type: "application/octet-stream" }), filename);
    },
    /** XLSXインポート */
    async importXlsx(event: Event) {
      const target = event.target as HTMLInputElement;
      const file: File = target.files[0];
      if (!file) {
        return;
      }
      target.value = "";
      const buf = await read(file);
      const book = arrayBufferToStringsArrays(buf);
      const exportData: ExportData = { menus: book[0], vars: book[1] };
      const result = FoodMenuModel.fromExportData(
        exportData,
        this.model.stores,
        this.model.currencyCode
      );
      if (0 < result.errors.length) {
        this.importDialog.errors = result.errors;
        this.importDialog.show = true;
      } else {
        result.fmm.setMode(this.tab === 0 ? "menus" : "variables");
        this.model = result.fmm;

        this.addSnackbar({
          text: "インポートしました",
          color: "info",
        });
      }
    },

    isSection: (rec: FoodMenuRow): boolean => rec.isSection,
    /** 「+」ボタン */
    add: function (row: FoodMenuRow, isSection: boolean): void {
      this.model.add(row, isSection);
      (this.$refs.grid0 as ListGrid<FoodMenuRow>).invalidate();
    },
    /** 「↑」「↓」ボタン */
    swap: function (row: FoodMenuRow, isUp: boolean): void {
      this.model.swap(row, isUp);
      (this.$refs.grid0 as ListGrid<FoodMenuRow>).invalidate();
    },
    /** 「🗑」ボタン */
    remove: function (row: FoodMenuRow, showConfirm: boolean): void {
      if (showConfirm) {
        if (this.model.hasPrice(row)) {
          this.removeDialog.row = row;
          this.removeDialog.show = true;
          return;
        }
      }
      this.model.remove(row);
      (this.$refs.grid0 as ListGrid<FoodMenuRow>).invalidate();
    },
    /** 「↑」「↓」ボタンの disabled */
    isSwapDisabled: function (isUp: boolean): (rec: FoodMenuRow) => boolean {
      const model = this.model;
      return function (rec: FoodMenuRow): boolean {
        return !model.canSwap(rec, isUp);
      };
    },
    /** 「↑」「↓」ボタンの style */
    swapStyle: function (isUp: boolean): (rec: FoodMenuRow) => { buttonBgColor: string } {
      const model = this.model;
      return function (rec: FoodMenuRow): { color: string; buttonBgColor: string } {
        return model.canSwap(rec, isUp)
          ? { color: "black", buttonBgColor: "#ddd" }
          : { color: "white", buttonBgColor: "white" };
      };
    },
    /** セクション名の style */
    bgStyle: function (isSectionColumn: boolean): (rec: FoodMenuRow) => { bgColor: string } {
      return function (rec: FoodMenuRow): { bgColor: string } {
        if (!rec) return null;
        return { bgColor: rec.isSection == isSectionColumn ? "" : "#ddd" };
      };
    },
  },
});
</script>
<style lang="scss" scoped>
.custom-grid {
  box-sizing: border-box;
  height: calc(100vh - 200px);
  position: absolute !important;
  min-width: 100px;
  top: 0;
  left: 0;
  right: 0;
  bottom: -70px;
}
</style>
