export function uniq<T>(arr: T[]): T[] {
    return arr.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
}

export function sort<T>(arr: T[]): T[] {
    if (arr.every((value) => typeof value === "number")) {
        return ([...arr] as number[]).sort((a, b) => a - b) as T[];
    } else {
        return [...arr].sort();
    }
}
export function containsAny<T>(arr: T[], values: T[]): boolean {
    return values.some((value) => arr.includes(value));
}
