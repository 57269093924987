<template>
  <div>
    <v-card v-if="status !== 'OK'" :class="cssClass">
      <v-card-text>
        <!--eslint-disable vue/no-v-html-->
        <span v-html="message"></span>
        <!--eslint-enable-->
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { FeatureToggle } from "@/routes/FeatureToggle";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    ft: { type: Object, required: false, default: FeatureToggle },
    part: { type: String, required: false, default: "" },
  },
  data() {
    return {
      status: "",
      message: "",
      cssClass: "",
    };
  },
  created() {
    const ft = this.ft as FeatureToggle;
    this.status = ft.getStatus(this.part);
    this.message = ft.getMessage(this.part);
    switch (this.status) {
      case "STOP":
        // 赤
        this.cssClass = "stop";
        break;
      case "INFO":
        // 青
        this.cssClass = "info";
        break;
      case "WARN":
        // 黄
        this.cssClass = "warn";
    }
  },
});
</script>
<style lang="scss" scoped>
.v-card {
  border-width: 0 0 0 4px;
  max-width: 36rem;
  margin: 0 10px 0 10px;
}
.v-card.stop {
  border-color: hsl(348deg, 86%, 61%);
  background-color: #feecf0;
  .v-card__text {
    font-size: 1rem;
    color: #cc0f35;
  }
}
.v-card.info {
  border-color: hsl(207deg, 61%, 53%);
  background-color: #eff5fb;
  .v-card__text {
    font-size: 1rem;
    color: #296fa8;
  }
}
.v-card.warn {
  border-color: hsl(44deg, 100%, 77%);
  background-color: #fffaeb;
  .v-card__text {
    font-size: 1rem;
    color: #946c00;
  }
}
</style>
