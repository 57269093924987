import axios from "axios";

/** ファイルを指定のURLにアップロードする */
export async function uploadFileToUrl(file: File, preSignedURL: string): Promise<boolean> {
    try {
        await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async (e) => {
                await axios
                    .put(preSignedURL, e.target.result, { headers: { "x-amz-acl": "public-read" } }) // 公開状態でアップロード
                    .then(() => {
                        resolve(true);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsArrayBuffer(file);
        });
        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
}
