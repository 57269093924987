<template>
  <div class="custom-grid">
    <c-grid
      :frozen-col-count="1"
      style="font-size: small"
      class="text-sm-left cgrid"
      :font="gridFont"
      :data="data"
      :header-row-height="40"
      :default-row-height="80"
      :v-if="headers.main.length"
    >
      <template #layout-header>
        <!-- 1段目 -->
        <c-grid-layout-row>
          <c-grid-header
            v-for="(hdr, index) in headers.main"
            :key="index"
            :caption="hdr.caption"
            :width="hdr.width"
            :field="hdr.field"
            :rowspan="hdr.rowSpan"
            :message="hdr.message"
          />
        </c-grid-layout-row>
        <c-grid-layout-row>
          <!-- 2段目 -->
          <c-grid-header
            v-for="(hdr, index) in headers.imagesSub"
            :key="index"
            :caption="hdr.caption"
            :width="hdr.width"
            :field="hdr.field"
            :rowspan="hdr.rowSpan"
            :message="hdr.message"
          ></c-grid-header>
        </c-grid-layout-row>
      </template>
      <template #layout-body>
        <c-grid-layout-row>
          <c-grid-column
            v-for="(hdr, index) in headers.main"
            :key="'parameter' + index"
            :caption="hdr.caption"
            :width="hdr.width"
            :field="hdr.field"
            :rowspan="hdr.rowSpan"
            :message="hdr.message"
            :column-type="hdr.columnType"
            :column-style="columnStyle"
          />
        </c-grid-layout-row>
        <c-grid-layout-row>
          <!-- 2段目 -->
          <c-grid-column
            v-for="(hdr, index) in headers.imagesSub"
            :key="'image-sub' + index"
            :caption="hdr.caption"
            :width="hdr.width"
            :field="hdr.field"
            :rowspan="hdr.rowSpan"
            :message="hdr.message"
            :column-type="hdr.columnType"
            :column-style="imageColumnStyle"
            @click-cell="showPreview($event, hdr.getFileURL)"
          />
        </c-grid-layout-row>
      </template>
    </c-grid>
    <!-- 写真・動画のダイアログ表示 -->
    <v-2-post-file-image-dialog ref="imageDialog" />
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, toNative } from "vue-facing-decorator";
import V2PostFileImageDialog from "./v2-post-file-image-dialog.vue";
import type { PostItem } from "./post-item";
import { Headers } from "./post-item";
import type { ClickEvent } from "./post-item";

@Component({
  components: { V2PostFileImageDialog },
})
class V2PostFileTable extends Vue {
  @Prop({ type: Object, default: new Headers() }) headers: Headers;
  @Prop({ type: Array, default: [] }) data: PostItem[];

  // グリッドに表示するフォントサイズ
  gridFontSize = 12.8;
  // グリッドに表示するフォント
  gridFont = `${this.gridFontSize}px sans-serif`;
  // cheetah gridのcolumnのスタイル
  columnStyle = { textOverflow: "ellipsis", buttonBgColor: "transparent" };
  imageColumnStyle = {
    textAlign: "center",
    buttonBgColor: "transparent",
    imageSizing: "keep-aspect-ratio",
  };

  showPreview(
    click: ClickEvent,
    getFileURL: (click: ClickEvent) => { name: string; url: string }
  ): void {
    const file = getFileURL(click);
    if (file?.name) {
      const dialog = this.$refs.imageDialog as InstanceType<typeof V2PostFileImageDialog>;
      dialog.show(file.name, file.url);
    }
  }
}
export default toNative(V2PostFileTable);
</script>
<style lang="scss" scoped>
.custom-grid {
  box-sizing: border-box;
  height: calc(100vh - 185px);
  width: calc(100vw - 325px);
  min-width: 100px;
}
</style>
