/** batchGetをする際に状態を管理するための型です */
export type LoadingStatus = {
    completed: boolean;
    error?: any;
};

/** 指定されたサイズごとに配列を分割する */
export function divide<T>(array: T[], size: number): T[][] {
    if (size < 1) {
        size = 1;
    }
    const dividedArray: T[][] = [];
    while (array.length) {
        dividedArray.push(array.splice(0, size));
    }
    return dividedArray;
}
