import wordDictionary from "@/word-dictionary";
import type {
    FileSelectionItem,
    FileSelectionState,
    PlatformName,
} from "@/models/v2-file-selection";
import {
    arFourFifth,
    arTwoFirst,
    arFourNinth,
    arSixteenNinth,
    arNinetyFirst,
    arNineSixteenth,
    arSquare,
    isImage,
    isVideo,
    isVideoExtension,
    platformOfficialNames,
} from "@/models/v2-file-selection";

// 画像サイズのチェック 違反していたらエラーメッセージを返す
export function isValidImageSize(
    platform: PlatformName,
    width: number,
    height: number,
    fileCount: number,
    isTrimImage?: boolean // 任意引数: trim画像の判定時のみtrueを指定するとエラーメッセージがわかりやすくなる
): string {
    const isExistTrimImageParam: boolean = isTrimImage ?? false;
    const targetImageName = isExistTrimImageParam && isTrimImage ? "トリム枠" : "画像";
    switch (platform) {
        case "google":
            // FIXME: GBPのバグにより写真枚数によって異なるバリデーションを行なっている
            // 共通ルール: 横400x縦300px以上横10000x縦10000px以下
            return fileCount == 1
                ? width < 400 || height < 300
                    ? `${targetImageName}のサイズを横400x縦300px以上にしてください`
                    : width > 10000 || height > 10000
                    ? `${targetImageName}のサイズを横10000x縦10000px以下にしてください`
                    : ""
                : width < 400 || height < 400
                ? `${targetImageName}のサイズを横400x縦400px以上にしてください`
                : width > 10000 || height > 10000
                ? `${targetImageName}のサイズを横10000x縦10000px以下にしてください`
                : "";
        case "yahoo":
            return width < 720 || height < 720
                ? `${targetImageName}のサイズを横720x縦720px以上にしてください`
                : "";
        case "instagram":
            // 横320px ~ 1440px
            return width < 320 || height <= 0
                ? `${targetImageName}のサイズを横320px以上にしてください`
                : width > 1440
                ? `${targetImageName}のサイズを横1440px以下にしてください`
                : "";
        case "facebook":
            // 画像サイズについては制限無し
            return width <= 0 || height <= 0
                ? `${targetImageName}のサイズを1px以上にしてください`
                : "";
    }
}

// ファイルサイズのチェック 違反していたらエラーメッセージを返す
export function isValidFileSize(
    platform: PlatformName,
    file: File | Blob,
    isTrimImage: boolean = false // 任意引数: trim画像の判定時のみtrueを指定するとエラーメッセージがわかりやすくなる
): string {
    const targetImageName: string = isTrimImage ? "トリム後" : "画像";
    switch (platform) {
        case "google":
            // 10KB ~ 25MB
            return file.size < 10000
                ? `${targetImageName}のファイルサイズが10KB以上である必要があります。`
                : file.size > 25000000
                ? `${targetImageName}のファイルサイズを25MB以下にしてください`
                : "";
        case "yahoo":
            // 10MB以下
            return file.size > 10000000
                ? `${targetImageName}のファイルサイズを10MB以下にしてください`
                : "";
        case "instagram":
            // ~8MiB
            return file.size > 8388608
                ? `${targetImageName}のファイルサイズを8.3MB以下にしてください`
                : "";
        case "facebook": {
            // ~4MB (.pngの場合は1MB未満)
            const isPngImage: boolean = file.type == "image/png";
            if (isPngImage) {
                const isLargerThanMaxLimitForPngImage: boolean = file.size >= 1000000;
                return isLargerThanMaxLimitForPngImage
                    ? `${targetImageName}のPNGファイルのサイズを1MB未満にしてください`
                    : "";
            }
            const isJpegImage: boolean = file.type == "image/jpeg";
            if (isJpegImage) {
                const isLargerThanMaxLimitForJpgImage: boolean = file.size > 3000000;
                return isLargerThanMaxLimitForJpgImage
                    ? `${targetImageName}のJPGファイルのサイズを3MB以下にしてください`
                    : "";
            }
            return "";
        }
    }
}

// 画像アスペクト比のチェック 違反していたらエラーメッセージを返す
export function isValidAspectRatio(
    platform: PlatformName,
    width: number,
    height: number,
    fileCount: number,
    isTrimImage?: boolean // 任意引数: trim画像の判定時のみtrueを指定するとエラーメッセージがわかりやすくなる
): string {
    const isExistTrimImageParam: boolean = isTrimImage ?? false;
    const targetImageName = isExistTrimImageParam && isTrimImage ? "トリム枠" : "投稿画像";
    // 横：縦
    const scale = width / height;
    let validationResult: string;
    switch (platform) {
        case "google":
            // GBP投稿では2枚以上投稿しようとしている場合1:1に制限する
            validationResult = "";
            if (fileCount === 1) {
                validationResult =
                    scale < arFourNinth.value || scale > arTwoFirst.value
                        ? `${targetImageName}は4:9 ~ 2:1 の間の比率にしてください`
                        : "";
            } else if (scale !== arSquare.value) {
                validationResult = `${targetImageName}は1:1の比率にしてください`;
            }
            return validationResult;
        case "yahoo":
            // 特に制限無し
            return "";
        case "instagram":
            return scale < arFourFifth.value || scale > arNinetyFirst.value
                ? `${targetImageName}は4:5 ~ 1.91:1 の間の比率にしてください`
                : "";
        case "facebook":
            // 特に制限無し
            return "";
    }
}

// 画像ファイルのバリデーションを行いエラーメッセージを返す
export async function validateFile(
    file: FileSelectionItem,
    fileCount: number,
    platform: PlatformName,
    errorMessages: string[],
    aspectNoCheck: boolean
): Promise<void> {
    if (isVideo(file.file)) {
        // 動画はアップロード時にバリデーションを行なっているため見ない
        return;
    }
    const dim = await getImageDimension(file.imageUrl);
    var error = isValidImageSize(platform, dim.width, dim.height, fileCount);
    if (error != "") {
        errorMessages.push(`${platformOfficialNames[platform]} ${file.state}枚目 ${error}`);
    }
    error = isValidFileSize(platform, file.file);
    if (error != "") {
        errorMessages.push(`${platformOfficialNames[platform]} ${file.state}枚目 ${error}`);
    }
    // 画像の縦横比チェックを行わないチェック有効だったらアスペクト比チェックせずここで終了
    if (aspectNoCheck) {
        return;
    }
    error = isValidAspectRatio(platform, dim.width, dim.height, fileCount);
    if (error != "") {
        errorMessages.push(`${platformOfficialNames[platform]} ${file.state}枚目 ${error}`);
    }
}

export type Dimension = {
    width: number;
    height: number;
};

// validationに必要なWxHを取得するための関数
async function getImageDimension(fileURL: string): Promise<Dimension> {
    return new Promise((resolve) => {
        var img = new Image();
        img.src = fileURL;
        img.onload = function () {
            var result = { width: img.naturalWidth, height: img.naturalHeight };
            resolve(result);
        };
    });
}

export type FileValidateCondition = { extensions: string[] };

export const FileValidateConditionMap = new Map<PlatformName, FileValidateCondition>();
FileValidateConditionMap["google"] = { extensions: ["png", "jpg", "jpeg", "mov", "mp4"] };
FileValidateConditionMap["yahoo"] = { extensions: ["png", "jpg", "jpeg"] };
FileValidateConditionMap["facebook"] = { extensions: ["png", "jpg", "jpeg", "mov", "mp4"] };
FileValidateConditionMap["instagram"] = { extensions: ["jpg", "jpeg", "mov", "mp4"] };

// アップロードする動画の制限
export type VideoLimitations = {
    // 最小ファイルサイズ (kb)
    minFileSizeKb: number;
    // 最大ファイルサイズ (kb)
    maxFileSizeKb: number;
    // 最短再生時間 (秒)
    minRecordingSeconds: number;
    // 最長再生時間 (秒)
    maxRecordingSeconds: number;
    // 最小の幅 (px)
    minWidth: number;
    // 最小の高さ (px)
    minHeight: number;
    // 最大の幅 (px)
    maxWidth: number;
    // 最大の高さ (px)
    maxHeight: number;
};
const googleLimitations: VideoLimitations = {
    minFileSizeKb: 10,
    maxFileSizeKb: 75 * 1000,
    minRecordingSeconds: 1,
    maxRecordingSeconds: 30,
    minWidth: 400,
    minHeight: 300,
    maxWidth: 0,
    maxHeight: 0,
};
const instagramLimitations: VideoLimitations = {
    minFileSizeKb: 0,
    maxFileSizeKb: 100 * 1000,
    minRecordingSeconds: 3,
    maxRecordingSeconds: 60,
    minWidth: 0,
    minHeight: 0,
    maxWidth: 1920,
    maxHeight: 0,
};
const facebookLimitations: VideoLimitations = {
    minFileSizeKb: 1,
    maxFileSizeKb: 1 * 1000 * 1000,
    minRecordingSeconds: 1,
    maxRecordingSeconds: 20 * 60,
    minWidth: 0,
    minHeight: 0,
    maxWidth: 0,
    maxHeight: 0,
};

export const VideoLimitationsMap = new Map<PlatformName, VideoLimitations>();
VideoLimitationsMap.set("google", googleLimitations);
VideoLimitationsMap.set("instagram", instagramLimitations);
VideoLimitationsMap.set("facebook", facebookLimitations);

// 動画投稿できないプラットフォームのリスト
export const platformsCannotPostVideo: PlatformName[] = ["yahoo"];

export type FileAmountsOfPlatforms = {
    google?: number;
    yahoo?: number;
    instagram?: number;
    facebook?: number;
};

// ファイル選択の上限数
export function maxImageFiles(canUseGbpConsole: boolean): FileAmountsOfPlatforms {
    return { google: canUseGbpConsole ? 10 : 1, yahoo: 1, instagram: 1, facebook: 10 };
}

export type FileValidationResult = {
    state: FileSelectionState;
    message?: string;
    toastMessage?: string;
};

// 投稿可能な拡張子か
export function validateExtension(ext: string, platform: PlatformName): FileValidationResult {
    var validExtensions: string[] = FileValidateConditionMap[platform].extensions;
    var state: FileSelectionState = "deselected";
    if (validExtensions.indexOf(ext) === -1) {
        let message = "";
        switch (platform) {
            case "google":
                message = wordDictionary.v2post.validator.invalidExtensionGBP;
                break;
            case "yahoo":
                message = wordDictionary.v2post.validator.invalidExtensionYahoo;
                break;
            case "instagram":
                message = wordDictionary.v2post.validator.invalidExtensionIG;
                break;
            case "facebook":
                message = wordDictionary.v2post.validator.invalidExtensionFB;
                break;
            default:
                message = wordDictionary.v2post.validator.invalidExtension;
                break;
        }
        return {
            state: "rejected",
            message: message,
        };
    }
    return { state };
}

export function validateFileSize(platform: PlatformName, imageFile: File): FileValidationResult {
    const state: FileSelectionState = "deselected";
    const isError = isValidFileSize(platform, imageFile);
    if (isError !== "" && platform === "google" && isError.includes("10KB以上")) {
        // アップロード時点でGBP向け10KB未満のファイルは編集したところでそれ以上のファイルサイズのものを生成出来ないと予想されるのでrejectedとする
        console.error("[checkLimitations] Invalid file size.");
        return {
            state: "rejected",
            message: wordDictionary.v2post.validator.fileSizeIsSmall,
            toastMessage: isError,
        };
    }
    return { state };
}

// ファイル選択数上限に達しているか
export function validateNumberOfFiles(
    canUseGbpConsole: boolean,
    ext: string,
    platform: PlatformName,
    selectedImages: number,
    selectedVideos: number,
    prev: FileValidationResult
): FileValidationResult {
    // すでに "rejected" が設定されていたらそのまま返す
    if (prev.state === "rejected") {
        return prev;
    }

    if (platform === "google") {
        if (isVideoExtension(ext) && selectedVideos > 0) {
            return { state: "disabled" };
        }
        if (selectedImages + selectedVideos >= maxImageFiles(canUseGbpConsole).google) {
            return { state: "disabled" };
        }
    } else if (platform === "yahoo") {
        if (selectedImages > 0) {
            return { state: "disabled" };
        }
    } else if (platform === "instagram") {
        if (selectedImages > 0 || selectedVideos > 0) {
            return { state: "disabled" };
        }
    } else if (platform === "facebook") {
        if (selectedVideos > 0) {
            return { state: "disabled" };
        }
        if (!isVideoExtension(ext) && selectedImages >= maxImageFiles(canUseGbpConsole).facebook) {
            return { state: "disabled" };
        }
        if (isVideoExtension(ext) && selectedImages) {
            return { state: "disabled" };
        }
    }

    return prev;
}

export function checkMaxFiles(
    canUseGbpConsole: boolean,
    items: FileSelectionItem[],
    ext: string,
    platform: PlatformName,
    prev: FileValidationResult
): FileValidationResult {
    // すでに "rejected" が設定されていたらそのまま返す
    if (prev.state === "rejected") {
        return prev;
    }
    var selectedImages = 0;
    var selectedVideos = 0;
    for (var item of items) {
        if (isImage(item.file) && typeof item.state === "number") {
            selectedImages++;
        }
        if (isVideo(item.file) && typeof item.state === "number") {
            selectedVideos++;
        }
    }

    return validateNumberOfFiles(
        canUseGbpConsole,
        ext,
        platform,
        selectedImages,
        selectedVideos,
        prev
    );
}
/** 選択済みの動画が存在するか */
export type HasSelectedVideo = {
    google: number;
    yahoo: number;
    instagram: number;
    facebook: number;
};

export function validateVideo(
    elem: HTMLVideoElement,
    platform: PlatformName,
    file: File,
    prev: FileValidationResult,
    aspectNoCheck: boolean,
    hasSelectedVideo?: HasSelectedVideo
): FileValidationResult {
    if (platformsCannotPostVideo.includes(platform)) {
        return { state: "rejected", message: wordDictionary.v2post.validator.rejectMovie };
    }
    const limits = VideoLimitationsMap.get(platform);

    // 国際単位系 (MacのFinderでのサイズ表記) に合わせ、KB = 1 * 1000 byte とする
    // 調査した結果、各社とも上限は厳密なものではなく KB = 1000B で計算して問題ないと判断
    // ファイルサイズ
    if (limits.minFileSizeKb > 0 && limits.minFileSizeKb > file.size / 1000) {
        return { state: "rejected", message: wordDictionary.v2post.validator.fileSizeIsSmall };
    }
    if (limits.maxFileSizeKb > 0 && limits.maxFileSizeKb < file.size / 1000) {
        return { state: "rejected", message: wordDictionary.v2post.validator.fileSizeIsBig };
    }

    // 再生時間, 幅, 高さ
    if (elem.duration > 0 && limits.minRecordingSeconds > elem.duration) {
        return { state: "rejected", message: wordDictionary.v2post.validator.secondsIsShort };
    }
    if (elem.duration > 0 && limits.maxRecordingSeconds < elem.duration) {
        return { state: "rejected", message: wordDictionary.v2post.validator.secondsIsLong };
    }
    if (elem.videoWidth > 0 && limits.minWidth > elem.videoWidth) {
        return { state: "rejected", message: wordDictionary.v2post.validator.widthIsSmall };
    }
    if (elem.videoHeight > 0 && limits.minHeight > elem.videoHeight) {
        return { state: "rejected", message: wordDictionary.v2post.validator.heightIsSmall };
    }
    // 最小は条件があるときだけチェックする
    if (limits.minWidth > 0) {
        if (elem.videoWidth > 0 && limits.minWidth > elem.videoWidth) {
            return { state: "rejected", message: wordDictionary.v2post.validator.widthIsSmall };
        }
    }
    if (limits.minHeight > 0) {
        if (elem.videoHeight > 0 && limits.minHeight > elem.videoHeight) {
            return { state: "rejected", message: wordDictionary.v2post.validator.heightIsSmall };
        }
    }
    // 最大は条件があるときだけチェックする
    if (limits.maxWidth > 0) {
        if (elem.videoWidth > 0 && limits.maxWidth < elem.videoWidth) {
            return { state: "rejected", message: wordDictionary.v2post.validator.widthIsBig };
        }
    }
    if (limits.maxHeight > 0) {
        if (elem.videoHeight > 0 && limits.maxHeight < elem.videoHeight) {
            return { state: "rejected", message: wordDictionary.v2post.validator.heightIsBig };
        }
    }
    if (aspectNoCheck) {
        if (typeof prev.state === "number") {
            return prev;
        } else {
            return {
                state:
                    hasSelectedVideo[platform] > 0 || prev.state === "disabled"
                        ? "disabled"
                        : "deselected",
            };
        }
    } else {
        // 「画像縦横比チェックを行わない」無効の場合
        if (!isValidAspectRatioVideo(platform, elem.videoWidth, elem.videoHeight)) {
            // 不正なアスペクト比の場合
            return {
                state: "rejected",
                message: wordDictionary.v2post.validator.invalidAspectRatio,
            };
        } else if (typeof prev.state === "number") {
            return { state: prev.state === 1 ? 1 : "disabled" };
        } else {
            // 正しいアスペクト比の動画で既に一つでも動画選択されていたら disabled、そうでなければ deselected
            return {
                state:
                    hasSelectedVideo[platform] > 0 || prev.state === "disabled"
                        ? "disabled"
                        : "deselected",
            };
        }
    }
}

function isValidAspectRatioVideo(
    platform: PlatformName,
    videoWidth: number,
    videoHeight: number
): boolean {
    const aspectRatio = videoWidth / videoHeight;
    if (videoHeight === 0) {
        // NOTE: h265の画像のときvideoWidthとvideoHeightが0となり取得できない場合がある
        //       その場合はアスペクト比率のチェックはやりようがないのでスキップさせる
        return true;
    }
    if (platform === "instagram") {
        return arFourFifth.value <= aspectRatio && aspectRatio <= arSixteenNinth.value;
    } else if (platform === "facebook") {
        return aspectRatio === arSixteenNinth.value || aspectRatio === arNineSixteenth.value;
    }
    return true;
}
