import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import wordDictionary from "@/word-dictionary";
import { trimLocationName } from "@/helpers/gmb";
import { getPlaceActionErrorMessage } from "@/helpers/error";
import { divide } from "@/helpers/api/utils";
import { REGEX_URL_OR_BLANK } from "@/helpers/validator";
import { api } from "@/helpers/api/place-action-links";
import { api as setupAPI } from "@/helpers/api/setup";
import { read, arrayBufferToCsv } from "@/helpers/xlsxtools";
import { isBoolean, isNumber, isString } from "lodash";
import { getOperationLogParams } from "@/routes/operation-log";

import type {
    EntitiesStore,
    MybusinessplaceactionsPlaceActionLink,
    DomainPlaceActionLinks,
} from "@/types/ls-api";
import type { AxiosError } from "axios";
import type { BatchGetPlaceActionLinksResult } from "@/helpers/api/place-action-links";
import type { RouteLocationNormalized } from "vue-router";

export type PlaceActionLinkData = MybusinessplaceactionsPlaceActionLink & {
    poiID: number;
    storeName: string;
    storeCode: string;
    areas: number[];
    locationName: string;
    dirtyFlag?: boolean;
    updateMask?: string[];
    deleteFlag?: boolean;
    isError?: boolean;
};

export type Dictionary = {
    [key: string]: string;
};

export const placeActionTypes = {
    APPOINTMENT: "予約",
    ONLINE_APPOINTMENT: "オンライン予約",
    DINING_RESERVATION: "レストランの予約",
    FOOD_ORDERING: "配達またはテイクアウト",
    FOOD_DELIVERY: "配達",
    FOOD_TAKEOUT: "テイクアウト",
    SHOP_ONLINE: "商品の配達や受け取り",
} as Dictionary;

export const providerTypes = {
    NO_DATA: "設定無し",
    PROVIDER_TYPE_UNSPECIFIED: "",
    AGGREGATOR_3P: "サードパーティー",
    MERCHANT: "販売者",
} as Dictionary;

/* 仮でLinkのNameを作る */
export function getTemporaryName(poiID: number): string {
    return `Temporary/${poiID}/${uuidv4()}`;
}

/* 英語を日本語に直す */
export function convertStringByDict(str: string, dict: Dictionary): string {
    let result = str;
    if (str == null) {
        return str;
    }
    const keys = Object.keys(dict);
    const regex = new RegExp(`\\b(${keys.join("|")})\\b`, "g");
    result = result.replace(regex, (match) => dict[match]);
    return result;
}

export const updateXLSXColumns: string[] = [
    "店舗ID",
    "店舗コード",
    "ビジネス名",
    "リンクの管理名",
    "種別",
    "URL",
    "優先表示",
    "削除フラグ",
];

/* Excelに保存 */
export function saveFile(
    aoa: Array<Array<string | number | boolean>>,
    companyName: string,
    mode: string
): void {
    try {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(aoa);
        XLSX.utils.book_append_sheet(wb, ws, "プレイスアクション");
        const buf: ArrayBuffer = XLSX.write(wb, {
            type: "array",
            bookType: "xlsx",
            bookSST: true,
        });
        const dateTime = dayjs().format("YYYYMMDD");
        const filename = `${wordDictionary.service.name}-プレイスアクションエクスポート-${mode}-${companyName}-${dateTime}.xlsx`;
        saveAs(new Blob([buf], { type: "application/octet-stream" }), filename);
    } catch (e) {
        console.error(e);
        throw Error("xlsxファイルのエクスポートに失敗しました。");
    }
}

export class Model {
    private stores: EntitiesStore[];
    locationNames: string[] = [];
    // APIから取得したデータ
    fetchData: DomainPlaceActionLinks[] = [];
    // 更新データ管理等に使うデータ
    data: PlaceActionLinkData[] = [];
    // 検索語などで絞り込んだデータ
    filteredData: PlaceActionLinkData[] = [];
    // vueインスタンスが破棄される際にbatchGetループ処理を中断させる為のフラグ
    isDestroyed: boolean = false;

    async init(myParent: any): Promise<void> {
        this.isDestroyed = false;
        this.stores = myParent.stores;
    }

    /* プレイスアクションリンク情報をAPIから取得します */
    async fetch(poiGroupID: number): Promise<string> {
        const message = await this.initGBPAccessToken(poiGroupID);
        if (message !== "") {
            return message;
        }
        for await (const result of this.batchGet(poiGroupID)) {
            this.fetchData.push(...(result?.result ?? []));
            if (result.error) {
                // batchGet取得失敗してerrorが返ってきた場合
                const errorMessage: string =
                    result.error.response?.status === 404
                        ? "無効な店舗が見つかったため現在利用できません。ConnectOM側で対応が完了するまでお待ち下さい。"
                        : "サーバーが混み合っています。すこし時間を置いて再読み込みしてください。";
                const originalErrorMessage = result.error.response?.data?.errorMessage ?? "";
                return `${errorMessage}<br/>${originalErrorMessage}`;
            }
        }
        this.initData();
        return "";
    }

    private async *batchGet(poiGroupID: number): AsyncGenerator<BatchGetPlaceActionLinksResult> {
        this.locationNames = this.stores.map((s) => trimLocationName(s.gmbLocationID));
        yield { completed: false, result: [] };
        // batchGet同時呼び出し上限
        const concurrency = 10;
        // 一度の呼び出しの際に取得する件数。即ち concurrency * batchSize が並列接続によって一度に取ってくる最大数
        const batchSize = 100;
        const locationNamesChunks: string[][] = divide(this.locationNames, batchSize);
        const batchSets: string[][][] = divide(locationNamesChunks, concurrency);
        for (const oneBatches of batchSets) {
            if (this.isDestroyed === true) {
                // ページから離脱時はbatchGetを中止させる
                return;
            }
            const result: Promise<BatchGetPlaceActionLinksResult> = api.batchGetPlaceActionLinks(
                poiGroupID,
                oneBatches
            );
            yield result;
        }
    }

    /** GBPアクセストークン更新 */
    async initGBPAccessToken(poiGroupID: number): Promise<string> {
        try {
            await setupAPI.initGBPToken(poiGroupID);
            return "";
        } catch (error) {
            console.error(error);
            const e = error as AxiosError;
            return `内部処理エラーが発生しました。システム管理者にお問い合わせください。 ${e.response?.status}`;
        }
    }

    /* 表示データの形をclient向けに初期化します */
    initData(): void {
        this.data = this.stores?.reduce((result, store) => {
            const placeActionsLinks = this.fetchData.find(
                (link) => link.LocationName === trimLocationName(store.gmbLocationID)
            );
            const links = placeActionsLinks?.links ?? [];

            const placeActionLinksData: PlaceActionLinkData[] = links.map((link) =>
                this.makePlaceActionLinkData(store, link)
            );

            // データが無い企業にはデータ無しとして行を追加します
            Object.keys(placeActionTypes).forEach((type) => {
                const isExist = placeActionLinksData.some((data) => data.placeActionType === type);
                if (!isExist) {
                    result.push(
                        this.makePlaceActionLinkData(store, {
                            name: getTemporaryName(store.poiID),
                            placeActionType: type,
                            providerType: "NO_DATA",
                            isEditable: false,
                        })
                    );
                }
            });

            return [...result, ...placeActionLinksData];
        }, []);
    }

    private makePlaceActionLinkData(
        store: EntitiesStore,
        placeActionLink: MybusinessplaceactionsPlaceActionLink
    ): PlaceActionLinkData {
        return {
            poiID: store.poiID,
            storeName: store.name,
            storeCode: store.gmbStoreCode,
            areas: store.areas,
            locationName: trimLocationName(store.gmbLocationID),
            ...placeActionLink,
        };
    }

    async importFile(xlsxFile: File): Promise<string> {
        let fields: string[];
        let rows: any[];
        try {
            const buf = await read(xlsxFile);
            [fields, rows] = arrayBufferToCsv(buf);
            if (fields.length === 0) {
                return "XLSXに ヘッダ行 がありません";
            }
        } catch (e) {
            console.error(e);
            return "XLSXの読み込みに失敗しました";
        }

        // ファイルに存在しないカラムがあったらエラー
        for (let i = 0; i < updateXLSXColumns.length; i++) {
            if (fields.includes(updateXLSXColumns[i]) === false) {
                return `XLSXに ${updateXLSXColumns[i]} がありません`;
            }
        }
        // 知らないカラムがあったらインポート完了時に警告を出す
        const unknownFields = [];
        fields.forEach((field) => {
            if (updateXLSXColumns.includes(field) === false) unknownFields.push(field);
        });

        const ignoreList = [];
        const typeErrList = [];
        const uriErrList = [];
        const missingPoiList = [];
        const importData = [];
        rows.forEach((row, index) => {
            const poiID = row[updateXLSXColumns[0]];
            const name = row[updateXLSXColumns[3]];
            const placeActionType = row[updateXLSXColumns[4]];
            const uri = row[updateXLSXColumns[5]];
            const isPreferred =
                row[updateXLSXColumns[6]] !== ""
                    ? row[updateXLSXColumns[6]].toUpperCase() === "TRUE"
                    : false;
            const isDelete =
                row[updateXLSXColumns[7]] !== ""
                    ? row[updateXLSXColumns[7]].toUpperCase() === "TRUE"
                    : false;

            // 設定無しはスキップする
            if (placeActionType === "" || uri === "") {
                ignoreList.push(index + 1);
                return;
            }
            if (!Object.keys(placeActionTypes).some((k) => k === placeActionType)) {
                typeErrList.push(index + 1);
                return;
            }
            // URLチェック
            if (!this.isValidUrl(uri)) {
                uriErrList.push(index + 1);
            }
            // インポートデータに基づいてデータを作成する
            const store = this.stores.find((s) => `${s.poiID}` === poiID);
            if (store == null) {
                missingPoiList.push(poiID);
                return;
            }
            const datum = this.makePlaceActionLinkData(store, {
                name: name === "" ? getTemporaryName(store.poiID) : name,
                placeActionType,
                uri,
                isPreferred,
                providerType: "MERCHANT",
                isEditable: true,
            });
            datum.deleteFlag = isDelete;
            importData.push(datum);
        });
        // データを画面データに反映する
        this.updateDirtyFlag(importData);

        // インポート結果のメッセージを生成
        let message = `${importData.length} 行 インポートしました。`;
        if (unknownFields.length > 0) {
            message += `<br/>対応していないカラムを読み飛ばしました[${unknownFields.join(",")}]。`;
        }
        if (ignoreList.length > 0) {
            message += `<br/>URLが設定されていなかったため、${ignoreList.length}行読み飛ばしました。`;
        }
        if (typeErrList.length > 0) {
            message += `<br/>プレイスアクションの種別が不正だったため、${typeErrList.length}行読み飛ばしました。`;
        }
        if (uriErrList.length > 0) {
            message += `<br/>URLが不正な行が${uriErrList.length}行ありました。`;
        }
        if (missingPoiList.length > 0) {
            message += `<br/>設定できない店舗IDだったため、${missingPoiList.length}行読み飛ばしました。`;
        }
        return message;
    }

    verify(d: PlaceActionLinkData): string[] {
        const errorList: string[] = [];

        // URLが不正な形であるかチェック
        if (!this.isValidUrl(d.uri)) {
            errorList.push("URLが不正です。");
        }

        // ロケーションとURLとplaceActionTypeの組み合わせで他のデータと重複しているかチェック(nameが違うものがあればNG)
        const duplicate = this.data.some(
            (data) =>
                data.locationName === d.locationName &&
                data.uri === d.uri &&
                data.placeActionType === d.placeActionType &&
                data.name !== d.name
        );
        if (duplicate) {
            errorList.push("プレイスアクションリンクの種別とURLの組み合わせが重複しています。");
        }

        // 既存のデータで優先表示フラグを単独でfalseにしようとしているかチェック(Googleの仕様でNGの模様)
        // 元のデータを探す
        const originalData = this.fetchData.find(
            (fd) => fd.LocationName === d.locationName && !!fd.links?.some((l) => l.name === d.name)
        );
        if (originalData) {
            // 対象の他に今回のデータの中で isPreferred = true のデータがあるか
            const sameLocationDataIsPreferred = this.data.filter(
                (data) =>
                    data.locationName === d.locationName &&
                    data.placeActionType === d.placeActionType &&
                    data.isPreferred &&
                    data.name != d.name
            );
            const link = originalData.links.find((l) => l.name === d.name);
            // 他にisPreferred = trueのデータが無く、isPreferred が true から false に変更されていたら警告を出す
            if (sameLocationDataIsPreferred.length === 0 && link.isPreferred && !d.isPreferred) {
                errorList.push(
                    "優先表示を単独でオフにすることはできません。他のリンクを優先表示オンにしてください。"
                );
            }
        }

        return errorList;
    }

    private isValidUrl(url: string): boolean {
        return REGEX_URL_OR_BLANK.test(url);
    }

    /* APIから取得したデータと比較して、更新をチェックしdirtyFlagを更新する */
    updateDirtyFlag(targets: PlaceActionLinkData[]) {
        // APIから取得したデータと比較してDirtyFlagを更新していく
        for (const target of targets) {
            if (target.providerType === "NO_DATA" || !target.isEditable) {
                // 都合上作成したデータ無しデータだった場合と編集不可のデータはskip
                continue;
            }

            // URLだけチェックする
            if (this.isValidUrl(target.uri)) {
                target.isError = false;
            } else {
                target.isError = true;
            }

            // ロケーションとリンク名が一致しているデータを探す
            const matchingData = this.fetchData.find(
                (d) =>
                    d.LocationName === target.locationName &&
                    d.links?.some((item) => item.name === target.name)
            );
            if (!matchingData) {
                // 新規追加
                target.dirtyFlag = true;
                continue;
            }
            if (!!matchingData && target.deleteFlag) {
                // 削除
                target.updateMask = [];
                target.dirtyFlag = true;
                continue;
            }

            // 上のfindで条件にマッチしているはずなので、特に存在チェックは行わない
            const matchingItem = matchingData.links.find((item) => item.name === target.name);

            // 編集で変更できるのはURI, PlaceActionType, isPreferredのみ
            const isUpdateType = matchingItem.placeActionType !== target.placeActionType;
            const isUpdateURI = matchingItem.uri !== target.uri;
            // isPreferredはoptionalなので元データでfalseの場合はundefinedになる
            const isUpdateIsPreferred =
                (matchingItem.isPreferred && matchingItem.isPreferred !== target.isPreferred) ||
                (!matchingItem.isPreferred && target.isPreferred === true);
            if (isUpdateType || isUpdateURI || isUpdateIsPreferred) {
                // 更新
                target.updateMask = [];
                if (isUpdateType) {
                    target.updateMask.push("placeActionType");
                }
                if (isUpdateURI) {
                    target.updateMask.push("uri");
                }
                if (isUpdateIsPreferred) {
                    target.updateMask.push("isPreferred");
                }
                target.dirtyFlag = true;
                continue;
            }
            // 更新無し(上記の条件に当てはまらない)
            target.dirtyFlag = false;
            target.updateMask = [];
            target.dirtyFlag = false;
        }

        // targetsの内容でdata(画面に表示するデータ)を更新する
        for (const target of targets) {
            const dataIndex = this.data.findIndex((item) => item.name === target.name);
            if (dataIndex === -1) {
                // 新規追加
                this.data.push(target);
                continue;
            }
            // 新規追加したものを削除する場合
            if (target.name?.startsWith("Temporary/") && !!target.deleteFlag) {
                // 削除した結果レコードが0になると困るので、その場合は設定無しレコードに差し替える
                const count =
                    this.data.filter(
                        (d) =>
                            d.poiID === target.poiID && d.placeActionType === target.placeActionType
                    )?.length ?? 0;
                if (count === 1 && target.providerType != "NO_DATA") {
                    this.data.splice(dataIndex, 1, {
                        poiID: target.poiID,
                        storeName: target.storeName,
                        storeCode: target.storeCode,
                        areas: target.areas,
                        locationName: target.locationName,
                        placeActionType: target.placeActionType,
                        providerType: "NO_DATA",
                        isEditable: false,
                    });
                } else {
                    this.data.splice(dataIndex, 1);
                }
            } else {
                // 編集・既存レコード削除(表示データからは消さないのでレコードを上書き)
                this.data.splice(dataIndex, 1, target);
            }
        }

        const duplicateRecords = {};
        // poiIDとplaceActionTypeが重複するレコードの組み合わせを見つける
        for (const record of this.data) {
            const key = record.poiID.toString() + record.placeActionType;
            if (duplicateRecords[key]) {
                duplicateRecords[key].push(record);
            } else {
                duplicateRecords[key] = [record];
            }
        }
        // 重複が2以上あるとき、NO_DATAのレコードを削除する
        for (const key in duplicateRecords) {
            const records = duplicateRecords[key];
            if (records.length <= 1) {
                continue;
            }
            const noDataRecord = records.find((record) => record.providerType === "NO_DATA");
            if (noDataRecord) {
                const index = this.data.indexOf(noDataRecord);
                this.data.splice(index, 1);
            }
        }

        // 最後にソート
        this.data.sort((a, b) => a.poiID - b.poiID);
    }

    /* 検索条件によって表示データをフィルターする */
    filter(searchWord: string, areaList: number[], onlyDirtyRows: boolean): void {
        if (
            onlyDirtyRows === false &&
            (searchWord == "" || searchWord === null) &&
            areaList.length == 0
        ) {
            // 変更行のみチェックも検索キーワードの指定もなかったら
            this.filteredData.splice(0, this.filteredData.length, ...this.data);
        } else if (onlyDirtyRows === false) {
            // 検索キーワードorエリアによる絞り込みの場合
            this.filteredData = [];
            this.data.forEach((d) => {
                if (this.searchWordHitCheck(searchWord, d) && this.areaListHitCheck(areaList, d)) {
                    this.filteredData.push(d);
                }
            });
        } else {
            // 検索キーワードに加えて変更行のみチェックも入ってたら
            this.filteredData = [];
            this.data.forEach((d) => {
                if (
                    this.searchWordHitCheck(searchWord, d) &&
                    this.areaListHitCheck(areaList, d) &&
                    !!d.dirtyFlag
                ) {
                    this.filteredData.push(d);
                }
            });
        }
    }
    /* 検索語が含まれているかチェック */
    private searchWordHitCheck(searchWord: string, datum: PlaceActionLinkData): boolean {
        if (searchWord == null || searchWord == "") {
            return true;
        }
        const keys = Object.keys(datum);
        for (const i in keys) {
            const key = keys[i];
            const value = datum[key];
            if (isBoolean(value)) {
                if (value.toString() === searchWord.toLowerCase()) {
                    return true;
                }
                continue;
            }
            if (isString(value)) {
                if (value.indexOf(searchWord) >= 0) {
                    return true;
                }
                continue;
            }
            if (isNumber(value)) {
                if (value.toString().indexOf(searchWord) >= 0) {
                    return true;
                }
                continue;
            }
        }
        return false;
    }
    /* データに対象areaが1つでも含まれているか */
    private areaListHitCheck(areaList: number[], datum: PlaceActionLinkData): boolean {
        if (areaList == null || areaList.length === 0) {
            return true;
        }
        for (const i in datum.areas) {
            if (areaList.includes(datum.areas[i])) {
                return true;
            }
        }
        return false;
    }

    /* 変更を反映する */
    async commitChange(
        poiGroupID: number,
        route: RouteLocationNormalized,
        d: PlaceActionLinkData
    ): Promise<string> {
        try {
            // 編集
            if (d.updateMask?.length > 0) {
                if (d.updateMask.includes("isPreferred") && !d.isPreferred) {
                    // Falseには更新できないのでskip(他のリンクの更新時に更新される)
                    return "";
                }
                const oplog = getOperationLogParams(route, "place-action-links-patch");
                const res = await api.patchPlaceActionLink(poiGroupID, d.poiID, oplog, {
                    updateMask: d.updateMask.join(","),
                    placeActionLink: {
                        name: d.name,
                        placeActionType: d.placeActionType,
                        uri: d.uri,
                        isPreferred: d.isPreferred,
                    },
                });
                this.replaceNewData(res?.data);
                return "";
            }
            // 削除
            if (d.deleteFlag) {
                const oplog = getOperationLogParams(route, "place-action-links-delete");
                const res = await api.deletePlaceActionLink(poiGroupID, d.poiID, oplog, d.name);
                this.replaceNewData(res?.data);
                return "";
            }
            // 新規作成
            const oplog = getOperationLogParams(route, "place-action-links-create");
            const res = await api.createPlaceActionLink(poiGroupID, d.poiID, oplog, {
                locationName: d.locationName,
                placeActionLink: {
                    placeActionType: d.placeActionType,
                    uri: d.uri,
                    isPreferred: d.isPreferred,
                },
            });
            this.replaceNewData(res?.data);
            return "";
        } catch (e) {
            return this.getErrorMessage(e as any);
        }
    }

    private replaceNewData(newData: DomainPlaceActionLinks): void {
        // fetchDataのうち、newDataのlocationNameが合致するデータを差し替えます
        const updateIndex = this.fetchData.findIndex(
            (d) => d.LocationName === newData?.LocationName
        );
        if (updateIndex !== -1) {
            this.fetchData[updateIndex] = newData;
        }
    }

    /* プレイスアクションリンクのエラーメッセージ */
    private getErrorMessage(e: {
        response: { data: { GMBError: { error: any }; message: string; errorMessage: any } };
        message: any;
    }): string {
        let errorMessage = "";
        if (e?.response?.data?.GMBError?.error?.code > 0) {
            // GBPからエラー情報が返ってきた
            errorMessage = getPlaceActionErrorMessage(e?.response?.data?.GMBError?.error);
        } else if (e?.response?.data?.message) {
            // API Gatewayのエラー
            errorMessage = e?.response?.data?.message ?? "想定外のエラー";
            errorMessage = errorMessage.replace(
                "Endpoint request timed out",
                "30秒経ってもサーバーから応答がありませんでした。<br/>お手数ですが画面を読み込み直して、更新に成功しているかご確認ください。"
            );
        } else {
            errorMessage =
                `時間を置いても同じエラーになる場合、お手数ですが担当者までお問い合わせください。<br/><br/>・${e?.message}<br/>` +
                `・${e?.response?.data?.errorMessage}`;
        }
        return errorMessage;
    }
}
