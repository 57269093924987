<template>
  <div>
    <v-card class="ma-3" max-width="600" :disabled="!companyLogin">
      <v-card-text>
        <p class="text-h4">サービス機能</p>
        <div class="text-body-2">
          サービス機能の確認、更新ができます。
          <br />
          <p></p>
          指定企業の設定されているサービス一覧を確認し、設定値を編集・更新できます。
          <br />
          企業一覧で特定の企業にログインしてからお使いください。
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn variant="elevated" color="primary" :to="serviceItemsLink">実行</v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="ma-3" max-width="600">
      <v-card-text>
        <p class="text-h4">質問と回答機能</p>
        <div class="text-body-2">質問と回答の確認、更新ができます。</div>
      </v-card-text>
      <v-card-actions>
        <v-btn variant="elevated" color="primary" :to="qandaLink">実行</v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="ma-3" max-width="600">
      <v-card-text>
        <p class="text-h4">多言語店舗名登録</p>
        <div class="text-body-2">
          一括で多言語での店舗名の登録ができます。
          <br />
          企業一覧で特定の企業にログインしてからお使いください。
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn variant="elevated" color="primary" :to="UpdateMultilingualInfoLink">実行</v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="ma-3" max-width="600">
      <v-card-text>
        <p class="text-h4">Sentry確認 ※開発者向け機能</p>
        <div class="text-body-2">
          Error を投げた場合の動作を確認できます。
          <br />
          （Sentryは連続して同じエラーが発生した場合は、2回目以降はダイアログを表示しません）
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn variant="elevated" color="primary" @click="throwError('エラーテスト1')">
          エラーテスト1
        </v-btn>
        <v-btn variant="elevated" color="primary" @click="throwError('エラーテスト2')">
          エラーテスト2
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative } from "vue-facing-decorator";
import { getter } from "@/storepinia/idxdb";

@Component({})
class Beta extends Vue {
  loading: boolean = false;
  company = getter().company;

  get companyLogin(): boolean {
    return this.company !== undefined && this.company.poiGroupID > 0;
  }

  get serviceItemsLink(): string {
    return `/companies/${this.company.poiGroupID}/service-items`;
  }

  get qandaLink(): string {
    return `/companies/${this.company.poiGroupID}/qanda`;
  }

  get UpdateMultilingualInfoLink(): string {
    return `/companies/${this.company.poiGroupID}/update-multilingual-info`;
  }

  throwError(msg: string) {
    throw Error(msg);
  }
}
export default toNative(Beta);
</script>

<style lang="scss" scoped>
div.pagination {
  ul.pagination-list {
    margin: 0;
    list-style: none;
  }
}
.content li + li {
  margin-top: 0 !important;
}
.modal.is-active {
  display: flex;
  z-index: var(--z-index-modal);
}
.error-message {
  color: red;
}
.iconPosition {
  padding-top: 5px;
  padding-left: 10px;
}
.buttonSection {
  text-align: right;
}
.control-display-checkbox {
  width: 220px;
  margin-left: auto;
  text-align: right;
  margin-right: 10px;
}
</style>
