import { Component, Vue, toNative } from "vue-facing-decorator";
import type { AxiosResponse, AxiosError } from "axios";
import type { ListGrid } from "cheetah-grid";

import type {
    EntitiesStoresResponse,
    EntitiesStore,
    EntitiesPutGMBLocationRequest,
    ControllersBatchGetLocationsOutput,
    MybusinessbusinessinformationAttribute,
    MybusinessbusinessinformationLocation,
    MybusinessbusinessinformationCategory,
    MybusinessbusinessinformationServiceItem,
    MybusinessbusinessinformationServiceType,
    MybusinessbusinessinformationListCategoriesResponse,
    EntitiesPutGMBLocationResponse,
} from "@/types/ls-api";
import { requiredAuth } from "@/helpers";
import { getGBPErrorMessage } from "@/helpers/error";
import { getOperationLogParams } from "@/routes/operation-log";
import type { GBPError } from "@/helpers/error";
import { getDashboardUrl } from "@/helpers/gmb";
import { useSnackbar } from "@/storepinia/snackbar";
import { getter } from "@/storepinia/idxdb";
import { currentTheme } from "@/components/shared/theme";
import type { CustomSortState } from "@/components/shared/cheetah-grid-shared";

// ヘッダの背景色定義
const HeaderBgColors = {
    // Primaryカテゴリの背景色
    BgPrimary: "#FF5733",
    // 追加カテゴリの背景色
    BgAdditional: "#EDA393",
    // structuredタイプの背景色
    BgStructured: "#ACF786",
    // freeタイプの背景色
    BgFree: "#E7F786",
    // 未定義の場合
    BgUndefined: undefined,
} as const;

// Union型で定義したHeaderBgColorsをenum的に使いたいための定義
type HeaderBgColors = (typeof HeaderBgColors)[keyof typeof HeaderBgColors];

type LoadingStatus = {
    completed: boolean;
    error?: any;
};

type gbpLocation = {
    store: EntitiesStore;
    attributes: MybusinessbusinessinformationAttribute[];
    location: MybusinessbusinessinformationLocation;
};

// cheetah Gridにセットするヘッダカラムのベース定義
interface HeaderItem {
    // 複数のSingleHeaderItemを持っている場合は trueを返す
    isMulti: boolean;
    // 格納されているSingleHeaderItemの数を返す
    length: number;

    // 表示名
    caption: string;
    // 背景色
    bgColor: string | undefined;

    // is_multi === false の場合は 自分自身のSingleHeaderItemを、 is_multi === true の場合は、複数格納されているSingleHeaderItemの先頭を返す
    single: SingleHeaderItem;
    // is_multi === false の場合は 配列に自分自身のSingleHeaderItemを、is_multi === true の場合は、複数格納されているすべてのSingleHeaderItemを返す
    headers: SingleHeaderItem[];
}

// poiID/店舗コード/店舗名で使用するシングルカラムヘッダ
class SingleHeaderItem implements HeaderItem {
    constructor(
        caption: string,
        field: any,
        disabled: any = undefined,
        bgColor: HeaderBgColors = HeaderBgColors.BgUndefined
    ) {
        this._caption = caption;
        this._field = field;
        this._disabled = disabled;
        this._bgColor = bgColor;
    }

    get bgColor(): string | undefined {
        return this._bgColor;
    }

    get isMulti(): boolean {
        return false;
    }

    get single(): SingleHeaderItem {
        return this;
    }

    get length(): number {
        return 1;
    }

    get headers(): SingleHeaderItem[] {
        return [this];
    }

    private _caption: string;
    private _field: any;
    private _disabled: any;
    private _bgColor: string | undefined;

    get caption(): string {
        return this._caption;
    }

    get field(): any {
        return this._field;
    }

    get disabled(): any {
        return this._disabled;
    }
}

// カテゴリ名が上段、ジョブ名（任意・固定）/価格/詳細が下段に表示されるマルチカラムヘッダ
class MultiHeaderItem implements HeaderItem {
    constructor(caption: string, bgColor: string | undefined, headers: SingleHeaderItem[]) {
        this._caption = caption;
        this._headers = headers;
        this._bgColor = bgColor;
    }

    private _caption: string;
    private _bgColor: string | undefined;
    private _headers: SingleHeaderItem[];

    // 上段:カテゴリ名
    get caption(): string {
        return this._caption;
    }

    get bgColor(): string | undefined {
        return this._bgColor;
    }

    get single(): SingleHeaderItem {
        return this._headers[0];
    }

    get isMulti(): boolean {
        return true;
    }

    get length(): number {
        return this._headers.length;
    }

    // 下段:ジョブ名・価格・詳細の格納されているリスト
    get headers(): SingleHeaderItem[] {
        return this._headers;
    }
}

class ServiceItemInfo {
    constructor(serviceItem: MybusinessbusinessinformationServiceItem);
    constructor(
        serviceItem: MybusinessbusinessinformationServiceItem,
        serviceType: MybusinessbusinessinformationServiceType
    );
    constructor(serviceItem: MybusinessbusinessinformationServiceItem, serviceType?: any) {
        if (serviceType == null) {
            this._serviceType = "free";
            this._name = serviceItem.freeFormServiceItem?.label?.displayName;
            this._description = serviceItem.freeFormServiceItem?.label?.description;
        } else {
            this._serviceType = "structured";
            this._name = (serviceType as MybusinessbusinessinformationServiceType).displayName;
            this._price = serviceItem.price?.units?.toString();
        }
        this._price = serviceItem.price?.units?.toString();
    }

    private _serviceType: string;
    private _name: string;
    private _price: string;
    private _description: string;

    get serviceType(): string {
        return this._serviceType;
    }

    get name(): string {
        return this._name;
    }

    get price(): string {
        return this._price;
    }

    get description(): string {
        return this._description;
    }
}

class ServiceItemsInfo {
    constructor(category: MybusinessbusinessinformationCategory) {
        this._category = category;
    }

    checkAppend(serviceItem: MybusinessbusinessinformationServiceItem): boolean {
        if (serviceItem.freeFormServiceItem?.category === this._category.name) {
            this._serviceItems.push(new ServiceItemInfo(serviceItem));
            return true;
        }
        const st = this._category.serviceTypes?.find(
            (st) => st.serviceTypeId === serviceItem.structuredServiceItem?.serviceTypeId
        );
        if (st) {
            this._serviceItems.push(new ServiceItemInfo(serviceItem, st));
            return true;
        }
        return false;
    }

    private _category: MybusinessbusinessinformationCategory;
    private _serviceItems: ServiceItemInfo[] = [];

    get categoryName(): string {
        return this._category?.displayName;
    }

    get ServiceItems(): ServiceItemInfo[] {
        return this._serviceItems;
    }
}

// location/storeを保持して、サービス情報を取得するためのクラス
// cheetahGridでは、このアイテムをレコードとして与えている
class ServiceItem {
    constructor(location: gbpLocation, areas: { [areaId: number]: string }) {
        this._checked = true;
        this._location = location;
        this._areas = location.store.areas?.map((a) => areas[a]).join(",");
    }

    // poiID/店舗コード/店舗名/サービス名/詳細が含まれる場合はtrueを返す
    filter(word: string): boolean {
        // poiIDは完全一致にしておく
        if (this.id.toString() === word) {
            return true;
        }
        // 店舗名以降は部分一致
        if (this.name?.includes(word)) {
            return true;
        }
        const location = this.location.location;
        if (location.storeCode?.includes(word)) {
            return true;
        }
        if (this._areas?.indexOf(word) >= 0) {
            return true;
        }
        if (location.serviceItems) {
            for (const serviceItem of location.serviceItems) {
                if (serviceItem.freeFormServiceItem) {
                    if (serviceItem.freeFormServiceItem?.label.displayName?.includes(word)) {
                        return true;
                    }
                    if (serviceItem.freeFormServiceItem?.label.description?.includes(word)) {
                        return true;
                    }
                }
                if (serviceItem.structuredServiceItem) {
                    if (serviceItem.structuredServiceItem.description?.includes(word)) {
                        return true;
                    }
                    // primaryカテゴリ名にwordが含まれるかをチェック
                    if (location.categories?.primaryCategory?.serviceTypes) {
                        const st = location.categories?.primaryCategory?.serviceTypes.find(
                            (st) =>
                                st.serviceTypeId ===
                                serviceItem.structuredServiceItem?.serviceTypeId
                        );
                        if (st && st.displayName?.includes(word)) {
                            return true;
                        }
                    }
                    // additionalカテゴリ名にwordが含まれるかをチェック
                    if (location.categories?.additionalCategories) {
                        for (const category of location.categories?.additionalCategories ?? []) {
                            if (category.serviceTypes) {
                                const st = category.serviceTypes.find(
                                    (st) =>
                                        st.serviceTypeId ===
                                        serviceItem.structuredServiceItem?.serviceTypeId
                                );
                                if (st && st.displayName?.includes(word)) {
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    get name(): string {
        return this._location.store.name || "";
    }

    get storeCode(): string {
        return this._location.store.gmbStoreCode || "";
    }

    get areas(): string {
        return this._areas || "";
    }

    private _checked: boolean = true;
    private _location: gbpLocation;
    private _areas: string = "";

    get location(): gbpLocation {
        return this._location;
    }

    get checked(): boolean {
        return this._checked;
    }
    set checked(value: boolean) {
        this._checked = value;
    }

    get id(): number {
        return this._location.store.poiID;
    }

    // 店舗にサービスが設定されていない場合にtrueを返す
    get serviceItemsEmpty(): boolean {
        const r =
            this._location.location.serviceItems === undefined ||
            this._location.location.serviceItems?.length === 0;
        console.log("serviceItemsEmpty", this.name, r);
        return r;
    }

    get primaryCategory(): ServiceItemsInfo {
        if (!this.location.location.categories?.primaryCategory) {
            return null;
        }
        const si = new ServiceItemsInfo(this.location.location.categories?.primaryCategory);
        for (const s of this._location.location?.serviceItems ?? []) {
            si.checkAppend(s);
        }
        return si.ServiceItems.length > 0 ? si : null;
    }

    get additionalCategories(): ServiceItemsInfo[] {
        if (!this.location.location.categories?.additionalCategories) {
            return [];
        }
        const sis: ServiceItemsInfo[] =
            this._location.location?.categories?.additionalCategories?.map(
                (c) => new ServiceItemsInfo(c)
            );
        if (sis?.length > 0) {
            for (const s of this._location.location?.serviceItems ?? []) {
                for (const si of sis) {
                    if (si.checkAppend(s)) {
                        break;
                    }
                }
            }
            return sis.filter((si) => si.ServiceItems.length > 0) || [];
        }
        return [];
    }

    // プライマリカテゴリ・追加カテゴリにcategoryNameが含まれている場合trueを返す
    private includeCategory(primary: boolean, categoryName: string): boolean {
        return (
            (primary &&
                this._location.location.categories?.primaryCategory.name === categoryName) ||
            this._location.location.categories?.additionalCategories?.findIndex(
                (item) => item.name === categoryName
            ) >= 0
        );
    }

    freeJobName(primary: boolean, categoryName: string, displayName: string): string {
        if (this.includeCategory(primary, categoryName)) {
            // サービス一覧を検索して、カテゴリ名/表示名が一致するfreeを探す
            const t = this._location.location.serviceItems?.find(
                (item) =>
                    item.freeFormServiceItem?.category == categoryName &&
                    item.freeFormServiceItem?.label?.displayName === displayName
            );
            if (t) {
                // 同一の名称がサービスに設定されている
                return t.freeFormServiceItem?.label?.displayName || "";
            }
        }
        return "";
    }

    freePrice(primary: boolean, categoryName: string, displayName: string): string {
        if (this.includeCategory(primary, categoryName)) {
            // サービス一覧を検索して、カテゴリ名/表示名が一致するfreeを探す
            const t = this._location.location.serviceItems?.find(
                (item) =>
                    item.freeFormServiceItem?.category == categoryName &&
                    item.freeFormServiceItem?.label?.displayName === displayName
            );
            if (t) {
                // 同一の名称がサービスに設定されている
                return t.price?.units?.toString() || "";
            }
        }
        return "";
    }

    freeDescription(primary: boolean, categoryName: string, displayName: string): string {
        if (this.includeCategory(primary, categoryName)) {
            // サービス一覧を検索して、カテゴリ名/表示名が一致するfreeを探す
            const t = this._location.location.serviceItems?.find(
                (item) =>
                    item.freeFormServiceItem?.category == categoryName &&
                    item.freeFormServiceItem?.label?.displayName === displayName
            );
            if (t) {
                // 同一の名称がサービスに設定されている
                return t.freeFormServiceItem?.label?.description || "";
            }
        }
        return "";
    }

    // プライマリカテゴリ・追加カテゴリにcategoryNameが含まれている場合Categoryを返す
    private findCategory(
        primary: boolean,
        categoryName: string
    ): MybusinessbusinessinformationCategory | undefined {
        if (primary) {
            if (this._location.location.categories?.primaryCategory?.name === categoryName) {
                return this._location.location.categories?.primaryCategory;
            }
            return undefined;
        }
        return this._location.location.categories?.additionalCategories?.find(
            (item) => item.name === categoryName
        );
    }

    jobName(primary: boolean, categoryName: string, jobTypeId: string): string {
        const c = this.findCategory(primary, categoryName);
        if (c) {
            const t = this._location.location.serviceItems?.find(
                (item) => item.structuredServiceItem?.serviceTypeId == jobTypeId
            );
            if (t) {
                const s = c.serviceTypes?.find((item) => item.serviceTypeId === jobTypeId);
                if (s) {
                    return s.displayName;
                }
            }
        }
        return "";
    }

    price(primary: boolean, categoryName: string, jobTypeId: string): string {
        const c = this.findCategory(primary, categoryName);
        if (c) {
            const t = this._location.location.serviceItems?.find(
                (item) => item.structuredServiceItem?.serviceTypeId == jobTypeId
            );
            if (t) {
                const s = c.serviceTypes?.find((item) => item.serviceTypeId === jobTypeId);
                if (s) {
                    return t.price?.units?.toString() || "";
                }
            }
        }
        return "";
    }

    description(primary: boolean, categoryName: string, jobTypeId: string): string {
        const c = this.findCategory(primary, categoryName);
        if (c) {
            const t = this._location.location.serviceItems?.find(
                (item) => item.structuredServiceItem?.serviceTypeId == jobTypeId
            );
            if (t) {
                const s = c.serviceTypes?.find((item) => item.serviceTypeId === jobTypeId);
                if (s) {
                    return t.structuredServiceItem?.description || "";
                }
            }
        }
        return "";
    }
}

@Component({})
class ServiceItems extends Vue {
    company = getter().company;
    areas = getter().areas;
    addSnackbarMessages = useSnackbar().addSnackbarMessages;

    // 読込中を表すフラグ
    loading: boolean = false;
    // 処理対象の企業ID
    poiGroupId: number;
    // APIから取得したLSに登録している店舗一覧情報
    private stores: EntitiesStore[];
    // APIから取得したGBPに登録されている企業内の全店舗情報
    private locations: ServiceItem[] = [];
    // 表示で使用するカラムリスト情報
    headerItems: HeaderItem[] = [];
    // 表示対象として保持しているレコード
    serviceItems: ServiceItem[] = [];
    // 表示名から検索するカテゴリ情報(表示名が同一のカテゴリが存在するので、名称に対してリストを持っている)
    private categoryMap: { [displayName: string]: MybusinessbusinessinformationCategory[] } = {};
    // グリッドに表示するフォントサイズ
    gridFontSize = 12.8;
    // グリッドに表示するフォント
    gridFont = `${this.gridFontSize}px sans-serif`;
    // 選択モードではtrue / コピーモードではfalse
    checkMode = true;
    // 検索キーワード
    searchWord: string = "";

    // サービス情報適用前の店舗情報確認ダイアログ表示用フラグ
    confirmationStore: boolean = false;
    // サービス情報適用前の店舗情報確認ダイアログ表示用店舗情報
    confirmationServiceItem: ServiceItem | undefined;

    // サービス情報上書き更新中の情報表示ダイアログ
    updateServiceItem = {
        show: false,
        title: "",
        percentage: 0,
        message: "",
        errorCause: [],
        errorCode: "",
        cancelButton: "",
        acceptButton: "",
    };
    // 汎用ダイアログ
    dialog = {
        show: false,
        percentage: 0,
        title: "",
        message: "",
        errorCause: "",
        errorCode: "",
        cancelButton: "",
        acceptButton: "",
        acceptAction: "",
    };

    // 検索キーワードを適用する処理
    searchStores(): void {
        try {
            const word = this.searchWord?.trim() || "";
            if (word !== "") {
                if (this.checkMode) {
                    // 全店舗を対象にpoiID/店舗コード/店舗名/サービス名/詳細が一致するものを検索する
                    this.serviceItems = this.locations.filter((l) => l.filter(word));
                } else {
                    // チェックされている店舗のみ対象にpoiID/店舗コード/店舗名/サービス名/詳細が一致するものを検索する
                    this.serviceItems = this.locations.filter((l) => l.checked && l.filter(word));
                }
            } else {
                if (this.checkMode) {
                    // 全店舗を表示する
                    this.serviceItems = this.locations;
                } else {
                    // チェックされている店舗のみ表示する
                    this.serviceItems = this.locations.filter((l) => l.checked);
                }
            }
            // 実際にデータをソートする
            this.sortRecord(this.customSortState.col, this.customSortState.order);
        } catch (e) {
            console.log(e);
        } finally {
            this.gridUpdate();
            this.loading = false;
        }
    }

    // cheetah-gridのカスタムテーマ設定（変更したセルの背景を水色に変えている）
    customTheme = {
        button: {
            color: "#ffffff",
            bgColor: currentTheme().vuetifyTheme.colors.primary,
        },
    };
    // cheetah-gridのスクロールバーだけ表示したいのでbodyのを消す
    mounted(): void {
        document.body.style.overflow = "hidden";
    }
    // ページ離脱時bodyのスクロールバーを元に戻しとく
    unmounted(): void {
        document.body.style.overflow = "initial";
    }
    async created(): Promise<void> {
        this.poiGroupId = this.company.poiGroupID;
        await this.fetch();
    }

    async loadCategories(): Promise<void> {
        const catres = await requiredAuth<MybusinessbusinessinformationListCategoriesResponse>(
            "get",
            `${import.meta.env.VITE_APP_API_BASE}v1/categories`
        );
        const categories: MybusinessbusinessinformationCategory[] =
            (typeof catres.data === "string" ? JSON.parse(catres.data) : catres.data).categories ??
            [];
        for (const c of categories) {
            if (this.categoryMap[c.displayName] === undefined) {
                this.categoryMap[c.displayName] = [c];
            } else {
                let f = true;
                for (const o of this.categoryMap[c.displayName]) {
                    if (o.name === c.name && o.serviceTypes?.length === c.serviceTypes?.length) {
                        f = false;
                        break;
                    }
                }
                if (f) {
                    this.categoryMap[c.displayName].push(c);
                }
            }
        }
    }

    async fetch(): Promise<void> {
        this.loading = true;
        this.stores = [];
        this.locations = [];
        try {
            // カテゴリ一覧を取得する
            await this.loadCategories();

            /** storesのgmbLocationIDをaccount/xxxxxを除いた状態で一覧で取得する */
            const locationNames: string[] = await this.getLocationNames();

            // batchGet同時呼び出し上限
            const concurrency = 10;
            // 一度の呼び出しの際に取得する件数。即ち concurrency * batchSize が並列接続によって一度に取ってくる最大数
            const batchSize = 100;
            const locationNamesChunks: string[][] = this.divide(locationNames, batchSize);
            const parallelSets: string[][][] = this.divide(locationNamesChunks, concurrency);

            for await (const refresh of this.getLocations(parallelSets)) {
                if (!refresh.completed && refresh.error) {
                    // batchGet取得失敗してerrorが返ってきた場合
                    const errorMessage: string =
                        refresh.error.response?.status === 404
                            ? "無効な店舗が見つかったため現在利用できません。ConnectOM側で対応が完了するまでお待ち下さい。"
                            : "サーバーが混み合っています。すこし時間を置いて再読み込みしてください。";

                    const originalErrorMessage = refresh.error.response?.data?.errorMessage ?? "";
                    console.log(originalErrorMessage);

                    this.addSnackbarMessages({
                        text: errorMessage,
                    });
                }
            }
        } finally {
            // 初回読み込み後はすべての店舗が表示対象
            this.serviceItems = this.locations;
            this.gridUpdate();
            this.loading = false;
        }
    }

    private primaryFreeGridUpdate(
        name: string,
        displayName: string,
        item: MybusinessbusinessinformationServiceItem,
        checkMap: { [categoryName: string]: string[] }
    ) {
        if (
            checkMap[name] === undefined ||
            !checkMap[name].includes(item.freeFormServiceItem.label?.displayName)
        ) {
            // free項目なので、名前をそのまま使用
            if (checkMap[name] === undefined) {
                // カテゴリ名が新規だった
                checkMap[name] = [];
            }
            // 設定されていなかったので新しく追加
            checkMap[name].push(item.freeFormServiceItem.label?.displayName);
            this.headerItems.push(
                new MultiHeaderItem("primary : " + displayName, HeaderBgColors.BgPrimary, [
                    new SingleHeaderItem(
                        "カスタム",
                        (rec: ServiceItem) =>
                            rec.freeJobName(
                                true,
                                name,
                                item.freeFormServiceItem.label?.displayName
                            ),
                        (rec: ServiceItem) =>
                            rec.freeJobName(
                                true,
                                name,
                                item.freeFormServiceItem.label?.displayName
                            ) === "",
                        HeaderBgColors.BgFree
                    ),
                    new SingleHeaderItem(
                        "価格",
                        (rec: ServiceItem) =>
                            rec.freePrice(true, name, item.freeFormServiceItem.label?.displayName),
                        (rec: ServiceItem) =>
                            rec.freePrice(
                                true,
                                name,
                                item.freeFormServiceItem.label?.displayName
                            ) === ""
                    ),
                    new SingleHeaderItem(
                        "詳細",
                        (rec: ServiceItem) =>
                            rec.freeDescription(
                                true,
                                name,
                                item.freeFormServiceItem.label?.displayName
                            ),
                        (rec: ServiceItem) =>
                            rec.freeDescription(
                                true,
                                name,
                                item.freeFormServiceItem.label?.displayName
                            ) === ""
                    ),
                ])
            );
        }
    }

    private primaryStructuredGridUpdate(
        name: string,
        category: MybusinessbusinessinformationCategory,
        item: MybusinessbusinessinformationServiceItem,
        checkMap: { [categoryName: string]: string[] }
    ) {
        const service = category.serviceTypes?.find(
            (c) => c.serviceTypeId === item.structuredServiceItem.serviceTypeId
        );
        if (service) {
            // サービスを見つけた
            if (checkMap[name] === undefined || !checkMap[name].includes(service.serviceTypeId)) {
                if (checkMap[name] === undefined) {
                    // カテゴリ名が新規だった
                    checkMap[name] = [];
                }
                // 設定されていなかったので新しく追加
                checkMap[name].push(service.serviceTypeId);
                this.headerItems.push(
                    new MultiHeaderItem(
                        "primary : " + category.displayName,
                        HeaderBgColors.BgPrimary,
                        [
                            new SingleHeaderItem(
                                service.displayName,
                                (rec: ServiceItem) =>
                                    rec.jobName(true, category.name, service.serviceTypeId),
                                (rec: ServiceItem) =>
                                    rec.jobName(true, category.name, service.serviceTypeId) === "",
                                HeaderBgColors.BgStructured
                            ),
                            new SingleHeaderItem(
                                "価格",
                                (rec: ServiceItem) =>
                                    rec.price(true, category.name, service.serviceTypeId),
                                (rec: ServiceItem) =>
                                    rec.price(true, category.name, service.serviceTypeId) === ""
                            ),
                            new SingleHeaderItem(
                                "詳細",
                                (rec: ServiceItem) =>
                                    rec.description(true, category.name, service.serviceTypeId),
                                (rec: ServiceItem) =>
                                    rec.description(true, category.name, service.serviceTypeId) ===
                                    ""
                            ),
                        ]
                    )
                );
            }
        }
    }

    private primaryGridUpdate() {
        // 表示対象のserviceItemsから設定されているすべてのサービス一覧を抽出して、カラムリストを作成する
        const checkMap: { [categoryName: string]: string[] } = {};
        // 表示対象の全店舗に設定されているプライマリカテゴリをチェックして、全てに設定されているカテゴリをカラムとして並べる
        for (const serviceItem of this.serviceItems) {
            // プライマリカテゴリ名を取得
            const name = serviceItem.location.location.categories?.primaryCategory?.name;
            if (name && serviceItem.location.location.serviceItems) {
                // サービス名
                for (const item of serviceItem.location.location.serviceItems) {
                    if (item.freeFormServiceItem) {
                        if (
                            serviceItem.location.location.categories?.primaryCategory?.name ===
                            item.freeFormServiceItem.category
                        ) {
                            const displayName =
                                serviceItem.location.location.categories?.primaryCategory
                                    ?.displayName;
                            this.primaryFreeGridUpdate(name, displayName, item, checkMap);
                        }
                    }
                    if (item.structuredServiceItem) {
                        this.primaryStructuredGridUpdate(
                            name,
                            serviceItem.location.location.categories?.primaryCategory,
                            item,
                            checkMap
                        );
                    }
                }
            }
        }
    }

    private additionalFreeGridUpdate(
        name: string,
        displayName: string,
        item: MybusinessbusinessinformationServiceItem,
        checkMap: { [categoryName: string]: string[] }
    ) {
        if (
            checkMap[name] === undefined ||
            !checkMap[name].includes(item.freeFormServiceItem.label?.displayName)
        ) {
            // free項目なので、名前をそのまま使用
            if (checkMap[name] === undefined) {
                // カテゴリ名が新規だった
                checkMap[name] = [];
            }
            // 設定されていなかったので新しく追加
            checkMap[name].push(item.freeFormServiceItem.label?.displayName);
            this.headerItems.push(
                new MultiHeaderItem("additional : " + displayName, HeaderBgColors.BgAdditional, [
                    new SingleHeaderItem(
                        "カスタム",
                        (rec: ServiceItem) =>
                            rec.freeJobName(
                                false,
                                name,
                                item.freeFormServiceItem.label?.displayName
                            ),
                        (rec: ServiceItem) =>
                            rec.freeJobName(
                                false,
                                name,
                                item.freeFormServiceItem.label?.displayName
                            ) === "",
                        HeaderBgColors.BgFree
                    ),
                    new SingleHeaderItem(
                        "価格",
                        (rec: ServiceItem) =>
                            rec.freePrice(false, name, item.freeFormServiceItem.label?.displayName),
                        (rec: ServiceItem) =>
                            rec.freePrice(
                                false,
                                name,
                                item.freeFormServiceItem.label?.displayName
                            ) === ""
                    ),
                    new SingleHeaderItem(
                        "詳細",
                        (rec: ServiceItem) =>
                            rec.freeDescription(
                                true,
                                name,
                                item.freeFormServiceItem.label?.displayName
                            ),
                        (rec: ServiceItem) =>
                            rec.freeDescription(
                                true,
                                name,
                                item.freeFormServiceItem.label?.displayName
                            ) === ""
                    ),
                ])
            );
        }
    }

    private additionalStructuredGridUpdate(
        name: string,
        category: MybusinessbusinessinformationCategory,
        item: MybusinessbusinessinformationServiceItem,
        checkMap: { [categoryName: string]: string[] }
    ) {
        const service = category.serviceTypes?.find(
            (c) => c.serviceTypeId === item.structuredServiceItem.serviceTypeId
        );
        if (service) {
            // サービスを見つけた
            if (checkMap[name] === undefined || !checkMap[name].includes(service.serviceTypeId)) {
                if (checkMap[name] === undefined) {
                    // カテゴリ名が新規だった
                    checkMap[name] = [];
                }
                // 設定されていなかったので新しく追加
                checkMap[name].push(service.serviceTypeId);
                this.headerItems.push(
                    new MultiHeaderItem(
                        "additional : " + category.displayName,
                        HeaderBgColors.BgAdditional,
                        [
                            new SingleHeaderItem(
                                service.displayName,
                                (rec: ServiceItem) =>
                                    rec.jobName(false, category.name, service.serviceTypeId),
                                (rec: ServiceItem) =>
                                    rec.jobName(false, category.name, service.serviceTypeId) === "",
                                HeaderBgColors.BgStructured
                            ),
                            new SingleHeaderItem(
                                "価格",
                                (rec: ServiceItem) =>
                                    rec.price(false, category.name, service.serviceTypeId),
                                (rec: ServiceItem) =>
                                    rec.price(false, category.name, service.serviceTypeId) === ""
                            ),
                            new SingleHeaderItem(
                                "詳細",
                                (rec: ServiceItem) =>
                                    rec.description(false, category.name, service.serviceTypeId),
                                (rec: ServiceItem) =>
                                    rec.description(false, category.name, service.serviceTypeId) ===
                                    ""
                            ),
                        ]
                    )
                );
            }
        }
    }
    private additionalGridUpdate() {
        // 表示対象のserviceItemsから設定されているすべてのサービス一覧を抽出して、カラムリストを作成する
        const checkMap: { [categoryName: string]: string[] } = {};
        // 表示対象の全店舗に設定されている追加カテゴリをチェックして、全てに設定されているカテゴリをカラムとして並べる
        for (const loc of this.serviceItems) {
            // 店舗に設定されているカテゴリ一覧(primary・additional含む)
            const categories = loc.location.location.categories?.additionalCategories;
            // additionalカテゴリを取り出して処理する
            // 店舗に設定されているサービス一覧(primary・additional含む)
            const serviceItems = loc.location.location.serviceItems;
            if (categories && serviceItems) {
                // カテゴリもサービス一覧も設定されている店舗が対象
                for (const serviceItem of serviceItems) {
                    if (serviceItem.freeFormServiceItem) {
                        // additionalカテゴリに含まれている同一カテゴリを検索
                        const category = categories.find(
                            (c) => c.name === serviceItem.freeFormServiceItem?.category
                        );
                        if (category) {
                            // free 任意文字列を設定したサービスの場合
                            this.additionalFreeGridUpdate(
                                category.name,
                                category.displayName,
                                serviceItem,
                                checkMap
                            );
                        }
                    }

                    if (serviceItem.structuredServiceItem) {
                        // additionalカテゴリに含まれている同一カテゴリを検索(カテゴリに一覧で設定されているサービスのserviceTypeIdが一致するものが含まれるカテゴリを検索)
                        const category = categories.find(
                            (c) =>
                                c.serviceTypes?.findIndex(
                                    (s) =>
                                        s.serviceTypeId ===
                                        serviceItem.structuredServiceItem?.serviceTypeId
                                ) >= 0
                        );
                        if (category) {
                            // structured 定義済みサービスを設定している場合
                            this.additionalStructuredGridUpdate(
                                category.name,
                                category,
                                serviceItem,
                                checkMap
                            );
                        }
                    }
                }
            }
        }
    }

    private gridUpdate() {
        this.loading = true;
        try {
            // 固定カラム
            this.headerItems = [
                new SingleHeaderItem("poiID", "id"),
                new SingleHeaderItem("店舗コード", "storeCode"),
                new SingleHeaderItem("店舗名", "name"),
                new SingleHeaderItem("グループ", "areas"),
            ];

            this.primaryGridUpdate();
            this.additionalGridUpdate();
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    }

    // 直前に行ったソート順と対象列の保存
    private customSortState: CustomSortState = {
        col: 0,
        order: null,
    };

    // cheetah-gridヘッダ部をクリックされたイベント
    sortColumn(order: string, col: number, grid: ListGrid<ServiceItem>): void {
        if (col !== this.customSortState.col) {
            // 直前のと違う列をソートしようとした場合はorderを初期化する
            this.customSortState.order = "desc";
            this.customSortState.col = col;
        } else {
            switch (this.customSortState.order) {
                case null:
                    this.customSortState.order = "desc";
                    break;
                case "desc":
                    this.customSortState.order = "asc";
                    break;
                case "asc":
                    this.customSortState.order = null;
            }
        }
        // ↑↓マーク制御
        grid.sortState.order = this.customSortState.order;
        // ソートを適用
        this.searchStores();
    }

    // クリックされたカラムでソート処理を実行
    private sortRecord(col: number, order: string | null = "asc"): void {
        let orderVal: number = 1;
        // カラムの並び順
        const keys: string[] = ["checked", "id", "storeCode", "name", "areas"];
        let key = keys[col];
        if (order === null) {
            // 昇りでも降りでもない場合は初期の並び順であるpoiIDの昇り順でのソートになる
            key = keys[1];
        } else if (order === "asc") {
            orderVal = -1;
        }
        this.serviceItems = this.serviceItems.sort((a, b) => {
            const aDash: string | number | boolean = a[key];
            const bDash: string | number | boolean = b[key];
            if (aDash > bDash) {
                return 1 * orderVal;
            } else {
                return -1 * orderVal;
            }
        });
    }

    copyServiceItems(rec: ServiceItem): void {
        if (rec.serviceItemsEmpty) {
            this.updateServiceItem.message =
                "サービスが設定されていない店舗はコピー元として選択できません";
            this.updateServiceItem.cancelButton = "閉じる";
            this.updateServiceItem.show = true;
            return;
        }
        console.log("copyServiceItems", rec);
        this.confirmationServiceItem = rec;
        this.confirmationStore = true;
    }

    async copyServiceItemExecute(): Promise<void> {
        console.log("copyServiceItemExecute");
        this.confirmationStore = false;
        this.updateServiceItem.title = "サービス情報の上書き中";
        this.updateServiceItem.percentage = 0;
        this.updateServiceItem.message = "";
        this.updateServiceItem.errorCode = "";
        this.updateServiceItem.errorCause = [];
        this.updateServiceItem.cancelButton = "";
        this.updateServiceItem.acceptButton = "";
        this.updateServiceItem.show = true;
        // 更新に失敗したエラーメッセージを格納する配列
        const failedMessages: string[] = [];
        // コピー元となる店舗情報
        const src = this.confirmationServiceItem;
        // コピー先の店舗一覧
        const targets = this.serviceItems.filter((t) => t.checked && t.id !== src.id);
        try {
            for (const { index, target } of targets.map((target, index) => ({ index, target }))) {
                try {
                    this.updateServiceItem.message = `${target.name} へ上書き中`;
                    // コピー元となるcategories/serviceItemsを作る
                    const n = { ...target.location };
                    n.location.categories = src.location.location.categories;
                    delete n.location.categories?.primaryCategory?.moreHoursTypes;
                    if (n.location.categories?.additionalCategories) {
                        for (const category of n.location.categories?.additionalCategories ?? []) {
                            delete category?.moreHoursTypes;
                        }
                    }
                    // 一旦サービスは空にする(categoriesとserviceItemsを同時に更新すると、エラーが発生する)
                    delete n.location.serviceItems;
                    var gmbLocPut: EntitiesPutGMBLocationRequest;
                    gmbLocPut = {
                        gmbLocation: {
                            location: n.location,
                            poiGroupID: n.store.poiGroupID,
                            poiID: n.store.poiID,
                        },
                        oldGmbLocation: {
                            location: target.location.location,
                            poiGroupID: target.location.store.poiGroupID,
                            poiID: target.location.store.poiID,
                        },
                        updateItems: ["serviceItems", "categories"],
                    };
                    const actionType = "put";
                    const params = {
                        ...getOperationLogParams(this.$route, actionType),
                        noStructuredUpdate: 1,
                    };
                    try {
                        const res = await requiredAuth<EntitiesPutGMBLocationResponse>(
                            "put",
                            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
                                n.store.poiGroupID
                            }/stores/${n.store.poiID}/gmbLocation`,
                            params,
                            gmbLocPut
                        );
                        console.log("put response", res);
                    } catch (e) {
                        console.error("put response except", e);
                        if (e as AxiosError) {
                            failedMessages.push(this.checkGMBError(e as AxiosError, target));
                        } else {
                            failedMessages.push(`${target.id} : ${target.name} : ${e}`);
                        }
                        // 次の店舗へ
                        continue;
                    }

                    // 改めてサービスをセットする
                    gmbLocPut.gmbLocation.location.serviceItems =
                        src.location.location.serviceItems;
                    gmbLocPut.updateItems = ["serviceItems"];
                    try {
                        const res = await requiredAuth<EntitiesPutGMBLocationResponse>(
                            "put",
                            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
                                n.store.poiGroupID
                            }/stores/${n.store.poiID}/gmbLocation`,
                            params,
                            gmbLocPut
                        );
                        // 更新内容でローカルのデータを更新する
                        target.location.location.serviceItems = res.data.location?.serviceItems;
                    } catch (e) {
                        console.error("put response except", e);
                        if (e as AxiosError) {
                            failedMessages.push(this.checkGMBError(e as AxiosError, target));
                        } else {
                            failedMessages.push(`${target.id} : ${target.name} : ${e}`);
                        }
                        // 次の店舗へ
                        continue;
                    }
                } finally {
                    // 進捗状況の更新
                    this.updateServiceItem.percentage = (index * 100) / targets.length;
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.updateServiceItem.percentage = 100;
            if (failedMessages.length > 0) {
                this.updateServiceItem.message = `${targets.length}店舗中、${failedMessages.length}店舗 更新に失敗しました`;
                this.updateServiceItem.errorCause = failedMessages;
                this.updateServiceItem.acceptButton = "OK";
            } else {
                this.updateServiceItem.message = `${targets.length}店舗 更新が完了しました`;
                this.updateServiceItem.acceptButton = "OK";
            }
            // グリッド内の表示を更新
            (this.$refs.cgrid as ListGrid<ServiceItem>).invalidate();
        }
    }

    private checkGMBError(e: AxiosError | undefined, serviceItem: ServiceItem): string {
        if (e !== undefined) {
            const gbp = e.response?.data?.GMBError?.error as GBPError;
            if (gbp !== undefined) {
                const dashboardUrl = getDashboardUrl(serviceItem.location.store.gmbLocationID);
                return (
                    `${serviceItem.id} : ${serviceItem.name} : ` +
                    getGBPErrorMessage(gbp, dashboardUrl)
                );
            }
        }
        return `${serviceItem.id} : ${serviceItem.name} : ${e.code} : 想定外のエラー`;
    }

    async updateServiceItemsAcceptMatter(): Promise<void> {
        this.updateServiceItem.show = false;
    }

    /** 指定されたサイズごとに配列を分割する */
    private divide<T>(array: T[], size: number): T[][] {
        if (size < 1) {
            size = 1;
        }
        const dividedArray: T[][] = [];
        while (array.length) {
            dividedArray.push(array.splice(0, size));
        }
        return dividedArray;
    }

    /** storesのgmbLocationIDをaccount/xxxxxを除いた状態で一覧で取得する */
    private async getLocationNames(): Promise<string[]> {
        const storesRes = await requiredAuth<EntitiesStoresResponse>(
            "get",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/stores`
        );
        this.stores = (storesRes?.data?.stores ?? []).filter((s) => s.enabled);
        this.stores.sort((a, b) => a.poiID - b.poiID);

        // 企業内の全店舗分のlocationNameを取得する
        return this.stores.map(
            (store) => store.gmbLocationID.match("(?<=accounts/[0-9]*/)(.*)")[0]
        );
    }

    private async *getLocations(parallelSets: string[][][]): AsyncGenerator<LoadingStatus> {
        // GBPアクセストークンの初期化
        const initRet: boolean = await this.initGBPAccessToken();
        if (!initRet) {
            console.error("gmb init error");
            yield { completed: false };
            return;
        }

        // 企業のGBPアカウント
        const accountName: string = this.company.gmbAccount;
        const url: string = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
            this.poiGroupId
        }/${accountName}/locations/batchGet`;
        for (const locationNamesChunks of parallelSets) {
            const status: Promise<LoadingStatus> = this.batchGet(
                accountName,
                url,
                locationNamesChunks
            );
            yield status;
        }
    }

    private async initGBPAccessToken(): Promise<boolean> {
        try {
            await requiredAuth<any>(
                "get",
                `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/gmbapiinit`
            );
            return true;
        } catch (error) {
            console.log(error);
            const e = error as AxiosError;
            if (e) {
                this.addSnackbarMessages({
                    text: `内部処理エラーが発生しました。システム管理者にお問い合わせください。 ${e.response?.status}`,
                });
            }
            return false;
        }
    }

    private async batchGet(
        account: string,
        url: string,
        locationNamesChunks: string[][]
    ): Promise<LoadingStatus> {
        const status: LoadingStatus = { completed: false };
        const batchGetPromises: Promise<AxiosResponse<ControllersBatchGetLocationsOutput>>[] = [];
        for (const locationNames of locationNamesChunks) {
            batchGetPromises.push(
                requiredAuth<ControllersBatchGetLocationsOutput>("post", url, null, {
                    names: locationNames,
                })
            );
        }

        await Promise.all(batchGetPromises)
            .then((message: any) => {
                let newLocations: ControllersBatchGetLocationsOutput["locations"] = [];
                for (const res of message) {
                    newLocations = newLocations.concat(res.data.locations);
                }
                for (const l of newLocations) {
                    const store = this.stores?.find((s) => {
                        if (s.gmbLocationID === `${account}/${l.location?.name}`) {
                            return true;
                        }
                    });
                    this.locations.push(
                        new ServiceItem(
                            {
                                store: store,
                                attributes: l.attributes,
                                location: l.location,
                            },
                            this.areas
                        )
                    );
                }
                status.completed = true;
            })
            .catch((error: any) => {
                console.error(error);
                status.error = error;
            });
        return status;
    }

    /** GBP店舗同期ダイアログ出す */
    showSyncGMB(): void {
        // ダイアログを表示
        this.dialog.show = true;
        this.dialog.title = "GBP店舗同期";
        this.dialog.errorCode = "";
        this.dialog.errorCause = "";
        this.dialog.acceptButton = "OK";
        this.dialog.cancelButton = "キャンセル";
        this.dialog.message =
            "GBP上の店舗情報をチェックしてトストア側に同期させる処理を手動実行します（毎日21時に起動する処理です）。GBP上にあってトストア側にない店舗があれば未登録状態で追加します。";
        this.dialog.percentage = 0;
        this.dialog.acceptAction = "syncGMB";
    }

    async acceptMatter(): Promise<void> {
        this.dialog.show = false;
        // acceptAction=true GBP店舗同期ダイアログのOKボタンによるイベント発火か否か
        switch (this.dialog.acceptAction) {
            case "syncGMB": {
                try {
                    await requiredAuth<any>(
                        "post",
                        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
                            this.poiGroupId
                        }/sync_gmb_stores`,
                        { noCache: 1 }
                    );
                    const message = "同期処理を開始しました。終了までしばらくお待ち下さい。";
                    this.addSnackbarMessages({
                        text: message,
                        color: "success",
                    });
                } catch (error) {
                    console.log("[Error] acceptMatter", (error as AxiosError).response);
                    this.dialog.show = true;
                    this.dialog.title = "GBP店舗同期エラー ";
                    this.dialog.errorCode = (error as AxiosError).response?.status.toString();
                    this.dialog.message =
                        "エラーが発生しました。お手数ですが担当者までお問い合わせください。";
                    this.dialog.errorCause =
                        (error as AxiosError).response?.data?.errorMessage ?? "想定外のエラー";
                    this.dialog.acceptAction = "";
                    this.dialog.acceptButton = "閉じる";
                    this.dialog.cancelButton = "";
                }
                break;
            }
        }
    }
}
export default toNative(ServiceItems);
