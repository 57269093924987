<template>
  <div>
    <date-picker v-model="from" :min="min" :max="max" required />
    &nbsp;〜&nbsp;
    <date-picker v-model="to" :min="min" :max="max" required />
  </div>
</template>

<script lang="ts">
import dayjs from "dayjs";
import DatePicker, { yyyymmdd } from "./DatePicker.vue";
import { defineComponent } from "vue";
import { useSnackbar } from "@/storepinia/snackbar";

export interface DateRange {
  from: string;
  to: string;
}

export default defineComponent({
  components: { DatePicker },
  props: {
    modelValue: { type: Object as () => DateRange, required: true },
    min: { type: String, default: "2000-01-01" },
    max: { type: String, default: "2121-12-31" },
    maxDuration: { type: Number, default: null },
  },
  emits: ["update:modelValue"],
  computed: {
    from: {
      get(): string {
        return this.modelValue.from;
      },
      set(val: string) {
        val = val || this.from;
        this.changed(val, this.to, true);
      },
    },
    to: {
      get(): string {
        return this.modelValue.to;
      },
      set(val: string) {
        val = val || this.to;
        this.changed(this.from, val, false);
      },
    },
  },
  methods: {
    changed(from: string, to: string, isFrom: boolean): void {
      // console.log("changed", from, to, isFrom);
      if (to < from) {
        if (isFrom) {
          to = from;
        } else {
          from = to;
        }
      }
      const dfrom = dayjs(from, yyyymmdd);
      const dto = dayjs(to, yyyymmdd);
      const duration = dto.diff(dfrom, "day");
      if (0 < this.maxDuration && this.maxDuration < duration) {
        if (isFrom) {
          to = dfrom.add(this.maxDuration, "day").format(yyyymmdd);
        } else {
          from = dto.add(-this.maxDuration, "day").format(yyyymmdd);
        }
        useSnackbar().addSnackbarMessages({
          text: `表示期間は最大${this.maxDuration + 1}日間までを指定してください`,
          color: "warning",
        });
      }
      this.$emit("update:modelValue", { from, to });
    },
  },
});
</script>
<style lang="scss" scoped></style>
