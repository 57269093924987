import { saveAs } from "file-saver";
import { requiredAuth, requiredAuthWithConfig } from "@/helpers";
import type { StorageReportFile } from "@/types/ls-api";
import type { DateParams, StoreFilter } from "@/components/root/contents/performance/performance";
export const api = { reportAsync, report, yahooReport };
/** レポートを同期で生成してダウンロードする */
async function generateReport(
    url: string,
    sheets: number[],
    storeFilter: StoreFilter,
    dateParams: DateParams
): Promise<void> {
    const params = makeParams(sheets, storeFilter, dateParams);
    const res = await requiredAuthWithConfig<Blob>({
        method: "get",
        url,
        params,
        responseType: "blob",
    });
    const disposition =
        res.headers["content-disposition"] || "attachment; filename*=utf-8''download.xlsx";
    const filename = getFileName(disposition) || "download.xlsx";
    saveAs(res.data, filename);
}
// performanceレポート
async function report(
    sheets: number[],
    poiGroupId: number,
    storeFilter: StoreFilter,
    dateParams: DateParams
): Promise<void> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/performance/report`;
    return generateReport(url, sheets, storeFilter, dateParams);
}
// yahooレポート
async function yahooReport(
    poiGroupId: number,
    storeFilter: StoreFilter,
    dateParams: DateParams
): Promise<void> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/performance/yahoo_report`;
    return generateReport(url, [], storeFilter, dateParams);
}
/** レポートを非同期で生成する */
async function reportAsync(
    sheets: number[],
    poiGroupId: number,
    storeFilter: StoreFilter,
    dateParams: DateParams
): Promise<StorageReportFile> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/performance/report/async`;
    const params = makeParams(sheets, storeFilter, dateParams);
    const res = await requiredAuth<StorageReportFile>("get", url, params);
    return res.data;
}

type Params = {
    sheets?: string;
    areaIDs?: string;
    poiID?: number;
    currentStartDate: string;
    currentEndDate: string;
    previousStartDate: string;
    previousEndDate: string;
};
function makeParams(sheets: number[], storeFilter: StoreFilter, dateParams: DateParams): Params {
    const areaIDs = storeFilter.areaIDs;
    const poiID = storeFilter.poiID;
    return {
        sheets: sheets && sheets.length > 0 ? sheets.sort().join(",") : undefined,
        areaIDs: areaIDs ? areaIDs : undefined,
        poiID: poiID ? poiID : undefined,
        currentStartDate: dateParams.currentStartDate,
        currentEndDate: dateParams.currentEndDate,
        previousStartDate: dateParams.previousStartDate,
        previousEndDate: dateParams.previousEndDate,
    };
}
/**
 * Content-Disposition ヘッダからファイル名を取り出す
 * https://stackoverflow.com/a/67994693
 */
function getFileName(disposition: string): string {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    let fileName: string = null;
    if (utf8FilenameRegex.test(disposition)) {
        fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
    } else {
        // prevent ReDos attacks by anchoring the ascii regex to string start and
        //  slicing off everything before 'filename='
        const filenameStart = disposition.toLowerCase().indexOf("filename=");
        if (filenameStart >= 0) {
            const partialDisposition = disposition.slice(filenameStart);
            const matches = asciiFilenameRegex.exec(partialDisposition);
            if (matches != null && matches[2]) {
                fileName = matches[2];
            }
        }
    }
    return fileName;
}
