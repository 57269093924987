import type { EntitiesV2GMBPostData } from "@/types/ls-api";
import type { GMBPostType } from "@/models/v2-file-selection";

export class PostGMBForm {
    postType: GMBPostType;
    title: string;
    detail?: string;
    button: string;
    buttonUrl: string;
    start?: string;
    end?: string;
    startTime?: string;
    endTime?: string;
    couponCode?: string;
    link?: string;
    termsOfService?: string;
    category?: string;
    hasPriceRange: boolean;
    price?: string;
    maxPrice?: string;
}

export class PostGMBData implements Partial<EntitiesV2GMBPostData> {
    info?: PostGMBInfo;
    event?: PostGMBEvent;
    benefits?: PostGMBBenefits;
    product?: PostGMBProduct;
    covid19?: PostGMBCovid19;
}

// 最新情報
export class PostGMBInfo {
    mode: string;
    title: string;
    gmbImages?: string[];
    button?: string;
    buttonUrl?: string;
}

// イベント
export class PostGMBEvent {
    mode: string;
    title: string;
    gmbImages?: string[];
    posting: string;
    start: string;
    end: string;
    startTime?: string;
    endTime?: string;
    details?: PostGMBEventDetail;
}

// イベント詳細
export class PostGMBEventDetail {
    detail: string;
    button: string;
    buttonUrl: string;
}

// 特典
export class PostGMBBenefits {
    mode: string;
    title: string;
    gmbImages?: string[];
    posting: string;
    start: string;
    end: string;
    // TODO: ~TimeはTM-5972 でGoの構造体を修正するときに削除してください
    startTime?: string;
    endTime?: string;
    details?: PostGMBBenefitsDetail;
}

// 特典詳細
export class PostGMBBenefitsDetail {
    detail: string;
    couponCode: string;
    link: string;
    termsOfService: string;
}

// 商品
export class PostGMBProduct {
    mode: string;
    title: string;
    gmbImages?: string[];
    explain?: string;
    category: string;
    categoryExplain?: string;
    priceType: string;
    price?: string;
    maxPrice?: string;
    button?: string;
    buttonUrl?: string;
}

// COVID-19の最新情報
export class PostGMBCovid19 {
    mode: string;
    title: string;
    gmbImages?: string[];
    button?: string;
    buttonUrl?: string;
}
