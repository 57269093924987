import * as Sentry from "@sentry/browser";
import axios from "axios";
import type { FeatureToggle } from "@/routes/FeatureToggle";
import FeatureToggleMessage from "@/components/shared/FeatureToggleMessage.vue";
import { Component, Vue, Watch, toNative } from "vue-facing-decorator";
import { captureAndThrow } from "@/helpers/error";
import type {
    ControllersLoginOutput,
    ControllersPasswordChangeOutput,
    ControllersForgotPasswordChangeInput,
    ControllersMFASecretInput,
    ControllersMFARegistInput,
} from "@/types/ls-api";
import wordDictionary from "@/word-dictionary";
import { getOperationLogParams } from "@/routes/operation-log";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { useSnackbar } from "@/storepinia/snackbar";
import SnackbarMessage from "@/components/shared/snackbar/snackbar-message.vue";
import { action, useIndexedDb } from "@/storepinia/idxdb";
import { currentTheme } from "../shared/theme";
import { useSs } from "@/storepinia/ss";

@Component({
    components: { FeatureToggleMessage, VueQrcode, SnackbarMessage },
})
class UserLogin extends Vue {
    setAuth = action().setAuth;
    setRoles = action().setRoles;
    setAreas = action().setAreas;
    setAreaStores = action().setAreaStores;
    setCompany = action().setCompany;
    setStores = action().setStores;
    setIsMobile = action().setIsMobile;
    addSnackbarMessages = useSnackbar().addSnackbarMessages;

    mode:
        | "LOGIN"
        | "CHALLENGE"
        | "SET_MFA"
        | "COMPANIES_SELECT"
        | "CHANGE_PASSWORD"
        | "RESET_PASSWORD1"
        | "RESET_PASSWORD2" = "LOGIN";
    dict = wordDictionary.login;
    email: string = "";
    password: string = "";
    oldPassword: string = "";
    newPassword1: string = "";
    newPassword2: string = "";
    verificationCode: string = "";
    message: string = "";
    isLoading: boolean = false;
    copyRight: string = currentTheme().copyRight;
    defaultCopyRight: string = currentTheme().copyRight;
    featureToggle: FeatureToggle;
    mfa: string = "";
    mfaSecret: string = "";
    challengeName: string = "";
    session: string = "";
    userIDforSRP: string = "";
    secretCode: string = "";
    qrCode: string = "";
    uuid: string = "";
    qrcodeOptions = {
        errorCorrectionLevel: "M",
        maskPattern: 0,
        margin: 10,
        scale: 2,
        width: 300,
        color: {
            dark: "#000000FF",
            light: "#FFFFFFFF",
        },
    };
    theme = currentTheme();
    showMFASecretCode: boolean = false;
    // 初回登録直後のMFAコード
    firstMFAcode = "";
    // 複数企業に所属している際のログイン結果を一時的に保存しておく
    authRes: ControllersLoginOutput;
    companySelect: number = 0;

    @Watch("mode")
    public onModeChange(): void {
        // 入力フォーム切り替わった際に自動でinputにフォーカス当てる
        this.$nextTick(() => {
            let inputForm: HTMLInputElement;
            switch (this.mode) {
                case "SET_MFA":
                case "CHALLENGE":
                    inputForm = this.$refs.mfa as HTMLInputElement;
                    break;
                case "LOGIN":
                case "RESET_PASSWORD1":
                    inputForm = this.$refs.email as HTMLInputElement;
                    break;
                case "RESET_PASSWORD2":
                    inputForm = this.$refs.verificationCode as HTMLInputElement;
                    break;
                case "CHANGE_PASSWORD":
                    inputForm = this.$refs.newPassword1 as HTMLInputElement;
                    break;
            }
            if (inputForm) {
                inputForm.focus();
            }
        });
    }

    created(): void {
        this.featureToggle = this.$route.meta.featureToggle as FeatureToggle;
    }

    mounted(): void {
        if (this.$route.query.timeout === "1") {
            this.addSnackbarMessages({
                text: "セッションがタイムアウトしました。ログインし直してください",
            });
        }
        const input = this.$refs.email as HTMLElement;
        if (input.focus) {
            input.focus();
        }
    }

    // ログイン処理とパスワード変更処理を行う。
    async emitLogin(): Promise<void> {
        if (this.email === "" || this.password === "") {
            this.errorToast(this.dict.emptyMessage);
            return;
        }
        this.isLoading = true;
        const authenticationData = {
            username: this.email.trim(),
            password: this.password,
        };

        const authRes: ControllersLoginOutput = await axios
            .post(`${import.meta.env.VITE_APP_API_BASE}v1/auth/login`, authenticationData, {
                params: getOperationLogParams(this.$route, "exec"),
            })
            .then((res) => res.data)
            .catch((e) => {
                // ログイン画面ならパスワード変更フォームを出す
                if (e.response.data.errorMessage.includes("NEW_PASSWORD_REQUIRED")) {
                    this.oldPassword = JSON.parse(JSON.stringify(this.password));
                    this.mode = "CHANGE_PASSWORD";
                } else {
                    this.error(e, this.dict.loginFailedMessage);
                }
                this.isLoading = false;
                this.deleteInputPassword();
                return;
            });

        if (authRes == null) {
            this.isLoading = false;
            return;
        }

        // レスポンスのerrorMsgでも判定する？
        if (authRes.errorMsg === "") {
            if (authRes.challengeName === "NEW_PASSWORD_REQUIRED") {
                this.oldPassword = JSON.parse(JSON.stringify(this.password));
                this.mode = "CHANGE_PASSWORD";
                this.isLoading = false;
                this.deleteInputPassword();
                return;
            } else if (authRes.challengeName === "SOFTWARE_TOKEN_MFA") {
                // MFA認証が必要
                this.challengeName = authRes.challengeName;
                this.session = authRes.session;
                this.userIDforSRP = authRes.userIDforSRP;
                this.mode = "CHALLENGE";
                this.isLoading = false;
                return;
            } else if (authRes.challengeName === "REQUEST_MFA_SETTING") {
                // MFA設定が必須
                // 一旦認証情報を設定
                await this.setAuth(authRes);
                // MFA設定時に必要なuuidを記録しておく
                this.uuid = authRes.user?.uuID;
                // MFA Secret code 取得を実行
                const req: ControllersMFASecretInput = {
                    accessToken: authRes.accessToken,
                };
                const res = await axios.post(
                    `${import.meta.env.VITE_APP_API_BASE}v1/auth/mfaSecret`,
                    req,
                    {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            Authorization: `Bearer ${authRes.idToken}`,
                        },
                    }
                );
                // MFAにQRCodeで登録させるための文字列生成
                this.qrCode = decodeURI(res.data.qrCode);
                // QRCodeを読み込めない場合のシークレットコード表示用
                this.secretCode = res.data.secretCode;
                this.mode = "SET_MFA";
                this.isLoading = false;
                return;
            } else if (authRes.companies?.length > 0) {
                // 複数企業に所属しているので、企業選択を表示させる
                this.setSelectCompanies(authRes);
                this.mode = "COMPANIES_SELECT";
                this.isLoading = false;
            } else {
                // ログイン成功
                await this.initIndexedDB();
                await this.userFound(authRes).catch(() => (this.isLoading = false));
            }
        } else {
            captureAndThrow(`想定外のログインエラーです。`, new Error(authRes.errorMsg));
            this.isLoading = false;
        }
    }

    async setSelectCompanies(authRes: ControllersLoginOutput): Promise<void> {
        let isAdmin = false;
        authRes.companies.forEach((company) => {
            if (company.poiGroupID === undefined) {
                company.poiGroupID = 0;
            }
            if (company.poiGroupID === 0) {
                isAdmin = true;
                authRes.company = company;
            }
        });
        authRes.users.forEach((user) => {
            if (user.poiGroupID === undefined) {
                user.poiGroupID = 0;
            }
            if (user.poiGroupID === 0) {
                isAdmin = true;
                authRes.user = user;
            }
        });
        if (isAdmin) {
            // poiGroupID=0に所属しているので、ログインさせる
            await this.initIndexedDB();
            await this.userFound(authRes).catch(() => (this.isLoading = false));
        } else {
            // 企業選択に必要な設定
            this.companySelect = authRes.companies[0].poiGroupID;
            this.authRes = authRes;
        }
    }

    async selectCompany(): Promise<void> {
        this.isLoading = true;
        const company = this.authRes.companies.find((res) => res.poiGroupID === this.companySelect);
        const user = this.authRes.users.find((res) => res.poiGroupID === this.companySelect);
        this.authRes.company = company;
        this.authRes.user = user;
        await this.initIndexedDB();
        await this.userFound(this.authRes).catch(() => (this.isLoading = false));
    }

    async emitMFA(): Promise<void> {
        if (this.mfa === "") {
            this.errorToast(this.dict.emptyMFAMessage);
            return;
        }
        // MFA認証登録直後にそのコードをそのままもう一度入力した場合はエラーメッセージ出してコード変わるの待ってもらう
        if (this.mfa === this.firstMFAcode) {
            this.errorToast(this.dict.sameAsTheFirstMfaMessage);
            return;
        }
        this.isLoading = true;
        const authenticationData = {
            challengeName: this.challengeName,
            session: this.session,
            userIDforSRP: this.userIDforSRP,
            softwareTokenMFACode: this.mfa,
        };
        const authRes: ControllersLoginOutput = await axios
            .post(`${import.meta.env.VITE_APP_API_BASE}v1/auth/challenge`, authenticationData, {
                params: getOperationLogParams(this.$route, "exec"),
            })
            .then((res) => res.data)
            .catch((e) => {
                this.error(e, this.dict.loginFailedMessage, true);
                this.mfa = "";
                this.isLoading = false;
                return;
            });
        if (authRes == null) {
            this.isLoading = false;
            return;
        }

        // レスポンスのerrorMsgでも判定する？
        if (authRes.errorMsg === "") {
            if (authRes.companies?.length > 0) {
                // 複数企業に所属しているので、企業選択を表示させる
                this.setSelectCompanies(authRes);
                this.mode = "COMPANIES_SELECT";
                this.isLoading = false;
            } else {
                // ログイン成功
                await this.initIndexedDB();
                await this.userFound(authRes).catch(() => (this.isLoading = false));
            }
        } else {
            captureAndThrow(`想定外のログインエラーです。`, new Error(authRes.errorMsg));
            this.isLoading = false;
        }
    }

    async setMFA(): Promise<void> {
        if (this.mfa === "") {
            this.errorToast(this.dict.emptySetMFAMessage);
            return;
        }
        this.isLoading = true;
        const req: ControllersMFARegistInput = {
            accessToken: sessionStorage.AccessToken,
            softwareTokenMFACode: this.mfa,
            uuID: this.uuid,
        };
        const client = axios.create();
        client.defaults.validateStatus = () => true;
        try {
            const res = await client.post(
                `${import.meta.env.VITE_APP_API_BASE}v1/auth/mfaRegist`,
                req,
                {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        authorization: `Bearer ${sessionStorage.IdToken}`,
                    },
                }
            );
            switch (res.status) {
                case 200:
                    // 正常に完了したので、再度ログインしてもらうことにする
                    this.addSnackbarMessages({
                        text: this.dict.mfaRegistSuccessMessage,
                        color: "success",
                    });
                    this.firstMFAcode = this.mfa;
                    this.showLogin();
                    break;
                case 400:
                    // 誤ったコードを入力した
                    this.errorToast(this.dict.mfaRegistErrorMessage);
                    this.isLoading = false;
                    break;
                default:
                    // エラー表示
                    this.errorToast(this.dict.mfaRegistUnknownErrorMessage);
                    this.isLoading = false;
            }
        } catch (e) {
            console.log(e);
            // エラー表示
            this.errorToast(this.dict.mfaRegistUnknownErrorMessage);
            this.isLoading = false;
        }
    }

    /** ログイン時にIndexedDB内の幾つかの内容を初期化する */
    private async initIndexedDB() {
        useIndexedDb().clear();
    }

    // ログイン成功時の処理
    async userFound(auth: ControllersLoginOutput): Promise<void> {
        try {
            Sentry.configureScope((scope) => {
                scope.setUser({ id: auth.user.uuID });
                scope.setTag("poiGroupId", auth.user.poiGroupID?.toString() ?? "");
                scope.setTag(
                    "isAdmin",
                    auth.user.uuID != null &&
                        (auth.user.poiGroupID == null || auth.user.poiGroupID === 0)
                        ? "true"
                        : "false"
                );
            });

            if (auth.user.poiGroupID == null) {
                auth.user.poiGroupID = 0;
            }
            if (auth.company.poiGroupID == null) {
                auth.company.poiGroupID = 0;
            }

            // 通常: ログインユーザーの所属する企業
            let poiGroupId = auth.user.poiGroupID;
            await Promise.all([this.setAuth(auth), this.setRoles(poiGroupId)]);

            // 管理者: URL直打ちした場合に対象企業にログイン
            if (this.$route.query.redirect != null && poiGroupId === 0) {
                const redirectURL = this.$route.query.redirect.toString();
                const m = redirectURL.match(/companies\/([0-9]+)\/.+/);
                if (m != null) {
                    poiGroupId = parseInt(m[1], 10);
                    await this.setCompany(poiGroupId);
                }
            }

            // パソコン(PC) or スマートフォン(SP)の判定
            // iPhone, Android をスマートフォンと判定（Samsungなども含まれる）、Mobile でタブレットを除外
            const isMobile = /iPhone|Android.+Mobile/.test(navigator.userAgent);

            await Promise.all([
                this.setAreas(poiGroupId),
                this.setAreaStores(poiGroupId),
                this.setStores(poiGroupId),
                this.setIsMobile(isMobile),
            ]);

            // pinia の state を更新
            if (auth.user?.stores && auth.user.stores.length > 0) {
                useSs().$state.selectedStore = auth.user.stores[0];
            }

            // リダイレクト元がなければ、管理者かどうかでリダイレクト先を決定
            if (this.$route.query.redirect != null) {
                const redirectedUrl = this.$route.query.redirect.toString();
                const url = new URL("http://" + redirectedUrl);
                const params = new URLSearchParams(url.search);
                const queryParams = {};
                for (const [key, value] of params.entries()) {
                    queryParams[key] = value;
                }
                this.$router.push({ path: redirectedUrl, query: queryParams });
            } else if (auth.user.poiGroupID === 0) {
                this.$router.push({ name: "AdminCompanies" });
            } else if (isMobile) {
                // SPの場合は、投稿画面に遷移
                this.$router.push({
                    name: "V2Posts",
                    params: { poiGroupId: String(auth.user.poiGroupID) },
                });
            } else {
                // PCの場合は、パフォーマンス画面に遷移
                this.$router.push({
                    name: "Performance",
                    params: { poiGroupId: String(auth.user.poiGroupID) },
                });
            }
        } catch (e) {
            captureAndThrow("ログインに失敗しました", e);
        }
    }

    // ログイン画面を表示する
    async showLogin(): Promise<void> {
        this.deleteInputPassword();
        this.mode = "LOGIN";
        this.isLoading = false;
    }

    // 初回ログイン時のパスワード変更処理
    async passwordChange(): Promise<void> {
        try {
            this.isLoading = true;
            if (
                this.newPassword1 === "" ||
                this.newPassword2 === "" ||
                this.newPassword1 !== this.newPassword2
            ) {
                this.errorToast(this.dict.duplicateMessage);
                return;
            }
            const passwordChangeData = {
                username: this.email,
                oldPassword: JSON.parse(JSON.stringify(this.oldPassword)),
                newPassword: this.newPassword1,
            };
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const changeRes: ControllersPasswordChangeOutput = (
                await axios.post(
                    `${import.meta.env.VITE_APP_API_BASE}v1/auth/passwordChange`,
                    passwordChangeData
                )
            ).data;
            this.mode = "LOGIN";

            this.addSnackbarMessages({
                text: this.dict.changeSuccessMessage,
                color: "success",
            });
        } catch (e) {
            this.error(e);
        } finally {
            this.deleteInputPassword();
            this.isLoading = false;
        }
    }

    // パスワードリセット画面を表示する
    async showResetPassword1(): Promise<void> {
        this.deleteInputPassword();
        this.mode = "RESET_PASSWORD1";
        this.isLoading = false;
    }

    // セキュリティコード入力画面を表示する
    async showResetPassword2(): Promise<void> {
        this.deleteInputPassword();
        this.mode = "RESET_PASSWORD2";
        this.isLoading = false;
    }

    // セキュリティコード送信依頼(forgot)
    async sendVerificationCode(): Promise<void> {
        if (this.mode !== "RESET_PASSWORD1") {
            throw new Error();
        }
        if (this.email === "") {
            this.errorToast(this.dict.emptyEmailMessage);
            return;
        }
        try {
            this.isLoading = true;
            const mailAddress = { username: this.email };
            await axios.post(`${import.meta.env.VITE_APP_API_BASE}v1/auth/forgot`, mailAddress);
            this.deleteInputPassword();
            this.mode = "RESET_PASSWORD2";
            this.addSnackbarMessages({
                text: this.dict.sentVerificationCodeMessage,
                color: "success",
            });
        } catch (e) {
            this.error(e, this.dict.failedVerificationCodeMessage);
        } finally {
            this.isLoading = false;
        }
    }

    // セキュリティコードを使ってパスワードをリセットする。
    async resetPassword(): Promise<void> {
        if (this.mode !== "RESET_PASSWORD2") {
            throw new Error();
        }
        if (/^.+$/.test(this.verificationCode) === false) {
            this.errorToast(this.dict.emptyVerificationCodeMessage);
            return;
        } else if (this.verificationCode.length !== 6) {
            this.errorToast(this.dict.verificationCodeLengthMessage);
            return;
        }
        if (
            this.newPassword1 === "" ||
            this.newPassword2 === "" ||
            this.newPassword1 !== this.newPassword2
        ) {
            this.errorToast(this.dict.duplicateMessage);
            return;
        }
        try {
            this.isLoading = true;
            const forgotChangeRequest: ControllersForgotPasswordChangeInput = {
                password: this.newPassword1,
                securityCode: this.verificationCode,
                username: this.email,
            };
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const res = await axios.post(
                `${import.meta.env.VITE_APP_API_BASE}v1/auth/forgotChange`,
                forgotChangeRequest
            );
            this.mode = "LOGIN";
            this.addSnackbarMessages({
                text: this.dict.changeSuccessMessage,
                color: "success",
            });
        } catch (e) {
            this.error(e);
        } finally {
            this.deleteInputPassword();
            this.isLoading = false;
        }
    }

    deleteInputPassword(): void {
        this.password = "";
        this.newPassword1 = "";
        this.newPassword2 = "";
        this.verificationCode = "";
        this.mfa = "";
        this.session = "";
        this.userIDforSRP = "";
        this.showMFASecretCode = false;
        this.mfaSecret = "";
        this.qrCode = "";
        this.companySelect = 0;
    }

    // エラーメッセージ表示
    error(e: any, generalMessage: string = null, is_mfa: boolean = false): void {
        const error = e?.response?.data?.errorMessage;
        if (error == null) {
            this.errorToast(this.dict.timeoutMessage);
        } else if (error.includes("NotAuthorizedException")) {
            if (error.includes("User password cannot be reset in the current state")) {
                this.errorToast(this.dict.userNotAuthorizedMessage);
            } else if (error.includes("Password attempts exceeded")) {
                this.errorToast(this.dict.attemptsExceededMessage);
            } else {
                this.errorToast(this.dict.notFoundMessage);
            }
        } else if (error.includes("LimitExceededException")) {
            this.errorToast(this.dict.limitExceedMessage);
        } else if (error.includes("ExpiredCodeException")) {
            this.errorToast(this.dict.codeExpiredMessage);
        } else if (error.includes("InvalidParameter")) {
            if (is_mfa) {
                this.errorToast(this.dict.invalidMFAMessage);
            } else {
                this.errorToast(this.dict.invalidPasswordMessage);
            }
        } else if (error.includes("InvalidPasswordException")) {
            this.errorToast(this.dict.invalidPasswordMessage);
        } else if (error.includes("CodeMismatchException")) {
            this.errorToast(this.dict.codeMismatchMessage);
        } else {
            this.errorToast((generalMessage ?? "エラーが発生しました。") + error);
        }
    }

    errorToast(message: string): void {
        this.addSnackbarMessages({
            text: message,
            color: "danger",
            options: { top: true },
        });
    }

    // デプロイモードを表示（開発者用）
    async modeCheck(): Promise<void> {
        this.isLoading = true;
        if (this.copyRight === this.defaultCopyRight) {
            const mode = await axios
                .get(`${import.meta.env.VITE_APP_API_BASE}v1/auth/mode`)
                .then((res) => res.data);
            this.copyRight = `Mode: ${JSON.stringify(mode)}`;
        } else {
            this.copyRight = this.defaultCopyRight;
        }
        this.isLoading = false;
    }
}
export default toNative(UserLogin);
