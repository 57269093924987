<template>
  <!-- 返信対象のクチコミ一覧 -->
  <p v-show="replyReviewsExists" class="description">以下のコメントに対して返信されます</p>
  <div class="inner">
    <v-card v-for="review in repliedReviews" :key="review" class="comment rounded-lg">
      <v-card-text>{{ review }}</v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";

export default defineComponent({
  components: {},
  props: {
    reviews: { type: Array as PropType<Array<string>>, default: () => [] as string[] },
  },
  emits: ["complete", "close"],
  data: () => {
    return {};
  },
  computed: {
    repliedReviews(): string[] {
      return this.reviews?.filter((r: string) => r?.length > 0);
    },
    replyReviewsExists(): boolean {
      return this.repliedReviews?.length > 0;
    },
  },
  watch: {},
  created(): void {},
  mounted(): void {},
  methods: {},
});
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

.description {
  font-size: 0.8rem;
  color: #333;
  padding: 0.5rem;
  + .inner {
    padding: 0.25rem;
    background-color: #f8f8f8;
    border-radius: 5px;
  }
}

.comment {
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  div {
    font-size: 0.9rem;
    color: hsl(0, 0%, 10%);
    line-height: 1.5;
    background-color: white;
  }
}
</style>
