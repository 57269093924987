import {
    myAttributeRenderer,
    myRenderer,
} from "@/components/root/contents/stores/spread-form/table";
import { REGEX_MULTI_URL_OR_BLANK, REGEX_URL_OR_BLANK } from "@/helpers/validator";

import type {
    MybusinessbusinessinformationAttribute,
    MybusinessbusinessinformationAttributeMetadata,
} from "@/types/ls-api";
import _ from "lodash";
type AttributeMetadata = MybusinessbusinessinformationAttributeMetadata;

export interface AttributeColumn {
    title: string;
    data: string;
    readOnly: boolean;
    type: string;
    validator?: any;
    editor?: string; // selectのときに設定する
    selectOptions?: string[]; // selectのときに設定する
    renderer?: any;
    attributeId: string; // attributes/url_facebook の形式
    valueType: string;
    enumValue?: string;
    width?: string;
    className?: string;
}
export function convertMetadataToAttributeColumns(am: AttributeMetadata): AttributeColumn[] {
    if (am.valueType === "BOOL") {
        return [
            {
                title: `${am.groupDisplayName} ${am.displayName}`,
                data: `attributes.${am.parent}`,
                readOnly: false,
                type: "text",
                validator: /^[ox]?$/,
                renderer: myAttributeRenderer,
                attributeId: am.parent,
                valueType: am.valueType,
                width: "32px",
            },
        ];
    }
    if (am.valueType === "ENUM") {
        return [
            {
                title: `${am.groupDisplayName} ${am.displayName}`,
                data: `attributes.${am.parent}`,
                readOnly: false,
                type: "text",
                editor: "select",
                selectOptions: ["", ...(am.valueMetadata?.map((vm) => vm.value as string) ?? [])],
                renderer: myRenderer,
                attributeId: am.parent,
                valueType: am.valueType,
                width: "128px",
            },
        ];
    }
    if (am.valueType === "REPEATED_ENUM") {
        return am.valueMetadata?.map((vm) => {
            return {
                title: `${am.groupDisplayName} ${am.displayName} ${vm.displayName}`,
                data: `attributes.${am.parent}-${vm.value}`,
                readOnly: false,
                type: "text",
                validator: /^[ox]?$/,
                renderer: myAttributeRenderer,
                attributeId: am.parent,
                valueType: am.valueType,
                enumValue: vm.value as string,
                width: "32px",
            };
        });
    }
    // URL
    const validator = am.repeatable ? REGEX_MULTI_URL_OR_BLANK : REGEX_URL_OR_BLANK;
    return [
        {
            title: `${am.groupDisplayName} ${am.displayName}`,
            data: `attributes.${am.parent}`,
            readOnly: false,
            type: "text",
            validator,
            renderer: myAttributeRenderer,
            attributeId: am.parent,
            valueType: am.valueType,
        },
    ];
}
// 属性に入っているが機能していないものを非表示にする
// note: locations.placeActionLinksで設定できるものは非表示にしている
const hiddenAttributes = [
    "attributes.attributes/url_appointment", // 予約リンク
    "attributes.attributes/url_order_ahead", // 事前注文リンク
    "attributes.attributes/url_reservations", // 予約リンク
    "attributes.attributes/url_shop_online", // 注文リンク
];
export function convertMetadatasToAttributeColumns(ams: AttributeMetadata[]): AttributeColumn[] {
    const acs: AttributeColumn[] = [];
    ams.forEach((am) =>
        convertMetadataToAttributeColumns(am).forEach((ac) => {
            if (!hiddenAttributes.includes(ac.data)) {
                acs.push(ac);
            }
        })
    );
    return acs;
}
export const initialColumns: AttributeColumn[] = [
    {
        title: "店舗ID",
        data: "poiID",
        type: "numeric",
        readOnly: true,
        attributeId: "",
        valueType: "",
        className: "rowheader",
    },
    {
        title: "店舗コード",
        data: "storeCode",
        type: "text",
        readOnly: true,
        attributeId: "",
        valueType: "",
    },
    {
        title: "ビジネス名",
        data: "title",
        type: "text",
        readOnly: true,
        attributeId: "",
        valueType: "",
    },
];

/**
 * AttributeColumn[] を data でユニークにして title の降順にソートする
 * title の重複するものは title に attributeId を加える
 */
export function uniqueAndSort(acs: AttributeColumn[]): AttributeColumn[] {
    const map: { [data: string]: AttributeColumn } = {};
    for (const ac of acs) {
        map[ac.data] = ac;
    }
    acs = Object.values(map);
    acs.sort((a, b) => {
        if (a.title > b.title) {
            return 1;
        }
        if (a.title < b.title) {
            return -1;
        }
        return 0;
    });
    // title の重複するものは title に attributeId を加える
    const dupTitles = [];
    for (var i = 1; i < acs.length; i++) {
        if (acs[i - 1].title === acs[i].title) {
            dupTitles.push(acs[i].title);
        }
    }
    for (const ac of acs) {
        if (dupTitles.includes(ac.title)) {
            ac.title = ac.title + " (" + ac.attributeId + ")";
        }
    }
    return acs;
}
/**
 * Attributes[] の更新した項目名の配列を返す
 */
export function diffAttributes(
    aas: MybusinessbusinessinformationAttribute[],
    bas: MybusinessbusinessinformationAttribute[]
): string[] {
    aas = aas ?? [];
    bas = bas ?? [];
    const res: string[] = [];
    const am: { [name: string]: MybusinessbusinessinformationAttribute } = {};
    const bm: { [name: string]: MybusinessbusinessinformationAttribute } = {};
    aas.forEach((a) => (am[a.name] = a));
    bas.forEach((b) => (bm[b.name] = b));
    aas.forEach((a) => {
        const b = bm[a.name];
        if (!b) {
            res.push(a.name);
        } else if (_.isEqual(a, b) === false) {
            res.push(a.name);
        }
    });
    bas.forEach((b) => {
        const a = am[b.name];
        if (!a) {
            res.push(b.name);
        }
    });
    res.sort();
    return res;
}
