<template>
  <v-dialog v-model="dialogVisible" persistent width="400">
    <v-card>
      <v-card-title class="text-h5 bg-grey-lighten-2">投稿を保存</v-card-title>
      <v-card-text>
        <p>現在の投稿内容を一時保存しますか？</p>
        <p v-if="isEdit">登録済みの投稿予約・削除予約は取り消されます。</p>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn variant="outlined" class="cancel-button mr-3" @click.stop="dialogVisible = false">
          キャンセル
        </v-btn>
        <v-btn color="primary" variant="flat" class="px-4" @click.prevent="$emit('save-post')">
          保存する
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: { type: Boolean, required: true },
    isEdit: { type: Boolean, required: true },
  },
  emits: ["update:modelValue", "save-post"],
  computed: {
    dialogVisible: {
      get() {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.cancel-button {
  border-color: #808080 !important;
}
</style>
