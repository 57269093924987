<template>
  <div>
    <!-- データテーブル -->
    <v-card-text>
      <v-data-table
        v-model:page="page"
        density="compact"
        :headers="headers"
        :items="data"
        :items-per-page="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
      >
        <!-- ヘッダは左寄せ、テーブルの数値は右寄せにするためにtemplateで定義している -->
        <template #item="{ item }">
          <tr>
            <td>{{ item.name }}</td>
            <td class="text-right">{{ getValue(item.value) }}</td>
            <td class="text-right">
              <!-- 小数点のフォーマット(データが存在しない場合は何も表示しない) -->
              <span v-if="isNaN(item.rate)"></span>
              <span v-else>{{ (item.rate * 100).toFixed(2) }}%</span>
            </td>
            <td class="text-right">{{ getValue(item.previousValue) }}</td>
            <td class="text-right">
              <span v-if="isNaN(item.previousRate)" class="text-right" />
              <span v-else class="text-right">{{ (item.previousRate * 100).toFixed(2) }}%</span>
            </td>
            <!-- 対比 -->
            <td class="text-right">
              <span v-if="isNaN(calcContrast(item))">-</span>
              <span v-else-if="calcContrast(item) > 0" key="plus" class="plus">
                {{ calcContrast(item).toFixed(2) }}%
                <v-icon small color="#66CBB6">fas fa-circle-up</v-icon>
              </span>
              <span v-else-if="calcContrast(item) < 0" key="minus" class="minus">
                {{ Math.abs(calcContrast(item)).toFixed(2) }}%
                <v-icon small color="#E95454">fas fa-circle-down</v-icon>
              </span>
              <span v-else>-</span>
            </td>
          </tr>
        </template>
        <template #no-data>データが存在しません</template>
      </v-data-table>
      <!-- ページネーション -->
      <v-col v-if="data.length > itemsPerPage">
        <v-row justify="end">
          <v-pagination v-model="page" :length="pageCount" :total-visible="10" />
        </v-row>
      </v-col>
    </v-card-text>
    <v-overlay :model-value="loading" persistent contained class="align-center justify-center">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { requiredAuth } from "@/helpers";
import { captureAndThrow } from "@/helpers/error";
import type {
  DomainPerformanceSearchCountResponse,
  DomainPerformanceSearchCountDatum,
} from "@/types/ls-api";
import type { DateParams, StoreFilter } from "./performance";

type Header = {
  title: string;
  sortable: boolean;
  key: string;
};

// コンポーネント定義
export default defineComponent({
  components: {},
  props: {
    dateParams: { type: Object as () => DateParams, required: true },
    storeFilter: {
      type: Object as () => StoreFilter,
      required: false,
      default: () => ({} as StoreFilter),
    },
  },
  data: () => {
    return {
      page: 1 as number,
      pageCount: 0 as number,
      itemsPerPage: 15 as number,
      loading: false as boolean,
      data: [] as DomainPerformanceSearchCountDatum[],
      headers: [
        {
          title: "キーワード",
          sortable: false,
          key: "name",
        },
        {
          title: "検索ボリューム（表示期間）",
          sortable: false,
          key: "value",
        },
        {
          title: "割合（表示期間）",
          sortable: false,
          key: "rate",
        },
        {
          title: "検索ボリューム（比較期間）",
          sortable: false,
          key: "previousValue",
        },
        {
          title: "割合（比較期間）",
          sortable: false,
          key: "previousRate",
        },
        {
          title: "対比",
          sortable: false,
          key: "contrast",
        },
      ] as Header[],
    };
  },
  computed: {
    poiGroupID: function (): number {
      return parseInt(this.$route.params.poiGroupId as string);
    },
  },
  watch: {},
  async created() {},
  methods: {
    /* データ取得処理 */
    async fetch() {
      // リトライ処理を入れている
      for (let i = 0; i < 1000; i++) {
        this.loading = true;

        const dt1 = new Date().getTime();
        if (i > 0) {
          // 5sec wait
          let dt2 = new Date().getTime();
          const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));
          while (dt2 < dt1 + 5 * 1000) {
            await sleep(500);
            dt2 = new Date().getTime();
          }
        }

        await Promise.all([this.fetchSearchCount()]);

        if (!this.loading) {
          break;
        }
      }
    },
    async fetchSearchCount() {
      // クエリパラメータでエリア・店舗を絞り込みする
      const params = Object.assign({}, this.storeFilter, this.dateParams);
      await requiredAuth<DomainPerformanceSearchCountResponse>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
          this.poiGroupID
        }/performance/search_count`,
        params
      )
        .then((res) => {
          const result = res?.data?.data ?? [];
          this.data.splice(0, this.data.length, ...result);
          this.loading = false;
        })
        .catch((e) => {
          if (e.response?.status === 503) {
            console.log("503エラー、5秒後にリトライ");
          } else {
            captureAndThrow("検索数取得エラー", e);
          }
        });
    },
    calcContrast(item: DomainPerformanceSearchCountDatum): number {
      return ((item.value - item.previousValue) * 100) / item.previousValue;
    },
    getValue(value: number): string {
      return value?.toLocaleString() ?? "";
    },
  },
});
</script>
<style lang="scss" scoped>
/* テーブルのヘッダ */
:deep(.v-data-table) {
  /* 枠線の追加 */
  thead th {
    border: 0.5px solid #b3b3b3 !important;
    vertical-align: middle;
    color: #333333 !important;
    background-color: #fafafa !important;
  }
  /* テーブルをstripedにする */
  tbody tr:nth-of-type(even) td {
    background-color: #fafafa;
  }
  /* テーブル枠線の追加 */
  tbody td {
    border: 0.5px solid #b3b3b3 !important;
    vertical-align: middle;
  }
}
/* 対比が正の場合の文字の色 */
.plus {
  color: #66cbb6;
}
/* 対比が負の場合の文字の色 */
.minus {
  color: #e95454;
}
/* ページネーション位置調整 */
:deep(.v-pagination) {
  display: flex;
  justify-content: right;
  margin-top: 10px;
}
/* データが存在しない場合の設定 */
.no-data {
  margin: 10px 20px;
  color: rgba(0, 0, 0, 0.38);
  font: normal normal 14px/20px sans-serif;
}
</style>
