import { requiredAuth } from "@/helpers";
export const api = {
    logout,
};
/** ログアウト */
async function logout(accessToken: string): Promise<string> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/auth/logout`;
    const res = await requiredAuth<string>("post", url, null, {
        accessToken: accessToken,
    });
    return res.data;
}
