import { Component, Prop, Vue, toNative } from "vue-facing-decorator";
import Sidebar from "../root/sidebar";
import Topbar from "../root/topbar";
import Contents from "./contents";

@Component({
    components: { Contents, Topbar, Sidebar },
})
class Admin extends Vue {
    @Prop() notActive: boolean;
}
export default toNative(Admin);
