<template>
  <v-app id="inspire-admin">
    <topbar />
    <sidebar />
    <v-main>
      <div id="contents">
        <contents />
      </div>
    </v-main>
  </v-app>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
<style lang="css">
@import "material-design-icons-iconfont/dist/material-design-icons.css";
</style>
