import type {
    DomainV2BulkPostWorkDirHeader,
    DomainV2BulkPostWorkDirString,
    DomainV2BulkPostWorkDirRecord,
    DomainV2BulkPostWorkDirValue,
} from "@/types/ls-api";

import minusMarker from "@/assets/images/minus_marker.svg";
import videoSolid from "@/assets/images/video-solid.svg";

export class PostItem {
    constructor() {
        return;
    }

    isValid: boolean;
    state: string;
    areas: string;
    poiID: number;
    storeCode: string;
    storeName: string;
    text: string;
    title: string;
    buttonType: string;
    buttonURL: string;
}

// HeaderItemの引数の関数の型
type FieldFunction = (item: PostItem) => string;
type MessageFunction = (item: PostItem) => string;
type ClickFunction = (click: ClickEvent) => { name: string; url: string };
export type ClickEvent = { col: number; row: number; event: PointerEvent };

/**
 * cheetah gridのHeader
 * @param caption - headerタイトル
 * @param field - dataのfield名 or 表示データを返すFunction
 * @param message - エラーメッセージを返すFunction
 * @param image - 画像を表示するためのオブジェクト(optional)
 * @param width - headerの横幅, default= 200(optional)
 * @param rowSpan - 表示する行数(default, max=2)(optional)
 * */
class HeaderItem {
    constructor(
        caption: string,
        field: string | FieldFunction | undefined,
        message: string | MessageFunction | undefined = "",
        width: number = 200,
        rowSpan: number = 2,
        columnType: string = "",
        getFileURL: ClickFunction = (click: ClickEvent) => null
    ) {
        this.caption = caption;
        this.field = field;
        this.message = message;
        this.width = width;
        this.rowSpan = rowSpan;
        this.columnType = columnType;
        this.getFileURL = getFileURL;
    }

    caption: string;
    field: string | FieldFunction | undefined;
    message: string | MessageFunction | undefined;
    width: number;
    image: any | undefined;
    rowSpan: number;
    columnType: string;
    getFileURL: ClickFunction;
}

/**
 * cheetah gridのHeaderの情報をまとめたもの
 * @param main - 投稿種別ごとのHeaderItemのリスト
 * @param imageSub - 画像・動画のサムネイル表示用のHeaderItemのリスト
 * */
export class Headers {
    main: HeaderItem[] = []; // 投稿種別ごと
    imagesSub: HeaderItem[] = []; // 写真・動画のファイル名を表示
    postRawData: DomainV2BulkPostWorkDirRecord[] = [];
    uploadCounter: number;

    constructor(
        postHeaders: DomainV2BulkPostWorkDirHeader[] = null,
        postRawData: DomainV2BulkPostWorkDirRecord[] = [],
        baseUrl: string = "",
        counter: number = 1
    ) {
        this.uploadCounter = counter;
        if (!postHeaders) {
            this.main = [];
            this.imagesSub = [];
            this.postRawData = [];
            return this;
        }

        this.postRawData = postRawData;

        const isImageOrVideo = (h: DomainV2BulkPostWorkDirHeader) => {
            return h.fieldType === 15 || h.fieldType === 16; // domain.ColumnType
        };
        const isVideo = (fileName: string) => {
            const ext = fileName?.split(".")?.pop();
            return ["mp4", "mov"].includes(ext?.toLowerCase());
        };
        const getHeader = (h: DomainV2BulkPostWorkDirHeader, i: number) => {
            return new HeaderItem(
                h.caption,
                (row: PostItem) => {
                    // link, url, value のうち値のセットされているもののどれかを表示する
                    const cell: DomainV2BulkPostWorkDirString = row[h.field];
                    if (cell?.value) return cell.value;
                    if (cell?.url) return cell.url;
                    if (cell?.link) return cell.link;
                    return "";
                },
                (row: PostItem) => {
                    const cell: DomainV2BulkPostWorkDirString = row[h.field];
                    return cell?.message;
                },
                200,
                isImageOrVideo(h) ? 1 : 2 // 画像・動画は2行にしてサムネイル表示
            );
        };
        const getFileURL = (click: ClickEvent) => {
            const row: DomainV2BulkPostWorkDirValue[] =
                this.postRawData?.[(click.row - 3) / 2]?.values; // 1店舗あたり2行なので、ヘッダ分の行数を引いてから2で割る
            const cellValue: DomainV2BulkPostWorkDirString = row?.[click.col].value;
            return !cellValue || cellValue?.message
                ? null
                : {
                      name: cellValue.url,
                      url: `${baseUrl}/${cellValue.url}?counter=${this.uploadCounter}`,
                  };
        };
        const getImageSubHeader = (h: DomainV2BulkPostWorkDirHeader, i: number) => {
            return new HeaderItem(
                `サムネイル${i + 1}`,
                (row: PostItem) => {
                    const cell: DomainV2BulkPostWorkDirString = row[h.field];
                    if (isVideo(cell?.url)) {
                        // 動画はアイコンを表示（エラーがないときだけ）
                        return cell?.message ? minusMarker : videoSolid;
                    } else {
                        // サムネイル用の image url（エラーがないときだけ）
                        return cell?.message || !cell?.url
                            ? minusMarker
                            : `${baseUrl}/${cell?.url}?counter=${this.uploadCounter}`;
                    }
                },
                (row: PostItem) => {
                    return ""; // サムネイルにエラーメッセージはなし
                },
                200,
                1, // 画像・動画は2行にしてサムネイル表示
                "image",
                getFileURL // サムネイルクリック時に画像ファイルのURLを取得する
            );
        };

        this.main = postHeaders.map(getHeader);
        this.imagesSub = postHeaders?.filter((h) => isImageOrVideo(h))?.map(getImageSubHeader);
    }
}
