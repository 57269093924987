<template>
  <v-dialog v-model="openEditor" persistent max-height="100vh">
    <div class="editor">
      <div class="editor-text">
        <v-row>
          <v-col cols="auto" class="mr-auto align-self-center">
            <p class="editor-title">画像のレイアウト編集</p>
          </v-col>
          <v-col cols="auto">
            <v-btn
              size="x-large"
              variant="text"
              color="#fff"
              icon="mdi-close"
              @click.stop="$emit('close-dialog')"
            />
          </v-col>
        </v-row>
        <p class="editor-description">
          投稿する画像のレイアウトを編集することができます。
          <br />
          編集を終える場合、編集しているイメージに上書き保存するか、編集内容で新しいファイルとして保存することができます。
          <br />
          複数メディアで選択している場合に、編集しているイメージに上書き保存すると全てのメディアに編集内容が反映されます。
        </p>
      </div>
      <div class="cropper-area">
        <!-- 画像編集エリア -->
        <div class="img-cropper">
          <vue-cropper
            v-if="imgSrc != ''"
            ref="cropper"
            :src="imgSrc"
            drag-mode="crop"
            :container-style="{ 'max-height': '600px', 'min-height': '300px' }"
            :auto-crop-area="1"
            :background="false"
            :guides="false"
            :center="false"
            :responsive="false"
            :restore="false"
            :view-mode="0"
            :zoom-on-wheel="false"
            @ready="initialize"
            @cropend="cropMove"
            @zoom="onZoom"
          />
        </div>
        <!-- 画像リスト -->
        <div class="thumbnail-list">
          <v-tabs
            v-model="selectedPlatform"
            slider-color="primary"
            @update:model-value="onChangePlatform"
          >
            <v-tab value="google" :disabled="fileList('google').length === 0">
              <v-icon color="google" icon="$google" size="x-large" />
            </v-tab>
            <!-- yahooだけ小さめに見えるので別途大きさを指定 -->
            <v-tab value="yahoo" :disabled="fileList('yahoo').length === 0">
              <v-icon color="yahoo" icon="$yahoo" size="1.5em" />
            </v-tab>
            <v-tab value="instagram" :disabled="fileList('instagram').length === 0">
              <v-icon color="instagram" icon="$instagram" size="x-large" />
            </v-tab>
            <v-tab value="facebook" :disabled="fileList('facebook').length === 0">
              <v-icon color="facebook" icon="$facebook" size="x-large" />
            </v-tab>
          </v-tabs>
          <v-window v-model="selectedPlatform">
            <v-window-item v-for="p in platforms" :key="p" :value="p">
              <v-container
                v-scroll.self="onScroll"
                fluid
                style="max-height: 450px"
                class="overflow-y-auto"
              >
                <v-item-group v-model="selectedOrder" mandatory>
                  <v-row>
                    <!-- 選択されたイメージのみ、順番通りに表示 -->
                    <v-col
                      v-for="(file, idx) in fileList(selectedPlatform)"
                      :key="idx"
                      cols="12"
                      md="6"
                    >
                      <v-item v-slot="{ isSelected, toggle }" :disabled="isVideo(file.file)">
                        <!-- 動画はクリックできないようにする -->
                        <v-img
                          :src="file.imageUrl"
                          :lazy-src="file.imageUrl"
                          contain
                          aspect-ratio="1"
                          class="thumbnail"
                          @click="
                            toggle();
                            setImgSrc();
                          "
                        >
                          <div
                            v-show="isSelected"
                            class="fill-height fill-width editing-background"
                          >
                            <div class="editing-text">編集中</div>
                          </div>
                          <!-- ビデオのみ編集不可としてグレーアウト -->
                          <v-row
                            v-if="isVideo(file.file)"
                            class="fill-height fill-width disabled-background"
                            align="center"
                            justify="center"
                          >
                            <v-icon color="white" size="60" icon="fas fa-video" />
                          </v-row>
                        </v-img>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-item-group>
              </v-container>
            </v-window-item>
          </v-window>
        </div>
      </div>
      <!-- 編集メニュー -->
      <v-row class="actions mt-5" dense>
        <div>
          <p class="action-label">トリム枠縦横比</p>
          <v-select
            v-model="aspectRatio"
            :items="aspectRatioList"
            item-title="label"
            item-value="value"
            :disabled="imgSrc == ''"
            density="compact"
            variant="outlined"
            hide-details
            prepend-inner-icon="mdi-crop"
            single-line
            bg-color="white"
            @update:model-value="setAspectRatio"
          />
        </div>
        <div>
          <v-checkbox
            v-model="shouldFixedCropBox"
            :disabled="imgSrc == ''"
            density="compact"
            color="white"
            base-color="white"
          >
            <template #prepend>
              <p class="action-label with-checkbox">トリム枠サイズ</p>
            </template>
            <template #label>
              <p class="action-label checkbox-label">縦横比を固定する</p>
            </template>
          </v-checkbox>
          <v-row class="size-fields with-check-fields" dense>
            <v-col>
              <v-text-field
                v-model.number="cropHeight"
                :disabled="imgSrc == ''"
                density="compact"
                variant="outlined"
                color="white"
                bg-color="white"
                class="crop-input"
                @change="setCropBoxData('height')"
                @blur="setCropBoxData('height')"
              >
                <template #prepend>
                  <p class="action-label with-checkbox">高さ</p>
                </template>
                <template #append>
                  <p class="action-label with-checkbox">px</p>
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="cropWidth"
                :disabled="imgSrc == ''"
                density="compact"
                variant="outlined"
                color="white"
                bg-color="white"
                class="crop-input"
                @change="setCropBoxData('width')"
                @blur="setCropBoxData('width')"
              >
                <template #prepend>
                  <p class="action-label with-checkbox">幅</p>
                </template>
                <template #append>
                  <p class="action-label with-checkbox">px</p>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="with-input">
          <p class="action-label">トリム枠左上の座標</p>
          <v-row class="size-fields" dense>
            <v-col>
              <v-text-field
                v-model.number="cropLeftCoord"
                :disabled="imgSrc == ''"
                density="compact"
                variant="outlined"
                color="white"
                bg-color="white"
                class="crop-input"
                @change="setCropBoxTopLeft"
              >
                <template #prepend>
                  <p class="action-label with-checkbox">X</p>
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="cropTopCoord"
                :disabled="imgSrc == ''"
                density="compact"
                variant="outlined"
                color="white"
                bg-color="white"
                class="crop-input"
                @change="setCropBoxTopLeft"
              >
                <template #prepend>
                  <p class="action-label with-checkbox">Y</p>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-checkbox
            v-model="shouldFixedImageRatio"
            :disabled="true"
            density="compact"
            color="white"
            base-color="white"
          >
            <template #prepend>
              <p class="action-label with-checkbox">画像サイズ</p>
            </template>
            <template #label>
              <p class="action-label checkbox-label">縦横比を固定する</p>
            </template>
          </v-checkbox>
          <v-row class="size-fields with-check-fields" dense>
            <v-col>
              <v-text-field
                v-model.number="canvasSettingHeight"
                :disabled="imgSrc == ''"
                density="compact"
                variant="outlined"
                color="white"
                bg-color="white"
                class="crop-input"
                @keydown.enter="changeImageSize('height')"
                @blur="changeImageSize('height')"
              >
                <template #prepend>
                  <p class="action-label with-checkbox">高さ</p>
                </template>
                <template #append>
                  <p class="action-label with-checkbox">px</p>
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="canvasSettingWidth"
                :disabled="imgSrc == ''"
                density="compact"
                variant="outlined"
                color="white"
                bg-color="white"
                class="crop-input"
                @keydown.enter="changeImageSize('width')"
                @blur="changeImageSize('width')"
              >
                <template #prepend>
                  <p class="action-label with-checkbox">幅</p>
                </template>
                <template #append>
                  <p class="action-label with-checkbox">px</p>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <div>
          <p class="action-label">画像倍率 (Zoom)</p>
          <v-row class="size-fields with-input" dense>
            <v-col>
              <v-btn-toggle divided>
                <v-btn
                  :disabled="imgSrc == '' || imageScale === maxImageScale"
                  size="28"
                  icon="mdi-magnify-plus"
                  @click.prevent="zoom('up')"
                />
                <v-btn
                  :disabled="imgSrc == '' || imageScale === minImageScale"
                  size="28"
                  icon="mdi-magnify-minus"
                  @click.prevent="zoom('down')"
                />
              </v-btn-toggle>
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="imageScale"
                :disabled="imgSrc == ''"
                density="compact"
                variant="outlined"
                color="white"
                bg-color="white"
                :max="maxImageScale"
                :min="minImageScale"
                class="zoom-input"
                @change="zoom(imageScale)"
              >
                <template #append>
                  <p class="action-label with-checkbox">%</p>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <div>
          <p class="action-label">画像回転 (Rotate)</p>
          <v-row class="size-fields with-input ml-0" style="gap: 10px" dense>
            <div>
              <v-btn-toggle divided>
                <v-btn
                  :disabled="imgSrc == ''"
                  size="28"
                  icon="mdi-rotate-left"
                  @click.prevent="rotate(-45)"
                />
                <v-btn
                  :disabled="imgSrc == ''"
                  size="28"
                  icon="mdi-rotate-right"
                  @click.prevent="rotate(45)"
                />
              </v-btn-toggle>
            </div>
            <div>
              <v-text-field
                v-model.number="rotateDeg"
                :disabled="imgSrc == ''"
                density="compact"
                variant="outlined"
                color="white"
                bg-color="white"
                class="zoom-input"
                @change="rotate"
              >
                <template #append>
                  <p class="action-label with-checkbox">deg</p>
                </template>
              </v-text-field>
            </div>
          </v-row>
        </div>
        <div>
          <p class="action-label">画像移動</p>
          <v-btn-toggle divided style="margin-top: 0 !important">
            <v-btn
              :disabled="imgSrc == ''"
              size="28"
              icon="mdi-arrow-left"
              @click.prevent="move(-10, 0)"
            />
            <v-btn
              :disabled="imgSrc == ''"
              size="28"
              icon="mdi-arrow-up"
              @click.prevent="move(0, -10)"
            />
            <v-btn
              :disabled="imgSrc == ''"
              size="28"
              icon="mdi-arrow-down"
              @click.prevent="move(0, 10)"
            />
            <v-btn
              :disabled="imgSrc == ''"
              size="28"
              icon="mdi-arrow-right"
              @click.prevent="move(10, 0)"
            />
          </v-btn-toggle>
        </div>
      </v-row>

      <!-- ボタン類 -->
      <v-row justify="center" dense class="mt-5" style="gap: 20px">
        <v-btn variant="text" color="white" @click.prevent="$emit('close-dialog')">
          キャンセル
        </v-btn>
        <v-btn :disabled="imgSrc == ''" class="primary" @click.prevent="overwriteImage">
          イメージを編集内容で上書き
        </v-btn>
        <v-btn :disabled="imgSrc == ''" class="primary" @click.prevent="saveImage">
          編集内容を新しいイメージとして新規保存
        </v-btn>
      </v-row>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, toNative, Watch } from "vue-facing-decorator";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import type {
  FileSelectionMap,
  EditImageSelected,
  PlatformName,
  FileSelectionItem,
  IsVideoFunc,
  FileRevisions,
  AspectRatio,
  FileNameObj,
} from "@/models/v2-file-selection";
import {
  platformList,
  isVideo,
  sortFileSelectionItemList,
  arSquare,
  arTwoFirst,
  arFourNinth,
  arFourThirds,
  aspectRatioList as defaultAspectRatioList,
  arFourFifth,
  arSixteenNinth,
  arNinetyFirst,
  getFileNameWithExtension,
} from "@/models/v2-file-selection";
import { isValidImageSize, isValidFileSize, isValidAspectRatio, maxImageFiles } from "./validator";
import { TOAST_DURATION } from "@/const";
import { useSnackbar } from "@/storepinia/snackbar";
import { getter } from "@/storepinia/idxdb";

type CropBoxData = {
  left: number;
  top: number;
  width: number;
  height: number;
};

type CropCanvasData = {
  left: number;
  top: number;
  width: number;
  height: number;
  readonly naturalWidth: number;
  readonly naturalHeight: number;
};

@Component({ components: { VueCropper }, emits: ["input", "close-dialog"] })
export class ImageEditor extends Vue {
  /** 投稿先ごとのファイル一覧 */
  @Prop({ type: Map, required: true }) fileSelectionMap!: FileSelectionMap; // 親コンポーネントに変更を伝える
  /** fileSelectionMapのうち編集対象の画像 このコンポーネント内では弄らないこと */
  @Prop({ type: Object, required: true }) propSelecting!: EditImageSelected;
  /** モーダル表示制御 */
  @Prop({ type: Boolean, required: true }) openEditor!: boolean;
  @Prop({ type: Boolean, required: true }) gbpAspectNoCheck: boolean;

  addSnackbarMessages = useSnackbar().addSnackbarMessages;
  company = getter().company;

  /** 編集対象。propSelectingを直接変更するとVue warnになる為、随時コピーして使う */
  selectedPlatform: PlatformName = "google";
  selectedOrder: number = 0;
  platforms = platformList;
  /** スクロール制御 */
  scrollInvoked: number = 0;
  /** 編集対象 */
  imgSrc: string = "";
  /** 動画はdisabledにして選択できないようにする */
  isVideo: IsVideoFunc = isVideo;
  /** トリム枠の選択肢 */
  aspectRatioList: AspectRatio[] = [];
  /** トリム枠縦横比 */
  aspectRatio = 1 / 1;
  /** トリム枠縦横比（縦横比の固定を解除する前） */
  beforeAspectRatio = null;
  /** クロップボックス(トリム枠)サイズ(トリム枠の縦横比固定のデフォルトはtrue) */
  cropWidth: number = 0;
  cropHeight: number = 0;
  shouldFixedCropBox: boolean = true;
  /** クロップボックス左上の座標 */
  cropLeftCoord: number = 0;
  cropTopCoord: number = 0;
  /** 画像サイズ(サイズの縦横比固定のデフォルトはtrue) */
  imageWidth: number = 0;
  imageHeight: number = 0;
  shouldFixedImageRatio: boolean = true;
  /** 1 = 原寸(100%), 0 = 0%  */
  imageScale: number = 100;
  /** imageScaleの最大値(%) */
  maxImageScale = 800;
  /** imageScaleの最小値(%) cropperコンテナの下限が自動的に最低値になる */
  minImageScale = 5;
  /** imageScaleの刻み値(%) */
  private scaleStep = 5;
  /** rotateする場合の角度(xx度) */
  rotateDeg: number = 0;
  /** 上書き直後か */
  private isOverwritten = false;
  /** キャンバス幅指定inputの値 */
  canvasSettingWidth = 0;
  /** キャンバス高さ指定inputの値 */
  canvasSettingHeight = 0;

  /**
   * レイアウトエディタを開いた時にトリム枠縦横比を初期化して、最上段のoptionを選択させる
   * 閉じたときにはimgSrcを空にする
   */
  @Watch("openEditor")
  onOpened(): void {
    if (this.openEditor === true) {
      this.selectedPlatform = this.propSelecting.platform;
      this.selectedOrder = this.propSelecting.order;
      this.resetAspectRatio();
      this.setImgSrc();
    } else {
      this.imgSrc = "";
    }
  }

  fixScale(): number {
    /** 元の大きさに合わせてfixするために現在のcanvasと実寸の比を出す */
    const canvas: CropCanvasData = (this.$refs.cropper as VueCropper)?.getCanvasData();
    return canvas == null ? 1 : canvas.width / this.imageWidth;
  }

  /** 選択されたプラットフォームによってトリム枠の選択肢を変更する */
  private setAspectRatioList(): void {
    if (this.selectedPlatform == "google") {
      if (this.fileList(this.selectedPlatform).length === 1) {
        this.aspectRatioList = [arFourThirds, arFourNinth, arTwoFirst];
      } else {
        this.aspectRatioList = [arSquare];
      }
    } else if (this.selectedPlatform == "instagram") {
      /** Aspect scale: Must be within a 4:5(0.8) to 1.91:1(1.91) range */
      this.aspectRatioList = [arSquare, arFourThirds, arFourFifth, arSixteenNinth, arNinetyFirst];
    } else {
      this.aspectRatioList = defaultAspectRatioList;
    }
  }

  /** 選択された画像・動画のみソートして返す */
  fileList(platform: PlatformName): FileSelectionItem[] {
    return sortFileSelectionItemList(this.fileSelectionMap.get(platform));
  }

  /** 画像リストのタブ内スクロール */
  onScroll(): void {
    this.scrollInvoked++;
  }

  /** cropper.jsが準備完了になったら実行される関数 */
  initialize(): void {
    // 画像の初期値から画像サイズをセット
    // 元のサイズ(canvasDataのnaturalWidth,naturalHeight)を基準とする
    if (this.imgSrc == "") {
      return;
    }
    const canvas: CropCanvasData = (this.$refs.cropper as VueCropper).getCanvasData();
    // 何らかの理由で編集画像ファイルを取得出来なかった場合Sentryダイアログで取得出来なかったこと告知
    if (this.checkCorrectCanvas(canvas) === false) {
      return;
    }
    this.minImageScale = 5;
    this.imageHeight = this.canvasSettingHeight = canvas.naturalHeight;
    this.imageWidth = this.canvasSettingWidth = canvas.naturalWidth;
    this.resize("height");
    this.shouldFixedImageRatio = true;
    // 新規にレイアウト編集モーダルを開いた際はsetAspectRatioで最上段のを選択、
    // 上書きによるinitializeの場合は直前のaspectRatioを保つのでsetAspectRatioは実行しない
    if (this.isOverwritten === false) {
      this.setAspectRatioList();
      // GBP1枚で新規にモーダル開いた際はトリム枠縦横比固定チェックを外す
      if (this.selectedPlatform === "google") {
        if (this.fileList(this.selectedPlatform).length === 1) {
          this.shouldFixedCropBox = false;
        } else {
          this.setAspectRatio(this.aspectRatioList[0].value);
        }
      } else {
        this.setAspectRatio(this.aspectRatioList[0].value);
      }
    }
    this.isOverwritten = false;
    this.setCropBoxData("height");
    this.$nextTick(() => {
      // 何らかの理由で編集画像ファイルを取得出来なかった場合Sentryダイアログで取得出来なかったこと告知
      if (this.checkCorrectCanvas(canvas) === false) {
        return;
      }
      /* モーダル初回オープン時にトリム枠縦横比で選択した値を反映させる
      正確な値を得る為に$nextTickで遅延実行する必要がある */
      this.cropMove();
      // 表示倍率をセット
      const imageScale = this.setZoomLimit((canvas.width / canvas.naturalWidth) * 100);
      this.minImageScale = imageScale;
      this.imageScale = imageScale;
    });
  }

  /** 画像が正しく取得出来なかった際にエラーダイアログ出す */
  private checkCorrectCanvas(canvas: CropCanvasData): boolean {
    if (canvas === null) {
      // レイアウト編集内の操作無効にする
      this.imgSrc = "";
      this.showToast(
        "編集画像の取得に失敗しました。恐れ入りますが、もう一度最初からお試しください。",
        "danger"
      );
      return false;
    }
    return true;
  }

  /** タブ内の選択を初期化 */
  onChangePlatform(): void {
    // console.log("onChangePlatform");
    // 選択中のタブの画像ファイルのみを抽出
    const selectedTabFiles = this.fileList(this.selectedPlatform);
    const photoFiles = selectedTabFiles.filter(
      (f) => f.videoUrl === "" && typeof f.state === "number"
    );
    // 静画がなかったら、編集エリアに画像を表示しない
    if (selectedTabFiles.length === 0 || photoFiles.length === 0) {
      this.selectedOrder = 0;
    } else {
      // 静画があれば先頭の画像を選択
      this.selectedOrder = photoFiles[0].state as number;
    }
    // トリム枠のアスペクト比を設定
    this.resetAspectRatio();

    // リストの最初を選択しているとwatchされないので強制的に実行
    this.setImgSrc();
  }

  setImgSrc(): void {
    // 選択が外れた場合はimgSrcをクリアして編集領域を非表示にする
    if (this.selectedOrder === 0) {
      this.imgSrc = "";
      return;
    }
    const src = this.fileList(this.selectedPlatform).filter(
      (f) => f.state == this.selectedOrder
    )[0];
    this.imgSrc = src?.imageUrl ?? "";
    // 編集対象を別の画像に更新するための処理(保存時に走る)
    if (this.$refs.cropper !== null && this.$refs.cropper !== undefined) {
      (this.$refs.cropper as VueCropper).reset();
      (this.$refs.cropper as VueCropper).replace(this.imgSrc);
    }
  }

  /** イメージを編集内容で上書き */
  async overwriteImage(): Promise<void> {
    // console.log("overwriteImage", this.selectedOrder);
    // 元ファイルの情報を取得
    const original = this.fileList(this.selectedPlatform).filter(
      (f) => f.state == this.selectedOrder
    )[0].file;
    const newBlob = await this.cropImage(original);
    if (newBlob == null) {
      return;
    }
    const newFile = new File([newBlob], original.name, {
      type: newBlob.type,
    });

    URL.revokeObjectURL(this.imgSrc);
    const newURL = URL.createObjectURL(newFile);

    /* 上書き対象のFileオブジェクトとObjectURLを置き換える
    note: 複数メディアで選択している場合に、編集しているイメージに上書き保存すると全てのメディアに編集内容が反映される */
    await new Promise<number>((resolve) => {
      const currentOrder: number = this.selectedOrder;

      this.fileSelectionMap.forEach((val, key) => {
        for (const file of val) {
          // 名前で判断している
          if (file.file.name == original.name) {
            file.file = newFile;
            file.imageUrl = newURL;
          }
        }
        this.fileSelectionMap.set(key, val);
      });

      resolve(currentOrder);
    }).then((order) => {
      // 上書きしましたフラグを立てる
      this.isOverwritten = true;
      // Mapの更新中にorderがundefinedになるので、改めてorderをセットする
      this.selectedOrder = order;
      this.setImgSrc();
    });
    this.showToast("画像を上書き保存しました。上書きした画像を表示しています", "info");
  }

  /** 編集内容を新しいイメージとして新規保存 */
  async saveImage(): Promise<void> {
    // 元ファイルの情報を取得
    const original = this.fileList(this.selectedPlatform).filter(
      (f) => f.state == this.selectedOrder
    )[0];
    const newBlob = await this.cropImage(original.file);
    if (newBlob == null) {
      return;
    }
    const platformItem = this.fileSelectionMap.get(this.selectedPlatform);
    // ユーザーがアップロードした画像ファイル
    let firstGenFile: FileNameObj;
    // プラットフォーム毎のリビジョン更新
    let newRevision: FileRevisions;
    if (!original.ancestor) {
      // 本PBI[TM-5661]適用以前に投稿された画像を編集する場合
      firstGenFile = getFileNameWithExtension(original.file.name);
      newRevision = {
        google: 0,
        yahoo: 0,
        instagram: 0,
        facebook: 0,
      };
      newRevision[this.selectedPlatform] = 1;
      original.ancestor = firstGenFile;
      original.revision = newRevision;
    } else {
      newRevision = original.revision;
      firstGenFile = original.ancestor;
      for (const item of platformItem) {
        if (item.revision && item.ancestor.fullName === firstGenFile.fullName) {
          newRevision[this.selectedPlatform] = Math.max(
            item.revision[this.selectedPlatform],
            newRevision[this.selectedPlatform]
          );
          newRevision[this.selectedPlatform]++;
          break;
        }
      }
    }
    // 元ファイル名に選択プラットフォームと改訂したリビジョンを新しいファイル名とする
    const newFile = new File(
      [newBlob],
      `${firstGenFile.fileName}_${this.selectedPlatform}_${newRevision[this.selectedPlatform]}.${
        firstGenFile.ext
      }`,
      {
        type: newBlob.type,
      }
    );
    const newURL = URL.createObjectURL(newFile);

    this.fileSelectionMap.forEach((val, key) => {
      const canUseGbpConsole = this.company.canUseGbpConsole;
      // 枚数制限を見て上限に達していれば"disabled"にする
      const selectedLength = this.fileList(key).length;
      // 作成したFileオブジェクトとObjectURLを新規オブジェクトとして追加
      const newItem: FileSelectionItem = {
        file: newFile,
        imageUrl: newURL,
        videoUrl: original.videoUrl,
        s3FileName: "",
        state: selectedLength < maxImageFiles(canUseGbpConsole)[key] ? "deselected" : "disabled",
        rejectMessage: "",
        ancestor: firstGenFile,
        revision: newRevision,
      };
      // 選択されている投稿先では新しいファイルの選択状態を元のファイルの順番として置き換える
      if (key == this.selectedPlatform) {
        val.map((f) => {
          if (f.state == this.selectedOrder) {
            f.state =
              selectedLength < maxImageFiles(canUseGbpConsole)[key] ? "deselected" : "disabled";
          }
        });
        newItem.state = this.selectedOrder;
      }
      val.push(newItem);
      this.fileSelectionMap.set(key, val);
    });
    this.setImgSrc();
    this.showToast("画像を新規保存しました。新規保存した画像を表示しています", "info");
  }

  private validateCanvasSize(
    width: number,
    height: number,
    isTrimImage: boolean,
    aspectCheck: boolean,
    toastDisabling: boolean = false
  ): boolean {
    const fileCount = this.fileList(this.selectedPlatform).length;
    // 画像サイズチェック
    const imageErrMsg = isValidImageSize(
      this.selectedPlatform,
      width,
      height,
      fileCount,
      isTrimImage
    );
    let isError = false;
    if (imageErrMsg !== "") {
      if (toastDisabling === false) {
        this.showToast(imageErrMsg);
      }
      isError = true;
    }
    if (aspectCheck) {
      // アスペクト比チェック
      const ratioErrMsg = isValidAspectRatio(
        this.selectedPlatform,
        width,
        height,
        fileCount,
        isTrimImage
      );
      if (ratioErrMsg !== "") {
        if (toastDisabling === false) {
          this.showToast(ratioErrMsg);
        }
        isError = true;
      }
    }
    return isError;
  }

  async cropImage(original: File): Promise<Blob> {
    const cropper = this.$refs.cropper as VueCropper;
    /* 最終的なトリミングデータを設定する
    これをしないとトリム枠1.91:1指定時にキャンバスの比率が崩れる */
    const cropBox = cropper.getData(true);
    cropper.setData(cropBox);

    const cropped = cropper.getCroppedCanvas({
      width: this.cropWidth,
      height: this.cropHeight,
      maxWidth: this.imageWidth,
      maxHeight: this.imageHeight,
      imageSmoothingQuality: "medium",
    }) as HTMLCanvasElement;

    // 何らかの理由でトリミングデータ生成失敗したら中断する
    if (cropped == null) {
      this.showToast("保存に失敗しました。恐れ入りますが初めからやり直してください。", "danger");
      return;
    }
    const aspectNoCheck = this.selectedPlatform === "google" ? this.gbpAspectNoCheck : false;
    const isError = this.validateCanvasSize(this.cropWidth, this.cropHeight, true, !aspectNoCheck);
    // 画像サイズかアスペクト比エラーだったら中断する
    if (isError === true) {
      return;
    }
    // cropしたものを保存できるようにするためにBlobを生成(拡張子は元画像のmimetypeのままになるようにする)
    const cropImgFile: Blob = await new Promise((resolve) => {
      cropped.toBlob(
        (blob) => {
          resolve(blob);
        },
        original.type,
        1
      );
    });
    // ファイルサイズチェック
    const fileErrMsg = isValidFileSize(this.selectedPlatform, cropImgFile, true);
    if (fileErrMsg != "") {
      this.showToast(fileErrMsg);
      return;
    }

    return cropImgFile;
  }

  showToast(msg: string, level: "danger" | "info" = "danger"): void {
    this.addSnackbarMessages({
      text: msg,
      timeout: TOAST_DURATION,
      color: level,
    });
  }

  resetAspectRatio(): void {
    if (this.$refs.cropper !== undefined && this.$refs.cropper !== null) {
      // アスペクト比は以前選択していた比率があればそれを使い、無ければリストの先頭のものを使う
      if (!this.aspectRatioList.some((a) => a.value == this.aspectRatio)) {
        this.setAspectRatio(this.aspectRatioList[0].value);
      }
    }
  }

  /** トリム枠縦横比の設定 */
  setAspectRatio(aspect: number): void {
    // 動画だったらSentryエラーになるので実行しない
    const selectedTabFiles = this.fileList(this.selectedPlatform);
    const topFile = selectedTabFiles.filter(
      (f) => f.videoUrl === "" && typeof f.state === "number"
    );
    if (selectedTabFiles.length === 0 || topFile.length === 0) {
      return;
    }
    if (typeof aspect === "number" && isFinite(aspect)) {
      this.beforeAspectRatio = null;
      // 数値指定されたらトリム枠の縦横比を固定
      this.shouldFixedCropBox = true;
    } else {
      this.shouldFixedCropBox = false;
    }
    this.aspectRatio = aspect;
    (this.$refs.cropper as VueCropper).setAspectRatio(aspect);
    // 設定した縦横比に合わせて自動変更されたトリム枠サイズを、入力ボックスに反映
    this.cropMove();
  }

  /** トリム枠サイズ */
  setCropBoxData(inputType: "width" | "height"): void {
    // 0だとフリーズするので入力させない
    if (this.cropWidth < 1 || this.cropHeight < 1) {
      return;
    }
    // 元のクロップボックスのサイズを取得
    const cropbox = (this.$refs.cropper as VueCropper).getCropBoxData();
    // 「縦横比を固定する」のチェック
    if (this.shouldFixedCropBox) {
      // 高さに合わせて横幅を縦横比にそろえる(四捨五入)
      if (inputType == "height") {
        // 高さに合わせて横幅を縦横比にそろえる(四捨五入)
        this.cropWidth = Math.round((this.cropHeight / cropbox.height) * cropbox.width);
        this.cropHeight = this.cropWidth / this.aspectRatio;
        if (this.cropHeight > this.imageHeight) {
          // 画像サイズを上回っていたら画像サイズ内に収める
          this.cropHeight = this.imageHeight;
          this.cropWidth = Math.round((this.cropHeight / cropbox.height) * cropbox.width);
        }
      } else {
        // 幅に合わせて高さを縦横比にそろえる(四捨五入)
        this.cropHeight = Math.round((this.cropWidth / cropbox.width) * cropbox.height);
        this.cropWidth = this.cropHeight * this.aspectRatio;
        if (this.cropWidth > this.imageWidth) {
          // 画像サイズを上回っていたら画像サイズ内に収める
          this.cropWidth = this.imageWidth;
          this.cropHeight = Math.round((this.cropWidth / cropbox.width) * cropbox.height);
        }
      }
      this.fixCropBox();
    }

    // クロップボックスのサイズに指定された値を設定
    cropbox.width = this.cropWidth * this.fixScale();
    cropbox.height = this.cropHeight * this.fixScale();
    // 枠の位置はみだしてたら矯正する
    this.$nextTick(() => {
      this.correctPosition(cropbox);
      // 縦横比が異なっている画像だと数値入力した際に切り抜き枠がはみ出すことがあるので再補正する
      this.correctCropBox();
    });
  }

  /** cropBoxのドラッグを検知してinput boxと同期させるための処理 */
  cropMove(): void {
    if (this.$refs.cropper == null) {
      // 画面を急激に縮めたりすると認識しなくなるようなので、その時は何もしない
      return;
    }
    const cropbox = (this.$refs.cropper as VueCropper).getCropBoxData();
    this.cropLeftCoord = Math.round(cropbox.left / this.fixScale());
    this.cropTopCoord = Math.round(cropbox.top / this.fixScale());
    // 「縦横比を固定する」のチェックがあった場合は調整を行う
    if (this.shouldFixedCropBox) {
      // ドラッグすると半端なサイズになって比率が狂う場合があるのでboxをキリの良い数字に調整する
      this.cropWidth = Math.round(cropbox.width / this.fixScale());
      this.cropHeight = this.cropWidth / this.aspectRatio;
      this.fixCropBox();
      cropbox.width = this.cropWidth * this.fixScale();
      cropbox.height = this.cropHeight * this.fixScale();
      (this.$refs.cropper as VueCropper).setCropBoxData(cropbox);
    } else {
      // ドラッグした後のトリム枠とテキストボックスを同期させる
      this.cropWidth = cropbox.width / this.fixScale();
      this.cropHeight = cropbox.height / this.fixScale();
    }
    this.cropWidth = Math.round(this.cropWidth);
    this.cropHeight = Math.round(this.cropHeight);
    // 枠はみだしてたら矯正する
    this.$nextTick(() => {
      this.correctCropBox();
    });
  }

  /** 切り抜き枠見た目と数値補正 */
  private correctCropBox(): void {
    const cropper: VueCropper = this.$refs.cropper as VueCropper;
    let cropbox = cropper.getCropBoxData();
    const canvasData: CropCanvasData = cropper.getCanvasData();
    // 切り抜きサイズがキャンバスサイズを上回っていたら丸める
    if (cropbox.width > canvasData.width) {
      cropbox.width = canvasData.width;
    }
    if (cropbox.height > canvasData.height) {
      cropbox.height = canvasData.height;
    }
    // はみ出さない様に見た目上の切り抜き枠矯正する
    cropbox = this.correctPosition(cropbox);
    // 数値の補正
    const fixScale = this.fixScale();
    this.cropWidth = Math.round(cropbox.width / fixScale);
    this.cropHeight = Math.round(cropbox.height / fixScale);
    this.cropLeftCoord = Math.round(cropbox.left / fixScale);
    this.cropTopCoord = Math.round(cropbox.top / fixScale);
  }

  /** 見た目の方の切り抜き枠位置矯正 */
  private correctPosition(cropbox: CropBoxData): CropBoxData {
    const cropper: VueCropper = this.$refs.cropper as VueCropper;
    const canvasData: CropCanvasData = cropper.getCanvasData();
    const canvasEdge = {
      right: canvasData.left + canvasData.width,
      bottom: canvasData.top + canvasData.height,
    };

    if (cropbox.left + cropbox.width > canvasEdge.right) {
      cropbox.left = canvasEdge.right - cropbox.width;
    } else if (cropbox.left < canvasData.left) {
      cropbox.left = canvasData.left;
    }
    if (cropbox.top < canvasData.top) {
      cropbox.top = canvasData.top;
    } else if (cropbox.top + cropbox.height > canvasEdge.bottom) {
      cropbox.top = canvasEdge.bottom - cropbox.height;
    }
    cropper.setCropBoxData(cropbox);
    return cropbox;
  }

  fixCropBox(): void {
    if (
      (this.selectedPlatform === "instagram" || this.selectedPlatform === "facebook") &&
      this.aspectRatio === 1.91
    ) {
      /** 1.91:1指定の場合、elseの方のやり方だとたまに意図したものより大きく補正され過ぎてしまうことがあるのでこちらを使う
      1.91を上回らなければいいので幅から1を引く */
      this.cropWidth = Math.floor(this.cropWidth - 1);
      this.cropHeight = Math.floor(this.cropHeight);
    } else {
      // 保存時のcanvasで出力する時に小数点が四捨五入されて比率が狂う場合があるので、値がfloatでなくなるまで調整する
      // note: トリム枠のサイズを切り上げてしまうと元画像の大きさを超えてしまう場合があるので切り下げで調整する
      while (!Number.isInteger(this.cropWidth) || !Number.isInteger(this.cropHeight)) {
        if (!Number.isInteger(this.cropWidth)) {
          this.cropWidth = Math.floor(this.cropWidth);
          this.cropHeight = this.cropWidth / this.aspectRatio;
        } else if (!Number.isInteger(this.cropHeight)) {
          this.cropHeight = Math.floor(this.cropHeight);
          this.cropWidth = this.cropHeight * this.aspectRatio;
        }
      }
    }
  }

  /** クロップボックス左上の座標を指定 */
  setCropBoxTopLeft(): void {
    const cropbox = (this.$refs.cropper as VueCropper).getCropBoxData();
    cropbox.left = this.cropLeftCoord * this.fixScale();
    cropbox.top = this.cropTopCoord * this.fixScale();
    (this.$refs.cropper as VueCropper).setCropBoxData(cropbox);
    this.cropMove();
  }

  @Watch("shouldFixedCropBox")
  setShouldFixedCropBox(): void {
    if (this.shouldFixedCropBox) {
      // アスペクト比の固定 → アスペクト比は以前選択していた比率があればそれを使い、無ければリストの先頭のものを使う
      if (this.beforeAspectRatio) {
        if (this.aspectRatioList.some((a) => a.value == this.beforeAspectRatio)) {
          this.setAspectRatio(this.beforeAspectRatio);
        } else {
          this.setAspectRatio(this.aspectRatioList[0].value);
        }
      }
    } else {
      // アスペクト比の固定を解除
      this.beforeAspectRatio = this.aspectRatio;
      this.setAspectRatio(null);
    }
  }

  private resize(inputType: "width" | "height") {
    /**
     * note: 元画像のサイズをリサイズし、リサイズした大きさで保存することを目的とする
     * トリミング時のサイズ調整もここで設定したサイズを用いる
     * canvas(≠元画像のサイズ)の大きさをリサイズする場合は、
     * getCanvasData()したデータのwidth/heightを編集してsetCanvasData()する
     */

    // 元のファイルのサイズを取得
    const image = (this.$refs.cropper as VueCropper).getImageData();
    // 縦横比固定の場合
    if (this.shouldFixedImageRatio) {
      if (inputType == "height") {
        // 高さに合わせて横幅を縦横比にそろえる(四捨五入)
        this.imageWidth = Math.round((this.imageHeight / image.naturalHeight) * image.naturalWidth);
        this.canvasSettingWidth = Math.round(
          (this.canvasSettingHeight / image.naturalHeight) * image.naturalWidth
        );
      } else {
        // 幅に合わせて高さを縦横比にそろえる(四捨五入)
        this.imageHeight = Math.round((this.imageWidth / image.naturalWidth) * image.naturalHeight);
        this.canvasSettingHeight = Math.round(
          (this.canvasSettingWidth / image.naturalWidth) * image.naturalHeight
        );
      }
    }
    // トリム枠を再設定
    this.cropHeight = this.canvasSettingHeight;
    this.cropWidth = this.canvasSettingWidth;
    this.setCropBoxData("height");
  }

  /** 表示倍率を変更する */
  zoom(mode: string | number): void {
    const cropper: VueCropper = this.$refs.cropper as VueCropper;
    let imageScale;
    if (mode === "up" || mode === "down") {
      const zoomTo = mode === "up" ? this.scaleStep : -this.scaleStep;
      imageScale = this.imageScale + zoomTo;
      imageScale = this.setZoomLimit(imageScale);
      cropper.zoomTo(imageScale / 100);
      this.imageScale = imageScale;
    } else if (Number.isNaN(mode as number) === false) {
      imageScale = this.setZoomLimit(mode as number);
      cropper.zoomTo(imageScale / 100);
      this.imageScale = imageScale;
    }
  }

  /** ズーム率限界を指定する */
  private setZoomLimit(zoomTo: number): number {
    let imageScale = zoomTo;
    if (imageScale > this.maxImageScale) {
      imageScale = this.maxImageScale;
    } else if (imageScale < this.minImageScale) {
      imageScale = this.minImageScale;
    }
    return Math.round(imageScale * 10) / 10;
  }

  /** ズーム実行した時に補正する */
  onZoom(e: CustomEvent): void {
    // 現在の拡大率の更新
    this.imageScale = this.setZoomLimit(Math.round(e.detail.ratio * 100));
    // 切り抜き枠はみ出さない様矯正
    this.$nextTick(() => {
      this.correctCropBox();
    });
  }

  /** 画像回転(Rotate) */
  rotate(deg: number): void {
    (this.$refs.cropper as VueCropper).rotate(deg);
  }

  /** 画像移動 */
  move(offsetX: number, offsetY: number): void {
    (this.$refs.cropper as VueCropper).move(offsetX, offsetY);
    // 切り抜き枠はみ出さない様に矯正
    this.correctCropBox();
  }

  /** 画像サイズを入力欄から変更 */
  changeImageSize(inputType: "width" | "height"): void {
    const image: CropCanvasData = (this.$refs.cropper as VueCropper).getCanvasData();
    // エラートーストが出まくるケースがあるのを抑止
    let toastDisabling = false;
    // 入力した値のチェック
    if (inputType === "width") {
      // 変更ない場合は何もしない(トリム枠座標がリセットされるのを抑止するため)
      if (this.imageWidth === this.canvasSettingWidth) {
        return;
      }
      this.canvasSettingWidth = Math.floor(this.canvasSettingWidth);
      /* リサイズしてからじゃないともう片方がvalidation条件に引っかかる場合にうまくいかない */
      this.resize(inputType);
      if (this.canvasSettingWidth > image.naturalWidth) {
        this.canvasSettingWidth = image.naturalWidth;
        this.showToast(`元の幅 ${image.naturalWidth} pxより大きくすることは出来ません。`, "danger");
      } else if (
        (toastDisabling = this.validateCanvasSize(
          this.canvasSettingWidth,
          this.canvasSettingHeight,
          false,
          false
        ))
      ) {
        this.canvasSettingWidth = image.naturalWidth;
        this.resize(inputType);
      }
    } else {
      //  変更ない場合は何もしない(トリム枠座標がリセットされるのを抑止するため)
      if (this.imageHeight === this.canvasSettingHeight) {
        return;
      }
      this.canvasSettingHeight = Math.floor(this.canvasSettingHeight);
      /* リサイズしてからじゃないともう片方がvalidation条件に引っかかる場合にうまくいかない */
      this.resize(inputType);
      if (this.canvasSettingHeight > image.naturalHeight) {
        this.canvasSettingHeight = image.naturalHeight;
        this.showToast(
          `元の高さ ${image.naturalHeight} pxより大きくすることは出来ません。`,
          "danger"
        );
      } else if (
        (toastDisabling = this.validateCanvasSize(
          this.canvasSettingWidth,
          this.canvasSettingHeight,
          false,
          false
        ))
      ) {
        this.canvasSettingHeight = image.naturalHeight;
        this.resize(inputType);
      }
    }
    // 入力しなかった方の値が最低サイズを下回ってないかチェックする
    this.checkImageSize(image, toastDisabling);
    this.imageWidth = this.canvasSettingWidth;
    this.imageHeight = this.canvasSettingHeight;
    /* トリム枠を同期させる */
    this.cropMove();
  }

  private checkImageSize(image, toastDisabling: boolean): void {
    const correctedSize = { width: 0, height: 0 };
    let ratio: number;
    if (image.naturalHeight < image.naturalWidth) {
      ratio = image.naturalHeight / image.naturalWidth;
      correctedSize.width = this.canvasSettingWidth;
      correctedSize.height = Math.floor(correctedSize.width * ratio);
    } else {
      ratio = image.naturalWidth / image.naturalHeight;
      correctedSize.height = this.canvasSettingHeight;
      correctedSize.width = Math.floor(correctedSize.height * ratio);
    }

    if (
      this.validateCanvasSize(
        correctedSize.width,
        correctedSize.height,
        false,
        false,
        toastDisabling
      )
    ) {
      this.canvasSettingWidth = image.naturalWidth;
      this.canvasSettingHeight = image.naturalHeight;
    }
  }
}
export default toNative(ImageEditor);
</script>

<style lang="scss" scoped>
.editor {
  height: 100%;
  box-sizing: border-box;
  padding-left: 7rem;
  padding-right: 7rem;
  padding-top: 0;
  padding-bottom: 1.5rem;
  background: #333;
  overflow: auto;
}

.editor-text {
  color: #fff;
}

.editor-title {
  font-family: sans-serif;
  font-size: 24px !important;
  font-weight: bold;
  margin-bottom: unset;
}

.editor-description {
  font-family: sans-serif;
  font-size: 14px !important;
  line-height: 200%;
  margin-bottom: unset;
}

.close-button {
  font-size: 3rem;
}

.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 10px;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}

// 画像編集エリア
.img-cropper {
  background: #000;
  background-clip: content-box;
}

// 画像リスト
.thumbnail-list {
  max-width: 480px;
  background: #fff;
}

.thumbnail {
  background: #4a4a4a;
}

.editing-background {
  background: #4ad4be;
  opacity: 0.87;
  display: flex;
  justify-content: center;
  align-items: center;

  .editing-text {
    font-size: 18px;
    font-family: sans-serif;
    color: #fff;
    text-shadow: 0 1px 0 #00000095;
    text-align: center;
    opacity: 1;
  }
}

.disabled-background {
  @extend .editing-background;

  background: #707070;
  opacity: 0.5;
}

// レイアウト編集操作部分
.actions {
  justify-content: center;
  gap: 20px;

  .action-label {
    font-size: 12px;
    margin: unset !important;
    color: #fff !important;
    margin-bottom: 0.5rem !important;
  }

  :deep(.v-input) {
    margin: unset !important;

    // 入力ボックスの中身
    .v-field {
      font-size: 12px;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      min-height: 20px !important;
      margin-bottom: unset !important;
      margin-top: 2.5px !important;
    }

    .v-field__field {
      font-size: 12px;
      max-height: 28px;
      min-height: 28px;
      margin-top: -0.25rem;
      margin-left: -0.25rem;
    }
  }

  // セレクトボックス
  :deep(.v-select) {
    .v-field__field {
      max-height: 2.15rem;
    }

    .v-icon {
      padding-left: 0.2rem;
      margin-right: 0.2rem;
      font-size: 1rem;
    }
  }

  // チェックボックス
  :deep(.v-checkbox) {
    margin-top: -0.5rem !important;

    .v-input__control {
      margin-top: -0.2rem;
    }

    .v-checkbox-btn {
      margin-top: 0;
    }

    .checkbox-label {
      margin-top: 0.5rem !important;
    }

    .v-selection-control__input {
      margin-right: 0.3rem !important;
    }

    // Vuetifyのopacityを打ち消す
    .v-selection-control--disabled {
      opacity: 1 !important;
    }

    .v-label {
      opacity: 1 !important;
    }
  }

  // disableでもラベルは他と同じように表示
  :deep(.v-input--disabled) {
    .v-input__prepend {
      opacity: 1 !important;
    }
  }

  // 前後にlabelのある入力ボックス
  .size-fields {
    :deep(.col) {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }

    :deep(.v-input__prepend) {
      padding-left: 0 !important;
      margin-right: 0.2rem !important;
      margin-left: 0 !important;
    }

    :deep(.v-input__append) {
      padding-right: 0.2rem !important;
      margin-left: 0.2rem !important;
    }
  }

  .crop-input {
    :deep(input) {
      width: 6em;
      text-align: right;
    }
  }

  .zoom-input {
    width: 5.5em;

    :deep(.v-field__input) {
      text-align: right;
    }
  }

  // チェックボックスのある項目の入力ボックス
  .with-check-fields {
    margin-top: -1.75rem;
  }

  .v-btn-toggle {
    // ボタングループはinputの下に付くように調整
    margin-top: 0.4rem !important;
  }
}

.cancel-button {
  color: #fff !important;
}
</style>
