<template>
  <VeeForm ref="observer" as="" data-testid="form">
    <div class="form-information">{{ dict.requiredDesc }}</div>

    <Field
      v-slot="{ handleChange, value, errors }"
      v-model="familyName"
      rules="required"
      :name="dict.familyName.title"
    >
      <span class="tooltip-target required-highlight">{{ dict.familyName.title }}</span>
      <ToolTipIcon :label="dict.familyName.supplement" />
      <o-field id="userFamilyName">
        <o-input
          ref="userFamilyName"
          data-testid="familyName"
          name="userFamilyName"
          maxlength="64"
          :has-counter="false"
          :model-value="value"
          @update:model-value="handleChange"
        />
      </o-field>
      <p data-testid="familyNameErrorMessage" class="error-message">
        {{ errors[0] }}
      </p>
    </Field>

    <Field
      v-slot="{ handleChange, value, errors }"
      v-model="firstName"
      rules="required"
      :name="dict.firstName.title"
    >
      <span class="tooltip-target required-highlight">{{ dict.firstName.title }}</span>
      <ToolTipIcon :label="dict.firstName.supplement" />
      <o-field id="userFirstName">
        <o-input
          ref="userFirstName"
          data-testid="firstName"
          name="userFirstName"
          maxlength="64"
          :has-counter="false"
          :model-value="value"
          @update:model-value="handleChange"
        />
      </o-field>
      <p data-testid="firstNameErrorMessage" class="error-message">
        {{ errors[0] }}
      </p>
    </Field>

    <Field
      v-slot="{ handleChange, value, errors }"
      v-model="mailAddress"
      rules="required|email"
      :name="dict.mailAddress.title"
    >
      <span class="tooltip-target required-highlight">{{ dict.mailAddress.title }}</span>
      <ToolTipIcon :label="dict.mailAddress.supplement" />
      <o-field id="userMail">
        <o-input
          ref="mailAddress"
          data-testid="mailAddress"
          name="mailAddress"
          maxlength="128"
          :has-counter="false"
          :disabled="!isNew"
          :model-value="value"
          @update:model-value="handleChange"
        />
      </o-field>
      <p data-testid="mailAddressErrorMessage" class="error-message">
        {{ errors[0] }}
      </p>
    </Field>

    <template v-if="!isNew">
      <span class="tooltip-target required-highlight">{{ dict.password.title }}</span>
      <ToolTipIcon :label="dict.password.supplement" />
      <o-field id="password">
        <o-input
          v-model="dict.notes_password"
          data-testid="password"
          name="password"
          :has-counter="false"
          :disabled="true"
        />
      </o-field>
    </template>
    <div v-if="!initialOwner">
      <Field v-slot="{ errors }" v-model="selectedRoleLv" rules="required" :name="dict.role.title">
        <span class="tooltip-target required-highlight">{{ dict.role.title }}</span>
        <ToolTipIcon :label="dict.role.supplement" />
        <v-autocomplete
          v-model="selectedRoleLv"
          class="my-2"
          name="selectRoleLv"
          density="compact"
          bg-color="#fff"
          :items="poiGroupId == 0 ? roleList : excludePrivilegedRole()"
          item-title="caption"
          item-value="roleLv"
          :disabled="userId == user.uuID || onlyOwner"
          data-testid="role"
          variant="outlined"
          placeholder="選択してください"
          color="primary"
          hide-details
          style="max-width: 380px"
        />
        <p data-testid="roleErrorMessage" class="error-message">
          {{ errors[0] }}
        </p>
      </Field>
    </div>

    <!-- オーナー権限 -->
    <template v-if="ownerRoleLv.includes(Number(selectedRoleLv))">
      <!-- メイン担当設定 -->
      <span class="tooltip-target required-highlight">{{ dict.isInCharge.title }}</span>
      <ToolTipIcon :label="dict.isInCharge.supplement" />
      <o-field id="isInCharge">
        <o-checkbox v-model="isInCharge" data-testid="isInCharge">設定する</o-checkbox>
      </o-field>
    </template>

    <!-- グループ権限 -->
    <div v-if="groupRoleLv.includes(Number(selectedRoleLv))">
      <!-- 閲覧可能グループの選択 -->
      <span class="tooltip-target required-highlight">{{ dict.areaTag.title }}</span>
      <ToolTipIcon :label="dict.areaTag.supplement" />
      <auto-complete-card
        ref="areas"
        v-model="areas"
        :items="areaItems"
        label="担当グループを選択してください"
        :hint="null"
        variant="filled"
        :chip-count="10"
        closable-chips
        style="width: 100%"
        :rules="[((value: any[]) => value.length > 0 ? (value.length > 20 ? dict.error_limit_area_tag : true) : dict.error_no_area_tag)]"
        :disabled="userId == user.uuID"
        data-testid="userArea"
      />
    </div>

    <!-- 店舗権限、閲覧可能店舗の選択 -->
    <div v-if="storeRoleLv.includes(Number(selectedRoleLv))" class="mt-4">
      <span class="tooltip-target required-highlight">{{ dict.storeTag.title }}</span>
      <ToolTipIcon :label="dict.storeTag.supplement" />
      <auto-complete-card
        ref="stores"
        v-model="stores"
        :items="storeItems"
        label="担当店舗を選択してください"
        :hint="null"
        variant="filled"
        :chip-count="10"
        :limit-length="20"
        closable-chips
        style="width: 100%"
        :rules="[((value: any[]) => value.length > 0 ? (value.length > 20 ? dict.error_limit_store_tag : true) : dict.error_no_store_tag)]"
        :disabled="canShowAllowedStoresOnly"
      />
    </div>

    <!-- クチコミアラート通知許可グループ -->
    <div v-if="!canShowAllowedStoresOnly" class="mt-4">
      <span class="tooltip-target">{{ dict.areaTagReviewSubscriptions.title }}</span>
      <ToolTipIcon :label="dict.areaTagReviewSubscriptions.supplement" />
      <auto-complete-card
        v-model="reviewAreas"
        :items="reviewAreaItems"
        :label="
          reviewAreaItems.length != 0
            ? '通知を許可するグループ名を入力してください'
            : 'クチコミ機能を契約していないので設定できません'
        "
        :hint="null"
        variant="filled"
        :chip-count="Number.MAX_SAFE_INTEGER"
        closable-chips
        style="width: 100%"
        :disabled="reviewAreaItems.length == 0"
      />
    </div>

    <!-- クチコミアラート通知許可店舗 -->
    <div class="mt-4">
      <span class="tooltip-target">{{ dict.storeTagReviewSubscriptions.title }}</span>
      <ToolTipIcon :label="dict.storeTagReviewSubscriptions.supplement" />
      <auto-complete-card
        v-model="reviewStores"
        :items="reviewStoreItems"
        :label="
          reviewStoreItems.length != 0
            ? '通知を許可する店舗名を入力してください'
            : 'クチコミ機能を契約していないので設定できません'
        "
        :hint="null"
        variant="filled"
        :chip-count="Number.MAX_SAFE_INTEGER"
        closable-chips
        style="width: 100%"
        :disabled="reviewStoreItems.length == 0"
      />
    </div>

    <!-- Google更新通知のON/OFF -->
    <span class="tooltip-target required-highlight">
      {{ dict.locationUpdateNotification.title }}
    </span>
    <ToolTipIcon :label="dict.locationUpdateNotification.supplement" />
    <o-field id="locationUpdateNotification" class="module">
      <o-switch
        v-model="locationUpdateNotificationEnabled"
        data-testid="locationUpdateNotificationEnabled"
        name="locationUpdateNotificationEnabled"
      >
        {{ locationUpdateNotificationEnabled ? "ON" : "OFF" }}
      </o-switch>
    </o-field>

    <!-- 投稿承認通知 -->
    <div v-if="canPostApprove" class="mt-4">
      <span class="tooltip-target required-highlight">{{ dict.postApprove.title }}</span>
      <ToolTipIcon :label="dict.postApprove.supplement" />
      <o-field id="postApprove" class="module">
        <o-switch
          v-model="isApprovePostNotification"
          data-testid="isApprovePostNotification"
          name="locationUpdatePostApprove"
        >
          {{ isApprovePostNotification ? "ON" : "OFF" }}
        </o-switch>
      </o-field>
    </div>

    <!-- MFA -->
    <v-dialog v-model="showMFAResetDialog" max-width="400">
      <v-card>
        <v-card-title>
          <div>
            {{ dict.resetMFACautionDialog.title }}
          </div>
        </v-card-title>
        <v-card-text>
          <p>
            {{ dict.resetMFACautionDialog.contents }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="resetMFARequest">{{ dict.resetMFACautionDialog.submit }}</v-btn>
          <v-btn @click="showMFAResetDialog = false">
            {{ dict.resetMFACautionDialog.cancel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>
      <span class="tooltip-target required-highlight">
        {{ dict.useMFA.title }}
      </span>
      <ToolTipIcon :label="dict.useMFA.supplement" />
      <o-field id="useMFA" class="module">
        <o-switch v-model="useMFA" data-testid="useMFA" name="useMFA">
          {{ useMFA ? "ON" : "OFF" }}
        </o-switch>
      </o-field>
      <span class="tooltip-target required-highlight">
        {{ dict.resetMFA.title }}
      </span>
      <ToolTipIcon :label="dict.resetMFA.supplement" />
      <o-field id="resetMFA" class="module">
        <button class="button is-primary" @click.prevent="showMFAResetDialog = true">
          {{ dict.resetMFA.caption }}
        </button>
      </o-field>
    </div>

    <div>
      <button
        v-show="canShowUser"
        data-testid="goBack"
        class="button is-primary"
        @click.prevent="goBack"
      >
        {{ dict.button_go_back }}
      </button>
      <button
        id="addUser"
        data-testid="submit"
        :disabled="isPushed"
        class="button is-primary"
        @click="submit"
      >
        {{ buttonName() }}
      </button>
    </div>
    <div v-if="message" class="error-message">{{ message }}</div>
    <div v-if="isLoading" class="progress-circular-container">
      <v-progress-circular
        :size="80"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </VeeForm>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
