<template>
  <div
    class=".icon"
    style="text-align: center; padding: 0; margin: 0"
    @click.prevent="disabled ? null : $emit('click')"
  >
    <span v-show="status == 'deselected'">
      <input type="checkbox" value="0" style="color: #fff; cursor: pointer" :disabled="disabled" />
    </span>
    <span v-show="status == 'disabled'">
      <input type="checkbox" value="0" style="cursor: pointer" disabled />
    </span>
    <span v-show="status == 'rejected'" style="color: #e83b3f">
      <v-tooltip location="top" open-on-click>
        <template #activator="{ props }">
          <span v-bind="props">
            <i class="fas fa-ban"></i>
          </span>
        </template>
        <span>{{ rejectMessage }}</span>
      </v-tooltip>
    </span>

    <span v-show="status === 1" class="number">1</span>
    <span v-show="status === 2" class="number">2</span>
    <span v-show="status === 3" class="number">3</span>
    <span v-show="status === 4" class="number">4</span>
    <span v-show="status === 5" class="number">5</span>
    <span v-show="status === 6" class="number">6</span>
    <span v-show="status === 7" class="number">7</span>
    <span v-show="status === 8" class="number">8</span>
    <span v-show="status === 9" class="number">9</span>
    <span v-show="status === 10" class="number">10</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    status: { type: [Number, String], required: true }, // FileSelectionState
    rejectMessage: { type: String, default: "" },
    // readonlyで制御できる要素がないため、disabledのみ設定
    disabled: { type: Boolean, default: false },
  },
  emits: ["click"],
});
</script>

<style lang="scss" scoped>
$number-background: #149477;

.icon {
  display: inline-flex;
}

.v-input__slot {
  align-items: center;
  justify-content: center;
}

.number {
  display: inline-block;
  width: 16px !important;
  height: 16px !important;
  line-height: 16px;
  font-size: 8pt;
  text-align: center;
  background-color: $number-background;
  border-radius: 25%;
  color: white;
  padding: 0;
  margin: 0;
  font-weight: bold;
  cursor: pointer;
}
</style>
