<template>
  <div>
    <post-stepper
      :post-steps="postSteps"
      :post-name="postName"
      :is-mobile="isMobile"
      @scroll-to-step="scrollToStep"
    />
    <div :class="isMobile ? 'mobile-post-form' : 'post-form'">
      <p ref="PostName" class="post-title-0st">投稿を作成・編集</p>
      <p class="post-title-1st">1. 投稿名の設定</p>
      <p class="post-desc text-indent-1">投稿に名称を設定してください。(80文字まで)</p>
      <div class="post-title-row">
        <v-row justify="start">
          <v-col v-if="!isMobile" class="post-title" cols="1">投稿名</v-col>
          <v-col :cols="cols('postName')" :class="isMobile ? 'mobile-post-title' : ''">
            <v-text-field
              v-model="postName"
              density="compact"
              variant="outlined"
              :rules="[rules.required, rules.maxLength(80)]"
              color="primary"
              :label="isMobile ? '投稿名' : ''"
              :readonly="readonly"
            />
          </v-col>
        </v-row>
      </div>
      <p ref="TargetSelect" class="post-title-1st">2. {{ selectStoresDict.Step2Title }}</p>
      <p class="post-desc text-indent-1">投稿対象をお選びください。</p>
      <p class="post-desc text-indent-1">
        {{ selectStoresDict.Step2Description }}
      </p>
      <p class="post-title-2nd text-indent-1">A. 全ての店舗に投稿</p>
      <p class="post-desc text-indent-2">チェックを入れると全ての店舗に投稿します。</p>
      <p>
        <v-checkbox
          v-model="allSelect"
          class="post-check"
          color="primary"
          hide-details
          :readonly="readonly"
          @change="changeTarget"
        >
          <template #label>
            <span class="text-black">全ての店舗を対象とした投稿を作成する</span>
          </template>
        </v-checkbox>
      </p>
      <div v-show="!allSelect">
        <p class="post-title-2nd text-indent-1">B. {{ selectStoresDict.BTitle }}</p>
        <div v-show="0 < areasRows.length">
          <p class="post-title-3rd text-indent-2">グループを選択して投稿</p>
          <p class="post-desc text-indent-2">
            投稿するグループをお選びください。選択されたグループに含まれる店舗すべてが投稿対象となります。
          </p>
          <auto-complete-card
            v-model="selectedAreas"
            :items="areasRows"
            label="グループを選択"
            unit="グループ"
            show-all-select
            class="ml-12"
            :is-mobile="isMobile"
            :readonly="readonly"
            @update:model-value="changeTarget"
          />
        </div>
        <div>
          <p class="post-title-3rd text-indent-2">店舗を選択して投稿</p>
          <p class="post-desc text-indent-2">
            グループを選択した場合は選択されたグループに加えて選択された店舗も投稿対象となります。
          </p>
          <auto-complete-card
            v-model="selectedStores"
            :items="storesRows"
            label="店舗を選択"
            unit="店舗"
            class="ml-12"
            :is-mobile="isMobile"
            :readonly="readonly"
            @update:model-value="changeTarget"
          />
        </div>
      </div>
      <p ref="FileUpload" class="post-title-1st">3. 画像・動画のアップロード</p>
      <div v-if="!isReview" class="file-upload">
        <file-uploader
          ref="fileUploader"
          v-model="fileSelectionMap"
          :loading="loading"
          :is-mobile="isMobile"
          :selected-platform="selecting.platform"
          :aspect-no-check="gbpAspectNoCheck"
          :has-selected-video="hasSelectedVideo"
          :disabled="readonly"
          @set-loading="loading = $event"
          @check-selected-video="checkSelectedVideo"
        />
      </div>
      <div v-else>
        <p class="post-desc text-indent-1">
          レビュー中のため、画像・動画のアップロードはできません。
        </p>
      </div>

      <div>
        <p ref="ImageSelect" class="post-title-1st">4. 投稿する画像・動画の選択</p>
        <v-row class="preview-area">
          <v-col lg="5" md="12">
            <p v-if="!isMobile" class="post-subtitle">4-1. 画像・動画の選択</p>
            <v-checkbox
              v-model="gbpAspectNoCheck"
              class="aspect-no-check"
              color="primary"
              label="Google Business Profile画像・動画の縦横比チェックを行わない"
              hide-details
              density="compact"
              :disabled="readonly"
            ></v-checkbox>
            <file-selection
              ref="fileSelection"
              :google-file-selection="googleFiles"
              :yahoo-file-selection="yahooFiles"
              :instagram-file-selection="instagramFiles"
              :facebook-file-selection="facebookFiles"
              :gpb-aspect-no-check="gbpAspectNoCheck"
              :is-mobile="isMobile"
              :disabled="readonly"
              @delete-img-file="deleteImgFile"
            />
          </v-col>
          <v-col v-if="!isMobile" lg="7" md="12">
            <p class="post-subtitle">4-2. 選択済の画像・動画の編集</p>
            <p class="post-desc">
              メディア毎に選択した画像・動画をご確認ください。投稿される順に左上から並んでいます。
              <br />
              ドラッグ＆ドロップして並び順を入れ替えることで投稿時の順番を決定できます。
              マウスオーバーすると現れる編集ボタンをクリックすると画像・動画のレイアウトの編集が行えます。
              <br />
              なおレイアウトの編集は「6.
              投稿メディア選択および投稿内容カスタマイズ／プレビュー確認」でも行えます。
            </p>
            <thumbnail-list
              v-model:step="postSteps.ImageSelect.childStep[1]"
              class="subcontents"
              :google-file-selection="googleFiles"
              :yahoo-file-selection="yahooFiles"
              :instagram-file-selection="instagramFiles"
              :facebook-file-selection="facebookFiles"
              :readonly="readonly"
              @edit-image="editImage"
            />
            <p class="images-error-message">{{ imageErrorMessage }}</p>
          </v-col>
        </v-row>
      </div>
      <div>
        <p ref="PostText" class="post-title-1st">5. 投稿テキスト入力</p>
        <p class="post-desc text-indent-1">投稿に使用するテキストを入力してください。</p>
        <p class="post-desc text-indent-1">
          なお次の「6.
          投稿メディア選択および投稿内容カスタマイズ／プレビュー確認」でメディアごとにテキストを編集できます。
        </p>
        <p class="post-desc text-indent-1">
          ※Google Business Profileの投稿種別 :
          商品で、絵文字や一部の特殊文字（環境依存文字、４バイト文字等）が存在すると投稿できません
        </p>
        <v-row>
          <v-col :cols="cols('baseText')">
            <v-textarea
              v-model="baseText"
              counter
              persistent-counter
              variant="outlined"
              style="margin: 0 20px"
              color="primary"
              :readonly="readonly"
            />
          </v-col>
        </v-row>
      </div>
      <div>
        <p ref="PostCustomize" class="post-title-1st">
          6. 投稿メディア選択および投稿内容カスタマイズ/プレビュー確認
        </p>
        <p class="post-desc text-indent-1">投稿するメディアを設定してください。</p>
        <p class="post-desc text-indent-1">
          プレビューのテキストエリアをクリックすることで、メディアごとにテキストを変更することが可能です。
        </p>
        <p v-if="!isMobile" class="post-desc text-indent-1">
          プレビューの画像・動画をマウスオーバーすると現れる編集ボタンをタップすることで画像・動画のレイアウトの編集が行えます。
        </p>
        <p class="post-desc text-indent-1">
          また、Google Business Profileは投稿種別の変更やボタンの追加などのカスタマイズもできます。
        </p>
        <mobile-post-preview-area
          v-if="isMobile"
          ref="mobilePreviewArea"
          :post-steps="postSteps"
          :post-g-m-b-form="postGMBForm"
          :post-yahoo-form="postYahooForm"
          :ig-text="igText"
          :fb-text="fbText"
          :base-text="{ gmbBaseText, yahooBaseText, igBaseText, fbBaseText }"
          :target-name="targetName"
          :files="{ googleFiles, yahooFiles, instagramFiles, facebookFiles }"
          :gbp-aspect-no-check="gbpAspectNoCheck"
          :is-edit="isEdit"
          :platform-enabled="{
            instagram: instagramEnabled,
            facebook: facebookEnabled,
            yahooPlace: yahooEnabled,
          }"
          :readonly="readonly"
          @update:model-value="changePostData"
          @edit-image="editImage"
          @update-form:gbp="updateFormGBP"
          @update-form:yahoo="updateFormYahoo"
          @update-text:ig="updateTextIG"
          @update-text:fb="updateTextFB"
          @update-step:gbp="updateStepGBP"
          @update-step:yahoo="updateStepYahoo"
          @update-step:ig="updateStepIG"
          @update-step:fb="updateStepFB"
        />
        <post-preview-area
          v-else
          ref="previewArea"
          :post-steps="postSteps"
          :post-g-m-b-form="postGMBForm"
          :post-yahoo-form="postYahooForm"
          :ig-text="igText"
          :fb-text="fbText"
          :base-text="{ gmbBaseText, yahooBaseText, igBaseText, fbBaseText }"
          :target-name="targetName"
          :files="{ googleFiles, yahooFiles, instagramFiles, facebookFiles }"
          :gbp-aspect-no-check="gbpAspectNoCheck"
          :is-edit="isEdit"
          :platform-enabled="{
            instagram: instagramEnabled,
            facebook: facebookEnabled,
            yahooPlace: yahooEnabled,
          }"
          :readonly="readonly"
          @update:model-value="changePostData"
          @edit-image="editImage"
          @update-form:gbp="updateFormGBP"
          @update-form:yahoo="updateFormYahoo"
          @update-text:ig="updateTextIG"
          @update-text:fb="updateTextFB"
          @update-step:gbp="updateStepGBP"
          @update-step:yahoo="updateStepYahoo"
          @update-step:ig="updateStepIG"
          @update-step:fb="updateStepFB"
        />
      </div>
      <!-- 予約する -->
      <div ref="PostExecute">
        <v-row justify="center">
          <v-col
            :class="isMobile ? 'date-checkbox ps-6' : 'date-checkbox'"
            :cols="cols('dateCheckBox')"
          >
            <v-checkbox
              v-model="isReserved"
              density="compact"
              hide-details
              color="primary"
              :label="postSteps.PostExecute.childStep[0].name"
              :disabled="isReservedDisabled || readonly"
              @change="changeDate"
            />
          </v-col>
          <v-col cols="auto">
            <date-picker
              v-model="reservedDate"
              :min="today"
              :disabled="isReservedDisabled"
              :readonly="readonly"
              class="mt-1"
              @update:model-value="changeDate"
            />
          </v-col>
          <v-col class="date-time" cols="auto">
            <input
              v-model="reservedTime"
              type="time"
              :disabled="isReservedDisabled || readonly"
              class="mt-1"
              @input="changeDate"
            />
          </v-col>
          <v-col v-if="postSteps.PostExecute.childStep[0].errorMessage != ''" cols="auto">
            <p class="error-message">{{ postSteps.PostExecute.childStep[0].errorMessage }}</p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
            :class="isMobile ? 'date-checkbox ps-6' : 'date-checkbox'"
            :cols="cols('dateCheckBox')"
          >
            <v-checkbox
              v-model="isSetEnd"
              density="compact"
              hide-details
              color="primary"
              :label="postSteps.PostExecute.childStep[1].name"
              :disabled="isSetEndDisabled || readonly"
              @change="changeDate"
            />
          </v-col>
          <v-col cols="auto">
            <date-picker
              v-model="endDate"
              :min="today"
              :disabled="isSetEndDisabled"
              :readonly="readonly"
              class="mt-1"
              @update:model-value="changeDate"
            />
          </v-col>
          <v-col class="date-time" cols="auto">
            <input
              v-model="endTime"
              type="time"
              :disabled="isSetEndDisabled || readonly"
              class="mt-1"
              @input="changeDate"
            />
          </v-col>
          <v-col v-if="postSteps.PostExecute.childStep[1].errorMessage != ''" cols="auto">
            <p class="error-message">{{ postSteps.PostExecute.childStep[1].errorMessage }}</p>
          </v-col>
        </v-row>
        <template v-if="isReview">
          <v-divider />
          <!-- 承認レビュー -->
          <v-row justify="center" :class="isMobile ? 'mobile-submit-buttons' : 'submit-buttons'">
            <v-col :offset="isMobile ? '4' : ''" cols="auto">
              <v-btn class="cancel-button" variant="outlined" @click.prevent="cancel">
                キャンセル
              </v-btn>
            </v-col>
            <v-spacer v-if="isMobile" />
            <v-col :cols="cols('saveButton')">
              <v-btn class="save-button" @click.prevent="openRejectConfirm = true">却下</v-btn>
              <approve-modal
                :open="openRejectConfirm"
                title="却下"
                message="投稿申請を却下します"
                :cautions="[
                  '却下すると、申請者にメールが通知されて、申請者が却下理由を確認できます。',
                ]"
                :remark="postRow?.judgementRejectRemark || ''"
                remark-required
                @close-dialog="openRejectConfirm = false"
                @submit="reject"
              />
            </v-col>
            <v-col :cols="cols('postButton')">
              <v-btn color="primary post-button" @click.prevent="requestApprove">承認</v-btn>
              <approve-modal
                :open="openApproveConfirm"
                title="承認"
                message="投稿申請を承認します"
                :cautions="approvalCautions"
                :remark="postRow?.judgementApproveRemark || ''"
                @close-dialog="openApproveConfirm = false"
                @submit="approve"
              />
            </v-col>
          </v-row>
        </template>
        <template v-else-if="isApproveRequest || canPostRequest">
          <!-- 承認要求を依頼する -->
          <v-row justify="center" :class="isMobile ? 'mobile-submit-buttons' : 'submit-buttons'">
            <v-col :offset="isMobile ? '4' : ''" cols="auto">
              <v-btn class="cancel-button" variant="outlined" @click.prevent="cancel">
                キャンセル
              </v-btn>
            </v-col>
            <v-spacer v-if="isMobile" />
            <v-col :cols="cols('saveButton')">
              <v-btn class="save-button" @click.prevent="confirmSave">一時保存する</v-btn>
              <save-modal v-model="openSaveModal" :is-edit="isEdit" @save-post="savePost" />
            </v-col>
            <v-col :cols="cols('postButton')">
              <v-btn color="primary post-button" @click.prevent="postRequest">承認依頼</v-btn>
              <confirm-modal
                :open-confirm="openRequestConfirm"
                :is-reserved="isReserved"
                :reserved-date-time="`${reservedDate.replace(/-/g, '/')} ${reservedTime}`"
                :is-set-end="isSetEnd"
                :end-date-time="`${endDate.replace(/-/g, '/')} ${endTime}`"
                :messages="cautionMessages"
                :is-approve-request="true"
                :is-manual-approve-select="isManualApproveSelect"
                @close-dialog="openRequestConfirm = false"
                @submit-post="request"
              />
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <!-- 通常投稿 -->
          <v-row justify="center" :class="isMobile ? 'mobile-submit-buttons' : 'submit-buttons'">
            <v-col :offset="isMobile ? '4' : ''" cols="auto">
              <v-btn class="cancel-button" variant="outlined" @click.prevent="cancel">
                キャンセル
              </v-btn>
            </v-col>
            <v-spacer v-if="isMobile" />
            <v-col :cols="cols('saveButton')">
              <v-btn v-if="canManage" class="save-button" @click.prevent="confirmSave">
                一時保存する
              </v-btn>
              <save-modal v-model="openSaveModal" :is-edit="isEdit" @save-post="savePost" />
            </v-col>
            <v-col :cols="cols('postButton')">
              <v-btn v-if="canManage" class="primary post-button" @click.prevent="confirm">
                投稿する
              </v-btn>
              <confirm-modal
                :open-confirm="openConfirm"
                :is-reserved="isReserved"
                :reserved-date-time="`${reservedDate.replace(/-/g, '/')} ${reservedTime}`"
                :is-set-end="isSetEnd"
                :end-date-time="`${endDate.replace(/-/g, '/')} ${endTime}`"
                :messages="cautionMessages"
                @close-dialog="openConfirm = false"
                @submit-post="submit"
              />
            </v-col>
          </v-row>
        </template>
      </div>
      <!-- 画像・動画のレイアウト編集ダイアログ -->
      <image-editor
        :file-selection-map="fileSelectionMap"
        :prop-selecting="selecting"
        :open-editor="openEditor"
        :gbp-aspect-no-check="gbpAspectNoCheck"
        @close-dialog="closeDialog"
      />
    </div>
    <div v-if="loading" class="progress-circular-container">
      <v-progress-circular
        :size="80"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <reload-confirm-modal
      :open-reload-confirm="openReloadConfirm"
      @reload-post-histories="reloadPostHistories"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, toNative } from "vue-facing-decorator";
import { useSnackbar } from "@/storepinia/snackbar";
import type {
  EntitiesAreasResponse,
  EntitiesPostGMBResponse,
  EntitiesStore,
  EntitiesStoresResponse,
  EntitiesV2PostFile,
  EntitiesV2YahooPostData,
  EntitiesV2InstagramPostData,
  EntitiesV2FacebookPostData,
  EntitiesGetPostImageResponse,
  EntitiesV2GMBPostData,
  EntitiesPostBulkPostImageFin,
  ControllersApproveJudgmentInput,
  ControllersApproveJudgmentOutput,
  EntitiesAreaStores,
} from "@/types/ls-api";
import axios from "axios";
import dayjs from "dayjs";
import AutoCompleteCard from "@/components/shared/auto-complete-card/AutoCompleteCard.vue";
import type { AreaStoresItem, HttpMethod } from "@/helpers";
import { VuetifyValidator } from "@/helpers";
import type { CustomSnackbarToast } from "@/helpers/request";
import { requiredAuth } from "@/helpers/request";
import DatePicker from "@/components/shared/date-picker/DatePicker.vue";
import TimePicker from "@/components/shared/time-picker";
import wordDictionary, { postStoresSelectDict } from "@/word-dictionary";
import type { PostStoresSelectDict } from "@/word-dictionary";
import { TOAST_CRITICAL_DURATION } from "@/const";
import type {
  EditImageSelected,
  FileNameObj,
  FileRevisions,
  FileSelectionItem,
  FileSelectionMap,
  FileSelectionState,
  PlatformName,
} from "@/models/v2-file-selection";
import {
  getExtension,
  getFileNameWithExtension,
  getMimeType,
  getObjectURL,
  getVideoElement,
  isImage,
  isVideo,
  sortFileSelectionItemList,
  platformOfficialNames,
} from "@/models/v2-file-selection";
import type { PostSteps } from "./post-stepper.vue";
import { StepStatus } from "./post-stepper.vue";
import PostStepper from "./post-stepper.vue";
import FileUploader from "./file-uploader.vue";
import FileSelection from "./file-selection.vue";
import ThumbnailList from "./thumbnail-list.vue";
import ImageEditor from "./image-editor.vue";
import PostPreviewArea from "./post-preview-area.vue";
import MobilePostPreviewArea from "./mobile-post-preview-area.vue";
import SaveModal from "./save-modal.vue";
import ConfirmModal from "./confirm-modal.vue";
import { CautionMessage } from "./confirm-modal.vue";
import ReloadConfirmModal from "./reload-confirm-modal.vue";
import ApproveModal from "./approve-modal.vue";
import { PostData } from "./post-request";
import {
  PostGMBForm,
  PostGMBData,
  PostGMBInfo,
  PostGMBEvent,
  PostGMBEventDetail,
  PostGMBBenefits,
  PostGMBBenefitsDetail,
  PostGMBProduct,
  PostGMBCovid19,
} from "./gmb/gmb-request";
import { YahooPostForm } from "./yahoo/yahoo-request";
import { getOperationLogParams } from "@/routes/operation-log";
import type { FileValidationResult, HasSelectedVideo, FileAmountsOfPlatforms } from "./validator";
import {
  validateExtension,
  validateFileSize,
  validateNumberOfFiles,
  validateVideo,
} from "./validator";
import { getter, action, useIndexedDb } from "@/storepinia/idxdb";
import { makeSelectItems, type SelectItem } from "@/helpers/select-items";
import {
  isNotYetPublished,
  isNewRequest,
  determineNewActionType,
  determineEditActionType,
  determineApproveActionType,
  determineRejectActionType,
} from "@/helpers/post-status";

type Item = SelectItem & { areas?: number[] };

@Component({
  components: {
    AutoCompleteCard,
    DatePicker,
    TimePicker,
    PostStepper,
    FileUploader,
    FileSelection,
    ThumbnailList,
    ImageEditor,
    PostPreviewArea,
    MobilePostPreviewArea,
    SaveModal,
    ConfirmModal,
    ReloadConfirmModal,
    ApproveModal,
  },
})
class V2Posts extends Vue {
  areaStores = getter().areaStores;
  company = getter().company;
  stores = getter().stores;
  postRow = getter().postRow;
  canManage = getter().canManagePost;
  canPostRequest = getter().canPostRequest; // 投稿承認要求権限のユーザーはtrue
  facebookEnabled = getter().facebookEnabled;
  instagramEnabled = getter().instagramEnabled;
  yahooEnabled = getter().yahooEnabled;
  user = getter().user;
  isMobile = getter().isMobile;
  setPostRow = action().setPostRow;
  addSnackbarMessages = useSnackbar().addSnackbarMessages;
  @Prop({ default: "" }) mode: string;

  poiGroupId: number;
  enabledStores: EntitiesStore[];
  postData: PostData = new PostData();
  rules: typeof VuetifyValidator = VuetifyValidator;
  gbpAspectNoCheck = false;

  // ガイドラインのステップ一覧（詳細ステップがある場合はchild内に配列で定義）
  postSteps: PostSteps = {
    PostName: new StepStatus({ name: "投稿名の設定", index: 1 }),
    TargetSelect: new StepStatus({ name: "投稿するグループ／店舗の選択", index: 2 }),
    FileUpload: new StepStatus({ name: "画像・動画のアップロード", index: 3 }),
    ImageSelect: new StepStatus({
      name: "投稿する画像の選択",
      index: 4,
      childStep: [
        new StepStatus({ name: "投稿イメージ選択" }),
        new StepStatus({ name: "選択済みイメージの編集" }),
      ],
    }),
    PostText: new StepStatus({ name: "投稿テキスト入力", index: 5 }),
    PostCustomize: new StepStatus({
      name: "投稿カスタマイズ／プレビュー確認",
      index: 6,
      enabled: false,
      childStep: [
        new StepStatus({ name: platformOfficialNames.google, enabled: false }),
        new StepStatus({ name: platformOfficialNames.yahoo, enabled: false }),
        new StepStatus({ name: platformOfficialNames.instagram, enabled: false }),
        new StepStatus({ name: platformOfficialNames.facebook, enabled: false }),
      ],
    }),
    PostExecute: new StepStatus({
      name: "投稿",
      index: 7,
      enabled: false, // 予約と削除予約日は設定しなくても良いのでfalseにしておく
      childStep: [
        new StepStatus({ name: "投稿開始日時を設定する" }),
        new StepStatus({ name: "削除開始日時を設定する" }),
      ],
    }),
  };
  updateFormGBP(form: PostGMBForm) {
    this.postGMBForm = form;
  }
  updateFormYahoo(form: YahooPostForm) {
    this.postYahooForm = form;
  }
  updateTextIG(value: string) {
    this.igText = value;
  }
  updateTextFB(value: string) {
    this.fbText = value;
  }
  updateStepGBP = this.genUpdateStep(this.postSteps.PostCustomize.childStep[0]);
  updateStepYahoo = this.genUpdateStep(this.postSteps.PostCustomize.childStep[1]);
  updateStepIG = this.genUpdateStep(this.postSteps.PostCustomize.childStep[2]);
  updateStepFB = this.genUpdateStep(this.postSteps.PostCustomize.childStep[3]);

  loading = false;

  // グループ・店舗選択
  allSelect = false;
  areasRows: SelectItem[] = [];
  selectedAreas: number[] = [];
  storesRows: Item[] = [];
  selectedStores: number[] = [];
  storeCount = 0;
  reportAreaStores: AreaStoresItem[] = [];

  // 投稿先をkeyとして、Fileオブジェクトを配列で持つ(順序は掲載順)
  googleFiles: FileSelectionItem[] = [];
  yahooFiles: FileSelectionItem[] = [];
  instagramFiles: FileSelectionItem[] = [];
  facebookFiles: FileSelectionItem[] = [];
  fileSelectionMap: FileSelectionMap = new Map<PlatformName, FileSelectionItem[]>([
    ["google", this.googleFiles],
    ["yahoo", this.yahooFiles],
    ["instagram", this.instagramFiles],
    ["facebook", this.facebookFiles],
  ]);
  // S3にアップロード済みのファイル。ローカルファイル名をKey、S3ファイル名をValueに持つ
  uploadedFiles = new Map<string, string>();
  // PostData の Files
  postFiles: EntitiesV2PostFile[] = [];

  // 画像ファイルに関するエラーメッセージを集約
  imageErrorMessage: string = "";

  // 画像・動画のレイアウト編集
  selecting: EditImageSelected = { platform: "google", order: 0 };
  openEditor: boolean = false;
  // 投稿名
  postName: string = "";

  // 店舗名（プレビュー表示用に対象店舗のひとつを設定）
  targetName: string = "";
  // 共通投稿テキスト
  baseText: string = "";

  // プラットフォームごとの初期投稿テキスト
  gmbBaseText: string = "";
  yahooBaseText: string = "";
  igBaseText: string = "";
  fbBaseText: string = "";

  // プラットフォームごとのカスタマイズ投稿テキスト
  gmbText: string = "";
  yahooText: string = "";
  igText: string = "";
  fbText: string = "";

  // GBPの投稿種別
  postGMBForm: PostGMBForm = new PostGMBForm();
  postYahooForm: YahooPostForm = new YahooPostForm();

  // 日付入力制御用
  today = dayjs().format("YYYY-MM-DD");
  isReserved: boolean = false;
  isReservedDisabled: boolean = false;
  reservedDate: string = "";
  reservedTime: string = "00:00";
  isSetEnd: boolean = false;
  isSetEndDisabled: boolean = false;
  endDate: string = "";
  endTime: string = "00:00";

  // 一時保存確認画面制御用
  openSaveModal: boolean = false;
  doSavePost: boolean = false;

  // 最終確認画面制御用
  openConfirm: boolean = false;
  openReloadConfirm: boolean = false;

  /** 壊れた動画ファイルかもしれないフラグ */
  brokenVideo = false;
  private isNewPost = false;

  // 選択済み動画があるかのチェックフラグ
  hasSelectedVideo: HasSelectedVideo = {
    google: 0,
    yahoo: 0,
    instagram: 0,
    facebook: 0,
  };

  // 承認時に手動で承認者を選択できる場合にtrueを返す
  get isManualApproveSelect(): boolean {
    return this.company.options?.includes("v2-post-approval-manual");
  }
  // 承認時ダイアログ表示用
  openApproveConfirm: boolean = false;
  // 承認却下時ダイアログ表示用
  openRejectConfirm: boolean = false;
  // 承認要求時ダイアログ表示用
  openRequestConfirm: boolean = false;

  get isEdit(): boolean {
    return (
      (this.mode === "edit" || this.mode == "request" || this.$route.path.includes("edit")) &&
      !this.postRow?.incompleteNew
    );
  }

  get isReview(): boolean {
    return this.mode === "review"; // 承認レビューはtrue(承認者向け)
  }

  get readonly(): boolean {
    // 閲覧権限及びレビュワーの場合は内容を操作できないように制限する
    return !this.canManage || this.isReview;
  }

  get isApproveRequest(): boolean {
    // 承認要求の編集はtrue(承認要求者向け)
    return this.mode === "request";
  }

  get selectStoresDict(): PostStoresSelectDict {
    return postStoresSelectDict(useIndexedDb().canShowAllowedStoresOnly);
  }

  get cautionMessages(): CautionMessage[] {
    const dict = wordDictionary.v2post.caution;
    const messages: CautionMessage[] = [new CautionMessage(dict.publicationDelayWarning)];
    const enabledPlatforms = this.postSteps.PostCustomize.childStep
      .filter((c) => c.enabled)
      .map((c) => c.name);
    if (
      enabledPlatforms.includes(platformOfficialNames.google) &&
      this.postGMBForm?.postType === "product"
    ) {
      messages.push(new CautionMessage(dict.gbpProduct));
    }
    if (enabledPlatforms.includes(platformOfficialNames.instagram)) {
      messages.push(new CautionMessage(dict.instagram));
    }
    if (enabledPlatforms.includes(platformOfficialNames.facebook)) {
      messages.push(new CautionMessage(dict.facebook));
    }
    if (this.brokenVideo) {
      messages.push(new CautionMessage(dict.brokenVideo, "warning-message"));
    }
    if (this.isApproveRequest) {
      messages.push(new CautionMessage(dict.reviewRequest));
    }
    return messages;
  }

  /** 承認時にダイアログに出すメッセージ */
  get approvalCautions(): string[] {
    const caution = [wordDictionary.v2post.caution.postApproval];
    if (this.postRow?.GMB?.product) {
      caution.push(wordDictionary.v2post.caution.gbpProduct);
    }
    return caution;
  }

  cols(step: string): number | "auto" {
    switch (step) {
      case "postName":
        return this.isMobile ? 11 : 5;
      case "baseText":
        return this.isMobile ? 12 : 6;
      case "dateCheckBox":
        return this.isMobile ? 12 : "auto";
      case "saveButton":
        return this.isMobile ? 6 : "auto";
      case "postButton":
        return this.isMobile ? 6 : "auto";
    }
  }

  async created(): Promise<void> {
    // URL直打ちで「投稿を作成・編集」ページを開いた場合は投稿履歴に戻す
    if (this.isEdit && !this.postRow) {
      this.$router.push({ path: `/companies/${this.company.poiGroupID}/v2posts/histories` });
      return;
    }
    if (
      this.company.poiGroupID !== this.postRow?.poiGroupID ||
      (!this.isEdit && !this.isReview && !this.isApproveRequest && !this.postRow?.incompleteNew) ||
      this.mode === "new"
    ) {
      // 編集でも一時保存でも承認レビューでも承認要求編集でもない ＝ 新規投稿の場合のみクリア（編集→ブラウザバックなどでpostRowが残るため）
      // 管理者が途中で企業を切り替えてた場合もクリア
      // 「投稿を作成」ボタンクリックでの遷移による新規投稿だったら問答無用でクリア
      this.setPostRow(null);
      this.isNewPost = true;
    } else if (!this.postRow) {
      this.addSnackbarMessages({
        text: "編集対象の投稿が見つかりませんでした",
        color: "danger",
      });
      this.$router.push({ name: "V2PostHistories" });
      return;
    }
    this.loading = true;
    this.poiGroupId = this.company.poiGroupID;
    this.enabledStores = this.stores.stores?.filter((s) => s?.enabled === true);
    this.postGMBForm = new PostGMBForm();
    this.postGMBForm.postType = "info";
    this.postGMBForm.button = "なし";
    this.postGMBForm.buttonUrl = "";
    this.postGMBForm.start = dayjs().format("YYYY-MM-DD");
    this.postYahooForm = new YahooPostForm();
    // 企業オプションを見てデフォルト値を決める
    this.gbpAspectNoCheck = this.company?.allowAnyAspectRatio?.googleBusinessProfile ?? false;

    // プルダウンを作成する
    this.allSelect = false;
    await this.setupPulldown(this.poiGroupId, this.postRow?.userName);

    if (this.isEdit || this.isApproveRequest || this.isReview || this.postRow?.incompleteNew) {
      this.postName = this.postRow.title;
      if (!this.postRow.GMB) {
        this.postSteps.PostCustomize.childStep[0].enabled = false;
      }
      if (!this.postRow.Yahoo) {
        this.postSteps.PostCustomize.childStep[1].enabled = false;
      }
      if (!this.postRow.Instagram) {
        this.postSteps.PostCustomize.childStep[2].enabled = false;
      }
      if (!this.postRow.Facebook) {
        this.postSteps.PostCustomize.childStep[3].enabled = false;
      }
      this.allSelect = this.postRow.targets.all;
      if (!this.allSelect) {
        this.selectedAreas = this.areasRows
          .filter((area) => this.postRow.targets.areas?.indexOf(area.id) > -1)
          .map((area) => area.id);
        this.selectedAreas = this.selectedAreas.filter((area, index, array) => {
          return array.findIndex((area2) => area === area2) === index;
        });
        this.selectedStores = this.storesRows
          .filter((row) => this.postRow.targets.poiIDs?.includes(row.id))
          .map((row) => row.id);
        this.selectedStores = this.selectedStores.filter((id, index, array) => {
          return array.findIndex((store2) => id === store2) === index;
        });
      }
      this.baseText = this.postRow.text ?? "";
      this.postFiles = this.postRow.files ?? [];
      this.isReservedDisabled = true;
      if (isNotYetPublished(this.postRow) || isNewRequest(this.postRow)) {
        this.isReservedDisabled = false;
      }
      if (this.postRow.posting?.length > 0) {
        this.isReserved = true;
        const posting = dayjs(this.postRow.posting);
        this.reservedDate = posting.format("YYYY-MM-DD");
        this.reservedTime = posting.format("HH:mm");
      }
      this.isSetEndDisabled = false;
      if (
        this.postRow.state === "INPROGRESS" ||
        this.postRow.state === "EDIT" ||
        this.postRow.state === "DELETE" ||
        this.postRow.state === "DELETEFINISHED" ||
        this.postRow.state === "UNKNOWN"
      ) {
        this.isSetEndDisabled = true;
      }
      if (this.postRow.cancellationReservation?.length > 0) {
        this.isSetEnd = true;
        const cancellation = dayjs(this.postRow.cancellationReservation);
        this.endDate = cancellation.format("YYYY-MM-DD");
        this.endTime = cancellation.format("HH:mm");
      }
      this.changeDate();

      this.gbpAspectNoCheck = this.postRow.aspectNoCheck ?? false;
      let s3Items: FileSelectionItem[] = [];
      if (this.postFiles?.length > 0) {
        s3Items = await this.getS3Items(this.postFiles);
      }
      if (this.postRow.GMB) {
        this.postSteps.PostCustomize.childStep[0].enabled =
          this.postRow?.incompleteNew || this.postRow?.incompleteEdit ? false : true;
        await this.loadGMBItems(s3Items, this.postRow.GMB);
      } else {
        this.googleFiles = await this.loadItems("google", s3Items, []);
        this.postSteps.PostCustomize.childStep[0].enabled = false;
      }
      if (this.postRow.Yahoo) {
        this.postSteps.PostCustomize.childStep[1].enabled =
          this.postRow?.incompleteNew || this.postRow?.incompleteEdit ? false : true;
        await this.loadYahooItems(s3Items, this.postRow.Yahoo);
      } else {
        this.yahooFiles = await this.loadItems("yahoo", s3Items, []);
        this.postSteps.PostCustomize.childStep[1].enabled = false;
      }
      if (this.postRow.Instagram) {
        this.postSteps.PostCustomize.childStep[2].enabled =
          this.postRow?.incompleteNew || this.postRow?.incompleteEdit ? false : true;
        await this.loadInstagramItems(s3Items, this.postRow.Instagram);
      } else {
        this.instagramFiles = await this.loadItems("instagram", s3Items, []);
        this.postSteps.PostCustomize.childStep[2].enabled = false;
      }
      if (this.postRow.Facebook) {
        this.postSteps.PostCustomize.childStep[3].enabled =
          this.postRow?.incompleteNew || this.postRow?.incompleteEdit ? false : true;
        await this.loadFacebookItems(s3Items, this.postRow.Facebook);
      } else {
        this.facebookFiles = await this.loadItems("facebook", s3Items, []);
        this.postSteps.PostCustomize.childStep[3].enabled = false;
      }
    }
    this.fileSelectionMap.set("google", this.googleFiles);
    this.fileSelectionMap.set("yahoo", this.yahooFiles);
    this.fileSelectionMap.set("instagram", this.instagramFiles);
    this.fileSelectionMap.set("facebook", this.facebookFiles);
    this.markAncestor();

    this.targetName = this.enabledStores.length > 0 ? this.enabledStores[0].name : "";
    this.postData = new PostData();
    this.postData.poiGroupID = this.poiGroupId;
    this.loading = false;

    // ページ開いた直後二重チェック走らない様ここでwatch登録する
    this.$watch("gbpAspectNoCheck", this.onAspectNoCheckChange);
  }

  // 投稿者の店舗・エリア一覧を取得
  // 取得できない場合は undefined を返す
  async fetchPosUserStoresAndAreas(
    poiGroupID: number,
    uuid: string
  ): Promise<{ postUserStores: EntitiesStore[]; postUserAreas: EntitiesAreaStores[] }> {
    let stores: EntitiesStore[] = undefined;
    let areas: EntitiesAreaStores[] = undefined;
    const params = { uuid: uuid };
    try {
      const resStores = await requiredAuth<EntitiesStoresResponse>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupID}/stores`,
        params
      );
      stores = resStores.data.stores;
      if (useIndexedDb().canShowAllowedStoresOnly) {
        areas = [];
      } else {
        try {
          const resAreas = await requiredAuth<EntitiesAreasResponse>(
            "get",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupID}/areas`,
            params,
            null,
            null,
            { statusCode: 403 } as CustomSnackbarToast
          );
          areas = resAreas.data.areas;
        } catch (e) {
          areas = [];
        }
      }
    } catch (e) {
      // 投稿者はすでに削除されているので、現在のユーザーが見える店舗・エリアで処理を続行させる必要がある
      stores = undefined;
      areas = undefined;
    }
    return { postUserStores: stores, postUserAreas: areas };
  }

  async setupPulldown(poiGroupID: number, uuid: string) {
    let stores = useIndexedDb().stores.stores;
    let areas = useIndexedDb().areaStores;
    if (uuid) {
      const { postUserStores, postUserAreas } = await this.fetchPosUserStoresAndAreas(
        poiGroupID,
        uuid
      );
      if (postUserStores !== undefined) {
        stores = postUserStores;
        areas = postUserAreas;
      } else {
        // undefinedが帰る場合はすでに削除されたユーザーの投稿だと思われるので、現在のユーザーが見える範囲の店舗・エリアを使い、投稿者も自分に書き換える
        this.postRow.userName = this.user.uuID;
      }
    }
    const post = wordDictionary.stores.options.post;
    const isPostEnabled = (s: EntitiesStore) =>
      s.enabled && s.gmbStoreCode?.length > 0 && s.options?.includes(post);
    stores = stores.filter(isPostEnabled);
    // グループと店舗の選択肢を生成
    ({ areas: this.areasRows, stores: this.storesRows } = makeSelectItems(areas, stores, true));

    // 投稿可能な店舗が1件もなければ未契約画面へ遷移
    if (this.storesRows.filter((s) => s?.id).length === 0) {
      this.$router.push({ name: "InquiriesForm", query: { from: this.$route.path } });
      return;
    }
    // 重複を除外した店舗数を計算する
    const storeIds: { [id: string]: any } = {};
    this.storesRows.filter((s) => s?.id).forEach((s) => (storeIds[s.id] = true));
    this.storeCount = Object.keys(storeIds).length;
  }

  editImage(selected: EditImageSelected): void {
    this.selecting = Object.assign({}, this.selecting, selected);
    this.openEditor = true;
  }

  async getS3Items(postFiles: EntitiesV2PostFile[]): Promise<FileSelectionItem[]> {
    const results: FileSelectionItem[] = [];
    for (const postFile of postFiles) {
      let blob = null;
      const resImage = await requiredAuth<EntitiesGetPostImageResponse>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v2/companies/${this.poiGroupId}/post/image`,
        { path: postFile.filePath }
      );
      const preSignedUrl = resImage.data?.url;
      if (preSignedUrl && preSignedUrl.length > 0) {
        await fetch(preSignedUrl)
          .then((res) => res.blob())
          .then((b) => {
            blob = b;
          })
          .catch((ex) => {
            this.addSnackbarMessages({
              text: "画像ファイルの取得に失敗しました",
              color: "danger",
            });
          });
      }

      let imageUrl = "";
      let videoUrl = "";
      const ext = getExtension(postFile.fileName);
      const mimeType = getMimeType(ext);
      let file: File;
      if (blob) {
        file = new File([blob], postFile.fileName, {
          type: mimeType,
        });
        [imageUrl, videoUrl] = await getObjectURL(file, ext);
      }

      results.push({
        file,
        imageUrl,
        videoUrl,
        s3FileName: postFile.filePath,
        state: "deselected",
        rejectMessage: "",
      });
      this.uploadedFiles[postFile.fileName] = postFile.filePath;
    }
    return results;
  }

  async loadItems(
    platform: PlatformName,
    s3Items: FileSelectionItem[],
    images: string[]
  ): Promise<FileSelectionItem[]> {
    let selectedImages = 0;
    let selectedVideos = 0;

    // 投稿済の画像・動画に投稿順を設定
    const items = s3Items.map((s3Item) => {
      let state: FileSelectionState = "deselected";
      if (images) {
        const num = images.indexOf(s3Item.s3FileName);
        if (num > -1) {
          state = num + 1;
          if (platform === "google") {
            if (isImage(s3Item.file) || isVideo(s3Item.file)) {
              selectedImages++;
            }
          } else {
            if (isImage(s3Item.file)) {
              selectedImages++;
            }
          }
          if (isVideo(s3Item.file)) {
            selectedVideos++;
          }
        }
      }
      const item: FileSelectionItem = {
        file: s3Item.file,
        imageUrl: s3Item.imageUrl,
        videoUrl: s3Item.videoUrl,
        s3FileName: s3Item.s3FileName,
        state,
        rejectMessage: s3Item.rejectMessage,
      };
      return item;
    });

    this.hasSelectedVideo[platform] = selectedVideos;
    const postedItems = this.validateFiles(items, platform, selectedImages);
    return postedItems;
  }

  async loadGMBItems(s3Items: FileSelectionItem[], postData: EntitiesV2GMBPostData): Promise<void> {
    this.postGMBForm = new PostGMBForm();
    let images: string[] = [];
    if (postData.info) {
      images = postData.info.gmbImages;
      this.postGMBForm.postType = "info";
      this.postGMBForm.title = postData.info.title;
      this.postGMBForm.button = postData.info.button;
      this.postGMBForm.buttonUrl = postData.info.buttonUrl;
      this.gmbBaseText = postData.info.title ?? "";
    } else if (postData.event) {
      images = postData.event.gmbImages;
      this.postGMBForm.postType = "event";
      this.postGMBForm.title = postData.event.title;
      this.postGMBForm.start = postData.event.start;
      this.postGMBForm.end = postData.event.end;
      this.postGMBForm.startTime = postData.event.startTime;
      this.postGMBForm.endTime = postData.event.endTime;
      this.postGMBForm.detail = postData.event.details?.detail;
      this.postGMBForm.button = postData.event.details?.button;
      this.postGMBForm.buttonUrl = postData.event.details?.buttonUrl;
      this.gmbBaseText = postData.event.details?.detail ?? "";
    } else if (postData.benefits) {
      images = postData.benefits.gmbImages;
      this.postGMBForm.postType = "benefits";
      this.postGMBForm.title = postData.benefits.title;
      this.postGMBForm.start = postData.benefits.start;
      this.postGMBForm.end = postData.benefits.end;
      // FIXME: startTimeとendTimeを引き継がないために削除済み(空欄にしないとバグで編集不可の可能性)
      this.postGMBForm.detail = postData.benefits.details?.detail;
      this.postGMBForm.couponCode = postData.benefits.details?.couponCode;
      this.postGMBForm.link = postData.benefits.details?.link;
      this.postGMBForm.termsOfService = postData.benefits.details?.termsOfService;
      this.gmbBaseText = postData.benefits.details?.detail ?? "";
    } else if (postData.product) {
      images = postData.product.gmbImages;
      this.postGMBForm.postType = "product";
      this.postGMBForm.title = postData.product.title;
      this.postGMBForm.detail = postData.product.explain;
      this.postGMBForm.category = postData.product.category;
      this.postGMBForm.price = postData.product.price;
      this.postGMBForm.button = postData.product.button;
      this.postGMBForm.buttonUrl = postData.product.buttonUrl;
      this.gmbBaseText = postData.product.explain ?? "";
    } else if (postData.covid19) {
      this.postGMBForm.postType = "covid19";
      this.postGMBForm.title = postData.covid19.title;
      this.postGMBForm.button = postData.covid19.button;
      this.postGMBForm.buttonUrl = postData.covid19.buttonUrl;
      this.gmbBaseText = postData.covid19.title ?? "";
    }
    this.googleFiles = await this.loadItems("google", s3Items, images);
  }

  async loadYahooItems(
    s3Items: FileSelectionItem[],
    postData: EntitiesV2YahooPostData
  ): Promise<void> {
    this.postYahooForm = new YahooPostForm();
    this.postYahooForm.convertFrom(postData);
    this.yahooBaseText = postData.text ?? "";
    const images: string[] = postData.mediaUrls;
    this.yahooFiles = await this.loadItems("yahoo", s3Items, images);
  }

  async loadInstagramItems(
    s3Items: FileSelectionItem[],
    postData: EntitiesV2InstagramPostData
  ): Promise<void> {
    const images: string[] = [postData.imageUrl];
    this.instagramFiles = await this.loadItems("instagram", s3Items, images);
    this.igBaseText = postData.text ?? "";
  }

  async loadFacebookItems(
    s3Items: FileSelectionItem[],
    postData: EntitiesV2FacebookPostData
  ): Promise<void> {
    const images: string[] = postData.mediaUrls;
    this.facebookFiles = await this.loadItems("facebook", s3Items, images);
    this.fbBaseText = postData.text ?? "";
  }

  @Watch("baseText", { immediate: true })
  syncBaseText(): void {
    this.gmbBaseText = this.baseText;
    this.yahooBaseText = this.baseText;
    this.igBaseText = this.baseText;
    this.fbBaseText = this.baseText;
  }

  confirmSave(): void {
    this.openSaveModal = true;
  }

  async savePost(): Promise<void> {
    this.openSaveModal = false;
    this.doSavePost = true;
    await this.submit();
  }

  async checkPostStepsAndVideoFiles(): Promise<boolean> {
    // 最終的なステータス更新を行う
    this.validateAllFiles();
    this.updateStepStatus(this.postSteps.PostCustomize, {});

    // 予約と削除予約日以外は必須
    const isValidate = Object.keys(this.postSteps)
      .map((p) =>
        p == "PostExecute"
          ? this.postSteps[p].enabled
            ? this.postSteps[p].isComplete
            : true
          : this.postSteps[p].isComplete
      )
      .every((v) => v);
    // 破損可能性のある動画が含まれていないかチェックする
    this.brokenVideo = false;
    let enabledPlatforms: string[] = [];
    if (this.doSavePost) {
      enabledPlatforms = Object.values(platformOfficialNames);
    } else {
      enabledPlatforms = this.postSteps.PostCustomize.childStep
        .filter((c) => c.enabled)
        .map((c) => c.name);
    }

    let imageFiles: FileSelectionItem[] = [];
    for (const platform of enabledPlatforms) {
      if (platform === platformOfficialNames.google) {
        imageFiles = this.googleFiles;
      } else if (platform === platformOfficialNames.yahoo) {
        imageFiles = this.yahooFiles;
      } else if (platform === platformOfficialNames.instagram) {
        imageFiles = this.instagramFiles;
      } else if (platform === platformOfficialNames.facebook) {
        imageFiles = this.facebookFiles;
      }
      for (let i = 0; i < imageFiles.length; i++) {
        // 動画ファイルが選択に含まれていたら
        if (typeof imageFiles[i].state === "number" && isVideo(imageFiles[i].file)) {
          await getVideoElement(imageFiles[i].videoUrl)
            .then((res) => {
              // 破損動画のチェックなので成功したら何もしない
            })
            .catch((ex) => {
              this.brokenVideo = true;
            });
        }
        if (this.brokenVideo) {
          break;
        }
      }
    }
    return isValidate;
  }

  async postRequest(): Promise<void> {
    const isValidate = await this.checkPostStepsAndVideoFiles();
    if (isValidate) {
      this.openRequestConfirm = true;
    } else {
      this.addSnackbarMessages({
        text: "投稿内容に不備があります。各ステップのエラーをご確認の上修正してください",
        color: "danger",
      });
    }
  }

  async requestApprove(): Promise<void> {
    const isValidate = await this.checkPostStepsAndVideoFiles();
    if (isValidate) {
      this.openApproveConfirm = true;
    } else {
      this.addSnackbarMessages({
        text: "投稿内容に不備があります。投稿を却下して修正を依頼することができます",
        color: "danger",
      });
    }
  }

  async confirm(): Promise<void> {
    const isValidate = await this.checkPostStepsAndVideoFiles();
    if (isValidate) {
      this.openConfirm = true;
    } else {
      this.addSnackbarMessages({
        text: "投稿内容に不備があります。各ステップのエラーをご確認の上修正してください",
        color: "danger",
      });
    }
  }

  async submit(): Promise<void> {
    this.openConfirm = false;
    this.loading = true;
    let enabledPlatforms: string[] = [];
    if (this.doSavePost) {
      enabledPlatforms = Object.values(platformOfficialNames);
    } else {
      enabledPlatforms = this.postSteps.PostCustomize.childStep
        .filter((c) => c.enabled)
        .map((c) => c.name);
    }

    let isFilesUploaded = true;
    let imageFiles: FileSelectionItem[] = [];

    for (const platform of enabledPlatforms) {
      if (platform === platformOfficialNames.google) {
        imageFiles = this.googleFiles;
      } else if (platform === platformOfficialNames.yahoo) {
        imageFiles = this.yahooFiles;
      } else if (platform === platformOfficialNames.instagram) {
        imageFiles = this.instagramFiles;
      } else if (platform === platformOfficialNames.facebook) {
        imageFiles = this.facebookFiles;
      }
      for (let i = 0; i < imageFiles.length; i++) {
        if (typeof imageFiles[i].state === "number") {
          let s3FileName = this.uploadedFiles[imageFiles[i].file.name];
          if (!s3FileName || s3FileName.length === 0) {
            s3FileName = await this.uploadImage(imageFiles[i].file);
            // S3のファイル名が取得できなければアップロード失敗として投稿を行わない
            if (s3FileName.length === 0) {
              isFilesUploaded = false;
              break;
            }
            this.uploadedFiles[imageFiles[i].file.name] = s3FileName;
            this.postFiles.push({
              fileName: imageFiles[i].file.name,
              filePath: s3FileName,
            });
          }
          imageFiles[i].s3FileName = s3FileName;
        }
      }
      if (!isFilesUploaded) {
        break;
      }
    }

    if (isFilesUploaded) {
      await this.postExec(enabledPlatforms);
    }
    this.loading = false;
  }
  async reloadPostHistories(): Promise<void> {
    await this.$router.push({ name: "V2PostHistories" });
  }

  private getExtension(filename: string): string {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }

  async readFileAsync(reader: FileReader, file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    });
  }

  async uploadImage(imageFile: File): Promise<string> {
    const reader = new FileReader();
    let arrayBuffer;
    try {
      arrayBuffer = await this.readFileAsync(reader, imageFile);
    } catch (e) {
      // アップロードする前にアップロード予定ファイルがちゃんと読み込めなかったらSnackbar出して中断する
      console.error("[uploadImage] FileReader Error", e);
      this.addSnackbarMessages({
        text: `${imageFile.name}<br>の読み取りに失敗しました。お手数ですが画像/動画ファイルを選択し直してください。`,
        color: "danger",
        timeout: TOAST_CRITICAL_DURATION,
      });
      return "";
    }

    const ext = this.getExtension(imageFile.name);
    let preSignedURL = "";
    let fileName = "";
    const errMessage =
      "画像/動画のアップロードに失敗しました。少し時間をおいてもう一度[投稿する]ボタンをクリックしてください。";
    try {
      const res = await requiredAuth<EntitiesPostGMBResponse>(
        "post",
        `${import.meta.env.VITE_APP_API_BASE}v2/companies/${this.poiGroupId}/post/image/${ext}`
      );
      preSignedURL = res?.data?.url;
      fileName = res?.data?.file_name;
    } catch (ex) {
      console.error("[uploadImage] Posting Error", ex.toString());
      this.addSnackbarMessages({
        text: errMessage,
        color: "danger",
        timeout: TOAST_CRITICAL_DURATION,
      });
      return "";
    }

    try {
      await axios.put(preSignedURL, arrayBuffer, {
        headers: { "Content-Type": imageFile.type },
      });
    } catch (ex) {
      console.error("[uploadImage] Putting Error", ex.toString());
      this.addSnackbarMessages({
        text: errMessage,
        color: "danger",
        timeout: TOAST_CRITICAL_DURATION,
      });
      return "";
    }

    try {
      // ファイル名だけを抜き出す
      const splitFile = fileName.split("/");
      const imageFileName = splitFile[splitFile.length - 1];
      requiredAuth<EntitiesPostBulkPostImageFin>(
        "post",
        `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
          this.poiGroupId
        }/post/imagefin/${imageFileName}`
      );
    } catch (err) {
      console.error("[uploadImageFin] Posting Error", err);
      this.addSnackbarMessages({
        text: "画像・動画の変換処理に失敗しました。管理者にお問い合わせください。",
        color: "danger",
        timeout: TOAST_CRITICAL_DURATION,
      });
      return "";
    }
    return fileName;
  }

  async postExec(platforms: string[]): Promise<void> {
    // 編集の場合か、新規の場合の２パターン
    const actionType = this.isEdit
      ? await determineEditActionType(this.postRow, this.canPostRequest)
      : determineNewActionType(this.postRow, this.canPostRequest);
    let method: HttpMethod = this.isEdit ? "put" : "post";
    // 投稿予約中(未投稿)を編集して保存する場合は "post" する
    if (this.doSavePost && isNotYetPublished(this.postRow)) {
      method = "post";
    }
    const data = await this.createPostData(platforms, method);

    const customToast: CustomSnackbarToast = {
      statusCode: 403,
      snackbarToast: {
        text: "投稿権限が無い店舗が指定されていたため、投稿はキャンセルされました。",
      },
    };
    await requiredAuth<any>(
      method,
      `${import.meta.env.VITE_APP_API_BASE}v2/companies/${this.poiGroupId}/post`,
      { status: this.postRow?.state, ...getOperationLogParams(this.$route, actionType, "v2_post") },
      data,
      null,
      customToast
    )
      .then(async (res) => {
        if (res.status == 205) {
          this.openReloadConfirm = true;
        } else if (res.status === 403) {
          await this.$router.push({ name: "V2PostHistories" });
        } else {
          this.addSnackbarMessages({
            text: this.doSavePost
              ? "保存処理を開始しました"
              : "投稿指示を受け付けました。ただいま、指示ファイルを作成中です。",
            color: "success",
          });
          this.setPostRow(null);
          await this.$router.push({ name: "V2PostHistories" });
        }
      })
      .catch((e) => {
        if (e.response?.status === 429) {
          this.addSnackbarMessages({
            text: "当月の投稿数が上限に達しています",
            color: "danger",
          });
        } else if (e.response?.status === 400) {
          // 承認依頼先ユーザが権限を持っていない場合などに原因が分からないのでエラーメッセージを表示する
          const errorMessageList = (e.response.data?.errorMessage ?? "").split(" ");
          this.addSnackbarMessages({
            text: errorMessageList.length > 0 ? errorMessageList[0] : "投稿処理に失敗しました",
            color: "danger",
          });
        } else {
          this.addSnackbarMessages({
            text: this.doSavePost ? "保存処理に失敗しました" : "投稿処理に失敗しました",
            color: "danger",
          });
        }
        this.loading = false;
      });
  }

  async createPostData(platforms: string[], method: "post" | "put"): Promise<string> {
    this.postData.incompleteNew = !this.isEdit && this.doSavePost ? true : false;
    this.postData.incompleteEdit = this.isEdit && this.doSavePost ? true : false;
    // 投稿予約中(未投稿)を編集して保存する場合は新規として扱う
    if (this.doSavePost && isNotYetPublished(this.postRow)) {
      this.postData.incompleteNew = true;
      this.postData.incompleteEdit = false;
    }
    this.postData.targets.all = this.allSelect;
    if (!this.postData.targets.all) {
      this.postData.targets.areas = this.selectedAreas;
      this.postData.targets.poiIDs = this.selectedStores;
    }
    if (this.postRow?.state) {
      this.postData.status = this.postRow.state;
    }

    this.postData.userName = method === "post" ? this.user.uuID : this.postRow.userName;

    this.postData.files = this.postFiles;
    this.postData.text = this.baseText;
    this.postData.title = this.postName;
    if (this.isReserved && this.reservedDate.length > 0) {
      let posting = this.reservedDate;
      if (this.reservedTime.length > 0) {
        posting += " " + this.reservedTime + ":00";
      }
      this.postData.posting = dayjs(posting).format();
    }
    if (this.isSetEnd && this.endDate.length > 0) {
      let cancellationReservation = this.endDate;
      if (this.endTime.length > 0) {
        cancellationReservation += " " + this.endTime + ":00";
      }
      this.postData.cancellationReservation = dayjs(cancellationReservation).format();
    }
    if (this.postRow?.fileName.length > 0) {
      this.postData.fileName = this.postRow.fileName;
    }
    // 編集時・今回の処理が新規一時保存・新規一時保存から投稿or承認依頼する場合は作成日時を引き継ぐ
    if (
      this.isEdit ||
      this.postData.incompleteNew ||
      (this.postRow?.incompleteNew && !this.postData.incompleteNew)
    ) {
      this.postData.createDateTime = this.postRow?.createDateTime;
    }

    if (platforms.indexOf(platformOfficialNames.google) > -1 || this.doSavePost) {
      this.postData.GMB = await this.createPostGMBData();
    }
    if (platforms.indexOf(platformOfficialNames.yahoo) > -1 || this.doSavePost) {
      this.postData.Yahoo = await this.createPostYahooData();
    }
    if (platforms.indexOf(platformOfficialNames.instagram) > -1 || this.doSavePost) {
      this.postData.Instagram = await this.createPostInstagramData();
    }
    if (platforms.indexOf(platformOfficialNames.facebook) > -1 || this.doSavePost) {
      this.postData.Facebook = await this.createPostFacebookData();
    }
    this.postData.aspectNoCheck = this.gbpAspectNoCheck;
    return JSON.stringify(this.postData);
  }

  async createPostGMBData(): Promise<PostGMBData> {
    const postGMBData = new PostGMBData();
    const sortedFiles = sortFileSelectionItemList(this.googleFiles);

    if (this.postGMBForm.postType === "info") {
      const postGMBInfo = new PostGMBInfo();
      postGMBInfo.mode = "info";
      postGMBInfo.title = this.postGMBForm.title;
      postGMBInfo.gmbImages = sortedFiles.map((f) => f.s3FileName);
      postGMBInfo.button = this.postGMBForm.button;
      postGMBInfo.buttonUrl = this.postGMBForm.buttonUrl?.trim();
      postGMBData.info = postGMBInfo;
    } else if (this.postGMBForm.postType === "event") {
      const postGMBEvent = new PostGMBEvent();
      postGMBEvent.mode = "event";
      postGMBEvent.title = this.postGMBForm.title;
      postGMBEvent.gmbImages = sortedFiles.map((f) => f.s3FileName);
      postGMBEvent.start = this.postGMBForm.start;
      postGMBEvent.end = this.postGMBForm.end;
      postGMBEvent.startTime = this.postGMBForm.startTime;
      postGMBEvent.endTime = this.postGMBForm.endTime;
      const postGMBEventDetail = new PostGMBEventDetail();
      postGMBEventDetail.detail = this.postGMBForm.detail;
      postGMBEventDetail.button = this.postGMBForm.button;
      postGMBEventDetail.buttonUrl = this.postGMBForm.buttonUrl?.trim();
      postGMBEvent.details = postGMBEventDetail;
      postGMBData.event = postGMBEvent;
    } else if (this.postGMBForm.postType === "benefits") {
      const postGMBBenefits = new PostGMBBenefits();
      postGMBBenefits.mode = "benefits";
      postGMBBenefits.title = this.postGMBForm.title;
      postGMBBenefits.gmbImages = sortedFiles.map((f) => f.s3FileName);
      postGMBBenefits.start = this.postGMBForm.start;
      postGMBBenefits.end = this.postGMBForm.end;
      const postGMBBenefitsDetail = new PostGMBBenefitsDetail();
      postGMBBenefitsDetail.detail = this.postGMBForm.detail;
      postGMBBenefitsDetail.couponCode = this.postGMBForm.couponCode;
      postGMBBenefitsDetail.link = this.postGMBForm.link?.trim();
      postGMBBenefitsDetail.termsOfService = this.postGMBForm.termsOfService;
      postGMBBenefits.details = postGMBBenefitsDetail;
      postGMBData.benefits = postGMBBenefits;
    } else if (this.postGMBForm.postType === "product") {
      const postGMBProduct = new PostGMBProduct();
      postGMBProduct.mode = "product";
      postGMBProduct.title = this.postGMBForm.title;
      postGMBProduct.gmbImages = sortedFiles.map((f) => f.s3FileName);
      postGMBProduct.explain = this.postGMBForm.detail;
      postGMBProduct.category = this.postGMBForm.category;
      postGMBProduct.price = this.postGMBForm.price;
      postGMBProduct.button = this.postGMBForm.button;
      postGMBProduct.buttonUrl = this.postGMBForm.buttonUrl?.trim();
      postGMBData.product = postGMBProduct;
    } else if (this.postGMBForm.postType === "covid19") {
      const postGMBCovid19 = new PostGMBCovid19();
      postGMBCovid19.mode = "covid19";
      postGMBCovid19.title = this.postGMBForm.title;
      postGMBCovid19.button = this.postGMBForm.button;
      postGMBCovid19.buttonUrl = this.postGMBForm.buttonUrl?.trim();
      postGMBData.covid19 = postGMBCovid19;
    }

    return postGMBData;
  }

  async createPostYahooData(): Promise<EntitiesV2YahooPostData> {
    const postData = this.postYahooForm.convertTo();
    const sortedFiles = sortFileSelectionItemList(this.yahooFiles);
    postData.mediaUrls = sortedFiles.map((f) => f.s3FileName);
    return postData;
  }

  async createPostInstagramData(): Promise<EntitiesV2InstagramPostData> {
    const fileNames = this.instagramFiles
      .filter((f) => typeof f.state === "number")
      .map((f) => f.s3FileName);
    const postData: EntitiesV2InstagramPostData = {
      addsLocation: false,
      imageUrl: fileNames.length > 0 ? fileNames[0] : "",
      text: this.igText,
    };
    return postData;
  }

  async createPostFacebookData(): Promise<EntitiesV2FacebookPostData> {
    const sortedFiles = sortFileSelectionItemList(this.facebookFiles);
    const postData: EntitiesV2FacebookPostData = {
      mediaUrls: sortedFiles.map((f) => f.s3FileName),
      text: this.fbText,
      link: "",
    };
    return postData;
  }

  async cancel(): Promise<void> {
    this.setPostRow(null);
    await this.$router.push({ name: "V2PostHistories" });
  }

  // 承認実行
  async approve(approveRemark: string): Promise<void> {
    const data: ControllersApproveJudgmentInput = {
      fileName: this.postRow.fileName,
      judgment: true,
      remark: approveRemark,
    };
    const actionType = await determineApproveActionType(this.postRow);
    await requiredAuth<ControllersApproveJudgmentOutput>(
      "post",
      `${import.meta.env.VITE_APP_API_BASE}v2/companies/${this.poiGroupId}/post/approve/judgment`,
      getOperationLogParams(this.$route, actionType, "v2_post"),
      data
    )
      .then(async (res) => {
        if (res.status === 200) {
          this.addSnackbarMessages({
            text: "承認しました",
            color: "success",
          });
          await this.$router.push({ name: "V2PostHistories" });
        } else {
          this.addSnackbarMessages({
            text: "承認に失敗しました : " + res.status.toString(),
            color: "danger",
          });
        }
      })
      .catch((e) => {
        this.addSnackbarMessages({
          text: "承認に失敗しました : " + e.message,
          color: "danger",
        });
      });
  }

  // 承認却下
  async reject(rejectRemark: string): Promise<void> {
    const data: ControllersApproveJudgmentInput = {
      fileName: this.postRow.fileName,
      judgment: false,
      remark: rejectRemark,
    };
    const actionType = await determineRejectActionType(this.postRow);
    await requiredAuth<ControllersApproveJudgmentOutput>(
      "post",
      `${import.meta.env.VITE_APP_API_BASE}v2/companies/${this.poiGroupId}/post/approve/judgment`,
      getOperationLogParams(this.$route, actionType, "v2_post"),
      data
    )
      .then(async (res) => {
        if (res.status === 200) {
          this.addSnackbarMessages({
            text: "承認却下しました",
            color: "success",
          });
          await this.$router.push({ name: "V2PostHistories" });
        } else {
          this.addSnackbarMessages({
            text: "承認却下に失敗しました : " + res.status.toString(),
            color: "danger",
          });
        }
      })
      .catch((e) => {
        this.addSnackbarMessages({
          text: "承認却下に失敗しました : " + e.message,
          color: "danger",
        });
      });
  }
  // 承認要求
  async request(selectUserUUIDList: string[] = [], remark: string = ""): Promise<void> {
    this.postData.judgementWantUserNames = selectUserUUIDList;
    this.postData.judgementRequestRemark = remark;
    await this.submit();
  }

  updatingStepStatus: boolean = false;
  /**
   * step を value で更新して step の直前までステップを更新する
   */
  updateStepStatus(step: StepStatus, values: Partial<StepStatus>): void {
    if (this.updatingStepStatus) {
      Object.assign(step, values);
      return;
    }
    this.updatingStepStatus = true;
    try {
      for (const value of Object.values(this.postSteps)) {
        if (value === step) {
          Object.assign(step, values);
          return;
        }
        if (value === this.postSteps.PostName) {
          this.changePostName();
        } else if (value === this.postSteps.TargetSelect) {
          this.changeTarget();
        } else if (value === this.postSteps.FileUpload) {
          this.changeUpdateFiles();
        } else if (value === this.postSteps.ImageSelect) {
          this.changeImageValidate();
        } else if (value === this.postSteps.PostCustomize) {
          this.postSteps.PostCustomize.enabled = true;
          this.changePostData();
        }
      }
    } finally {
      this.updatingStepStatus = false;
    }
  }

  // Step 1.投稿名の設定
  @Watch("postName")
  changePostName(): void {
    const pss = this.postSteps;
    if (this.postName.length == 0) {
      this.updateStepStatus(pss.PostName, { isComplete: false, errorMessage: "投稿名が未入力" });
      return;
    } else if (this.postName.length > 80) {
      this.updateStepStatus(pss.PostName, { isComplete: false, errorMessage: "投稿名に不備あり" });
      return;
    }
    this.updateStepStatus(pss.PostName, { isComplete: true, errorMessage: "" });
  }

  // Step 2.投稿するグループ／店舗の選択（一つでも選択されていればOK）
  changeTarget(): void {
    if (!this.allSelect && this.selectedAreas.length === 0 && this.selectedStores.length === 0) {
      this.updateStepStatus(this.postSteps.TargetSelect, {
        isComplete: false,
        errorMessage: "投稿対象が未選択",
      });
      return;
    }
    this.updateStepStatus(this.postSteps.TargetSelect, {
      isComplete: true,
      errorMessage: "",
    });
  }

  /** ファイルの状態が変わったときに stepStatusを再計算する */
  @Watch("googleFiles", { deep: true })
  @Watch("yahooFiles", { deep: true })
  @Watch("instagramFiles", { deep: true })
  @Watch("facebookFiles", { deep: true })
  changeFiles(): void {
    this.changePostData();
    this.updateStepStatus(this.postSteps.PostCustomize, {});
  }

  // Step 3.画像・動画のアップロード
  changeUpdateFiles(): void {
    //  GBPの『投稿種別』によって画像が必須かどうか異なるのでここでチェックする
    if (!this.postSteps.PostCustomize.isComplete) {
      if (
        // GBP有効で投稿種別に「商品」を選択している場合
        ((this.postGMBForm.postType === "product" &&
          this.postSteps.PostCustomize.childStep[0].enabled) ||
          // Yahoo有効の場合
          this.postSteps.PostCustomize.childStep[1].enabled ||
          // Instagram有効の場合
          this.postSteps.PostCustomize.childStep[2].enabled ||
          // Facebook有効の場合
          this.postSteps.PostCustomize.childStep[3].enabled) &&
        this.googleFiles.length === 0 &&
        this.yahooFiles.length === 0 &&
        this.instagramFiles.length === 0 &&
        this.facebookFiles.length === 0
      ) {
        this.updateStepStatus(this.postSteps.FileUpload, {
          isComplete: false,
          errorMessage: "ファイルが未アップロード",
        });
        return;
      }
    }
    this.updateStepStatus(this.postSteps.FileUpload, {
      isComplete: true,
      errorMessage: "",
    });
  }

  // Step 4.画像・動画の選択
  changeImageValidate(): void {
    //  GBPの『投稿種別』によって画像が必須かどうか異なるのでここでチェックする
    if (!this.postSteps.PostCustomize.isComplete) {
      if (
        this.postGMBForm.postType === "product" &&
        this.postSteps.PostCustomize.childStep[0].enabled &&
        this.googleFiles.filter((f) => !isNaN(Number(f.state))).length === 0 &&
        this.yahooFiles.filter((f) => !isNaN(Number(f.state))).length === 0 &&
        this.instagramFiles.filter((f) => !isNaN(Number(f.state))).length === 0 &&
        this.facebookFiles.filter((f) => !isNaN(Number(f.state))).length === 0
      ) {
        this.updateStepStatus(this.postSteps.ImageSelect, {
          isComplete: false,
          errorMessage: "ファイルが未選択",
        });
        return;
      }
    }

    if (this.imageErrorMessage != "") {
      this.updateStepStatus(this.postSteps.ImageSelect, {
        isComplete: false,
        errorMessage: "選択ファイルに不備あり",
      });
      return;
    }

    this.updateStepStatus(this.postSteps.ImageSelect, {
      isComplete: true,
      errorMessage: "",
    });
  }

  onAspectNoCheckChange(): void {
    // 「画像の縦横比チェックを行わない」チェック時に改めて各バリデータを走らせる
    this.$nextTick(() => {
      if (this.isMobile) {
        (
          this.$refs.mobilePreviewArea as InstanceType<typeof MobilePostPreviewArea>
        )?.onAspectNoCheckChange();
      } else {
        (this.$refs.previewArea as InstanceType<typeof PostPreviewArea>)?.onAspectNoCheckChange();
      }
      // 動画ファイルも含め全てのアスペクト比を再チェックする
      this.validateAllFiles();
    });
  }

  // Step 5.投稿テキスト入力
  @Watch("baseText")
  changeBaseText(): void {
    // 6で個別に入力されていた場合はOKとしたいので、ここでは厳密にチェックしない
    // 未入力の場合は初期状態に戻す
    if (this.baseText?.trim().length === 0) {
      this.updateStepStatus(this.postSteps.PostText, { isComplete: false });
      return;
    }
    this.updateStepStatus(this.postSteps.PostText, { isComplete: true, errorMessage: "" });
  }

  // Step 6.投稿カスタマイズ（全SNSすべてvalidか）
  changePostData(): void {
    const isSomeEnabled = this.postSteps.PostCustomize.childStep
      .map((c) => c.enabled)
      .some((v) => v);
    // 子ステップのいずれかが有効なら親ステップも有効にする
    if (isSomeEnabled) {
      this.updateStepStatus(this.postSteps.PostCustomize, { enabled: true });
    }
    // ステップが無効ならスキップ
    if (!this.postSteps.PostCustomize.enabled) {
      return;
    }
    if (!isSomeEnabled) {
      this.imageErrorMessage = "";
      this.updateStepStatus(this.postSteps.PostCustomize, {
        isComplete: false,
        errorMessage: "投稿メディアが未選択",
      });
      return;
    }

    const errorSns: string[] = [];
    this.postSteps.PostCustomize.isComplete = this.postSteps.PostCustomize.childStep
      .map((c, index) => {
        if (c.enabled) {
          if (this.isMobile) {
            (
              this.$refs.mobilePreviewArea as InstanceType<typeof MobilePostPreviewArea>
            )?.isCompleted(index);
          } else {
            (this.$refs.previewArea as InstanceType<typeof PostPreviewArea>)?.isCompleted(index);
          }
        }
        // 対象SNSが無効 or 入力がすべてValid
        const isComplete = !c.enabled || c.isComplete;
        if (!isComplete) {
          errorSns.push(c.name);
        }
        return isComplete;
      })
      .every((v) => v);

    // 投稿内容に問題無ければステップ5もOKとする
    if (this.postSteps.PostCustomize.isComplete) {
      this.updateStepStatus(this.postSteps.PostText, { isComplete: true });
    }

    // 完了しているステップがあればエラーメッセージ表示（入力前には出力しないようにする）
    this.postSteps.PostCustomize.errorMessage = "";
    for (const [_, step] of Object.entries(this.postSteps)) {
      if (step.isComplete === true && errorSns.length) {
        this.updateStepStatus(this.postSteps.PostCustomize, {
          errorMessage: errorSns.join(", ") + "に不備あり",
        });
      }
    }

    // 画像ファイルに関するエラー文を集約する
    const errors: string[] = [];
    this.postSteps.PostCustomize.childStep.map((s) => {
      if (s.errorMessage != "" && errorSns.includes(s.name)) {
        errors.push(s.errorMessage);
      }
    });
    this.imageErrorMessage = errors.join("\n");
    this.updateStepStatus(this.postSteps.PostCustomize, {});
  }

  // Step 7.投稿
  changeDate(): void {
    this.validateDate();
    const isComplete = this.postSteps.PostExecute.childStep
      .map((c) => (c.enabled ? c.isComplete : true))
      .every((v) => v);
    this.updateStepStatus(this.postSteps.PostExecute, {
      enabled: true, // 日付系の入力があれば有効にする
      isComplete,
      errorMessage: isComplete ? "" : "日時入力に不備あり",
    });
  }

  // 時刻関係のバリデーション
  private validateDate() {
    // チェックボックスにチェックが入っていたら有効
    this.postSteps.PostExecute.childStep[0].enabled = this.isReserved;
    this.postSteps.PostExecute.childStep[1].enabled = this.isSetEnd;
    // 状態の初期化
    this.postSteps.PostExecute.childStep[0].isComplete = true;
    this.postSteps.PostExecute.childStep[1].isComplete = true;
    this.postSteps.PostExecute.childStep[0].errorMessage = "";
    this.postSteps.PostExecute.childStep[1].errorMessage = "";

    if (this.isReserved && (this.reservedDate == "" || this.reservedTime == "")) {
      this.postSteps.PostExecute.childStep[0].errorMessage = "投稿開始日時が設定されていません";
      this.postSteps.PostExecute.childStep[0].isComplete = false;
    }
    if (this.isSetEnd && (this.endDate == "" || this.endTime == "")) {
      this.postSteps.PostExecute.childStep[1].errorMessage = "削除開始日時が設定されていません";
      this.postSteps.PostExecute.childStep[1].isComplete = false;
    }
    // 以下、値がNaNの場合は評価されない
    const now = Date.now();
    const reserved = Date.parse(`${this.reservedDate} ${this.reservedTime}`);
    const end = Date.parse(`${this.endDate} ${this.endTime}`);
    if (this.isReserved && !this.isReservedDisabled && now >= reserved) {
      this.postSteps.PostExecute.childStep[0].errorMessage =
        "投稿開始日時が現在時刻より前に設定されています";
      this.postSteps.PostExecute.childStep[0].isComplete = false;
    }
    if (this.isSetEnd && !this.isSetEndDisabled && now >= end) {
      this.postSteps.PostExecute.childStep[1].errorMessage =
        "削除開始日時が現在時刻より前に設定されています";
      this.postSteps.PostExecute.childStep[1].isComplete = false;
    }
    if (this.isReserved && this.isSetEnd && reserved >= end) {
      this.postSteps.PostExecute.childStep[1].errorMessage =
        "削除開始日時が投稿開始日時より前に設定されています";
      this.postSteps.PostExecute.childStep[1].isComplete = false;
    }
  }

  /** 再編集時、既存投稿イメージリストから遡ってancestorとrevisionプロパティを復元する */
  private markAncestor(): void {
    if (this.isNewPost) {
      return;
    }
    type TmpAncestor = {
      ancestor: FileNameObj;
      revision: FileRevisions;
    };

    // 仮先祖リスト
    const tmpAncestors: TmpAncestor[] = [];
    // プラットフォーム毎にアップロード画像リスト走査
    new Map([
      ["google", this.googleFiles],
      ["yahoo", this.yahooFiles],
      ["instagram", this.instagramFiles],
      ["facebook", this.facebookFiles],
    ]).forEach((entries, platformName) => {
      if (entries.length === 0) {
        return false;
      }

      let tmpAncestor: TmpAncestor;
      const picItems = entries.filter((item) => isImage(item.file));
      // レイアウト編集の新規保存により生成された画像があったら世代を復元
      for (const picItem of picItems) {
        const reg = new RegExp(/_(google|yahoo|instagram|facebook)_(\d+)/, "g");
        const fileNameObj = getFileNameWithExtension(picItem.file.name);
        tmpAncestor = {
          ancestor: fileNameObj,
          revision: {
            google: 0,
            yahoo: 0,
            instagram: 0,
            facebook: 0,
          },
        };
        if (reg.test) {
          // プラットフォームとリビジョン番号をファイル名から抽出し、先祖の本来のファイル名を確定
          fileNameObj.fullName = picItem.file.name.replace(reg, (whole, platform, rev) => {
            tmpAncestor.revision[platform] = parseInt(rev, 10);
            return "";
          });
          fileNameObj.fileName = fileNameObj.fileName.replace(reg, "");
        }

        const families = tmpAncestors.filter(
          (item) => item.ancestor.fileName === tmpAncestor.ancestor.fileName
        );
        let newRevision: FileRevisions = tmpAncestor.revision;
        if (families.length === 0) {
          // 仮先祖リストにまだ無かったら追加
          tmpAncestors.push(tmpAncestor);
        } else {
          // 仮先祖リスト内のと同じ先祖を持つ画像ファイルを比較して最終リビジョンを取得する
          newRevision = tmpAncestor.revision;
          for (const rev of families) {
            rev.revision[platformName] = Math.max(
              rev.revision[platformName],
              newRevision[platformName]
            );
            newRevision[platformName] = rev.revision[platformName];
          }
        }
        // 投稿イメージリストのアイテムの世代確定
        picItem.ancestor = tmpAncestor.ancestor;
        picItem.revision = newRevision;
      }
    });
  }

  checkSelectedVideo(): void {
    new Map([
      ["google", this.googleFiles],
      ["instagram", this.instagramFiles],
      ["facebook", this.facebookFiles],
    ]).forEach((items, platform) => {
      const videoUrl = items.filter(
        (value) => value.videoUrl != "" && typeof value.state === "number"
      );
      // 動画本数上限は各プラットフォーム1本なので、一つでも検出されたらそのプラットフォームに選択済ありとしてマークしとく
      this.hasSelectedVideo[platform] = videoUrl.length;
    });
  }

  deleteImgFile(fileName: string, s3FileName: string): void {
    // ゴミ箱行きしたファイルを除いてpostFilesを更新する
    this.postFiles = this.postFiles.filter(
      (postFile) => postFile.fileName !== fileName && postFile.filePath !== s3FileName
    );
  }

  /** 動画含め全ての画像・動画をバリデートする */
  async validateAllFiles(): Promise<void> {
    const selectedImages: FileAmountsOfPlatforms = {
      google: 0,
      yahoo: 0,
      instagram: 0,
      facebook: 0,
    };
    const fileSelectionItems: FileSelectionItem[][] = [
      this.googleFiles,
      this.yahooFiles,
      this.instagramFiles,
      this.facebookFiles,
    ];

    const platforms: PlatformName[] = ["google", "yahoo", "instagram", "facebook"];
    for (let i = 0; i < fileSelectionItems.length; i++) {
      const platform = platforms[i];
      this.hasSelectedVideo[platform] = 0;
      // 投稿済の画像・動画に投稿順を設定
      let selectedImage = selectedImages[platform];
      for (const item of fileSelectionItems[i]) {
        if (!isImage(item.file) && !isVideo(item.file)) {
          continue;
        }
        if (typeof item.state !== "number") {
          continue;
        }
        if (platform === "google") {
          if (isVideo(item.file)) {
            this.hasSelectedVideo[platform]++;
          } else {
            selectedImage++;
          }
        } else {
          if (isImage(item.file)) {
            selectedImage++;
          } else if (isVideo(item.file)) {
            this.hasSelectedVideo[platform]++;
          }
        }
      }
      this.validateFiles(fileSelectionItems[i], platform, selectedImage);
    }
  }

  async validateFiles(
    items: FileSelectionItem[],
    platform: PlatformName,
    selectedImages: number
  ): Promise<FileSelectionItem[]> {
    let state: FileValidationResult;
    if (platform === "instagram" || platform === "facebook") {
      // InstagramとFacebookは動画と画像が排他関係にあるので、予めaspectNoCheckにチェックが入った状態で不正なアスペクト比の動画にチェック済みのものがないかを調べ、チェック済みだったらrejectedにしてhasSelectedVideo[platform]もリセットする
      for (let i = 0; i < items.length; i++) {
        if (typeof items[i].state === "number" && isVideo(items[i].file)) {
          const prev: FileValidationResult = { state: items[i].state };
          const newState = await this.doVideoValidation(items[i], platform, prev);
          items[i].state = newState.state;
          items[i].rejectMessage = newState.message;
          if (items[i].state === "rejected") {
            this.hasSelectedVideo[platform] = 0;
          }
        }
      }
    }
    for (let i = 0; i < items.length; i++) {
      if (typeof items[i].state !== "number") {
        // 番号が設定されていない画像・動画についてバリデーションを行い FileSelectionState を設定
        const ext = getExtension(items[i].file.name);
        state = { state: "deselected" };
        state = validateExtension(ext, platform);
        // 10KB未満ファイルではGBP選べない様にする
        const gbp10KBCheck = validateFileSize(platform, items[i].file);
        state = gbp10KBCheck.state === "rejected" ? gbp10KBCheck : state;
        state = validateNumberOfFiles(
          this.company.canUseGbpConsole,
          ext,
          platform,
          selectedImages,
          this.hasSelectedVideo[platform],
          state
        );

        const newState = await this.doVideoValidation(items[i], platform, state);
        items[i].state = newState.state;
        items[i].rejectMessage = newState.message;
      }
    }

    return items;
  }

  async doVideoValidation(
    item: FileSelectionItem,
    platform: PlatformName,
    state: FileValidationResult
  ): Promise<FileValidationResult> {
    let videoElem: HTMLVideoElement;
    await getVideoElement(item.videoUrl)
      .then((res) => {
        videoElem = res;
      })
      .catch((ex) => {
        // Chrome非対応により動画ファイルのデータを取得できないケースがあるが、GBP側が変換してくれるため続行する
      });
    if (videoElem) {
      const aspectNoCheck = platform === "google" ? this.gbpAspectNoCheck : false;
      state = validateVideo(
        videoElem,
        platform,
        item.file,
        state,
        aspectNoCheck,
        this.hasSelectedVideo
      );
    }
    return state;
  }

  genUpdateStep(
    step: StepStatus
  ): (ss: Pick<StepStatus, "enabled" | "isComplete" | "errorMessage">) => void {
    return (ss) => {
      let changed = false;
      if (ss.enabled !== undefined && step.enabled !== ss.enabled) {
        step.enabled = ss.enabled;
        changed = true;
      }
      if (ss.isComplete !== undefined && step.isComplete !== ss.isComplete) {
        step.isComplete = ss.isComplete;
        changed = true;
      }
      if (ss.errorMessage !== undefined && step.errorMessage !== ss.errorMessage) {
        step.errorMessage = ss.errorMessage;
        changed = true;
      }
      if (changed) {
        this.changePostData();
      }
    };
  }

  scrollToStep(stepName: string): void {
    (this.$refs[stepName] as HTMLElement).scrollIntoView({ behavior: "smooth" });
  }

  closeDialog(): void {
    this.openEditor = false;
    this.validateAllFiles();
  }
}
export default toNative(V2Posts);
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

.post-form {
  background-color: white;
  // タイムラインを固定表示するためにコンテンツ部分をウィンドウの高さにする
  height: calc(100vh - 260px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.mobile-post-form {
  @extend .post-form;
  height: calc(100vh - 142px);
}

.row {
  margin-top: 0;
  margin-bottom: 0;
}

.post-title-0st {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 10px 10px 20px;
  padding-top: 20px;
}

.post-title-1st {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 10px 10px 20px;
  padding-top: 10px;
}

.post-title-2nd {
  font-weight: bold;
  margin: 10px 10px 10px 20px;
  padding-top: 10px;
}

.post-title-3rd {
  font-size: 0.8rem;
  font-weight: bold;
  margin: 10px 10px 10px 20px;
  padding-top: 10px;
}

.post-subtitle {
  @extend .post-title-3rd;

  margin-top: 0;
  margin-left: 0;
  padding-top: 0;
}

.post-desc {
  font-size: 0.8rem;
  margin: 0 0 0 20px;
}

.post-check {
  border: 1px solid #b9b9b9;
  margin-left: 40px;
  padding-left: 10px;
  margin-right: 1rem;
  max-width: 360px;
}

.post-title-row {
  margin-left: 40px;
}

.post-title {
  font-weight: bold;
  padding-top: 20px;
}

.mobile-post-title {
  margin-top: 1rem;
}

.file-upload {
  margin-left: 10px;
}

.text-indent-1 {
  text-indent: 1em;
}

.text-indent-2 {
  text-indent: 2em;
}

.text-black {
  color: #000;
  font-size: 0.9rem;
}

.preview-area {
  display: flex;
  margin: 1em;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  border: 1px solid #bbb;
  opacity: 1;
  overflow-x: scroll;
  overflow-y: clip;
}

.mobile-preview-area {
  border: 1px solid #bbb;
  margin: 1em;
}

:deep(.v-messages) {
  min-height: 0 !important;
}

.file-upload {
  padding: 10px 0 0 10px;
}

.subcontents {
  margin-left: 20px;
  margin-top: 1rem;
}

.post-text {
  margin-left: 20px;
}

.warn-container {
  margin: 5px;
  display: flex;
  justify-content: flex-end;
}

.warn-item {
  font-size: 0.8em;
  color: #9d9d9d;
}

.date-checkbox {
  :deep(.v-input--checkbox) {
    margin-top: 0;
  }

  :deep(.v-label) {
    color: #666;
  }
}

.date-label {
  text-align: end;
  padding-right: unset;

  p {
    color: color.$primary;
    margin-bottom: 0;
    margin-top: 0.25rem;
  }
}

.date-time {
  :deep(.v-text-field__slot input) {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.error-message {
  color: red;
  margin-bottom: 0;
  margin-top: 0.25rem;
}

.images-error-message {
  @extend .error-message;

  font-size: 0.75rem;
  white-space: pre-wrap;
  padding-left: 1.25rem;
  overflow-y: scroll;
  max-height: 80px;
}

.submit-buttons {
  .col-auto {
    padding-left: 0;
    padding-right: 0;
  }

  .cancel-button {
    font-size: 16px;
    font-weight: bold;
    height: 53px !important;
    color: #808080;
  }

  .save-button {
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
    margin-left: 20px;
    margin-right: 40px;
    width: 240px;
    height: 53px !important;
    color: color.$main-orange;
    background-color: #fff !important;
    border: 1px solid color.$main-orange;
    box-shadow: none;
  }

  .post-button {
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
    width: 240px;
    height: 53px !important;
    box-shadow: none;
  }
}

.mobile-submit-buttons {
  @extend .submit-buttons;

  .cancel-button {
    width: 100%;
  }

  .save-button {
    width: 100%;
    height: unset;
    margin: unset;
  }

  .post-button {
    width: 100%;
    height: unset;
  }
}

:deep(.aspect-no-check) {
  display: inline-block;
  margin: 10px 0 10px 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0 10px;

  .v-label {
    color: #000;
    opacity: 1;
    font-size: 14px;
  }
}

.modal-card-title {
  padding-left: 30px;
  margin: 10px 0 0 0;
}

:deep(.modal-card-body) {
  padding: 0 0 0 50px;
}

.padding-top {
  padding-top: 20px;
}

.padding-left {
  padding-left: 30px;
}
</style>
