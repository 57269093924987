<template>
  <div data-testid="review-reply-table">
    <o-table
      ref="table"
      v-model:checked-rows="checkedRows"
      :data="reviews"
      paginated
      backend-pagination
      :current-page="page"
      :per-page="reviewsPerPage"
      :total="total"
      backend-sorting
      detailed
      :loading="isLoading"
      detail-key="reviewID"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :checkable="canManage"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      :is-row-checkable="(row) => !row.isRival"
      :row-class="(row, index) => (row.isRival === true ? 'rival-row' : '')"
      :mobile-cards="false"
      @page-change="onPageChange"
      @sort="onSort"
    >
      <o-table-column v-slot="props" field="isRival" label="自社/競合" sortable>
        <p :data-testid="`review-reply-table-own-rival-${props.row.reviewID}`">
          {{ isRivalName(props.row.isRival) }}
        </p>
      </o-table-column>

      <o-table-column v-slot="props" field="updateTime" label="最終更新時刻" sortable>
        <p :data-testid="`review-reply-table-updatetime-${props.row.reviewID}`">
          {{ reviewPostTime(props.row.updateTime) }}
        </p>
      </o-table-column>

      <o-table-column v-slot="props" field="createTime" label="初投稿時刻" sortable>
        <div :data-testid="`review-reply-table-createtime-${props.row.reviewID}`">
          <p v-if="props.row.createTime === props.row.updateTime">同左</p>
          <p v-else>{{ reviewPostTime(props.row.createTime) }}</p>
        </div>
      </o-table-column>

      <o-table-column v-slot="props" field="storeName" label="店舗">
        <p :data-testid="`review-reply-table-store-${props.row.reviewID}`">
          {{ props.row.storeName }}
        </p>
      </o-table-column>

      <o-table-column v-slot="props" field="rank" label="クチコミ表示順位" sortable centered>
        <div :data-testid="`review-reply-table-ranking-${props.row.reviewID}`">
          <p v-if="props.row.isRival === true">-</p>
          <p v-else>{{ ranking(props.row.rank) }}</p>
        </div>
      </o-table-column>

      <o-table-column v-slot="props" field="reviewer" label="投稿者">
        <span :data-testid="`review-reply-table-reviewer-displayName-${props.row.reviewID}`">
          {{ props.row.reviewerDisplayName }}
        </span>
      </o-table-column>

      <o-table-column v-slot="props" field="starRating" label="星数" sortable>
        <span :data-testid="`review-reply-table-star-rating-${props.row.reviewID}`">
          <v-rating
            v-model="props.row.starRating"
            readonly
            color="primary"
            active-color="primary"
            :data-testid="`review-reply-table-star-rating-detail-${props.row.reviewID}`"
          />
        </span>
      </o-table-column>

      <o-table-column v-slot="props" field="replyStatus" label="返信状態">
        <span
          :data-testid="`review-reply-table-reply-status-${props.row.reviewID}`"
          :class="replyStyle(props.row.replyComment)"
        >
          {{ replyStatusName(props.row.replyComment) }}
        </span>
      </o-table-column>

      <o-table-column v-slot="props" field="comment" label="内容">
        <span :data-testid="`review-reply-table-comment-${props.row.reviewID}`">
          <p v-show="props.row.comment">
            {{ omittedComment(emptyComment(props.row.comment)) }}
          </p>
          <small v-show="!props.row.comment">コメントなし</small>
        </span>
      </o-table-column>

      <template #detail="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <div class="content-comment">
                <strong>{{ props.row.reviewerDisplayName }}</strong>
                <small>{{ reviewPostTime(props.row.updateTime) }}</small>
                <!-- eslint-disable-next-line -->
                <p v-if="props.row.comment" v-html="sanitize(props.row.comment)"></p>
                <p v-else>コメントなし</p>
              </div>
              <div v-show="props.row.replyUpdateTime" class="message-body">
                <strong>返信</strong>
                <small>
                  {{ reviewPostTime(props.row.replyUpdateTime) }}
                </small>
                <!-- eslint-disable-next-line -->
                <p v-html="sanitize(props.row.replyComment)"></p>
              </div>
            </div>
          </div>
        </article>
      </template>
    </o-table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { ReviewResponseRow } from "./review-parameter";
import sanitizeHtml from "sanitize-html";
import { useIndexedDb } from "@/storepinia/idxdb";

export default defineComponent({
  props: {
    reviews: {
      type: Array as () => Array<ReviewResponseRow>,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    reviewsPerPage: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    checkedReviews: {
      type: Array as () => Array<ReviewResponseRow>,
      required: true,
    },
  },
  emits: ["page-change", "sort", "update:checkedReviews"],
  data() {
    return {
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
    };
  },
  computed: {
    canManage(): boolean {
      return useIndexedDb().canManageReviewReply;
    },
    checkedRows: {
      get() {
        return this.$props.checkedReviews;
      },
      set(value: Array<ReviewResponseRow>) {
        this.$emit("update:checkedReviews", value);
      },
    },
  },
  methods: {
    onPageChange(page: number) {
      this.$emit("page-change", page);
    },
    onSort(sort: string, order: string) {
      this.$emit("sort", sort, order);
    },
    isRivalName(value: number): string {
      return value ? "競合" : "自社";
    },
    reviewPostTime(time: string): string {
      const d = new Date(time);
      const utcDate = new Date(
        Date.UTC(
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          d.getHours(),
          d.getMinutes(),
          d.getSeconds()
        )
      );
      return utcDate.toLocaleString();
    },
    ranking(rank: number) {
      return rank == null ? "9位以下" : `${rank}位`;
    },
    replyStatusName(replyComment: string) {
      return replyComment ? "返信済み" : "未返信";
    },
    replyStyle(replyComment: string) {
      return replyComment ? "tag is-success" : "tag is-warning";
    },
    emptyComment(comment: string) {
      return comment ? comment : "コメントなし";
    },
    omittedComment(comment: string) {
      return comment.length > 30 ? comment.substring(0, 17) + "..." : comment;
    },
    sanitize(text: string): string {
      const sanitizedText = sanitizeHtml(text, {
        allowedTags: ["br"],
      }).replace(/\n/g, "<br>");
      return sanitizedText;
    },
  },
});
</script>

<style lang="scss" scoped>
.o-table {
  // FIXME: b-tableにかつて当てられていたCSS、今使っているかは不明
  :deep(table tr.rival-row) {
    background-color: rgb(249, 249, 249);
  }
}
.message-body {
  & > p {
    margin: 0;
  }
}
</style>
