<template>
  <v-card>
    <v-card-title class="text-h5">反映済み料金の一括ダウンロード</v-card-title>
    <v-card-title class="text-h6">出力するメニューを選択してください</v-card-title>
    <v-card-text>
      <p class="error-message">{{ message }}</p>
    </v-card-text>
    <v-card-text v-if="percentage !== 0">
      <v-progress-linear v-model="percentage" color="light-blue"></v-progress-linear>
    </v-card-text>
    <v-card-text>
      <v-combobox
        v-model="checks"
        :items="visibleGroups"
        item-title="title"
        label="メニュー"
        color="primary"
        multiple
      ></v-combobox>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn variant="text" text="キャンセル" @click="cancel()" />
      <v-btn flat color="primary" :disabled="checks.length === 0" @click="submit()">
        ダウンロード
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import wordDictionary from "@/word-dictionary";
import type { MybusinessFoodMenu } from "@/types/ls-api";
import { api } from "@/helpers/api/food-menu";
import type { Group } from "./group-list-utils";
import {
  renameAllSectionsAndItems,
  extractNameOrders,
  exportAllPricesCsv,
} from "./group-list-utils";
import { useIndexedDb } from "@/storepinia/idxdb";

// コンポーネント定義
export default defineComponent({
  props: {
    groups: { type: Array, required: false, default: () => [] }, // EntitiesFoodMenuGroup[]
  },
  emits: ["cancel"],
  data: () => {
    return {
      message: "",
      percentage: 0,
      checks: [] as Group[],
    };
  },
  computed: {
    visibleGroups(): Group[] {
      return (this.groups as Group[]).filter((g) => g.stores.length !== 0);
    },
  },
  async created() {},
  methods: {
    cancel() {
      this.clear();
      this.$emit("cancel");
    },
    async submit() {
      await this.exportAllPrices(this.checks, useIndexedDb().company.name);
      this.clear();
      this.$emit("cancel");
    },
    clear() {
      this.message = "";
      this.percentage = 0;
      this.checks = [];
    },
    /** 料金エクスポート */
    async exportAllPrices(groups: Group[], companyName: string) {
      // すべての既設のメニューを取得する
      const poiIDs: number[] = [];
      const menus: { [poiID: number]: MybusinessFoodMenu } = {};
      let total = 0; // 進捗表示に用いる FoodMenuStore の件数を調べる
      for (const g of groups) {
        total += g.stores.length;
      }
      let count = 0;
      for (const g of groups) {
        for (const s of g.stores) {
          const x = await api.getFoodMenuStore(s.poiGroupID, s.poiID);
          this.percentage = total === 0 ? 0 : (count / total) * 100;
          await this.$nextTick();
          if (x.lastAppliedMenus && 1 <= x.lastAppliedMenus.length) {
            poiIDs.push(s.poiID);
            menus[s.poiID] = x.lastAppliedMenus[0];
          }
          count++;
        }
      }
      // セクションとアイテムの名前の重複を排除する
      for (const menu of Object.values(menus)) {
        renameAllSectionsAndItems(menu);
      }
      // 料金表を作る
      const [secNames, mItemNames] = extractNameOrders(poiIDs, menus);
      const result = exportAllPricesCsv(secNames, mItemNames, groups, menus);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(result), "メニュー反映済み料金");
      const buf: ArrayBuffer = XLSX.write(wb, {
        type: "array",
        bookType: "xlsx",
        bookSST: true,
      });
      const datetime = dayjs().format("YYYYMMDD");
      const filename = `${wordDictionary.service.name}-メニュー反映済み料金エクスポート-${companyName}-${datetime}.xlsx`;
      saveAs(new Blob([buf], { type: "application/octet-stream" }), filename);
    },
  },
});
</script>
<style lang="scss" scoped>
.error-message {
  color: red;
  margin-bottom: 0;
  margin-top: 0.25rem;
}
</style>
