<template>
  <div>
    <o-field horizontal data-testid="reviews-search-own-rival" label="自社/競合" grouped>
      <o-radio v-model="reviewType" data-testid="reviews-search-own" name="Own" native-value="Own">
        自社
      </o-radio>
      <o-radio
        v-model="reviewType"
        data-testid="reviews-search-rival"
        name="Rival"
        native-value="Rival"
      >
        競合
      </o-radio>
      <o-radio
        v-model="reviewType"
        data-testid="reviews-search-own-rival-2"
        name="Both"
        native-value="Both"
      >
        自社/競合
      </o-radio>
    </o-field>
    <o-field horizontal data-testid="reviews-search-comment" label="コメント有無" grouped>
      <o-radio
        v-model="commentExists"
        data-testid="reviews-search-comment-unspecified"
        name="CommentUnspecified"
        native-value="CommentUnspecified"
      >
        指定なし
      </o-radio>
      <o-radio
        v-model="commentExists"
        data-testid="reviews-search-comment-exists"
        name="Commented"
        native-value="Commented"
      >
        コメントあり
      </o-radio>
      <o-radio
        v-model="commentExists"
        data-testid="reviews-search-comment-not-exists"
        name="NoComment"
        native-value="NoComment"
      >
        コメントなし
      </o-radio>
    </o-field>
    <o-field horizontal data-testid="reviews-search-reply-status" label="返信状態" grouped>
      <o-radio
        v-model="replyStatus"
        data-testid="reviews-search-reply-status-unspecified"
        name="ReplyUnspecified"
        native-value="ReplyUnspecified"
      >
        指定なし
      </o-radio>
      <o-radio
        v-model="replyStatus"
        data-testid="reviews-search-reply-status-not-replied"
        name="NoReplied"
        native-value="NoReplied"
      >
        未返信
      </o-radio>
      <o-radio
        v-model="replyStatus"
        data-testid="reviews-search-reply-status-replied"
        name="Replied"
        native-value="Replied"
      >
        返信済み
      </o-radio>
    </o-field>

    <o-field horizontal data-testid="reviews-search-by-word" label="検索ワード" grouped>
      <o-input
        v-model="word"
        data-testid="reviews-search-by-word-box"
        placeholder="Search..."
        type="search"
      ></o-input>
    </o-field>
    <o-field horizontal data-testid="reviews-search-star-rating" label="星数">
      <v-btn-toggle v-model="starRatingsChecked" density="compact" multiple>
        <v-btn
          v-for="starRating in starRatings"
          :key="starRating"
          class="toggle-button"
          selected-class="toggle-button-selected"
          elevation="0"
          :data-testid="`reviews-search-star-rating-checkbox-${starRating}`"
          :value="starRating"
        >
          <span v-for="n in starRating" :key="n">
            <i class="mdi mdi-star mdi-24px" />
          </span>
        </v-btn>
      </v-btn-toggle>
    </o-field>

    <o-field horizontal data-testid="reviews-search-sort-by-priority">
      <template #label>
        返信優先度で並び替える
        <ToolTipIcon
          data-testid="reviews-search-sort-by-priority-tooltip"
          :label="replyPrioritySortTooltip"
        />
      </template>

      <o-checkbox
        v-model="sortByReplyPriority"
        data-testid="reviews-search-sort-by-priority-checkbox"
      ></o-checkbox>
    </o-field>

    <o-field horizontal>
      <p class="control">
        <o-button data-testid="reviews-search-search-button" variant="primary" @click="search">
          検索
        </o-button>
        <o-button
          data-testid="reviews-search-clear-button"
          class="button white-button"
          @click="clearSearchCondition"
        >
          クリア
        </o-button>
      </p>
    </o-field>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { ReviewSearchCondition } from "./review-parameter";

export default defineComponent({
  props: {
    conditions: {
      type: Object as () => ReviewSearchCondition,
      required: true,
    },
    replyPrioritySortTooltip: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "search", "clearSearchCondition"],
  data: () => {
    return {
      starRatings: [1, 2, 3, 4, 5],
    };
  },
  computed: {
    reviewType: {
      get() {
        return this.$props.conditions.reviewType;
      },
      set(value: string) {
        this.$emit("update:modelValue", { ...this.$props.conditions, reviewType: value });
      },
    },
    commentExists: {
      get() {
        return this.$props.conditions.commentExists;
      },
      set(value: string) {
        this.$emit("update:modelValue", { ...this.$props.conditions, commentExists: value });
      },
    },
    replyStatus: {
      get() {
        return this.$props.conditions.replyStatus;
      },
      set(value: string) {
        this.$emit("update:modelValue", { ...this.$props.conditions, replyStatus: value });
      },
    },
    word: {
      get() {
        return this.$props.conditions.word;
      },
      set(value: string) {
        this.$emit("update:modelValue", { ...this.$props.conditions, word: value });
      },
    },
    starRatingsChecked: {
      get() {
        return this.$props.conditions.starRatingsChecked;
      },
      set(value: number[]) {
        this.$emit("update:modelValue", { ...this.$props.conditions, starRatingsChecked: value });
      },
    },
    sortByReplyPriority: {
      get() {
        return this.$props.conditions.sortByReplyPriority;
      },
      set(value: boolean) {
        this.$emit("update:modelValue", { ...this.$props.conditions, sortByReplyPriority: value });
      },
    },
  },
  methods: {
    search() {
      this.$emit("search");
    },
    clearSearchCondition() {
      this.$emit("clearSearchCondition");
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

.toggle-button {
  margin-right: 1rem;
  border: 1px solid rgb(219, 219, 219) !important;
  border-radius: 5px !important;
}
.toggle-button-selected {
  border-color: color.$primary !important;
  background-color: color.$primary;
  color: color.$white;
}
</style>
