<template>
  <v-card>
    <v-card-title class="text-h5">反映を予約</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="auto">
          <date-picker
            v-model="date"
            :min="today"
            required
            @update:model-value="updateReserveDialogMessage()"
          />
        </v-col>
        <v-col class="date-time" cols="auto">
          <input v-model="time" type="time" @change="updateReserveDialogMessage()" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <p class="error-message">{{ message }}</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="cancel()">キャンセル</v-btn>
      <v-btn depressed color="primary" :disabled="message !== ''" @click="submit()">予約</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import dayjs from "dayjs";
import DatePicker from "@/components/shared/date-picker/DatePicker.vue";

// コンポーネント定義
export default defineComponent({
  components: { DatePicker },
  emits: ["cancel", "submit"],
  data: () => {
    return {
      date: "",
      time: "",
      message: "",
      today: dayjs().format("YYYY-MM-DD"),
    };
  },
  async created() {
    this.updateReserveDialogMessage();
  },
  methods: {
    cancel() {
      // console.log("cancel", this.date, this.time);
      this.$emit("cancel");
      this.date = "";
      this.time = "";
    },
    submit() {
      // console.log("submit", this.date, this.time);
      this.$emit("submit", this.date, this.time);
      this.date = "";
      this.time = "";
    },
    /** 反映を予約ダイアログのメッセージ更新 */
    updateReserveDialogMessage() {
      console.log(this.date, this.time);
      if (this.date && this.time) {
        this.message = "";
      } else {
        this.message = "予約日時が設定されていません";
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;
.error-message {
  color: red;
  margin-bottom: 0;
  margin-top: 0.25rem;
}
:deep(.v-messages) {
  min-height: 0 !important;
}
.input-row {
  height: 60px;
  padding: 0 12px;
  text-align: center;
}
input[type="time"] {
  background-color: white;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #dbdbdb;
  &:focus {
    border-color: color.$primary;
    outline: none;
  }
}
</style>
