import type { EntitiesArea, EntitiesAreaStores, EntitiesStore } from "@/types/ls-api";

/** v-autocomplete と v-select の選択肢 */
export interface SelectItem {
    isHeader: boolean;
    title: string;
    id?: number;
    keyword?: string; // 検索用キーワード
}

/** エリアの選択肢を作成する */
function makeAreaSelectItems(areas: EntitiesAreaStores[]): SelectItem[] {
    const items: SelectItem[] = [];
    areas.forEach((area) => {
        if (!area.areaID || !area.stores || area.stores.length === 0) {
            return;
        }
        items.push({ isHeader: false, title: area.name, id: area.areaID });
    });
    return items;
}

/**
 * エリアと店舗の選択肢を作成する。
 * 引数のareasとstoresは破壊しない。areas.stores は無視して、stores.areas でどのエリアにどの店舗が属するかを決定する。
 * @param areas エリア
 * @param stores 全店舗のリスト
 * @param addUnAssignedStoresGroup true の場合 areas に含まれない店舗をグループ未割り当ての店舗を追加する
 * @returns areas: エリアの選択肢, stores: 店舗の選択肢
 */
export function makeSelectItems(
    areas: EntitiesArea[],
    stores: EntitiesStore[],
    addUnAssignedStoresGroup: boolean
): { areas: SelectItem[]; stores: SelectItem[] } {
    // 自前でAreaStoresを作成する
    let areaStores = JSON.parse(JSON.stringify(areas)) as EntitiesAreaStores[];
    areaStores.forEach((area) => {
        area.stores = [];
    });
    for (const store of stores) {
        const areaIds = store.areas ?? [];
        for (const area of areaStores) {
            if (areaIds.includes(area.areaID)) {
                area.stores.push(store);
            }
        }
    }
    // areaID を持たないエリアと、店舗がないエリアを除外する
    areaStores = areaStores.filter((area) => area.areaID && 0 < area.stores?.length);
    // エリアの選択肢を作成する
    const areaItems = makeAreaSelectItems(areaStores);

    // 店舗の選択肢を作成する
    const storeItems: SelectItem[] = [];
    const ids = [] as number[];
    areaStores.forEach((area) => {
        if (!area.stores || area.stores.length === 0) {
            return;
        }
        storeItems.push({ isHeader: true, title: area.name });
        area.stores.forEach((store) => {
            storeItems.push({
                isHeader: false,
                title: store.name,
                id: store.poiID,
                keyword: area.name + " " + store.name, // 検索用キーワード
            });
            ids.push(store.poiID);
        });
    });
    // stores に含まれていて areas に含まれていないものを、グループ未割り当ての店舗として追加する
    if (addUnAssignedStoresGroup) {
        const orphans = stores.filter(
            (store) => store.enabled && ids.includes(store.poiID) === false
        );
        if (0 < orphans.length) {
            // エリアを持たないユーザの場合は「グループ未割り当て」ヘッダ行を追加しない
            if (0 < areaStores.length) {
                storeItems.push({ isHeader: true, title: "グループ未割り当て" });
            }
            orphans.forEach((store) => {
                storeItems.push({ isHeader: false, title: store.name, id: store.poiID });
            });
        }
    }
    return { areas: areaItems, stores: storeItems };
}
