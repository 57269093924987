export function captureAndThrow(message: string, e: unknown): Error {
    console.log(e);
    throw Error(message);
}

// GBP更新エラー
export interface GBPError {
    code: number; // エラー番号
    status: string; // エラー状態
    message: string; // エラー内容
    details: ErrorInfo[]; // エラー詳細
    Errors: { reason: string; message: string }[]; // リクエストエラー
}

// GBPロケーション更新エラー 詳細
export interface ErrorInfo {
    "@type": string; // type.googleapis.com/google.rpc.ErrorInfo
    domain: string; // mybusinessbusinessinformation.googleapis.com
    metadata: { field_mask: string }; // 項目名
    reason: string; // 理由
}

export interface BadRequest {
    "@type": string; // type.googleapis.com/google.rpc.BadRequest
    fieldViolations: {
        field: string;
        description: string;
    }[];
}

// GBPロケーション更新エラーの項目名（field_mask）
const gbpFieldMasks = new Map<string, string>([
    ["name", "ロケーションID"],
    ["language_code", "言語コード"],
    ["store_code", "店舗コード"],
    ["title", "ビジネス名"],
    ["phone_numbers", "電話番号"],
    ["phone_numbers.primary_phone", "電話番号1"],
    ["phone_numbers.additional_phones", "電話番号2"],
    ["categories", "カテゴリー"],
    ["storefront_address", "住所"],
    ["storefront_address.address_lines", "住所"],
    ["storefront_address.administrative_area", "行政区域"],
    ["storefront_address.postal_code", "郵便番号"],
    ["website_uri", "ウェブサイト"],
    ["regular_hours", "営業時間"],
    ["special_hours", "特別営業時間"],
    ["service_area", "サービス提供地域"],
    ["labels", "ラベル"],
    ["ad_words_location_extensions", "AdWordsLocationExtensions"],
    ["latlng", "緯度経度"],
    ["open_info", "開業日"],
    ["open_info.opening_date", "開業日"],
    ["metadata", "メタデータ"],
    ["profile", "ビジネス情報"],
    ["relationship_data", "RelationshipData"],
    ["more_hours", "追加の営業時間"],
    ["service_items", "サービス"],
]);

// GBPロケーション更新エラーの理由（reason）
// https://developers.google.com/my-business/reference/businessinformation/rest/v1/ErrorCode
const gbpErrorReasons = new Map<string, string>([
    [
        "THROTTLED",
        "この項目はこのビジネスでの編集回数が多すぎます。しばらくしてからもう一度お試しください。",
    ],
    [
        "INVALID_PHONE_NUMBER",
        "不正な電話番号です。入力した市外局番などが正しいかをご確認ください。",
    ],
    ["INVALID_URL", "不正なURLです。入力したURLが正しい形式かご確認ください。"],
    [
        "INVALID_ADDRESS",
        "不正な住所です。入力した住所、行政区域（都道府県）、郵便番号をご確認ください。",
    ],
    ["OPENING_DATE_TOO_FAR_IN_THE_FUTURE", "開業日が未来過ぎます。1年以内にしてください。"],
    [
        "ATTRIBUTE_PROVIDER_URL_NOT_ALLOWED",
        "この項目は既にサードパーティプロバイダによるリンクが追加されていたため、トストアから追加・変更を行えませんでした。GBP管理画面から変更してください。",
    ],
    [
        "PIN_DROP_REQUIRED",
        "緯度経度を特定できません。GBP管理画面で住所を更新して緯度経度を指定してください。",
    ],
    [
        "STALE_DATA",
        "Googleから確度の高い変更提案をされており、この項目を変更することができませんでした。お手数ですが、GBP管理画面で変更提案を承認してから変更してください。",
    ],

    ["INVALID_ATTRIBUTE_NAME", "指定されたattribute_nameが不正です"],
    ["ASSOCIATE_LOCATION_INVALID_PLACE_ID", "指定されたPlaceIDが場所と不一致、もしくは不正です"],
    [
        "LAT_LNG_UPDATES_NOT_PERMITTED",
        "APIから緯度経度を更新できません（GBP Consoleから更新してください）",
    ],
    ["PO_BOX_IN_ADDRESS_NOT_ALLOWED", "私書箱は住所に使用できません"],
    ["LOCKED_REGION", "指定された地域からのビジネスを受け入れられません（国際的な制約のため）"],
    [
        "MISSING_BOTH_PHONE_AND_WEBSITE",
        "CUSTOMER_LOCATION_ONLYのビジネスには、電話またはWebサイトのいづれかが必要です",
    ],
    ["MISSING_STOREFRONT_ADDRESS_OR_SAB", "店頭の住所またはサービス襟が必要です"],
    ["LAT_LNG_TOO_FAR_FROM_ADDRESS", "緯度経度と住所が離れすぎています"],
    ["INVALID_CHARACTERS", "無効な文字が見つかりました"],
    ["FORBIDDEN_WORDS", "禁止されている言葉が見つかりました"],
    ["INVALID_INTERCHANGE_CHARACTERS", "無効な文字が見つかりました"],
    ["FIELDS_REQUIRED_FOR_CATEGORY", "このカテゴリの場所には、追加のフィールドが必要です"],
    [
        "STOREFRONT_REQUIRED_FOR_CATEGORY",
        "指定されたビジネスカテゴリは、店舗が必要です（移動店舗には設定できません）",
    ],
    ["ADDRESS_MISSING_REGION_CODE", "アドレスに必要なregionCodeがありません"],
    ["ADDRESS_EDIT_CHANGES_COUNTRY", "住所を別の国に移動することは出来ません"],
    [
        "SPECIAL_HOURS_SET_WITHOUT_REGULAR_HOURS",
        "特別営業時間は、その場所に通常の営業時間がある場合にのみ指定できます",
    ],
    ["INVALID_TIME_SCHEDULE", "無効なタイムスケジュール、重複または終了時間が開始時間よりも前です"],
    ["INVALID_HOURS_VALUE", "時間の形式または値が無効です"],
    ["OVERLAPPED_SPECIAL_HOURS", "special_hoursは重複できません"],
    [
        "INCOMPATIBLE_MORE_HOURS_TYPE_FOR_CATEGORY",
        "このビジネスプライマリカテゴリは、この時間タイプをサポートしていません",
    ],
    [
        "INCOMPATIBLE_SERVICE_AREA_AND_CATEGORY",
        "サービスエリアビジネスは、選択したプライマリカテゴリを持つことが出来ません",
    ],
    ["INVALID_SERVICE_AREA_PLACE_ID", "serviceAreaのplaceIDが無効です"],
    ["INVALID_AREA_TYPE_FOR_SERVICE_AREA", "serviceAreaのエリアタイプが無効です"],
    ["OPENING_DATE_MISSING_YEAR_OR_MONTH", "開業日は年または月を指定する必要があります"],
    ["OPENING_DATE_BEFORE_1AD", "開業日は西暦1年より前にすることは出来ません"],
    ["TOO_MANY_ENTRIES", "フィールドのエントリが多すぎます"],
    ["INVALID_PHONE_NUMBER_FOR_REGION", "地域の電話番号が無効です"],
    ["MISSING_PRIMARY_PHONE_NUMBER", "プライマリ電話番号がありません"],
    [
        "UNSUPPORTED_POINT_RADIUS_SERVICE_AREA",
        "地点と半径を指定するサービスエリアはサポートされなくなりました",
    ],
    ["INVALID_CATEGORY", "カテゴリIDが無効です"],
    ["CANNOT_REOPEN", "事業を再開することは出来ません"],
    ["INVALID_BUSINESS_OPENING_DATE", "開業日が無効です"],
    ["INVALID_LATLNG", "無効な緯度経度"],
    ["PROFILE_DESCRIPTION_CONTAINS_URL", "ビジネスの説明にはURLを含めないでください"],
    ["LODGING_CANNOT_EDIT_PROFILE_DESCRIPTION", "宿泊場所のプロフィールの説明は編集できません"],
    ["PARENT_CHAIN_CANNOT_BE_THE_LOCATION_ITSELF", "ParentChainを場所自体にすることは出来ません"],
    [
        "RELATION_CANNOT_BE_THE_LOCATION_ITSELF",
        "関係(replation)は場所そのものにすることは出来ません",
    ],
    ["MISSING_ADDRESS_COMPONENTS", "アドレスコンポーネントの値がありません"],
    ["READ_ONLY_ADDRESS_COMPONENTS", "読み取り専用アドレスコンポーネントを編集できません"],
    ["STRING_TOO_LONG", "文字列が長すぎます"],
    ["STRING_TOO_SHORT", "文字列が短すぎます"],
    ["REQUIRED_FIELD_MISSING_VALUE", "必須フィールドの値がありません"],
    ["ATTRIBUTE_INVALID_ENUM_VALUE", "enum属性の値が不明です"],
    ["ATTRIBUTE_NOT_AVAILABLE", "この場所ではscalable属性が無効です"],
    ["ATTRIBUTE_CANNOT_BE_REPEATED", "scalable属性は1回だけ指定してください"],
    [
        "ATTRIBUTE_TYPE_NOT_COMPATIBLE_FOR_CATEGORY",
        "scalable属性はその場所に設定されているカテゴリと互換性がありません",
    ],
    ["ADDRESS_REMOVAL_NOT_ALLOWED", "あなたのビジネスでは住所の削除は許可されていません"],
    ["AMBIGUOUS_TITLE", "言語のベスト名が曖昧です"],
    [
        "INVALID_CATEGORY_FOR_SAB",
        "純粋な SABは、gcid:establishment_poiのサブタイプであるgcidを持つことは出来ません",
    ],
    ["RELATION_ENDPOINTS_TOO_FAR", "関係のエンドポイントが互いに離れすぎています"],
    ["INVALID_SERVICE_ITEM", "structureServiceItemもfreeFormServiceItemも設定されていません"],
    ["SERVICE_ITEM_LABEL_NO_DISPLAY_NAME", "ラベルに表示名がありません"],
    [
        "SERVICE_ITEM_LABEL_DUPLICATE_DISPLAY_NAME",
        "表示名はすべての価格表のすべてのラベルで一意ではありません",
    ],
    ["SERVICE_ITEM_LABEL_INVALID_UTF8", "ラベルに無効なUTF-８シンボルが含まれています"],
    [
        "FREE_FORM_SERVICE_ITEM_WITH_NO_CATEGORY_ID",
        "freeFormServiceItemにcategory_idフィールドがありません",
    ],
    ["FREE_FORM_SERVICE_ITEM_WITH_NO_LABEL", "freeFormServiceItemにラベルがありません"],
    [
        "SERVICE_ITEM_WITH_NO_SERVICE_TYPE_ID",
        "structuredServiceItemにserviceTypeIdフィールドがありません",
    ],
    ["INVALID_LANGUAGE", "言語コードが無効です"],
    ["PRICE_CURRENCY_MISSING", "ISO4217通貨コードがありません"],
    ["PRICE_CURRENCY_INVALID", "指定された通貨コードは有効なISO4217ではありません"],
    ["SERVICE_TYPE_ID_DUPLICATE", "サービスタイプIDは、ロケーション内で一意ではありません"],
]);

function linkUrl(message: string, url: string) {
    if (url) {
        url = url.replace(/\?.+/, ""); // クエリストリングがついていると遷移できない可能性があるので削除
        return message.replace("GBP管理画面", `<a href="${url}" target="_blank">GBP管理画面</a>`);
    }
    return message;
}

export function getGBPErrorMessage(error: GBPError, url: string = ""): string {
    if (error == null) {
        return "";
    }
    const errorDetails = error?.details
        ?.map((info: ErrorInfo) => {
            const fieldMask = info?.metadata?.field_mask ?? "不明な項目";
            const errorField = gbpFieldMasks.get(fieldMask) ?? fieldMask;
            const errorReason =
                gbpErrorReasons.get(info?.reason) ??
                `<a href="https://developers.google.com/my-business/reference/businessinformation/rest/v1/ErrorCode">${info?.reason}</a>`;
            return `・${errorField}: ${linkUrl(errorReason, url)}<br/>`;
        })
        .join("");
    if (errorDetails) {
        return `店舗情報の更新に失敗しました。<br/><br/>${errorDetails}<br/>`;
    } else {
        return `店舗情報の更新に失敗しました。<br/><br/>${error?.message}（${error.code}）<br/>`;
    }
}

const placeActionLinksFieldMasks = new Map<string, string>([
    ["place_action_link.uri", "URL"],
    ["place_action_link.place_action_type", "プレイスアクションリンクの種別"],
    ["place_action_link.isPreferred", "優先表示"],
    ["update_mask", "更新項目"],
]);

// GBPロケーション更新エラーの理由（reason）
// https://developers.google.com/my-business/reference/placeactions/rest/v1/ErrorCode
const placeActionLinksErrorReasons = new Map<string, string>([
    ["UNVERIFIED_LOCATION", "オーナー未確認の店舗です"],
    ["INVALID_LOCATION_CATEGORY", "この店舗のカテゴリには登録できない種別のリンクです"],
    ["INVALID_URL", "URLの形式が間違っています"],
    ["URL_PROVIDER_NOT_ALLOWED", "URLには許可されていないプロバイダーが含まれています"],
    ["TOO_MANY_VALUES", "この種別に登録されている値が多すぎます"],
    ["DELETED_LINK", "指定のリンクは削除されました"],
    ["LINK_ALREADY_EXISTS", "同一の種別・URLのリンクが既に設定されています"],
    [
        "SCALABLE_DEEP_LINK_INVALID_MULTIPLICITY",
        "リンクに含まれるドメインはすでに使用されており、種別ごとにドメインあたり1つのリンクという制限があります",
    ],
    ["LINK_DOES_NOT_EXIST", "指定のリンクが見つかりません"],
]);

// BadRequestのエラー
const placeActionLinksBadRequestReasons = new Map<string, string>([
    [
        "Invalid value at 'place_action_link.place_action_type'",
        "プレイスアクションリンクの種別が不正です",
    ],
    [
        "Paths provided in `update_mask` are not present in `place_action_link`",
        "優先表示をオフにすることができません",
    ],
]);

function getBadRequestReason(message: string): string {
    for (const [key, value] of placeActionLinksBadRequestReasons) {
        if (message.includes(key)) {
            return value;
        }
    }
    return message;
}

export function getPlaceActionErrorMessage(error: GBPError): string {
    if (error == null) {
        return "";
    }
    const errorDetails = error?.details
        ?.map((info: ErrorInfo) => {
            if (info["@type"].includes("ErrorInfo")) {
                const errorReason =
                    placeActionLinksErrorReasons.get(info?.reason) ??
                    `<a href="https://developers.google.com/my-business/reference/placeactions/rest/v1/ErrorCode">${info?.reason}</a>`;
                return `・${errorReason}<br/>`;
            } else if (info["@type"].includes("BadRequest")) {
                // BadRequestの可能性あり
                const violations = (info as unknown as BadRequest)?.fieldViolations || [];
                let errorReason = "";
                violations.forEach((v) => {
                    const field = placeActionLinksFieldMasks.get(v?.field);
                    const description = getBadRequestReason(v?.description);
                    errorReason += `・${field}: ${description}<br/>`;
                });
                return errorReason;
            } else {
                return "未知のエラー";
            }
        })
        .join("");
    if (errorDetails) {
        return `プレイスアクションリンクの更新に失敗しました。<br/><br/>${errorDetails}<br/>`;
    } else {
        return `プレイスアクションリンクの更新に失敗しました。<br/><br/>${error?.message}（${error.code}）<br/>`;
    }
}

// foodMenuのsaveエラー
const foodMenuErrorReason = new Map<RegExp | string, string>([
    [
        /json: invalid use of ,string struct tag, trying to unmarshal .+ into int64/,
        "アイテムの価格には数値を入力してください。",
    ],
    [/parsing currencyCode ".+": invalid format/, "通貨コードが不正です"],
    [
        "json: cannot unmarshal bool into Go struct field FoodMenuData.menuData.variationRows of type entities.FoodMenuVariationRow",
        "真偽値は文字列に変換してください",
    ],
    [
        "json: cannot unmarshal number into Go struct field MenuLabel.menuData.menus.labels.displayName of type string",
        "数値は文字列に変換してください",
    ],
]);

export function getFoodMenuErrorMessage(status, message: string): string {
    let errorMessage = "";
    for (const [key, value] of foodMenuErrorReason) {
        if (typeof key === "object") {
            if (key.test(message)) {
                errorMessage = value;
                break;
            }
        } else if (message.includes(key)) {
            errorMessage = value;
            break;
        }
    }
    // 辞書にないエラーだったら汎用的なメッセージを出す
    if (errorMessage === "") {
        switch (status) {
            case 400:
                errorMessage =
                    "入力内容にエラーがありました。<br>入力内容をもう一度ご確認ください。";
                break;
            default:
                errorMessage =
                    "何らかのエラーが発生しました。<br>しばらくしてからもう一度お試しください。";
        }
    }
    return errorMessage;
}
