<template>
  <div
    class="login-wrapper"
    :style="{
      backgroundImage: `url(${theme.bgLogin})`,
      backgroundSize: 'cover',
      backgroundPosition: '50% 0',
    }"
  >
    <div class="logo"><img :src="theme.logoLogin" /></div>
    <br />
    <feature-toggle-message :ft="featureToggle" class="mb-4" />
    <div v-if="mode == 'LOGIN'">
      <div class="field">
        <input
          key="email"
          ref="email"
          v-model="email"
          type="email"
          name="email"
          autocomplete="email"
          placeholder="メールアドレスを入力"
          @keyup.enter="emitLogin"
        />
      </div>
      <div class="field">
        <input
          key="password"
          v-model="password"
          type="password"
          name="password"
          autocomplete="current-password"
          placeholder="パスワードを入力"
          @keyup.enter="emitLogin"
        />
      </div>
      <br />
      <div class="field">
        <v-btn @click="emitLogin">次へ進む</v-btn>
        <br />
        <br />
        <div align="left">
          <a @click="showResetPassword1">パスワードを忘れた場合はこちら</a>
        </div>
      </div>
    </div>

    <div v-else-if="mode == 'CHALLENGE'">
      <div class="field">
        <input
          key="mfa"
          ref="mfa"
          v-model="mfa"
          type="text"
          name="MFA コード"
          placeholder="MFA コードを入力してください"
          @keyup.enter="emitMFA"
        />
      </div>
      <br />
      <div class="field">
        <button @click="emitMFA">次へ進む</button>
      </div>
    </div>

    <div v-else-if="mode == 'SET_MFA'">
      <div class="field">
        <span class="text">google authenticator などのMFAへ登録してください</span>
      </div>
      <div class="field">
        <vue-qrcode :value="qrCode" :options="qrcodeOptions" tag="img"></vue-qrcode>
      </div>
      <div class="field">
        <input
          key="mfa"
          ref="mfa"
          v-model="mfa"
          type="text"
          name="MFA コード"
          placeholder="MFA コードを入力してください"
          @keyup.enter="setMFA"
        />
      </div>
      <br />
      <div class="field">
        <button v-if="!showMFASecretCode" @click="showMFASecretCode = true">
          QRCodeを読めない場合
        </button>
        <v-textarea v-if="showMFASecretCode" readonly :value="secretCode" rows="2" variant="solo" />
      </div>
      <br />
      <div class="field">
        <button @click="setMFA">次へ進む</button>
      </div>
    </div>

    <div v-else-if="authRes && mode == 'COMPANIES_SELECT'" class="companies-selector">
      <span class="text">ログイン先の企業を選択してください</span>
      <fieldset class="input_radio">
        <label v-for="company in authRes?.companies" :key="company.gmbAccount">
          <input
            v-model="companySelect"
            type="radio"
            name="chooseCompany"
            :value="company.poiGroupID"
          />
          {{ company.name }}
        </label>
      </fieldset>
      <br data-v-17a05b89="" />
      <div class="field">
        <button @click="selectCompany">選択</button>
      </div>
    </div>

    <div v-else-if="mode == 'CHANGE_PASSWORD'">
      <span class="text">パスワードの変更が必要です。</span>
      <br />
      <span class="text">英字と数字を組み合わせて、6文字以上で入力してください。</span>
      <div class="field">
        <input
          key="newPassword1"
          ref="newPassword1"
          v-model="newPassword1"
          type="password"
          placeholder="新しいパスワードを入力"
          autocomplete="new-password"
          @keyup.enter="passwordChange"
        />
      </div>
      <div class="field">
        <input
          key="newPassword2"
          v-model="newPassword2"
          type="password"
          placeholder="新しいパスワードを入力（確認）"
          autocomplete="new-password"
          @keyup.enter="passwordChange"
        />
      </div>
      <br />
      <div class="field">
        <button class="button is-primary" @click="passwordChange">パスワード変更</button>
      </div>
    </div>

    <div v-else-if="mode == 'RESET_PASSWORD1'">
      <span class="text text-center">お客様のメールアドレスを入力してください。</span>
      <div class="field">
        <input
          key="email"
          ref="email"
          v-model="email"
          type="email"
          name="email"
          autocomplete="email"
          placeholder="メールアドレスを入力"
          @keyup.enter="sendVerificationCode"
        />
      </div>
      <br />
      <div class="field">
        <button class="button is-primary" @click="sendVerificationCode">
          セキュリティコード送信
        </button>
        <br />
        <br />
        <div align="left">
          <a @click="showLogin">&lt;&lt;戻る</a>
          &emsp;
          <a @click="showResetPassword2">セキュリティコード受信済み&gt;&gt;</a>
        </div>
      </div>
    </div>

    <div v-else-if="mode == 'RESET_PASSWORD2'">
      <span class="text">
        メールに記載されたセキュリティコードと新しいパスワードを入力してください。
      </span>
      <div class="field">
        <input
          key="verificationCode"
          ref="verificationCode"
          v-model="verificationCode"
          type="text"
          placeholder="セキュリティコードを入力"
          autocomplete="one-time-code"
          @keyup.enter="resetPassword"
        />
      </div>
      <div class="field">
        <input
          key="newPassword1"
          v-model="newPassword1"
          type="password"
          placeholder="新しいパスワードを入力"
          autocomplete="new-password"
          @keyup.enter="resetPassword"
        />
      </div>
      <div class="field">
        <input
          key="newPassword2"
          v-model="newPassword2"
          type="password"
          placeholder="新しいパスワードを入力（確認）"
          autocomplete="new-password"
          @keyup.enter="resetPassword"
        />
      </div>
      <br />
      <div class="field">
        <button class="button is-primary" @click="resetPassword">パスワードリセット</button>
        <br />
        <br />
        <div align="left">
          <a @click="showResetPassword1">&lt;&lt;戻る</a>
        </div>
      </div>
    </div>
    <div class="pt-16">
      <div class="copy-right" style="cursor: pointer" @click="modeCheck">
        {{ copyRight }}
      </div>
    </div>
    <div v-if="isLoading" class="progress-circular-container">
      <v-progress-circular
        :size="80"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
