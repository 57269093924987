<template>
  <div>
    <div class="d-flex flex-wrap align-center justify-end my-2">
      <v-text-field
        v-model="searchWord"
        label="検索キーワード"
        variant="underlined"
        density="compact"
        single-line
        hide-details
        clearable
        prepend-inner-icon="mdi-magnify"
        color="primary"
        class="mr-2 mb-1"
        style="width: 200px; max-width: 200px"
        @keypress.enter="searchCompanies"
        @click:clear="
          searchWord = '';
          searchCompanies();
        "
      />
      <o-button
        data-testid="filter-by-search-word"
        variant="primary"
        size="small"
        @click="searchCompanies"
      >
        検索キーワードで絞り込み
      </o-button>
      <div class="ml-3 mr-6">{{ total }}件表示中</div>
      <o-checkbox v-model="isAll">{{ dict.checkbox_label_isAll }}</o-checkbox>
      <div class="ml-4 mb-1">
        <router-link :to="{ name: 'AdminCompaniesNew' }" class="button is-primary">
          {{ dict.process_register_link_button_label }}
        </router-link>
      </div>
    </div>
    <div v-if="companies.length === 0 && !loading">{{ dict.result_message_loading }}</div>
    <o-table
      v-else
      paginated
      root-class="company-table"
      :data="companies"
      :total="total"
      :per-page="perPage"
      :current-page="currentPage"
      :loading="loading"
    >
      <o-table-column v-slot="props" field="poiGroupId" :label="dict.col_poiGroupId_title">
        {{ props.row.poiGroupID }}
      </o-table-column>

      <o-table-column v-slot="props" field="name" :label="dict.col_organization_name_title">
        <a
          :data-testid="`name-${props.row.poiGroupID}`"
          @click="changeCompany(props.row.poiGroupID)"
        >
          {{ props.row.name }}
        </a>
      </o-table-column>

      <!-- <o-table-column
        field="gmbAccount"
        :label="dict.col_gmbAccountName_title"
      >{{ props.row.gmbAccount }}</o-table-column> -->

      <!-- <o-table-column field="ownerCount" :label=dict.col_ownerCount_title numeric>
        {{ props.row.ownerCount }}
      </o-table-column>-->

      <!-- <o-table-column field="userCount" :label=dict.col_userCount_title numeric>
        {{ props.row.Users }}
      </o-table-column>-->

      <o-table-column v-slot="props" field="keywords" :label="dict.col_keywords_title">
        {{ props.row.keywords }}
      </o-table-column>

      <!-- <o-table-column field="fedPlatforms" :label=dict.col_fedPlatforms_title>
        {{ props.row.fedPlatforms }}
      </o-table-column>-->

      <o-table-column
        v-slot="props"
        field="gmbLocations"
        :label="dict.col_gmbLocationCount_title"
        numeric
      >
        {{ props.row.stores }}
      </o-table-column>

      <!-- <o-table-column field="basicCount" :label=dict.col_basicCount_title numeric>
        {{ props.row.basicCount }}
      </o-table-column>

      <o-table-column field="starterCount" :label=dict.col_starterCount_title numeric>
        {{ props.row.starterCount }}
      </o-table-column>

      <o-table-column field="pagesCount" :label=dict.col_pagesCount_title numeric>
        {{ props.row.pagesCount }}
      </o-table-column>-->

      <o-table-column
        v-slot="props"
        field="AdminCompaniesEdit"
        :label="dict.col_edit_title"
        numeric
      >
        <router-link
          :to="{
            name: 'AdminCompaniesEdit',
            params: { poiGroupId: `${props.row.poiGroupID}` },
          }"
        >
          <o-icon class="white-button" icon="pencil"></o-icon>
        </router-link>
      </o-table-column>

      <o-table-column v-slot="props" field="NewOwner" :label="dict.col_owner_edit_title">
        <button
          :data-testid="`new-owner-${props.row.poiGroupID}`"
          class="button is-primary"
          @click="newOwnerConfirm(props.row)"
        >
          <!-- <v-icon color="white" class="pr-1">fas fa-user-tie</v-icon>{{ dict.col_new_owner_button_label }} -->
          <v-icon color="white">fas fa-user-tie</v-icon>
        </button>
      </o-table-column>

      <o-table-column v-slot="props" field="gmbPostEdit" :label="dict.gmbPostEdit.title">
        <o-switch
          v-model="props.row.ops.gmbPostEdit"
          :data-testid="`gmb-post-edit-${props.row.poiGroupID}`"
          type="is-info"
          name="gmbPostEdit"
          @change="saveCompany(props.row)"
        >
          {{ props.row.ops.gmbPostEdit ? "ON" : "OFF" }}
        </o-switch>
      </o-table-column>

      <o-table-column v-slot="props" field="isActive" :label="dict.col_isActive_title">
        <o-switch
          v-model="props.row.isActive"
          :data-testid="`is-active-${props.row.poiGroupID}`"
          type="is-info"
          @change="saveCompany(props.row)"
        >
          {{ props.row.isActive ? "有効" : "無効" }}
        </o-switch>
      </o-table-column>

      <o-table-column v-slot="props" field="aggregate" :label="dict.col_aggregate_title">
        <button
          :data-testid="`aggregate-${props.row.poiGroupID}`"
          class="button is-primary"
          :disabled="props.row.poiGroupId === 0"
          @click="execAggregation(props.row.poiGroupID)"
        >
          {{ dict.col_aggregate_company_button_label }}
        </button>
      </o-table-column>

      <o-table-column v-slot="props" field="delete" label>
        <button
          :data-testid="`delete-${props.row.poiGroupID}`"
          class="button is-primary"
          :disabled="props.row.poiGroupId === 0"
          @click="deleteConfirm(props.row)"
        >
          {{ dict.col_delete_company_button_label }}
        </button>
      </o-table-column>
    </o-table>
    <v-dialog v-if="isModalActive" v-model="isModalActive" custom-class="deletion-modal">
      <modal-form
        v-bind="modalProps"
        @delete="deleteCompany(modalProps.propOrgId)"
        @cancel="isModalActive = false"
      ></modal-form>
    </v-dialog>
  </div>
</template>
<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
