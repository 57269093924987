<template>
  <div class="dashboard">
    <!-- 共通ヘッダ -->
    <div class="fixed-header" :style="`width: calc(100% - ${headerCalcWidth}px)`">
      <div class="fixed-header-wrapper">
        <o-field position="is-right">
          <!-- ヘルスチェック -->
          <div class="health-check comparison-area">
            <div class="term-container">
              <span class="word-display">表示期間:</span>
              <date-range-picker v-model="range" />
              <button class="button is-primary" style="margin: 0 10px" @click="search">適用</button>
            </div>
          </div>
        </o-field>
      </div>
    </div>
    <!-- ヘルスチェック -->
    <div class="health-check-container">
      <div class="text-h6 px-3">{{ dict.reportName }}</div>
      <div class="text-red p-3">
        ※表示回数、サイトアクセス数、ルートの照会数と通話数の直近３日分はGBP側でレポートが確定していないため、ただしい数値が表示されません
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="tableDatas"
      item-value="poiGroupId"
      :items-per-page="-1"
      :footer-props="footerProps"
      :fixed-header="true"
      :height="'calc(100vh - 304px)'"
      class="health-check-data-table"
    >
      <template #no-data>
        <v-alert :value="true" color="" icon="info">
          該当期間で表示できるデータがありません。
        </v-alert>
      </template>
      <template #item.bulkStores="{ item }">
        {{ getOptionSymbol(item.bulkStores) }}
      </template>
      <template #item.bulkMedia="{ item }">
        {{ getOptionSymbol(item.bulkMedia) }}
      </template>
      <template #item.citation="{ item }">
        {{ getOptionSymbol(item.citation) }}
      </template>
      <template #item.review="{ item }">
        {{ getOptionSymbol(item.review) }}
      </template>
      <template #item.post="{ item }">
        {{ getOptionSymbol(item.post) }}
      </template>
      <template #item.structure="{ item }">
        {{ getOptionSymbol(item.structure) }}
      </template>
      <template #item.inChargeLogins="{ item }">
        <router-link :to="createHealthCheckLogURL(item.poiGroupId, 'inChargeLogins')">
          {{ item.inChargeLogins }}
        </router-link>
      </template>
      <template #item.businessUpdates="{ item }">
        <router-link :to="createHealthCheckLogURL(item.poiGroupId, 'businessUpdates')">
          {{ item.businessUpdates }}
        </router-link>
      </template>
      <template #item.mediaUploads="{ item }">
        <router-link :to="createHealthCheckLogURL(item.poiGroupId, 'mediaUploads')">
          {{ item.mediaUploads }}
        </router-link>
      </template>
      <template #item.reviewReplies="{ item }">
        <router-link :to="createHealthCheckLogURL(item.poiGroupId, 'reviewReplies')">
          {{ item.reviewReplies }}
        </router-link>
      </template>
      <template #item.posts="{ item }">
        <router-link :to="createHealthCheckLogURL(item.poiGroupId, 'gmbPosts')">
          {{ item.posts }}
        </router-link>
      </template>
      <template #item.gmbStoresUpdateCreate="{ item }">
        <router-link :to="createHealthCheckLogURL(item.poiGroupId, 'gmbStoresUpdateCreate')">
          {{ item.gmbStoresUpdateCreate }}
        </router-link>
      </template>
    </v-data-table>
    <div v-if="loading" class="progress-circular-container">
      <v-progress-circular
        :size="80"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
