<template>
  <div>
    <div v-if="isLoading" class="progress-circular-container">
      <v-progress-circular
        :size="80"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card elevation="2" class="mb-2">
      <v-card-text>質問と回答</v-card-text>
    </v-card>
    <div class="d-flex align-center mb-2">
      <auto-complete-card
        v-if="!canShowAllowedStoresOnly"
        v-model="filter.areaIds"
        :items="areaItems"
        label="グループを選択して下さい"
        unit="グループ"
        show-all-select
        :chip-count="6"
        hint=""
        :persistent-hint="false"
        hide-details
        single-line
        density="compact"
        :clearable="false"
        data-testid="area-selector"
        class="mr-1"
        style="max-width: 250px"
        @update:model-value="updateFilter"
      />
      <v-tooltip text="店舗ID, 店舗コード, 店舗名で検索" location="top">
        <template #activator="{ props: tooltip }">
          <div v-bind="tooltip">
            <v-text-field
              v-model="filter.searchWord"
              label="検索キーワード"
              variant="underlined"
              density="compact"
              single-line
              hide-details
              clearable
              prepend-inner-icon="mdi-magnify"
              color="primary"
              class="mr-2 w-20"
              style="width: 200px; max-width: 200px"
              @keypress.enter="updateFilter"
              @click:clear="
                filter.searchWord = '';
                updateFilter();
              "
            />
          </div>
        </template>
      </v-tooltip>
      <v-btn size="small" class="primary me-auto" @click="updateFilter()">絞り込み</v-btn>
    </div>
    <div class="d-flex align-center mb-2">
      <div class="me-auto"></div>

      <v-btn class="primary mr-3" :disabled="!isCacheUpdatedAll" @click="downloadXlsxFull">
        <v-icon icon="mdi-download" />
        質問と回答の一括ダウンロード
      </v-btn>
      <v-btn variant="outlined" class="mr-3" :disabled="isEditing" @click="reloadQandaStores">
        <v-icon left>fas fa-refresh</v-icon>
        最新の状態を取得
      </v-btn>
      <span v-if="!isEditing">
        <v-btn class="primary" :disabled="!isCacheUpdatedAll" @click="exportXlsx">
          <v-icon icon="mdi-download" />
          XLSXエクスポート
        </v-btn>
        <input ref="importInput" type="file" hidden @change="importXlsx" />
        <v-btn
          class="primary"
          :disabled="!isCacheUpdatedAll || isEditing"
          @click="($refs.importInput as HTMLInputElement).click()"
        >
          <v-icon icon="mdi-upload" />
          XLSXインポート
        </v-btn>
      </span>
      <span v-else>
        <v-btn variant="outlined" :disabled="!isCacheUpdatedAll" @click="reloadQandaStores">
          <v-icon icon="mdi-cancel" />
          編集内容を破棄
        </v-btn>
        <v-btn class="primary ml-2" :disabled="!isCacheUpdatedAll" @click="applyEditing">
          <v-icon icon="mdi-cloud-upload" />
          編集内容を反映
        </v-btn>
      </span>
    </div>
    <div v-if="!isCacheUpdatedAll" class="d-flex align-center mb-2">
      情報取得中は一部機能が使えません。質問や回答の編集を行ったあとおよそ1分後に情報取得が終わります。
    </div>
    <div v-if="0 !== unverifiedRows.length" class="d-flex align-center mb-2">
      オーナー確認が完了していない店舗があります
    </div>
    <div v-if="0 !== ineligibleRows.length" class="d-flex align-center mb-2">
      メインカテゴリが質問と回答に対応していない店舗があります
    </div>
    <div>
      <div v-if="filteredRows.length === 0 && !isLoading">
        質問と回答はありません（閉業している店舗、権限を取得していない店舗は表示されません）
      </div>
      <o-table
        v-else
        :data="filteredRows"
        detailed
        detail-key="id"
        :show-detail-icon="true"
        custom-row-key="id"
        :paginated="true"
        :per-page="50"
        @details-open="
          async (row: QandaRow) => {
            isLoading = true;
            await populateCachedQuestion(row);
            isLoading = false;
          }
        "
      >
        <o-table-column v-slot="props" label="店舗ID">
          <v-tooltip :text="trimLocationName(props.row.store.gmbLocationID)">
            <template #activator="{ props: tooltip }">
              <div v-bind="tooltip">
                {{ props.row.qas.poiID }}
              </div>
            </template>
          </v-tooltip>
        </o-table-column>
        <o-table-column v-slot="props" label="店舗コード">
          {{ props.row.store.gmbStoreCode }}
        </o-table-column>
        <o-table-column v-slot="props" label="店舗名">
          {{ props.row.store.name }}
        </o-table-column>
        <o-table-column v-slot="props" label="オーナー自身の質問数">
          {{ props.row.getCountQuestionsFromMerchant() }}
        </o-table-column>
        <o-table-column v-slot="props" label="利用者の質問数">
          {{ props.row.getCountQuestionsFromOther() }}
        </o-table-column>
        <o-table-column v-slot="props" label="情報取得">
          <v-tooltip>
            キャッシュ取得リクエスト日時:
            {{ props.row.qas.cacheSummary?.updateRequestedAt }}
            <br />
            キャッシュ取得日時:
            {{ props.row.qas.cacheSummary?.updatedAt }}
            <template #activator="{ props: tooltip }">
              <div v-bind="tooltip" @click="enqueueUpdateCache(props.row)">
                <span v-if="props.row.isCacheUpdated()" class="status-success">
                  <v-icon>fas fa-check</v-icon>
                </span>
                <v-progress-circular v-else indeterminate />
              </div>
            </template>
          </v-tooltip>
        </o-table-column>
        <o-table-column v-slot="props" label="更新日時">
          {{ utcToJst(props.row.qas.currentApplicationTask?.updatedAt) }}
        </o-table-column>
        <o-table-column v-slot="props" label="反映状況">
          <div @click="getTasks(props.row.qas.poiID)">
            <div
              v-if="props.row.qas.currentApplicationTask?.status === 'EDITING'"
              style="background: cyan"
            >
              編集中
            </div>
            <span
              v-if="props.row.qas.currentApplicationTask?.status === 'PENDING'"
              class="status-running"
            >
              <v-icon>fas fa-spinner</v-icon>
              待機中
            </span>
            <span
              v-if="props.row.qas.currentApplicationTask?.status === 'READY'"
              class="status-ready"
            >
              <v-icon>fas fa-hourglass</v-icon>
              反映待ち
            </span>
            <span
              v-if="props.row.qas.currentApplicationTask?.status === 'RUNNING'"
              class="status-running"
            >
              <v-icon>fas fa-spinner</v-icon>
              反映中
            </span>
            <span
              v-if="props.row.qas.currentApplicationTask?.status === 'SUCCESS'"
              class="status-success"
            >
              <v-icon>fas fa-check</v-icon>
              反映成功
            </span>
            <span
              v-if="props.row.qas.currentApplicationTask?.status === 'FAILURE'"
              class="status-failure"
            >
              <v-icon color="#E95454">fas fa-exclamation-triangle</v-icon>
              反映失敗
            </span>
            <span
              v-if="props.row.qas.currentApplicationTask?.status === 'CANCELED'"
              class="status-canceled"
            >
              <v-icon>fas fa-xmark</v-icon>
              キャンセル
            </span>
          </div>
        </o-table-column>
        <template #detail="props">
          <table v-if="props.row.detailRows?.length !== 0" class="store-status">
            <thead>
              <tr>
                <th>質問</th>
                <th>質問者</th>
                <th>オーナー(トストア)による回答</th>
                <th>その他回答</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="detail in props.row.detailRows ?? []" :key="detail.id">
                <td
                  :style="detail.beforeQuestion !== detail.afterQuestion ? 'background: cyan;' : ''"
                >
                  <div v-if="detail.afterQuestion === '-'" style="text-decoration: line-through">
                    {{ detail.beforeQuestion }}
                  </div>
                  <div v-else>
                    {{ detail.beforeQuestion }}
                    {{
                      detail.beforeQuestion !== detail.afterQuestion
                        ? "→" + detail.afterQuestion
                        : ""
                    }}
                  </div>
                  <v-btn
                    v-if="detail.isOwnerQuestion && props.row.isEditable() && !isEditing"
                    icon="fas fa-pencil"
                    size="x-small"
                    @click="showQuestionDialog(props.row, detail.question)"
                  />
                  <v-btn
                    v-if="detail.isOwnerQuestion && props.row.isEditable() && !isEditing"
                    size="small"
                    class="primary"
                    @click="deleteQuestion(props.row, detail.question)"
                  >
                    質問を削除
                  </v-btn>
                </td>
                <td>
                  <v-tooltip :text="detail.question?.author?.displayName">
                    <template #activator="{ props: tooltip }">
                      <span v-bind="tooltip">
                        {{ detail.author }}
                      </span>
                    </template>
                  </v-tooltip>
                </td>
                <td :style="detail.beforeAnswer !== detail.afterAnswer ? 'background: cyan;' : ''">
                  <span v-if="detail.afterAnswer === ''" style="text-decoration: line-through">
                    {{ detail.beforeAnswer }}
                  </span>
                  <span v-else :class="detail.isAnswerChanging ? 'status-ready' : ''">
                    {{ detail.beforeAnswer }}
                    {{ detail.beforeAnswer !== detail.afterAnswer ? "→" + detail.afterAnswer : "" }}
                  </span>
                  <span v-if="detail.answer">
                    <v-btn
                      v-if="props.row.isEditable() && !isEditing"
                      icon="fas fa-pencil"
                      size="x-small"
                      @click="showAnswerDialog(props.row, detail.question)"
                    />
                    <v-btn
                      v-if="props.row.isEditable() && !isEditing"
                      size="small"
                      class="primary"
                      @click="deleteAnswer(props.row, detail.question)"
                    >
                      回答を削除
                    </v-btn>
                  </span>
                  <span v-else>
                    <v-btn
                      v-if="props.row.isEditable() && !isEditing"
                      size="small"
                      class="primary"
                      @click="showAnswerDialog(props.row, detail.question)"
                    >
                      回答する
                    </v-btn>
                  </span>
                </td>
                <th>
                  {{ detail.otherAnswerCount }}件
                  <v-btn
                    v-if="0 < detail.otherAnswerCount"
                    variant="outlined"
                    @click="showOtherAnswerDialog(detail.question.topAnswers ?? [])"
                  >
                    表示
                  </v-btn>
                </th>
              </tr>
            </tbody>
          </table>
          <v-btn
            v-if="props.row.isEditable() && !isEditing"
            class="primary mr-3"
            @click="showQuestionDialog(props.row, null)"
          >
            質問を追加
          </v-btn>
        </template>
      </o-table>
    </div>

    <!-- 質問ダイアログ -->
    <v-dialog v-model="questionDialog.show" max-width="600">
      <v-card>
        <v-card-title class="text-h5">質問を編集</v-card-title>
        <v-card-text name="menuname">
          <v-text-field
            v-model="questionDialog.text"
            label="質問"
            :rules="[rules.minLength(10), rules.maxLength(250)]"
            counter="250"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="questionDialog.show = false">キャンセル</v-btn>
          <v-btn
            depressed
            color="primary"
            :disabled="
              !questionDialog.text ||
              questionDialog.text.length < 10 ||
              questionDialog.text.length > 250
            "
            @click="upsertQuestion()"
          >
            登録
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 回答ダイアログ -->
    <v-dialog v-model="answerDialog.show" max-width="600">
      <v-card>
        <v-card-title class="text-h5">回答を編集</v-card-title>
        <v-card-text name="menuname">
          <v-text-field
            v-model="answerDialog.text"
            label="回答"
            :rules="[rules.maxLength(1000)]"
            counter="1000"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="answerDialog.show = false">キャンセル</v-btn>
          <v-btn
            depressed
            color="primary"
            :disabled="!answerDialog.text || answerDialog.text.length > 1000"
            @click="upsertAnswer()"
          >
            登録
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- その他回答ダイアログ -->
    <v-dialog v-model="otherAnswerDialog.show" max-width="1000">
      <v-card>
        <v-card-title class="text-h5">その他回答</v-card-title>
        <v-card-text>
          <o-table :data="otherAnswerDialog.answers">
            <o-table-column v-slot="props" label="回答">
              {{ props.row.text }}
            </o-table-column>
            <o-table-column v-slot="props" label="回答者">
              {{ props.row.author?.displayName }}
            </o-table-column>
            <o-table-column v-slot="props" label="回答者タイプ">
              {{ props.row.author?.type }}
            </o-table-column>
          </o-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="otherAnswerDialog.show = false">閉じる</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ZoneId, DateTimeFormatter, ZonedDateTime } from "@js-joda/core";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import wordDictionary from "@/word-dictionary";
import type {
  EntitiesQandaUpdate,
  EntitiesStore,
  MybusinessqandaQuestion,
  MybusinessqandaAnswer,
} from "@/types/ls-api";
import { api } from "@/helpers/api/qanda";
import { VuetifyValidator } from "@/helpers";
import type { SnackbarToast } from "@/components/shared/snackbar/snackbar-shared";
import AutoCompleteCard from "@/components/shared/auto-complete-card/AutoCompleteCard.vue";
import { useSnackbar } from "@/storepinia/snackbar";
import { useIndexedDb } from "@/storepinia/idxdb";
import { arrayBufferToStringsArrays, read } from "@/helpers/xlsxtools";
import { trimLocationName } from "@/helpers/gmb";
import { makeUpdatesFromImportData, QandaRow } from "./qanda-list-utils";
import { makeSelectItems, type SelectItem } from "@/helpers/select-items";
import { containsAny } from "@/helpers/arrays";

const RELOAD_INTERVAL = 20000;

export default defineComponent({
  components: { AutoCompleteCard },
  data: () => {
    return {
      isLoading: false,
      canShowAllowedStoresOnly: useIndexedDb().canShowAllowedStoresOnly,
      areaItems: [] as SelectItem[],
      filter: {
        searchWord: "",
        areaIds: [] as number[],
      },
      rows: [] as QandaRow[],
      filteredRows: [] as QandaRow[],
      unverifiedRows: [] as QandaRow[], // オーナー確認が完了していない店舗
      ineligibleRows: [] as QandaRow[], // 質問と回答に対応していない店舗
      questionDialog: {
        show: false,
        row: null as QandaRow,
        question: null as MybusinessqandaQuestion,
        text: "",
      },
      answerDialog: {
        show: false,
        row: null as QandaRow,
        question: null as MybusinessqandaQuestion,
        text: "",
      },
      otherAnswerDialog: { show: false, answers: [] as MybusinessqandaAnswer[] },
      rules: VuetifyValidator,
    };
  },
  computed: {
    poiGroupId: function (): number {
      return parseInt(this.$route.params.poiGroupId as string);
    },
    isEditing: function (): boolean {
      return this.rows.some((row) => row.qas?.currentApplicationTask?.status === "EDITING");
    },
    isCacheUpdatedAll: function (): boolean {
      return this.rows.every((row) => row.isCacheUpdated());
    },
    canEdit: function (): boolean {
      return !this.rows.some(
        (row) =>
          row.qas.currentApplicationTask?.status === "RUNNING" ||
          row.qas.currentApplicationTask?.status === "READY"
      );
    },
  },
  async mounted() {
    this.isLoading = true;
    // グループプルダウン作成
    ({ areas: this.areaItems } = makeSelectItems(
      useIndexedDb().areaStores,
      useIndexedDb().stores?.stores,
      false
    ));
    // qanda_stores を初回読み込み
    const stores = useIndexedDb().stores.stores as EntitiesStore[];
    const qass = await api.getQandaStores(this.poiGroupId);
    const rows: QandaRow[] = [];
    for (const store of stores) {
      if (!store.enabled) continue; // 無効な店舗はここで除外
      const qas = qass.find((q) => q.poiID === store.poiID);
      if (!qas) {
        console.log("qanda_store not found", store.poiID);
        continue;
      }
      rows.push(new QandaRow(qas, store));
    }
    this.isLoading = false;
    // 表示できない行を分類
    for (const row of rows) {
      if (row.qas.cacheSummary.error?.["details"]?.[0]?.reason === "UNVERIFIED_LOCATION") {
        // ここで unverifiedRows にセットされるのは、オーナ確認が完了していない店舗
        this.unverifiedRows.push(row);
      } else if (row.qas.cacheSummary.error?.["details"]?.[0]?.reason === "INELIGIBLE_PLACE") {
        // ここで ineligibleRows にセットされるのは、質問と回答に対応していないカテゴリーの店舗
        this.ineligibleRows.push(row);
      } else if (row.qas.cacheSummary.isInvalidLocation) {
        console.log("invalidLocation", row.store.poiID, row.qas.cacheSummary.error);
      }
    }
    this.rows = rows.filter((row) => !row.qas.cacheSummary.isInvalidLocation);
    this.updateFilter();

    // すべてのキャッシュが読み込み済みでないか、READY か RUNNING があれば、10秒後に qanda_stores を更新する
    console.log("isCacheUpdatedAll", this.isCacheUpdatedAll, "canEdit", this.canEdit);
    if (!this.isCacheUpdatedAll || !this.canEdit) {
      setTimeout(async () => {
        await this.reloadQandaStores();
      }, RELOAD_INTERVAL);
    }
  },
  methods: {
    async updateFilter(): Promise<void> {
      // フィルターをセットして再描画
      let rows: QandaRow[] = this.rows;
      if (this.filter.areaIds.length !== 0) {
        rows = rows.filter((row) => containsAny(this.filter.areaIds, row.store.areas ?? []));
      }
      if (this.filter.searchWord) {
        rows = rows.filter((row) => {
          const word = [row.store.poiID, row.store.gmbStoreCode, row.store.name].toString();
          return word.includes(this.filter.searchWord);
        });
      }
      this.filteredRows = rows;
    },
    /** qanda_stores を更新する */
    async reloadQandaStores() {
      console.log("reloadQandaStores");
      this.isLoading = true;
      const newQass = await api.getQandaStores(this.poiGroupId);
      this.rows = this.rows.filter((row) => {
        const newQas = newQass.find((v) => v.poiID === row.qas.poiID);
        if (!newQas) return false; // 新たに読み込んだ qanda_stores に無いものを除外する
        return newQas.cacheSummary.isInvalidLocation == false;
      });
      for (const row of this.rows) {
        const newQas = newQass.find((v) => v.poiID === row.qas.poiID);
        if (!newQas) continue;
        const oldUpdatedAt = row.qas.cacheSummary.updatedAt;
        newQas.cache = row.qas.cache;
        row.setQandaStore(newQas);
        // キャッシュを読み込んでいて、キャッシュが更新されていたらキャッシュを読み込み直す
        if (row.qas.cache && oldUpdatedAt < row.qas.cacheSummary.updatedAt) {
          this.populateCachedQuestion(row, true);
        }
      }
      this.updateFilter();
      this.isLoading = false;
      // すべてのキャッシュが読み込み済みでないか、READY か RUNNING があれば、20秒後に qanda_stores を更新する
      console.log("isAllStoresCacheLoaded", this.isCacheUpdatedAll, "canEdit", this.canEdit);
      if (!this.isCacheUpdatedAll || !this.canEdit) {
        setTimeout(async () => {
          await this.reloadQandaStores();
        }, RELOAD_INTERVAL);
      }
    },
    /** キャッシュを画面に読み込む */
    async populateCachedQuestion(row: QandaRow, forceUpdate = false) {
      if (!forceUpdate) {
        // 強制読み込みでないなら次の場合は読み込まない
        // - すでにキャッシュが読み込み済み
        // - 不正なロケーション
        // - 質問数が0
        const count = row.getCountQuestionsFromMerchant() + row.getCountQuestionsFromOther();
        if (row.qas.cache || row.qas.cacheSummary.isInvalidLocation || count === 0) {
          return;
        }
      }
      const newQas = await api.getQandaStore(this.poiGroupId, row.qas.poiID);
      row.setQandaStore(newQas);
    },

    addSnackbar: function (message: SnackbarToast): void {
      useSnackbar().addSnackbarMessages(message);
    },
    utcToJst(utc: string, format?: string): string {
      if (utc == null || utc === "") {
        return "";
      } else {
        format = format ? format : "yyyy/MM/dd HH:mm:ss";
        return ZonedDateTime.parse(utc)
          .withZoneSameInstant(ZoneId.of("Asia/Tokyo"))
          .format(DateTimeFormatter.ofPattern(format));
      }
    },
    trimLocationName(locationName: string): string {
      return trimLocationName(locationName);
    },

    /** キャッシュ更新 */
    async enqueueUpdateCache(row: QandaRow) {
      const res = await api.enqueueUpdateCacheTask(this.poiGroupId, row.qas.poiID);
      row.setQandaStore(res);
      setTimeout(async () => {
        await this.reloadQandaStores();
      }, RELOAD_INTERVAL);
    },
    /** 反映履歴取得 */
    async getTasks(poiId: number) {
      // 単にAPIを実行するだけ。画面には表示しない
      const res = await api.getTasks(this.poiGroupId, poiId);
      for (const task of res.list) {
        console.log(task);
        console.table(task.updates);
      }
    },
    /** 質問ダイアログ 表示 */
    async showQuestionDialog(store: QandaRow, question: MybusinessqandaQuestion) {
      this.questionDialog.row = store;
      this.questionDialog.question = question;
      this.questionDialog.text = question?.text ?? "";
      this.questionDialog.show = true;
    },
    /** 回答ダイアログ 表示 */
    async showAnswerDialog(store: QandaRow, question: MybusinessqandaQuestion) {
      const answer = question.topAnswers?.find((a) => a?.author?.type === "MERCHANT");
      this.answerDialog.row = store;
      this.answerDialog.question = question;
      this.answerDialog.text = answer?.text ?? "";
      this.answerDialog.show = true;
    },
    /** 質問を登録 */
    async upsertQuestion() {
      const row = this.questionDialog.row;
      const poiId = row.qas.poiID;
      const q = this.questionDialog.question;
      let action: EntitiesQandaUpdate;
      if (q) {
        action = {
          action: "UPDATE_QUESTION",
          criteria: q.name,
          question: this.questionDialog.text,
        };
      } else {
        action = {
          action: "CREATE_QUESTION_AND_ANSWER",
          question: this.questionDialog.text,
        };
      }
      const res = await api.createApplyTask(this.poiGroupId, poiId, [action]);
      row.qas.currentApplicationTask = res.currentApplicationTask;
      console.log(res);
      const res2 = await api.enqueueApplyTask(this.poiGroupId, poiId);
      console.log(res2);
      row.qas.currentApplicationTask = res2;
      row.setQandaStore(row.qas);
      row.setCacheIsNotUpdated();
      this.questionDialog.show = false;
      await this.reloadQandaStores();
    },
    /** 質問を削除 */
    async deleteQuestion(row: QandaRow, q: MybusinessqandaQuestion) {
      const poiId = row.qas.poiID;
      const res = await api.createApplyTask(this.poiGroupId, poiId, [
        { action: "DELETE_QUESTION", criteria: q.name, question: "-" },
      ]);
      row.qas.currentApplicationTask = res.currentApplicationTask;
      console.log(res);
      const res2 = await api.enqueueApplyTask(this.poiGroupId, poiId);
      console.log(res2);
      row.qas.currentApplicationTask = res2;
      row.setQandaStore(row.qas);
      row.setCacheIsNotUpdated();
      await this.reloadQandaStores();
    },
    /** 回答を登録 */
    async upsertAnswer() {
      const row = this.answerDialog.row;
      const poiId = row.qas.poiID;
      const q = this.answerDialog.question;
      const res = await api.createApplyTask(this.poiGroupId, poiId, [
        { action: "UPSERT_ANSWER", criteria: q.name, answer: this.answerDialog.text },
      ]);
      row.qas.currentApplicationTask = res.currentApplicationTask;
      console.log(res);
      const res2 = await api.enqueueApplyTask(this.poiGroupId, poiId);
      console.log(res2);
      row.qas.currentApplicationTask = res2;
      row.setQandaStore(row.qas);
      row.setCacheIsNotUpdated();
      this.answerDialog.show = false;
      await this.reloadQandaStores();
    },
    /** 回答を削除 */
    async deleteAnswer(row: QandaRow, q: MybusinessqandaQuestion) {
      const poiId = row.qas.poiID;
      const res = await api.createApplyTask(this.poiGroupId, poiId, [
        { action: "DELETE_ANSWER", criteria: q.name },
      ]);
      row.qas.currentApplicationTask = res.currentApplicationTask;
      console.log(res);
      const res2 = await api.enqueueApplyTask(this.poiGroupId, poiId);
      console.log(res2);
      row.qas.currentApplicationTask = res2;
      row.setQandaStore(row.qas);
      row.setCacheIsNotUpdated();
      await this.reloadQandaStores();
    },
    /** その他回答ダイアログ 表示 */
    async showOtherAnswerDialog(answers: MybusinessqandaAnswer[]) {
      this.otherAnswerDialog.answers = answers.filter((a) => !(a.author?.type === "MERCHANT"));
      this.otherAnswerDialog.show = true;
    },
    /** 質問と回答の一括ダウンロード、すべての質問と回答を一覧するもの（閲覧専用） */
    async downloadXlsxFull() {
      // qanda_stores を更新する
      await this.reloadQandaStores();
      if (!this.isCacheUpdatedAll || !this.canEdit) {
        this.addSnackbar({
          text: "キャッシュが読み込み済みでないか、反映中の店舗があるためエクスポートできません",
        });
        return;
      }
      this.isLoading = true;
      for (const row of this.rows) {
        await this.populateCachedQuestion(row);
      }
      this.isLoading = false;
      const data: string[][] = [
        [
          "店舗ID",
          "店舗名",
          "質問ID",
          "質問者名",
          "質問者プロフィール画像URI",
          "質問者種別",
          "質問の賛同数",
          "質問内容",
          "質問作成時刻",
          "質問更新時刻",
          "回答ID",
          "回答者名",
          "回答者プロフィール画像URI",
          "回答者種別",
          "回答の賛同数",
          "回答内容",
          "回答作成時刻",
          "回答更新時刻",
        ],
      ];
      for (const s of this.rows) {
        for (const q of s.qas.cache?.questions ?? []) {
          for (const a of q.topAnswers ?? [{} as MybusinessqandaAnswer]) {
            data.push([
              s.qas.poiID.toString(),
              s.store.name,
              /questions\/([^/]+)/.exec(q.name)?.[1] ?? "",
              q.author?.displayName ?? "",
              q.author?.profilePhotoUri ?? "",
              q.author?.type ?? "",
              q.upvoteCount?.toString() ?? "0",
              q.text ?? "",
              this.utcToJst(q.createTime) ?? "",
              this.utcToJst(q.updateTime) ?? "",
              /answers\/([^/]+)/.exec(a?.name)?.[1] ?? "",
              a?.author?.displayName ?? "",
              a?.author?.profilePhotoUri ?? "",
              a?.author?.type ?? "",
              a?.upvoteCount?.toString() ?? (a.name ? "0" : ""),
              a?.text ?? "",
              this.utcToJst(a?.createTime) ?? "",
              this.utcToJst(a?.updateTime) ?? "",
            ]);
          }
        }
      }
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(data), "質問と回答");
      const buf: ArrayBuffer = XLSX.write(wb, {
        type: "array",
        bookType: "xlsx",
        bookSST: true,
      });
      const companyName = useIndexedDb().company.name;
      const datetime = dayjs().format("YYYYMMDD");
      const filename = `${wordDictionary.service.name}-質問と回答の一括ダウンロード-${companyName}-${datetime}.xlsx`;
      saveAs(new Blob([buf], { type: "application/octet-stream" }), filename);
    },
    /** XLSXエクスポート */
    async exportXlsx() {
      // qanda_stores を更新する
      await this.reloadQandaStores();
      if (!this.isCacheUpdatedAll || !this.canEdit) {
        this.addSnackbar({
          text: "キャッシュが読み込み済みでないか、反映中の店舗があるためエクスポートできません",
        });
        return;
      }
      this.isLoading = true;
      for (const row of this.rows) {
        await this.populateCachedQuestion(row);
      }
      this.isLoading = false;

      const data: string[][] = [
        ["店舗ID", "店舗名", "質問ID", "質問内容", "トストアによる回答内容"],
      ];
      for (const s of this.rows) {
        for (const q of s.qas.cache?.questions ?? [{} as MybusinessqandaQuestion]) {
          const a = q.topAnswers?.find((a) => a?.author?.type === "MERCHANT");
          data.push([
            s.qas.poiID.toString(),
            s.store.name,
            /questions\/([^/]+)/.exec(q.name)?.[1] ?? "",
            q.text ?? "",
            a?.text ?? "",
          ]);
        }
      }
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(data), "質問と回答");
      const buf: ArrayBuffer = XLSX.write(wb, {
        type: "array",
        bookType: "xlsx",
        bookSST: true,
      });
      const companyName = useIndexedDb().company.name;
      const datetime = dayjs().format("YYYYMMDD");
      const filename = `${wordDictionary.service.name}-質問と回答のFAQ登録-${companyName}-${datetime}.xlsx`;
      saveAs(new Blob([buf], { type: "application/octet-stream" }), filename);
    },
    /** XLSXインポート */
    async importXlsx(event: Event) {
      // qanda_stores を更新する
      await this.reloadQandaStores();
      if (!this.isCacheUpdatedAll || !this.canEdit) {
        this.addSnackbar({
          text: "キャッシュが最新でないか、反映中の店舗があるためインポートできません",
        });
        return;
      }
      // すべての店舗のキャッシュを画面に読み込む
      for (const row of this.rows) {
        await this.populateCachedQuestion(row);
      }
      // インポートファイルを読み込む
      const target = event.target as HTMLInputElement;
      const file: File = target.files[0];
      if (!file) {
        return;
      }
      target.value = "";
      const buf = await read(file);
      const book = arrayBufferToStringsArrays(buf);
      const data = book[0];
      console.log(data);

      const [umap, errors] = makeUpdatesFromImportData(data, this.rows);
      if (errors.length > 0) {
        this.addSnackbar({
          text: "エラーが発生しました\n" + errors.join("\n"),
          color: "danger",
          timeout: 10000,
        });
        return;
      }
      console.log(umap);
      for (const [poiId, updates] of umap) {
        if (updates.length === 0) {
          continue;
        }
        const row = this.rows.find((s) => s.qas.poiID === poiId);
        if (!row) {
          continue;
        }
        row.qas.currentApplicationTask = {
          poiGroupID: this.poiGroupId,
          poiID: poiId,
          status: "EDITING",
          updatedAt: "",
          updates: updates,
        };
        row.setQandaStore(row.qas);
      }
    },
    /** 編集内容を反映する */
    async applyEditing() {
      const rows = this.rows.filter((row) => row.qas.currentApplicationTask?.status === "EDITING");
      for (const row of rows) {
        const poiId = row.qas.poiID;
        const updates = row.qas.currentApplicationTask?.updates ?? [];
        if (updates.length === 0) continue;

        // 質問を削除した場合、回答を追加・変更・削除できない
        // そのため updates に "DELETE_QUESTION" が含まれる場合、同じ criteria の "UPSERT_ANSWER" などを削除する
        const deleteQuestions = updates.filter((u) => u.action === "DELETE_QUESTION");
        const deleteQuestionCriteria = deleteQuestions.map((u) => u.criteria);
        const filterdUpdates = updates.filter(
          (u) => u.action === "DELETE_QUESTION" || !deleteQuestionCriteria.includes(u.criteria)
        );

        console.log(poiId, filterdUpdates);
        const res = await api.createApplyTask(this.poiGroupId, poiId, filterdUpdates);
        row.qas.currentApplicationTask = res.currentApplicationTask;
        console.log(res);
        const res2 = await api.enqueueApplyTask(this.poiGroupId, poiId);
        console.log(res2);
        row.qas.currentApplicationTask = res2;
        row.setQandaStore(row.qas);
        row.setCacheIsNotUpdated();
      }
      await this.reloadQandaStores();
    },
  },
});
</script>
<style lang="scss" scoped>
@use "@/components/style/button.scss" as button;

.button-col {
  button:not(:last-child) {
    margin-right: 1rem;
  }
}
table.store-status {
  background-color: #ffffff;
  th,
  td,
  tbody,
  thead {
    border-width: 1px;
    border-color: #b3b3b3;
    border-style: solid;
  }
  th {
    background-color: #fafafa;
  }
  td {
    background-color: #ffffff;
  }
}
span.status-ready {
  color: #ee9d26;
  svg {
    color: #ee9d26;
  }
}
span.status-running {
  color: #42a4e4;
  svg {
    color: #42a4e4;
  }
}
span.status-success {
  color: #73c92d;
  svg {
    color: #73c92d;
  }
}
span.status-failure {
  color: #e95454;
  svg {
    color: #e95454;
  }
}
span.status-canceled {
  color: #757575;
  svg {
    color: #757575;
  }
}
</style>
