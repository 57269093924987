import type { RouteLocationNormalized } from "vue-router";

export class OperationLogParams {
    uiType: string;
    actionType: string;
    requestID: string;
}
export function getOperationLogParams(
    route: RouteLocationNormalized,
    actionType: string,
    uiType?: string
): OperationLogParams {
    return {
        uiType: uiType ?? (route.meta?.uiType as string) ?? "unknown",
        actionType,
        requestID: (route.meta?.requestID as string) ?? "unknown",
    };
}
