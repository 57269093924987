import type { ControllersFeatureTogglesFindOutput } from "@/types/ls-api";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";

type Status = "OK" | "INFO" | "WARN" | "STOP";
export class FeatureToggle {
    statusCode: number;
    result: ControllersFeatureTogglesFindOutput;
    constructor(statusCode: number, result: ControllersFeatureTogglesFindOutput) {
        this.statusCode = statusCode;
        this.result = result;
    }
    getStatus(partName: string = ""): Status {
        if (!partName) {
            return this.result.status as Status;
        }
        const part = this.result.parts?.[partName];
        if (part) {
            return part.status as Status;
        }
        return "OK";
    }
    getMessage(partName: string = ""): string {
        if (!partName) {
            return this.result.message;
        }
        const part = this.result.parts?.[partName];
        if (part) {
            return part.message;
        }
        return "Nothing";
    }
}
export async function getFeatureToggle(
    isLoginNeeded: boolean,
    poiGroupId: number | string,
    featureName: string
): Promise<FeatureToggle> {
    const url = isLoginNeeded
        ? `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/featuretoggles`
        : `${import.meta.env.VITE_APP_API_BASE}v1/featuretoggles`;
    try {
        const res = await request(isLoginNeeded, { method: "GET", url, params: { featureName } });
        return new FeatureToggle(res.status, res.data);
    } catch (e) {
        return new FeatureToggle(0, null);
    }
}
/**
 * トークンリフレッシュ機能をつけたリクエスト関数
 * requiredAuthは自動的に画面遷移するので独自に実装している
 */
export async function request(
    isLoginNeeded: boolean,
    config: AxiosRequestConfig
): Promise<AxiosResponse<any>> {
    // 認証が不要であればそのまま実行する
    if (isLoginNeeded === false) {
        return await axios({ ...config, validateStatus: () => true });
    }
    let headers = {
        ...(config.headers ?? {}),
        Authorization: `Bearer ${sessionStorage.IdToken}`,
    };
    const res = await axios({ ...config, headers, validateStatus: () => true });
    if (res.status === 401 && res.data?.message === "The incoming token has expired") {
        // IDトークン有効期限切れにつきリフレッシュトークンで更新して、リトライ
        const refresh = await axios({
            method: "post",
            url: `${import.meta.env.VITE_APP_API_BASE}v1/auth/refresh`,
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            data: { refreshToken: sessionStorage.RefreshToken },
            validateStatus: () => true,
        });
        if (refresh.status !== 200) {
            return res;
        }
        sessionStorage.IdToken = refresh.data.idToken;
        sessionStorage.AccessToken = refresh.data.accessToken;
        headers = {
            ...(config.headers ?? {}),
            Authorization: `Bearer ${sessionStorage.IdToken}`,
        };
        return await axios({ ...config, headers, validateStatus: () => true });
    }
    return res;
}
