<template>
  <v-snackbar
    v-model="state.isSnackbarShow"
    :timeout="-1"
    :location="snackbarConfig.top ? 'top' : 'bottom'"
    :bottom="!snackbarConfig.top"
    content-class="snackbars-container"
    color="rgba(255,255,255,0)"
    :transition="false"
  >
    <div class="d-flex flex-column">
      <template v-for="message in snackbarMessages" :key="message.id">
        <snackbar-message
          :message="message"
          :snackbar-config="snackbarConfig"
          @mark="markSnackbarMessages(message.id)"
          @remove="removeSnackbarMessages(message.id)"
        />
      </template>
    </div>
  </v-snackbar>
</template>

<script lang="ts">
import { Component, Vue, Watch, toNative } from "vue-facing-decorator";
import { useSnackbar } from "@/storepinia/snackbar";
import snackbarMessage from "./snackbar-message.vue";
import type { SnackbarConfig, SnackbarToast } from "./snackbar-shared";

const SNACKBAR_DEFAULT_CONFIG: SnackbarConfig = { top: true };
@Component({
  components: { snackbarMessage },
})
class Snackbar extends Vue {
  // 表示オプション
  // 20221107時点では縦の表示位置のみ。Snackbar全体に適用される(個々の通知に指定することは出来ない)
  // top: falseなら画面下部に表示する
  snackbarConfig: SnackbarConfig = SNACKBAR_DEFAULT_CONFIG;
  state = useSnackbar();
  snackbarMessages = this.state.snackbarMessages;
  markSnackbarMessages = this.state.markSnackbarMessages;
  removeSnackbarMessages = this.state.removeSnackbarMessages;

  @Watch("snackbarMessages", { deep: true })
  onSnackbarChange(): void {
    // 最後のメッセージにoptionsがあったらそれを適用する
    this.snackbarConfig = this.snackbarMessages[this.lastIndex]?.options
      ? this.snackbarMessages[this.lastIndex].options
      : SNACKBAR_DEFAULT_CONFIG;
  }

  addSnackbar(message: SnackbarToast): void {
    this.state.addSnackbarMessages(message);
  }

  get lastIndex(): number {
    return this.snackbarMessages.length - 1;
  }
}
export default toNative(Snackbar);
</script>
<style lang="scss">
// box-shadow を除去。scoped にしていない
.v-snackbar > .v-overlay__content.snackbars-container {
  box-shadow: none !important;
}
</style>
