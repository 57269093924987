<template>
  <div v-if="!isLoading" data-testid="review-reply-cards">
    <v-card v-for="review in items" :key="review.reviewID" class="mb-2">
      <!-- 返信チェックボックス -->
      <v-checkbox
        v-if="canManage"
        v-model="review.checked"
        color="primary"
        density="compact"
        hide-details
        :data-testid="`review-reply-cards-checkbox-${review.reviewID}`"
        class="pl-1"
        @update:model-value="checkReply(review)"
      >
        <template #label>
          <!-- 返信状態 -->
          <v-chip
            label
            variant="outlined"
            size="small"
            :color="replyColor(review.replyComment)"
            class="ml-2"
          >
            {{ replyStatusName(review.replyComment) }}
          </v-chip>
        </template>
      </v-checkbox>
      <!-- 閲覧のみの場合はchipのみ表示 -->
      <v-chip
        v-else
        label
        variant="outlined"
        size="small"
        :color="replyColor(review.replyComment)"
        class="ml-2 my-2"
      >
        {{ replyStatusName(review.replyComment) }}
      </v-chip>
      <!-- 自店舗/競合店と店舗名 -->
      <v-card-title class="review-title d-flex align-center px-2">
        <v-avatar color="#78909C" size="28" class="p-2">
          <span class="white--text is-rival-avatar">
            {{ review.isRival ? "競" : "自" }}
          </span>
        </v-avatar>
        <div class="review-title-storename pl-2">
          {{ review.storeName }}
        </div>
      </v-card-title>
      <!-- 星数 -->
      <span class="d-flex justify-center v-card-star">
        <v-rating v-model="review.starRating" readonly color="primary" active-color="primary" />
      </span>
      <!-- クチコミ表示順位 -->
      <span class="d-flex justify-start review-info-1 review-ranking">
        <span class="review-ranking-label mr-3">
          <p class="px-2">クチコミ表示順位</p>
        </span>
        <p v-if="review.isRival === true">-</p>
        <p v-else>{{ ranking(review.rank) }}</p>
      </span>
      <!-- 最終更新時刻と投稿者名 -->
      <span class="d-flex justify-start stripe review-info-2 pl-2 py-1">
        <span>{{ reviewPostTime(review.updateTime) }}</span>
        <strong class="ml-3">{{ review.reviewerDisplayName }}</strong>
      </span>
      <p class="px-2 my-1 review-info-2">
        {{ emptyComment(review.comment) }}
      </p>

      <!-- 返信内容はexpandで表示する -->
      <v-card-actions v-show="review.replyUpdateTime">
        <v-spacer />
        <v-btn icon @click.stop="review.show = !review.show">
          <v-icon>{{ review.show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="review.show" class="mt-n5">
          <small class="px-2 review-info-1">
            {{ reviewPostTime(review.replyUpdateTime) }} 返信
          </small>
          <p class="px-1 my-1 review-info-2">
            <v-icon color="primary" small class="reply-arrow px-1">fas fa-level-up-alt</v-icon>
            <span class="pt-2">{{ review.replyComment }}</span>
          </p>
        </div>
      </v-expand-transition>
    </v-card>
    <!-- ページネーション -->
    <v-pagination
      v-model="pageNumber"
      :length="totalPageLength"
      density="comfortable"
      active-color="primary"
    />
  </div>
  <v-overlay :model-value="isLoading" persistent class="align-center justify-center">
    <v-progress-circular indeterminate size="64" color="primary" />
  </v-overlay>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ReviewSearchTable from "./review-search-table.vue";

import type { ReviewResponseRow } from "./review-parameter";

type ReviewRow = ReviewResponseRow & {
  show: boolean;
  checked: boolean;
};

export default defineComponent({
  extends: ReviewSearchTable,
  emits: ["page-change"],
  computed: {
    totalPageLength(): number {
      return Math.ceil(this.$props.total / 15);
    },
    pageNumber: {
      get(): number {
        return this.$props.page;
      },
      set(page: number) {
        this.$emit("page-change", page);
      },
    },
    items(): ReviewRow[] {
      const items = [...this.$props.reviews] as ReviewRow[];
      // クチコミ返信内容を表示するためのフラグを追加する
      items.forEach((i) => {
        i.show = false;
      });
      // クチコミ返信選択フラグを追加する
      items.forEach((i) => {
        i.checked = false;
        this.checkedRows.forEach((r) => {
          if (i.name === r.name) {
            i.checked = true;
          }
        });
      });
      return items;
    },
  },
  methods: {
    replyColor(replyComment: string) {
      return replyComment ? "#48c78e" : "#ff5252";
    },
    checkReply(row: ReviewRow) {
      if (row.checked) {
        this.checkedRows = [...this.checkedRows, row as ReviewResponseRow];
      } else {
        this.checkedRows = this.checkedRows.filter((r) => r.name !== row.name);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

:deep(.v-card .v-card-title) {
  line-height: 1.5rem;
}
:deep(.v-card-title) {
  font-size: 1rem;
  box-shadow: 0 0 3px #00000033;
}
:deep(.v-card-star) {
  padding: 0.5rem 0;
  font-size: 1.5rem;
  border-bottom: 1px solid #f5f5f5;
  .mdi-star {
    &::before {
      font-size: 2rem;
    }
  }
  .mdi-star-outline {
    &::before {
      font-size: 2rem;
    }
  }
}
:deep(.v-card-actions) {
  margin-top: -1rem;
}
.stripe {
  background-color: #f5f5f5;
}
:deep(.v-checkbox) {
  // 勝手にopacity指定されるので無効化する
  .v-label {
    opacity: unset;
  }
}
.is-rival-avatar {
  font-size: 14px;
  font-weight: bold;
}
.review-ranking {
  border: 0.25px solid color.$base-main-color;
}
.review-ranking-label {
  background-color: color.$base-main-color;
  color: #333333;
}
.review-info-1 {
  font-size: 0.8rem;
  border-bottom: 1px solid #00000033;
}
.review-info-2 {
  font-size: 0.9rem;
  border-top: 1px solid white;
}
.reply-arrow {
  transform: rotate(90deg);
}
.review-title-storename {
  font-weight: 900;
}
</style>
