import dayjs from "dayjs";
import { locale } from "@/helpers";
import numeral from "numeral";
import wordDictionary from "../word-dictionary";
import { sentimentLevels } from "../const";

export const readableStrDate = (val: string): string => {
    if (val === "") {
        return "";
    } else {
        return dayjs(val).format("YYYY/MM/DD");
    }
};

export const readableStrDateTime = (val: string): string => {
    if (val === null) {
        return "-";
    } else {
        return dayjs(val).format("YYYY/MM/DD HH:mm");
    }
};

export const strDate = (val: string): string => {
    if (val === "") {
        return "";
    }
    return dayjs(val).format("YYYY-MM-DD");
};

export const addPlusOrMinusSign = (val: number): string => {
    const n = numeral(val).value();
    const ret = locale(Math.abs(n));

    if (n === 0) {
        return `±${ret}`;
    } else if (n > 0) {
        return `+${ret}`;
    } else {
        return `-${ret}`;
    }
};

export const convertTime = (val: any): string => {
    const strVal = `${val}`;
    const sec = Math.round(Math.abs(numeral(val).value()));
    const sign = strVal[0] === "+" || strVal[0] === "-" || strVal[0] === "±" ? strVal[0] : "";
    const converted = dayjs().startOf("day").second(sec).format("HH:mm:ss");
    const ret = sign + converted;

    return ret;
};

function isInvalidNumber(n: number) {
    return n == null || Number.isNaN(n);
}

export const timesFormat = (n: number): string => {
    if (isInvalidNumber(n)) {
        return wordDictionary.metric.result_message_loading;
    }
    const formated = n > 0 ? Math.floor(n) : Math.ceil(n);

    return `${formated.toLocaleString()}回`;
};
export const numberFomat = (n: number): string =>
    isInvalidNumber(n) ? wordDictionary.metric.result_message_loading : `${n}`;
export const rateFormat = (n: number): string =>
    isInvalidNumber(n) ? wordDictionary.metric.result_message_loading : `${n}%`;
export const indexFormat = (n: number): string =>
    isInvalidNumber(n) ? wordDictionary.metric.result_message_loading : n.toLocaleString();

export interface ReviewAlertConditionInterface {
    alertSentimentRanges?: string[];
    alertKeywords?: string[];
    alertStarRatings?: number[];
}

export const reviewAlertConditionFormat = (
    cond: Readonly<ReviewAlertConditionInterface>
): string => {
    const orConds: string[] = [];
    const orVariation = (a: string[]) => `${a.join("、")}${a.length >= 3 ? "のいずれか" : ""}`;

    if (cond.alertStarRatings != null && cond.alertStarRatings.length !== 0) {
        const starRatings = cond.alertStarRatings
            .map((asr) => asr) // filterはobserverをトリガしてはいけないためコピー
            .sort((a, b) => a - b)
            .map((asr) => `${asr}`);
        orConds.push(`星数が${orVariation(starRatings)}`);
    }

    if (cond.alertSentimentRanges != null && cond.alertSentimentRanges.length !== 0) {
        const ranges = cond.alertSentimentRanges
            .map((asr) => {
                let name: string;
                let idx: number;

                sentimentLevels.forEach((sl, i) => {
                    if (asr == sl.value) {
                        idx = i;
                        name = sl.name;
                    }
                });
                return { name, idx };
            })
            .sort((a, b) => a.idx - b.idx)
            .map((r) => `「${r.name}」`);

        orConds.push(`感情スコアが${orVariation(ranges)}`);
    }

    if (cond.alertKeywords != null && cond.alertKeywords.length !== 0) {
        const keywords = cond.alertKeywords.map((asr) => `「${asr}」`);
        orConds.push(`内容に${orVariation(keywords)}を含む`);
    }

    if (orConds.length === 0) {
        return wordDictionary.stores.noNotification;
    } else {
        return orConds.join(" または ");
    }
};
